import { Component, OnInit } from '@angular/core';
import {AtivacaoAssinaturaComponent} from "../../ativacao-assinatura/ativacao-assinatura.component";

import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ActivatedRoute} from "@angular/router";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-assinatura-pagamento',
  templateUrl: './assinatura-pagamento.component.html',
  styleUrls: ['./assinatura-pagamento.component.scss']
})
export class AssinaturaPagamentoComponent implements OnInit {
  usuario: any = {};

  constructor(  private empresasService: EmpresasService,
               private dialogService: DialogService,
               private activatedRoute: ActivatedRoute) {

    let token =  this.activatedRoute.snapshot.params.token;

    this.empresasService.logada().then( (empresa: any) => {
      this.empresasService.obtenhaContratoAssinatura(token).then(  (contrato: any) => {
        this.abraModalAtivaoAssinatura(empresa, contrato);
      });
    })


  }

  ngOnInit(): void {
  }

  abraModalAtivaoAssinatura(empresa: any, contrato: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: AtivacaoAssinaturaComponent,
      minWidth: 250,
      width: window.innerWidth - 150,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = empresa;
    windowRef.content.instance.contrato = contrato;
    windowRef.content.instance.reload =  false;


  }
}
