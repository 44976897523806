import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-selo',
  templateUrl: './selo.component.html',
  styleUrls: ['./selo.component.scss']
})
export class SeloComponent implements OnInit {
  @Input() selo;

  constructor() { }

  ngOnInit() {}
}

@Component({
  selector: 'app-selos-cartao',
  templateUrl: './selos.component.html',
  styleUrls: ['./selo.component.scss']
})
export class SelosComponent implements OnInit {
  constructor() { }
  cartao: any  = {};

  selos = [];

  ngOnInit() {  }

  setCartao(cartao: any) {
    this.cartao = cartao;
    console.log('setou cartao: ')
    console.log(this.cartao);

    this.selos = [];

    if(this.cartao && this.cartao.pontos){
      for(let i = 1; i <= this.cartao.pontos; i++){
        this.selos.push({n: i, on: true});
      }
    } else {
      this.selos.push({n: 0, off: true})
    }
  }
}
