import {Empresa} from "./Empresa";
import {Plano} from "./Plano";

export class Atividade {
  id: number;
  nome: string;
  valor: number;
  cashback: number;
  empresa: Empresa;
  plano: Plano;
  pontosGanhos: number;
  integrada = false;
  constructor(id: number= null, nome: string = null, valor: number= null,
              plano: Plano = null, empresa: Empresa = null, pontosGanhos: number = null, cashback: number = null) {
    this.id = id;
    this.nome = nome;
    this.valor = valor;
    this.plano = plano;
    this.empresa = empresa;
    this.pontosGanhos = pontosGanhos;
    this.cashback = cashback;
  }

  nomeDescricaoRegra(){
    if(this.nome === 'Fez um pedido') return 'Fazer um pedido'

    return this.nome;
  }
}
