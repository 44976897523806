import {IUITipoDePontuacao} from "./IUITipoDePontuacao";
import * as _ from 'underscore';

export class UITipoDePontuacaoQtdeFixaAtividade implements IUITipoDePontuacao {
  empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any) {
    if (!pontuacaoRegistrada) return 0;

    return _.reduce(pontuacaoRegistrada.atividades,
      (valor, atividade: any) => valor + atividade.pontosGanhos, 0);
  }
}
