import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from "@progress/kendo-angular-dialog";
import {CadCupomComponent} from "../cad-cupom/cad-cupom.component";
import {CuponsService} from "../../superadmin/services/cupons.service";
import {TelaAssociarCupomContatosComponent} from "../tela-associar-cupom-contatos/tela-associar-cupom-contatos.component";
import {GridCuponsComponent} from "./grid-cupons/grid-cupons.component";


@Component({
  selector: 'app-tela-cupons',
  templateUrl: './tela-cupons.component.html',
  styleUrls: ['./tela-cupons.component.scss']
})
export class TelaCuponsComponent implements OnInit {
  @ViewChild('gridGruponsAtivos',  {static: true} )  gridGruponsAtivos: GridCuponsComponent;
  constructor(private cuponsService: CuponsService) {

  }

  ngOnInit(): void {
    this.carregueCuponsAtivos();
  }


  private carregueCuponsAtivos() {
    this.gridGruponsAtivos.carregando = true;
    this.cuponsService.listeCupons({ listavel: true }).then( (resposta) => {
      this.gridGruponsAtivos.setCupons(resposta)
    })
  }

}
