import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {PainelImportacaoProdutosComponent} from "../painel-importacao-produtos/painel-importacao-produtos.component";
import {CatalogosService} from "../../superadmin/services/catalogos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-tela-sincronizar-catalogomodelo',
  templateUrl: './tela-sincronizar-catalogomodelo.component.html',
  styleUrls: ['./tela-sincronizar-catalogomodelo.component.scss']
})
export class TelaSincronizarCatalogomodeloComponent extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: false}) frm: NgForm;
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  mensagemSucesso: any;
  mensagemErro: any;
  carregandoProdutos: boolean;
  tudoSincronizado: boolean;
  buscarProdutos: boolean;
  buscouProdutos: boolean;
  opcoes: any = { sincronizarPrecos: true};
  empresa: any = {};
  constructor(  private catalogosService: CatalogosService) {
    super()
  }

  ngOnInit(): void {
    this.buscarProdutos = true
  }

  async carregueProdutosNovos(){
    this.fecheMensagemErro();
    this.carregandoProdutos = true;

    let  resposta = await   this.catalogosService.listeNovosProdutosdDoModelo(  this.opcoes.sincronizarPrecos,
      this.opcoes.sincronizarImagens).catch( erro => {
      this.mensagemErro = erro
    })

    this.carregandoProdutos = false;
    this.buscouProdutos = true;

    if(resposta){

      if(resposta.categorias.length){
        this.buscarProdutos = false;
      } else {
        this.tudoSincronizado =  true;
      }

      this.painelImportacao.setProdutosSincronizar(resposta.novosProdutos,
        resposta.produtosAtualizar, resposta.produtosRemovidos, resposta.categorias)
    }

  }

  submitFormBusca() {
    this.frm.ngSubmit.emit()
  }

  fecheMensagemErro() {
    delete  this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  feche() {
    this.fecheModal(this.painelImportacao.importou);
  }
}
