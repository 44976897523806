import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent} from "ngx-image-cropper";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-upload-crop-imagem',
  templateUrl: './upload-crop-imagem.component.html',
  styleUrls: ['./upload-crop-imagem.component.scss']
})
export class UploadCropImagemComponent implements OnInit {
  @Input() objeto: any = {} ;
  @Input() prop = 'linkImagem';
  @Input() label  = 'Imagem';
  @Input() ratio;
  @Input() resizeWidth;
  @Input() widthExibir;
  @Input() minWidth;
  @Input() minHeight;
  @Input()  confirmarSalvar = true;
  @Output() onEnviou = new EventEmitter();
  uploadUrl: any = '/upload/imagem';
  @ViewChild('imageCropper')  imageCropper: any;
  croppedImage: any = '';
  showCropper = false;
  backupImagem: string;
  imageChangedEvent: any;
  cortar = false;
  imageCropData: any;
  editarImagem = false;
  config: any = { ratio: 1, resizeWidth: 80, minWidth: 80 , minHeight: 80}
  salvar: any = false;
  erro: any;
  salvando: any;
  cortando: any;
  erroLoadImage: string;

  constructor(private httpClient: HttpClient) { }
  ngOnInit() {
    if(this.ratio) this.config.ratio = this.ratio;
    if(this.resizeWidth) this.config.resizeWidth = this.resizeWidth;
    if(this.minWidth) this.config.minWidth = this.minWidth;
    if(this.minHeight) this.config.minHeight = this.minHeight;
    this.salvar = false;
  }

  canceleImagem(){
    this.objeto[this.prop] = this.backupImagem;
    this.imageChangedEvent = null;
    this.showCropper = null;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded')
  }
  cropperReady() {
    console.log('Cropper ready')

  }
  loadImageFailed () {
    this.erroLoadImage = 'Imagem não é do tipo suportado: png, jpeg ou jpg';
    this.imageChangedEvent = null;
  }

  fileChangeEvent(event: any) {
    delete this.erroLoadImage;
    this.imageChangedEvent = event;
  }

  corteImagem() {
    delete this.erro;
    this.cortando = true;
    let fd = new FormData();

    fd.append('files', this.dataURItoBlob(this.croppedImage));

    this.httpClient.post(this.uploadUrl , fd).toPromise().then( (resp: any ) => {
      this.backupImagem = this.objeto[this.prop];
      this.objeto[this.prop] = resp.data.file;
      this.editarImagem = false;

      this.cortando = false;
      if(this.confirmarSalvar){
        this.salvar = true;
      } else {
        this.setImagemSalva();
      }

    }).catch( erro => { this.cortando = false; this.erro = erro || 'não foi possível cortar imagem';   })
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  getLinkImagem() {
    return this.objeto[this.prop]
  }

  salve() {
    if(this.salvando) return;

    this.salvando = true;
    let retorno = {
      salvou: () => {
         this.setImagemSalva();
      },
      erro: (erro) => {
         this.salvando = false;
         this.erro = erro || 'não foi possível salvar imagem';
      }
    }
    delete this.erro;
    this.onEnviou.emit( retorno);

  }

  setImagemSalva(){
    this.salvando = false;
    this.imageChangedEvent = null;
    this.salvar = false;
  }

  canceleSalvar() {
    this.salvar = false;
  }

  larguraMinima(){
    return (this.widthExibir || this.config.minWidth );
  }

  alturaMinima(){
    return (this.widthExibir || this.config.minHeight )
  }
}
