import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageHandler {
  private userMessages: { [key: string]: any } = {};
  public messageComplete: EventEmitter<{ userId: number, message: string }> = new EventEmitter<{ userId: number, message: string }>();

  public obtenhaTextoMensagem(msg: any) {
    let textoMensagem = msg.body;

    const mapTipoMensagem: any = {
      'image': 'Imagem',
      'audio': 'Audio',
      'video': 'Video',
      'document': 'Documento',
      'ptt': 'Audio'
    }
    if( mapTipoMensagem[msg.type] ) { //o arquivo de imagem vem no body
      textoMensagem = msg.caption;

      if( !textoMensagem ) {
        textoMensagem = 'Enviei um(a) ' + mapTipoMensagem[msg.type] + '.';
      } else {
        textoMensagem = 'Enviei um(a) ' + mapTipoMensagem[msg.type] + '. ' + textoMensagem;
      }
    }

    return textoMensagem;
  }

  public obtenhaNomeContato(mensagem: any) {
    const chat = mensagem.chat;

    let nomeContato = '';
    if( chat && chat.contact ) {
      nomeContato = chat.contact.name ? chat.contact.name : chat.contact.displayName;

      if( !nomeContato ) nomeContato = chat.contact.pushname;
      if( !nomeContato ) nomeContato = chat.contact.verifiedName;
    }

    if( mensagem.sender ) {
      nomeContato = mensagem.sender.name ? mensagem.sender.name : mensagem.sender.formattedName;
    }

    return nomeContato;
  }

  public handleMessage(msg: any, tempo: number = 5000) {
    const idChat = msg.chatId;

    const nome = this.obtenhaNomeContato(msg);
    const telefone = msg.telefone;
    let textoMensagem = this.obtenhaTextoMensagem(msg);

    if (!this.userMessages[idChat]) {
      this.userMessages[idChat] = {
        nome: nome,
        telefone: telefone,
        msg: '',
        tipo: msg.type,
        idChat: idChat,
        timeoutId: null,
        ultimaMsg: msg.ultimaMsg ? this.obtenhaTextoMensagem(msg.ultimaMsg) : ''
      };
    }

    this.userMessages[idChat].msg += textoMensagem + '\n';

    if (this.userMessages[idChat].timeoutId) {
      window.clearTimeout(this.userMessages[idChat].timeoutId);
    }

    this.userMessages[idChat].timeoutId = window.setTimeout(() => {
      this.checkIfMessageComplete(idChat);
    }, tempo);
  }

  private checkIfMessageComplete(idChat: number) {
    const msg = this.userMessages[idChat];

    if( msg ) {
      this.messageComplete.emit(msg);
      delete this.userMessages[idChat];
    }
  }
}
