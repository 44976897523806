import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CampanhaService} from "../../services/campanha.service";
import {WhatsappService} from "../../services/whatsapp.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-gerenciador-envio-campanha',
  templateUrl: './gerenciador-envio-campanha.component.html',
  styleUrls: ['./gerenciador-envio-campanha.component.scss']
})
export class GerenciadorEnvioCampanhaComponent implements OnInit, OnDestroy {
  campanhas: any = null;
  enviando = false;
  assinante: Subscription;
  @Input() campanha: any;
  @Input() titulo = '';

  constructor(private campanhaService: CampanhaService, private whatsappService: WhatsappService) {

  }

  ngOnInit(): void {
    this.assinante = this.whatsappService.novaMensagem$.subscribe( (msg) => {
      if( !msg.id ) {
        return;
      }

      if( this.campanhas ) {
        const campanha = this.encontreCampanha(msg);
        if( campanha ) {
          campanha.qtdeEnviadas = msg.posicao;

          if (campanha.qtdeEnviadas === campanha.qtdeMensagens) {
            campanha.status = 'Enviada';
          }
        }
      } else {
        this.carregueCampanha();
      }
    });

    this.carregueCampanha();
  }

  encontreCampanha(msg: any) {
    for( let i = 0; i < this.campanhas.length; i++ ) {
      const campanha = this.campanhas[i];

      if( campanha.id === msg.campanha.id) {
        return campanha;
      }
    }

    return null;
  }

  carregueCampanha() {
    if( this.campanha ) {
      this.campanhas = [this.campanha];
      return;
    }
    this.campanhaService.obtenhaCampanhas(null, 'Enviando,ParouEnvio').then( (resposta) => {
      this.campanhas = resposta.campanhas;
    });
  }

  pararEnvio(campanha: any) {
    this.enviando = true;

    this.campanhaService.pararEnvio(campanha).then( () => {
      this.enviando = false;
      campanha.status = 'ParouEnvio';
    }).catch( () => {
      this.enviando = false;
    });
  }

  continuarEnvio(campanha: any) {
    this.enviando = true;

    this.campanhaService.continuarEnvio(campanha).then( () => {
      this.enviando = false;
      campanha.status = 'Enviando';
    }).catch( () => {
      this.enviando = false;
    });
  }

  ngOnDestroy(): void {
    this.assinante.unsubscribe();
  }

  cancelarEnvio(campanha: any) {
    if( !window.confirm('Tem certeza que deseja cancelar a campanha') ) {
      return;
    }

    this.enviando = true;

    this.campanhaService.cancelarEnvio(campanha).then( () => {
      this.enviando = false;
      campanha.status = 'Cancelada';
    }).catch( () => {
      this.enviando = false;
    });
  }
}
