import { Injectable } from '@angular/core';
import { ServerService } from './ServerService';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TemaPersonalizadoService extends ServerService {

  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  listeTemas(): Promise<any[]> {
    return this.http.get('/temas/liste')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  crieTema(tema: any): Promise<any> {
    return this.salve('/temas', tema);
  }

  atualizeTema(tema: any): Promise<any> {
    return this.salve('/temas', tema);
  }

  removaTema(id: number): Promise<void> {
    return super.facaPut('/temas-personalizados/remova' + id, {});
  }

  obtenha(id: string): Promise<any> {
    return this.http.get('/temas/' + id).toPromise().then(this.retorno).catch(this.handleError);
  }
}
