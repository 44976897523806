import { Component, OnInit } from '@angular/core';
import {ConfigMensagensBotService} from "../../services/config-mensagens-bot.service";

@Component({
  selector: 'app-config-mensagens',
  templateUrl: './config-mensagens.component.html',
  styleUrls: ['./config-mensagens.component.scss']
})
export class ConfigMensagensComponent implements OnInit {
  loading: any;
  listaConfigsMensagem: any = [];

  constructor(private configMensagensDeBotService: ConfigMensagensBotService) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.configMensagensDeBotService.todas().then( (configMensagens)  => {
      this.loading = false;
      this.listaConfigsMensagem = configMensagens
    });

  }

}
