
<table class="table table-striped">
  <thead>
  <tr>
    <th></th>
    <th>Empresa</th>
    <th>Descriçao</th>
    <th>Compensação</th>
    <th>Referência</th>
    <th>Ativaçao</th>
    <th>Mensalidade</th>
    <th>Adesão</th>
    <th>Sub-Total</th>
    <th>Tarifas</th>
    <th>Total</th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let recebimento of resumo.recebimentos; let i = index;">
    <td>{{i + 1}}</td>
    <td class="text-primary"><b>{{recebimento.empresa.nome}}</b></td>
    <td>{{recebimento.descricao}}</td>
    <td>{{recebimento.dataCompensacao | date :"dd/MM/yy"}}</td>
    <td>{{recebimento.referencia }}</td>
    <td>{{recebimento.dataAtivacao | date :"dd/MM/yy"}}</td>
    <td class="text-muted"> {{recebimento.valorMensalidade  | number: "1.2-2"}} </td>
    <td class="text-muted"> {{recebimento.valorAdesao  | number: "1.2-2"}} </td>
    <td class="text-muted"> {{recebimento.valorBruto  | number: "1.2-2"}} </td>
    <td class="text-danger"> {{recebimento.valorTarifas  | number: "1.2-2"}} </td>
    <td><b>{{recebimento.total  | number: "1.2-2"}}</b></td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="6"><p class="float-right">Totais</p></td>
    <td colspan="1">
      <p><b>{{resumo.totalMensalidade  | number: "1.2-2"}}</b></p>
    </td>
    <td colspan="1">
      <p><b>{{resumo.totalAdesao  | number: "1.2-2"}}</b></p>
    </td>

    <td colspan="1">
      <p><b>{{resumo.totalBruto  | number: "1.2-2"}}</b></p>
    </td>
    <td colspan="1">
      <p><b>{{resumo.totalTarifas  | number: "1.2-2"}}</b></p>
    </td>

    <td colspan="1">
      <p><b>{{resumo.total  | number: "1.2-2"}}</b></p>
    </td>
  </tr>
  </tfoot>

</table>
