import {Component, OnDestroy, OnInit} from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {PedidosService} from "../../services/pedidos.service";
import {NgForm} from "@angular/forms";
import {ModalKendo} from "../../lib/ModalKendo";
import {timer} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-modal-solicitar-cancelamento-delivery',
  templateUrl: './modal-solicitar-cancelamento-delivery.component.html',
  styleUrls: ['./modal-solicitar-cancelamento-delivery.component.scss']
})
export class ModalSolicitarCancelamentoDeliveryComponent extends ModalKendo implements OnInit, OnDestroy  {
  pedido: any = {};
  motivo: string;
  cancelando: boolean;
  ignorarParceiro: boolean;
  erro: string;
  acoes = [{ descricao:  "Retornar a loja", valor: "RETURN_TO_STORE" } , { descricao: "Cancelar entrega", valor: 'CANCEL_DELIVERY'}]
  razoes = [
    {descricao: 'Cliente Cancelou', valor: '"CONSUMER_CANCELLATION_REQUESTED"'} ,
    {descricao: "Problemas na Loja", valor: "PROBLEM_AT_MERCHANT"},
    {descricao: "Pedido incorreto ou problema na retirada", valor: "INCORRECT_ORDER_OR_PRODUCT_PICKUP"},
    {descricao: "Resolver um problema", valor: "PROBLEM_RESOLUTION"},
    {descricao: "Pedido errado", valor: "DISCOMBINE_ORDER"},
    {descricao: "Tempo retirada/entrega elevado", valor: "HIGH_ACCEPTANCE_TIME"},
    {descricao: "Não compareceu", valor: "NO_SHOW"},
    {descricao: "Outro", valor: "OTHER"},
  ]
  acao: any;
  razao: any;
  aguardandoParceiro: boolean;
  timerCancelamento: any;
  motivoRejeicao: string;
  constructor( private  grupolojasService: GrupolojasService,
              private httpCliente: HttpClient,
              private  pedidosService: PedidosService) {
    super()
  }

  ngOnDestroy(): void {
    this.pareMonitoramento();
  }

  ngOnInit(): void {   }

  pareMonitoramento(){
    this.aguardandoParceiro = false;
    if( this.timerCancelamento)
      this.timerCancelamento.unsubscribe();
  }

  cancelarSomenteMeucarpadio(){
    let dados: any = this.obtenhaDadosCancelamento();

    dados.ignorarParceiro = true;

    this.executeCancelamento(dados)
  }

  obtenhaDadosCancelamento(){
    let dados: any = {}

    if(this.exigeMotivo())
      dados = { motivo: this.motivo, razao: this.razao.valor, acao: this.acao.valor}

    return dados;
  }

  executeCancelamento(dados: any){
    this.cancelando = true;
    delete this.erro;
    this.pedidosService.canceleEntrega(this.pedido, dados).then((resposta) => {
      if(resposta && resposta.parceiroErro){
        this.erro = resposta.parceiroErro;
        this.ignorarParceiro = resposta.ignorarParceiro;
      } else {
        if(!resposta.aguardarRetorno){
          this.fecheModal(resposta)
        } else {
          this.inicieMonitoramentoCancelamento(resposta.idEvent);
        }
      }
      this.cancelando = false;
    }).catch((erro) => {
      this.cancelando = false;
      this.erro = erro;
    })
  }

  submitForm(frm: NgForm){
    if(frm && !frm.valid) return;

   this.executeCancelamento(this.obtenhaDadosCancelamento() )
  }

  inicieMonitoramentoCancelamento(idEvent){
    this.aguardandoParceiro = true;

    let segundosTimer = 5;
    this.timerCancelamento =  timer(2000, 1000 * segundosTimer  ).subscribe( () => {
      console.log('verificar pedido: ' + this.pedido.id)
      this.pedidosService.obtenhaStatusCancelamentoDelivery(this.pedido, idEvent).then( (resposta: any) => {
        if(resposta.rejeitouCancelamento){
          this.cancelando = false;
          this.motivoRejeicao  =  resposta.motivo;
          this.pareMonitoramento();
        }

        if(resposta.cancelado){
          this.cancelando = false;
          this.fecheModal(resposta)
        }
      })
    });
  }

  verDelivery(){
    this.pedidosService.verDelivery(this.pedido)
  }

  exigeMotivo(){
    return this.pedido.delivery.origem === 'opendelivery';
  }

}
