export enum EnumStatusContato {
  Novo = 'Novo',
  Ativo = 'Ativo',
  Inativo = 'Inativo',
  Fiel = 'Fiel',
  VIP = 'VIP',
  EmPerigo  = 'Em Perigo',
  Importado = 'Importado',
  Perdido = 'Perdido'
}
