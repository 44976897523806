import {ApplicationRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CadCartaocreditoComponent} from "../cad-cartaocredito/cad-cartaocredito.component";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {Location} from "@angular/common";
import {ContratoService} from "../../superadmin/services/contrato.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-pagamento-cartao-desconto',
  templateUrl: './pagamento-cartao-desconto.component.html',
  styleUrls: ['./pagamento-cartao-desconto.component.scss']
})
export class PagamentoCartaoDescontoComponent implements OnInit {

  @Input() contrato: any = {};
  @Input() fatura: any = {};
  @ViewChild('cccompoment' , { static: true }) cccompoment: CadCartaocreditoComponent;
  pagando: boolean;
  erro: any;

  constructor(private empresaService: EmpresasService, private _location: Location,
              private app: ApplicationRef,
              private  contratoService: ContratoService, private router: Router) { }

  ngOnInit() {
    const { contrato, fatura } = window.history.state;

    if(contrato){
      this.setFaturaComDesconto(contrato, fatura)

    } else {
      this.empresaService.obtenhaContrato().then( _contrato => {
        this.setFaturaComDesconto(_contrato, _contrato.proximaFatura)
      })
    }
  }

  setFaturaComDesconto(contrato, fatura){
    this.contrato = contrato;
    this.fatura = fatura;

    if(contrato.plano.descontoCartao && contrato.pagaNoBoleto){
      this.fatura.lancamentos.push({ descricao: 'Desconto cartão', total: -contrato.plano.descontoCartao})
      this.fatura.total -= contrato.plano.descontoCartao;
    }

  }

  fecheMensagemErro() {
    delete this.erro;
  }

  voltar() {
    this._location.back();
  }

  mudarParaCartao(cartao: any) {
    this.fecheMensagemErro();

    if(this.pagando) return;

    this.pagando = true;
    this.contratoService.vinculeCartaoAssinatura(this.contrato, cartao).then( resp => {
      this.pagando = false;
      this.router.navigateByUrl('/admin/plano');
    }).catch( erro => {
      this.erro = erro;
      this.pagando = false;
      this.cccompoment.canceleProcessando();
    })
  }
}
