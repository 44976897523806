import {ImpressaoService} from "../services/impressao.service";
import {PedidosService} from "../services/pedidos.service";
import {ProcessadorReciboTexto} from "../services/impressao/ProcessadorReciboTexto";



export   class TelaImprimirPedido  {
  empresa: any = {}
  _pedido: any = {}
  pedidosImprimir = {}
  host: any;
  constructor( protected impressao: ImpressaoService , protected  pedidoService: PedidosService) {
    this.host = location.origin;
  }

  private obtenhaImpressorasImprimir(){
    if(!this.empresa.configImpressao || !this.empresa.configImpressao.imprimirTXT) return [];

    let impressorasDasCategorias = [], produtosPorImpressora: any = {}

    if(this.empresa.configImpressao.multiplasImpressoras){
      this._pedido.itens.map( itemPedido => itemPedido.produto).forEach(produto => {
        if(produto.categoria){
          if(this.empresa.catalogo && this.empresa.catalogo.categorias) {
            let categoria = this.empresa.catalogo.categorias.find( cat => cat.id === produto.categoria.id);

            if(categoria && categoria.impressoras.length){
              let impressorasIds = categoria.impressoras.map(impressora => impressora.id);

              impressorasIds.forEach((impressoraId: number) => {
                if(!produtosPorImpressora[impressoraId]) produtosPorImpressora[impressoraId] = []

                produtosPorImpressora[impressoraId].push(produto)
              })

              impressorasDasCategorias = impressorasDasCategorias.concat(impressorasIds)
            }
          }
        }
      })
    }

    let impressorasAuto = this.empresa.configImpressao.impressoras.filter(
      impressora => (impressorasDasCategorias.indexOf( impressora.id) >= 0 || impressora.imprimirAutomatico) &&
        (this._pedido.mesa ? !impressora.naoImprimePedidosMesa : !impressora.naoImprimePedidosDelivery)
    );

    let descricaoImpressoras = [];

    impressorasAuto.forEach((impressora: any) => {
      if(!impressora.layout)
        impressora.layout = this.empresa.configImpressao.layoutPedido

      if(produtosPorImpressora[impressora.id])
        impressora.produtos = produtosPorImpressora[impressora.id]

      descricaoImpressoras.push(impressora.nome + (impressora.setor ? " (" + impressora.setor + ")" : ""))
    })

    console.log("Imprimindo nas impressoras", descricaoImpressoras)
    return impressorasAuto
  }

 async imprimaNovosPedidos(pedidos: any){
   console.log('total pedidos chegou para imprimir: ' + pedidos.length)
   let pedidosImprimir = [];

   pedidos.forEach((pedido: any) => {
     if(!this.pedidosImprimir[pedido.id]){
       this.pedidosImprimir[pedido.id] = pedido;
       pedidosImprimir.push(pedido)
     }
   })

   console.log('total pedidos imprimir: ' + pedidosImprimir.length)
    for(let i = 0; i < pedidosImprimir.length; i++){
      this._pedido = pedidosImprimir[i];
      console.log('imprimindo pedido:' + this._pedido.codigo)
      await this.envieImpressaoAutomatica(true);
    }
  }

  async envieImpressaoAutomatica(impressoPeloMonitorador = false){

    try{
      let impressoras = this.obtenhaImpressorasImprimir();
      if(!impressoras.length) return;

      console.log('imprimir automaticamente nas impressoras: ' + impressoras.map(impressora => impressora.nome).join(', '))

      for(let i = 0; i <  impressoras.length; i++){
        await   this.imprimaNativamente(impressoras[i], this._pedido, impressoPeloMonitorador)
      }
    } finally {

      this.pedidosImprimir[this._pedido.id] = null
    }

  }

  async imprimaNativamente(impressora: any, pedido: any, impressoPeloMonitorador = false){
    console.log(String(`imprimir pedido: ${pedido.codigo}`));

    let processadorReciboTexto = new ProcessadorReciboTexto(impressora);

    let imprimiu: boolean = await  this.impressao.imprima(processadorReciboTexto.obtenhaRecibo(pedido, impressora.layout),
      processadorReciboTexto.impressora, String(`imprimir pedido ${pedido.codigo} na impressora ${impressora.nome}`))

    if(imprimiu){
      console.log(String(`marcar  pedido como impresso: ${pedido.codigo}`));
      await this.pedidoService.marqueFoiImpresso(pedido, impressoPeloMonitorador)
    } else{
      console.log("Houve um erro ao imprimir, será feita uma nova tentativa")
    }
    this.pedidosImprimir[pedido.id] = null
  }


}
