import { Injectable } from '@angular/core';
import Dexie from "dexie";

export interface AtendimentosPendentes {
  id?: number;
  nome: string;
  telefone: string;
  horario: Date;
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService extends Dexie {
  private databaseVersion = 1;

  private atendimentosPendentesTable: Dexie.Table<AtendimentosPendentes, number>;

  constructor() {
    super('meucardapio');

    this.version(1).stores({
      atendimentos_pendentes: '++id,nome,telefone,horario,idChat',
    });

    this.atendimentosPendentesTable = this.table('atendimentos_pendentes');
  }

  //salve atendimento
  async addData(atendimento: AtendimentosPendentes) {
    await this.atendimentosPendentesTable.add(atendimento);


    return atendimento;
  }

  //obtenha todos os atendimentos
  async obtenhaTodosAtendimentos() {
    return this.atendimentosPendentesTable.toArray();
  }

  //obtenha atendimento por telefone
  async getAtendimentosPorTelefone(telefone: string) {
    return this.atendimentosPendentesTable.get({telefone: telefone});
  }

  removaAtendimento(atendimento: any) {
    return this.atendimentosPendentesTable.delete(atendimento.id);
  }
}
