import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from "@progress/kendo-angular-dialog";
import {CuponsService} from "../../superadmin/services/cupons.service";
import {GridComponent, PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {timeout} from "rxjs/operators";

@Component({
  selector: 'app-tela-associar-cupom-contatos',
  templateUrl: './tela-associar-cupom-contatos.component.html',
  styleUrls: ['./tela-associar-cupom-contatos.component.scss']
})
export class TelaAssociarCupomContatosComponent implements OnInit {
  @ViewChild('gridNovos', {static: false}) gridNovos: GridComponent;
  cupom: any;
  windowRef: DialogRef;
  contatos  = []
  contatosAdicionar  = []
  carregando: any;
  selectableSettings = {
    checkboxOnly: false,
    mode: 'multiple'
  };
  adicionar: any = false;
  public mySelection: number[] = [];
  salvando: boolean;
  totalAdicionado: number
  erro: any;
  filtro: any = {};
  private timerBusca;
  paginacao: any = {
    pagina: 0,
    qtde: 25
  }
  constructor(private cuponsService: CuponsService) {

  }

  ngOnInit(): void {
    this.carregueContatos(0);
  }

  adicionarContatos() {
    this.adicionar = true;
    delete this.filtro.texto;
    this.mySelection = [];
    this.carregueContatosDisponiveis();
  }

  volteAdicionarContatos(recarregar: boolean = false){
    this.adicionar = false;
    delete this.totalAdicionado
    if(recarregar) this.carregueContatos(0);
  }

  private carregueContatos(inicio: number = 0,   termo: string = null) {
    if(    this.carregando) return;

    this.carregando   = true;
    this.cuponsService.listeContatos(this.cupom, inicio, this.paginacao.qtde, termo).then( (resposta: any) => {
      this.carregando = false;
      if(inicio === 0){
        this.paginacao.pagina =    1
        this.contatos = resposta || [];
      } else {
        resposta.forEach( contato => this.contatos.push(contato))
        this.paginacao.pagina ++;
      }
      this.paginacao.total =   this.contatos.length;
    } )
  }

  private carregueContatosDisponiveis(termo: string = null){
    this.carregando   = true;
    this.contatosAdicionar = []
    this.cuponsService.listeContatosDisponiveis(this.cupom, 0, 50, termo).then( (resposta: any) => {
      this.carregando = false;
      this.contatosAdicionar = resposta || [];
    } )
  }

  pageChange($event: PageChangeEvent) {

  }

  salveNovosContatos(){
    if(this.salvando ) return;
    delete this.erro;
    this.salvando = true;
    this.cuponsService.adicioneContatosLista(this.cupom, this.mySelection).then( (resp: any) => {
      this.salvando = false;
      this.totalAdicionado = resp.total;
    }).catch( (err: any) => {
      this.erro = err;
      this.salvando = false;
    })


  }

  removerContato($event: RemoveEvent) {
    let contato = $event.dataItem;

    if(   contato.removendo) return;

    contato.removendo = true;

    this.cuponsService.removaContatoDaLista(this.cupom, contato).then( () => {
      contato.removendo = false;
      contato.removido = true;
    }).catch( () => {
      contato.removendo = false;
    })

  }

  selectionChange(e: any) {


  }

  onSelectedKeysChange(e: any) {

  }

  fecheMensagemErro() {
    delete  this.erro;
  }

  adicionarMais() {
    delete this.totalAdicionado;
    this.adicionarContatos();
  }

  onFilter(event: any) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueContatos(0, event.target.value)
    }, 1000)

  }
  onFilterDisponiveis(event: any) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueContatosDisponiveis(event.target.value)
    }, 1000)

  }

  loadMore() {
    this.carregueContatos(this.contatos.length, this.filtro.texto)
  }
}
