<div >
  <h4 class="page-title">
    <button class="btn btn-outline-blue btn-rounded mr-1" (click)="voltar()"    >
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <i class="fa fa-credit-card"></i> Mudar para cartão</h4>

  <div class="row mt-2">
    <div class="col-12 col-sm-6 col-lg-5 ">
      <div class="card-box">
        <div class="row">
          <div class="col-12">
            <app-fatura-detalhes [fatura]="fatura"></app-fatura-detalhes>
          </div>

          <div class="col-12">
            <cad-cartaocredito #cccompoment (onCriou)="mudarParaCartao($event)"  >

            </cad-cartaocredito>
          </div>

          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show mt-2" *ngIf="erro" role="alert">
              <i class="mdi mdi-check-all mr-2"></i> {{erro}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
