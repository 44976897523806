import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root',
})
export class QrCodeService extends ServerService {
  constructor(public http: HttpClient) {
    super(http);
  }
  listarTodos(): Promise<any[]> {
    return this.obtenha('/qrcodes', {});
  }

  buscarPorId(id: number): Promise<any> {
    return this.obtenha(`/qrcodes/${id}`, {});
  }

  salveQrCode(qrCode: any): Promise<any> {
    return super.salve('/qrcodes', qrCode);
  }

  excluir(id: number): Promise<void> {
    return this.remova(`/qrcodes/${id}`, {});
  }
}
