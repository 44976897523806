<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <img src="assets/fidelidade/icones/novocartao.svg" style="height: 32px;"/> Novo Cartão
    </h4>
  </div>

  <div class="card-body">
  <form [hidden]="cartao.id"  [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >
    <app-buscar-contato #buscarContato mensagemObrigatorio="Telefone é obrigatório"
                        [validarContato]="false" [mensagem]="'Informe o telefone ou nome do contato'"></app-buscar-contato>

    <div class="form-group mb-3">
      <label for="nome">Nome do Contato</label>
      <input type="text" class="form-control" autocomplete="off"
             id="nome" name="nome" [(ngModel)]="contato.nome" #nome="ngModel"    nomeValido
             placeholder="Nome do Contato" value="" required appAutoFocus [autoFocus]="true">
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        <p *ngIf="nome.errors?.nomeCompleto">Informe um nome completo</p>
        <p *ngIf="nome.errors?.nomeValido">Nome inválido, é permitido apenas letras</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-group mb-12">
          <label  >Data Nascimento</label>
          <kendo-datepicker [(ngModel)]="contato.dataNascimento" class="form-control" id="dataNascimento" name="dataNascimento" style="border: none;">
          </kendo-datepicker>
        </div>
      </div>
      <div class="col">
        <div class="form-group mb-3">
          <label  >Sexo</label>
          <div class="mt-1 mb-1" style="width:250px;">
            <input type="radio" #sexo="ngModel" name="sexo" [id]="'sexo1' + idAleatorio" class="k-radio right" value="Homem" [(ngModel)]="contato.genero" required />
            <label class="k-radio-label mr-1" [for]="'sexo1' + idAleatorio">Homem</label>
            <input type="radio" #sexo="ngModel" name="sexo" [id]="'sexo2' + idAleatorio" class="k-radio right" value="Mulher" [(ngModel)]="contato.genero" required/>
            <label class="k-radio-label" [for]="'sexo2' + idAleatorio">Mulher</label>

            <div class="invalid-feedback">
              <span *ngIf="sexo.errors?.required"> Sexo é obrigatório
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="usuario.empresa.ativarIndicacoes && deveInserirPontos">
      <app-buscar-contato #buscarQuemIndicouComponent mensagemObrigatorio="Telefone é obrigatório" [parent]="this"
                          [validarContato]="false" [mensagem]="'Informe quem indicou o cliente'" criarNovo="true"></app-buscar-contato>


      <div *ngIf="contatoQuemIndicou != null && contatoQuemIndicou.cartao">
        <h4>Indicação: </h4>
        <div class="row contato">
          <div class="col-5">
            <card-contato [contato]="contatoQuemIndicou"></card-contato>
          </div>
          <div class="col-7">
            <div class="card-box text-center" style="padding: 0px;">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span class="text-black"> {{contatoQuemIndicou.cartao.pontos}}</span><br>
                  <label>{{contatoQuemIndicou.cartao.plano.tipoDeAcumulo}}</label>
                </div>

                <div class="col-12 col-sm-8">
                  <span class="text-black" style="    line-height: 40px;">{{contatoQuemIndicou.ultimaVisita | date: 'dd/MM/yyyy HH:mm' }}</span><br>
                  <label>Última visita</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group mb-12" *ngIf="planos.length > 0">
      <label for="plano">Plano:</label>

      <kendo-combobox id="plano" name="plano" [(ngModel)]="plano" [disabled]="cartao.id" [data]="planos" [filterable]="true"
                      [allowCustom]="false" placeholder="Selecione um Plano" class="form-control"
                      textField="nome" required="true" style="border: none;"
                      [kendoDropDownFilter]="filterSettings"
                      [valueField]="'id'" (ngModelChange)="alterouPlano()">
      </kendo-combobox>
      <div class="invalid-feedback">
        Plano é obrigatório
      </div>
    </div>


    <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible fade show" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemSucesso()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemErro()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <mensagem-bloqueio [bloqueado]="bloqueado"></mensagem-bloqueio>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light mr-2"   [disabled]="salvando"  *ngIf="!bloqueado" >Salvar</button>

      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="fechar()">Fechar</button>
    </div>
  </form>

  <form [hidden]="!cartao.id" [ngClass]="{'needs-validation': !frmPts.submitted, 'was-validated': frmPts.submitted}"
        novalidate #frmPts="ngForm" (ngSubmit)="onSubmitPontos()">
    <app-inserir-pontos   #appInserirPts [frm]="frmPts" *ngIf="deveInserirPontos"></app-inserir-pontos>

    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="mensagemErro">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemErro()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>


    <div class="mb-12 row">
      <div class="col-12">
        <span *ngIf="!bloqueado">
              <button type="button" class="btn btn-warning waves-effect mr-2" data-dismiss="modal" (click)="novo()">Novo</button>
              <button type="submit" class="btn btn-primary waves-effect waves-light mr-2" [disabled]="salvando" >Adicionar Pontos</button>
        </span>

        <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="fechar()">Fechar</button>
      </div>
    </div>
  </form>
  </div>
</div>

<kendo-dialog title="Contato Indicação não existe" *ngIf="abraDialogo" (close)="close('nao')" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center;">Você preencheu um número de indicação que ainda não tem um contato. Deseja criar agora?</p>
    <kendo-dialog-actions>
      <button kendoButton (click)="close('sim')" primary="true">OK</button>
    </kendo-dialog-actions>
</kendo-dialog><!-- /.modal-content -->
