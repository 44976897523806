<ng-container *ngIf="listaDeAtendimentos.length > 0">
  <h4>Atendimentos Pendentes</h4>
  <kendo-listview [data]="listaDeAtendimentos" itemClass="item">
    <ng-template kendoListViewItemTemplate let-dataItem>
      <div class="p-2">
        <p class="mt-0 mb-1 font-15">
          <strong><i class="far fa-user font-16"></i> {{ dataItem.nome }}</strong>
        </p>
        <p class="mb-0">
          <label for="telefone" class="text-black-50">Telefone: </label>
          <span id="telefone" class="text-dark">&nbsp;{{ dataItem.telefone }}</span>
        </p>
        <p class="mb-0"><i class="fas fa-clock"></i> {{ dataItem.horario }}</p>
        <div class="mt-2 mt-0">
          <button class="btn btn-sm btn-primary" (click)="atenderCliente(dataItem)" kendoTooltip title="Abre a conversa no Whatsapp">Abra Conversa</button>
          <button class="btn btn-sm btn-blue ml-2" (click)="encerrarAtendimento(dataItem)" kendoTooltip title="Encerra o atendimento e habilita o Atendente Virtual">Encerrar Atendimento</button>
        </div>
      </div>
      <hr class="mt-1 mb-1">
    </ng-template>
  </kendo-listview>
</ng-container>
