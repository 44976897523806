import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tabela-recebimento',
  templateUrl: './tabela-recebimento.component.html',
  styleUrls: ['./tabela-recebimento.component.scss']
})
export class TabelaRecebimentoComponent implements OnInit {
  @Input() resumo: any = {}
  constructor() { }

  ngOnInit(): void {
  }

}
