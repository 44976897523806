import {AfterContentInit, Directive, ElementRef, Input, ViewContainerRef} from '@angular/core';
import {NumericTextBoxComponent} from "@progress/kendo-angular-inputs";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {NgModel} from "@angular/forms";

@Directive({
  selector: '[appSelecionarNoFoco]'
})
export class SelecionarNoFocoDirective implements AfterContentInit{
  private selecionou: boolean;

  constructor(private el: ElementRef, private model: NgModel) {
  }

  obtenhaValor() {
    return parseFloat(this.el.nativeElement.value.replace('R$', '').replace(',', '.').trim());
  }

  selecioneTudo() {
    if( this.obtenhaValor() > 0 ) {
      this.el.nativeElement.select();
      this.selecionou = true;
    }
  }

  limpeSeTiverFoco() {
    if( !this.selecionou )
      return;

    this.selecionou = false;
    this.model.valueAccessor.writeValue(0);
  }

  public ngAfterContentInit() {
    this.el.nativeElement.addEventListener('focusin', (e) => {
      this.selecioneTudo();
    });

    this.el.nativeElement.addEventListener('keydown', (e) => {
      this.limpeSeTiverFoco();
    });
  }
}
