<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()" *ngIf="!widget" style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fe-user "></i> Contato</h4>

<div class="row"  [hidden]="!contato.id">
  <div class="col-12">
    <div class="card contato">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-4">
            <card-contato [contato]="contato" [exibirUltimaVisita]="false" [exibirEmail]="true" [exibirEndereco]="true">

            </card-contato>
            <button class="btn btn-rounded btn-outline-danger waves-effect "  (click)="remover(contato)" *ngIf="false"> //TODO: Refazer remover contato
              <div class="icone "> <i class="fas fa-trash" style="font-size: 24px; line-height: 32px" ></i> </div>
            </button>

            <button class="btn btn-rounded btn-blue waves-effect waves-light editar" (click)="editarContato(contato)"
            style="    float: right; top: 0;   position: absolute;  right: 10px;">
              <i class="fe-edit"  > </i>
            </button>


            <div class="form-group">
              <div class="input-group" >
                <kendo-autocomplete [loading]="buscandoTag"   style="width: 240px" [data]="tags"   [highlightFirst]="true"
                                    #tagList  name="tagList"       [valueField]="'nome'"
                                    [kendoDropDownFilter]="{ operator: 'contains' }"
                                    [filterable]="true"
                                    placeholder="Tag: crie ou adicione uma tag"
                                    (valueChange)="alterouTag($event)"
                >
                </kendo-autocomplete>

                <div class="d-inline-block ml-1">
                  <button class="btn btn-success " *ngIf="novaTag" (click)="salveTag()">
                    <i class="k-icon k-i-save " *ngIf="!salvandoTag"> </i>
                    <i class="k-icon k-i-loading" *ngIf="salvandoTag"></i>
                  </button>

                  <button class="btn btn-blue  " *ngIf="!novaTag" (click)="adicioneTagSelecionada()" [disabled]="!tagSelecionada?.id">
                    <i class="k-icon k-i-plus " *ngIf="!salvandoTag"> </i>
                    <i class="k-icon k-i-loading" *ngIf="salvandoTag"></i>
                  </button>
                </div>
              </div>
            </div>

          </div>

          <div class="col-12 col-md-8 plano-info">
             <div class="card-box plano">
              <div class="float-left">
                <label class="mb-1">Cartão Fidelidade:</label>
                <kendo-combobox id="cartao" name="cartao" [(ngModel)]="filtro.cartao" [data]="cartoes" [filterable]="true"
                                [allowCustom]="false" placeholder="Selecione um Cartão" class="form-control" [clearButton]="false"
                                [textField]="'descricao'"  style="border: none;"
                                [kendoDropDownFilter]="filterSettings" (selectionChange)="alterouCartao($event)">
                </kendo-combobox>
              </div>
               <div class="float-right text-center d-none d-sm-block">
                 <span class="text-black"  > <i class="fa fa-calendar"></i> {{ contato?.ultimaVisita | date: 'dd/MM/yyyy HH:mm'}}</span><br>
                 <label class="text-muted font-13 ">Última visita</label>
               </div>

             </div>
              <div class="card-box pontos" [hidden]="!porPontos()"  >
                <label> Pontos:  </label>
                <span class="text-success  ml-2">
                    {{filtro?.cartao?.pontos | number}}
                 </span>
              </div>

              <div class="card-box pontos" [hidden]="!porReais()"  >
                <label> Saldo:  </label> <span class="text-success  ml-2">{{filtro?.cartao?.pontos | currency: "BRL"}}</span>
              </div>

              <div class="card-box por-selo" [hidden]="!porSelos()" >
                  <label class="float-left"  > Selos:  </label>
                  <span class="lista-selos">
                  <app-selos-cartao #selosCartao></app-selos-cartao>
              </span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb-3">
    <acoes-modulos #acoesModulo [contato]="contato"  [cartao]="filtro?.cartao" (salvouContato)="eventoSalvouContato(contato)" ></acoes-modulos>
  </div>

  <div class="col-12 mb-0" *ngIf="contato !== null">
    <div class="alert mt-2 mb-2" role="alert" *ngIf="respServerAlterouAtivarDesativar"
         [class.alert-danger]="!respServerAlterouAtivarDesativar.sucesso"
         [class.alert-success]="respServerAlterouAtivarDesativar.sucesso">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <i class="fas fa-exclamation-triangle" *ngIf="!respServerAlterouAtivarDesativar.sucesso"></i>
      <i class="mdi mdi-check-all mr-2" *ngIf="respServerAlterouAtivarDesativar.sucesso"></i>
      {{respServerAlterouAtivarDesativar.mensagem}}
    </div>
    <div class="form-group">
      <label>Desativar mensagens de marketing</label>
      <div class="mt-0 mb-1 font-24 valor">
        <kendo-switch name="exibirCardapio" [(ngModel)]="contato.desativarMensagensDeMarketing"
                      (ngModelChange)="alterouDesativarEnvioMsgMarketing($event)"
                      [onLabel]="'Sim'"
                      [offLabel]="'Não'"></kendo-switch>
      </div>
    </div>
  </div>

  <div class="col-12">
    <info-pedidos [contato]="contato"  [empresa]="empresa" ></info-pedidos>
  </div>

  <div class="col-12">

    <app-historico-modulos [contato]="contato" [empresa]="empresa" (onEstornouPontos)="carregueContato()" ></app-historico-modulos>
  </div>


  <app-dialog-remover-contato [contatoSelecionado]="contato" [tela]="this" [abraDialogo]="abraDialogo"></app-dialog-remover-contato>
</div>
<!--
<div class="row"  [hidden]="contato != null">
  <div class="col">
    <div style="width: 100%;">
      <div class="contato">
        <label class="mb-1">{{nomeContato}}</label>
        <p>
          <span class="text-muted">
            <i class="fab fa-whatsapp" style="color: #82c91e;font-size: 14px;"></i> {{telefone | mask: '(99) 9-9999-9999' }}</span>
        </p>
      </div>

      <h5 class="mt-2">Você ainda fidelizou esse cliente</h5>
    </div>

    <div clas="mt=2">
    <button _ngcontent-evq-c8="" class="btn btn-blue   btn-lg  waves-effect waves-light float-left " type="button"
     (click)="abraTelaNovoContato()">
      <i _ngcontent-evq-c8="" class="fe-user-plus"></i> Fidelizar Agora </button>
    </div>
  </div>
</div>
-->
