<ng-container *ngIf="objeto && objeto.menu && objeto.menu.opcoes">
    <hr>
    <div class="col-12">
        <h5 class="font-16">Enviar um botão de opções junto com a mensagem?</h5>

        <div class="mt-3">
            <figure class="figure">
                <img src="/assets/fidelidade/mensagem_de_menu.png" class="figure-img img-fluid rounded" alt="Uma imagem representativa" style="width: 400px;">
                <figcaption class="figure-caption">Exemplo de mensagem com opções</figcaption>
            </figure>
        </div>

        <kendo-switch [(ngModel)]="objeto.temMenu" name="temMenu" id="temMenu" (valueChange)="ativouDesativouOMenu($event)"></kendo-switch>

        <ng-container *ngIf="objeto.temMenu && objeto.menu">
            <div class="form-group mb-2">
                <label>Título da Mensagem (Texto em Negrito no Whatsapp, veja foto acima)</label>
                <input type="text" name="tituloMensagem" #tituloMensagem="ngModel" [(ngModel)]="objeto.menu.tituloMensagem" class="form-control" placeholder="Título da Mensagem" required="true"/>
                <span class="small help">Título que fica em negrito no Whatspp. Você pode colocar o nome do cliente: <strong>Oi, tudo bem?</strong>
                    Importante: Esse campo não aceita campos personalizados, apenas texto fixo.
                </span>

                <div *ngIf="tituloMensagem.invalid" class="invalid-feedback">
                    <div *ngIf="tituloMensagem.errors.required">
                        Título do Botão é obrigatório
                    </div>
                </div>
            </div>

            <div class="form-group mb-2 pt-2">
                <label>Texto do Botão</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-list"></i></span>
                </div>
                <input type="text" name="textoBotao" #textoBotao="ngModel" [(ngModel)]="objeto.menu.textoBotao" class="form-control" placeholder="Texto do botão" required="true"/>
              </div>
                <span class="small help">Você pode adicionar emojis no botão. Exemplo: <strong>Ver cupom 🎟️</strong></span>

                <div *ngIf="textoBotao.invalid" class="invalid-feedback">
                    <div *ngIf="textoBotao.errors.required">
                        Texto do Botão é obrigatório
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <div class="form-group mb-2">
                    <label>Texto Seção</label>
                    <input type="text" name="tituloMensagem" #textoSecao="ngModel" [(ngModel)]="objeto.menu.textoSecao" class="form-control" placeholder="Título da Mensagem" required="true"/>
                    <span class="small help">Explique o que o cliente pode fazer com as opções. Exemplo: <strong>Escolha uma das opções abaixo:</strong>
                    <br>Importante: Esse campo não aceita campos personalizados, apenas texto fixo.
                </span>

                    <div *ngIf="textoSecao.invalid" class="invalid-feedback">
                        <div *ngIf="textoSecao.errors.required">
                            Texto da Seção
                        </div>
                    </div>
                </div>

                <label class="mr-2">Opções:</label>
                <button class="btn btn-sm btn-blue" type="button" (click)="adicionarOpcao($event)">Adicionar</button>

                <ul class="list-group mt-2">
                  <ng-container *ngFor="let opcao of objeto.menu.opcoes">
                    <li class="list-group-item">
                        <div class="form-group mb-2 pt-1">
                            <input type="text" name="textoOpcao" #textoOpcao="ngModel" [(ngModel)]="opcao.texto" class="form-control" placeholder="Nova Opção"
                                   required="true" appAutoFocus/>
                            <div *ngIf="textoOpcao.invalid" class="invalid-feedback">
                                <div *ngIf="textoOpcao.errors.required">
                                    Texto da opção é obrigatório
                                </div>
                            </div>
                            <div class="alert alert-danger mt-1" *ngIf="erroOpcao">
                                <i class="fas fa-exclamation-triangle text-warning"></i> {{erroOpcao}}
                            </div>
                        </div>
                        <div class="form-group mb-2 pt-1">
                            <label>Resposta automática quando usuário escolher a opção:</label>
                            <app-controle-texto-mensagem [campanha]="opcao"></app-controle-texto-mensagem>
                        </div>
                    </li>
                  </ng-container>

                    <div class="alert alert-success mt-1" *ngIf="msgOpcao">
                      <i class="fas fa-check text-success"></i> {{msgOpcao}}
                    </div>
                </ul>
            </div>
        </ng-container>
    </div>
</ng-container>
