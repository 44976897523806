import { Component, OnInit } from '@angular/core';
import {IntegracaoERPService} from "../../services/integracaoerp.service";
import {ProdutoService} from "../../services/produto.service";
import {LabelSettings} from "@progress/kendo-angular-progressbar";
import {timer} from "rxjs";
import {process, State} from "@progress/kendo-data-query";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-tela-sincronizar-precos',
  templateUrl: './tela-sincronizar-precos.component.html',
  styleUrls: ['./tela-sincronizar-precos.component.scss']
})
export class TelaSincronizarPrecosComponent extends ModalKendo implements OnInit {
  empresa: any;
  ultimaSincronizacao: any;
  produtosSincronizar: any = [];
  produtosFiltrados: any = []
  buscando: boolean
  tudoSincronizado: boolean
  sincronizado: boolean
  erro: any;
  salvando = false;
  filtro: string;
  percentualSalvos: any = 0;
  timerImportacao: any;
  sincronizouSemErros: boolean;
  public label: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center'
  };

  constructor(private integracaoERPService: IntegracaoERPService,
              private produtoService: ProdutoService) {
    super()
  }

  ngOnInit(): void {
    this.ultimaSincronizacao = this.empresa.integracaoDelivery.ultimaSincronizacaoPrecos;

    this.carreguePrecosNovosAsync();
  }

  busqueTodosPrecos(){
    this.tudoSincronizado = false;
    this.carreguePrecosNovosAsync(true);
  }

  async carreguePrecosNovosAsync(todos = false){
    this.buscando = true;

    this.integracaoERPService.inicieSincronizarPrecosAsync(todos).then(
      () => {
        this.timerImportacao =  timer(2000, 1000 * 5  ).subscribe( () => {
          this.verifqueStatusImportacao();
        });
      }
    ).catch( erro => {
      this.erro = erro
      this.buscando = false;
    })

  }

  verifqueStatusImportacao(){
    this.integracaoERPService.obtenhaStatusImportacao().then( (resposta) => {
      if(resposta.processando) return;
      this.pareImportacao(resposta.erro);

     this.produtosFiltrados = this.produtosSincronizar = resposta || [];

      if(!this.produtosSincronizar.length )
        this.tudoSincronizado = true;

    }).catch( erro => {
      this.pareImportacao(erro)
    })
  }

  pareImportacao(erro){
    if(erro)
      this.erro = erro
    this.buscando = false;
    this.timerImportacao.unsubscribe();
  }

  async salvarNovosPrecos() {
    this.salvando = true;
     this.sincronizouSemErros = true;
    for(let i = 0; i <  this.produtosSincronizar.length ; i++){
      let produto = this.produtosSincronizar[i];
      this.produtosFiltrados = [produto];
      await this.salvePreco(produto)

      this.percentualSalvos = Math.floor(( (i + 1) / this.produtosSincronizar.length) * 100)
    }

    this.salvando = false;
    this.sincronizado = true;
    this.produtosFiltrados = [...this.produtosSincronizar]

    if(this.sincronizouSemErros)
      this.produtoService.finalizouSincronizarPrecos().then( (ultimaSincronizacao: any) => {
        this.empresa.integracaoDelivery.ultimaSincronizacaoPrecos = ultimaSincronizacao;
        this.ultimaSincronizacao = ultimaSincronizacao;
      })
  }

  async salvePreco(produto){
    produto.salvando = true;
    delete   produto.erro;
    await this.produtoService.sincronizeProduto( [produto]).catch(erro => {
      produto.salvando = false;
      produto.erro = erro;
    })

    if(!produto.erro){
      produto.salvando = false;
      produto.sincronizado = true;
    } else {
      this.sincronizouSemErros = false;
    }
  }

  fecheModal() {
    super.fecheModal( this.sincronizado )
  }

  onFilter(texto) {
    let estado: State = {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'nome',
            operator: 'contains',
            value: texto
          }
        ],
      }
    };


    this.produtosFiltrados = process(this.produtosSincronizar, estado).data;
  }




  public mostrarComplementos(dataItem: any, index: number): boolean {
    return dataItem.opcoesAtualizadas.length > 0
  }


  public expandInStockProducts({ dataItem, index }: any): boolean {
    if(this.salvando) return false;

    return  dataItem.opcoesAtualizadas.length > 0
  }
}
