import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {EnderecoService} from "../../services/endereco.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {Location} from "@angular/common";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {PedidoLoja} from "../objetos/PedidoLoja";
import {Entrega} from "../objetos/Entrega";
import {FormaDeEntrega} from "../objetos/FormaDeEntrega";
import {Endereco} from "../objetos/Endereco";
import {TelaMesaEscolhidaComponent} from "../tela-mesa-escolhida/tela-mesa-escolhida.component";
import {TelaExpandidaComponent} from "../TelaExpandida";

@Component({
  selector: 'app-tela-endereco-entrega',
  templateUrl: './tela-endereco-entrega.component.html',
  styleUrls: ['./tela-endereco-entrega.component.scss']
})
export class TelaEnderecoEntregaComponent extends TelaExpandidaComponent implements OnInit, AfterViewInit {
  @ViewChild('telaMesaEscolhida') telaMesaEscolhida: TelaMesaEscolhidaComponent;
  estados: any = [];
  cidades: any = [];
  tipoFormaEntrega = FormaDeEntrega;
  pedido: PedidoLoja
  public formatOptions: any = {
    style: 'currency',
    currency: 'BRL'
  };

  @ViewChild('frm', { static: true})  frm: NgForm;

  buscandoCEP = false;
  public mask: any;

  @ViewChild('txtCEP', {static: true}) private txtCEP: any;
  cep: string;
  enderecos = [];
  empresa: any;
  usuario: any;
  telefone: any = '';
  urlRetorno: any;
  tituloPagina: string;
  agendarEntrega: boolean;
  agora: Date = new Date();
  calculandoTaxa: boolean;
  msgErro: string;
  mapFormasDeEntrega: any = {}
  formasDeEntrega: any = []
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
              private enderecoService: EnderecoService, private _location: Location,
              private autorizacaoService: AutorizacaoService, private constantsService: ConstantsService,
              private pedidoLocalService: PedidoLocalService) {
    super();

    this.agora.setHours(0, 0, 0, 0);

    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      this.telefone = queryParams.telefone;
      this.urlRetorno = queryParams.urlRetorno
    });
  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();
    this.pedido = this.pedidoLocalService.obtenhaPedido(this.telefone);

    if(this.pedido.dataEntrega)
      this.agendarEntrega = true;

    this.tituloPagina = this.pedido.id ?  'Editar Pedido #' + this.pedido.codigo : 'Novo Pedido'

    if(!this.pedido.entrega)
      this.pedido.entrega = new Entrega()

    this.pedidoLocalService.salveUrlAtual(this.telefone, this.router.url, window.history.state);

    this.constantsService.empresa$.subscribe( dada => {
      this.empresa = dada || {};
      if(this.empresa.formasDeEntrega ){

        this.empresa.formasDeEntrega.forEach((formaEntrega: any) => {
          this.formasDeEntrega.push(Object.assign({}, formaEntrega));
          if(formaEntrega.permiteComerNoLocal){
            let formaComerNoLocal = Object.assign({}, formaEntrega);

            formaComerNoLocal.comerNoLocal = true;
            formaComerNoLocal.nome = 'Comer no local';

            this.formasDeEntrega.push(formaComerNoLocal)
          }
        })

        if(this.empresa.formasDeEntrega.length === 1)
          this.pedido.entrega.formaDeEntrega = this.empresa.formasDeEntrega[0].descricao

        if(this.empresa.formasDeEntrega.find( forma => forma.bairroOpcional))
          this.pedido.entrega.bairroOpcional = true;

        if(this.empresa.formasDeEntrega.find(forma => forma.cepObrigatorio))
          this.pedido.entrega.cepObrigatorio = true;

        for(let formaDeEntrega of this.empresa.formasDeEntrega)
          this.mapFormasDeEntrega[formaDeEntrega.nome] = formaDeEntrega

      }
    })



  }

  voltar() {
    this.pedidoLocalService.removaUrlSalva(this.telefone);
    this._location.back()
  }


  onSubmit() {
    if(!this.agendarEntrega)
      this.pedido.dataEntrega = null

    this.salvePedido();

    if( this.frm.valid ) {
      this.router.navigate(['/admin/pedidos/novo/pagamento'],
      {queryParams: {telefone: this.telefone,
          idm: this.telaMesaEscolhida.obtenhaIdMesa(),
          urlRetorno: this.urlRetorno}}).then( () => {});
    }

    return false;
  }

  ngAfterViewInit(): void {
    if( this.pedido.contato.id ) {
      this.enderecoService.obtenhaEnderecos(this.pedido.contato).then( (enderecos: any) => {
        const listaDeEnderecos = [];

        for( let i = 0; i < enderecos.length; i++ ) {
          const endereco = enderecos[i];

          let objEndereco: any = Endereco.fromJson(endereco);

          listaDeEnderecos.push(objEndereco);

          if( !objEndereco.descricaoCompleta ) {
            objEndereco.descricaoCompleta = objEndereco.obtenhaEnderecoCompleto();
          }

          objEndereco.cep = this.pedidoLocalService.apliqueMascaraCEP(endereco.cep)

          if(this.pedido.entrega.endereco && (endereco.id === this.pedido.entrega.endereco.id)) {
            Object.assign(objEndereco, this.pedido.entrega.endereco);
            this.pedido.entrega.endereco = objEndereco;

          }
        }

        this.enderecos = listaDeEnderecos;

        this.setEnderecos();
      });
    } else {
      this.setEnderecos();
    }
  }

  setEnderecos() {
    if( this.pedido.novosEnderecos ) {
      for (let i = 0; i < this.pedido.novosEnderecos.length; i++) {
        this.enderecos.push(this.pedido.novosEnderecos[i]);
      }
    }

    if( !this.pedido.entrega.endereco) {
      this.pedido.entrega.endereco = this.enderecos[0];
    } else {
      for(let endereco of this.enderecos)
        if(this.pedido.entrega.endereco.id ===  endereco.id &&
          this.pedido.entrega.endereco.descricao === endereco.descricao)
          this.pedido.entrega.endereco = endereco;
    }
    this.calculeTaxaDeEntrega()
  }

  mudouEstado(estado: any) {
    if( estado == null ) {
      return;
    }

    this.enderecoService.obtenhaCidades(estado).then( (cidades) => {
      this.cidades = cidades;
    })
  }

  alterou($evento: Event) {
    if( this.pedido.entrega.endereco.cep.length < 8 ) {
      return;
    }

    this.buscandoCEP = true;

    this.enderecoService.busquePorCEP(this.unmask(this.pedido.entrega.endereco.cep)).then( (resposta) => {
      this.buscandoCEP = false;
      this.pedido.entrega.endereco.logradouro = resposta.logradouro;
      this.pedido.entrega.endereco.bairro = resposta.bairro;

      const localidade = resposta.localidade;

      const nomeCidade = localidade.split('/')[0];
      const sigla = localidade.split('/')[1];

      this.carregueEstadoECidade(nomeCidade, sigla);

      this.salvePedido();
    }).catch( () => {
      this.buscandoCEP = false;
    });
  }

  unmask(val) {
    return val ? val.replace(/\D+/g, '') : val;
  }

  salvePedido() {
    this.pedidoLocalService.salvePedido(this.pedido);
  }

  private carregueEstadoECidade(nomeCidade: string, sigla: string) {
    for( const estado of this.estados ) {
      if( sigla === estado.sigla ) {
        this.enderecoService.obtenhaCidades(estado).then( (cidades) => {
          this.cidades = cidades;

          for( const cidade of this.cidades ) {
            console.log(cidade);
            if( nomeCidade.toUpperCase() === cidade.nome.toUpperCase() ) {
              this.pedido.entrega.endereco.estado = estado;
              this.pedido.entrega.endereco.cidade = cidade;
              break;
            }
          }
        });

        break;
      }
    }
  }

  abraTelaNovoEndereco() {
    this.salvePedido()
    this.router.navigate(['/admin/pedidos/novo/endereco/novo'],
      {queryParams: {telefone: this.telefone, urlRetorno: this.urlRetorno}});
  }

  editarEndereco() {
    this.router.navigate(['/admin/pedidos/endereco/editar'],
      {queryParams: {telefone: this.telefone, urlRetorno: this.urlRetorno}})
  }

  calculeTaxaDeEntrega() {
    delete this.msgErro;
    if(!this.pedido.entrega.formaDeEntrega)
      return;

    if(this.pedido.entrega.formaDeEntrega === FormaDeEntrega.RETIRAR) {
      this.pedido.entrega.taxaDeEntrega = null;
      return;
    }

    if(this.pedido.entrega.endereco)
      if(this.mapFormasDeEntrega[this.pedido.entrega.formaDeEntrega].tipoDeCobranca === 'zona' &&
        this.pedido.entrega.endereco.zonaDeEntrega === undefined)
        this.editarEndereco()
    else {
        this.calculandoTaxa = true;
        this.enderecoService.calculeTaxaDeEntrega('Receber em casa', this.pedido.entrega.endereco,
          this.pedido.obtenhaSubTotal()).toPromise().then( (resposta: any) => {
          this.calculandoTaxa = false;

          if(resposta.sucesso) {
            let dados = resposta.data
            if(dados.taxaDeEntrega >= 0){
              this.pedido.entrega.setTaxaEntrega(this.pedido.entrega.endereco, dados);
            } else
              this.pedido.entrega.taxaDeEntrega = null;

            if(dados.localizacao)
              this.pedido.entrega.endereco.localizacao = dados.localizacao

          } else {
            this.pedido.entrega.taxaDeEntrega = null;
            this.msgErro = resposta.erro;
          }
        }).catch( erro => {
          this.calculandoTaxa = false;
          this.msgErro = erro;
        })
      }
  }
}
