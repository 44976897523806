import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CampanhaRedeService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  obtenhaCampanhas(tipoDeEnvio: string, status: string = ''): Promise<any> {
    return this.obtenha('/campanha-rede/liste' ,{
      t: tipoDeEnvio,
      s: status
    });
  }

  protected salve(endpoint: string, obj: any): Promise<any> {
    const objeto = JSON.parse(obj.get('data'));

    if ( !objeto.id ) {
      return this.http.post(endpoint, obj)
        .toPromise().then(this.retorno).catch(this.handleError);
    } else {
      return this.http.put(endpoint, obj)
        .toPromise().then(this.retorno).catch(this.handleError);
    }
  }

  salveCampanha(campanha: any): Promise<any> {
    let arquivo = null;

    if( campanha.files && campanha.files.length > 0) {
      arquivo = campanha.files[0].rawFile;
    }

    return super.upload('/campanha-rede', arquivo, campanha);
  }

  desative(campanha: any): Promise<any> {
    return super.salve('/campanha-rede/ative', {id: campanha.id, ativar: false});
  }

  ative(campanha: any): Promise<any> {
    return super.salve('/campanha-rede/ative', {id: campanha.id, ativar: true});
  }

  obtenhaCampanha(id: any) {
    return this.obtenha('/campanha-rede/' + id,{});
  }

  replicarCampanha(campanha: any) {
    return this.facaPost('/campanha-rede/replique', {id: campanha.id});
  }

  empresasQueAceitaram(campanha: any, pendentes: boolean, inicio: number, total: number, q: string) {
    return this.obtenha('/campanha-rede/liste-empresas/' + campanha.id + '?i=' + inicio +
      '&t=' + total + '&q=' + q + '&p=' + pendentes,
      {});
  }
}
