<div class="vh-100">
  <div class="linha-caixa justify-content-center align-items-center h-100">
    <div class="caixa_login">

      <div class="text-center w-75 mx-auto mb-4" *ngIf="empresa.logo">
        <img class="imagem_empresa" src="/images/empresa/{{empresa.logo}}"/>
      </div>

      <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
            novalidate #frm="ngForm" (ngSubmit)="onEnviarVerificacao()"  *ngIf="model.passo == 'email' ">

        <div class="form-group mb-2 col"  >
          <label for="email">E-mail do cadastro</label>
          <input type="email" class="form-control" autocomplete="off" [required]=" true" appAutoFocus
                 id="email" name="email"  [(ngModel)]="model.email" #email="ngModel"
                 placeholder="Email do usuário cadastrado"   >
          <div class="invalid-feedback">
            <p *ngIf="email.errors?.required" >Informe um email</p>
          </div>

        </div>

        <div class="form-group mb-0 text-center col">
          <button class="btn btn-blue btn-block" type="submit" [disabled]="aguardeProcessar" >
            <i class="k-icon k-i-loading" *ngIf="aguardeProcessar"></i>
            Avançar </button>
          <div class="text-danger mt-2" role="alert" *ngIf="erro"  >
            <b> {{erro}}</b>
          </div>

        </div>

      </form>

      <form [ngClass]="{'needs-validation': !frmv.submitted, 'was-validated': frmv.submitted}"
            novalidate #frmv="ngForm"     *ngIf="model.passo == 'codigo'" >

        <h5 class="mb-4 text-center">Insira o código   enviado  para o email: <b>{{model.email}}</b></h5>


        <div class="form-group mb-2 col"  >
          <label for="email">Código de confirmação </label>

          <div class="input-group mb-2  ">

            <input class="form-control codigo-validacao text-center" type="text" minlength="5" mask="0-0-0-0-0"
                   id="codigo" name="codigo"  #codigo="ngModel" (ngModelChange)="alterouCodigo($event)" #codigoEle
                   [(ngModel)]="model.codigo" placeholder="0-0-0-0-0" required  appAutoFocus>


            <div class="invalid-feedback">
              <p *ngIf="codigo.errors?.required">Informe o código de validação de 5 digitos</p>

            </div>
          </div>


        </div>

        <div class="form-group mb-0 text-center col">

          <div class="text-danger mt-2" role="alert" *ngIf="erro"  >
            <b> {{erro}}</b>
          </div>

        </div>

      </form>

      <form [ngClass]="{'needs-validation': !frms.submitted, 'was-validated': frms.submitted}"
            novalidate #frms="ngForm" (ngSubmit)="onRedefinir()"   *ngIf="model.passo == 'senha'" >

        <h5 class="mb-4 text-center">Insira sua nova senha</h5>

        <div class="form-group mb-2 col"  >
          <label for="senha">Nova Senha</label>
          <label   class="col-form-label float-right cpointer text-blue" (click)="exibirSenhaTela()">
            <i class="fa fa-eye fa-lg   " *ngIf="!exibirSenha"></i>
            <i class="fa fa-eye-slash fa-lg   " *ngIf="exibirSenha"></i>
          </label>
          <span *ngIf="!exibirSenha">
            <input class="form-control" type="password"  id="senha" name="senha"  #senha="ngModel"
                   [(ngModel)]="model.senha" placeholder="Informe sua senha"  #senhaEle  required >
            <div  class="invalid-feedback">
              <p  *ngIf="senha.errors?.required">Insira a nova senha</p>
            </div>
        </span>

          <span *ngIf="exibirSenha">
          <input class="form-control" type="text"  id="senhaTexto" name="senhaTexto"  #senhaTexto="ngModel"
                 [(ngModel)]="model.senha" placeholder="Informe sua senha" required>
          <div  class="invalid-feedback">
            <p  *ngIf="senhaTexto.errors?.required">Insira a nova senha</p>
          </div>
        </span>

        </div>

        <div class="form-group mb-0 text-center col">
          <button class="btn btn-success btn-block" type="submit" [disabled]="aguardeProcessar" > Redefinir senha
           <i class="k-i-loading k-icon" *ngIf="aguardeProcessar"></i>
          </button>
          <div class="text-danger mt-2" role="alert" *ngIf="erro"  >
            <b> {{erro}}</b>
          </div>

        </div>

      </form>

      <div class="clearfix mb-4"></div>
    </div>
  </div>
</div>


