import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ImportadorCsvService} from "../services/importador-csv.service";
import {FileRestrictions} from "@progress/kendo-angular-upload";

import {
  PainelImportacaoProdutosComponent
} from "../compartilhado/painel-importacao-produtos/painel-importacao-produtos.component";
import {ModalKendo} from "../lib/ModalKendo";

@Component({
  selector: 'app-importador-csv',
  templateUrl: './importador-csv.component.html',
  styleUrls: ['./importador-csv.component.scss']
})
export class ImportadorCsvComponent extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: true}) frm: NgForm;
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  @Input()   empresa: any = {};
  @Input()   catalogo: any = {};
  importando: boolean;
  carregando: boolean;
  mensagemSucesso: string;
  mensagemErro: string;
  importou: boolean;
  files: any = {};
  colunasRemovidas = [];
  opcoes: any = {}
  tipoImportar = {
    tipo: 'Produto',
    colunas: [
      {descricao: '-'}, {descricao: 'Nome'}, {descricao:  'Preço'}, {descricao : 'Categoria'} , {descricao: 'Descricao'},
      {descricao: 'CodigoPDV'}, {descricao: 'LinkImagem'}, {descricao: 'NomeAdicional'}, {descricao: 'OpcaoAdicional'}]
  }
  restricoes: FileRestrictions = {
    allowedExtensions: ['.csv']
  };
  private arquivo: File;
  conteudoArquivo: any;
  private colunasImportar: any = [];
  private removeuPrimeiraLinha: boolean;
  public somentePrecos: boolean;
  separadorDecimal: any;
  buscarProdutos = true;
  tudoSincronizado = false;
  opcoesSeparadorDecimal: any = [
    {codigo: ',', descricao: "Separado por ','"},
    {codigo: '.', descricao: "Separado por '.'"}
  ]
  constructor( private importador: ImportadorCsvService) {
    super()
  }
  ngOnInit(): void {
    if(this.somentePrecos){
      this. tipoImportar.colunas  = [
        {descricao: 'CodigoPDV'}, {descricao:  'Preço'}
      ]
      this.separadorDecimal = this. opcoesSeparadorDecimal[0]
    }

  }

  feche() {
    this.fecheModal(this.importou)
  }

  onSubmit() {
    this.fecheMensagemErro();
    if(this.frm.valid) {
      if(!this.arquivo) {
        this.mensagemErro = 'É necessário escolher um arquivo antes de iniciar a importação.'
        return;
      }

      if(!this.separadorDecimal) {
        this.mensagemErro = 'É necessário escolher o separador decimal do campo preço antes de iniciar a importação'
        return;
      }


      let opcoes: any = {}
      opcoes.colunas = {}

      opcoes.separadorDecimal = this.separadorDecimal
      let i = 0;

      let selecionouPreco = false, selecionouNome = false, selecionouCodigoPdv = false;

      for(let coluna of this.colunasImportar) {
        if(coluna.coluna) {
          opcoes.colunas[i] = coluna.coluna.descricao

          if(coluna.coluna.descricao === 'Preço')
            selecionouPreco = true;

          if(coluna.coluna.descricao === 'Nome')
            selecionouNome = true;

          if(coluna.coluna.descricao === 'CodigoPDV')
            selecionouCodigoPdv = true;

        }
        i++
      }

      if(!this.somentePrecos){
        if(!selecionouPreco || !selecionouNome) {
          this.mensagemErro = 'É necessário selecionar ao menos uma coluna para Nome e outra coluna para Preço do produto antes de importar'
          return;
        }

        this.importando = true;
        this.importador.importe(this.empresa.id, this.arquivo, opcoes).then((resposta: any) => {
          console.log(resposta)
          this.mensagemSucesso = "Foram importadas " + resposta.categoriasInseridas + " categorias e " + resposta.produtosInseridos + " produtos."
          this.importando = false;
          this.importou = true;

        }).catch((erro: any) => {
          console.log(erro);
          this.mensagemErro = erro;
          this.importando = false;
        })
      } else {
        if(!selecionouPreco || !selecionouCodigoPdv) {
          this.mensagemErro = 'É necessário selecionar ao menos uma coluna para Codigo Pdv e outra coluna para Preço do produto antes de atualizar'
          return;
        }
        this.carregando = true;
        this.importador.obtenhaPrecosCsv(this.catalogo.id, this.arquivo, opcoes).then((resposta: any) => {
          this.carregando = false;
          this.buscarProdutos = false;
          this.tudoSincronizado = !  resposta.categorias.length;

          if(!this.empresa || !this.empresa.id) this.painelImportacao.setModoRede(this.catalogo);
          this.painelImportacao.setProdutosSincronizar([], resposta.produtosAtualizar, [],
            resposta.categorias)

        }).catch((erro: any) => {
          console.log(erro);
          this.mensagemErro = erro;
          this.carregando = false;
        })
      }


    }

  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;

  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }
  volteTela(){
    this.painelImportacao.setProdutosSincronizar([],  [], [], []);
    this.buscarProdutos = true;
  }

  CSVtoArray(text: string) {
    let re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    let re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null;
    let a = [];                     // Initialize array to receive values.
    text.replace(re_value, // "Walk" the string using replace with callback.
      function(m0: any, m1: any, m2: any, m3: any) {
        // Remove backslash from \' in single quoted values.
        if      (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
        // Remove backslash from \" in double quoted values.
        else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
        else if (m3 !== undefined) a.push(m3);
        return ''; // Return empty string.
      });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('');
    return a;
  };

  selecionouArquivo(e: any) {
    this.arquivo = e.files[0].rawFile;
    this.conteudoArquivo = []
    let reader = new FileReader();
    reader.readAsText(this.arquivo, "UTF-8");
    reader.onload =  (evt) => {
      let conteudo: any =  evt.target.result ;

      let linhas = conteudo.match(/[^\r\n]+/g)

      for(let linha of linhas) {
        let colunas =  this.CSVtoArray(linha)

        /*
        let objeto: any = {}

        for(let i = 0; i < colunas.length; i++)
          objeto[i] = colunas[i]*/

        this.conteudoArquivo.push(colunas);
      }
      let i = 0;
      if(this.conteudoArquivo.length > 0)
        this.conteudoArquivo[0].forEach( () => {
          let coluna: any = { indice: i, coluna: (i === 0 ? '_id' : null)};
          this.colunasImportar.push( coluna )
          i++
        })

      this.fecheMensagemErro();
    }
    reader.onerror = (evt) => {
      this.mensagemErro = "Houve um erro ao ler o arquivo."
    }


  }

  removaColuna(item: any) {

  }

  removaLinha(linha: any, ilinha: number) {
    this.removeuPrimeiraLinha = true;
  }
}
