<span class="badge  " [ngClass]="{'badge-info': pedido.status === 'Novo',
                                     'badge-warning': pedido.status === 'Em preparação' ,
                                     'badge-success': pedido.status === 'Saiu para entrega',
                                     'badge-blue': pedido.status === 'Pronto',
                                     'badge-light': pedido.status === 'Entregue',
                                     'badge-secondary': pedido.aguardandoPagamentoOnline,
                                     'badge-danger': pedido.cancelado || pedido.ultimoPagamentoNegado
                                     }" >  <b> {{pedido.status}}</b></span>
  <span *ngIf="pedido.entregador" style="white-space: nowrap; font-size: 75%; text-overflow: ellipsis">🏍️ {{pedido.entregadorReduzido}}</span>

  <i class="k-i-exclamation-circle text-danger k-icon" *ngIf="pedido.disputa"
     kendoTooltip title="foi relatado um problema nesse pedido" > </i>
