
import {TipoDePontuacao} from "./TipoDePontuacao";
import {PontuacaoRegistrada} from "./PontuacaoRegistrada";
import {EnumTipoDePontuacao} from "../lib/emun/EnumTipoDePontuacao";
import {Empresa} from "./Empresa";
declare const global: any;

export class TipoDePontuacaoPorValor extends TipoDePontuacao {
  valorPorPonto: number;

  constructor() {
    super(EnumTipoDePontuacao.PorValor)
  }

  calculePontos(empresa: Empresa, pontuacaoRegistrada: PontuacaoRegistrada): number {
    return Math.floor(pontuacaoRegistrada.valor / this.valorPorPonto);
  }

  clone(): TipoDePontuacao {
    let clone = new TipoDePontuacaoPorValor();

    clone.valorPorPonto = this.valorPorPonto;

    return clone;
  }

  reset() {
    this.valorPorPonto = null;
  }

  getDescricao(plano: any): string {
    return this.getDescricaoAtividades(plano)
  }

  getDescricaoAtividades(plano: any): string {
     let  tipoAcumulo: string = plano.tipoDeAcumulo.toString().toLowerCase().slice(0, -1);

     return '1 ' + tipoAcumulo + ' a cada '   + global.pluralize('real', plano.tipoDePontuacao.valorPorPonto, true) + ' em compras';
  }
}
