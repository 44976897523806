import { Component, OnInit } from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {PedidosService} from "../../services/pedidos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-deliverydetalhes',
  templateUrl: './modal-deliverydetalhes.component.html',
  styleUrls: ['./modal-deliverydetalhes.component.scss']
})
export class ModalDeliverydetalhesComponent extends ModalKendo implements OnInit {
  notificacoes: any =  [];
  pedido: any = {};
  eventos = [];
  carregando = true;
  constructor( private  grupolojasService: GrupolojasService,
              private  pedidosService: PedidosService) {
    super()
  }


  ngOnInit(): void {
    this.pedidosService.listeNotificacaoesLogiscaDoPedido(this.pedido.id).then((notificacoes) => {
      this.carregando = false;
      this.notificacoes = notificacoes;
    }).catch((erro) => {
      this.carregando = false;
      alert(erro);
    })
  }

}
