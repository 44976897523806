import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {EnumOrigemPedido} from "../../../server/lib/emun/EnumOrigemPedido";
import {FiltroPedidoUltis} from "./FiltroPedidoUltis";
import {IOperacoesModuloPedidos} from "../superadmin/services/ioperacoesmodulopedidos";

@Injectable({
  providedIn: 'root'
})
export class PedidosService  extends ServerService  implements IOperacoesModuloPedidos{

  constructor(http: HttpClient) {
    super(http);
  }

  envieCardapio(contato){
    let telefone = encodeURIComponent(contato.codigoPais + contato.telefone);

    return this.salve('/api/envieCardapio?numero=' + telefone + '&nome=' + contato.nome, {})
  }

  envieConfirmacaoPedido(pedido: any){
    let telefone = encodeURIComponent(pedido.contato.codigoPais + pedido.contato.telefone);

    return this.facaPost('/api/envieConfirmacaoPedido?numero=' + telefone, pedido);
  }

  salvePedido(dadosPedido: any, origem: EnumOrigemPedido = null){
    if(origem) dadosPedido.origem = origem;

    return this.salve('/pedidos', dadosPedido)
  }

  altereEntregador(pedido: any, entregador: any) {
    let params: any = { guid: pedido.guid, entregador: entregador};

    return this.facaPut('/pedidos/' + pedido.guid + '/entregador', params)
  }


  altereStatus(pedido: any, status: any, notificar: boolean){
    let params: any = { guid: pedido.guid, status: status};

    if(notificar) params.notificar = true;

    return this.facaPut('/pedidos/' + pedido.guid + '/status', params)
  }

  cancelePedido(pedido: any, motivo: any, notificar: boolean){
    let params: any = { guid: pedido.guid, motivo: motivo};

    if(notificar) params.notificar = true;

    return this.facaPut('/pedidos/' + pedido.guid + '/cancele', params)
  }

  obtenhaStatusCancelamento(pedido: any, idEvent){
    return this.obtenha('/pedidos/' + pedido.guid + '/cancelamento/status/' + idEvent, {})
  }

  obtenhaStatusCancelamentoDelivery(pedido: any, idEvent){
    return this.obtenha('/pedidos/' + pedido.guid + '/cancelamento/status/' + idEvent, {de: 1})
  }

  marqueFoiImpresso(pedido: any, impressaoAutomatica: boolean = false){
    return this.facaPut('/pedidos/' + pedido.guid + '/impresso', { impressaoAutomatica: impressaoAutomatica})
  }

  finalizePedidoMesa(pedido: any, pagamento: any){

    return this.facaPut('/pedidos/' + pedido.id + '/finalize-mesa', pagamento)

  }
  foiVisualizado(pedido: any, idEmpresa: any = null){
    return this.salve('/pedidos/' + pedido.id + '/visualizado',  pedido)
  }

  altereFoiPago(pedido: any, foiPago: boolean, formaDePagamento: any){
    return this.salve('/pedidos/' + pedido.id + '/pago', { id: pedido.id, pago: foiPago, formaDePagamento: formaDePagamento})
  }

  listePedidosContatoEncerrados(inicio: number, total: number, contato: any = {}){
    let filtro = { apenasEncerrados: true , contato: contato, orderByDesc: true};

    return this.listePedidos(inicio, total,  filtro)
  }

  listePedidosContatoAbertos(inicio: number, total: number, contato: any = {}){
    let filtro = { emAberto: true , contato: contato};

    return this.listePedidos(inicio, total,  filtro)
  }

  listePedidos(inicio: number, total: number, filtro: any = {},
               filtroPedido: EnumFiltroDePedidos = EnumFiltroDePedidos.todos){
    let dados: any = FiltroPedidoUltis.obtenhaDados(inicio, total, filtro, filtroPedido)

    let url = '/pedidos';

    if(filtro.ultimoPedido)
      url = String(`${url}/novos`)
    else if(filtro.ultimaAtualizacao)
      url = String(`${url}/atualizados`)

    return this.obtenha(url, dados);
  }


  listeUltimoPedidosAtras(tempo: number, origens = []){
    return this.obtenha('/pedidos/ultimo/' + tempo, { o: origens.join(',')});
  }

  listePedidosCancelados(inicio: number, total: number, contato: any = null,  dtInicio: string = null, dtFim: string= null,
                         filtro: EnumFiltroDePedidos = EnumFiltroDePedidos.todos, q: string){
    let dados: any = { i: inicio, t: total, cancelados: true};

    if(contato) dados.cid = contato.id;
    if(dtInicio) dados.di = dtInicio;
    if(dtFim) dados.df = dtFim;
    dados.q = q;
    dados.f = filtro;

    return this.obtenha('/pedidos', dados);
  }

  listePedidosMesaComErro(){
    let dados: any = { i: 0, t: 100, errIn: true, f: EnumFiltroDePedidos.comanda, a: true, odbd: true};

    return this.obtenha('/pedidos', dados);
  }

  listeIntegradosComErro(inicio: number, total: number, filtro: any = {}){
    filtro.i = inicio
    filtro.t = total

    return this.obtenha('/pedidos/integrados/erros', filtro);
  }

  listeUltimoPedidosIntegradoErros(tempo: number, origens = []){
    return this.obtenha('/pedidos/integrados/erros/ultimo/' + tempo, { o: origens.join(',')});
  }

  listeFalhasImportacao(inicio = 0, total= 10){
    return this.obtenha('/pedidos/integrados/importacao/falhas' , {   i: inicio, t: total });
  }

  marqueFalhaImportacaoLida(falhaIntegracao: any){
    return this.facaPut('/pedidos/integrados/importacao/falhas/' + falhaIntegracao.id + '/lida' , falhaIntegracao);
  }

  reimportePedidoIfood(falhaIntegracao: any){
    return this.facaPost('/ifood/reimporte/order/' + falhaIntegracao.payload.id   , falhaIntegracao);
  }

  obtenhaPorCodigo(guid: any , idEmpresa: any = null, imprimir = null) {
    return this.obtenha('/pedidos/chave/' + guid, { imprimir: imprimir ? 1 : 0 });
  }

  obtenhaComandaComoPedido(id: any) {
    return new Promise( (resolve, reject) => {
      this.obtenha('/pedidos/comanda/' + id, { completo: true }).then( (comanda) => {
        comanda.empresa = comanda.empresa;

        const lista = [];

        let subValor = 0
        let total = 0

        for(let i = 0; i < comanda.pedidos.length; i++ ) {
          const pedido = comanda.pedidos[i];
          for(let j = 0; j < pedido.itens.length; j++ ) {
            const item = pedido.itens[j];

            item.pedido = pedido;

            lista.push(item);
          }

          if(!pedido.cancelado){

            subValor += pedido.subvalor
            total += pedido.total
          }


        }

        comanda.itens = lista;
        if( comanda.pedidos.length > 0 )
        comanda.horarioDescricao = comanda.pedidos[0].horarioDescricao;
        comanda.taxaEntrega = 0
        comanda.subvalor = subValor
        comanda.total = total

        if(comanda.desconto)
          comanda.total = comanda.total - comanda.desconto

        resolve(comanda);
      }).catch( erro => {
        reject(erro);
      });
    });
  }

  obtenhaPedidosComanda(id: any) {
    return this.obtenha('/pedidos/comanda/' + id, { completo: true });
  }

  configureAdicionaisDoPedido(pedido) {
    for (let item of pedido.itens) {
      item.adicionais = []
      if (item.adicionaisEscolhaSimples && item.adicionaisEscolhaSimples.id) {
        for (let campo in item.adicionaisEscolhaSimples)
          if (campo.startsWith('campo'))
            item.adicionais.push({ opcao: item.adicionaisEscolhaSimples[campo], qtde: 1})
      }
      if(item.adicionaisMultiplaEscolha && item.adicionaisMultiplaEscolha.id) {
        for (let campo in item.adicionaisMultiplaEscolha)
          if (campo.startsWith('lista'))
            for(let valoresOpcao of item.adicionaisMultiplaEscolha[campo].opcoes)
              item.adicionais.push(valoresOpcao)
      }

    }
  }

  aceitePedido(pedido: any){
    return this.salve(String(`/pedidos/${pedido.guid}/aceitar`), pedido)
  }

  obtenhaPorGuid(codigo: any) {
    return this.obtenha('/pedidos/chave/' + codigo, {  });
  }

  fecheComanda(comanda: any) {
    return this.facaPost('/pedidos/comanda/feche', comanda);
  }

  enviePrefechamentoComanda(comanda: any){
    return this.facaPost('/integracaoerp/comanda/prechamento', comanda);
  }

  atualizeCobrarTaxa(comanda: any) {
    return this.facaPost('/pedidos/comanda/taxa', comanda);
  }

  vinculeGarcomOuCodigoComanda(comanda: any){
    return this.facaPost('/pedidos/comanda/garcom', {id: comanda.id, garcom: comanda.garcom, codigoPdv:  comanda.codigoPdv });
  }


  calculeDescontoCupom(codigoCupom: string, pedido: any) {
    if(!codigoCupom) return Promise.resolve(null);

    return this.http.post('/cupons/desconto/', {pedido: pedido, codigo: codigoCupom})
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  rejeitePedido(pedido: any, motivo: any = {}){
    return this.salve(String(`/pedidos/${pedido.guid}/rejeitar`), {id: pedido.id, motivo: motivo || {}})
  }

  finalizeDisputaComAceitar(pedido: any){
    return this.salve(String(`/pedidos/${pedido.guid}/disputa/aceitar`), {id: pedido.id })
  }

  finalizeDisputaComRejeitar(pedido: any, motivo: string){
    return this.salve(String(`/pedidos/${pedido.guid}/disputa/rejeitar`), {id: pedido.id , motivo: motivo})
  }

  enviePropostaDisputa(pedido: any, acao: any, valor: number){
    return this.salve(String(`/pedidos/${pedido.guid}/disputa/proposta`), {id: pedido.id , acao: acao, valor: valor})
  }

  notifiqueSistemaIntegrado(pedido: any){
    return this.facaPost('/pedidos/integracao/notifique', { id: pedido.id});
  }

  obtenhaProdutosVendidoContato(contato: any, inicio = null, fim = null) {
    let link = '/produtos/vendas/resumo/' + contato.id

    let query = []

    if(inicio)
      query.push('inicio=' + inicio)

    if(fim)
      query.push('fim=' + fim)

    if(query.length > 0)
      link += '?' + query.join('&')


    return this.obtenha(link, {});
  }

  listeEventosPedido(guid) {
    return this.obtenha(String(`/opendelivery/pedidos/${guid}/events`), {});
  }

  listeNotificacaoesLogiscaDoPedido(guid) {
    return this.obtenha(String(`/opendelivery/pedidos/${guid}/events/logistic`), {});
  }

  listeNotificacaoesUberDoPedido(guid) {
    return this.obtenha(String(`/uberdirect/pedidos/${guid}/notificacoes`), {});
  }

  listeNotificacaoesIfoodDelivery(guid){
    return this.obtenha(String(`/ifood/pedidos/${guid}/notificacoes/delivery`), {});
  }

  soliciteParceiroOpenDelivery(pedido: any) {
    return this.facaPost(String(`/opendelivery/pedidos/${pedido.guid}/novaEntrega`), {});
  }

  soliciteFoodDelivery(pedido: any) {
    return this.facaPost(String(`/integracaoerp/foodydelivery/pedidos/${pedido.guid}/novaEntrega`), {});
  }

  soliciteEntregadorIfood(pedido:  any, idLoja: any){
    return this.facaPost(String(`/ifood/pedidos/${pedido.guid}/novaEntrega`), {idLoja: idLoja});
  }

  canceleCotacaoDeliveryIfood(pedido: any){
    return this.facaPost(String(`/ifood/pedidos/${pedido.guid}/canceleCotacao`), { });
  }

  soliciteCotacaoIfood(pedido:  any, idLoja: any){
    return this.facaPost(String(`/ifood/pedidos/${pedido.guid}/novaCotacao`), {idLoja: idLoja});
  }



  envieRespostaAlteracaoEndereco(pedido:  any, aceitou: boolean, parceiro = 'ifood'){
    return this.facaPost(String(`/${parceiro}/pedidos/${pedido.guid}/respostaAlteracaoEndereco`), {aceitou: aceitou ? 1 : 0});
  }


  listeNotificacaoesFoodydelivery(guid) {
    return this.obtenha(String(`/integracaoerp/foodydelivery/pedidos/${guid}/notificacoes`), {});
  }


  soliciteUberDirect(pedido: any, acaoRetorno: any) {
    let descartar = acaoRetorno && acaoRetorno.id === 2;

    return this.facaPost(String(`/uberdirect/pedidos/${pedido.guid}/novaEntrega`), { descartar: descartar});
  }

  sincronizeEntrega(pedido: any){
    if(pedido.delivery.origem === 'uber'){
      return this.obtenha(String(`/uberdirect/pedidos/${pedido.guid}/delivery/sincronize`), {})
    } else  if(pedido.delivery.origem === 'foodydelivery'){
      return this.obtenha(String(`/integracaoerp/foodydelivery/${pedido.guid}/delivery/sincronize`), {})
    } else {
      return this.obtenha(String(`/opendelivery/pedidos/${pedido.guid}/delivery/sincronize`), {})
    }
  }

  canceleEntrega(pedido: any, dados = {}){
    if(pedido.delivery.origem === 'uber'){
      return this.facaPost(String(`/uberdirect/pedidos/${pedido.guid}/delivery/cancele`), dados)
    } else  if(pedido.delivery.origem === 'foodydelivery'){
      return this.facaPost(String(`/integracaoerp/foodydelivery/pedidos/${pedido.guid}/cancele`), dados)
    } else  if(pedido.delivery.origem === 'ifood'){
      return this.facaPost(String(`/ifood/pedidos/${pedido.guid}/delivery/cancele`), dados)
    } else {
      return this.facaPost(String(`/opendelivery/pedidos/${pedido.guid}/delivery/cancele`), dados)
    }
  }

  verDelivery(pedido: any){
    if(pedido.delivery.origem === 'uber'){
      window.open(String(`/uberdirect/delivery/${pedido.delivery.codigo}`))
    } else  if(pedido.delivery.origem === 'foodydelivery'){
      window.open(String(`/integracaoerp/foodydelivery/${pedido.guid}`))
    }  else     if(pedido.delivery.origem === 'ifood'){
      window.open(String(`/ifood/${pedido.idLojaExterna}/order/${pedido.referenciaExterna}`))
    } else {
      window.open(String(`/opendelivery/delivery/${pedido.guid}`))
    }
  }

  obtenhaRazoesCancelamentoIfood(pedido: any){
    if(!pedido || !pedido.referenciaExterna) return Promise.resolve([])

    return this.obtenha(String(`/ifood/order/${pedido.referenciaExterna}/razoesCancelamento/${pedido.idLojaExterna}`), {})
  }

  listeHistoricoRequests(id: any) {
    return this.obtenha(String(`/pedidos/${id}/request/historico`), {})
  }

  listeHistorico(id: any){
    return this.obtenha(String(`/pedidos/${id}/historico`), {})
  }

  async sincronizePagamentoOnline(pedido: any) {
    let pagamentoOnline = pedido.pagamentos.find((item: any) => item.formaDePagamento.online);

    if(!pagamentoOnline) return Promise.resolve(null);

    return this.obtenha(`/pedidos/${pedido.guid}/pagamento/${pagamentoOnline.id}/sincronize/${pagamentoOnline.statusNumero}`, {})

  }
}

export enum EnumFiltroDePedidos {
  comanda = 'comandas',
  pedidos = 'pedidos',
  todos = 'todos',
  agendados = 'agendados'
}
