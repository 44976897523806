import {Component, OnInit, ViewChild} from '@angular/core';
import {ContatosService} from '../services/contatos.service';
import {NovoContatoComponent} from "../novo-contato/novo-contato.component";
import {EnumStatusContato} from "../../../server/lib/emun/EnumStatusContato";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {UsuarioService} from "../services/usuario.service";
import {FiltroAvancadoComponent} from "../componentes/filtro-avancado/filtro-avancado.component";
import {Router} from "@angular/router";
import {PlanosService} from "../services/planos.service";
import {AutorizacaoService} from "../services/autorizacao.service";
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {KendoPopupUtils} from "../lib/KendoPopupUtils";
import {FiltroTelaUI} from "../../../projects/loja/src/objeto/FiltroTelaUI";
declare var _;
@Component({
  selector: 'app-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.scss']
})
export class ContatosComponent implements OnInit {
  @ViewChild('filtroComponent')  filtroComponent: FiltroAvancadoComponent;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  resumo = {total: 0, qtdeAtivos: 0 , qtdeInativos: 0, qtdeNovo: 0 };
  contatos: Array<any> = [];
  paginacao: any = { totalPorPagina: 10, pagina: 1, total: 0, skip: 0 };
  EnumStatusContato: any;
  filtro: any = { id: -1, dados: {}, nome: 'Todos os contatos' }
  filtros: any  = [ this.filtro  ];
  podeExportar = false;
  modalSalvarFiltro = false;
  erroSalvarFiltro: string;
  carregando = true;
  plano: any = {};
  planos = [];
  abraDialogo: boolean;
  contatoSelecionado: any;
  usuarioLogado: any;

  constructor(private  contatoService: ContatosService,
              private dialogService: DialogService,
              private autorizacaoService: AutorizacaoService,
              private  usuarioService: UsuarioService,  private planosService: PlanosService,
              private router: Router) {
    this.EnumStatusContato = EnumStatusContato;
  }

  ngOnInit() {
    this.planosService.listePlanos().then( planos => {
      this.planos = planos;
      this.setPlanoPadrao();
      this.filtroComponent.setPlanos(this.planos);
      this.filtreContatos();
      this.usuarioService.listeFiltros().then( filtros => {
        this.filtros = _.union(this.filtros, filtros)
      })
    });

    let usuarioLogado = this.autorizacaoService.getUser();
    this.usuarioLogado = usuarioLogado
    this.podeExportar = usuarioLogado && !usuarioLogado.operador;

  }

  setPlanoPadrao(){
    if(this.planos.length === 1)
      this.plano = this.planos[0];
    else
      this.plano = {}
  }

  busqueContatos(inicio, cb: Function) {
    if(this.planos.length > 1 && this.filtro.dados.plano){
       this.plano =  _.findWhere(this.planos, { id: this.filtro.dados.plano.valor.value}) || {}
    } else {
      this.setPlanoPadrao();
    }
    let params = this.getParamsFiltro();
    this.contatoService.listeContatos(inicio, this.paginacao.totalPorPagina, params).then( contatos => {
      this.contatos = contatos;
      cb();
    })
  }

  filtreContatos() {
    if(!this.filtro)
       this.filtro = this.filtros[0];

    this.carregando = true;
    this.busqueContatos(0 , () => {
      let params = this.getParamsFiltro();
      this.contatoService.obtenhaResumo(params).then( resumo => {
        this.resumo = resumo;
        this.paginacao.total = this.resumo.total;
        this.carregando = false;
      });
    })
  }

  paginacaoMudou(novaPagina) {
    this.paginacao.skip = novaPagina.skip;
    const pagina = novaPagina.skip / this.paginacao.totalPorPagina;

    const inicio = (pagina) * this.paginacao.totalPorPagina  ;
    this.busqueContatos(inicio , () => {});
  }

  adcionarPontos(contato: any) {
    this.router.navigateByUrl('/admin/contatos/pontos/adicionar',
      { state: {contato: contato, cartao: this.getCartaoPlano(contato)}});
  }

  criarCartao(contato: any){
    this.router.navigateByUrl('/admin/contatos/novo',
      { state: { contato: contato }});
  }

  getCartaoPlano(contato){
    if(!this.plano.id) return null;

    return _.find(contato.cartoes, (cartao) => cartao.plano.id === this.plano.id)
  }

  verContato(contato: any){
    let link = '/admin/contatos/'

    if(contato.telefone)
      link = String(`${link}/${contato.codigoPais}${contato.telefone}`)
    else
      link = String(`${link}/cpf/${contato.cpf}`)

    this.router.navigate([link], { state: { contato: contato }});
  }

  trocarPontos(contato: any) {
    this.router.navigateByUrl('/admin/contatos/pontos/trocar' ,
      { state: { contato: contato, cartao: this.getCartaoPlano(contato)}});
  }

  editarContato(contato: any) {
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: NovoContatoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.exibaContato(contato);

    windowRef.result.subscribe((result: any) => {
        if(result && result.id) {
            contato = Object.assign(contato, result);
        }
    }, (reason) => {

    });
  }

  novoCartao() {
    this.router.navigateByUrl('/admin/contatos/novo');
  }

  temFiltro() {
    return  Object.keys(this.filtro.dados).length > 0;
  }

  exibaFiltroAvancado() {
    this.filtroComponent.setFiltroAvancado(this.filtro.dados);
    this.filtro.exibir = true;

  }

  onFiltrarAvancado(dados) {
    if( this.filtro.id === -1 ){
      let novoFiltro = { dados: dados, nome: 'Novo filtro', salvar: true};
      this.filtros.push(novoFiltro);
      this.filtro = novoFiltro;
    } else {
      this.filtro.dados = dados;
      this.filtro.salvar = true;
    }

    this.filtro.exibir = false;
    this.filtreContatos();
  }

  getDescricaoFiltro(filtro: any){

   if(typeof filtro === 'string' ) return  'Buscando por: \"' + this.filtro.q + "\""

    let label = FiltroTelaUI.getLabel(filtro);

    if (!(label === true || label === false)) {
      return filtro.nome + ": " + label
    } else {
      return filtro.nome;
    }
  }

  getParamsFiltro(){
    if(this.filtro.q && this.filtro.q !== '') this.filtro.dados.q =  this.filtro.q;
    return FiltroTelaUI.getParamsRequest(this.filtro.dados);
  }

  abraModalSalvarFiltro() {
    if(!this.filtro.id ) this.filtro.nome = '';

    this.modalSalvarFiltro = true;
  }

  fecheModalFiltro() {
    this.modalSalvarFiltro = false;
  }

  salveFiltro() {
    delete this.erroSalvarFiltro;
    if(!this.filtro.nome) {
      this.erroSalvarFiltro = 'informa um nome para o filtro';
      return;
    }
    this.usuarioService.salveFiltro(this.filtro).then( resp => {
       this.filtro.id = resp.id;
       this.fecheModalFiltro();
    }).catch( (erro) => {
        this.erroSalvarFiltro = erro;
    })
  }

  limpeFiltros(){
    this.filtro.dados = {};
    delete this.filtro.id;
    this.filtreContatos();
  }

  removerFiltro(key: any) {
    delete this.filtro.dados[key.toString()]
    delete this.filtro[key];
    this.filtreContatos();

  }

  downloadCsv() {
    let link = '/exportar/contatos/csv?';
    let params = this.getParamsFiltro();

    for(let k in params)
      link += k  + "=" + params[k] + "&";

    window.open(link );
  }

  remover(contato: any) {
    this.contatoSelecionado = contato;
    this.abraDialogo = true;
  }


  apagueiContato() {
    this.filtreContatos()
  }
}
