import {Component, Input, OnInit} from '@angular/core';
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {DialogService} from "@progress/kendo-angular-dialog";
import {FormTemplateComponent} from "../../compartilhado/form-template/form-template.component";
import {TemplatesService} from "../../services/templates.service";

@Component({
  selector: 'app-templates-de-mensagem',
  templateUrl: './templates-de-mensagem.component.html',
  styleUrls: ['./templates-de-mensagem.component.scss']
})
export class TemplatesDeMensagemComponent implements OnInit {
  paginacao = {
    size: 10,
    skip: 0,
    total: 0,
    buttonCount: 5,
    info: true,
    type: "numeric"  ,
    pageSizes: false,
    previousNext: true
  }
  templatesDeMensagem: any = [];
  carregando: boolean;


  constructor(private dialogService: DialogService,
              private templatesService: TemplatesService) { }

  ngOnInit(): void {
    this.templatesService.todos().then((templates => {
      this.templatesDeMensagem = templates
    }))
  }

  editarTemplate({ sender, rowIndex, dataItem }) {
    this.abraModalTemplateDeMensagem(dataItem)
  }

  mudouPaginacao($event: PageChangeEvent) {

  }

  abraModalTemplateDeMensagem(template) {
    let altura = window.innerHeight;

    const windowRef: any = this.dialogService.open({
      title: 'Cadastrar Template de Mensagem',
      content: FormTemplateComponent,
      minWidth: 600,
      width: window.innerWidth > 1424 ? 1024 : (window.innerWidth - 400  < 600 ? 600 : window.innerWidth - 400),
      maxHeight: altura
    });
    const telaCadastrarTemplate: FormTemplateComponent = windowRef.content.instance;
    telaCadastrarTemplate.template = template
    telaCadastrarTemplate.windowRef = windowRef

    windowRef.result.subscribe((result: any) => {
      if(result && result.id){
        this.carregando = true;
        template.id = result.id
        this.templatesDeMensagem = [];
        this.templatesService.todos().then( (resposta) => {
          this.carregando = false;
          this.templatesDeMensagem = resposta
        })
      }

    });

  }

  novoTemplateDeMensagem() {
    this.abraModalTemplateDeMensagem({nome: "", mensagem: ""})
  }
}
