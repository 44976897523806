<div style="position: relative;">
    <div style="position: absolute;right: 0px;top: -40px;">
      <kendo-dropdownbutton [data]="campos" textField="nome" valueField="nome" (itemClick)="adicioneCampo(textArea, $event)">
        Adicionar Campo <i class="fa fa-caret-down "></i>
      </kendo-dropdownbutton>
    </div>


    <textarea class="form-control" id="txtMensagem" rows="2" name="txtMensagem" #txtMensagem="ngModel" #textArea [(ngModel)]="_value"
              required appAutoFocus (input)="resize(textArea)"  (ngModelChange)="alterouMensagem()" maxlength="1000" (focusin)="resize(textArea)"></textarea>
    <div>
      <span [ngClass]="{'text-warning': qtdeCaracteresRestantes < 50, 'text-success': qtdeCaracteresRestantes >= 50, 'text-danger': qtdeCaracteresRestantes < 20}">{{qtdeCaracteresRestantes}} caracteres restantes</span>
    </div>
    <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched )" class="invalid-feedback">
      <div *ngIf="txtMensagem.errors.required">
        Informe uma mensagem que será enviada ao cliente.
      </div>
    </div>
</div>
