import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ImportadorIfoodService} from "../../services/importador-ifood.service";
import {PainelImportacaoProdutosComponent} from "../painel-importacao-produtos/painel-importacao-produtos.component";
import {ArmazenamentoService} from "../../services/armazenamento.service";
import {ModalKendo} from "../../lib/ModalKendo";
declare var _;
@Component({
  selector: 'app-tela-sincronizar-ifood',
  templateUrl: './tela-sincronizar-ifood.component.html',
  styleUrls: ['./tela-sincronizar-ifood.component.scss']
})
export class TelaSincronizarIfoodComponent  extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: false}) frm: NgForm;
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  empresa: any = {};
  mensagemSucesso: any;
  mensagemErro: any;
  opcoes: any = { link: null};
  buscando = false;
  filtro: any;
  buscarProdutos: boolean
  buscouProdutos: boolean;
  tudoSincronizado: boolean;
  sincronizando: boolean;
  sincronizou: boolean;
  KEYLINKLOJA = '#linklojaifood'
  empresaNoIfood: any;
  constructor( private importador:  ImportadorIfoodService,
              private armazenamentoService: ArmazenamentoService) {
    super()

  }

  ngOnInit(): void {
   this.buscarProdutos = true

    let linkIfood = this.armazenamentoService.carregue(this.KEYLINKLOJA, false)

   if(linkIfood){
     this.opcoes.link = linkIfood;
   }
  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  feche() {
    this.fecheModal(this.painelImportacao.importou)
  }

  onBusqueProdutos() {
    if(this.buscando) return;

    delete this.mensagemErro;
    delete this.mensagemSucesso;
    delete this.tudoSincronizado;

    this.buscando = true;
    this.importador.listeNovosProdutosIfood(this.opcoes.link, this.opcoes.reducao, this.opcoes.sincronizarPrecos,
      this.opcoes.sincronizarImagens).then (resposta => {
      this.buscouProdutos = true;
      this.empresaNoIfood   = resposta.empresaNoIfood;
      if(resposta.novosProdutos.length || resposta.produtosAtualizar.length){
        this.buscarProdutos = false;
        this.painelImportacao.setProdutosSincronizar(resposta.novosProdutos, resposta.produtosAtualizar, [],
          resposta.categorias)
      } else {
        this.tudoSincronizado = true;
      }

      this.buscando = false;
      this.armazenamentoService.salveSemExpirar(this.KEYLINKLOJA, this.opcoes.link)
    }).catch( erro => {
      this.buscando = false;
      this.mensagemErro = erro;
    })
  }

  onFilter(texto: any){

  }

  obtenhaFormatoPercentual(){
    return String(`0"%"`);
  }

  sincronizarTodos() {

  }

  submitFormBusca() {
    this.frm.ngSubmit.emit()
  }
}
