import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EditEvent, PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {CadAdicionalProdutoComponent} from "../../cad-adicional-produto/cad-adicional-produto.component";
import {ProdutoService} from "../../../services/produto.service";
import {SortableComponent} from "@progress/kendo-angular-sortable";

@Component({
  selector: 'app-lista-adicionais',
  templateUrl: './lista-adicionais.component.html',
  styleUrls: ['./lista-adicionais.component.scss']
})
export class ListaAdicionaisComponent implements OnInit {
  @Input() objetoComAdicionais: any;
  @Input() tela: any;
  @Input() servicoSalvarAdicional: any;
  @Input() naoPermiteCopiar = false;
  @Input() catalogo: any;
  @Input() empresa: any;
  @Input()  templatesPiza: any;
  @ViewChild('sortable', { static: false}) public sortable: SortableComponent;

  carregando: boolean;
  abrirDialogAdicional: boolean;
  abraDialogoRemover: boolean;
  adicionalRemover: any;
  adicionalRemoverIndex: number;
  copiarAdicional: boolean;

  urlSalvar = '/produtos';

  @ViewChild('cadAdicionalProduto',  { static: false}) cadAdicionalProduto: CadAdicionalProdutoComponent;
  clonarAdicional: boolean;
  podeEditarProdutos: boolean;

  constructor(private produtoService: ProdutoService) { }

  ngOnInit(): void {
    this.podeEditarProdutos = !this.empresa || !this.empresa.id || !this.catalogo || !this.catalogo.compartilhado

  }

  editarAdicional(dataItem) {
    this.abrirDialogAdicional  = true;
    setTimeout( () => {
      console.log(dataItem)
      this.cadAdicionalProduto.exibaAdicional(dataItem)
    });
  }

  removerAdicional(dataItem, rowIndex) {
    this.adicionalRemover = dataItem
    this.adicionalRemoverIndex = rowIndex
    this.abraDialogoRemover = true;
  }

  novoAdicional() {
    this.abrirDialogAdicional = true;
  }

  novoAdicionalProduto(){
    this.abrirDialogAdicional = true;
    setTimeout(() => {
      this.cadAdicionalProduto.setComoCombo()
    })
  }

  exibaCopiarAdicionalProduto(clonar: boolean) {
    this.copiarAdicional = true;
    this.clonarAdicional = clonar;
  }

  encerrouEdicaoDeAdicional(adicionalDeProduto: any) {
    this.abrirDialogAdicional = false;
  }

  fecharDialogRemover() {
    this.abraDialogoRemover = false;
  }

  copiouAdicionais(novosAdicionais: any) {
    this.copiarAdicional = null;
    if(novosAdicionais && novosAdicionais.length)
      novosAdicionais.forEach( adicional => this.objetoComAdicionais.camposAdicionais.push(adicional))
  }

  confirmouRemoverAdicional() {
    this.fecharDialogRemover();
    this.adicionalRemover.removendo = true;

    this.servicoSalvarAdicional.removaAdicional(this.objetoComAdicionais, this.adicionalRemover).then( (resp) => {
      this.adicionalRemover.removendo = false;
      this.objetoComAdicionais.camposAdicionais.splice(this.adicionalRemoverIndex, 1);
      this.sortable.removeDataItem(this.adicionalRemoverIndex);
      this.adicionalRemover = null;
      this.adicionalRemoverIndex = null;
    }).catch( erro => {
      this.adicionalRemover.removendo = false;
      alert(erro ? erro : "Não foi possível remover adicional")
    })
  }

  carregue($event: PageChangeEvent) {   }

  onDragOver($event: any) {
    $event.preventDefault();
  }

  onNavigate($event: any) {

  }

  atualizeOrdem() {
    for(let i = 0; i < this.objetoComAdicionais.camposAdicionais.length; i++)
      this.objetoComAdicionais.camposAdicionais[i].ordem = i;

  }

  onDragEnd($event: any) {
    if($event.index < 0) return $event.preventDefault();

    this.sortable.moveItem($event.oldIndex, $event.index)
    console.log(this.objetoComAdicionais.camposAdicionais)
    this.atualizeOrdem()

    this.servicoSalvarAdicional.atualizeOrdemAdicionais(this.catalogo,
      this.objetoComAdicionais, this.objetoComAdicionais.camposAdicionais).then(() => {

    }).catch((error) => {
      console.log(error)
      this.sortable.moveItem($event.index, $event.oldIndex)
      this.atualizeOrdem()
    })

    /*
    let temp = this.objetoComAdicionais.camposAdicionais[$event.oldIndex]
    this.objetoComAdicionais.camposAdicionais.splice($event.oldIndex, 1)
    this.objetoComAdicionais.camposAdicionais.splice($event.index, 0, temp)*/

  }

  obtenhaAlgumasOpcoes(opcoesDisponiveis: any) {
    if(!opcoesDisponiveis) return []
    return opcoesDisponiveis.slice(0, 10)
  }
}
