export class FiltroTelaProdutos{
  constructor(public temEstoque: boolean, public categoria: any, public disponibilidade: any,
              public texto: string, public semCodigoPdv: boolean = false,
              public inicio: number = null, public total: number = null,
              public rede: boolean = false, public tipo: string = null,
              public tamanho: any = null, public notIn = [], public apenasNaoSincronizados: boolean = false) {

  }
  toQueryString(){
    let  filtros = []

    if(this.temEstoque)
      filtros.push(String(`temEstoque=true`));

    if(this.inicio != null)
      filtros.push(String(`i=${this.inicio}&t=${this.total}`))

    if(this.texto)
      filtros.push(String(`q=${this.texto}`))

    if(this.categoria && this.categoria.id)
      filtros.push(String(`idc=${this.categoria.id}`))

    if(this.disponibilidade && this.disponibilidade.length > 0)
      filtros.push(String(`disp=${this.disponibilidade.map((item: any) => item.id).join(',')}`))

    if(this.semCodigoPdv)
      filtros.push('scpdv=1')

    if(this.rede)
      filtros.push('rede=1')

    if(this.tipo) filtros.push('tipo=' + this.tipo);

    if(this.tamanho) filtros.push('tam=' + this.tamanho.id);

    if(this.notIn && this.notIn.length) filtros.push('notin=' + this.notIn.join(','))

    if(this.apenasNaoSincronizados)
      filtros.push('ans=1')


    return filtros.length ? ("?" +  filtros.join('&')) : ''
  }
}
