import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ProdutoService} from "../../services/produto.service";

@Component({
  selector: 'app-cad-produto-simples',
  templateUrl: './cad-produto-simples.component.html',
  styleUrls: ['./cad-produto-simples.component.scss']
})
export class CadProdutoSimplesComponent implements OnInit {
  @ViewChild('frm', {static: true}) frm: NgForm;
  @Output() onSalvou = new EventEmitter();
  @Input() empresa: any;
  produto: any = { temEstoque: true};
  erro: any;

  constructor(private produtoService: ProdutoService) { }

  ngOnInit() {
  }

  onSubmit() {
    delete this.erro;
    if(this.frm.valid){
      this.produto.catalogo = this.empresa.catalogo;
      this.produtoService.salveProduto(this.produto).then( resposta => {
        if(!this.produto.id) this.produto.id = resposta.id;
        this.onSalvou.emit(this.produto);
      }).catch( erro => {
        this.erro   = erro;
      })
    }
  }
}
