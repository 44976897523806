import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SistemaIntegradoService  extends ServerService {
  constructor(protected http: HttpClient) {
    super(http);
  }


  todos() {
    return this.obtenha('/integracao/sistemas/' , { });

  }
}
