import {ProdutoTemplateTamanho} from "./ProdutoTemplateTamanho";


export class ProdutoTamanho{
  produto: any;
  descricao: string;
  qtdePedacos: number;
  qtdeSabores: number
  novoPreco: number;
  percentualDesconto: number;
  destaque: boolean;
  codigoPdv: string;
  pontosGanhos: number;
  cashback: number;
  constructor(public id: number, public preco: number,
              public  template: ProdutoTemplateTamanho, public disponivel: boolean = true) {

    if(template){
      this.descricao = template.descricao;
      this.qtdePedacos = template.qtdePedacos;
      this.qtdeSabores = template.qtdeSabores;
    }

  }

  clone(): ProdutoTamanho {
    let template = Object.assign({}, this.template);

    let tamanhoClone = new ProdutoTamanho(null, this.preco, template, this.disponivel)

    tamanhoClone.novoPreco = this.novoPreco
    tamanhoClone.percentualDesconto = this.percentualDesconto
    tamanhoClone.destaque = this.destaque
    tamanhoClone.codigoPdv = this.codigoPdv
    tamanhoClone.pontosGanhos = this.pontosGanhos
    tamanhoClone.cashback = this.cashback

    return tamanhoClone
  }

  estaDisponivel(){
    if(this.template && !this.template.disponivel) return false;

    return this.disponivel;
  }

  obtenhaPreco(){
    if(this.novoPreco >= 0) return this.novoPreco;

    return this.preco;
  }

  setValoresDeVenda(){
    let tamanho: any = this;

    if(tamanho.novoPreco){
      tamanho.precoAntigo = tamanho.preco;
      tamanho.preco = tamanho.novoPreco;
      tamanho.desconto =  tamanho.precoAntigo -  tamanho.preco ;
    }
  }
}
