import {RouterModule, Route, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {SignupComponent} from "./signup/signup.component";
import {AuthGuardService} from "./guards/auth-guard.service";
import {NgModule} from "@angular/core";
import {ComprovanteTrocaComponent} from "./comprovante-troca/comprovante-troca.component";
import {ComprovantePontuacaoComponent} from "./comprovante-pontuacao/comprovante-pontuacao.component";
import {ComprovantePedidoComponent} from "./pedidos/comprovante-pedido/comprovante-pedido.component";
import {AdminRecuperarComponent} from "./login/admin-recuperar/admin-recuperar.component";
import {ComprovanteComandaComponent} from "./pedidos/comprovante-comanda/comprovante-comanda.component";
import {ImpressaoRelatorioVendasComponent} from "./pedidos/impressao-relatorio-vendas/impressao-relatorio-vendas.component";
import {AuthRedeGuard} from "./guards/auth-rede.guard";
import {LoginRedeComponent} from "./admin-rede/login-rede/login-rede.component";
import {AuthGrupoGuard} from "./guards/auth-grupo.guard";
import {LoginGrupoComponent} from "./admin-grupo/login-grupo/login-grupo.component";
import {RelatorioRecebimentosComponent} from "./componentes/relatorio-recebimentos/relatorio-recebimentos.component";
import {AdminAutenticarComponent} from "./login/admin-autenticar/admin-autenticar.component";

export function MatchEmpresaRoute(url: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  return !window.location.host.startsWith('www') ?  {consumed: []}  : null;
}

export function MatchLandingpageRoute(url: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  return window.location.host.startsWith('www') ? {consumed: []} : null

}

const routes: Route[] = [
  {  path: 'superadmin', canActivate: [AuthGuardService],
    loadChildren: () =>
      import('app/superadmin/superadmin.module').then(m => m.SuperadminModule) },
  {  path: 'admin', canActivate: [AuthGuardService],
    loadChildren: () =>
      import('app/fidelidade/fidelidade.module').then(m => m.FidelidadeModule) },
  {  path: 'admin-rede', canActivate: [AuthRedeGuard],
    loadChildren: () =>
      import('app/admin-rede/painel-rede/painel-rede.module').then(m => m.PainelRedeModule) },
  {  path: 'admin-sdr', canActivate: [AuthRedeGuard],
    loadChildren: () =>
      import('app/admin-sdr/admin-sdr-routing.module').then(m => m.AdminSdrRoutingModule) },
  {  path: 'admin-grupo', canActivate: [AuthGrupoGuard],
    loadChildren: () =>
      import('app/admin-grupo/painel-grupo.module').then(m => m.PainelGrupoModule) },
  {  path: 'admin-rede/login', component: LoginRedeComponent},
  {  path: 'admin-grupo/login', component: LoginGrupoComponent},
  {  path: 'admin/login', component: LoginComponent},
  {  path: 'admin/recuperar', component: AdminRecuperarComponent},
  {  path: 'admin/recuperar/:token', component: AdminRecuperarComponent},
  {  path: 'admin/autenticar/:token', component: AdminAutenticarComponent},
  {  path: 'admin/signup', component: SignupComponent},
  {  path: 'imprimir/troca/:codigo',   canActivate: [AuthGuardService], component: ComprovanteTrocaComponent},
  {  path: 'imprimir/pontuacao/:codigo',   canActivate: [AuthGuardService], component: ComprovantePontuacaoComponent},
  {  path: 'imprimir/pedido/:codigo',   canActivate: [AuthGuardService], component: ComprovantePedidoComponent},
  {  path: 'imprimir/comanda/:id',   canActivate: [AuthGuardService], component: ComprovanteComandaComponent},
  {  path: 'imprimir/relatorio/delivery', canActivate: [AuthGuardService], component: ImpressaoRelatorioVendasComponent},
  {  path: 'imprimir/recebimentos/ecletica/:ano/:mes', canActivate: [AuthGuardService], component: RelatorioRecebimentosComponent},
  {
    matcher: MatchEmpresaRoute,
    loadChildren: () => import('app/site-empresa/site-empresa.module').then(mod => mod.SiteEmpresaModule)
  },
  {
    matcher: MatchLandingpageRoute,
    loadChildren: () => import('app/landingpage/landingpage.module').then(mod => mod.LandingpageModule)
  }

]

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { useHash: false, relativeLinkResolution: 'legacy' }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}


