import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ImpressaoService} from "../../services/impressao.service";
import {ConstantsService} from "../ConstantsService";
import {TelaImprimirPedido} from "../TelaImprimirPedido";
import {PedidosService} from "../../services/pedidos.service";
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {ModalPedidoStatusComponent} from "../modal-pedido-status/modal-pedido-status.component";

import {ModalOpendeliveryEventsComponent} from "../modal-opendelivery-events/modal-opendelivery-events.component";


import {timer} from "rxjs";
import {
  RazaoCancelamentoIfoodComponent
} from "../../componentes/razao-cancelamento-ifood/razao-cancelamento-ifood.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {ModalRequestParceiroComponent} from "../modal-request-parceiro/modal-request-parceiro.component";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-pedido-detalhes',
  templateUrl: './pedido-detalhes.component.html',
  styleUrls: ['./pedido-detalhes.component.scss']
})
export class PedidoDetalhesComponent extends TelaImprimirPedido implements OnInit , OnDestroy  {
  @ViewChild('razaoCancelmentoComp',{ static: false}) razaoCancelmentoComp: RazaoCancelamentoIfoodComponent;
  @Input() modal = false;
  @Input() modoGrupoLojas = false;
  @Input() idEmpresa: any;
  FormaDeEntrega = FormaDeEntrega;
  carregou: boolean;
  configImpressao: any;
  processando: boolean;
  erroAceitar: string
  integracao: any;
  timerCancelamento: any;
  usuario: any = {}
  timerPedido
  historico = []
  carregandoHistorico = true;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              protected pedidosService: PedidosService, private _location: Location,
              private grupolojasService: GrupolojasService, private  dialogService: DialogService,
              private autorizacaoService: AutorizacaoService,
              private constantsService: ConstantsService,
              impressao: ImpressaoService) {
    super(impressao, pedidosService)

  }



  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if(window.history.state.pedido)
        this._pedido = window.history.state.pedido

      let guid: string = params ?  params.guid : null;

      //outro pedido
      if(guid && guid !== this._pedido.guid)
        this._pedido = {};

      if(!this._pedido.id){
        if(guid)
          this.carreguePedidoCompleto(guid)
      } else if(!this._pedido.itens.length) {
        this.carreguePedidoCompleto(this._pedido.guid)
      } else {
        this.carregou = true;
        this.caregueRequestParceiro();
      }
    });

    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) return;

      if(!this.modoGrupoLojas){
        this.empresa  = empresa;
        this.configImpressao = empresa.configImpressao;
        this.integracao = empresa.integracaoDelivery;
      }
    });

    this.usuario = this.autorizacaoService.getUser();

    this.timerPedido  =  timer(5000, 1000 * 30  ).subscribe( () => {
        this.busqueAlteracaoPedido();
    });
  }

  carreguePedidoCompleto(guid){
    this.getService().obtenhaPorGuid(guid, this.idEmpresa).then( (pedido) => {
      this.carregou = true;
      this._pedido = pedido || {};
      if(this.modoGrupoLojas &&  this._pedido.empresa)
        this.integracao = this._pedido.empresa.integracaoDelivery;

      this.caregueRequestParceiro();
    }).catch( (erro) => {
      this.carregou = true;
    });
  }

  busqueAlteracaoPedido(){
    if(!this._pedido || !this._pedido.id) return;

    if(this._pedido.finalizado || this._pedido.cancelado) return;

    this.getService().obtenhaPorGuid(this._pedido.guid, this.idEmpresa).then( (pedido) => {
      if (pedido && pedido.horarioAtualizacao !== this._pedido.horarioAtualizacao)
        this._pedido = pedido

    } ).catch( (erro) => { });

  }

  caregueRequestParceiro(){
    if(this._pedido.referenciaExterna || this._pedido.naoNotificado || this.empresa.integracaoFoodyDelivery){
       this.pedidosService.listeHistoricoRequests(this._pedido.id).then((requests: any) => {
        if(requests && requests.length)
          this._pedido.requests = requests;
      })
    }

    this.carregueHistoricoPedido();
  }

  carregueHistoricoPedido(){

    this.pedidosService.listeHistorico(this._pedido.guid).then((resposta: any) => {
        this.carregandoHistorico = false;
        this.historico = resposta;
    })
  }

  editarPedido(pedido: any){
    this.router.navigateByUrl('/admin/pedidos/' + pedido.guid);
  }

  abraModalEventosOpenDelivery(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalOpendeliveryEventsComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.pedido = this._pedido

    windowRef.result.subscribe( (result) => {  },
      (a) => {   });

  }

  abraModalRequestsParceiro(){
    //
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalRequestParceiroComponent,
      minWidth: 800,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.pedido = this._pedido

    windowRef.result.subscribe( (result) => {  },
      (a) => {   });

    return false;
  }

  mudarStatusPedido(pedido: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalPedidoStatusComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.modoGrupoLojas = this.modoGrupoLojas
    windowRef.content.instance.pedido = pedido
    windowRef.content.instance.contato = pedido.contato;


    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          Object.assign(pedido, result)
      } );

  }

   aceitarPedido(){
    if( this.processando ) return;

    delete this.erroAceitar;
    this.processando = true;

    this.pedidosService.aceitePedido(this._pedido).then( async (resp) => {
      this._pedido.aceito = true;
      this._pedido.referenciaExterna = resp.referenciaExterna;
      if(resp.erroNotificar)
        this.erroAceitar = resp.erroNotificar
      this.processando = false;

      await this.envieImpressaoAutomatica();
    }).catch( erro => {
      this.processando = false;
      this.erroAceitar = erro;
    })
  }


  rejeitarPedido(){
    if( this.processando ) return;

    delete this.erroAceitar;
    this.processando = true;

    let razao = this._pedido.doIfood ? this.razaoCancelmentoComp.razaoCancelamento : null

    this.pedidosService.rejeitePedido(this._pedido, razao).then( (resp) => {
      if(!resp.aguardandoPdv){
        this.setPedidoCancelado(resp)
      } else {
        this._pedido.aguardandoPdv = true;
        this.inicieMonitoramentoCancelamento(resp.idEvent);
      }
    }).catch( erro => {
      this.processando = false;
      this.erroAceitar = erro;
    })

  }

  setPedidoCancelado(resp: any){
    this.processando = false;
    this._pedido.cancelado = true;
    this._pedido.status =  resp.status;
    this._pedido.statusOrdem = resp.statusOrdem;
  }

  inicieMonitoramentoCancelamento(idEvent){
    let segundosTimer = 5;
    this.timerCancelamento =  timer(2000, 1000 * segundosTimer  ).subscribe( () => {
      console.log('verificar pedido: ' + this._pedido.id)
      this.pedidosService.obtenhaStatusCancelamento(this._pedido, idEvent).then( (resposta: any) => {
        if(resposta.pdvReconheceu)  this._pedido.pdvReconheceu = true;

        if(resposta.rejeitouCancelamento){
          this._pedido.motivoRejeicao  =  resposta.motivo;
          this.pareMonitoramento();
        }

        if(resposta.cancelado){
          this.setPedidoCancelado(resposta);
          this.pareMonitoramento();
        }
      })
    });
  }

  ngOnDestroy(): void {
    this.pareMonitoramento();
  }

  pareMonitoramento(){
    this._pedido.aguardandoPdv = false;
    this.processando = false;

    if( this.timerCancelamento)
      this.timerCancelamento.unsubscribe();

    if(this.timerPedido)
      this.timerPedido.unsubscribe();
  }

  notifiqueSistemaIntegrado() {
    if( this.processando ) return;

    delete this.erroAceitar;
    this.processando = true;

    delete this._pedido.erroExterno;

    this.getService().notifiqueSistemaIntegrado(this._pedido).then( (resp) => {
      this._pedido.referenciaExterna = resp;
      this.processando = false;
    }).catch( erro => {
      this.processando = false;
        this._pedido.erroExterno = erro;
    })
  }



  voltar() {
    this._location.back();
  }

  imprimaPedidoDoLayout(){
    this.imprimaPedido(this.configImpressao ? this.configImpressao.layoutPedido : 'PedidoCompleto');
  }

  async imprimaPedido(layoutPedido: string) {
    if(!this.configImpressao  || !this.configImpressao.imprimirTXT)
      window.open('/imprimir/pedido/' + this._pedido.guid + '?l=' + layoutPedido);
    else
      await this.imprimaNaImpressora(null, layoutPedido)
  }



  async imprimaNaImpressora(impressora: any = null, layoutPedido: string  = null ) {
    if(this.configImpressao.multiplasImpressoras){
      await this.imprimaAutomaticamente(impressora);
    } else {
      let impressoraPadrao = this.configImpressao.impressoras[0];

      impressoraPadrao.layout =  layoutPedido || this.configImpressao.layoutPedido;

      await this.imprimaAutomaticamente(impressoraPadrao);
    }
  }


  private async imprimaAutomaticamente(impressora: any) {
    try {
      await this.impressao.imprimaPedidoNaImpressora(this._pedido, impressora)
    } catch(erro) {
      alert("Houve um erro ao imprimir: " + erro)
    }
  }

  getService() {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }


  obtenhaItens(empresa: any) {
    return  this._pedido.itens.filter((item: any) => !item.empresa || item.empresa.id === empresa.id);
  }

  verPedidoExterno(_pedido: any) {

  }



}

export enum FormaDeEntrega {
  RECEBER_EM_CASA = 'Receber em casa',
  RETIRAR = 'Retirar'
}
