import { Component, OnInit } from '@angular/core';
import {merge, Observable, Subject} from "rxjs";
import {Message, User} from "@progress/kendo-angular-conversational-ui";
import {BotsService} from "../../services/bots.service";
import {scan} from "rxjs/operators";

@Component({
  selector: 'app-conversas-chatbot',
  templateUrl: './conversas-chatbot.component.html',
  styleUrls: ['./conversas-chatbot.component.scss']
})
export class ConversasChatbotComponent implements OnInit {
  public feed: Observable<Message[]>;

  conversas = [];
  public readonly user: User = {
    id: 1
  };

  public readonly bot: User = {
    id: 0
  };

  altura: number;
  local: Subject<Message> = new Subject<Message>();
  conversaSelecionada: any;

  constructor(private botsService: BotsService) {
    this.altura = window.innerHeight - 120;
  }

  ngOnInit(): void {
    this.botsService.obtenhaConversas().then( (conversas: Array<any>) => {
      this.conversas = conversas;
    });

    this.feed = merge(
      this.local
    ).pipe(
      scan((acc: Message[], x: Message) => [...acc, x], [])
    );
  }

  abraConversa(conversa: any) {
    this.conversaSelecionada = conversa;

    this.feed = merge(
      this.local
    ).pipe(
      scan((acc: Message[], x: Message) => [...acc, x], [])
    );

    this.botsService.obtenhaMensagens(conversa.telefone).then( (mensagens: Array<any>) => {
      for( let objConversa of mensagens ) {
        this.local.next({
          author: objConversa.idRemetente === objConversa.idChat ? this.bot : this.user,
          text: objConversa.conteudo,
          timestamp: new Date(objConversa.timestamp * 1000),
          attachments: []
        });
      }
    });
  }
}
