import {ProcessadorTexto} from "./ProcessadorTexto";
import {DecimalPipe} from "@angular/common";
import {LayoutRelatorioCaixa} from "./LayoutRelatorioCaixa";

export class ProcessadorCaixaTexto extends ProcessadorTexto {
  private layout: LayoutRelatorioCaixa;
  constructor(impressora) {
    super(impressora);
    this.layout = LayoutRelatorioCaixa.obtenhaLayout(this.papel)

  }

  private formateData(data: Date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return data.toLocaleString('pt-BR', { timeZone: timeZone }).split(',').join('')
  }
  obtenhaResumoCaixa(resumoCaixa: any) {
    let relatorio = this.centralizarLinha(resumoCaixa.empresa.nome)


    let titulo = resumoCaixa.status === 'ABERTO' ? 'RELATÓRIO CAIXA' : 'FECHAMENTO CAIXA'


    relatorio += this.linha() + this.linha() + this.centralizarLinha(titulo)
      + this.linha()
      + this.linha()


    console.log(resumoCaixa)

    relatorio += this.linha("Operador: " + (resumoCaixa.online ?   "Online" : resumoCaixa.operador.nome))
    relatorio += this.linha("Hora Abertura: " +  this.formateData(new Date(resumoCaixa.horaAbertura)))

    if (resumoCaixa.status !== 'ABERTO')
      relatorio += this.linha("Hora Fechamento: " + this.formateData(new Date(resumoCaixa.horaFechamento)))

    relatorio += this.linha(
      this.alinhadoEsquerda('(+) SALDO INICIAL:', this.layout.tamanhoDescricaoCampo)
      + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(resumoCaixa.saldoInicial, '1.2-2'),
        this.layout.tamanhoValorCampo)
    )

    relatorio += this.linha("(+) ENTRADAS NO CAIXA")
    relatorio += this.linha("  PEDIDOS")
    for(let totalPorForma of resumoCaixa.totalEntradasPorFormaDePagamento)
    {
      let stringValor = "R$ " + new DecimalPipe("pt-BR").transform(totalPorForma.valor, '1.2-2')
      this.layout.setTamanhoValorCampo(stringValor.length + 1)

      relatorio += this.linha(
        this.alinhadoEsquerda(totalPorForma.descricao, this.layout.tamanhoDescricaoCampo)
        + this.alinhadoDireita(stringValor,
          this.layout.tamanhoValorCampo)
      )

    }
    relatorio += this.linha("*****")

    relatorio += this.linha("(-) SAÍDAS DO CAIXA")
    relatorio += this.linha("  SANGRIAS")
    let totalSangrias = "R$ " + new DecimalPipe("pt-BR").transform(resumoCaixa.totalSangrias, '1.2-2')
    this.layout.setTamanhoValorCampo(totalSangrias.length + 1)
    relatorio += this.linha(
      this.alinhadoEsquerda('Dinheiro', this.layout.tamanhoDescricaoCampo)
      + this.alinhadoDireita(totalSangrias,
        this.layout.tamanhoValorCampo))
    relatorio += this.linha("  PEDIDOS CANCELADOS")
    for(let totalPorForma of resumoCaixa.totalSaidasPorFormaDePagamento)
    {
      let stringValor = "R$ " + new DecimalPipe("pt-BR").transform(totalPorForma.valor, '1.2-2')
      this.layout.setTamanhoValorCampo(stringValor.length + 1)

      relatorio += this.linha(
        this.alinhadoEsquerda(totalPorForma.descricao, this.layout.tamanhoDescricaoCampo)
        + this.alinhadoDireita(stringValor,
          this.layout.tamanhoValorCampo)
      )

    }

    relatorio += this.linha("*****")
    relatorio += this.linha("(=) SALDO FINAL")
    let saldoFinal = "R$ " + new DecimalPipe("pt-BR").transform(resumoCaixa.saldo, '1.2-2')
    this.layout.setTamanhoValorCampo(saldoFinal.length + 1)

    relatorio += this.linha(
      this.alinhadoEsquerda('Dinheiro', this.layout.tamanhoDescricaoCampo)
      + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(resumoCaixa.saldoDinheiro, '1.2-2'),
        this.layout.tamanhoValorCampo))
    relatorio += this.linha(
      this.alinhadoEsquerda('TUDO', this.layout.tamanhoDescricaoCampo)
      + this.alinhadoDireita(saldoFinal,
        this.layout.tamanhoValorCampo))
      + this.linha()
      + this.linha()

    relatorio += this.linha("Data emissão: " + this.formateData(new Date()))
      + this.linha()

    return relatorio;



  }
}


