import {Component, OnInit, ViewChild} from '@angular/core';
import {EnumTipoDeCupom} from "../../../../server/lib/emun/EnumTipoDeCupom";
import {CuponsService} from "../../superadmin/services/cupons.service";
import {NgForm} from "@angular/forms";
import {DialogRef} from "@progress/kendo-angular-dialog";
import {ProdutoService} from "../../services/produto.service";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
declare let moment;
@Component({
  selector: 'app-cad-cupom',
  templateUrl: './cad-cupom.component.html',
  styleUrls: ['./cad-cupom.component.scss']
})
export class CadCupomComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;
  cupom: any = {};
  EnumTipoDeCupom = EnumTipoDeCupom;
  tiposDeCupoms = [EnumTipoDeCupom.Valor, EnumTipoDeCupom.Percentual ];
  windowRef: DialogRef;
  salvando: any;
  mensagemErro: any;
  produtos: any = [   { descricao: ''}];
  categorias = []
  tamanhosPizzas: any = [];
  buscando: boolean;
  timerBusca;
  hoje;
  empresa: any;
  disponibilidadesSemana  = [
    { dia: 0 , label: 'Dom', disponivel: false },
    { dia: 1 , label: 'Seg', disponivel: false },
    { dia: 2 , label: 'Ter', disponivel: false },
    { dia: 3 , label: 'Qua', disponivel: false },
    { dia: 4 , label: 'Qui', disponivel: false },
    { dia: 5 , label: 'Sex', disponivel: false },
    { dia: 6 , label: 'Sab', disponivel: false }
  ]
  mensagemSucesso: string;
  constructor(private cuponsService: CuponsService, private empresasService: EmpresasService,
              private constantsService: ConstantsService,
              private produtoService: ProdutoService) {
    this.hoje = moment().add(-1, 'd').toDate();

    this.constantsService.empresa$.subscribe( empresa => {
         if(empresa && empresa.id) this.categorias = empresa.catalogo.categorias;
    }  );

    this.empresasService.obtenhaTemplatesEmpresaLogada( ).then( (templates) => {
      if(templates && templates.length){
        this.tamanhosPizzas.push( { descricao: ''})
        templates.forEach( template => {
            template.tamanhos.forEach( tamanho => {
               this.tamanhosPizzas.push( { id: tamanho.id, descricao: String(`${template.nome} - ${tamanho.descricao}`)});
            })
        })

        if(this.cupom.produtoTemplateTamanho){
          this.cupom.produtoTemplateTamanho =
            this.tamanhosPizzas.find( tamanhoPizza => tamanhoPizza.id ===  this.cupom.produtoTemplateTamanho.id)
        }
      }
    });
  }

  ngOnInit(): void {
    if(!this.cupom.id)
      this.cupom.tipo = this.tiposDeCupoms[0];

    if(this.cupom.valorMinimo == null) this.cupom.valorMinimo = 0;

    this.cupom.alvo =  this.cupom.aplicarNaTaxaDeEntrega ?  'taxaEntrega' :
      (this.cupom.aplicarNaFidelidade ? 'fidelidade' : 'produto');

    if(this.cupom.validade) this.cupom.validade = new Date(this.cupom.validade)

    this.produtos = [{ descricao: ''}]

    if(this.cupom.horarios){
      this.disponibilidadesSemana.forEach( (ds: any) => {
        ds.disponivel =    this.cupom.horarios.find( horario => horario.dia === ds.dia) != null
      })
    }

    if(this.cupom.produto && this.cupom.produto.id){
      this.cupom.produto.descricao = String(`${this.cupom.produto.id} - ${this.cupom.produto.nome}`);

      this.produtos.push(this.cupom.produto)
    }

    if(this.cupom.percentualMaximoDescontoProduto > 0)
      this.cupom.naoAplicarProdutosComDesconto = true

    if(this.cupom.id){
      this.cuponsService.jaUsou(this.cupom.id).then((resposta) => {
         this.cupom.jaUtilizado = resposta;
      })
    }
  }

  alvoSeraProdutos(){
    this.cupom.aplicarNaTaxaDeEntrega = false;
    this.cupom.aplicarNaFidelidade = false;
  }

  alvoSeraTaxaEntrega(){
    this.cupom.aplicarNaTaxaDeEntrega = true;
    this.cupom.aplicarNaFidelidade = false;

    if(this.cupom.escopo === 'mesas'){
      this.cupom.escopo = 'livre'
      this.cupom.formaEntrega = ''
    }
  }

  alvoSeraFidelidade(){
    this.cupom.aplicarNaFidelidade = true;
    this.cupom.aplicarNaTaxaDeEntrega = false;
  }

  onSalvar() {
    delete this.mensagemSucesso;
    delete this.mensagemErro;
    if(this.frm.valid){
      this.salvando = true;
      this.cupom.horarios = this.disponibilidadesSemana.filter( (ds: any) => ds.disponivel)
      this.cuponsService.salveCupom(this.cupom).then( (resp) => {
        this.mensagemSucesso = 'Cupom salvo com sucesso!'
        if(!this.cupom.id)
          this.cupom.id = resp.id;
        this.salvando = false;
        setTimeout(() => {
          delete this.mensagemSucesso;
        }, 5000)
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro = erro;
      })
    }
  }

  gereCodigoCupom() {
    let codigo = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( let i = 0; i < 10; i++ )
      codigo += possible.charAt(Math.floor(Math.random() * possible.length));

    this.cupom.codigo = codigo;
  }

  fechar() {
    this.windowRef.close(this.cupom.id ? this.cupom : null);
  }


  search($event: string) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.busqueProdutos($event)
    }, 1000)

  }

  searchCategoria(texto: string){
    if(texto){
      this.categorias = this.empresa.categorias.filter((categoria) => categoria.nome.toLowerCase().indexOf(texto.toLowerCase()) !== -1);
    } else {
      this.categorias = this.empresa.categorias;
    }
  }

  busquePrimeiraVez() { //
    if(!this.produtos.length){
      this.busqueProdutos();
    }
  }

  busqueProdutos(texto: string = ''){
    if(this.buscando) return;

    this.buscando = true;

    this.produtoService.autoComplete( texto).then (  (produtos) => {
      this.produtos = produtos;
      this.buscando = false;
    }).catch( () => {
      this.buscando = false;
    })

  }

  obtenhaFormatoPercentual(){
    return String(`0"%"`);
  }

  alterouComboProduto(item) {
    if(this.cupom.minimoApenasNoValorProduto) return;

    if(item && item.id){
      this.cupom.produtoNaoComporMinimo = true;
    } else {
      this.cupom.produtoNaoComporMinimo = false;
    }
  }

  onSelectedChange(ds, selecionou) {
    ds.disponivel = selecionou;
  }
}
