import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-fatura-detalhes',
  templateUrl: './fatura-detalhes.component.html',
  styleUrls: ['./fatura-detalhes.component.scss']
})
export class FaturaDetalhesComponent implements OnInit {
  @Input() fatura: any = {}
  constructor() { }

  ngOnInit() {
  }

}
