import { Component, OnInit } from '@angular/core';
import {process} from "@progress/kendo-data-query";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {EditEvent, GridDataResult, RemoveEvent} from "@progress/kendo-angular-grid";
import {BannerService} from "../../services/banner.service";
import {NovaFormaDePagamentoComponent} from "../../formas-de-pagamento/nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {state} from "@angular/animations";
import {Router} from "@angular/router";
import {Banner} from "../../../../server/domain/delivery/Banner";

@Component({
  selector: 'app-tela-cadastro-banner',
  templateUrl: './tela-cadastro-banner.component.html',
  styleUrls: ['./tela-cadastro-banner.component.scss']
})
export class TelaCadastroBannerComponent implements OnInit {
  banners = [];
  empresa: any;

  loading: any;
  public gridDataResult: GridDataResult;
  carregando: any;
  modalDeletar = false;
  filtro: any;
  bannerApagar: any;

  constructor(private router: Router, private constantsService: ConstantsService, private bannerService: BannerService,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;

      this.bannerService.obtenhaBanners(empresa).then( (banners) => {
        this.banners = banners;

        this.gridDataResult = {
          data: this.banners,
          total: this.banners.length
        }
      });
    });
  }

  novo(pagamentoOnline: boolean) {
    this.router.navigateByUrl('/admin/banner/novo').then( () => {
    });
  }

  editar($event: EditEvent) {
    const banner: Banner = $event.dataItem;

    this.router.navigateByUrl('/admin/banner/' + banner.id).then( () => {
    });
  }

  close(status: string) {
    if( status === 'sim') {
      this.bannerService.removaBanner(this.bannerApagar).then( () => {
        this.constantsService.recarregueEmpresa();
        this.bannerApagar = null;
        this.modalDeletar = false;
      });
    } else {
      this.bannerApagar = null;
      this.modalDeletar = false;
    }
  }

  abraDialogRemover($event: RemoveEvent) {
    this.bannerApagar = $event.dataItem;

    this.modalDeletar = true;
  }
}
