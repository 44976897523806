import {Component, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../services/empresas.service";
import {NgForm} from "@angular/forms";
import {NumeroWhatsapp} from "../../pedidos/objetos/NumeroWhatsapp";
import {NumerosWhatsappService} from "../../services/numeros-whatsapp.service";
import {TelefonePipe} from "../../telefone.pipe";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-cad-usuario',
  templateUrl: './cad-usuario.component.html',
  styleUrls: ['./cad-usuario.component.scss']
})
export class CadUsuarioComponent extends ModalKendo  implements OnInit {
  @ViewChild('frm')  frm: NgForm;
  usuario: any;
  empresa: any;
  mensagemSucesso: string;
  mensagemErro: string;
  numerosWhatsapp: Array<NumeroWhatsapp> = [];
  operadoresPdv = []
  cadastroSemLogin: boolean;
  carregandoNoPdv = false;
  constructor(private empresaService: EmpresasService,
              private numerosWhatsappService: NumerosWhatsappService) {
    super()
  }

  ngOnInit() {  }

  async setUsuario(empresa: any, usuario: any, cadastroSemLogin: boolean  = false) {
    this.usuario = Object.assign({},  usuario);
    this.empresa = empresa;
    this.cadastroSemLogin = cadastroSemLogin;
    this.numerosWhatsappService.obtenhaNumeros(this.empresa).then( (numeros) => {
      this.numerosWhatsapp = numeros;

      for( let numero of this.numerosWhatsapp ) {
        numero.whatsapp = new TelefonePipe().telefone(numero.whatsapp);
      }
    });

    this.carregandoNoPdv = true;
    this.operadoresPdv = await this.empresaService.listeOperadoresPdv();
    this.carregandoNoPdv = false;

    if(this.usuario.codigoPdv)
      this.usuario.operadorPdv = this.operadoresPdv.find((item: any) => item.cod_fun === this.usuario.codigoPdv)

  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  onSubmit() {
    delete this.mensagemErro;

    if(this.frm.valid){
      if(this.usuario.garcom) {
        this.empresaService.salveGarcom(this.empresa, this.usuario).then( resposta => {
          if(!this.usuario.id) this.usuario.id = resposta.id;
          this.fecheModal(this.usuario)
        }).catch( erro => {
          this.mensagemErro   = erro;
        })
      } else
      this.empresaService.salveUsuario(this.empresa, this.usuario).then( resposta => {
        this.fecheModal(this.usuario)
      }).catch( erro => {
        this.mensagemErro   = erro;
      })
    }
  }

  cadastrarCodigoPdv(){
    if(!this.usuario || !this.empresa || !this.empresa.integracaoDelivery) return false;

    let config: any = this.empresa.integracaoDelivery.configuracoesEspecificas;
    let sistema = this.empresa.integracaoDelivery.sistema;

    return this.usuario.garcom && sistema === 'ecletica' && config && config.integrarComComandas
  }
}
