import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CampanhaService} from "../services/campanha.service";
import {ContatosService} from "../services/contatos.service";
import { Location } from '@angular/common';
import {NgForm} from "@angular/forms";
import {WindowCloseResult, WindowRef, WindowService} from "@progress/kendo-angular-dialog";
import {FiltroTelaUI} from "../../../projects/loja/src/objeto/FiltroTelaUI";


@Component({
  selector: 'app-tela-enviar-mensagens',
  templateUrl: './tela-enviar-mensagens.component.html',
  styleUrls: ['./tela-enviar-mensagens.component.scss']
})
export class TelaEnviarMensagensComponent implements OnInit {
  campanha: any = {};
  contatos: any = [];
  paginacao: any = {totalPorPagina: 5, pagina: 1, total: 0 };
  filtro: any = {};
  enviando = false;
  progresso: any = 0.0;
  contatoAtual: any;
  tarefa: any = {
    qtdeContatosProcessados: 0.0,
    totalContatos: 0.0
  };
  erroSalvarFiltro: any;
  abriuTelaEnvio = false;

  celularTeste: any = '';
  @ViewChild('frm')  frmEnviarMensagemTeste: NgForm;

  checarSeDeuCerto = false;
  window: WindowRef;
  foiTestada = false;
  saldo: any;
  totalContatos: any;
  configQtdeMsgsAEnviar = 'Todos';
  respContatos: any;
  carregou = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private campanhaService: CampanhaService,
              private contatoService: ContatosService, private location: Location,
              private windowService: WindowService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let id = +params['idc']; // (+) converts string 'id' to a number

      if( id ) {
        this.campanhaService.obtenhaSaldo().then( (respostaSaldo) => {
          this.saldo = respostaSaldo;

          this.campanhaService.obtenhaCampanha(id).then((resposta: any) => {
            console.log('campanha');

            this.campanha = resposta;

            console.log(this.campanha);

            this.alterouMensagem(this.campanha);

            this.busqueContatos(0, () => {
              this.carregou = true;
            });
          });
        });
      }
    });
  }

  voltar() {
    this.location.back();
  }

  alterouMensagem(campanha: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = campanha.mensagem.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(campanha.mensagem)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        qtdARemover = campo.length;
        qtdAAdicionar = 15;
      } else if ( campo === '[LinkCartao]' ) {
        qtdARemover =  campo.length;
        qtdAAdicionar = TAMANHO_URL_ENCURTADA;

      } else if ( campo === '[Empresa]' ) {
        qtdARemover = campo.length;
        qtdAAdicionar = 15;
      }
    }
  }

  busqueContatos(inicio, cb: Function) {
    let params = this.getParamsFiltro();
    params.idc = this.campanha.id;
    params.rc = true;
    params.origemContatos = this.campanha.origemContatos;
    params.envio = true;

    this.contatoService.listeContatos(inicio, this.paginacao.totalPorPagina, params).then( respContatos => {
      this.respContatos = respContatos;
      this.contatos = respContatos.contatos;
      this.totalContatos = respContatos.total;

      cb();
    })
  }

  getParamsFiltro(){
    return FiltroTelaUI.getParamsRequest(this.filtro.dados);
  }

  iniciarEnvio() {
    if( !this.campanha.foiTestada ) {
      alert('Campanha não pode ser enviada, pois ainda não foi testada!');

      return;
    }

    const resposta = window.confirm("Tem certeza que deseja enviar a campanha?");

    if( resposta ) {
      this.enviando = true;

      const dados: any = {};

      this.campanhaService.inicieEnvio(this.campanha.id, dados).then( () => {
        this.campanhaService.mudouSaldo();
      });

      this.monitoreEnvio();
    }
  }

  monitoreEnvio() {
    this.campanhaService.statusEnvio(this.campanha.id).then( (tarefa: any) => {
      if( tarefa ) {
        this.tarefa = tarefa;
        this.contatoAtual = tarefa.contatoAtual;
        this.progresso = tarefa.percentual;

        if (tarefa.terminou) {
          return;
        }
      }

      setTimeout( () => {
        this.monitoreEnvio();
      }, 500);
    });
  }

  getCartao(contato: any) {
    if(!this.campanha.filtro) return;

    let cartao    =  contato.cartoes[0],
      plano = this.campanha.filtro.plano;

    if(plano){
      contato.cartoes.forEach( (ct) => {
        if(ct.plano.id === plano.value)
          cartao = ct;
      })
    }

    return cartao;
  }

  testarEnvio(tplTestarEnvio: TemplateRef<any>) {
    this.foiTestada = false;
    this.checarSeDeuCerto = false;

    this.window = this.windowService.open({
      title: 'Enviar Teste',
      content: tplTestarEnvio,
      top: window.innerHeight / 2 - 130,
      width: 450,
      minHeight: 200
    });

    this.window.result.subscribe((result) => {
      if (result instanceof WindowCloseResult) {
        console.log('Window was closed!');
        this.window = null;
      }
    });
  }

  envieMensagemTeste(txtTelefone) {
    this.celularTeste = txtTelefone.value.replace(/\D/g, '');
    this.campanhaService.inicieEnvioTeste(this.campanha.id, this.celularTeste).then( (resposta: any) => {
      console.log(resposta);
      this.checarSeDeuCerto = true;
    });
  }

  salveFiltro() {

  }

  fecheModalFiltro() {
    this.window.close();
  }

  unmask(val) {
    return val.replace(/\D+/g, '');
  }

  marqueCampanhaComoEnviada() {
    this.campanhaService.marqueCampanhaComoEnviada(this.campanha.id).then( (resposta: any) => {
      console.log(resposta);
      this.campanha.foiTestada = true;
      this.foiTestada = true;
    });
  }

  mensagemIncorreta() {
    this.abriuTelaEnvio = false;
    this.window.close();

    this.router.navigateByUrl( '/admin/nova-campanha/' + this.campanha.id, {state: {data: this.campanha }}).then( () => {});
  }

  mudouQtdeMensagensAEnviarTodos() {
    this.totalContatos = this.respContatos.total;
  }
}
