export const ptBR = {
  firstDayOfWeek: 1,
  dayNames: [ "Domingo","Segunda","Terça","Quarta","Quinta","Sexta","Sábado" ],
  dayNamesShort: [ "DOM","SEG","TER","QUA","QUI","SEX","SAB" ],
  dayNamesMin: [ "D","S","T","Q","Q","S","S" ],
  monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
  monthNamesShort: [ "JAN","FEV","MAR","ABR","MAI","JUN","JUL","AGO","SET","OUT","NOV","DEZ" ],
  today: 'Hoje',
  clear: 'Limpar'
}
