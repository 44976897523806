import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {DataStateChangeEvent, GridDataResult, SelectionEvent, GridComponent} from "@progress/kendo-angular-grid";
import {ProdutoService} from "../../services/produto.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {GroupDescriptor, process, State} from '@progress/kendo-data-query';
import {Location} from "@angular/common";
import {ItemPedido} from "../objetos/ItemPedido";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {AdicionaisCustomizadosComponent} from "../adicionais-customizados/adicionais-customizados.component";
import {SiteCampoAdicionalComponent} from "../site-campo-adicional/site-campo-adicional.component";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-tela-adicionar-produto',
  templateUrl: './tela-adicionar-produto.component.html',
  styleUrls: ['./tela-adicionar-produto.component.scss']
})
export class TelaAdicionarProdutoComponent implements OnInit {
  @ViewChild('adicionaisCustomizados', { static: false }) adicionaisCustomizados: AdicionaisCustomizadosComponent;
  @ViewChildren('adicionalComponent') ctrlAdicionais: QueryList< SiteCampoAdicionalComponent>;
  @ViewChild('gridProdutos', {static: false}) gridProdutos: GridComponent;
  @Output() onAdicionou = new EventEmitter();
  @Output() onAtualizou = new EventEmitter();
  @Output() onCancelouAdicionar = new EventEmitter();
  pedido: any = {};
  public gridDataResult: GridDataResult;
  page = 0;
  tamanhoPagina = 10;
  loading = false;
  mensagemErro: any = '';
  validado = false;
  produtos = [];
  indice: number;
  editando: boolean;
  produtoSelecionado: any;
  produtoSelecionadoNaGrid: any;
  public groups: GroupDescriptor[] = [{field: 'categoria.nome'}];
  buscarNoServer = false;
  itemPedido: any = { };
  tipoDeCardapio: any =  'ADMIN';
  public state: State = {
    skip: 0,
    take: 100,
    sort: []
};
  configuracoesDeSelecao: any = {
    checkboxOnly: false,
    mode: 'multiple'
  };
  public mySelection: number[] = [];
  alturaGrid = 500;
  timerBusca: any
  desktop = false;
  constructor(private produtoService: ProdutoService, private autorizacaoService: AutorizacaoService,
              private _location: Location, private constantsService: ConstantsService,
              private activatedRoute: ActivatedRoute) {
    this.alturaGrid = window.innerHeight - 300;
    this.desktop = window.innerWidth >= 1024;
  }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.queryParams.subscribe(queryParams => {
      const idMesa = queryParams.idm;

      if( idMesa )
        this.tipoDeCardapio =  'MESA';

      this.produtoService.listeAhVenda(this.tipoDeCardapio).then((resposta: any) => {
        this.produtos = resposta.produtos;
        this.constantsService.produtosLoja = resposta.produtos;
        this.ordeneProdutos();
        this.loading = false;
        this.state.group = this.groups;
        this.setProdutosNaGrid();
      });
    });
  }

  setProdutosNaGrid(){
    this.produtoSelecionado = null;
    this.gridDataResult = process( this.produtos, this.state);
  }

  onSubmit() {
  }

  dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.state.group = this.groups;
    this.gridDataResult = process(this.produtos, this.state);
  }

  cancelar() {
    this.setProdutosNaGrid();
    this.onCancelouAdicionar.emit({});
  }

  ordeneProdutos(){
    if (this.produtos && this.produtos.length > 0) {
      this.produtos.sort((produtoA: any, produtoB: any) => {
        let ordemA = produtoA.categoria ? produtoA.categoria.posicao : -1;
        let ordemB = produtoB.categoria ? produtoB.categoria.posicao : -1;

        if (ordemA >= 0 && ordemB >= 0) return ordemA - ordemB

        if (produtoA.ordem == null || produtoB.ordem == null) return 0;

        return produtoA.ordem - produtoB.ordem
      })
    }
  }

  filtreNaTela(value){
    const estado: State = {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'id',
            operator: 'contains',
            value: value
          },
          {
            field: 'codigoPdv',
            operator: 'contains',
            value: value
          },
          {
            field: 'nome',
            operator: 'contains',
            value: value
          },
          {
            field: 'preco',
            operator: 'contains',
            value: value
          },
          {
            field: 'categoria.nome',
            operator: 'contains',
            value: value
          }
        ],
      }
    };

    estado.group = this.groups;
    this.gridDataResult = process(this.produtos, estado);
  }

  filtreNoServer(value){
    if(value.length < 3) return;
    if(this.loading) return;

    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.loading = true;
      this.produtoService.listeAhVenda(this.tipoDeCardapio, value ).then((resposta: any) => {
        this.produtos = resposta.produtos;
        this.ordeneProdutos();
        this.loading = false;
        this.gridDataResult = process(this.produtos, this.state);
      });
    }, 750)

  }

  onFilter(value: any) {
   if(!this.buscarNoServer){
     this.filtreNaTela(value);
   } else {
     this.filtreNoServer(value)
   }
  }

  exibaErroObrigatorio() {
    if( !this.desktop ) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    this.mensagemErro = 'Verifique os adicionais obrigatórios';
  }

  novoItem(){
    if(this.editando)
      this.gridDataResult = process(this.produtos, this.state);

    this.editando = null;
    delete this.indice;
    this.itemPedido = {
      qtde: 1,
      adicionais: {},
      observacao: ''
    }

    delete this.produtoSelecionado

  }

  editeItem(indice: number, itemPedido: any){
    this.produtoSelecionado = this.produtos.find( produto => produto.id === itemPedido.produto.id);

    if(this.produtoSelecionado){
      this.indice = indice;
      this.editando = true;

      itemPedido.calculePosicaoAdicionais( this.produtoSelecionado.camposAdicionais);

      this.itemPedido = Object.assign(new ItemPedido(itemPedido.produto, itemPedido.qtde, itemPedido.observacao), itemPedido);

      this.gridDataResult = process([ this.produtoSelecionado], this.state);

      setTimeout( () => {
        if(this.adicionaisCustomizados)
          this.adicionaisCustomizados.setValoresEdicao()

        if(this.itemPedido.produtoTamanho)
          this.onAlterouTamanho(this.itemPedido.produtoTamanho)

      }, 0)

    }

  }

  private valideAdicionais(){
    delete this.mensagemErro;
    if(this.adicionaisCustomizados){
      let validos = this.adicionaisCustomizados.valideCampos();

      if(!validos) {
        this.exibaErroObrigatorio();
        return false;
      }
    }

    if(this.informouAdicionaisObrigatorios()){
        return true;
    } else {
      this.exibaErroObrigatorio();
      return false;
    }
  }

  atualizeProduto(){
    if(this.valideAdicionais()){
      this.setProdutosNaGrid();
      this.onAtualizou.emit({ indice: this.indice, item: this.itemPedido})
    }
  }

  adicionarProduto() {
    if(this.valideAdicionais()) {
      this.itemPedido.produto = this.produtoSelecionado;
      this.setProdutosNaGrid();
      this.onAdicionou.emit(this.itemPedido)
    }
  }

  selecionouProduto($event: SelectionEvent) {
    if(this.editando) return;

    this.produtoSelecionadoNaGrid = $event.selectedRows[0].dataItem;
  }

  duploClique($event: MouseEvent) {
    if(this.editando) return;

    this.produtoSelecionado = this.produtoSelecionadoNaGrid;

    let qtde = this.produtoSelecionado.valorInicial || 1;

    this.itemPedido = new ItemPedido(this.produtoSelecionado, qtde, '');

    this.itemPedido.calculePosicaoAdicionais(this.produtoSelecionado.camposAdicionais);
    window.scrollTo(0, 0);
  }

  private informouAdicionaisObrigatorios() {
    if(!this.produtoSelecionado.camposAdicionais || this.produtoSelecionado.camposAdicionais.length === 0) return true;

    let valido = true,
      //todo: como essa tela nao esta usando o objeto, instaciar aqui so para validar
       itemPedido = new ItemPedido(this.produtoSelecionado, this.itemPedido.qtde, null)

    itemPedido.adicionais = this.itemPedido.adicionais;

    for(let campoAdicional of this.produtoSelecionado.camposAdicionais) {
      if(!itemPedido.valideCampoAdicional(campoAdicional)) {
        valido = false;
        break;
      }
    }

    let posicaoSabor = this.produtoSelecionado.camposAdicionais.length - 1;

    for( let i = 0; i < this.itemPedido.sabores.length; i++ ) {
      const sabor = this.itemPedido.sabores[i];
      if(sabor.produto !== this.produtoSelecionado.id){
        for( let j = 0; j < sabor.camposAdicionais.length; j++ ) {
          posicaoSabor++
          const campoAdicionalSabor = sabor.camposAdicionais[j];

          if(!itemPedido.valideCampoAdicional(campoAdicionalSabor)) {
            valido = false;
            break;
          }
        }
      }
    }

    return valido;
  }

  exibirUnidade(produto) {
    return produto && produto.tipoDeVenda &&  produto.tipoDeVenda  === 'Peso';
  }

  obtenhaFormato() {
    if(!this.produtoSelecionado || !this.produtoSelecionado.unidadeMedida)
      return '#####'

    return String(`0.00 ${this.produtoSelecionado.unidadeMedida.sigla}`);
  }

  obtenhaIncremento(){
    if(!this.produtoSelecionado || !this.produtoSelecionado.incremento)
      return 1

    return this.produtoSelecionado.incremento;
  }


  onAlterouTamanho(produtoTamanho: any) {
    this.ctrlAdicionais.forEach( (ctlAdicioanais) => {
      ctlAdicioanais.alterouTamanho(produtoTamanho);
    })
  }


  selecionarProduto(produto: any) {
    this.produtoSelecionado = produto;

    let qtde = this.produtoSelecionado.valorInicial || 1;

    this.itemPedido = new ItemPedido(this.produtoSelecionado, qtde, '');

    if( this.produtoSelecionado.camposAdicionais.length > 0 ||
      (this.produtoSelecionado.tamanhos && this.produtoSelecionado.tamanhos.length)) {
      this.itemPedido.calculePosicaoAdicionais(this.produtoSelecionado.camposAdicionais);
    } else {
      this.adicionarProduto();
    }

    window.scrollTo(0, 0);
  }

  selecionarProdutoBtn() {
    this.selecionarProduto(this.produtoSelecionadoNaGrid);
  }

  escolheuNovaOpcao(opcao: any){
    if(this.ctrlAdicionais){
      this.ctrlAdicionais.forEach( (ctlAdicioanal: SiteCampoAdicionalComponent) => {
        ctlAdicioanal.exibaOuEscondaOpcaoQueDepende(opcao)
      });
    }
  }

  desmarcouNovaOpcao(opcao: any){
    this.ctrlAdicionais.forEach( (ctlAdicioanal: SiteCampoAdicionalComponent) => {
      ctlAdicioanal.desmarcouOpcaoQueDepende(opcao)
    });
  }
}
