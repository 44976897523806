import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {CellClickEvent, DetailCollapseEvent, DetailExpandEvent} from "@progress/kendo-angular-grid";
import {ExcelExportData} from "@progress/kendo-angular-excel-export";

@Component({
  selector: 'app-tela-contatos-por-categoria',
  templateUrl: './tela-contatos-por-categoria.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tela-contatos-por-categoria.component.scss']
})
export class TelaContatosPorCategoriaComponent implements OnInit {
  @Input() public categoria = '';
  @Input() public rede  = false;
  gridView: any
  contatos: any = [];
  skip  = 0;
  pageSize = 100;
  public sort: SortDescriptor[] = [{
    field: 'qtdeDiasNaoVolta',
    dir: 'desc'
  }];
  nomeArquivo = 'Contatos.xls';
  carregando = false;
  constructor() {  }
  ngOnInit() { }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadGrid();
  }

  public pageChange(event: any): void {
    this.skip = event.skip;
    this.loadGrid();
  }


  private loadGrid(): void {
    this.contatos =  orderBy(this.contatos, this.sort);
    let data =  this.contatos.slice(this.skip, this.skip + this.pageSize);

    this.gridView = {
      data: data,
      total: this.contatos.length,
    }
  }

  public limpeGrid( ): void {
    this.contatos = [];
    this.loadGrid();
  }

  public alterouOsProdutos(categoria: any, mesAtual: string, contatos: any, rede: string = null): void {
    this.contatos = contatos;
    this.nomeArquivo = String(`Contatos ${rede ? rede : ''}(${categoria})${mesAtual}.xls`);

    for( let contato of this.contatos ) {
      contato.exibirEmRisco = false;
      if( this.categoria === 'NOVO' || this.categoria === 'Regular' )
        continue;

      if( (contato.cicloDeRecorrencia) <= contato.qtdeDiasNaoVolta)
        contato.exibirEmRisco = true;

    }

    this.loadGrid();
  }

  colapsou($event: DetailCollapseEvent) {
    $event.dataItem.expandido = false;
  }

  expandiu($event: DetailExpandEvent) {
    $event.dataItem.expandido = true;
  }

  public todosContatos = (): any  => {
    let myState: any = {};

    myState.skip = 0;
    myState.take = this.contatos.length;

    const result: ExcelExportData =  {
      data: process(this.contatos, myState).data
    };
    return result;
  };
}
