import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {ServerService} from "./ServerService";
import {ArmazenamentoService} from "./armazenamento.service";
import { Observable, of } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoService  extends ServerService {
  kEYUSER = 'pkuser';
  private usuarioLogado = new BehaviorSubject<any>(null);
  usuarioLogado$ = this.usuarioLogado.asObservable();

  constructor (public http: HttpClient, private constantsService: ConstantsService,
               private armazenamentoService: ArmazenamentoService) {
    super(http);
    this.estaLogado().then((resposta: any) => {
      if(!resposta.logado ) {
        this.limpeSessaoLocal();
        return;
      }
      this.usuarioLogado.next(this.getUser());
    });

    window.addEventListener('storage', (event) => {
      if (event.key === 'alterouEmpresa') {
        //this.constantsService.empresa.next(JSON.parse(event.newValue));
      } else if (event.key === 'usuario') {
        this.usuarioLogado.next(this.getUser());
      }
    });
  }

  estaLogado(): Promise<boolean> {
    return new Promise(resolve => {
      this.http.get('/auth/logado').subscribe((resposta: any) => {
        resolve(resposta);
      });
    });
  }

  carregueUsuario(): Observable<any> {
    if(this.usuarioLogado && this.usuarioLogado.value){
      return of(this.usuarioLogado.value)
    } else {
      return this.http.get<any>('/auth/user/me').pipe(
        tap(user => this.usuarioLogado.next(user))
      );
    }
  }

  fetchUser(): Observable<any> {
    if(this.usuarioLogado && this.usuarioLogado.value){
      return of(this.usuarioLogado.value)
    } else {
      return this.http.get<any>('/auth/user/me').pipe(
        map(user => {
          this.usuarioLogado.next(user)
          return  this.usuarioLogado.value
        }),
        catchError(error => {
          console.error('Erro ao buscar usuário', error);
          return of(null); // Retorna null em caso de erro
        })
      );
    }
  }

  getUser(): any | null {
    return this.usuarioLogado.value;
  }

  login(email: string, senha: string): Promise <Object> {
    return new Promise(resolve => {
      this.http.post('/auth/login', {user: {email: email, password: senha}}).toPromise().then((resposta: any) => {
        if(resposta.sucesso) {
          if(resposta.data && resposta.data.autenticar)
            return resolve({ redirect: `/admin/autenticar/${resposta.data.token}`, autenticar: true})

          this.usuarioFezLogin(resposta.data);
          return resolve({})
        }
        let retorno: any = { erro: resposta.erro || 'Falha ao fazer login'}
        resolve(retorno);
      }).catch((err: any) => {
        resolve({ erro: err.message || err.messageText || err});
      })
    });
  }

  usuarioFezLogin(dados: any){
    const usuario: any = {};
    Object.assign(usuario, dados);
    this.usuarioLogado.next(usuario);
  }


  tokenSessaoAutenticar(token: string){
    return new Promise((resolve, reject) => {
        this.http.get(`/auth/sessao/token/${token}`).toPromise().then((resposta: any) => {
          resolve(resposta)
        }).catch((resp: any) => {
          if(resp && resp.status === 404) return reject('Sessão finalizada ou nao inicializada' )
          console.error(resp)
          reject(resp.message || resp.messageText || resp.erro  )
        })
    });
  }

  autentiqueUsuarioPeloCodigo(token: any, codigo: any) {
    return new Promise((resolve, reject) => {
      this.http.post('/auth/token/autenticar/' + token, {codigo: codigo}).toPromise().then((resposta: any) => {
        if(resposta.erro) return reject(resposta.erro);

        this.usuarioFezLogin(resposta.data);
        resolve(resposta)
      }).catch((resp: any) => {
        if(resp && resp.status === 404) return reject('Sessão finalizada ou nao inicializada' )
        console.error(resp)
        reject( resp.erro || resp.message || resp.messageText   )
      })
    });
  }

  reenvieCodigoAutenticacao(token: any ) {
    return new Promise((resolve, reject) => {
      this.http.post('/auth/token/reenviar/' + token, { }).toPromise().then((resposta: any) => {
        if(resposta.erro) return reject(resposta.erro);
        resolve(resposta.data.token)
      }).catch((resp: any) => {
        if(resp && resp.status === 404) return reject('Sessão finalizada ou nao inicializada' )
        console.error(resp)
        reject( resp.erro || resp.message || resp.messageText   )
      })
    });
  }



  cadastrar(nome: string, email: string, senha: string): Promise<Object> {
    return new Promise(resolve => {
      this.http.post('/usuario/', {user: {nome: nome, email: email, senha: senha}}).subscribe((resposta: any) => {
        resolve(resposta)
      })

    })
  }

  limpeSessaoLocal() {
    this.armazenamentoService.remova('idm2');
    this.armazenamentoService.remova(this.kEYUSER);
  }

  logout(): Promise<boolean> {
    return new Promise(resolve => {
      this.http.post('/auth/logout', {}).subscribe((resposta: any) => {
        if(resposta.sucesso) {
          this.limpeSessaoLocal();

          return resolve(null)
        }

        resolve(false)
      });

    })
  }

  atualizeUsuario(usuario: any) {
    this.usuarioLogado.next(usuario);
  }

  envieEmailRecuperacao(email: any) {
    return this.http.post('/auth/usuario/recuperar', {email: email})
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  troqueSenha(token: string, senha: string): Promise <any> {
    return this.http.put('/auth/usuario/troquesenha/' + token, {senha: senha})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  verifiqueCodigoConfirmacao(token: any, codigo: any) {

    return this.http.post('/auth/token/verifique/' + token, {codigo: codigo})
      .toPromise().then(this.retorno).catch(this.handleError);

  }


  obtenhaToken(token: any) {
    return this.http.get('/auth/token/' + token)
      .toPromise().then(this.retorno).catch(this.handleError);
  }
}
