import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class BrindeService extends ServerService {
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  listeDaEmpresa(empresa: any) {
    let link =   '/empresas/' + empresa.id + "/brindes" ;

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeDoPlano(plano: any) {
    let link =   '/brindes/' + plano.id

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveBrinde(brinde: any): Promise<any> {
    return this.salve('/brindes', brinde );
  }

  trocarPontos(parametros) {
    return this.salve('/brindes/trocarPontos', parametros);
  }

  obtenhaComprovanteResgate(codigo: string){
    return this.obtenha('/brindes/comprovante/' + codigo, {})
  }

  removaBrinde(brinde: any) {
    return this.remova( "/brindes/" + brinde.id, {})
  }
}
