<div class="alert alert-danger mb-2" role="alert" *ngIf="msgErroAlcance">
  <i class="fas fa-exclamation-triangle"></i> {{msgErroAlcance}}
</div>

<kendo-grid
#grid
[data]="view"
(save)="salvarRaio($event)"
(edit)="editHandler($event)"
(add)="addHandler($event)"
(remove)="removeHandler($event)"
(cancel)="cancelHandler($event)"
[height]="330"
[loading]="carregando"
[pageSize]="gridState.take"
[pageable]="true"
[skip]="gridState.skip"
[sort]="gridState.sort"
[pageable]="true"
[sortable]="true"
(pageChange)="onPageChange($event)"
(dataStateChange)="onStateChange($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand type="button">Novo Alcance</button>
  </ng-template>
  <kendo-grid-column field="alcance" title="Alcance" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem.alcance}} KM</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'n0'"   name="alcance"
                            [(ngModel)]="dataItem.alcance" required
                            [min]="0"   placeholder="Alcance KM" >  </kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="taxa" editor="Taxa" title="Taxa" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem.taxa | currency: "BRL"}}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'c2'"   name="taxa"
                            [(ngModel)]="dataItem.taxa" required
                            [min]="0"   placeholder="Valor R$" >  </kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="desativado"  title="Ativo" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      <i class="fa fa-check text-success" *ngIf="!dataItem.desativado"> </i>
      <i class="fa fa-times text-danger" *ngIf="dataItem.desativado"> </i>
    </ng-template>

    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <input   name="exibirTelaBusca" type="checkbox"  class="k-checkbox"   kendoCheckBox
             [(ngModel)]="dataItem.ativo"
             />
    </ng-template>

  </kendo-grid-column>


  <kendo-grid-command-column title="" [width]="170">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand type="button">Editar</button>
      <button kendoGridRemoveCommand type="button" [primary]="true">&nbsp;<i class="fa fa-trash"></i>&nbsp;</button>
      <button kendoGridSaveCommand type="button">{{ isNew ? 'Salvar': 'Salvar' }}</button>
      <button kendoGridCancelCommand type="button">Fechar</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja apagar esse alcance?</p>
  <kendo-dialog-actions>
    <button kendoButton type="button" (click)="close('sim')" primary="true">Sim</button>
    <button kendoButton  type="button" (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
