import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ProdutoService} from "../../../services/produto.service";

@Component({
  selector: 'app-produto-preco',
  templateUrl: './produto-preco.component.html',
  styleUrls: ['./produto-preco.component.scss']
})
export class ProdutoPrecoComponent implements OnInit,  OnChanges  {
  @Input() produto: any = {}
  @Input() catalogo: any = {};
  @Input() empresa: any = {};
  @Input() podeEditar: any = {};
  precoPorEmpresa: boolean;
  atualizarPreco: boolean
  salvando: boolean
  precoExibir: any;
  precoEditado: any;
  novoPrecoExibir: any;
  constructor( private produtoService: ProdutoService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    this.precoPorEmpresa = this.catalogo.precoPorEmpresa;
    const produtoNaEmpresa = this.produto.produtoNaEmpresa;
    const produtoNaEmpresaExiste = this.precoPorEmpresa && produtoNaEmpresa;


    if(!this.produto.valorMinimo){
      this.precoExibir = produtoNaEmpresaExiste && produtoNaEmpresa.preco ? produtoNaEmpresa.preco : this.produto.preco;

      if(!this.precoExibir && this.produto.valor != null) //opção de adicional
         this.precoExibir = this.produto.valor;

    } else{
      this.precoExibir = this.produto.valorMinimo;
    }

    this.novoPrecoExibir = produtoNaEmpresaExiste && produtoNaEmpresa.novoPreco ? produtoNaEmpresa.novoPreco : this.produto.novoPreco;

    this.produto.precoTela = this.novoPrecoExibir != null ? this.novoPrecoExibir : this.precoExibir;
  }


  obtenhaFormatoPreco(){
    if(!this.produto.tipoDeVenda || this.produto.tipoDeVenda !== 'Peso')
      return '$0.00'

    return String(`$ 0.00/ kg`);
  }

  salvarPreco(){

    if(this.precoExibir !== this.precoEditado){
      if(this.salvando) return;

      this.salvando = true;
      this.produtoService.salvePrecoProduto(this.catalogo, this.empresa, this.produto, this.precoEditado).then( resposta => {
        this.precoExibir  = this.precoEditado
        this.salvando = false;
        this.cancelarPreco();
      }).catch((err) => {
        this.salvando = false;
         alert(err)
      })

    } else {
      this.cancelarPreco()
    }
  }

  editarPreco() {
    this.precoEditado = this.precoExibir;
    this.atualizarPreco = true;
  }

  cancelarPreco(){
    this.atualizarPreco = false;
  }
}
