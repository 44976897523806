import {Component, Input, OnInit} from '@angular/core';
import {AtividadeService} from "../../../services/atividade.service";
import {PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";

import {CadAtividadeComponent} from "../cad-atividade/cad-atividade.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

import {KendoPopupUtils} from "../../../lib/KendoPopupUtils";

@Component({
  selector: 'app-atividades',
  templateUrl: './atividades.component.html',
  styleUrls: ['./atividades.component.scss']
})
export class AtividadesComponent implements OnInit {
  @Input() private empresa: any;
  atividades: any = [];
  paginacao = {
    size: 10,
    skip: 0,
    total: 0,
    buttonCount: 5,
    info: true,
    type: "numeric"  ,
    pageSizes: false,
    previousNext: true
  }
  loading: any;
  abraDialogo: boolean;
  atividadeSelecionada: any;
  excluindoAtividade: boolean;
  removeu: boolean;
  mensagemRemocao: string;
  constructor(private  atividadeService: AtividadeService ,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.atividadeService.listeAtividadesEmpresa(this.empresa).then( atividades => {
        this.atividades =  atividades;
    })
  }

  exibaModalAtividade(atividadeEditar: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadAtividadeComponent,
      minWidth: 250,
      width: window.innerWidth > 500 ? 500 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100
    });

    windowRef.content.instance.empresa = this.empresa;

    if(atividadeEditar)
      windowRef.content.instance.setAtividade(atividadeEditar);


    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe(
      (result: any) => {
        if(atividadeEditar) return;

        if(result && result.id){
          this.atividades.push(result)
          this.paginacao.size = this.atividades.length;
        }
      });

  }

  editarAtividade({ sender, rowIndex, dataItem }) {
     this.exibaModalAtividade(dataItem)
  }

  mudouPaginacao($event: PageChangeEvent) {
    console.log($event)
  }

  fechar() {
    this.atividadeSelecionada = null;
    this.mensagemRemocao = null;
    this.excluindoAtividade = false;
    this.abraDialogo = false;

  }

  removerAtividade() {
    this.excluindoAtividade = true;

    this.atividadeService.removaAtividade(this.atividadeSelecionada).then((resposta) => {

      this.atividadeService.listeAtividadesEmpresa(this.empresa).then( atividades => {
        this.removeu = true;
        this.excluindoAtividade = false;
        this.mensagemRemocao = "A atividade '" + this.atividadeSelecionada.nome + "' foi removida "
        this.atividadeSelecionada = false;

        this.atividades =  atividades;
        this.paginacao.size = this.atividades.length;
      })

    }).catch((reason) => {
      this.excluindoAtividade = false;
      this.atividadeSelecionada = null;
      this.mensagemRemocao = reason;
    })
  }

  abrirDialogoRemoverAtividade($event: RemoveEvent) {
    this.atividadeSelecionada = $event.dataItem;
    this.abraDialogo = true;
  }

}
