import {Component, OnInit, ViewChild} from '@angular/core';
import {ContatosService} from '../services/contatos.service';
import {NgForm} from '@angular/forms';
import {PlanosService} from '../services/planos.service';
import {BuscarContatoComponent} from '../componentes/buscar-contato/buscar-contato.component';
import {InserirPontosComponent} from '../inserir-pontos/inserir-pontos.component';
import * as locale from '../locale/calendar-ptbr';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {AutorizacaoService} from "../services/autorizacao.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {ICriarContato} from "../ui/ICriarContato";
import {TelaExpandidaComponent} from "../pedidos/TelaExpandida";
import {DataHoraUtils} from "../lib/DataHoraUtils";

@Component({
  selector: 'app-novo-cartao',
  templateUrl: './novo-cartao.component.html',
  styleUrls: ['./novo-cartao.component.css']
})
export class NovoCartaoComponent extends TelaExpandidaComponent implements OnInit, ICriarContato {
  @ViewChild('frm', { static: true})  frm: NgForm;
  @ViewChild('frmPts', { static: true})  frmPts: NgForm;
  @ViewChild('appInserirPts') appInserirPts: InserirPontosComponent;
  @ViewChild('buscarContato') buscarContato: BuscarContatoComponent;
  @ViewChild('buscarQuemIndicouComponent') buscarQuemIndicouComponent: BuscarContatoComponent;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  contato: any = {};
  plano: any = {};
  cartao: any = {};
  planos = [];
  contatoQuemIndicou: any;
  ptBr: any = {};
  mensagemSucesso: string ;
  mensagemErro: string;
  salvando = false ;
  usuario: any;
  deveInserirPontos = true;
  idAleatorio = '';
  abraDialogo = false;
  bloqueado = false;

  nomeContato: any;
  telefone: any;
  valorPedido: number;
  constructor(private contatosService: ContatosService, private activatedRoute: ActivatedRoute,
              private planosService: PlanosService, private autorizacaoService: AutorizacaoService,
              private _location: Location,  private router: Router, private constantsService: ConstantsService) {
    super();
    this.ptBr = locale.ptBR
    this.idAleatorio = Math.random() + '';
  }
  urlRetorno: any;
  informarCpf: any;
  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser() || {}
    this.nomeContato = this.activatedRoute.snapshot.queryParams.nome
    this.telefone = this.activatedRoute.snapshot.queryParams.tel
    this.informarCpf = this.constantsService.campoCpf$.subscribe(data => this.informarCpf = data);
    this.planosService.listePlanos().then( planos => {
      this.planos = planos;
      this.setPlanoDoCartao();
    });

    this.autorizacaoService.estaLogado().then( (resposta: any) => {
      this.bloqueado = resposta.bloqueado;
      if (!resposta.logado)
        this.redirecioneTelaLogin();

    });

    this.urlRetorno = this.activatedRoute.snapshot.queryParams.urlRetorno;

    const { contato, valor } = window.history.state;

    setTimeout( () => {
      if(contato && contato.id){
        this.contato = contato;
        this.valorPedido = valor;
        this.buscarContato.exibaContato(this.contato)
      }

      if(!this.contato.id) {
        this.setContato({cartoes: [], nome: this.nomeContato, telefone: this.telefone});
        this.buscarContato.exibaContato({cartoes: [], nome: this.nomeContato, telefone: this.telefone});
      }

      this.buscarContato.onContatoBuscado = (contatoBuscado: any) => {
        if ( contatoBuscado && contatoBuscado.id) {
          this.setContato(contatoBuscado);
        } else if (this.contato.id) {
          this.setContato({ cartoes: [] });
        }
      };

      if( this.buscarQuemIndicouComponent ) {
        this.buscarQuemIndicouComponent.onContatoBuscado = (contatoIndicado: any) => {
          if (contatoIndicado && contatoIndicado.id) {
            this.contatoQuemIndicou = contatoIndicado;
          } else if (this.contato.id) {
            this.contatoQuemIndicou = null;
          }
        };
      }
    }, 0);
  }

  redirecioneTelaLogin(){
    this.router.navigateByUrl('/admin/login?t=' + window.location.pathname);
  }

  setContato(contato){
    if( contato.dataNascimento )
      contato.dataNascimento = DataHoraUtils.obtenhaDataDaString( contato.dataNascimento );

    this.contato = Object.assign({}, contato);

    this.setCartao(this.planos.length === 1 ? this.contato.cartoes[0] : null )
  }

  setCartao(cartao){
    this.cartao = cartao || {}
    this.setPlanoDoCartao();
    if( this.appInserirPts )
      this.appInserirPts.setCartao(this.contato, this.cartao, null);
  }

  setPlanoDoCartao() {
    if( !this.cartao.id ){
      this.plano = null;
    } else {
      for ( let i = 0; i < this.planos.length; i++ ) {
        if ( this.cartao.plano && this.cartao.plano.id === this.planos[i].id ) {
          this.plano = this.planos[i];
          break;
        }
      }
    }

  }

  alterouPlano() {
    this.contato.cartoes.forEach((cartao) => {
      if(cartao.plano.id === this.plano.id){
        this.setCartao(cartao);
        if( this.appInserirPts )
          this.appInserirPts.valorMudou();
      }
    })
  }

  onSubmit() {
    this.buscarContato.valide();

    if ( !this.frm.valid )
      return;

    this.cartao.contato = this.contato;

    this.cartao.contato.telefone = this.buscarContato.obtenhaTelefone();
    this.cartao.contato.codigoPais = this.buscarContato.obtenhaCodigoPais();

    if(!this.cartao.id)
       this.cartao.plano = this.plano;

    if( this.contatoQuemIndicou ) {
      this.cartao.contato.quemIndicou = {
        id: this.contatoQuemIndicou.id
      }
    } else {
      if( this.buscarQuemIndicouComponent && this.buscarQuemIndicouComponent.obtenhaTelefone() !== '' ) {
        this.abraDialogo = true;
        return;
      }
    }

    delete this.mensagemErro
    delete this.mensagemSucesso;

    this.salvando = true;

    this.contatosService.salveCartao(this.cartao).then( resp =>   {
      this.contato.id = resp.idContato;
      this.contato.ultimaVisita = new Date();
      if(resp.id){
        this.mensagemSucesso = 'Cartão criado com sucesso!';
        this.cartao.id = resp.id;
        this.contato.cartoes.push(this.cartao)
        if( this.appInserirPts )
          this.appInserirPts.setCartao(this.contato, this.cartao, this.valorPedido);
      } else {
        this.mensagemSucesso = 'Contato criado com sucesso!';
      }
      /*
      if( this.deveInserirPontos === false )
        this.activeModal.close(this.contato)
       */

      this.salvando = false;
    }).catch(erro => {
      this.salvando = false;
      this.mensagemErro = erro;
    });
  }

  onSubmitPontos() {
    if( this.salvando) return;

    this.salvando = true;

    this.appInserirPts.registrePontuacao().then( (resposta: any) => {
      this.salvando = false;
      this.resetFormulario();
      this.novo();
      this.mensagemSucesso = 'Pontuação adicionada com sucesso. Agora o cliente ' + resposta.nome +  ' tem: ' +
        resposta.novaPontuacao +  " " + resposta.tipo;


      if(this.urlRetorno) {
          this.retorne(resposta.codigo)
      }


    }).catch( (erro) => {
      this.salvando = false;
      this.mensagemErro = erro;
    });
  }

  retorne(codigo){
    let dados = {mensagemPontuacao: this.mensagemSucesso, codigoPontuacao:  codigo  };
    this.router.navigateByUrl(this.urlRetorno, { state: dados});
  }



  exibaContato(contato: any) {
    this.setContato(contato)
    this.buscarContato.exibaContato(this.contato);
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = null;
    this.resetFormulario();
  }

  fecheMensagemErro() {
    this.mensagemErro = null;
  }

  resetFormulario(){
    this.buscarContato.reset();
    this.frm.resetForm();
    this.frmPts.resetForm();
  }

  close(cancel: string) {
    this.abraDialogo = false;
    if( cancel === 'nao' ) {
      return;
    }
    this.buscarQuemIndicouComponent.criarContatoIndicacao();
  }

  obtenhaComponenteCriarContato(): any {
    return NovoCartaoComponent;
  }

  novoContato() {
    this.setContato({cartoes: []})
    this.fecheMensagemErro();
    this.fecheMensagemSucesso();
  }

  novoCartao(){
    this.setCartao({})
    this.fecheMensagemErro()
  }

  novo() {
    if(this.planos.length > 1){
     this.novoCartao();
    } else {
      this.novoContato();
    }
  }

  voltar() {
    if(this.urlRetorno){
      this.retorne(null)
    } else {

      this._location.back();
    }
  }

  fechar() {
    this.voltar()
  }
}
