<!-- Begin page -->
<div id="wrapper">
  <!-- Topbar Start -->
  <div class="navbar-custom" >
    <ul class="list-unstyled topnav-menu float-right mb-0">

      <li class="dropdown notification-list">
        <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown"
           href="#" role="button" aria-haspopup="false" aria-expanded="false">
          <img style="width:32px; height: 32px;" src="/assets/fidelidade/icones/user-icon.png" alt="user-image">
          <span class="pro-user-name ml-1">
                                {{usuario.nome}} <i class="mdi mdi-chevron-down"></i>
                            </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
          <!-- item-->
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bem vindo!</h6>
          </div>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <i class="fe-user"></i>
            <span>Minha Conta</span>
          </a>
          <div class="dropdown-divider"></div>

          <!-- item-->
          <a href="javascript:void(0);"    class="dropdown-item notify-item">
            <i class="fe-log-out"></i>
            <span>Logout</span>
          </a>

        </div>
      </li>

    </ul>

    <!-- LOGO -->
    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <li>
        <button   class="button-menu-mobile waves-effect waves-light">
          <i class="fe-menu"></i>
        </button>
        <a class="navbar-brand" href="#"  >

        </a>
      </li>
    </ul>
    <div class="logo-box">
      <a href="index.html" class="logo text-center">
                        <span class="logo-lg">
                            <img src="/assets/fidelidade/promokit-horizontal-branco.png" alt="" height="36">
                          <!-- <span class="logo-lg-text-light">UBold</span> -->
                        </span>
        <span class="logo-sm">
                            <!-- <span class="logo-sm-text-dark">U</span> -->
                            <img src="/assets/fidelidade/zapkit-logo.png" alt="" height="36">
                        </span>
      </a>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <!--
      <li>
        <button class="button-menu-mobile waves-effect waves-light">
          <i class="fe-menu"></i>
        </button>
      </li>
      -->
      <li class="d-none d-sm-block">
        <form class="app-search">
          <div class="app-search-box">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Buscar...">
              <div class="input-group-append">
                <button class="btn" type="submit">
                  <i class="fe-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </li>
    </ul>
  </div>


  <div class="content-page">
    <div class="content">
    </div>
  </div>
</div>
