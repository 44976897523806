import { Component, OnInit } from '@angular/core';
import {ChavesIntegracaoService} from "../../services/chaves-integracao.service";
import {SistemaIntegradoService} from "../../services/sistema-integrado.service";

@Component({
  selector: 'app-grid-chaves-integracao',
  templateUrl: './grid-chaves-integracao.component.html',
  styleUrls: ['./grid-chaves-integracao.component.scss']
})
export class GridChavesIntegracaoComponent implements OnInit {
  chavesIntegracao: any;
  carregando: any;
  groups: any;
  mensagemCopiada: any;
  sistemasIntegrados: any;

  constructor(private chavesIntegracaoService: ChavesIntegracaoService,
              private sistemaIntegradoService: SistemaIntegradoService) {
    this.carregueSistemasIntegrados()

  }

  ngOnInit(): void {

    this.carregueChaves()
  }

  copiarSegredo(segredo: any) {


  }

  desativarIntegracao(chaveIntegracao: any) {
    this.chavesIntegracaoService.desativeChave(chaveIntegracao).then((resposta) => {
      chaveIntegracao.ativo = resposta.ativo;
    })

  }

  desativarAcessoDireto(chaveIntegracao: any) {
    this.chavesIntegracaoService.desativeAcessoDireto(chaveIntegracao).then((resposta) => {
      chaveIntegracao.acessoDireto = resposta.acessoDireto;
    })

  }

  ativarAcessoDireto(chaveIntegracao: any) {
    this.chavesIntegracaoService.ativeAcessoDireto(chaveIntegracao).then((resposta) => {
      chaveIntegracao.acessoDireto = resposta.acessoDireto;
    })

  }

  ativarIntegracao(chaveIntegracao: any) {
    this.chavesIntegracaoService.ativeChave(chaveIntegracao).then((resposta) => {
      chaveIntegracao.ativo = resposta.ativo;
    })
  }

  private carregueChaves() {
    this.chavesIntegracaoService.todas().then((chavesIntegracao: any) => {
      this.chavesIntegracao = chavesIntegracao
    })

  }

  alterouSistema(dataItem: any, $event: any) {
    this.chavesIntegracaoService.atualizeSistemaIntegrado(dataItem).then(() => {
      return;
    })
  }

  private carregueSistemasIntegrados() {
    this.sistemaIntegradoService.todos().then((sistemasIntegrados: any) => {
      this.sistemasIntegrados = sistemasIntegrados
    })
  }
}
