<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

    <div class="form-group mb-3">
      <label for="nome">Nome do Produto</label>
      <input type="text" class="form-control" autocomplete="off"
             id="nome" name="nome" [(ngModel)]="produto.nome" #nome="ngModel"
             placeholder="Nome do Produto" value="" required appAutoFocus [autoFocus]="true">
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="nome">Descrição do Produto</label>
      <input type="text" class="form-control" autocomplete="off" required
             id="descricao" name="descricao" [(ngModel)]="produto.descricao" #descricao="ngModel"
             placeholder="Descricao do Produto"       >
      <div class="invalid-feedback">
        <p *ngIf="descricao.errors?.required">Descrição é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3"  >
      <label for="valorPorPonto">Preço</label>
      <kendo-numerictextbox id="valorPorPonto" [min]="0"  class="form-control" name="preco" #preco=ngModel
                            [(ngModel)]="produto.preco" required>
      </kendo-numerictextbox>

      <div class="invalid-feedback">
        <p *ngIf="preco.errors?.required">Preço é obrigatório</p>
      </div>
    </div>


    <app-upload-imagem [objeto]="produto" [maxsize]="800"></app-upload-imagem>

    <p class="mt-2 mb-2 text-danger"  *ngIf="erro"   >
      <i class="fa fa-times mr-1"></i> {{erro}}
    </p>

  <button type="submit" class="btn btn-primary waves-effect waves-light width-lg mt-2"  >
    <i class="fa fa-save fa-lg fa-fw"></i>Salvar</button>
</form>
