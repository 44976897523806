<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Sincronizar Estoque
  </h4>
</kendo-dialog-titlebar>

<div  class="modal-body">

  <h4  *ngIf="buscando">
    <i class="k-i-loading k-icon"></i>
    Verificando novas atualizações, isso pode demorar alguns minutos... </h4>

  <h5 *ngIf="ultimaSincronizacao">Ultima sincronização estoque realizada em <b>{{ultimaSincronizacao | date: 'dd/MM/yyyy HH:mm'}}</b></h5>

  <div class="alert alert-danger alert-dismissible fade show mt-2 mb-2" role="alert" *ngIf="erro">
    {{erro}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <ng-container *ngIf="!buscando">
    <div *ngIf="tudoSincronizado" class="alert alert-info" role="alert">
      Não há nenhum produto para sincronizar.
      <button class="btn btn-warning mt-1" (click)="busqueTodosEstoque()" *ngIf="empresa.integracaoDelivery.sistema ==='bluesoft'">
        Nova busca sem a data ultima sincronização
      </button>
    </div>

    <div *ngIf="sincronizado" class="alert alert-success" role="alert">
      Sincronização realizada com sucesso!
      <button type="button" class="btn btn-light waves-effect" (click)="fecheModal()">
        Fechar
      </button>
    </div>


    <ng-container *ngIf="!sincronizado && !tudoSincronizado">
      <h4  class="float-left">
        Foram encontrados <b>{{produtosSincronizar.length}}</b> produtos
      </h4>

      <button class="btn btn-danger d-inline-block float-right" (click)="salvarDisponiblidades()" [disabled]="salvando">
        <i class="k-i-loading k-icon " *ngIf="salvando"></i>
        Atualizar todos
      </button>
      <div class="clearfix"></div>
    </ng-container>


    <ng-container *ngIf="produtosSincronizar.length">
      <h5><b>{{produtosSincronizar.length}}</b> Produtos  </h5>

      <kendo-grid   [data]="produtosSincronizarFiltro"  [isDetailExpanded]="expandInStockProducts"
                    [kendoGridBinding]="produtosSincronizarFiltro" height="700"
                    [loading]="buscando" [scrollable]="true">

        <ng-template kendoGridToolbarTemplate [position]="'top'">

          <div class="row">
            <div class="col-12" *ngIf="!salvando">
              <input kendoTextBox placeholder="Busque por nome, categoria , ordem ou valor" class="form-control busca" [(ngModel)]="filtro"
                     appAutoFocus kendoTextBox (input)="onFilter($event)" *ngIf="!salvando"/>
            </div>
            <div class="col-12" *ngIf="salvando">
              <h6 class="text-black-50">Produtos sincronizados
                <i class="k-icon k-i-loading" ></i>
              </h6>

              <kendo-progressbar [label]="label" [value]="percentualSalvos" [style.width.%]="100" [emptyCssStyle]="emptyStyles"
                                 [progressCssStyle]="progressStyles"></kendo-progressbar>
            </div>
          </div>

        </ng-template>

        <kendo-grid-column title="Id" field="id"            width="75"> </kendo-grid-column>
        <kendo-grid-column title="Cod." field="codigoPdv"    width="100"> </kendo-grid-column>
        <kendo-grid-column title="Imagem" class="coluna_menor" width="110">
          <ng-template kendoGridCellTemplate let-produto >

            <img [src]="produto.linkImagem" style="width: 80px;" *ngIf="produto.linkImagem">

            <i class="fe-image fa-2x" *ngIf="!produto.linkImagem"></i>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Nome" field="nome"     >
          <ng-template kendoGridCellTemplate let-produto  >
            <b>{{produto.nome }}</b>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Disponivel"   width="150">
          <ng-template kendoGridCellTemplate let-produto >
            <ng-container *ngIf="!produto.sincronizarSoAdicionais">
              <span *ngIf="produto.indisponivel" class="text-danger"><b>Não</b></span>
              <span *ngIf="!produto.indisponivel" class="text-success"><b>Sim</b></span>
            </ng-container>

            <span *ngIf="produto.sincronizarSoAdicionais" class="text-danger"><b>-</b></span>

          </ng-template>
        </kendo-grid-column>



        <kendo-grid-command-column title="" width="75px" [class]="{'comandos': true}">
          <ng-template kendoGridCellTemplate let-produto >
            <button kendoGridEditCommand [primary]="true"  *ngIf="!salvando && !produto.salvando && !produto.sincronizado"
                    (click)="salveEstoque(produto)"
                    kendoTooltip title="salvar disponibiliade">
              <i class="fa fa-save "></i>
            </button>

            <i class="k-icon k-i-loading" *ngIf="produto.salvando"></i>
            <i class="k-icon k-i-check-circle text-success fa-lg" *ngIf="produto.sincronizado"></i>

            <p class="text-danger" *ngIf="produto.erro">{{produto.erro}}</p>


          </ng-template>

        </kendo-grid-command-column>

        <ng-template kendoGridDetailTemplate let-produto     style="background: #fff;">
          <div class="alert alert-danger alert-dismissible fade show mb-2" *ngIf="produto.erro" role="alert">
            Não foi possivel importar esse produto: <br>
            {{produto.erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <kendo-grid *ngIf="produto.opcoesAtualizadas && produto.opcoesAtualizadas.length > 0"
                      [data]="obtenhaAdicionaisAgrupados(produto)"
                      [loading]="buscando"   >
            <kendo-grid-column title="Id" field="id"    width="75"> </kendo-grid-column>
            <kendo-grid-column title="Cod." field="codigoPdv"   width="150" > </kendo-grid-column>
            <kendo-grid-column title="Nome" field="nome"     >
              <span class="text-black-50"> <b>{{produto.nome }}</b></span>
            </kendo-grid-column>


            <kendo-grid-column title="Disponivel"   width="150">
              <ng-template kendoGridCellTemplate let-opcao  >
                <span *ngIf="opcao.ficouIndisponivel" class="text-danger"><b>Não</b></span>
                <span *ngIf="opcao.ficouDisponivel" class="text-success"><b>Sim</b></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="" field="nomeAdicional"   width="10"   >

              <ng-template kendoGridGroupHeaderTemplate let-value=value  >
                Adicional - {{value}}


              </ng-template>
              <ng-template kendoGridCellTemplate let-opcao  >
              </ng-template>

            </kendo-grid-column>

            <kendo-grid-column      width="80">
              <ng-template kendoGridCellTemplate let-opcao  >
                <i class="k-icon k-i-check-circle text-success fa-lg" *ngIf="produto.sincronizado"></i>
              </ng-template>
            </kendo-grid-column>

          </kendo-grid>
          <kendo-grid *ngIf="produto.tamanhosAtualizados && produto.tamanhosAtualizados.length > 0"
                      [data]="produto.tamanhosAtualizados"
                      [loading]="buscando"   >
            <kendo-grid-column title="Id" field="id"    width="75"> </kendo-grid-column>
            <kendo-grid-column title="Cod." field="codigoPdv"   width="150" > </kendo-grid-column>
            <kendo-grid-column title="Nome" field="descricao"     >
              <span class="text-black-50"> <b>{{produto.nome }}</b></span>
            </kendo-grid-column>


            <kendo-grid-column title="Disponivel"   width="150">
              <ng-template kendoGridCellTemplate let-tamanho  >
                <span *ngIf="tamanho.ficouIndisponivel" class="text-danger"><b>Não</b></span>
                <span *ngIf="tamanho.ficouDisponivel" class="text-success"><b>Sim</b></span>
              </ng-template>
            </kendo-grid-column>


            <kendo-grid-column      width="80">
              <ng-template kendoGridCellTemplate let-opcao  >
                <i class="k-icon k-i-check-circle text-success fa-lg" *ngIf="produto.sincronizado"></i>
              </ng-template>
            </kendo-grid-column>

          </kendo-grid>
        </ng-template>


      </kendo-grid>

    </ng-container>


  </ng-container>

</div>
