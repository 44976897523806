import {ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
  selector: 'app-dynamic-changelog',
  templateUrl: './dynamic-changelog.component.html',
  styleUrls: ['./dynamic-changelog.component.scss'],
})
export class DynamicChangelogComponent {
  changelogContent = ``;

  constructor() {
  }
}
