import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {UsuarioService} from "../../services/usuario.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-painel-sdr',
  templateUrl: './painel-sdr.component.html',
  styleUrls: ['./painel-sdr.component.scss']
})
export class PainelSDRComponent implements OnInit {
  @ViewChild('frm',  {static: false} ) frm: NgForm;

  assinarMensagens: boolean;
  assinatura: string;
  usuario: any = {
    assinarMensagens: false
  };
  msgSucesso = '';

  constructor(private router: Router, private autorizacaoService: AutorizacaoService,
              private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.autorizacaoService.usuarioLogado$.subscribe( (usuarioLogado) => {
      if( !usuarioLogado ) return;

      this.usuario = usuarioLogado;
    });
  }

  onSalvar() {
    this.usuarioService.salveAssinatura(this.assinarMensagens).then( (resposta: any) => {
      this.msgSucesso = "Salvou";
    });
  }

  logout() {
    if(this.autorizacaoService.logout() !== null)  {
      this.router.navigate(['/admin-sdr/login'], { }).then( () => {});
    }
  }

  naoFacaNada() {
    return false;
  }
}
