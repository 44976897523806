import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {GerenciadorDeAtendimentosService} from "../../services/gerenciador-de-atendimentos.service";
import {NotificationService} from "@progress/kendo-angular-notification";
import {WhatsappService} from "../../services/whatsapp.service";
import {BotsService} from "../../services/bots.service";

@Component({
  selector: 'app-notificacoes-promokit',
  templateUrl: './notificacoes-promokit.component.html',
  styleUrls: ['./notificacoes-promokit.component.scss']
})
export class NotificacoesPromokitComponent implements OnInit, AfterViewInit {
  @ViewChild("notificationContainer", { read: ViewContainerRef })
  public notificationContainer: ViewContainerRef;
  listaDeAtendimentos = [];


  constructor(private gerenciamentoDeAtendimentosService: GerenciadorDeAtendimentosService,
              private notificationService: NotificationService, private botsService: BotsService,
              private whatsappService: WhatsappService) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.gerenciamentoDeAtendimentosService.obtenhaTodosAtendimentosPendentes().then( (atendimentosDoBanco) => {
      this.listaDeAtendimentos = atendimentosDoBanco;
    });

    this.gerenciamentoDeAtendimentosService.atendimentosPendentes$.subscribe((atendimentos: any[]) => {
      this.listaDeAtendimentos = atendimentos;
    });
  }

  atenderCliente(dataItem: any) {
    this.whatsappService.abraConversa(dataItem.idChat);
  }

  encerrarAtendimento(dataItem: any) {
    this.gerenciamentoDeAtendimentosService.finalizePendencia(dataItem);

    // envia mensagem que atendimento foi finalizado
    this.whatsappService.envieMensagemNoWhatsapp({
      telefone: dataItem.telefone,
      tipo: 'CHAT',
      msg: 'Atendimento finalizado. Muito obrigado. Se precisar só falar com a gente novamente.'
    });

    this.botsService.reset(dataItem.telefone).then( () => {} );


  }
}
