import { Injectable } from '@angular/core';
import {ServerService} from "../../services/ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PromocoesService   extends  ServerService {
  constructor(http: HttpClient) {
    super(http)
  }

  salvePromocao(promocao: any) {
    return this.salve('/promocoes', promocao);
  }

  listePromocoes(filtro: any = {}) {
    return this.obtenha('/promocoes', filtro)
  }

  removaPromocao(promocao: any) {
    return this.remova( "/promocoes/" + promocao.id, {})
  }

  desativePromocao(promocao: any) {
    return this.salve('/promocoes/desative', promocao);
  }

  ativePromocao(promocao: any) {
    return this.salve('/promocoes/ative', promocao);
  }



}
