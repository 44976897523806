import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {PedidosService} from "../services/pedidos.service";
import {NgForm} from "@angular/forms";
import {ConstantsService} from "../fidelidade/ConstantsService";

@Component({
  selector: 'app-tela-finalizar-pedido-mesa',
  templateUrl: './tela-finalizar-pedido-mesa.component.html',
  styleUrls: ['./tela-finalizar-pedido-mesa.component.scss']
})
export class TelaFinalizarPedidoMesaComponent implements OnInit {
  @ViewChild('frm', { static: false})  frm: NgForm;
  contato: any = {};
  empresa: any = {};
  pedido: any = {};
  formasDePagamento = [];
  urlRetorno
  carregou: any;
  pagamento: any = {}
  erro
  finalizando
  constructor(private _location: Location, private activatedRoute: ActivatedRoute,
              private router: Router,
              private pedidosService: PedidosService, protected constantsService: ConstantsService) { }

  ngOnInit() {

    this.pedidosService.obtenhaPorCodigo(this.activatedRoute.snapshot.params.codigo).then((pedido) => {

      this.pedido = pedido || {};
      this.contato = pedido.cliente;
      this.empresa = pedido.empresa;
      this.carregouPedido();

    })

  }

  onSubmit(){
    if( !this.frm.valid || this.finalizando)
      return;

    delete  this.erro;
    this.finalizando = true;

    this.pedidosService.finalizePedidoMesa(this.pedido, this.pagamento).then( (resp: any) => {
      this.finalizando = false;
      if(resp.erroNotificar)
        this.erro = resp.erroNotificar;

      if(this.pedido.pago && resp.redirectAdicionarPontos  )
        this.adcionarPontos(this.contato, this.pedido.valor)
      else
        this.finalizouPedido();

    }).catch( erro => {

      this.finalizando = false;
      this.erro = erro;
    })
  }

  carregouPedido(){
    this.carregou = true;
    this.activatedRoute.queryParams.subscribe(params => {
      this.urlRetorno  = params.urlRetorno;

      this.constantsService.empresa$.subscribe( (empresa) => {
        if (!empresa) return;
        this.empresa = empresa;
      });
    })
  }

  finalizouPedido(){
    if(this.urlRetorno && !this.erro)
      this.router.navigateByUrl(this.urlRetorno, {
        state: { mensagem: 'Pedido  #' + this.pedido.codigo + ' finalizado com sucesso!', codigo: this.pedido.codigo}
                });

  }

  adcionarPontos(contato: any, valor: number) {
    let state: any = { contato: contato, valor: valor };
    let urlRetorno = this.urlRetorno ? this.urlRetorno : this.router.url

    if(contato.cartoes && contato.cartoes.length ){
      state.cartao = contato.cartoes[0];

      this.router.navigateByUrl('/admin/contatos/pontos/adicionar?urlRetorno=' + urlRetorno,
        { state: state });
    } else {

      this.router.navigateByUrl('/admin/contatos/novo?urlRetorno=' + urlRetorno,
        { state: state });
    }
  }

  voltar() {
    this._location.back();
  }

}
