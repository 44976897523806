import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {EnderecoService} from "../../services/endereco.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {PedidoLoja} from "../objetos/PedidoLoja";
import {Endereco} from "../objetos/Endereco";
import {FormEnderecoContatoComponent} from "../../fidelidade/form-endereco-contato/form-endereco-contato.component";
import {TelaExpandidaComponent} from "../../pedidos/TelaExpandida";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'app-tela-criar-endereco',
  templateUrl: './tela-criar-endereco.component.html',
  styleUrls: ['./tela-criar-endereco.component.scss']
})
export class TelaCriarEnderecoComponent extends TelaExpandidaComponent implements OnInit {
  @ViewChild('formEndereco', { static: true})  formEndereco: FormEnderecoContatoComponent;
  empresa;
  pedido: PedidoLoja;
  endereco: Endereco;
  telefone: any = '';
  urlRetorno: any;
  tituloPagina: string;
  editando: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private enderecoService: EnderecoService,
              private _location: Location, private pedidoLocalService: PedidoLocalService, private constantsService: ConstantsService) {
    super();
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      this.telefone = queryParams.telefone;
      this.urlRetorno = queryParams.urlRetorno;
    });

    this.endereco = Endereco.novo();

    if(this.router.url.indexOf('editar') >= 0) this.editando = true
  }

  ngOnInit() {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if(empresa){
        let formaDeEntregaComZona =
          empresa.formasDeEntrega.find( (formaDeEntrega) => formaDeEntrega.tipoDeCobranca === 'zona' &&
            formaDeEntrega.zonasDeEntrega &&  formaDeEntrega.zonasDeEntrega.length)

        if(formaDeEntregaComZona){
          this.formEndereco.selecionarBairroDaZona = formaDeEntregaComZona.selecionarBairroDaZona;
          this.formEndereco.zonasDeEntrega = formaDeEntregaComZona.zonasDeEntrega;
        }
      }
    })
    this.pedidoLocalService.salveUrlAtual(this.telefone, this.router.url, window.history.state);
    this.carreguePedidoDoLocalStorage();
  }

  voltar() {
    this.pedidoLocalService.removaUrlSalva(this.telefone);
    this._location.back();
  }

  salvouEndereco(endereco) {
    if(!this.endereco.descricaoCompleta &&  this.endereco.descricao)
       this.endereco.descricaoCompleta =  this.endereco.descricao;

    this.pedido.entrega.endereco = this.endereco;
    this.enderecoService.salveEnderecoLocal(this.pedido, this.endereco);
    this.pedidoLocalService.salvePedido(this.pedido, this.telefone);
    //this._location.back();
    this.router.navigate(['/admin/pedidos/novo/endereco'],
      {queryParams: {telefone: this.telefone, urlRetorno: this.urlRetorno}});
  }


  private carreguePedidoDoLocalStorage() {
    this.pedido = this.pedidoLocalService.obtenhaPedido(this.telefone);
    this.formEndereco.cepObrigatorio =  this.pedido.entrega && this.pedido.entrega.cepObrigatorio

    if (this.pedido.codigo) {
      this.tituloPagina = 'Editar Pedido #' + this.pedido.codigo
    } else
      this.tituloPagina = 'Novo Pedido'

    if (this.editando && this.pedido.entrega.endereco)
      this.endereco = this.pedido.entrega.endereco;
    else
      this.endereco = Endereco.novo();

    if(this.pedido.entrega && this.pedido.entrega.bairroOpcional)
      this.formEndereco.bairroObrigatorio = false;
  }

}

