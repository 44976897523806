import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CampanhaService extends ServerService {
  private novoSaldoMensagens = new BehaviorSubject<boolean>(null);

  novoSaldoMensagens$ = this.novoSaldoMensagens.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  protected salve(endpoint: string, obj: any): Promise<any> {
    const objeto = JSON.parse(obj.get('data'));

    if ( !objeto.id ) {
      return this.http.post(endpoint, obj)
        .toPromise().then(this.retorno).catch(this.handleError);
    } else {
      return this.http.put(endpoint, obj)
        .toPromise().then(this.retorno).catch(this.handleError);
    }
  }

  salveCampanha(campanha: any): Promise<any> {
    let arquivo = null;

    if( campanha.files && campanha.files.length > 0) {
      arquivo = campanha.files[0].rawFile;
    }

    return super.upload('/campanha', arquivo, campanha);
  }

  pararEnvio(campanha: any): Promise<any> {
    return this.facaPost('/campanha/pararEnvio', {id: campanha.id});
  }

  aprove(campanha: any): Promise<any> {
    return this.facaPost('/campanha/aprove', {id: campanha.id});
  }

  reprove(campanha: any): Promise<any> {
    return this.facaPost('/campanha/reprove', {id: campanha.id});
  }

  aceitarCampanhaRede(campanha: any): Promise<any> {
    return this.facaPost('/campanha/aceitarCampanhaRede', {id: campanha.id});
  }

  rejeitarCampanhaRede(campanha: any): Promise<any> {
    return this.facaPost('/campanha/rejeitarCampanhaRede', {id: campanha.id});
  }

  continuarEnvio(campanha: any): Promise<any> {
    return this.facaPost('/campanha/continuarEnvio', {id: campanha.id});
  }

  cancelarEnvio(campanha: any): Promise<any> {
    return this.facaPost('/campanha/cancelarEnvio', {id: campanha.id});
  }

  desative(campanha: any): Promise<any> {
    return super.salve('/campanha/desative', {id: campanha.id});
  }

  obtenhaSaldo(): Promise<any> {
    return this.obtenha('/campanha/saldo', {});
  }

  obtenhaCampanhas(tipoDeEnvio: string, status: string = '', campanhaDeRede: boolean = false): Promise<any> {
    const dados: any = {
      t: tipoDeEnvio,
      s: status
    };

    if( campanhaDeRede ) {
      dados['cr'] = 1;
    }

    return this.obtenha('/campanha/liste', dados);
  }

  obtenhaQtdeLidas(campanha:any ): Promise<any> {
    return this.obtenha('/campanha/' + campanha.id + "/qtdeLidas" ,{});
  }

  obtenhaCampanha(id: any) {
    return this.obtenha('/campanha/' + id,{});
  }

  inicieEnvio(id: any, dadosExtras: any) {
    return this.facaPost('/campanha/inicieEnvio/' + id, dadosExtras);
  }

  statusEnvio(idCampanha: any) {
    return this.obtenha('/campanha/statusEnvio/' + idCampanha,{});
  }

  listeMensagens(campanha: any, pagina: number, filtro: any = {}): Promise<any> {
    filtro.p = pagina;
    return this.obtenha('/campanha/' + campanha.id + "/mensagens" , filtro);
  }

  inicieEnvioTeste(id: string, telefone: string) {
    const url = '/campanha/envioTeste/inicie?idc=' + id + '&tel=' + telefone;

    return this.obtenha(url,{});
  }

  marqueCampanhaComoEnviada(id: string) {
    return this.obtenha('/campanha/marqueComoTestada/' + id,{});
  }

  mudouSaldo() {
    this.novoSaldoMensagens.next(true);
  }

  obtenhaCampanhasPendentes(): Promise<any> {
    return this.obtenha('/campanha/liste' ,{
      p: 1
    });
  }

  obtenhaSaldoIA() {
    return this.obtenha('/campanha/saldoIA', {});
  }
}
