import {FBAccountService} from "../services/fbaccount.service";

export function appInitializer(accountService: FBAccountService) {
  return () => new Promise(resolve => {
    resolve(null);

    // wait for facebook sdk to initialize before starting the angular app
    resolve(null);

    window['fbAsyncInit'] = function () {
      FB.init({
        appId: '1003567500405109',
        cookie: true,
        status: true,
        xfbml: true,
        version: 'v14.0'
      });

      // auto authenticate with the api if already logged in with facebook
      FB.getLoginStatus(({authResponse}) => {
        if (authResponse) {
          accountService.apiAuthenticate(authResponse.accessToken)
            .subscribe()
            .add(resolve);
        } else {
          resolve(null);
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v11.0&appId=1003567500405109&autoLogAppEvents=1";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}
