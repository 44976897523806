<div class="modal-content">

  <div class="modal-header" *ngIf="exibirFechar">
    <h4 class="modal-title" id="myModalLabel">


      Pagar Fatura </h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col-12 centralizado" [ngClass]="{' col-sm-9 ': !exibirFechar}" >
        <div class="text-center w-75 mx-auto mb-2"  [hidden]="exibirFechar" >
          <img class="imagem_empresa" src="/images/empresa/{{empresa.logo}}" *ngIf="empresa.logo" height="70px"/>
          <h3>{{empresa.nome}}</h3>
        </div>

        <app-pagamento-cartao [contrato]="contrato" [fatura]="fatura" [modal]="true">

        </app-pagamento-cartao>

        <div  *ngIf="fatura?.pix"  [hidden]="fatura.paga" class="mt-2">

          <h4 class="page-title">  Pagamento com PIX</h4>


          <div class="card-box" style="    overflow: hidden;">
            <h5 class="text-center mt-2">QRCode Pix</h5>

            <img class="pix" [src]="fatura.pix.qrcode" >

            <button *ngIf="!mensagemCopiada" class="btn btn-blue btn-copia centralizado" (click)="copyMessage(fatura.pix.qrcodeText)"
            >Pix Copia e Cola</button>

            <button *ngIf="mensagemCopiada" class="btn btn-success btn-copia"
            >Pix Copiado</button>


            <h6> O que é Pix?</h6>
            <p> O Pix é um novo meio de pagamento instantâneo criado pelo Banco Central, que permite realizar pagamentos 24h por dia.
            </p>
            <p>Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneio QR Code acima  ou copie e código
              usando o Pix Copia e Cola para efetuar o um pagamento.</p>
          </div>





        </div>
      </div>

    </div>

  </div>
</div>
