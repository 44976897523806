import {Component, Input, OnInit} from '@angular/core';

import {CadEnderecoContatoComponent} from "../cad-endereco-contato/cad-endereco-contato.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'resumo-endereco',
  templateUrl: './resumo-endereco.component.html',
  styleUrls: ['./resumo-endereco.component.scss']
})
export class ResumoEnderecoComponent implements OnInit {
  @Input() endereco: any = {}
  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  abraModalEditarEndereco() {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadEnderecoContatoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.setEndereco(this.endereco);

    windowRef.result.subscribe((result: any) => {
      if(result && result.id) {
        this.endereco = Object.assign(this.endereco, result);
      }
    } );
  }

}
