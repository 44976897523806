import { Component, OnInit } from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {IOperacoesModuloPedidos} from "../../superadmin/services/ioperacoesmodulopedidos";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-pedido-status',
  templateUrl: './modal-pedido-status.component.html',
  styleUrls: ['./modal-pedido-status.component.scss']
})
export class ModalPedidoStatusComponent extends ModalKendo implements OnInit {
  pedido: any = {};
  contato: any = {};
  empresa: any = {};
  novoStatus: any;
  modoGrupoLojas: boolean
  exibirApenasAtualizarEntregador: boolean


  constructor(private  grupolojasService: GrupolojasService,
              private  pedidosService: PedidosService) {
    super()
  }

  ngOnInit(): void {
    this.getService().obtenhaPorCodigo(this.pedido.guid, this.pedido.empresa.id).then((pedido) => {
      this.pedido = pedido || {};
      this.contato = pedido.cliente;
      this.empresa = pedido.empresa;
    });
  }


  alterouStatusPedido(erro){
    if(!erro){
      this.fecheModal(this.pedido)
    }
  }

  getService(): IOperacoesModuloPedidos {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }

}
