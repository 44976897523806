<h4 class="page-title"><i class="fas fa-cash-register"></i> Gestão de Caixa</h4>

<div class="card">
  <div class="card-body">
    <kendo-tabstrip class="nav-bordered mt-3">
      <kendo-tabstrip-tab [title]="'Caixa'" [selected]="true">
        <ng-template kendoTabContent>
          <app-gestao-de-caixa [online]="false"></app-gestao-de-caixa>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Caixa Online'">
        <ng-template kendoTabContent>
          <app-gestao-de-caixa [online]="true"></app-gestao-de-caixa>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Histórico de Caixa'">
        <ng-template kendoTabContent>
          <app-historico-de-caixa></app-historico-de-caixa>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Sangrias'">
        <ng-template kendoTabContent>
          <app-historico-sangrias></app-historico-sangrias>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
