<h4 _ngcontent-mki-c5="" class="page-title"><i class="fas fa-mail-bulk"></i> Marketing </h4>

<div class="card">
  <div class="card-body">
    <button type="button" class="btn btn-blue   btn-lg  waves-effect waves-light" (click)="novaCampanha()">
      <i class="fas fa-mail-bulk"></i> Nova Campanha
    </button>

    <div class="mt-3">
      <kendo-tabstrip class="nav-bordered" id="tabs" name="tabs" (tabSelect)="selecionouTipoDeCampanha($event)">
        <kendo-tabstrip-tab [id]="tipoDeEnvio.id" [title]="tipoDeEnvio.nome" *ngFor="let tipoDeEnvio of tiposDeEnvio; index as i;"
                            [selected]="i === 0">
          <ng-template kendoTabContent>
            <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
            <div *ngIf="campanhas.length == 0">
              <div class="justify-content-center text-center">
                <p><i class="fas fa-exclamation-triangle" style="font-size: 100px;color: #faa597;"></i>
                </p>
                <span style="font-size: 26px;">Você não tem campanhas ainda!</span>
              </div>
            </div>

            <div class="mt-1" *ngFor="let campanha of campanhas" style="border: solid 1px #e4e4e4; padding: 15px;border-radius: 5px;padding-left: 10px;">
              <app-exibir-campanha-rede [campanha]="campanha"></app-exibir-campanha-rede>
            </div>
          </ng-template>

        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
</div>
