import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";
import libphonenumber from "google-libphonenumber";
import {DataHoraUtils} from "../lib/DataHoraUtils";

const ERROS: any = {
  0 : 'Falha de conexão com servidor',
  404: 'Falha ao invocar servidor, recurso não encontrado',
  500: 'Falha ao tentar realizar operação, tente de novo mais tarde.',
  504: 'Falha ao tentar realizar operação, tente de novo mais tarde.' };
@Injectable({
  providedIn: 'root'
})
export class ContatosService  extends ServerService {

  constructor(http: HttpClient) {
    super(http);
  }

  novaPontuacao(pontuacaoRegistrada: any): Promise<any> {
    return this.salve('/contatos/novaPontuacao', pontuacaoRegistrada );
  }

  salveNovoCartao(cartao: any): Promise<any> {
    if(cartao.id && cartao.inseriu) return Promise.resolve({id: cartao.id})

    return this.salve('/cartoes2/novo', cartao );
  }

  salveCartao(cartao: any): Promise<any> {
    if(cartao.id && cartao.inseriu) return Promise.resolve({id: cartao.id})

    if(cartao.contato)
      cartao.contato = this.obtenhaDadosCantato(cartao.contato);

    return this.salve('/cartoes', cartao );
  }

  salveEndereco(endereco: any){
    return this.salve(String(`/contatos/endereco/${endereco.id}`), endereco );
  }

  removaEndereco(endereco: any){
    return this.remova( "/contatos/endereco/" + endereco.id, {})
  }

  obtenhaContatosAC(numZap: string): Promise<any> {
    return this.obtenha('/contatos/autocomplete/' + numZap, { });
  }

  obtenhaContato(numZap: string): Promise<any> {
    if(!numZap) return Promise.resolve(null);

    return this.obtenha('/contatos/' + numZap, { });
  }
  obtenhaContatoPorCpf(cpf: string): Promise<any> {
    return this.obtenha('/contatos/cpf/' + cpf, { });
  }

  obtenhaContatoPorId(id: string): Promise<any> {
    return this.obtenha('/contatos/id/' + id, { });
  }

  obtenhaUltimasAcoes(contato: any= null, cartao: any = null,
                      inicio: number = 0, total: number = 20, soPontuado: boolean = null): Promise<any> {
    const params: any =  {i: inicio, t: total};

    if (contato) params.cid = contato.id;
    if (cartao)  params.caid = cartao.id;
    if (soPontuado)  params.sp = true;

    return this.obtenha('/api/ultimasAcoes', params);
  }

  obtenhaResumoVendas(referencia): Promise<any> {
    return this.obtenha('/contatos/resumoVendas/' + referencia , { });
  }

  obtenhaRelatorioFidelidade(dtInicio: string, dtFim: string, qtdeVendasRecorrente: number, qtdeDiasEmRisco: number,
                             qtdeDiasPerdido: number, qtdeVendasVIP: number, ticketMedioVIP: number,
                             qtdeDiasPeriodo: number, rede: boolean = false, redeId: string): Promise<any> {

    let params: any = 'di=' + dtInicio + '&df=' + dtFim +
      '&qtdeVendasRecorrente=' + qtdeVendasRecorrente + '&qtdeDiasEmRisco=' + qtdeDiasEmRisco + '&qtdeDiasPerdido=' +
      qtdeDiasPerdido + '&qtdeVendasVIP=' + qtdeVendasVIP + '&ticketMedioVIP=' +
      ticketMedioVIP + '&qtdeDiasPeriodo=' + qtdeDiasPeriodo

    if(rede)
      params = params + '&r=1';

    if(redeId)
      params = params + '&rid=' + redeId;

    return this.obtenha('/api/relatorioClientes?' + params  , {});
  }

  obtenhaClientesFidelidadePorCategoria(categoria: string, dtInicio: string, dtFim: string, qtdeVendasRecorrente: number,
                                        qtdeDiasEmRisco: number,
                             qtdeDiasPerdido: number, qtdeVendasVIP: number, ticketMedioVIP: number,
                                        qtdeDiasPeriodo: number,  rede: boolean = false, redeId: string): Promise<any> {
    let params: any = 'di=' + dtInicio + '&df=' + dtFim +
      '&qtdeVendasRecorrente=' + qtdeVendasRecorrente + '&qtdeDiasEmRisco=' + qtdeDiasEmRisco + '&qtdeDiasPerdido=' +
      qtdeDiasPerdido + '&qtdeVendasVIP=' + qtdeVendasVIP + '&ticketMedioVIP=' + ticketMedioVIP + '&categoria=' + categoria
      + '&qtdeDiasPeriodo=' + qtdeDiasPeriodo

    if(rede)
      params = params + '&r=1';

    if(redeId)
      params = params + '&rid=' + redeId;

    return this.obtenha('/api/contatos?' + params , {});
  }

  obtenhaResumo(filtro): Promise<any> {
    const params = filtro || {}

    return this.obtenha('/contatos/resumo/liste' , params);
  }

  listeContatos(inicio, total, filtro): Promise<any> {
    const params = filtro || {}
    params.i = inicio;
    params.t = total;
    return this.obtenha('/contatos/' , params);
  }

  salveContato(contato: any): Promise<any> {
    return this.salve('/contatos', this.obtenhaDadosCantato(contato) );
  }

  private obtenhaDadosCantato(contato: any){
    let dadosContato = Object.assign({}, contato)

    if(contato.dataNascimento)
      dadosContato.dataNascimento = DataHoraUtils.obtenhaDiaMesAno(contato.dataNascimento)

    return dadosContato;
  }

  obtenhaComprovantePontucao(codigo: any) {
    return this.obtenha('/cartoes/comprovante/' + codigo, {});
  }

  removaContato(contato: any): Promise<any> {
    return this.remova( "/contatos/" + contato.id, {})
  }

  obtenhaPontuacaoVencer(cartao: any, inicio: string, fim: string ):  Promise<any> {
    let params: any = { cid: cartao.id };

    if(inicio)
      params.di = inicio;
    params.df = fim;

    return this.obtenha( "/api/pontuacoes/vencer", params);
  }

  ativarEnvioDeMensagemDeMkt(contato: any, ativar: boolean): Promise<any> {
    return this.facaPost('/contatos/' + contato.id + '/ativar', {ativar: ativar});
  }

  obtenhaContatosStatus() {
    return this.obtenha( "/contatos/status-contatos", {});
  }

  bloqueieContato(contato: any, bloquear: boolean) {
    return this.facaPost('/contatos/' + contato.id + '/bloqueie?tel=' + contato.telefone, {bloquear: bloquear});
  }

  desbloqueieContato(contato: any, bloquear: boolean) {
    return this.facaPost('/contatos/' + contato.id + '/desbloqueie?tel=' + contato.telefone, {bloquear: bloquear});
  }

  estornePontos(contato: any, pontuacao: any, motivoEstorno: string) {
    return this.facaPost('/contatos/' + contato.id + '/pontos/estorne', {codigo: pontuacao.codigo, m: motivoEstorno});
  }

  listeEmails(contato: any, inicio = 0, total = 10) {
    if(!contato || !contato.id) return Promise.resolve([]);

    return this.obtenha('/emails/contatos/' + contato.id, {i: inicio, t: total});
  }

  adicioneTag(contato: any, tag: any){
    return this.facaPost(String(`/contatos/${contato.id}/tags`), tag);
  }

  removaTag(contato: any, tag: any){
    return this.facaPost(String(`/contatos/${contato.id}/tags/remova`), tag);
  }

  extraiCodigoPaisETelefone(telefoneCompleto: string) {
    if(telefoneCompleto === '+5500000000000')
      return {codigoPais: '+55' , telefone: "00000000000"}

    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();


    let phoneNumber = null;

    try {
      phoneNumber = phoneUtil.parse(telefoneCompleto, 'ZZ');
    } catch (erro) {
      console.log('erro ao tentar converter: ' + telefoneCompleto, erro);
    }

    if (phoneNumber) {
      return { codigoPais: '+' + phoneNumber.getCountryCode(), telefone: phoneNumber.getNationalNumber().toString() };
    } else {
      return { codigoPais: null, telefone: null };
    }
  }
}
