import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {AutorizacaoService} from "../services/autorizacao.service";
import {Router} from "@angular/router";
import {interval, timer} from "rxjs";
import {AtivacaoAssinaturaComponent} from "../ativacao-assinatura/ativacao-assinatura.component";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {ConstantsService} from "./ConstantsService";
import {MonitoradorPedidos} from "./MonitoradorPedidos";
import {WhatsappService} from "../services/whatsapp.service";
import {StatusWhatsapp} from "../objetos/StatusWhatsapp";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {TelaFecharLojaComponent} from "../tela-fechar-loja/tela-fechar-loja.component";
import {ImpressaoService} from "../services/impressao.service";
import {NotificationService} from "@progress/kendo-angular-notification";
import {NotificationRef} from "@progress/kendo-angular-notification/dist/es2015/models/notification-ref";
import {ConfiguracoesMiaService} from "../services/configuracoes-mia.service";
import {KendoPopupUtils} from "../lib/KendoPopupUtils";
import {SocketService} from "../services/socket.service";

declare var $;

@Component({
  selector: 'app-fidelidade',
  templateUrl: './fidelidade.component.html',
  styleUrls: ['./fidelidade.component.scss']
})
export class FidelidadeComponent implements OnInit , OnDestroy {
  constructor(private router: Router, private renderer: Renderer2, private deviceService: DeviceDetectorService,
              private autorizacaoService: AutorizacaoService,
              private empresasService: EmpresasService, private constantsService: ConstantsService,
              public monitoradorPedidos: MonitoradorPedidos, private whatsappService: WhatsappService,
              private notificationService: NotificationService,
              private dialogService: DialogService, private impressaoService: ImpressaoService,
              private configuracoesMiaService: ConfiguracoesMiaService,
              private socketService: SocketService) { }
  @ViewChild('template', { read: TemplateRef }) notificationTemplate: TemplateRef<any>;
  @ViewChild('templateErro', { read: TemplateRef }) notificationTemplateErro: TemplateRef<any>;
  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;
  errosImpressao: any = []
  usuario: any = {};
  bloqueado = true;
  public innerWidth: any;
  tarefaMonitorar: any;
  possuiModuloChatbot: boolean;
  statusWhatsapp: StatusWhatsapp;
  empresa: any;
  timerAberto: any;
  possuiModuloFidelidade: boolean;
  possuiModuloPedidos: boolean;
  notificationImpressao: NotificationRef;
  notificacoesErros = []
  modoGarcom: boolean;
  isMobile: boolean;
  configuracoesMia: any;
  statusBot  = '';
  miaLigada = false;
  modoTeste = false;
  servico: any;
  opened = true;

  static abraComoPopup(dialogService: DialogService, isMobile: boolean ): DialogRef {
    let altura: any = window.innerHeight - 100;
    if( isMobile ) {
      altura = '100%';
    }

    let largura: any = '100%';

    if( window.innerWidth > 600 ) {
      largura = 'calc(100% - ' + (window.innerWidth - 600) + 'px)';
    }

    const windowRef = dialogService.open({
      title: 'Fechar Loja',
      content: TelaFecharLojaComponent,
      minWidth: 250,
      width: largura,
      maxHeight: altura
    });

    const $divDialog = windowRef.dialog.location.nativeElement;

    if( $divDialog ) {
      const $divDialogContent = $divDialog.getElementsByClassName('k-dialog-content');

      if( $divDialogContent.length > 0 ) {
        $divDialogContent[0].classList.add('dialog-pagamento');
      }
    }

    const telaCartaoDeCreditoComponent: TelaFecharLojaComponent = windowRef.content.instance;
    telaCartaoDeCreditoComponent.inicialize(windowRef);

    return windowRef;
  }

 async ngOnInit() {
   this.isMobile = this.deviceService.isMobile()

   this.carregueMia();

   if(!this.isMobile) {
     console.log('assinando evento do impressão service')
     this.impressaoService.conectandoQzTray.subscribe((conectando) => {
       console.log('recebeu notificação: ' + conectando)
       if (conectando)
         this.exibaAlertaConectando()
       else
         this.fecheAlertaConectantoImpressora()

     })

     this.impressaoService.onErrosImpressao.subscribe( (erro: any) => {
       if(erro) this.exibaNotificacaoErros(erro)
     })
   } else {
     console.log('Dispositivo móvel, impressão não será ativada')
   }


    this.innerWidth = window.innerWidth;
    let usuarioLogado = this.autorizacaoService.getUser();

    if( usuarioLogado != null ) {
      this.usuario = usuarioLogado;
      this.modoGarcom = this.usuario.garcom
    }

    this.monitoreLogado();

    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if( !empresa )     return;
      this.empresa = empresa;
      this.servico = empresa.horariosDoServico[0];

      this.monitoreLojaFechada();

    });

    this.empresasService.obtenhaContrato().then(  (contrato: any) => {
      if(!contrato.ativo){
        this.abraModalAtivaoAssinatura(contrato);
      } else {
        this.bloqueado = false;
      }
    });

    this.autorizacaoService.usuarioLogado$.subscribe( (usuario) => {
      if( !usuario ) return;

      this.usuario = usuario;
      this.modoGarcom = this.usuario.garcom
    });

    this.constantsService.moduloChatbot$.subscribe( (data) => {
      this.possuiModuloChatbot = data;
    });
    this.constantsService.moduloFidelidade$.subscribe( (data) => {
      this.possuiModuloFidelidade = data;
    });

    this.constantsService.moduloPedido$.subscribe( (data) => {
      this.possuiModuloPedidos = data;
       if(data)
         this.monitoradorPedidos.inicieMonitoramento();
    });

    this.whatsappService.status$.subscribe( (status: StatusWhatsapp) => {
      if(!this.statusWhatsapp?.online && status.online) {
        this.whatsappService.notifiqueMensagensAntigas();
      }

      this.statusWhatsapp = status;
    });
  }

  private carregueMia() {
    this.configuracoesMiaService.configuracoes$.subscribe((configuracoesMia) => {
      if( !configuracoesMia ) return;

      this.configuracoesMia = configuracoesMia;
      //converte o status do chatbot em uma descrição
      if (this.configuracoesMia?.status === 'MODO_TESTE') {
        this.statusBot = 'em Modo teste';
        this.miaLigada = true;
        this.modoTeste = true;
      } else if (this.configuracoesMia?.status === 'ATIVADA') {
        this.statusBot = 'Ativado'
        this.miaLigada = true;
      } else {
        this.statusBot = 'Desativada';
        this.miaLigada = false;
        this.modoTeste = false;
      }
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  exibaNotificacaoErros(erroImpressao: any){
    let tempoAberto = 1000 * 25 ;
    let tempoAnimacao = 400;

    let notificationErro: any =  this.notificationService.show({
      appendTo: this.appendTo,
      hideAfter: tempoAberto,
     // closable: true,
      content:  erroImpressao,
      position: { horizontal: 'right', vertical: 'top' },
      animation: { type: 'fade', duration: tempoAnimacao },
      type: { style:  'error', icon: false}
    });

    notificationErro.erro = erroImpressao;

    this.notificacoesErros.forEach((item: NotificationRef) => {
       if((item as any).erro === erroImpressao)
         item.hide();
    })

    this.notificacoesErros.push(notificationErro)

  }

  exibaAlertaConectando(){
   if(this.notificationImpressao ) this.notificationImpressao.hide();

    this.notificationImpressao =  this.notificationService.show({
      appendTo: this.appendTo,
      content: this.notificationTemplate,
      hideAfter: 30000,
      position: { horizontal: 'right', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style:  'info', icon: false}
    });

  }

  fecheAlertaConectantoImpressora(){
    if(  this.notificationImpressao)   this.notificationImpressao.hide();
  }

  monitoreLogado(){
    if(  this.tarefaMonitorar) return;

    this.tarefaMonitorar =  timer(0,  300 * 1000).subscribe( () => {
        this.verifiqueLogado();
    } )
  }

  verifiqueLogado(){
    if( window.location.pathname === '/admin/login') return;

    this.autorizacaoService.estaLogado().then( (resposta: any) => {
      if(!resposta.logado || this.autorizacaoService.getUser() === null)
        this.router.navigateByUrl('/admin/login?t=' + window.location.pathname)
    });
  }

  abraModalAtivaoAssinatura(contrato: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: AtivacaoAssinaturaComponent,
      minWidth: 250,
      width: window.innerWidth - 50,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });


    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.contrato = contrato;

    windowRef.result.subscribe( (result) => {
        window.location.reload();
      } );
  }

  clicouHome() {
    if(document.body.classList.contains('sidebar-enable'))
      this.renderer.removeClass(document.body, 'sidebar-enable')
    else
      this.renderer.addClass(document.body, 'sidebar-enable')

    if(this.innerWidth > 767){
      if(document.body.classList.contains('enlarged'))
        this.renderer.removeClass(document.body, 'enlarged')
      else
        this.renderer.addClass(document.body, 'enlarged')
    }
  }

  logout() {
    if(this.autorizacaoService.logout())  {
      this.router.navigate(['/admin/login'], { });
    }
  }

/*
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
*/


  naoFacaNada() {
    return false;
  }

  ngOnDestroy(){
    this.monitoradorPedidos.pareMonitoramento();
  }




  alterouStatusAlerta(event: any) {
    this.monitoradorPedidos.toogleTocarIframe();
  }

  clicouAlerta($event: MouseEvent) {
    $event.stopPropagation(); $event.preventDefault();
  }

  recarregarAbas() {
    this.socketService.atualizeAbas();
  }

  fecharAgora() {
    const windowRef = FidelidadeComponent.abraComoPopup(this.dialogService, false);

    windowRef.result.subscribe((result: any) => {
      if (result && result.fechou) {
        this.empresa.fechadoTemporariamente = true;
        this.verifiqueServicosAbertos();
      }
      this.monitoreLojaFechada();
    });
  }

  reabrir() {
    this.empresa.reabrindo = true;
    this.empresasService.abra().then( (resposta) => {
      this.empresa.reabrindo = false;
      this.verifiqueServicosAbertos();
    })
  }

  verifiqueServicosAbertos(){
    this.empresasService.logada().then( (_empresa: any) => {
      this.empresa.fechadoTemporariamente = _empresa.fechadoTemporariamente;
      if(  !_empresa.fechadoTemporariamente ){
        if(this.timerAberto)
          this.timerAberto.unsubscribe();
      }
      this.empresa.horariosDoServico = _empresa.horariosDoServico;
      this.servico =    this.empresa.horariosDoServico.find((item: any) => item.id === this.servico.id)
    });
  }

  private monitoreLojaFechada() {
    if(!this.servico.estaAberta || !this.servico.estaRecebendoPedidos){
      if(this.timerAberto) this.timerAberto.unsubscribe();

      const segundosTimer = 60;

      this.timerAberto =  timer(1000 * segundosTimer , 1000 * segundosTimer  ).subscribe( () => {
        this.verifiqueServicosAbertos();
      })
    }
  }

  clicouNaoImprimir($event: MouseEvent) {
    $event.stopPropagation(); $event.preventDefault();
  }

  alterouNaoImprimir($event: any) {
    this.monitoradorPedidos.toogleNaoImprimir();
  }

  alterouNaoTocarGarcom($event: any): void {
    this.monitoradorPedidos.toogleNaoTocarGarcom();
  }

  mudouServico($event){
    this.servico = $event;
  }
}
