
import {TelPipe} from "./tel.pipe";
import {PontosAcaoPipe} from "./pontos.acao";
import {NgModule} from "@angular/core";

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    TelPipe, PontosAcaoPipe
  ],
  exports: [
    TelPipe, PontosAcaoPipe
  ]
})
export class ApplicationPipesModule {}
