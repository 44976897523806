export class TamanhoPapel {

  static Papel58mm = new TamanhoPapel(35);
  static Papel58mm32car = new TamanhoPapel(31);
  static Papel80mm = new TamanhoPapel(48);
  static Papel80mm42car = new TamanhoPapel(42);

  public tamanhoLinha: number;
  public quebraLinha: string;

  constructor(tamanhoLinha: number) {
    this.tamanhoLinha = tamanhoLinha;
    this.quebraLinha = '\n'
  }
}
