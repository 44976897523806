<h4 _ngcontent-mki-c5="" class="page-title"><i class="fab fa-whatsapp"></i> Configurações da Mia - Sua Atendente Virtual
</h4>

<div class="row mt-2">
  <div class=" col-xl-8 col-md-10 col-12">
    <div class="mia-section">
      <h3 class="text-center">Dê um upgrade no seu atendimento com a Mia, sua assistente virtual inteligente!</h3>
      <div class="row mt-3">
        <div class="col-md-7">
          <p>A Mia é capaz de atender às seguintes intenções com facilidade e eficiência:</p>
          <ul>
            <li>A <b>Mia estimula</b> os clientes a fazerem pedidos pelo cardápio digital, mesmo se tentarem fazer pelo WhatsApp.</li>
            <li><b>Envio</b> do cardápio digital aos clientes diretamente no WhatsApp.</li>
            <li><b>Informações</b> sobre o cardápio disponível, sem sair do WhatsApp.</li>
            <li><b>Status</b> dos pedidos dos clientes, acessíveis diretamente no WhatsApp.</li>
            <li><b>Promoções</b>, <b>cupons</b> ofertas especiais via WhatsApp.</li>
            <li><b>Horários</b> de funcionamento da loja.</li>
            <li><b>Endereço</b> da loja, disponível no WhatsApp.</li>
            <li><b>Respostas</b> às perguntas dos clientes sobre o restaurante, diretamente no WhatsApp.</li>
            <li><b>Experiência</b> de atendimento eficiente e personalizada aos clientes.</li>
            <li><b>Redução</b> do tempo de espera dos clientes para receber informações ou fazer pedidos.</li>
          </ul>
        </div>
        <div class="col-md-5">
          <p>Experimente a Mia agora mesmo e descubra como ela pode revolucionar o atendimento ao cliente no seu estabelecimento.</p>
          <div class="text-center">
            <button class="btn btn-primary mia-btn" kendoButton>Adquira a Mia hoje!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="card col-xl-8 col-md-10 col-12">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="alert alert-success" role="alert" *ngIf="msgSucesso">
          <i class="mdi mdi-check-all mr-2"></i> {{msgSucesso}}
        </div>

        <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}" (ngSubmit)="onSubmit()"
              novalidate #frm="ngForm">
          <div class="form-group">
            <label for="chatbot-name">Nome da Mia</label> <!-- movemos o campo de nome da Mia para o topo -->
            <input type="text" class="form-control" id="chatbot-name" name="Mia" kendoTextBox #nome="ngModel"
                   [(ngModel)]="configuracoes.nome" required="true" minlength="3"/>
            <div class="invalid-feedback">
              <p *ngIf="nome.errors?.required">O Nome da Mia é Obrigatório</p>
              <small class="text-danger" *ngIf="nome.errors?.minlength">Por favor, preencha o nome da Mia com pelo menos 3 letras.</small>
            </div>
          </div>
          <label class="font-weight-bold font-14">Status Mia:</label>
          <div class="form-group">
            <label for="rbDesativada">
              <input type="radio" id="rbDesativada" name="estado-mia" value="DESATIVADA"  kendoRadioButton [(ngModel)]="configuracoes.status"/>
              Mia Desativada <i class="fas fa-power-off"></i>
            </label>
            <small id="nameHelp1" class="form-text text-muted">A Mia desligada</small>
          </div>
          <div class="form-group">
            <label for="rbModoTeste">
              <input type="radio" id="rbModoTeste" name="estado-mia" kendoRadioButton value="MODO_TESTE" [(ngModel)]="configuracoes.status"/>
              Habilitar a Mia em <b>Modo Teste</b> &nbsp;<i class="fas fa-flask"></i>
            </label>
            <small id="nameHelp2" class="form-text text-muted">Você pode testar a Mia falando com você mesmo pelo Whatsapp.</small>
          </div>
          <div class="form-group" *ngIf="configuracoes.status === 'MODO_TESTE'">
            <label for="telefones-teste">Lista de Telefones em Modo Teste</label>
            <kendo-multiselect
              id="telefones-teste"
              [data]="telefones"
              [value]="telefones"
              [allowCustom]="true"
              (valueChange)="onTelefonesMudou($event)"
            >
            </kendo-multiselect>
            <small id="telefones-teste-help" class="form-text ">Informe o telefone completo incluindo o código do país e aperte enter.
              <br>Não use nenhum espaço ou separador no telefone.
              <br>Exemplo: <span class="font-weight-bold">+55{{empresa.numeroWhatsapp.whatsapp}}</span>
            </small>
          </div>
          <div class="form-group">
            <label for="rbAtivada">
              <input type="radio" id="rbAtivada" name="estado-mia" kendoRadioButton value="ATIVADA" [(ngModel)]="configuracoes.status"/>
              Habilitar a Mia em Produção <i class="fas fa-rocket"></i>
            </label>
            <small id="nameHelp" class="form-text text-muted">Ativa a Mia para que ela possa atender aos clientes.</small>
          </div>


          <ng-container *ngIf="configuracoes.status !== 'DESATIVADA'">
            <div class="w-100 mb-1">
              <div style="max-width: 400px;">
                <label for="tempoDePausaMia">Tempo de pausa da Mia (em segundos) ⏱️</label>
                <div class="input-group" style="width: 250px;line-height: 34px;">
                  <span class="input-group-text" id="tempoDePausaMia-addon">⏱️</span>
                  <input type="number" class="form-control" id="tempoDePausaMia" name="tempoDePausaMia" kendoNumericTextBox #tempoDePausaMia="ngModel"
                         [(ngModel)]="configuracoes.tempoPausaMia" required="true" min="1" maxlength="6"
                         style="max-width: 180px" aria-describedby="tempoDePausaMia-addon"/>
                  &nbsp;segs.
                </div>
                <small id="tempoDePausaMiaHelp" class="form-text text-muted">Defina o tempo de pausa em segundos durante o qual a Mia ficará inativa após uma resposta, permitindo que o atendente humano intervenha se necessário.</small>
                <label>A Mia ficará <strong>{{ formatTime(configuracoes.tempoPausaMia) }}</strong> desativada após chamar o atendente.</label>

                <div class="invalid-feedback">
                  <p *ngIf="tempoDePausaMia.errors?.required">O tempo de pausa é obrigatório</p>
                  <small class="text-danger" *ngIf="tempoDePausaMia.errors?.min">Por favor, insira um tempo de pausa maior ou igual a 1 segundo.</small>
                </div>
              </div>
            </div>

            <div>
              <label class="font-weight-bold font-14 mt-1">Personalização da Mia</label>
              <div class="">
                <h5 class="card-title">Comportamento para perguntas fora do escopo:</h5>

                <div class="form-group">
                  <label for="rbResponder">
                    <input type="radio" id="rbResponder" name="comportamentoForaDoEscopo" value="RESPONDER" kendoRadioButton [(ngModel)]="configuracoes.comportamentoForaDoEscopo"/>
                    Responder mesmo fora do escopo <i class="fas fa-comment"></i>
                  </label>
                  <small id="smallHelp1" class="form-text text-muted">A Mia tentará responder a perguntas mesmo que estejam fora do escopo.</small>
                </div>

                <div class="form-group">
                  <label for="rbNaoResponder">
                    <input type="radio" id="rbNaoResponder" name="comportamentoForaDoEscopo" value="NAO_RESPONDER" kendoRadioButton [(ngModel)]="configuracoes.comportamentoForaDoEscopo"/>
                    Não responder perguntas fora do escopo <i class="fas fa-comment-slash"></i>
                  </label>
                  <small id="smallHelp2" class="form-text text-muted">A Mia não responderá a perguntas que estejam fora do escopo e manterá a mensagem como não lida.</small>
                </div>

                <div class="form-group">
                  <label for="rbChamarAtendente">
                    <input type="radio" id="rbChamarAtendente" name="comportamentoForaDoEscopo" value="CHAMAR_ATENDENTE" kendoRadioButton [(ngModel)]="configuracoes.comportamentoForaDoEscopo"/>
                    Chamar atendente <i class="fas fa-user"></i>
                  </label>
                  <small id="smallHelp3" class="form-text text-muted">A Mia solicitará a intervenção de um atendente humano quando receber perguntas fora do escopo.</small>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label for="responderSobreProdutos" class="font-weight-bold font-14 mt-1">Responder sobre Produtos do Cardápio:</label>
                  <br>
                  <kendo-switch id="responderSobreProdutos" name="responderSobreProdutos" [(ngModel)]="configuracoes.responderSobreProdutos"
                                [onLabel]="'Sim'"
                                [offLabel]="'Não'"></kendo-switch>
                </div>
              </div>

              <div>
                <div class="form-group">
                  <label for="responderSobreProdutos" class="font-weight-bold font-14 mt-1">Recuperar Carrinho Abandonado</label>
                  <p>Quando um cliente não finaliza a compra, a Mia envia um lembrete após um intervalo pré-definido. Esta função visa reengajar o cliente e incentivar a finalização da compra. Ajuste o tempo de notificação conforme suas preferências aqui.</p>
                  <kendo-switch id="recuperarCarrinho" name="recuperarCarrinho" [(ngModel)]="configuracoes.recuperarCarrinho"
                                [onLabel]="'Sim'"
                                [offLabel]="'Não'"></kendo-switch>
                </div>

                <ng-container *ngIf="configuracoes.recuperarCarrinho">
                  <div class="w-100 mb-1" *ngIf="configuracoes.recuperarCarrinho">
                    <div style="max-width: 400px;">
                      <label for="tempoRecuperarCarrinho">Tempo Enviar Mensagem Recuperar Carrinho ⏱️</label>
                      <div class="input-group" style="width: 250px;line-height: 34px;">
                        <span class="input-group-text" id="tempoRecuperarCarrinho-addon">⏱️</span>
                        <input type="number" class="form-control" id="tempoRecuperarCarrinho" name="tempoRecuperarCarrinho" kendoNumericTextBox #tempoRecuperarCarrinho="ngModel"
                               [(ngModel)]="configuracoes.tempoRecuperarCarrinho" required="true" min="1" maxlength="6"
                               style="max-width: 180px" aria-describedby="tempoDePausaMia-addon"/>
                        &nbsp;segs.
                      </div>
                      <small id="tempoRecuperarCarrinhoHelp" class="form-text text-muted">Tempo que a Mia aguarda antes de lembrar o cliente do carrinho não finalizado: escolha um tempo para equilibrar decisão e oportunidade.</small>
                      <label>A Mia mandará mensagem para o cliente em <strong>{{ formatTime(configuracoes.tempoRecuperarCarrinho) }}</strong>.</label>

                      <div class="invalid-feedback">
                        <p *ngIf="tempoRecuperarCarrinho.errors?.required">O tempo de pausa é obrigatório</p>
                        <small class="text-danger" *ngIf="tempoRecuperarCarrinho.errors?.min">Por favor, insira um tempo de pausa maior ou igual a 1 segundo.</small>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="card">
                      <div class="card-header p-2">
                        <h5 class="mb-1">Mensagem Carrinho Abandonado</h5>
                      </div>
                      <div class="card-body p-1">
                        <app-form-notificacao [notificacao]="notificacao"></app-form-notificacao>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <hr>
          <div class="form-group">
            <div class="alert alert-success" role="alert" *ngIf="msgSucesso">
              <i class="mdi mdi-check-all mr-2"></i> {{msgSucesso}}
            </div>

            <button type="submit" class="btn btn-primary mr-2" kendoButton>Salvar</button>
            <!-- alteramos a classe do botão "Salvar" e adicionamos a classe "ml-2" para adicionar uma margem à esquerda -->
            <button type="reset" class="btn btn-light waves-effect" kendoButton>Cancelar</button>
            <!-- alteramos a classe do botão "Cancelar" -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
