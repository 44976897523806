import {ObjetoPersistente} from "../ObjetoPersistente";
import {MapeadorBasico} from "../../mapeadores/MapeadorBasico";
import {ProdutoTemplateOpcao} from "../templates/ProdutoTemplateOpcao";
import {AdicionalDeProduto} from "./AdicionalDeProduto";
import {DependenciaOpcaoDeAdicional} from "./DependenciaOpcaoDeAdicional";

export class OpcaoDeAdicionalDeProduto extends ObjetoPersistente {
  id: number;
  adicional: AdicionalDeProduto;
  codigoPdv: string
  idIfood: string
  idProduto: number
  opcaoNaEmpresa: any
  dependencias: Array<DependenciaOpcaoDeAdicional> = [];
  qtdeMaxima: number;
  qtdeMinima: number;
  constructor(public nome: string, public  valor: number, public disponivel: boolean,
              public descricao: string = null, public template: ProdutoTemplateOpcao = null,
              public linkImagem: string = null) {
    super();
  }

  mapeador(): MapeadorBasico {
    return undefined;
  }

  clone(){
    let opcao =  new OpcaoDeAdicionalDeProduto(this.nome, this.valor, this.disponivel, this.descricao, null, this.linkImagem);

    opcao.codigoPdv = this.codigoPdv;
    opcao.idIfood = this.idIfood;

    return opcao
  }

  estaDisponivel() {
     if(this.template && !this.template.disponivel) return false;

     return this.disponivel;
  }

  mesmoParaImportar(outraOpcao: any){
    if(this.idIfood != null)   return this.idIfood === outraOpcao.idIfood
    if(this.codigoPdv != null)   return this.codigoPdv === outraOpcao.codigoPdv

    return this.nome === outraOpcao.nome
  }
}
