import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FidelidadeService  extends ServerService{
  constructor(http: HttpClient) {
    super(http);
  }
  listePontuacoesRegistradas(inicio: number, total: number, dtInicio: string, dtFim: string, plano: any, idContato: number){
    let params: any = {i: inicio, t: total, di: dtInicio, df: dtFim};

    if(plano) params.pid  = plano.id;

    if(idContato) params.cid = idContato;

    return this.obtenha('/fidelidade/pontuacoes', params)
  }

  listeRegastesFidelidade(inicio: number, total: number, dtInicio: string, dtFim: string, plano: any,
                          idContato: number, idBrinde: number){
    let params: any = {i: inicio, t: total, di: dtInicio, df: dtFim};

    if(plano) params.pid  = plano.id;

    if(idContato) params.cid = idContato;
    if(idBrinde) params.bid = idBrinde;

    return this.obtenha('/fidelidade/resgastes', params)
  }
}
