<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()" *ngIf="!widget" style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fe-user "></i> Contato
</h4>

<app-notificacoes-promokit></app-notificacoes-promokit>

<div style="position: absolute;top: 15px;right: 15px;">
  <button class="btn btn-blue" (click)="recarregue();" kendoTooltip title="Recarregar o cardápio e forçar envio das mensagens."><i class="fas fa-sync-alt"></i></button>
</div>

<app-gerenciador-envio-campanha></app-gerenciador-envio-campanha>

<div class="mb-2" *ngIf="dev">
  <div class="form-group mt-2">
    <label>Enviar mensagem Teste Chatbot:</label>
    <input type="text" class="form-control" (keyup.enter)="digitouMensagemBot($event)"/>
  </div>
</div>

<div class="k-i-loading ml-1 mr-1 mt-3" style="font-size: 50px; padding-bottom: 20px" *ngIf="!carregou" ></div>

<div class="row"  [hidden]="contato == null">
  <div class="col-12">
    <info-contato [contato]="contato" [exibirUltimaVisita]="true" [atendente]="atendente" [statusMia]="statusMia"
                  [desativarParaSempre]="desativarParaSempre"
                  [inserirtTag]="true" [empresa]="empresa" [exibirEndereco]="true" [limiteEndereco]="2">
    </info-contato>
  </div>

  <div class="col-12 mt-2">
    <div class="alert alert-success mb-0" role="alert" *ngIf="msg">
      <i class="mdi mdi-check-all mr-2"></i> {{msg}}  <i class="fa fa-print fa-lg cpointer ml-1" *ngIf="novoPedido.guid"
                                                         (click)="imprimaPedido(this.novoPedido)"> </i>
    </div>
  </div>

  <div class="col-12 mb-3" *ngIf="contato  && (contato.telefone || contato.id)">
    <acoes-modulos [contato]="contato" (salvouContato)="salveContato($event)"></acoes-modulos>
  </div>

  <div class="col-12">
    <info-pedidos [contato]="contato"  [empresa]="empresa"></info-pedidos>
  </div>

  <div class="col-12 ">
    <info-fidelidade *ngIf="contato" [cartoes]="contato.cartoes" [empresa]="empresa"></info-fidelidade>
  </div>

  <div class="col-12">
    <app-historico-modulos [contato]="contato" [empresa]="empresa" ></app-historico-modulos>
  </div>
</div>

<div  [hidden]="!carregou || contato != null">
  <div class="row">
    <div class="col">
      <ng-container *ngIf="!empresa.dark">
        <h5 class="mt-2">Este é um novo cliente</h5>
      </ng-container>

      <info-contato [contato]="contatoPorDados" [atendente]="atendente" [statusMia]="statusMia"
                    [exibirUltimaVisita]="true" [empresa]="empresa" [desativarParaSempre]="desativarParaSempre"></info-contato>
      <acoes-modulos [contato]="contatoPorDados" [novoContato]="true" (salvouContato)="salveContato($event)"></acoes-modulos>
    </div>
  </div>
</div>
