import { Component, OnInit } from '@angular/core';
import {CampanhaService} from "../services/campanha.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {ContatosService} from "../services/contatos.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-campanha-detalhes',
  templateUrl: './campanha-detalhes.component.html',
  styleUrls: ['./campanha-detalhes.component.scss']
})
export class CampanhaDetalhesComponent implements OnInit {
  campanha: any = {}
  mensagensEnviadas: Array<any> = [];
  buttonCount = 5;
  info = true;
  type: 'numeric' | 'input' = 'numeric';
  pageSizes = false;
  previousNext = true;
  loading = false;
  gridDataResult: GridDataResult;
  page = 0;
  tamanhoPagina = 10;
  filtro = {recebeu: false};
  constructor(private campanhaService: CampanhaService, private router: Router,
              private  activatedRoute: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    if(window.history.state && window.history.state.data){
      this.setCampanha(window.history.state.data)
    } else {
      this.activatedRoute.params.subscribe(params => {
        let id = +params['idc']; // (+) converts string 'id' to a number
        this.campanhaService.obtenhaCampanha(id).then((resposta: any) => {
          this.setCampanha(resposta)
        });
      });
    }
  }
  private setCampanha(campanha: any) {
    this.campanha = campanha;
    this.carregueMensagens()
  }
  paginacaoMudou($event: PageChangeEvent) {
    this.page = $event.skip;
    this.carregueMensagens()
  }

  private  carregueMensagens() {
    this.loading = true;
    this.campanhaService.listeMensagens(this.campanha, this.page, this.filtro).then( (resposta => {
      this.mensagensEnviadas = resposta.mensagens;
      this.gridDataResult = {
        data: this.mensagensEnviadas,
        total: resposta.total
      };
      this.loading = false;
    }))
  }
  getUltimaVisita(links: any) {
    let ultimaVisita = null;

    links.forEach( (link) => {
      if(link.ultimaVisita &&  (!ultimaVisita || new Date(link.ultimaVisita).getTime() > new Date(ultimaVisita).getTime() ))
        ultimaVisita  = link.ultimaVisita;
    })

    return ultimaVisita
  }

  getVisitas(links: any) {
    let visitas = 0;
    links.forEach( (link) => {
      visitas += link.visitas;
    })
    return visitas;
  }

  voltar() {
    this.location.back();
  }

  filtre() {
    this.carregueMensagens();
  }
}
