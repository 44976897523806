import {
  ApplicationRef,
  Component,
  EventEmitter,
  Inject, Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import {DOCUMENT} from "@angular/common";
import {NgForm} from "@angular/forms";
declare let Iugu;
declare let moment;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cad-cartaocredito',
  templateUrl: './cad-cartaocredito.component.html',
  styleUrls: ['./cad-cartaocredito.component.scss']
})
export class CadCartaocreditoComponent implements OnInit {
  @ViewChild('frm', { static: true})  frm: NgForm;
  @Input() parcelamento: any;
  @Output() onCriou = new EventEmitter();
  empresa: any;
  processando: any;
  mensagemSucesso: any;
  mensagemErro: any;
  cartao: any = {};
  esconderPagar = false;
  errosIugu
  constructor(private _renderer2: Renderer2,   private app: ApplicationRef,
              @Inject(DOCUMENT) private _document: Document, private _ngZone: NgZone) { }

  ngOnInit() {
    if(this.parcelamento){
      this.parcelamento.parcelas = []
      for(let i = 1; i <= this.parcelamento.numeroParcelas; i++){
        let valor: any = this.parcelamento.total / i ;

        valor = valor.toFixed(2).replace('.', ',')

        this.parcelamento.parcelas.push( { numeroParcelas: i, descricao: String(`${i}x de R$ ${valor} sem juros`)});
      }
    }



    this.incluaIugu();
  }

  private incluaIugu() {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src =  'https://js.iugu.com/v2';
    this._renderer2.appendChild(this._document.body, script);

    this.ativeIugu();
  }

  private ativeIugu() {
    if (typeof Iugu === 'object'){
      Iugu.setAccountID("DC9A148BEB274D64B3EB143F5F664803");
      if(window.location.host.indexOf('localhost') >= 0 )
        Iugu.setTestMode(true);
    } else {
      setTimeout( () => { this.ativeIugu()}, 500)
    }
  }

  criarCartao() {
    if(!this.frm.valid) return;

    delete this.mensagemErro;
    delete  this.errosIugu;

    if(!Iugu.utils.validateCreditCardNumber(this.cartao.numero)){
        this.mensagemErro = 'Número do cartão inválido';
        return
    }

    if(!this.cartao.bandeira){
      this.mensagemErro =
        'Não foi possível identificar a bandeira do cartão, bandeiras aceitas são: MasterCard, Visa, Elo, Diners e American Express.';
      return
    }

    if(!Iugu.utils.validateCVV(this.cartao.cvv, this.cartao.bandeira)){
      this.mensagemErro = 'Número de segurança inválido';
      return
    }

    let mesValidade = moment(this.cartao.validade).format('MM')
    let anoValidade = moment(this.cartao.validade).format('YYYY')

    if(!Iugu.utils.validateExpiration( mesValidade, anoValidade)){
      this.mensagemErro = 'Data de validade inválida'  ;
      return
    }

    let nomes = this.cartao.nome.split(" ");
    let primeiroNome = nomes[0].trim();
    let ultimoNome = this.cartao.nome.replace(primeiroNome, "").trim();

    if(!Iugu.utils.validateFirstName(primeiroNome)){
      this.mensagemErro = 'Nome impresso no cartão inválido' + primeiroNome;
      return
    }

    if(!Iugu.utils.validateLastName(ultimoNome)){
      this.mensagemErro = 'Informe o nome e sobrenome impresso no cartão'
      return
    }

    let creditCard = Iugu.CreditCard(this.cartao.numero, mesValidade, anoValidade,
      primeiroNome, ultimoNome, this.cartao.cvv);

    if(!creditCard.valid()){
      this.mensagemErro = 'Cartão nao é um cartão valido.'
      return
    }
    this.processando = true;
    Iugu.createPaymentToken(creditCard.toData(),  (response) => {
      this.app.tick();
      if (!response.errors) {
        this.cartao.token = response.id;
        let dados: any = { token: this.cartao.token,
                      numero: this.cartao.numero,
                      bandeira: this.cartao.bandeira,
                      validade: this.cartao.validade
        }

        if(this.cartao.parcelamento )
          dados.numeroParcelas = this.cartao.parcelamento.numeroParcelas

        this.onCriou.emit(dados);
      } else {
        this.processando = false;
        console.log(response.errors);
        this.mensagemErro = "Erro salvando cartão";
        this.errosIugu = response.errors;
      }
    });
  }

  canceleProcessando(){
    this._ngZone.run(() => {
      this.processando = false;
    });

  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  alterouNumeroCartao() {
    if(this.cartao.numero.toString().length > 5){
      let bandeira = Iugu.utils.getBrandByCreditCardNumber(this.cartao.numero);

      if(bandeira) this.cartao.bandeira = bandeira;
      else delete  this.cartao.bandeira;

      this.cartao.numeroValido = Iugu.utils.validateCreditCardNumber( this.cartao.numero );
    }
  }

  ativeAssinatura() {

  }


}
