import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {ItemPedido} from "../objetos/ItemPedido";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {SaborPizzaLoja} from "../objetos/SaborPizzaLoja";


@Component({
  selector: 'app-adicionais-customizados',
  templateUrl: './adicionais-customizados.component.html',
  styleUrls: ['./adicionais-customizados.component.scss',
    '../site-campo-adicional/site-campo-adicional.component.scss']
})
export class AdicionaisCustomizadosComponent implements OnInit {
  exibirErros: any;
  @Input() itemPedido: ItemPedido;
  @Input() produto: any;
  pizzas: any = [];
  saboresPizza: any = [];
  saboresPizzaFiltrados: any = [];
  qtdeSabores  = 1;
  cobrarMaiorPedacao: boolean;
  modoEdicao: boolean;
  saboresInicial: SaborPizzaLoja[];

  filtroSabor: any;
  clicouBusca: boolean;
  private timerBusca;

  @Output() alterouTamanho =  new EventEmitter();
  constructor(private constantsService: ConstantsService) { }

  ngOnInit(): void {
    this.pizzas = this.constantsService.produtosLoja.filter( produto => produto.tipo === 'pizza');
    this.setSaboresDoTamanho();

    this.cobrarMaiorPedacao = this.produto.template.tipoDeCobranca === 'maior';

  }

  alterouTamanhoProduto(){
    this.setSaboresDoTamanho();
    if(this.qtdeSabores > this.totalSaboresSelecionados())
      this.desselecioneSabores();

    this.obtenhaSaboresEscolhidos();
    this.itemPedido.atualizeTotal();
    this.alterouTamanho.emit(this.itemPedido.produtoTamanho);
  }


  desselecioneSabores(){
    this.saboresPizza.forEach( sabor =>  sabor.qtde =  sabor.obrigatorio ? 1 : 0)
  }


  diminuirQtdeSabor(saborPizza: any){
    if(saborPizza.obrigatorio && saborPizza.qtde === 1) return;

    if(saborPizza.qtde > 0){
      saborPizza.qtde--;
      this.obtenhaSaboresEscolhidos();
    }

  }

  aumentarQtdeSabor(saborPizza: any){

    if(this.qtdeSabores > this.totalSaboresSelecionados()){
      saborPizza.qtde++;
      this.obtenhaSaboresEscolhidos();
    }
  }

  setSaboresDoTamanho() {
    if(!this.itemPedido.produtoTamanho) return;

    this.saboresPizza = [  ];

    if(!this.itemPedido.produtoTamanho || this.qtdeSabores < 2) return [];

    let manterSelecaoSabores = this.itemPedido.produtoTamanho.qtdeSabores >= this.qtdeSabores;

    if(!manterSelecaoSabores)
      this.qtdeSabores = 1;

    this.pizzas.forEach( (produtoPizzaAdicional: any) => {
      let tamanhoPizzaAdicional =
        produtoPizzaAdicional.tamanhos.find(tamanho => tamanho.template.id === this.itemPedido.produtoTamanho.template.id)

      if(tamanhoPizzaAdicional){
        let saborTela: SaborPizzaLoja = new SaborPizzaLoja(produtoPizzaAdicional, tamanhoPizzaAdicional);

        saborTela.obrigatorio = produtoPizzaAdicional.id === this.itemPedido.produto.id

        if(this.itemPedido.sabores.length && manterSelecaoSabores){
          let saboresProdutoNoPedido =
            this.itemPedido.sabores.filter( saborSelecionado => saborSelecionado.produto === produtoPizzaAdicional.id);

          saboresProdutoNoPedido.forEach((saborPedido: any) => {
            saborTela.qtde += saborPedido.qtde;
          })
        } else if( saborTela.obrigatorio){
          saborTela.qtde = 1;
        }

        this.saboresPizza.push(saborTela)
      }


    })

    this.saboresPizzaFiltrados = [...this.saboresPizza]


  }

  obtenhaSaboresEscolhidos(){
    if(this.qtdeSabores === 1) {
      this.itemPedido.sabores = [new SaborPizzaLoja(this.itemPedido.produto, this.itemPedido.produtoTamanho, this.itemPedido.qtde)]
    } else {
      let sabores: any = [],
        saboresSelecionados =  this.saboresPizza.filter( sabor => sabor.qtde > 0 );

      saboresSelecionados.forEach((saborSelecionado: any) => {
        for (let i = 1; i <= saborSelecionado.qtde; i++){
          let sabor = Object.assign({}, saborSelecionado);
          sabor.qtde = 1;

          sabor.camposAdicionais = sabor.camposAdicionais.filter((adicionalSabor) =>
            adicionalSabor.obrigatorio &&
            !this.produto.camposAdicionais.find((adicionalProduto: any) =>  adicionalProduto.nome === adicionalSabor.nome))

          sabores.push(sabor);
        }
      })

      this.itemPedido.sabores = sabores;
    }

    this.itemPedido.prepareAdicionaisSabores(this.produto)

  }

  totalSaboresSelecionados() {
    return this.saboresPizza.filter( sabor => sabor.qtde > 0 ).reduce((sum: number, item: any) => item.qtde + sum, 0);
  }

  obtenhaQtdeSabores(){
    let listaQtde = [];

    if( this.itemPedido.produtoTamanho){
      for(let i = 1; i <= this.itemPedido.produtoTamanho.qtdeSabores; i++){
        listaQtde.push(i);
      }

    } else {
      listaQtde = [1]
    }

    return listaQtde;
  }

  valideCampos() {
    this.exibirErros = true;

    if(!this.itemPedido.produtoTamanho)
      return false;

    if(this.qtdeSabores > 1){
      if(this.totalSaboresSelecionados()  !== this.qtdeSabores)
        return false;
    }

    return true;

  }


  setValoresEdicao() {
    this.itemPedido.produtoTamanho = this.produto.tamanhos.find( tamanho => tamanho.id === this.itemPedido.produtoTamanho.id )
    this.qtdeSabores = this.itemPedido.sabores.length;
    this.setSaboresDoTamanho();


    this.saboresInicial = this.itemPedido.sabores
  }

  onFilter() {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      if(!this.filtroSabor) {
        this.saboresPizzaFiltrados = [...this.saboresPizza]
        return;
      }
      this.saboresPizzaFiltrados = []

      let sabor: SaborPizzaLoja;
      for(sabor of this.saboresPizza)
        if(sabor.obrigatorio) this.saboresPizzaFiltrados.push(sabor)
        else if (sabor.nome.toLowerCase().includes(this.filtroSabor.toLowerCase()) ||
          (sabor.descricao && sabor.descricao.toLowerCase().includes(this.filtroSabor.toLowerCase())))
          this.saboresPizzaFiltrados.push(sabor)
    }, 1000);
  }

  alterouQtdeSabores(){
    this.setSaboresDoTamanho();

    if(this.qtdeSabores < this.totalSaboresSelecionados())
      this.desselecioneSabores();

    this.obtenhaSaboresEscolhidos();
    this.itemPedido.atualizeTotal();
  }

  habilitarBusca() {
    this.clicouBusca = !this.clicouBusca
  }

}

