import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EnumFiltroDePedidos, PedidosService} from "../../services/pedidos.service";
import {GridPedidosComponent} from "../grid-pedidos/grid-pedidos.component";

declare var moment;

@Component({
  selector: 'app-tela-pedidos-cancelados',
  templateUrl: './tela-pedidos-cancelados.component.html',
  styleUrls: ['./tela-pedidos-cancelados.component.scss']
})
export class TelaPedidosCanceladosComponent implements OnInit {
  @ViewChild('gridPedidos', { static: true}) gridPedidos: GridPedidosComponent;
  pedidos: [];
  paginacao = { total: 1000};
  carregando: any = false;
  filtro: any = { inicio: null, fim: null};
  resumoPedidos: any = { qtde: 0, total: 0, totalTaxas: 0 };
  @Input() mesa = false;
  objFiltro: any = {
    q: ''
  };

  constructor(private pedidosService: PedidosService) {
  }

  ngOnInit(): void {
    this.filtro.inicio = moment().add(-7 , 'd').toDate();
    this.filtro.fim = new Date();
    this.carreguePedidos();
  }

  carreguePedidos(){
    if(this.carregando) return;

    let dtInicio = moment(this.filtro.inicio).format('YYYYMMDD');
    let dtFim = moment(this.filtro.fim).format('YYYYMMDD');

    this.carregando = true;
    let filtro = EnumFiltroDePedidos.pedidos;
    if( this.mesa ) {
      filtro = EnumFiltroDePedidos.comanda;
    }
    this.pedidosService.listePedidosCancelados(0, this.paginacao.total,
      null,    dtInicio, dtFim, filtro, this.objFiltro.q).then( (resposta) => {
      this.setPedidos(resposta.pedidos || []);

      this.gridPedidos.atualizeGridPedidos(this.pedidos, 'cancelados');
      this.carregando = false;
    }).catch( () => {
      this.carregando = false;
    });
  }

  private setPedidos(resposta: any) {
    this.pedidos = resposta || [];
    this.resumoPedidos.qtde = this.pedidos.length;


  }

  onFilter($event: any) {
    this.carreguePedidos();
  }
}
