<h4 class="page-title">
  <button class="btn-voltar btn btn-rounded btn-outline-dark" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  &nbsp; <i class="fe-shopping-cart"></i> {{tituloPagina}}

</h4>

<div class="card">
  <div class="card-body p-0">
    <app-tela-mesa-escolhida #telaMesaEscolhida></app-tela-mesa-escolhida>

    <div class="row">
      <div class="col-12 col-lg-5 col-xl-4 border-right pr-0" [ngClass]="{'border-right': desktop, 'pr-0': desktop}"
           [hidden]="(adicionandoProduto || adicionandoBrinde || editandoProduto) &&  !desktop">
        <div class="p-2">
          <h4>
            Cliente e Carrinho
          </h4>
          <div>
            <small class="" [hidden]="pedido.balcao">Informe o cliente</small>
            <small class="" [hidden]="!pedido.balcao"><b>Pedido balcão</b></small>
          </div>
          <form [hidden]="pedido.id  && !this.modoEdicao"  [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
            <div class="borda mb-2" style="padding: 10px" *ngIf="pedidoDeMesa && empresa.pedidoMesaNaoIdentificado">
              <ng-container *ngIf="!this.devePreencherContato">
                <label>Cliente: </label>
                <label class="ml-1"> <strong> Consumidor Não Identificado</strong></label>

                <button type="button" class="ml-2 btn btn-blue" (click)="identificarConsumidor()">Identificar Consumidor</button>
              </ng-container>
              <button type="button" class="btn btn-secondary" (click)="naoIdentificarConsumidor()" *ngIf="this.devePreencherContato">Não identificar Consumidor</button>
            </div>

            <div class="borda mb-2 " *ngIf="(!pedidoDeMesa || this.devePreencherContato) && !this.pedido.balcao">
              <app-buscar-contato #buscarContato mensagemObrigatorio="Telefone é obrigatório" [borda]="false"
                                  [editavel]="!modoEdicao" (onBuscouContato)="contatoFoiBuscado($event)"
                                  [validarContato]="false" [mensagem]="'Informe o telefone ou nome do contato'"></app-buscar-contato>


                <div class="form-group mb-2  " *ngIf="contato">
                  <label for="nome">Nome do Contato</label>
                  <input type="text" class="form-control" autocomplete="off"
                         id="nome" name="nome" [(ngModel)]="contato.nome" #nome="ngModel"  nomeValido [completo]="false"
                         placeholder="Nome do Contato" value="" required appAutoFocus [autoFocus]="false">

                  <div class="invalid-feedback">
                    <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
                    <p *ngIf="nome.errors?.nomeValido">Informe um nome válido</p>
                  </div>
                </div>

              <div class="row">
                <div class="form-group mb-2 col-12" *ngIf="informarCpf" [ngClass]="{' col-sm-6': informarDataNascimento }">
                  <label  >CPF</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="cpf" [(ngModel)]="contato.cpf" #cpf="ngModel" mask="000.000.000-00" cpfValido
                         placeholder="___.___.___-__"     appAutoFocus [autoFocus]="true">
                  <div class="invalid-feedback">
                    <p *ngIf="cpf.errors?.required">CPF é obrigatório</p>
                    <p *ngIf="cpf.errors?.cpfInvalido">CPF inválido</p>
                  </div>
                </div>

                <div class="form-group mb-2 col-12" *ngIf="informarDataNascimento" [ngClass]="{' col-sm-6': informarCpf }">
                  <label  >Data Nascimento</label>
                  <kendo-datepicker #dataNascimento='ngModel' [(ngModel)]="contato.dataNascimento"
                                    class="form-control "   format="dd/MM/yyyy"  activeView="decade"
                                    name="dataNascimento" activeView="decade" >
                    <kendo-datepicker-messages
                      today="Hoje"
                      toggle="Trocar calendário"
                    ></kendo-datepicker-messages>

                  </kendo-datepicker>

                  <div class="invalid-feedback">
                    <p *ngIf="dataNascimento.errors?.required">Data de nascimento é obrigatória</p>

                  </div>

                </div>
              </div>

            </div>

            <button kendoButton class="btn btn-primary waves-effect waves-light mr-2" type="button" (click)="cliqueAdicionarProdutos()" *ngIf="!desktop">
              Adicionar Produtos</button>

            <div class="mt-2"></div>

            <app-grid-produtos #listaDePedidos [tela]="this" (onEditar)="editeItemPedido($event)"></app-grid-produtos>


            <div class="mt-2" *ngIf="pedido.pontosReceber">

              <h6>  <i class="fe-star-on   text-blue"></i>
                Ganhe  <b> {{pedido.pontosReceber}}</b> no programa <b>{{pedido.integracao.plano.nome}}</b></h6>

            </div>

            <div class="mt-2 resgate" *ngIf="podeResgatarBrinde()">

              <h6>Resgatar brinde</h6>

              <div class="planos row">
                <div *ngFor="let resgate of pedido.trocas" class="col-12 mb-2">
                  <button class="btn btn-block btn-outline-success" (click)="exibaTelaAdicionarBrinde(resgate)">
                    <span class="btn-label float-left abreviar">{{resgate.cartao.plano.nome}}</span>

                    <span class="float-right">
                      <saldo-cartao [cartao]="resgate.cartao" [inline]="true" ></saldo-cartao>
                   </span>
                  </button>
                </div>
              </div>

            </div>

            <mensagem-bloqueio [bloqueado]="bloqueado"  ></mensagem-bloqueio>

            <div class="alert alert-danger mb-0 mt-2" role="alert" *ngIf="msgErro">
              <i class="fa fa-info-circle"></i> {{msgErro}}
            </div>

            <div class="mt-3">
              <button class="btn btn-blue btn-block" (click)="configureEnderecoEntrega()" *ngIf="!bloqueado && !telaMesaEscolhida.mesa">Entrega</button>

              <button class="btn btn-blue btn-block" (click)="verificarPedidoMesa()" *ngIf="telaMesaEscolhida.mesa">Verificar Pedido</button>
            </div>

          </form>
        </div>
      </div>

      <div class="col-12 col-lg-7 col-xl-8" [ngClass]="{'border-left': desktop, 'pl-0': desktop}"
           [hidden]="!adicionandoProduto && !editandoProduto && !desktop">
        <div class="p-2">
          <app-tela-adicionar-produto #telaAdicionarProduto
                                      (onAtualizou)="atualizeProduto($event)"    (onAdicionou)="adicioneProduto($event)" (onCancelouAdicionar)="canceleAdicionar($event)"  >

          </app-tela-adicionar-produto>
        </div>
      </div>

      <span [hidden]="!adicionandoBrinde">
        <app-tela-adicionar-brinde #telaAdicionarBrinde (onAdicionou)="salveBrinde($event)" (onCancelou)="canceleAdicionarBrinde()">

        </app-tela-adicionar-brinde>
      </span>
    </div>
  </div>
</div>
