<div class="area" [class.componente]="componente">
  <div class="form-kml" *ngIf="exibirFormKML">
    <app-form-upload-kml (selecionouArquivo)="escolheuArquivo($event)" (falhouEnvioArquivo)="falhouEnvioArquivo($event)"
                         (enviouArquivo)="enviouArquivoKML($event)" (terminouUpload)="terminouUpload($event)"
                         [formaDeEntrega]="formaDeEntrega" (novaLocalizacao)="adicioneMarca($event)"
                         (atualizouAlcances)="atualizeAlcances($event)"
                         ></app-form-upload-kml>
  </div>

  <div class="form-salvar-localizacao" *ngIf="mudandoLocalizacao">
    <div class="card card-body">
      <h4 class="card-title mt-0"><strong>Nova Localização da Loja</strong></h4>

      <p>
        <strong><i class="fas fa-mouse-pointer font-18"></i> &nbsp;Arraste o mapa para mudar a localização da sua loja.</strong>
      </p>

      <div class="alert alert-success mt-2" role="alert" *ngIf="msgAlterouLocalizacao">
        <i class="mdi mdi-check-all mr-2"></i> {{msgAlterouLocalizacao}}
      </div>

      <div>
        <button class="btn btn-danger" (click)="salvarNovaLocalizacao()" [disabled]="salvando">Salvar Nova Localização</button>
        <button class="btn btn-primary ml-2" (click)="cancelarMudarLocalizacao()">Cancelar</button>
      </div>
    </div>
  </div>

  <div id="map" style="width: 100%;height: 800px;"></div>

  <div *ngIf="enviandoArquivo" class="card-img-overlay d-flex align-content-center justify-content-center flex-wrap"
       style="background: rgba(0, 0, 0, .5);z-index: 9999;">
    <div>
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;color: #fff;line-height: 40px"></div>
      <h4 style="color: #fff;" class="mt-3">Enviando arquivo...</h4>
    </div>
  </div>
</div>

