import { Component, OnInit } from '@angular/core';
import {DialogService} from "@progress/kendo-angular-dialog";
import {TemplatesService} from "../../services/templates.service";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {FormTemplateComponent} from "../../compartilhado/form-template/form-template.component";
import {CadCupomComponent} from "../../compartilhado/cad-cupom/cad-cupom.component";
import {EmpresasUsandoTemplateComponent} from "../empresas-usando-template/empresas-usando-template.component";

@Component({
  selector: 'app-templates-mensagens',
  templateUrl: './templates-mensagens.component.html',
  styleUrls: ['./templates-mensagens.component.scss']
})
export class TemplatesMensagensComponent implements OnInit {
  paginacao = {
    size: 10,
    skip: 0,
    total: 0,
    buttonCount: 5,
    info: true,
    type: "numeric"  ,
    pageSizes: false,
    previousNext: true
  }
  templatesDeMensagem: any = [];
  carregando: boolean;
  redes: any;
  filtro: any = {}
   usuarioLogado: any;


  constructor(private dialogService: DialogService,
              private templatesService: TemplatesService,
              private constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService,
              private empresaService: EmpresasService) {

    this.constantsService.empresa$.subscribe( async(empresa) => {
      if( !empresa ) {
        return;
      }
      this.redes = await this.empresaService.listeRedesDoGrupoDaEmpresa( empresa );

      if(this.redes && this.redes.length > 0) {
        this.filtro.rede = this.redes[0]
        this.carregueTemplates()
      }
    });
  }

  ngOnInit(): void {
    this.usuarioLogado = this.autorizacaoService.getUser();
  }

  carregueTemplates() {
    this.carregando = true;
    this.templatesService.todosDaRede(this.filtro.rede).then((templates => {
      this.carregando = false;
      this.templatesDeMensagem = templates
    }))

  }


  editarTemplate({ sender, rowIndex, dataItem }) {
    this.abraModalTemplateDeMensagem(dataItem)
  }

  mudouPaginacao($event: PageChangeEvent) {

  }

  abraModalTemplateDeMensagem(template) {
    let altura = window.innerHeight;

    const windowRef: any = this.dialogService.open({
      title: 'Cadastrar Template de Mensagem',
      content: FormTemplateComponent,
      minWidth: 600,
      width: window.innerWidth > 1424 ? 1024 : (window.innerWidth - 400  < 600 ? 600 : window.innerWidth - 400),
      maxHeight: altura
    });
    const telaCadastrarTemplate: FormTemplateComponent = windowRef.content.instance;
    telaCadastrarTemplate.template = template
    telaCadastrarTemplate.windowRef = windowRef

    windowRef.result.subscribe((result: any) => {
      if(result && result.id){
        this.carregando = true;
        this.templatesDeMensagem = [];
        this.carregueTemplates()
        this.templatesService.todos().then( (resposta) => {
          this.carregando = false;
          this.templatesDeMensagem = resposta
        })
      }

    });

  }

  novoTemplateDeMensagem() {
    this.abraModalTemplateDeMensagem({nome: "", mensagem: "", rede: this.filtro.rede})
  }

  abraGridEmpresasUsando(dataItem: any) {
    let altura = window.innerHeight;

    const windowRef = this.dialogService.open({
      title: 'Empresas que Usam',
      content: EmpresasUsandoTemplateComponent,
      minWidth: 600,
      width: window.innerWidth > 1424 ? 1024 : (window.innerWidth - 400  < 600 ? 600 : window.innerWidth - 400),
      maxHeight: altura
    });

    const telaEmpresasUsando: EmpresasUsandoTemplateComponent = windowRef.content.instance;

    telaEmpresasUsando.windowRef = windowRef;

    telaEmpresasUsando.templateDeMensagem = dataItem;

    windowRef.result.subscribe((result: any) => {

    });
  }
}
