import { Component, OnInit } from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {PedidosService} from "../../services/pedidos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-ifood-notificacoes',
  templateUrl: './modal-ifood-notificacoes.component.html',
  styleUrls: ['./modal-ifood-notificacoes.component.scss']
})
export class ModalIfoodNotificacoesComponent  extends ModalKendo implements OnInit {

  pedido: any = {};
  notificacoes = [];
  carregando = true;
  constructor( private  grupolojasService: GrupolojasService,
               private  pedidosService: PedidosService) {
    super()
  }

  ngOnInit(): void {
    this.pedidosService.listeNotificacaoesIfoodDelivery(this.pedido.id).then((notificacoes) => {
      this.carregando = false;
      this.notificacoes = notificacoes;
    }).catch((erro) => {
      this.carregando = false;
      alert(erro);
    })
  }

}
