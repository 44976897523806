import {MapeadorBasico} from "../mapeadores/MapeadorBasico";
import {Catalogo} from "./catalogo/Catalogo";

export abstract class  ObjetoPersistente {
  id: any;
    async salve(graph: boolean = false){
      return new Promise<void>(async (resolve, reject) => {
        let erro = await this.valide();

      if (erro) return reject(erro)

      if(this.id){
        await this.atualize();
      } else {
        if(graph)
          await this.insiraGraph();
        else
          await this.insira();
      }

      resolve();

    })

  }

  async atualize(){
    return this.mapeador().atualizeSync(this)
  }

  async remova(){
    return this.mapeador().removaAsync(this)
  }

  async insira(){
    return this.mapeador().insiraSync(this)
  }

  async insiraGraph(){
    return this.mapeador().insiraGraph(this)
  }

  async valide( contexto: any = null, catalogo: any = null): Promise<any>{
    return null;
  }

  abstract mapeador(): MapeadorBasico;
}
