import { Component, OnInit } from '@angular/core';
import {CatalogosService} from "../../superadmin/services/catalogos.service";
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {CadCupomComponent} from "../../compartilhado/cad-cupom/cad-cupom.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {FormCatalogoComponent} from "../form-catalogo/form-catalogo.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogos.component.html',
  styleUrls: ['./catalogos.component.scss']
})
export class CatalogosComponent implements OnInit {
  catalogos: any;
  carregando: boolean;
  modalDeletar: any;
  catalogoApagar: any;

  constructor(private dialogService: DialogService,
              private catalogosService: CatalogosService,
              private router: Router) { }

  ngOnInit(): void {
    this.carregueCatalogos()
  }

  private carregueCatalogos() {
    this.carregando = true;
    this.catalogosService.listeCatalogos({}).then( (resposta) => {
      this.carregando = false;
      this.catalogos = resposta;
    })
  }

  editarCatalogo(dataItem: any) {
    this.abraModalCatalogo(dataItem)
  }

  abraDialogRemover($event: any) {
    this.catalogoApagar = $event.dataItem;

    this.modalDeletar = true;
  }

  criarCatalogo() {
    this.abraModalCatalogo()
  }

  private abraModalCatalogo(catalogo: any = null) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Catálogo',
      content: FormCatalogoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaCatalogo: FormCatalogoComponent = windowRef.content.instance;

    telaCatalogo.windowRef = windowRef;

    if(catalogo && catalogo.id)
      telaCatalogo.catalogo = catalogo;

    windowRef.result.subscribe((result: any) => {
      if(result && result.id)
        this.carregueCatalogos();
    });
  }


  desativarCatalogo(catalogo: any) {

  }

  ativarCatalogo(catalogo: any) {

  }

  close(cancel: string) {

  }

  confirmeRemocao() {

  }

  canceleRemocao() {

  }

  verProdutos(catalogo: any) {
    this.router.navigateByUrl('/admin-rede/produtos?cid=' + catalogo.idCatalogo, {});
  }

  vaParaSincronizacao(catalogo: any) {
    this.router.navigateByUrl('/admin-rede/catalogo-modelo/' + catalogo.id,
      {state: { catalogo: catalogo}  });

    return false;
  }
}
