<div class="acoes " *ngIf="this.temModuloPedidos || this.temModuloFidelidade  ">
  <div class="alert alert-success mt-2" role="alert" *ngIf="msg">
    <i class="mdi mdi-check-all mr-2"></i> {{msg}}
  </div>

  <div class="alert alert-danger mt-2" role="alert" *ngIf="msgErro">
    <i class="mdi mdi-block-helper mr-2"></i> {{msgErro}}
  </div>

  <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="mensagemPontuacao">
    <a href="/imprimir/pontuacao/{{codigoPontuacao}}" target="_blank">
      <i class="fa fa-print fa-lg mr-2"></i>
      Pontuação adicionada com sucesso. <br>
      Nova pontuação é de
      <span class="font-18">
        <b>{{mensagemPontuacao.novaPontuacao}} {{mensagemPontuacao.tipo}}</b>
      </span>
    </a>
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemSucesso()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <div class="row" [class.quatro_botoes]="temModuloFidelidade && !temModuloPedidos"
       [class.tres_botoes]="temModuloPedidos && !temModuloFidelidade"  [class.seis_botoes]="temModuloFidelidade && temModuloPedidos">

    <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" [class.padding-right-5]="estaNoIframe"  *ngIf="estaNoIframe && contato?.codigoValidacaoPendente">
      <button class="btn btn-blue waves-effect tamanho-botao botao-fundo laranja" style="" (click)="enviarCodigoValidacao(contato)"
              title="Envia um codigo validaçao para cliente fazer login" [disabled]="enviandoCodigoValidacao">
        <i class="fas fa-mobile icone font-24"></i> Enviar Código de Login
      </button>
    </div>

    <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" [class.padding-right-5]="estaNoIframe"  *ngIf="estaNoIframe && temModuloPedidos">
      <button class="btn btn-blue waves-effect tamanho-botao botao-fundo azul" style="" (click)="enviarLinkCardapio(contato)"
              title="Envia a mensagem de saudação com o link do cardápio para o cliente" [disabled]="enviandoMensagemSaudacao">
        <i class="fas fa-link icone font-24"></i> Enviar Link do Cardápio
      </button>
    </div>

    <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" [class.padding-right-5]="estaNoIframe"  *ngIf="estaNoIframe && temModuloPedidos">
      <button class="btn btn-blue waves-effect tamanho-botao botao-fundo verde" (click)="enviarCardapio(contato)"
        [disabled]="enviandoCardapio">
        <i class="far fa-image icone font-24"></i> Enviar Imagem do Cardápio
      </button>
    </div>


    <ng-container *ngIf="((!empresa.dark || !estaNoIframe) && (contato?.telefone))">
      <div class="col-6 col-md  mt-2 mt-md-0 col-sm-4" [class.padding-left-5]="estaNoIframe" *ngIf="!possuiPedido && temModuloPedidos" >
        <button class="btn btn-blue waves-effect tamanho-botao botao-fundo laranja" (click)="novoPedido(contato)">
          <i class="fas fa-cart-plus icone font-24"></i> Novo Pedido
        </button>
      </div>
      <div class="col-6 col-md  mt-2 mt-md-0 col-sm-4" [class.padding-left-5]="estaNoIframe" *ngIf="possuiPedido && temModuloPedidos" >
        <button class="btn btn-blue waves-effect tamanho-botao botao-fundo laranja" (click)="continuarPedido(contato)">
          <i class="fas fa-cart-plus icone font-24"></i> Continuar Pedido
        </button>
      </div>

      <ng-container *ngIf="temPermisaoAdicionarPontos() && !novoContato">
        <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" [class.padding-right-5]="estaNoIframe"   *ngIf="temCartao ">
          <button class="btn btn-blue waves-effect tamanho-botao botao-fundo azul" (click)="adcionarPontos(contato)">
            <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/novoselo.svg'" [removeSVGAttributes]="['fill']"></div>
            Adicionar Pontos
          </button>
        </div>

        <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" [class.padding-right-5]="estaNoIframe"   *ngIf="!temCartao">
          <button class="btn btn-blue waves-effect tamanho-botao botao-fundo verde" (click)="criarCartao(contato)">
            <img class="icone tam1"  src="/assets/fidelidade/icones/novocartao.svg" />
            Novo cartão
          </button>
        </div>
      </ng-container>

      <div class="col-6 col-md  mt-2 mt-md-0 col-sm-4"  [class.padding-left-5]="estaNoIframe"
           *ngIf="!novoContato &&   temCartao && temPermisaoResgatarBrindes()">
        <button class="btn btn-blue waves-effect tamanho-botao botao-fundo roxo" (click)="trocarPontos(contato, null)">
          <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/trocar-selo.svg'" [removeSVGAttributes]="['fill']"></div>
          Trocar Pontos
        </button>
      </div>

      <div class="col-6 col-md mt-2 mt-md-0 col-sm-4"  *ngIf="!estaNoIframe && temCartao " >
        <button class="btn btn-blue waves-effect tamanho-botao botao-fundo" (click)="verCartao(contato)">
          <div class="icone tam1 iconeazul" [inlineSVG]="'/assets/fidelidade/icones/novoscartoes-dashboard.svg'" [removeSVGAttributes]="['fill']"></div>
          Ver Cartão
        </button>
      </div>

      <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" [class.padding-right-5]="estaNoIframe"  *ngIf="!novoContato && temModuloFidelidade && temCartao">
        <button class="btn btn-blue waves-effect tamanho-botao botao-fundo verde" (click)="enviarExtrato(contato)"
                [disabled]="enviandoExtratoPontos">
          <i class="fe-list icone font-24"></i> Enviar Extrato
        </button>
      </div>

      <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" *ngIf="!estaNoIframe">
        <a class="btn botao-fundo btn-success waves-effect tamanho-botao " [href]="'http://wa.me/55' + contato?.telefone" target="_blank">
          <div class="icone "> <i class="fab fa-whatsapp" ></i> </div>
          Enviar WhatsApp
        </a>
      </div>

      <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" *ngIf="temModuloPedidos && !estaNoIframe && !contato.bloqueado" (click)="bloquearContato(contato, true)">
        <button class="btn botao-fundo btn-danger waves-effect tamanho-botao " title="Bloquear contato para não fazer mais compras">
          <div class="icone "> <i class="fas fa-ban font-16"></i> </div>
          Bloquear Contato
        </button>
      </div>

      <div class="col-6 col-md mt-2 mt-md-0 col-sm-4" *ngIf="temModuloPedidos && !estaNoIframe && contato.bloqueado" (click)="desbloquearContato(contato, false)">
        <button class="btn botao-fundo btn-secondary waves-effect tamanho-botao " title="Desbloquear contato para que ele posso voltar a comprar">
          <div class="icone "> <i class="fas fa-ban font-16"></i> </div>
          Desbloquear Contato
        </button>
      </div>

      <div class="col-12 mt-2 col-md"  *ngIf="novoContato">
        <button  class="btn btn-blue   btn-lg  waves-effect waves-light float-left " type="button"
                 (click)="abraTelaNovoContato()">
          <i class="fe-user-plus"></i> Fidelizar Agora </button>
      </div>
    </ng-container>
  </div>
</div>
