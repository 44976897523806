import {Component, Input, OnInit} from '@angular/core';
import {EmpresasService} from "../../../superadmin/services/empresas.service";
import {CadUsuarioComponent} from "../../../superadmin/cad-usuario/cad-usuario.component";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../../lib/KendoPopupUtils";

@Component({
  selector: 'app-garcons-empresa',
  templateUrl: './garcons-empresa.component.html',
  styleUrls: ['./garcons-empresa.component.scss']
})
export class GarconsEmpresaComponent implements OnInit {
  @Input() private empresa: any;
  @Input() private moduloGarcom: any;

  garcons: any = [];
  paginacao = {
    size: 10,
    skip: 0,
    total: 0,
    buttonCount: 5,
    info: true,
    type: "numeric"  ,
    pageSizes: false,
    previousNext: true
  }
  loading: any;
  constructor(private empresaService: EmpresasService, private dialogService: DialogService) { }

  ngOnInit() {
    this.carregueGarcons();
  }

  private carregueGarcons() {
    this.empresaService.listeGarcons(this.empresa).then((resposta: any) => {
      this.garcons = resposta.data;
    })
  }

  editarUsuario({ sender, rowIndex, dataItem }) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadUsuarioComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.setUsuario( this.empresa, dataItem, !this.moduloGarcom);


    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          Object.assign(dataItem, result)
      });
  }

  mudouPaginacao($event: PageChangeEvent) {
    console.log($event)
  }

  novoGarcom() {
    let novoUsuario = { garcom: true, ativo: true}

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadUsuarioComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.setUsuario( this.empresa, novoUsuario, !this.moduloGarcom);

    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          this.carregueGarcons()
      });
  }
}
