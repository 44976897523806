import {AfterViewInit, Component, OnInit} from '@angular/core';
import StatusResponse = facebook.StatusResponse;
import {InstagramService} from "../../services/instagram.service";

@Component({
  selector: 'app-config-instagram',
  templateUrl: './config-instagram.component.html',
  styleUrls: ['./config-instagram.component.scss']
})
export class ConfigInstagramComponent implements OnInit, AfterViewInit {
  conectado = false;
  contas: Array<any>;
  accessToken: string;
  contaSelecionada: any;
  carregandoInstagram = false;
  mensagem = '';
  msgCarregando = '';
  dadosInstagram: any;

  constructor(private instagramService: InstagramService) {
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.instagramService.obtenhaContaConectada().then( (contaInsta) => {
      this.dadosInstagram = contaInsta;
      FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          this.conectado = true;
          this.contaSelecionada = contaInsta;
          if( this.contaSelecionada ) {
            this.mensagem = 'Chatbot instalado. Teste conversando com sua conta no instagram.'
          }
        } else if (response.status === 'unknown') {
          this.conectado = false;
          if( contaInsta ) {
            //this.desconectar();
          }
        }

        setTimeout(() => {
          FB.XFBML.parse();
        });
      });
    });
  }

  desconectar() {
    this.instagramService.desconectarConta().then( () => {
      FB.logout((response) => {
        this.conectado = false;
        this.contaSelecionada = null;

        setTimeout(() => {
          FB.XFBML.parse();
        })
      });

      this.contas = null;
    });
  }

  atualizarToken() {
    this.contaSelecionada = null;
    this.obtenhaContasInstagram(this.dadosInstagram.accessToken);
  }

  conectar() {
    FB.login((response: StatusResponse) => {
      if (response.status === "connected") {
        this.conectado = true;
      } else if (response.status === 'unknown') {
        this.conectado = false;
      }

      this.instagramService.estendaToken(response.authResponse.accessToken).then( (resposta) => {
        this.conectou(resposta);
      });

      setTimeout(() => {
        FB.XFBML.parse();
      });
    }, {
      scope: 'email,public_profile,instagram_basic,instagram_manage_messages,pages_show_list,pages_manage_metadata'
    });
  }

  conectou(accessToken: string) {
    this.accessToken = accessToken;

    this.obtenhaContasInstagram(this.accessToken).then((resp) => {
      if( this.contas.length === 1 ) {
        const conta = this.contas[0];
        this.mensagem = "Instalando chatbot no instagram selecionado."
        this.instagramService.obtenhaTokenDePagina(conta, accessToken, conta.tokenDePagina).then((resposta) => {
          this.contaSelecionada = conta;
          this.mensagem = 'Chatbot instalado. Teste conversando com sua conta no instagram.'
        });
      }
    });
  }

  obtenhaContasInstagram(accessToken: string): Promise<any> {
    this.carregandoInstagram = true;

    return new Promise((resolve => {
      this.contas = [];

      this.msgCarregando = 'Buscando Páginas do Facebook que possuem Instagram Business associado';
      FB.api(
        '/me/accounts?fields=id,name,username,access_token,category,category_list,' +
        'instagram_business_account&access_token=' + accessToken, (response) => {
        let requests = [];
        const paginas = response.data;
        for (let i = 0; i < response.data.length; i++) {
          const conta = response.data[i];

          if( !conta.instagram_business_account ) {
            continue;
          }

          const igId = conta.instagram_business_account.id;

          requests.push({method: 'GET', relative_url: igId + '?fields=name,profile_picture_url'});
        }

        this.msgCarregando = 'Estamos encontram as contas de instagram que você possui';
        FB.api('/?access_token=' + accessToken, 'POST', {
          batch: requests
        }, (responseInsta: any) => {
          this.carregandoInstagram = false
          for (let i = 0; i < responseInsta.length; i++) {
            const pagina = paginas[i];
            const respInsta = responseInsta[i];

            let idInstagram = '';

            if( pagina.instagram_business_account ) {
              idInstagram = pagina.instagram_business_account.id;
            }

            if (respInsta.code === 200) {
              const conta = JSON.parse(respInsta.body);
              conta.idPagina = pagina.id;
              this.contas.push({
                tokenDePagina: pagina.access_token,
                nomeInstagram: conta.name,
                igid: idInstagram,
                imageProfileUrl: conta.profile_picture_url,
                idPagina: pagina.id
              });
            }
          }

          resolve(null);
        });
      });
    }));
  }

  selecionouInstagram(conta: any) {
    this.instagramService.obtenhaTokenDePagina(conta, this.accessToken, conta.tokenDePagina).then((resposta) => {
      this.contaSelecionada = conta;
    }).catch( erro => {
      conta.msgErro = erro;
    });
  }
}
