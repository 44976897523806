<b> {{getStatusPagamentoOnline(pedido)}}</b>
<i class="fa fa-lg  fa-sync ml-2 text-warning cpointer" title="sincronizar com {{pedido.gatewayPagamentoOnline}}" kendoTooltip
   (click)="sincronizarPagamentoOnline(pedido, $event)"
   [hidden]="pedido.sincronizandoPagamento">
</i>

<span *ngIf="pedido.motivoReprovacao" class=" text-danger cpointer" [hidden]="pedido.sincronizandoPagamento">
            <br>     <i class="fa fa-times-circle ml-1"></i> ver erro
        </span>

<i class="k-icon k-i-loading ml-2 fa-lg" *ngIf="pedido.sincronizandoPagamento"></i>
