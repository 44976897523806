<div class="form-group mb-3">
  <label> <b> Selecione a razão ( enviar para ifood )</b></label>
  <kendo-dropdownlist  name="entregado" [(ngModel)]="razaoCancelamento"
                       [data]="obtenhaRazoesIfood()" textField="description" valueField="cancelCodeId"
                       class="form-control " required>
  </kendo-dropdownlist>

  <div class="invalid-feedback">
    Informe a razão do cancelamento
  </div>
</div>

<div class="alert alert-danger alert-dismissible fade show mt-2 mb-2" *ngIf="erroParaCancelar" role="alert">
  {{erroParaCancelar}}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
