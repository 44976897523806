import {Component, Input, OnInit} from '@angular/core';
import {EditEvent, GridDataResult, PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {ActivatedRoute} from "@angular/router";
import {CadPlanosComponent} from "./cad-planos/cad-planos.component";
import {Plano} from "../../../../server/domain/Plano";
import {PlanosService} from "../services/planos.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

import {KendoPopupUtils} from "../../lib/KendoPopupUtils";


@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss']
})
export class PlanosComponent implements OnInit {
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = false;
  public previousNext = true;
  tamanhoPagina = 10;
  mensagens: any;
  total: any;
  loading = false;
  public gridDataResult: GridDataResult;

  planos: any = [];
  page = 0;
  idEmpresa: any;
  @Input()
  private empresa: any;
  abraDialogo: boolean;
  exibaConfirmacaoDesativar: boolean
  planoSelecionado: any;
  atualizandoPlano: boolean;
  mensagemRemocao: string;
  private removeu: boolean;

  constructor(  private route: ActivatedRoute, private dialogService: DialogService,
              private planosService: PlanosService) {
  }

  ngOnInit() {
    this.carreguePlanos(0);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.crieModalPlano(dataItem);
  }

  novoPlano() {
    this.crieModalPlano(null);
  }

  crieModalPlano(plano: Plano) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadPlanosComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100
    });

    windowRef.content.instance.empresa = this.empresa;
    if( plano )
      windowRef.content.instance.editePlano(plano);

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe( () => {
        console.log('When user closes'); },
      () => {
        this.carreguePlanos(0);
      });
  }

  private carreguePlanos(page: number) {
    this.planosService.obtenhaPlanos(this.empresa.id, page).then( (respEmpresas: any) => {
      this.planos = respEmpresas.data;

      this.gridDataResult = {
        data: this.planos,
        total: this.planos.length
      }
    });
  }

  carregue($event: PageChangeEvent) {
    this.page = $event.skip;
    this.loading = true;
    this.carreguePlanos(this.page);
  }

  abraDialogRemoverPlano($event: RemoveEvent) {
    this.planoSelecionado = $event.dataItem;
    this.abraDialogo = true;

  }

  confirmeDesativarPlano(plano: any) {
    this.planoSelecionado = plano;
    this.exibaConfirmacaoDesativar = true;
  }

  fechar() {
    this.planoSelecionado = null;
    this.mensagemRemocao = null;
    this.atualizandoPlano = false;
    this.abraDialogo = false;
    this.exibaConfirmacaoDesativar = false;
  }

  ativarPlano(plano: any){
    if(this.atualizandoPlano) return
    this.atualizandoPlano = true;

    plano.empresa = this.empresa;

    this.planosService.ativePlano(plano).then((resposta) => {
      plano.ativo = true;
      this.atualizandoPlano = false;
    }).catch((reason) => {
      this.atualizandoPlano = false;
      alert(reason)
    })
  }

  desativarPlano(){
    if(this.atualizandoPlano) return
    this.atualizandoPlano = true;

    this.planoSelecionado.empresa = this.empresa;

    this.planosService.desativePlano(this.planoSelecionado).then((resposta) => {
        this.planoSelecionado.ativo = false;
        this.atualizandoPlano = false;
        this.fechar();
    }).catch((reason) => {
      this.atualizandoPlano = false;
      this.mensagemRemocao = reason;
    })
  }

  removerPlano() {
    if(this.atualizandoPlano) return
    this.atualizandoPlano = true;

    this.planosService.removaPlano(this.planoSelecionado).then((resposta) => {
      this.planosService.obtenhaPlanos(this.empresa.id, 0).then( (respEmpresas: any) => {
        this.removeu = true;
        this.atualizandoPlano = false;
        this.mensagemRemocao = "O plano '" + this.planoSelecionado.nome + "' foi removido ";
        this.planoSelecionado = false;

        this.planos = respEmpresas.data;

        this.gridDataResult = {
          data: this.planos,
          total: this.planos.length
        }
      })

    }).catch((reason) => {
      this.atualizandoPlano = false;
      this.planoSelecionado = null;
      this.mensagemRemocao = reason;
    })
  }


}
