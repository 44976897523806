<div *ngIf="!abrirDialogAdicional && !copiarAdicional">
  <div >
    <label>Opções extras e adicionais</label>
  </div>
  <div    style="min-height: 150px;"  class="k-widget k-grid">
    <div position="top" kendoTooltip class="k-grid-toolbar k-header">
      <button  [disabled]="!podeEditarProdutos"
               class="k-button btn-primary" (click)="novoAdicional()">
        <span kendoTooltip
              [title]="podeEditarProdutos ? 'Criar um novo adicional exclusivo para o produto' : 'Não pode criar adicionais em catálogo compartilhado'" ><i  class="fe-plus mr-1" ></i>   Novo adicional  </span> </button>

      <button  class="k-button btn-primary ml-1" (click)="novoAdicionalProduto()" [disabled]="!podeEditarProdutos">
        <span kendoTooltip
              [title]="podeEditarProdutos ? 'Criar um novo adicional do tipo produto' : 'Não pode criar adicionais em catálogo compartilhado'"><i  class="fe-plus mr-1" ></i>   Novo adicional de produto</span></button>

      <button  [disabled]="!podeEditarProdutos"
               class="k-button btn-confirm btn-blue" style="background: #306cc8;" kendoTooltip (click)="exibaCopiarAdicionalProduto(false)" *ngIf="!naoPermiteCopiar"
              [title]="podeEditarProdutos ? 'Utiliza o complemento do outro produto. O complemento será compartilhado entre todos produtos que o utilizam.': 'Não pode criar adicionais em catálogo compartilhado' ">

        <span><i class="fas fa-link"></i>&nbsp;
        Usar de outro produto</span>
      </button>
      <button [disabled]="!podeEditarProdutos"
              kendoTooltip class="k-button btn-confirm" (click)="exibaCopiarAdicionalProduto(true)" *ngIf="!naoPermiteCopiar"
              [title]="podeEditarProdutos ? 'Duplica o complemento do outro produto neste.' : 'Não pode criar adicionais em catálogo compartilhado'">
        <i class="far fa-clone"></i> &nbsp;
        Duplicar de outro produto
      </button>
    </div>
    <div role="grid" class="k-grid-aria-root mt-2" aria-rowcount="5" aria-colcount="3" >
      <div role="presentation" class="k-grid-header" style="padding: 0px">
        <div role="presentation" data-scrollable="" class="k-grid-header-wrap"
             ng-reflect-kendo-grid-resizable-container="0" ng-reflect-locked-width="19">
          <table role="presentation" ng-reflect-virtual-columns="false">
            <colgroup kendogridcolgroup="" role="presentation" ng-reflect-columns="[object Object],[object Object"
                      ng-reflect-groups="">
              <col>
              <col>
              <col style="width: 100px;"></colgroup>
            <thead kendogridheader="" role="presentation">
            <tr _ngcontent-dpe-c349="" kendogridlogicalrow="" role="row">

              <th _ngcontent-dpe-c349="" kendogridlogicalcell="" role="columnheader" aria-selected="false"
                  kendodroptarget="" kendodraggable="" kendodraggablecolumn="" class="k-header"
                  rowspan="1" colspan="1" aria-colindex="1">Nome</th>
              <th _ngcontent-dpe-c349="" kendogridlogicalcell="" role="columnheader" aria-selected="false"
                  kendodroptarget="" kendodraggable="" kendodraggablecolumn="" class="k-header"
                  ng-reflect-logical-row-index="0" ng-reflect-logical-col-index="1" ng-reflect-col-span="1"
                  ng-reflect-row-span="1" ng-reflect-enable-drag="false" ng-reflect-context="[object Object]"
                  rowspan="1" colspan="1" aria-colindex="2">Opções</th>
              <th _ngcontent-dpe-c349="" kendogridlogicalcell="" role="columnheader" aria-selected="false"
                  kendodroptarget="" kendodraggable="" kendodraggablecolumn="" class="k-header"
                  ng-reflect-logical-row-index="0" ng-reflect-logical-col-index="2" ng-reflect-col-span="1"
                  ng-reflect-row-span="1" ng-reflect-enable-drag="false" ng-reflect-context="[object Object]"
                  rowspan="1" colspan="1" aria-colindex="3"></th></tr>
            </thead>
          </table>
        </div>
      </div>
              <kendo-sortable
                #sortable
                [kendoSortableBinding]="objetoComAdicionais.camposAdicionais"
                [navigatable]="true"
                [animation] = "true"
                emptyText="Sem opções extras/adicionais"
                [emptyItemStyle]="{'text-align': 'center'}"
                (dragOver)="onDragOver($event)"
                (navigate)="onNavigate($event)"
                (dragEnd)="onDragEnd($event)"
                class=""
                itemClass=" "
                activeItemClass="   active">
                <ng-template let-dataItem="item" let-index="index" >
                  <div role="presentation" tabindex="-1" class="k-grid-content k-virtual-content"
                       ng-reflect-kendo-grid-resizable-container="0" ng-reflect-locked-width="1" style="overflow: hidden">
                    <div role="presentation" class="k-grid-table-wrap">

                      <table role="presentation" class="k-grid-table" ng-reflect-virtual-columns="false">
                        <colgroup kendogridcolgroup="" role="presentation">
                          <col>
                          <col>
                          <col style="width: 100px;"></colgroup>
                        <tbody kendogridtablebody="" role="presentation" >

                        <tr role="row" [class.k-alt]="index % 2 > 0">
                          <td role="gridcell" aria-selected="false"
                              aria-colindex="1">
                            <span>{{dataItem.nome}}</span>
                            <span class="d-inline-block p-1 ml-1" style="border-radius: 5px;background: #f1ecff;color: #5f2cb6"
                                  title="Esse adicional é compartilhado com outros." kendoTooltip *ngIf="dataItem.compartilhado">
                              <i class="fas fa-link"></i>
                            </span>
                          </td>
                          <td role="gridcell" aria-selected="false"
                              colspan="1" aria-colindex="2">
                            <span *ngFor="let opacao of obtenhaAlgumasOpcoes(dataItem.opcoesDisponiveis); let ultimo = last">
                                         {{opacao.nome}} <span *ngIf="!ultimo">, </span> </span>

                            <span *ngIf="dataItem.opcoesDisponiveis.length > 10"><b>+{{dataItem.opcoesDisponiveis.length - 10}}</b></span>
                          </td>
                          <td kendogridcell="" kendogridlogicalcell="" role="gridcell" aria-selected="false"
                              ng-reflect-row-index="0" ng-reflect-column-index="2" ng-reflect-column="[object Object]"
                              ng-reflect-data-item="[object Object]" ng-reflect-logical-row-index="1"
                              ng-reflect-logical-col-index="2" ng-reflect-data-row-index="0" ng-reflect-col-index="2"
                              ng-reflect-col-span="1" data-kendo-grid-column-index="2" colspan="1" class="k-command-cell"
                              aria-colindex="3"><span _ngcontent-dpe-c666=""><button _ngcontent-dpe-c666=""
                                                                                     kendogrideditcommand=""
                                                                                     ng-reflect-primary="true" role="button"
                                                                                     aria-disabled="false" dir="ltr"
                                                                                     (click)="editarAdicional(dataItem)"
                                                                                     class="k-button k-primary k-grid-edit-command"><i
                            _ngcontent-dpe-c666="" class="fa fa-edit"></i></button><button _ngcontent-dpe-c666=""
                                                                                           kendogridremovecommand=""
                                                                                           (click)="removerAdicional(dataItem, index)"
                                                                                           class="ml-1 k-button k-grid-remove-command"
                                                                                           role="button" aria-disabled="false"
                                                                                           dir="ltr"><i _ngcontent-dpe-c666=""
                                                                                                        class="fa fa-trash"></i></button></span>
                          </td>
                        </tr>
                        </tbody>



                      </table>
                    </div>
                  </div>

                  <!--
                  <div kendodraggable="" kendogridselectionmarquee="" class="k-grid-container" >
                  </div>
                  -->
                </ng-template>
              </kendo-sortable>
    </div>
  </div>
</div>

<div *ngIf="abrirDialogAdicional">
  <app-cad-adicional-produto   [produto]="objetoComAdicionais" [catalogo]="catalogo"     [templatesPiza]="templatesPiza"
                               [sistemaIntegrado]="empresa.integracaoDelivery?.sistema"   [pizzaria]="empresa.pizzaria"
                               [empresa]="empresa"
                               (onSalvou)="encerrouEdicaoDeAdicional($event)"
                               #cadAdicionalProduto [servicoSalvarAdicional]="servicoSalvarAdicional"></app-cad-adicional-produto>
</div>

<div *ngIf="copiarAdicional" >

  <app-form-copiar-adicional [produto]="objetoComAdicionais" [clonarAdicional]="clonarAdicional" [catalogo]="catalogo"
                             (onCopiou)="copiouAdicionais($event)"></app-form-copiar-adicional>

</div>

<kendo-dialog title="Remover Adicional" *ngIf="abraDialogoRemover" (close)="fecharDialogRemover()" [minWidth]="250" [width]="450">
  <div class="form-group mb-3">
    Tem certeza que você deseja remover o campo adicional "{{adicionalRemover.nome}}"?
  </div>
  <div class="modal-footer">
    <button type="button" (click)="confirmouRemoverAdicional()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" (click)="fecharDialogRemover()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</kendo-dialog>
