<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit(frm, template)">
  <div #pnl class="modal-body" style="max-width: 1024px;">
    <div *ngIf="template.mensagemSucesso" class="alert alert-success alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemSucesso()">
        <span>&times;</span>
      </button>
      <i class="mdi mdi-check-all mr-2"></i> {{template.mensagemSucesso}}
    </div>

    <div class="row">
      <div class="col mt-2" >
        <label>Nome do Template (sem espaços)</label>
        <div class="ui-g" style="width:250px;margin-bottom:10px">
          <input appAutoFocus type="text" class="form-control" #nomeTemplate="ngModel" placeholder="NomeTemplate" name="nomeTemplate"
                 required (keypress)="impedirEspacos($event)" [(ngModel)]="template.nome"/>
          <span class="small">[Template_{{template.nome? template.nome : "NomeTemplate"}}]</span>

        </div>
        <div *ngIf="nomeTemplate.invalid && (nomeTemplate.dirty || nomeTemplate.touched || frm.submitted)" class="invalid-feedback">
          <div *ngIf="nomeTemplate.errors.required">
            Informe o nome do template para ser usado nas mensagens de notificação e campanha.
          </div>
        </div>
      </div>

    </div>




    <div class="form-group mb-3" style="position: relative;">
      <label for="txtMensagem">Mensagem</label>
      <div>
        <div style="position: absolute;right: 0px;top: -13px;">
          <kendo-dropdownbutton [data]="campos" buttonClass="btn btn-azul" textField="nome" (itemClick)="adicioneCampo(textArea, template, $event)" >
            Adicionar Campo <i class="mdi mdi-chevron-down"></i>
          </kendo-dropdownbutton>

        </div>

        <textarea class="form-control" id="txtMensagem" rows="10" name="txtMensagem" [(ngModel)]="template.mensagem" #txtMensagem="ngModel" #textArea
                  required (ngModelChange)="alterouMensagem(template)" [maxlength]="QTDE_MAXIMA_CARACTERES"></textarea>
        <div>
          <span [ngClass]="{'text-warning': template.qtdeCaracteresRestantes < 50, 'text-success': template.qtdeCaracteresRestantes >= 50,
           'text-danger': template.qtdeCaracteresRestantes < 20}">
            {{template.qtdeCaracteresRestantes}} caracteres restantes</span>
        </div>
        <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched || frm.submitted)" class="invalid-feedback">
          <div *ngIf="txtMensagem.errors.required">
            Informe o texto do template que substituirá o marcador na mensagem.
          </div>
        </div>
      </div>
    </div>

    <hr>

    <p class="text-danger mt-2 mb-2" *ngIf="erro">{{erro}}</p>

    <div class="text-center mb-1">
      <button type="submit" class="btn btn-primary width-lg" >Salvar</button>
    </div>
  </div>
</form>
