<ng-container *ngIf="produto != null && opcoes.length > 0">
  <kendo-grid
    [data]="opcoes"
    [loading]="carregando"
    style="max-width: 800px;"
    id="gridDeProdutos"
    [scrollable]="true">
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem produtos"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>
    <kendo-grid-column title="ID"    width="90">
      <ng-template kendoGridCellTemplate let-dataItem  >
        <i class="fas fa-circle complemento"></i>
        <span class="text-blue ml-1"><b>{{dataItem.id}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Nome" width="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span><b>{{dataItem.nome}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Descrição" class="coluna_menor" media="(min-width: 1300px)">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="abreviar">{{dataItem.descricao}}</div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Preco" width="100">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="text-success"><b>{{dataItem.valor | currency:"BRL"}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" width="155px">
      <ng-template kendoGridCellTemplate let-opcao>
        <button class="btn btn-sm btn-danger" (click)="pausarAdicional(opcao)"
                *ngIf="produto.catalogo.disponibilidadePorEmpresa && opcao.opcaoNaEmpresa && opcao.opcaoNaEmpresa.id  ?  opcao.opcaoNaEmpresa.disponivel : opcao.disponivel" style="width: 130px;" [disabled]="processando">
          <i class="far fa-pause-circle" kendoTooltip title="Parar Temporariamente o Adicional"></i> Parar Vendas
        </button>
        <button (click)="ativarAdicional(opcao)" *ngIf="!(produto.catalogo.disponibilidadePorEmpresa && opcao.opcaoNaEmpresa && opcao.opcaoNaEmpresa.id  ?  opcao.opcaoNaEmpresa.disponivel : opcao.disponivel)" class="btn btn-sm btn-primary" style="width: 130px;" [disabled]="processando">
          <i class="far fa-play-circle" kendoTooltip title="Ativar Vendas do Adicional"></i> Ativar Vendas
        </button>
      </ng-template>
    </kendo-grid-command-column>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
  </kendo-grid>
</ng-container>
