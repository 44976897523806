import {Component, OnInit, ViewChild} from '@angular/core';
import {TemplatePromptsService} from "../../services/template-prompts.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TemplatesPromptsDbService} from "../../services/templates-prompts-db.service";
import {PopoverContainerDirective} from "@progress/kendo-angular-tooltip";

@Component({
  selector: 'app-lista-templates-prompts',
  templateUrl: './lista-templates-prompts-db.component.html',
  styleUrls: ['./lista-templates-prompts-db.component.scss']
})
export class ListaTemplatesPromptsDbComponent implements OnInit {
  templatePrompts = [
  ];
  carregando: boolean;
  isGlobal = false;

  @ViewChild("container", { static: false })
  private container: PopoverContainerDirective;

  constructor(private templatePromptsService: TemplatesPromptsDbService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      // Verifica se a URL pertence ao superadmin
      this.isGlobal = url.map(segment => segment.path).join(',').includes('global');
    });

    this.carregando = true;
    this.carregueTemplatesPrompts().then( () => {
      this.carregando = false;
    }).catch( () => {
      this.carregando = false;
    });
  }

  // carregue os templateprompts do servidor /templatesprompts/liste
  carregueTemplatesPrompts() {
    return new Promise( (resolve, reject) => {
      this.templatePromptsService.liste(this.isGlobal).then((resposta) => {
        this.templatePrompts = resposta;

        // processe os templates prompts e formate o template com negrito onde tiver ${}
        this.templatePrompts.forEach( (templatePrompt) => {
          templatePrompt.template = this.formateTemplate(templatePrompt.template);
          templatePrompt.template = this.boldWords(templatePrompt.template);
        });
        resolve(true);
      }).catch( (erro) => {
        reject(erro);
      });
    });
  }

  private formateTemplate(template: string) {
    const regex = /\${(.*?)}/g;
    let match;

    while ((match = regex.exec(template)) !== null) {
      template = template.replace(match[0], `<b>\$\{${match[1]}\}</b>`);
    }
    //substitui (empresa) por um ícone i fontawesome
    template = template.replace(/\(empresa\)/g, '<i class="fas fa-cog" aria-hidden="true"></i>');
    template = template.replace(/\(global\)/g, '<i class="fas fa-globe" aria-hidden="true"></i>');

    return template;
  }

  boldWords(text: string): string {
    text = text.replace(/\[(.*?)\]/g, '<strong>[$1]</strong>');
    text = text.replace(/\${(.*?)}/g, '<strong>${$1}</strong>');
    text = text.replace(/CUPOM::(.*?)/g, '<strong>CUPOM::$1</strong>');
    text = text.replace(/\n/g, '<br>');
    return text;
  }

  private geraTexto(exemplos: Array<any>) {
    let texto = '';

    exemplos.forEach((exemplo, index) => {
      if (index > 0) {
        texto += '\n';
      }

      texto += `Cliente: ${exemplo.pergunta} Mia: ${exemplo.resposta}`;
    });

    return texto;
  }

  editarTemplatePrompt(dataItem: any) {
    if( this.isGlobal ) {
      this.router.navigate(['/superadmin/prompts-mia/global/nova/', dataItem.id]);
      return;
    }

    this.router.navigate(['/admin/templates-prompts-db/nova/', dataItem.id]);
  }

  public onShow(anchor: Element): void {
    this.container.hide();

    this.container.show(anchor);
  }
}
