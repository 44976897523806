import {IUITipoDePontuacao} from "./IUITipoDePontuacao";

export class UITipoDePontuacaoQtdSelos implements IUITipoDePontuacao {
  selosPorAtividade: number;
  empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoResgistrada: any) {
    return this.selosPorAtividade * pontuacaoResgistrada.atividades.length;
  }
}
