import {Directive, ElementRef, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  selector: '[minimo][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValorMinimoValidator, multi: true }
  ]
})
export class ValorMinimoValidator  implements  Validator {
  @Input() minimo: number;

  constructor(private el: ElementRef) {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    const valor = c.value;

    const erros = (valor < this.minimo) ? {"minimo":  this.minimo} : null;

    if( erros ) {
      this.el.nativeElement.setCustomValidity('erro de mínimo');
    } else {
      this.el.nativeElement.setCustomValidity('');
    }

    return erros;
  }
}
