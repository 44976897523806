import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EditEvent, PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {NgForm, NgModel} from "@angular/forms";
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {ProdutoService} from "../../services/produto.service";
import {
  FormCadastroOpcaoadicionalComponent
} from "./form-cadastro-opcaoadicional/form-cadastro-opcaoadicional.component";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-cad-adicional-produto',
  templateUrl: './cad-adicional-produto.component.html',
  styleUrls: ['./cad-adicional-produto.component.scss']
})
export class CadAdicionalProdutoComponent implements OnInit {
  @ViewChild('frmOpcaoAdicional', {static: true}) frmOpcaoAdicional: NgForm;
  @ViewChild('frmAdicional', {static: true}) frmAdicional: NgForm;
  @ViewChild('opcoesDisponiveis', {static: true}) fieldOpcoesDisponiveis: NgModel;
  @ViewChild('campoAdicionalEdicao', {static: true}) campoAdicionalEdicao: any;
  @ViewChild('formCadastroOpcao', {static: false}) formCadastroOpcao: FormCadastroOpcaoadicionalComponent;
  @Input()   produto: any = {};
  @Input()   catalogo: any = {};
  @Input()   empresa: any = {};
  @Input()   templatesPiza: any = [];
  @Input()   abraDialogoOpcao: boolean;
  @Input() servicoSalvarAdicional: any;
  @Input() sistemaIntegrado: string;
  @Input() pizzaria: boolean;
  @Output()  onSalvou = new EventEmitter();
  adicionalDeProdutoEmEdicao: any
  carregando: boolean;
  abraDialogoRemover: boolean;
  opcaoRemover: any;
  salvando: boolean;
  erro: string;
  minimoMaximo = 0
  adicionalDeProduto: any = { opcoesDisponiveis: [], tipoDeCobranca: 'SOMA'  }
  itemPedidoExemplo: any = {
    lista0: {
      totalSelecionado: 0
    }
  };
  camposOrdenar = [{campo: 'nome', descricao: 'Nome' },
                   {campo: 'valor', descricao: 'Valor'},
                   {campo: 'id', descricao: 'Cadastro'},
                    {campo: 'codigoPdv', descricao: 'Código PDV'}]

  campoOrdenar: any;
  possuiPermissaoEditarPrecos: boolean;
  constructor(private autorizacaoService: AutorizacaoService) { }

  ngOnInit() {
    this.possuiPermissaoEditarPrecos = RotaGuardClient.alterarPrecoCardapioCompartilhado(this.autorizacaoService.getUser());
    this.exibaAdicional();
  }

  exibaAdicional(adicionalDeProduto: any = null) {
    if(adicionalDeProduto && adicionalDeProduto.id){
      this.adicionalDeProdutoEmEdicao = adicionalDeProduto;
      Object.assign(this.adicionalDeProduto , adicionalDeProduto);
    }

    if(this.adicionalDeProduto.qtdMinima)
      this.minimoMaximo = this.adicionalDeProduto.qtdMinima;

    if(this.adicionalDeProduto.campoOrdenar){
      this.campoOrdenar = this.camposOrdenar.find( campoOrdenar => campoOrdenar.campo === this.adicionalDeProduto.campoOrdenar)
      this.setOpcoesOrdenadas();
    }

    this.campoAdicionalEdicao.exibaAdicionalDoProduto(this.adicionalDeProduto, this.itemPedidoExemplo, 0)
  }

  removerOpcao($event: RemoveEvent) {
    $event.dataItem.removida = true;
    //this.abraDialogoRemover = true
  }

  carregue($event: PageChangeEvent) { }

  adicionarOpcao() {
    this.abraDialogoOpcao = true;
    setTimeout(() => {
      this.formCadastroOpcao.setNovaOpcao();
    }, 0)

    return false;
  }

  editarOpcao(opcao: any) {
    this.abraDialogoOpcao = true;
    setTimeout(() => {
       this.formCadastroOpcao.setEditarOpcao(opcao)
    }, 0);
  }

  cancelar() {
    this.onSalvou.emit(null)
  }

  fecheDialogOpcao() {
    this.abraDialogoOpcao = false
  }

  salvouOpcaoDeAdicional(novasOpcoes: any) {
    novasOpcoes.forEach((opcao: any) => {
      if(opcao.nova){
        if(!this.itemPedidoExemplo['lista0'])
          this.itemPedidoExemplo['lista0'] = {}

        this.adicionalDeProduto.opcoesDisponiveis.push(opcao)

        this.itemPedidoExemplo['lista0'].totalSelecionado = 0

        let indice = this.adicionalDeProduto.opcoesDisponiveis.length - 1

        this.itemPedidoExemplo['lista0']['opcao_' + indice] = {
          selecionada: false
        }

        this.itemPedidoExemplo['lista0']['opcao_' + indice].qtde = 0

      }
    })

    this.setOpcoesOrdenadas();

    this.fieldOpcoesDisponiveis.control.setErrors({'minimoLista': false})


    this.fecheDialogOpcao();
  }

  salvarAdicionalDeProduto() {
    if(this.salvando) return;

    if(this.adicionalDeProduto.opcoesDisponiveis.length === 0)
      this.fieldOpcoesDisponiveis.control.setErrors({'minimoLista': true})
    else
      this.fieldOpcoesDisponiveis.control.setErrors(null)

    delete this.erro;
    if(this.frmAdicional.valid) {

      this.salvando = true;
      this.servicoSalvarAdicional.salveAdicional(this.catalogo, this.produto, this.adicionalDeProduto,
        this.empresa.id ? null : true).then( (resp: any) => {
        this.salvando = false;
        this.adicionalDeProduto.opcoesDisponiveis = resp.opcoesDisponiveis;
        if(!this.adicionalDeProduto.id){
          this.adicionalDeProduto.id = resp.id;
          this.adicionalDeProduto.opcoesDisponiveis =
          this.produto.camposAdicionais.push( Object.assign({}, this.adicionalDeProduto));
        } else {
          Object.assign(this.adicionalDeProdutoEmEdicao, this.adicionalDeProduto);
        }

        this.onSalvou.emit(this.adicionalDeProduto);

      }).catch( erro => {
        this.salvando = false;
        this.erro = erro;
      })

    } else
      this.erro = "Verifique o preenchimento dos campos"

    return false;
  }

  marqueAtualizar(opcao: any){
    if(opcao.id)
      opcao.atualizar = true;
  }

  fecharDialogRemover() {
    this.opcaoRemover = null
    this.abraDialogoRemover = false
  }

  confirmouRemoverOpcao() {
    this.opcaoRemover.removida = true;
    this.fecharDialogRemover()
  }

  cancelarRemocao(event, dataItem: any) {
    dataItem.removida = null;
    event.stopPropagation();
    event.preventDefault();

  }

  mudoCampoOrdenar(campoOrdenar: any) {
    this.adicionalDeProduto.campoOrdenar =  campoOrdenar.campo
    this.setOpcoesOrdenadas()
  }

  setOpcoesOrdenadas( ){

    if(  this.adicionalDeProduto.campoOrdenar){
      this.adicionalDeProduto.opcoesDisponiveis = orderBy(this.adicionalDeProduto.opcoesDisponiveis, [{
        field:   this.adicionalDeProduto.campoOrdenar,
        dir: 'asc'
      }]);
    }
  }

  setComoCombo() {
    this.adicionalDeProduto.combo = true;
    this.adicionalDeProduto.obrigatorio = true;
    this.adicionalDeProduto.tipo =  'escolha-simples';
  }


  alterouMinimo(valor) {
    this.minimoMaximo = valor > 0 ? valor : 1
  }
}
