import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemaPersonalizadoService } from '../../services/tema-personalizado.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tela-criar-tema-personalizado',
  templateUrl: './tela-criar-tema-personalizado.component.html',
  styleUrls: ['./tela-criar-tema-personalizado.component.scss']
})
export class TelaCriarTemaPersonalizadoComponent implements OnInit, AfterViewInit {
  temaForm: FormGroup;
  salvando = false;
  mensagemSucesso: string;
  mensagemErro: string;
  idTema = '';

  constructor(
    private fb: FormBuilder,
    private temaService: TemaPersonalizadoService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.temaForm = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      corFundo: ['#FFFFFF', Validators.required],
      corTextoFundo: ['#000000', Validators.required],
      corTextoPrimaria: ['#FFFFFF', Validators.required],
      corBotao: ['#4a81d4', Validators.required],
      corTextoBotao: ['#FFFFFF', Validators.required],
      corTextoSecundaria: ['#FFFFFF', Validators.required]
    });

    this.activatedRoute.params.subscribe(params => {
      this.idTema = params['id'];
      if (this.idTema) {
        this.carregarTema(this.idTema);
      }
    });
  }

  carregarTema(id: string) {
    this.temaService.obtenha(id).then(tema => {
      debugger;
      if (tema) {
        this.temaForm.patchValue(tema);
      }
    }).catch(erro => {
      console.error('Erro ao carregar tema:', erro);
      this.mensagemErro = 'Erro ao carregar tema. Por favor, tente novamente.';
    });
  }

  salvarTema() {
    if (this.temaForm.valid) {
      this.salvando = true;
      this.mensagemSucesso = '';
      this.mensagemErro = '';

      this.temaService.crieTema(this.temaForm.value)
        .then(() => {
          ///this.salvando = false;
          if (this.idTema) {
            this.mensagemSucesso = 'Tema personalizado atualizado com sucesso!';
          } else {
            this.mensagemSucesso = 'Tema personalizado criado com sucesso!';
          }
          setTimeout(() => {
            this.router.navigate(['/admin/temas']);
          }, 2000);
        })
        .catch((erro) => {
          this.salvando = false;
          this.mensagemErro = 'Erro ao criar tema personalizado: ' + erro;
        });
    }
  }

  cancelar() {
    this.router.navigate(['/admin/temas']);
  }

  ngAfterViewInit(): void {
  }

  voltar() {
    this.router.navigateByUrl('/admin/temas');
  }
}
