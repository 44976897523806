import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PersistenciaDeChatsWhatappService {
  private MENSAGENS_KEY = 'mensagens2';

  constructor() {
    localStorage.removeItem('mensagens');
  }

  salvarMensagem(mensagem: any) {
    console.log(`[persistencia-de-chats-whatapp] id: ${mensagem.id} chatId: ${mensagem.chatId._serialized} type: ${mensagem.type} timestamp: ${mensagem.timestamp}`);

    const mensagensArmazenadas = this.recuperarMensagensArmazenadas();

    if( !mensagem.chatId || !mensagem.chatId._serialized ) return;

    mensagensArmazenadas.set(mensagem.chatId._serialized, {
      id: mensagem.id,
      timestamp: mensagem.timestamp
    });
    console.log('msg: ' + mensagem.body);

    localStorage.setItem(this.MENSAGENS_KEY, JSON.stringify(Array.from(mensagensArmazenadas.entries())));
  }

  recuperarMensagemPorChatId(chatId: string): any {
    const mensagensArmazenadas = this.recuperarMensagensArmazenadas();
    const mensagemArmazenada: any = mensagensArmazenadas.get(chatId);

    if( mensagemArmazenada ) {
      mensagemArmazenada.chatId = chatId;
    }
    return mensagemArmazenada ? mensagemArmazenada : null;
  }

  recuperarMensagensArmazenadas() {
    const mensagensArmazenadas = localStorage.getItem(this.MENSAGENS_KEY);
    return mensagensArmazenadas ? new Map(JSON.parse(mensagensArmazenadas)) : new Map();
  }
}
