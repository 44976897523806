<form [ngClass]="{'needs-validation': !_submitted, 'was-validated': _submitted}"  novalidate #frm="ngForm"  >
  <div  class="mb-3 mt-2 container"    >
    <h4 class="titulo" [hidden]="pedido.alteracaoEndereco.aceito != null"><b>
      <i class="k-icon k-i-exclamation-circle mr-1"></i>Cliente solicitou alteração do endereço de entrega</b></h4>

    <br>
    <h5 class="text-muted"><i class="fas fa-map-marker-alt mr-1"></i> <b>Endereço novo:</b> </h5>
    <div class="alert alert-info" style="font-size: 16px"  >
      {{pedido.alteracaoEndereco.enderecoCompleto}}
    </div>

    <span class="text-success" *ngIf="pedido.alteracaoEndereco.aceito">
      <i class="k-icon k-i-check"></i><b>Alteração foi aceita</b>
    </span>

    <span class="text-danger" *ngIf="pedido.alteracaoEndereco.aceito === false">
       <b>Alteração negada</b>
    </span>

    <div class="botoes" [hidden]="pedido.alteracaoEndereco.aceito != null">

      <button  type="button" class="btn btn-success  mr-1 mt-1" (click)="respondeu(true)" [disabled]="processando">
         Aceitar Alteração
      </button>

      <button  type="button" class="btn btn-outline-secondary  mr-1 mt-1  " (click)="respondeu(false)" [disabled]="processando">
        Não Aceitar Alteração
      </button>

      <i class="k-i-loading k-icon mr-2" *ngIf="processando"></i>

    </div>
  </div>
</form>
