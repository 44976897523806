import {Component, OnInit, ViewChild} from '@angular/core';
import {EnumTipoDeCartao} from "../../../../../server/lib/emun/EnumTipoDeCartao";
import {TipoDePontuacaoPorValor} from "../../../../../server/domain/TipoDePontuacaoPorValor";
import {ActivatedRoute} from "@angular/router";
import {PlanosService} from "../../services/planos.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {TipoDePontuacao} from "../../../../../server/domain/TipoDePontuacao";
import { PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {ModalKendo} from "../../../lib/ModalKendo";

declare var _;
@Component({
  selector: 'app-cad-planos',
  templateUrl: './cad-planos.component.html',
  styleUrls: ['./cad-planos.component.scss']
})
export class CadPlanosComponent extends ModalKendo implements  OnInit {
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  idAleatorio = '1';
  mensagemSucesso = '';
  mensagemErro: any = '';

  tiposDeCartoes = [];
  salvando = false;
  plano: any = {};
  tiposDePontuacoes = [];
  empresa: any;
  data: any;
  regrasExtras = [];
  carregando: any;
  tituloDialogRegra: string;
  abraDialogo: boolean;
  regra: any;
  private editando: boolean;
  private indiceEdicao: any;
  private regraEditando: any;
  abraDialogoRemover: boolean;

  constructor(  private route: ActivatedRoute,
               private planosService: PlanosService) {
    super();
    this.plano.tipoDePontuacao = new TipoDePontuacaoPorValor();
  }

  ngOnInit() {
    this.tiposDeCartoes = [EnumTipoDeCartao.Pontos, EnumTipoDeCartao.Selos, EnumTipoDeCartao.Reais];

    this.tiposDePontuacoes =  []

    TipoDePontuacao.tipos.forEach( (tipo) => {
       this.tiposDePontuacoes.push(Object.assign({}, tipo))
    })

    if(this.plano.vencimento)
      this.plano.vencimento = new Date(this.plano.vencimento)

    if(this.plano.dataFimAcumulo)
      this.plano.dataFimAcumulo = new Date(this.plano.dataFimAcumulo)


    if( this.plano.tipoDePontuacao ) {
      let tipo = _.findWhere(this.tiposDePontuacoes, { tipo: this.plano.tipoDePontuacao.tipo})

      Object.assign( tipo, this.plano.tipoDePontuacao);
      this.plano.tipoDePontuacao = tipo;
    }
  }

  editePlano(plano) {
    this.plano = plano;

    if(this.plano.regrasExtras) {
      for(let regra in this.plano.regrasExtras) {
        this.regrasExtras.push(this.plano.regrasExtras[regra]);
      }
    }

  }
  onSubmit(frm) {
    if(this.salvando) return;

    if(frm.valid){
      this.plano.empresa = this.empresa;
      this.salvando = true;
      this.planosService.salvePlano(this.plano).then( ( ) => {
        if((this.regrasExtras && this.regrasExtras.length > 0) ||
          (this.regrasExtras.length === 0 && this.plano.regrasExtras && this.plano.regrasExtras.length > 0))
          this.planosService.salveRegrasExtras(this.plano.id, this.regrasExtras).then (( ) => {
            this.salvando = false;
            this.fecheModal()
          })
        else {
          this.salvando = false;
          this.fecheModal()
        }
      }).catch( (erro) => {
       this.mensagemErro = erro;
        this.salvando = false;
      });
    } else {
      this.mensagemErro = 'Verifique os campos obrigatórios'
    }
  }

  fecheMensagemErro() {
  }

  fecheMensagemSucesso() {
  }

  ehCashback() {
    return this.plano.tipoDeAcumulo === 'Reais'
  }

  carregue($event: PageChangeEvent) {

  }

  adicionarRegra() {
    this.regra = {};
    this.editando = false;
    this.abraDialogo = true;

    return false;
  }

  fecharDialogRegra() {
    this.abraDialogo = false;
    return false;

  }

  salvarRegraExtra() {
    if(this.editando) {
      this.regraEditando.descricao = this.regra.descricao;
      this.regrasExtras[this.indiceEdicao] = this.regra;
    }

    else
      this.regrasExtras.push(this.regra)  ;

    this.regraEditando = null;
    this.regra = null;
    this.editando = false;
    this.abraDialogo = false;
  }

  editarRegra({ sender, rowIndex, dataItem }) {
    this.regraEditando = dataItem;
    this.regra = {};
    Object.assign(this.regra, this.regraEditando);
    this.editando = true;
    this.indiceEdicao = rowIndex;
    this.abraDialogo = true;
  }

  removerRegra($event: RemoveEvent) {
    this.regra = $event.dataItem;
    this.abraDialogoRemover = true;
  }

  fecharDialogRemover() {
    this.abraDialogoRemover = false;
  }

  removerRegraExtra() {
    this.regrasExtras.splice( this.regrasExtras.indexOf(this.regra), 1 );
    this.regra = null;
    this.abraDialogoRemover = false;
  }
}
