import {Component, OnInit, ViewChild} from '@angular/core';
import {EnumFiltroDePedidos, PedidosService} from "../../../services/pedidos.service";
import {GridPedidosComponent} from "../../grid-pedidos/grid-pedidos.component";

@Component({
  selector: 'app-pedidos-mesas-com-erro',
  templateUrl: './pedidos-mesas-com-erro.component.html',
  styleUrls: ['./pedidos-mesas-com-erro.component.scss']
})
export class PedidosMesasComErroComponent implements OnInit {
  @ViewChild('gridPedidos', { static: true}) gridPedidos: GridPedidosComponent;
  pedidos = []
  carregando = false;
  constructor(private pedidosService: PedidosService) {

  }

  ngOnInit(): void {
    this.carregando = true;
    this.pedidosService.listePedidosMesaComErro().then( (resposta) => {
      this.pedidos = resposta.pedidos  || [];
      this.gridPedidos.atualizeGridPedidos(this.pedidos, 'Com Erro');
      this.carregando = false;
    }).catch( () => {
      this.carregando = false;
    });
  }

}
