<div style="padding-bottom: 0px;border-radius: 5px;" class="mb-2" [class.borda]="borda">
<div style="background: #eaebeb;">
  <div class="p-1">
    <strong>{{mensagem}}</strong>
  </div>
</div>

<div class="mt-1">
  <form [ngClass]="{'needs-validation': !validado, 'was-validated': validado}" #f="ngForm" novalidate>
    <div class="form-group" class="mb-1">
      <label for="contato2">{{rotulo}}</label>
      <div class="input-group">
        <div style="display: flex; width: 100%">
          <app-seletor-codigo-pais-server
            [selectedCountryCode]="contatoCodigoPais ? contatoCodigoPais : '+55' "
            (phoneMaskChange)="onPhoneMaskChange($event)"
            (selectedCountryCodeChange)="onCountrySelected($event)"></app-seletor-codigo-pais-server>
          <kendo-autocomplete #ipmWhatsapp name="contato2" id="contato2" #contato2="ngModel" (blur)="valide($event)"
                              [data]="results" [(ngModel)]="contatoNgModel"
                              [filterable]="true" [suggest]="true" [highlightFirst]="true" [popupSettings]="configuracoes"
                              (filterChange)="alterouCampoBusca($event)"
                              (valueChange) ="mudouValorCampoBusca($event)"

                              [placeholder]="'Nome ou telefone com DDD'" [valueField]="'telefoneMascarado'"  [loading]="buscandoOpcoes">
            <ng-template kendoAutoCompleteItemTemplate let-contato>
              <div class="row">
                <div class="col">
                  <div><b> {{contato.nome}} </b><br>
                    <span>{{contato.telefone | tel: contato.codigoPais}}</span> <br>
                    <span class="badge badge-pill badge-success" *ngIf="contato.ativo"> <i class="fe-user"></i>Ativo</span>
                    <span class="badge badge-pill badge-danger" *ngIf="contato.inativo"> <i class="fe-user"></i>Inativo</span>
                    <span class="badge badge-pill badge-warning" *ngIf="!contato.inativo && !contato.ativo"> <i class="fe-user"></i>A confirmar</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template kendoAutoCompleteNoDataTemplate>
              <h5 class="mt-4">Contato não encontrado. Deseja criar um novo contato<br />
                <button class="mt-1 btn btn-primary waves-effect waves-light" (click)="criarContatoIndicacao()">Criar Novo Contato</button>
              </h5>
            </ng-template>
          </kendo-autocomplete>

        </div>

        <span class="input-group-append">
              <button class="btn btn-secondary" tabindex="-1" type="button" style="border-top-right-radius: 3px;border-bottom-right-radius: 0px;width: 45px;height: 37px;">
                  <i class="fa fa-search" *ngIf="!carregandoContato"></i>
                <div class="k-i-loading ml-1 mr-1" style="font-size: 20px;" *ngIf="carregandoContato" ></div>
              </button>
        </span>

      </div>

      <div class="alert alert-danger mt-2" role="alert" *ngIf="contato2.invalid && validado">
        <i class="mdi mdi-block-helper mr-2"></i> Telefone é obrigatório
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
        <i class="mdi mdi-block-helper mr-2"></i> {{mensagemErro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </form>
</div>
</div>
