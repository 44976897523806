import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver, EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import {MudarStatusComponent} from "../../componentes/mudar-status/mudar-status.component";
import {PedidosService} from "../../services/pedidos.service";
import {IOperacoesModuloPedidos} from "../../superadmin/services/ioperacoesmodulopedidos";
import {ModalPedidoStatusComponent} from "../modal-pedido-status/modal-pedido-status.component";
import {Router} from "@angular/router";
import {ImpressaoService} from "../../services/impressao.service";
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";


@Component({
  selector: 'app-pedido-proximas-acoes',
  templateUrl: './pedido-proximas-acoes.component.html',
  styleUrls: ['./pedido-proximas-acoes.component.scss']
})
export class PedidoProximasAcoesComponent implements OnInit {
  @Input() pedido: any = {};
  @Input() empresa: any = {};
  @Input() modoGrupoLojas = false;
  @Input() comanda: any ;
  factory: ComponentFactory<MudarStatusComponent>;
  @Input() entregadores: any = [];
  @Output() onAtualizouComanda = new EventEmitter();
  constructor(private router: Router, private impressao: ImpressaoService,
              private dialogService: DialogService,
              private grupolojasService: GrupolojasService,  private autorizacaoService: AutorizacaoService,
              private pedidosService: PedidosService,
              private viewContainerRef: ViewContainerRef, private resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.factory = this.resolver.resolveComponentFactory(MudarStatusComponent);
  }

  mudarStatusPedidoSemTela($event: Event, pedido: any, novoStatus: string, selecionarEntregador: boolean = false) {
    const mudarStatusComponentRef = this.viewContainerRef.createComponent<MudarStatusComponent>(this.factory);

    const mudarStatusComponent: MudarStatusComponent = mudarStatusComponentRef.instance;
    mudarStatusComponent.visivel = false;

    if(selecionarEntregador && this.entregadores && this.entregadores.length > 0) {
      this.exibaModalAtualizarEntregador(pedido, novoStatus)
      this.empresa = pedido.empresa;
      return;
    }

    let statusInicial = this.pedido.status;
    this.getService().obtenhaPorCodigo(this.pedido.guid, this.pedido.empresa.id).then((pedidoObtido) => {

      mudarStatusComponent.pedido = pedidoObtido;
      mudarStatusComponent.contato = pedidoObtido.cliente;
      mudarStatusComponent.modoGrupoLojas = this.modoGrupoLojas;

      if( novoStatus === 'Finalizar' ) {
        mudarStatusComponent.atualizarFoiPago = true;
        mudarStatusComponent.pedidoAlterado.pago = true;
        this.pedido.pago = true;
      } else {
        mudarStatusComponent.pedidoAlterado.status = novoStatus;
        this.pedido.status = novoStatus;
      }


      mudarStatusComponent.atualizarStatus((foiAlterado, msgErro) => {
        if(!foiAlterado) {
          alert(msgErro);
          if(novoStatus === 'Finalizar') {
            this.pedido.pago = false;
          } else
            this.pedido.status = statusInicial;

          return;
        }

      });

      mudarStatusComponentRef.destroy();
      this.empresa = pedido.empresa;

    });

    $event.stopPropagation();
    $event.preventDefault();
  }

  exibaModalAtualizarEntregador(pedido: any, novoStatus: string) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalPedidoStatusComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    let telaModal: any =  windowRef.content.instance;

    telaModal.modoGrupoLojas = this.modoGrupoLojas
    telaModal.pedido = pedido
    telaModal.novoStatus = novoStatus
    telaModal.contato = pedido.contato;
    telaModal.exibirApenasAtualizarEntregador = true


    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          Object.assign(this.pedido, result)

        if(result && result.cancelado)
          this.onAtualizouComanda.emit( this.comanda);

      } );

  }

  getService(): IOperacoesModuloPedidos {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }

  integracaoDeliveryAtiva(){
    if(!this.empresa) return false;

    return (this.empresa.integracaoOpendeliveryLogistica && this.empresa.integracaoOpendeliveryLogistica.ativa ) ||
      (this.empresa.integracaoUberdirect && this.empresa.integracaoUberdirect.ativa )
  }

}
