import {Produto} from "./Produto";
import {Empresa} from "./Empresa";
import {Categoria} from "./delivery/Categoria";
import {EnumTipoDeVenda} from "../lib/emun/EnumTipoDeVenda";
import {ProdutoTamanho} from "./templates/ProdutoTamanho";
import {ImagemDoProduto} from "./ImagemDoProduto";
import {Catalogo} from "./catalogo/Catalogo";

export class ProdutoPizza extends Produto{

  constructor(public  id: number= null, public nome: string = null, public preco: number= null,
              public descricao: string = null, public mensagemPedido: string = null, public imagens: ImagemDoProduto[]= null ,
              public catalogo: Catalogo = null, public exibirNoSite: boolean = false,
              public disponibilidade: any = null,  public categoria: Categoria = null,
              public tamanhos: Array<ProdutoTamanho>  = [] , public template: any = null) {

      super(id, nome, preco, descricao, mensagemPedido, imagens, catalogo,  exibirNoSite,
          disponibilidade, null, categoria,   EnumTipoDeVenda.Unidade )

    this.tipo = 'pizza';

  }

  protected crieNovo(): Produto {
    let produto = new ProdutoPizza(null, this.nome, this.preco, this.descricao, this.mensagemPedido, [], this.catalogo,
      this.exibirNoSite, this.disponibilidade, this.categoria)

    produto.template = this.template;

    this.tamanhos.forEach((tamanho: ProdutoTamanho) => {
        produto.tamanhos.push(tamanho.clone())
    })


    return produto;
  }


  obtenhaPreco(tamanho: any, sabores: Array<any>){
    if(sabores && sabores.length)
      return  CalculadoraPrecoSabor.calculeValorSabores(this.template, sabores);

    if(tamanho){
      let tamanhoPizza = this.tamanhos.find(_tamanhoPizza => _tamanhoPizza.id === tamanho.id);

      return tamanhoPizza.novoPreco ? tamanhoPizza.novoPreco : tamanhoPizza.preco;
    } else {
      return null;
    }
  }

  obtenhaValorMinimo(): number {
    let valorMinimo =  0;

    this.tamanhos.forEach( (tamanho: ProdutoTamanho) => {
      if(tamanho.estaDisponivel()){
        (tamanho as any).valorMinimo  = tamanho.novoPreco ? tamanho.novoPreco : tamanho.preco;

        let valorMinimoProduto = super.obtenhaValorMinimo();

        if (valorMinimoProduto > 0)
          (tamanho as any).valorMinimo += valorMinimo;

        if(!valorMinimo || (tamanho as any).valorMinimo < valorMinimo )
          valorMinimo = (tamanho as any).valorMinimo
      }
    })

    return valorMinimo;

  }

  obtenhaValorMinimoAntigo(){
    let valorMinimoAntigo =  0 ;

    this.tamanhos.forEach( (tamanho: ProdutoTamanho) => {
      if(tamanho.estaDisponivel() &&  tamanho.novoPreco ){
        if(!valorMinimoAntigo ||  tamanho.preco < valorMinimoAntigo )
          valorMinimoAntigo =  tamanho.preco
      }
    })

    return valorMinimoAntigo;
  }

  temTamanho() {
    return true;
  }

  adicionaisObrigatoriosEstaoDisponiveis(){
    if(!this.tamanhos.length) return false;

    return true;
  };

  obtenhaNomeCompleto(){
    return String(`${this.template.identificador} ${this.nome}`);
  }

  temMassaSemBorda() {

   return this.camposAdicionais.find((campoAdicional: any) => {
        return  campoAdicional.nome === 'Massa' &&
                campoAdicional.opcoesDisponiveis.find((opcao: any) => opcao.template && opcao.template.semborda  ) != null
      }) != null

  }

}



class CalculadoraPrecoSabor {

  static calculeValorSabores( template: any, sabores: Array<any> ){
    let tipoDeCobranca: any = template.tipoDeCobranca,
      preco = 0;

    if(tipoDeCobranca === 'maior'){
      let maiorPreco = sabores[0].produto.obtenhaPreco(sabores[0].produtoTamanho)

      sabores.forEach( (saborPizza: any) => {
         let precoSabor = saborPizza.produto.obtenhaPreco(saborPizza.produtoTamanho);
         if(precoSabor > maiorPreco)
           maiorPreco = precoSabor
      } )

      preco =  maiorPreco

    } else  if(tipoDeCobranca === 'media'){

      let media = sabores.reduce( (sum: any, sabor: any) => sum + sabor.produto.obtenhaPreco(sabor.produtoTamanho), 0) / sabores.length;

      preco =  media
    } else {
      throw Error('Tipo de conbraça não suportado: ' + tipoDeCobranca)
    }

    if(template.taxaExtra > 0 && sabores.length  > 1)
      preco += template.taxaExtra

    return Number (preco.toFixed(2) );

  }

}
