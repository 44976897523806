import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {EmpresasService} from "../superadmin/services/empresas.service";

@Component({
  selector: 'app-tela-upload-favicon',
  templateUrl: './tela-upload-favicon.component.html',
  styleUrls: ['./tela-upload-favicon.component.scss']
})
export class TelaUploadFaviconComponent implements OnInit, AfterViewInit {
  @Input()
  empresa: any;

  mensagemSucesso = '';
  exibirSalvar = false;
  salvando = false;
  possuiModuloDominioProprio = false;

  constructor(private empresaService: EmpresasService) { }

  ngOnInit(): void {
  }

  trocouImagem(imagem: any) {
    this.exibirSalvar = true;
    this.empresa.favicon = imagem;
  }

  canceleImagem() {
    this.exibirSalvar = false;
  }

  onSubmitCardapio() {
    this.mensagemSucesso = '';
    this.salvando = true;

    this.empresaService.salveFavicon(this.empresa, this.empresa.favicon).then( (resp) => {
      this.salvando = false;
      this.mensagemSucesso = "Favicon salvo com sucesso!";
      this.exibirSalvar = false;
    }).catch( (erro) => {
      this.salvando = false;
      alert(erro)
    });
  }

  fecheMensagemSucesso() {

  }

  ngAfterViewInit(): void {
    this.possuiModuloDominioProprio = this.empresa.linkLoja.indexOf('meucardapio.ai') === -1;
  }
}
