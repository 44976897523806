import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class VendasPedidoService  extends ServerService {
  obtenhaResumo(filtro: any){
    return this.obtenha('/vendas/resumo', filtro);
  }

  obtenhaResumoComandas(filtro: any){
    return this.obtenha('/vendas/resumoComandas', filtro);
  }

  obtenhaResumoGarcons(filtro: any){
    return this.obtenha('/vendas/resumoComandas/garcons', filtro);
  }


  obtenhaResumoPrudutos(filtro: any){
    return this.obtenha('/vendas/resumo/produtos', filtro);
  }

  obtenhaResumoAdicionais(filtro: any){
    return this.obtenha('/vendas/resumo/adicionais', filtro);
  }


  obtenhaResumoCupons(filtro: any){
    return this.obtenha('/vendas/resumo/cupons', filtro);
  }

  obtenhaResumoAdicionaisPruduto(idProduto: any, filtro: any = {}) {
    filtro.pid = idProduto;
    return this.obtenha('/vendas/resumo/produtos/adicionais', filtro);
  }
}
