import {IUITipoDePontuacao} from "./IUITipoDePontuacao";

export class UITipoDePontuacaoPorPontos implements IUITipoDePontuacao {
  pontosPorValor: number;
  private empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any) {
    if (!pontuacaoRegistrada) return 0;
    return Math.floor(pontuacaoRegistrada.valor * this.pontosPorValor);
  }
}
