<div class="bg-light p-1 pl-2 headerCampo"><h4>{{campoAdicional.nome}}
  <div class="float-right">
    <span *ngIf=" campoAdicional.tipo == 'multipla-escolha'" class="badge badge-info mr-1"
          [class.badge-danger]="itemPedido['lista' + posicao].totalSelecionado < campoAdicional.qtdMinima"
          [class.badge-info]="itemPedido['lista' + posicao].totalSelecionado >= campoAdicional.qtdMinima &&
           itemPedido['lista' + posicao].totalSelecionado < campoAdicional.qtdMaxima"
          [class.badge-success]="itemPedido['lista' + posicao].totalSelecionado == campoAdicional.qtdMaxima">
      {{itemPedido['lista' + posicao].totalSelecionado}}/{{campoAdicional.qtdMaxima}}</span>
    <span *ngIf="campoAdicional.obrigatorio && campoAdicional.tipo == 'multipla-escolha'"
          class="badge"[class.badge-danger]="itemPedido['lista' + posicao].totalSelecionado < campoAdicional.qtdMinima "
          [class.badge-success]="itemPedido['lista' + posicao].totalSelecionado >= campoAdicional.qtdMinima">OBRIGATÓRIO</span>
    <span *ngIf="campoAdicional.obrigatorio && campoAdicional.tipo == 'escolha-simples'"
          class="badge"[class.badge-danger]="!itemPedido['campo' + posicao]"
          [class.badge-success]="itemPedido['campo' + posicao]">OBRIGATÓRIO</span>

  </div>
  </h4>
  <span *ngIf="campoAdicional.tipo == 'escolha-simples'"  class="text-muted">Escolha {{campoAdicional.obrigatorio ?'': 'até'}} 1 opção</span>
  <span *ngIf="campoAdicional.tipo == 'multipla-escolha'"  class="text-muted">Escolha entre {{campoAdicional.qtdMinima}} e {{campoAdicional.qtdMaxima}} opções</span>
</div>

<div *ngIf="campoAdicional.tipo == 'multipla-escolha' && !campoAdicional.podeRepetirItem" class="scroll">
  <div class="mt-2 ml-3">
    <div *ngFor="let opcao of campoAdicional.opcoesDisponiveis; let i = index; let last = last;">
      <div [class.mb-1]="!last" [class.mt-1]="!last" style="border-bottom: solid 1px #f7f7f7" *ngIf="itemPedido['lista' + posicao] && opcao.disponivel">
        <img  *ngIf="opcao.linkImagem" style="max-width: 60px; max-height: 60px" class="img img-pequena" [src]="'/images/empresa/' + opcao.linkImagem"/>
        <label [for]="'campo' + posicao + '_' + i" class="mb-1" >
          <input id="campo{{posicao}}_{{i}}" type="checkbox"  kendoCheckBox  [(ngModel)]="itemPedido['lista' + posicao]['opcao_' + i].selecionada" [value]="opcao" name="campo{{posicao}}_{{i}}"
                 (click)="vaiMudarSelecao($event, opcao, i)" (ngModelChange)="mudouSelecao($event, opcao)"
                 [disabled]="!itemPedido['lista' + posicao]['opcao_' + i].selecionada && itemPedido['lista' + posicao].totalSelecionado == campoAdicional.qtdMaxima"/>

          {{opcao.nome}} <span class="badge badge-primary ml-1"  style='margin-top: 1px;float:  right' *ngIf="opcao.valor">+{{opcao.valor | currency:'BRL'}}</span></label>
      </div>
    </div>
  </div>
</div>
<div *ngIf="campoAdicional.tipo == 'escolha-simples'" class="scroll">
  <div class="mt-2 ml-2  radio radio-blue " >
    <div *ngFor="let opcao of campoAdicional.opcoesDisponiveis; let i = index; let last = last" [class.mb-1]="!last" [class.mt-1]="!last" style="border-bottom: solid 1px #f7f7f7"
      [hidden]="!opcao.disponivel">
      <img  *ngIf="opcao.linkImagem" style="max-width: 60px; max-height: 60px" class="img img-pequena" [src]="'/images/empresa/' + opcao.linkImagem"/>
      <input id="campo{{campoAdicional.id}}_{{posicao}}_{{i}}" [(ngModel)]="itemPedido['campo' + posicao]"  name="campo{{posicao}}" type="radio" class="k-radio"
             [value]="opcao" kendoRadioButton
             [required]="true"/>

      <label for="campo{{campoAdicional.id}}_{{posicao}}_{{i}}" class="k-checkbox-label  mb-1" >
        &nbsp;{{opcao.nome}} <span class="badge badge-primary ml-1" style="margin-top: 1px" *ngIf="opcao.valor">&nbsp; +{{opcao.valor | currency:'BRL'}}</span></label>
      <br>
    </div>
  </div>
</div>
<div *ngIf="campoAdicional.tipo == 'multipla-escolha' && campoAdicional.podeRepetirItem && itemPedido['lista' + posicao]"
     class="scroll">
  <div class="mt-2 ml-2"  >
    <div *ngFor="let opcao of campoAdicional.opcoesDisponiveis; let i = index">
      <div class="mb-1"   style="display: flex;align-items: center;justify-content: space-between;border-bottom: solid 1px #f7f7f7" *ngIf="opcao.disponivel" class="row">
        <div class="col-auto" style="padding: 0"  *ngIf="opcao.linkImagem">
          <img  style="max-width: 60px; max-height: 60px" class="img img-pequena" [src]="'/images/empresa/' + opcao.linkImagem"/>
        </div>
        <div class="col" style="padding: 3px 0; margin: auto; overflow: hidden">
          <div [class.mb-1]="!opcao.descricao"   style="display: flex;align-items: center;justify-content: space-between;">
            <label class="k-checkbox-label mb-1">

              {{opcao.nome}} <span class="badge badge-primary ml-1"  style='margin-top: 1px;float: right' *ngIf="opcao.valor">+{{opcao.valor | currency:'BRL'}}</span>

            </label>
            <div>
              <div class="col flex-fixed-width-item pl-0 pr-0" style="margin-left: 10px; background: #fff; min-width: 90px;">
                <button type="button" class="btn btn-outline-light btn-xs" type="button"
                        style="color: #787878;" (click)="diminuirQtde(opcao, i)" [disabled]="itemPedido['lista' + posicao]['opcao_' + i].qtde == 0" ><i class="fas fa-minus"></i></button>
                <input name="campo{{campoAdicional.id}}{{i}}_qtd" [(ngModel)]="itemPedido['lista' + posicao]['opcao_' + i].qtde" [value]="itemPedido['lista' + posicao]['opcao_' + i].qtde" style="width: 30px;border: none;text-align: center;font-size: 16px;background: #fff;" disabled/>
                <button type="button" class="btn btn-outline-light btn-xs" type="button"
                        style="color: #787878;" (click)="aumentarQtde(opcao, i)" [disabled]="itemPedido['lista' + posicao].totalSelecionado == campoAdicional.qtdMaxima"><i class="fas fa-plus"></i></button>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>
