<div class="chat-window">
    <div class="chat-header">
        <span class="font-weight-bold">Prévia da mensagem</span>
    </div>

    <div class="chat-body">
        <div class="chat-text">
          <ng-container *ngIf="mensagem.mensagem">
            <div class="font-14 mb-2" *ngIf="mensagem.menu.tituloMensagem"><b>{{mensagem.menu.tituloMensagem}}</b></div>

            <span>
            {{mensagem.mensagem}}
            </span>

            <div class="chat-footer" *ngIf="mensagem.menu.textoBotao">
              <span (click)="openBottomSheet()">
                <i class="fas fa-list"></i>
                {{mensagem.menu.textoBotao}}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!mensagem.mensagem">
            <strong>Configure a mensagem no menu ao lado</strong>
          </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="mensagem.temMenu">
  <hr>
<div style="position: fixed;bottom: 70px;width: 285px;">
  <h5>Prévia do Menu no Whatsapp</h5>

  <div class="p-0" style="border: solid 1px #e8e8e8;border-radius: 5px;box-shadow: 0px 1px 3px #ccc;">
    <h5 class="ml-2" style="border-radius: 5px;">
      <i class="fas fa-times" style="cursor: pointer;"></i>
      {{mensagem.menu.textoSecao ? mensagem.menu.textoSecao : 'Título do menu'}}
    </h5>
    <mat-selection-list [multiple]="true">
      <mat-list-option [value]="'oi'" *ngFor="let opcao of mensagem.menu.opcoes">
        {{opcao.texto ? opcao.texto : 'Texto da opção'}}
      </mat-list-option>
    </mat-selection-list>
    <div class="p-1">
      <div class="btn btn-block btn-primary p-2">
        Enviar
      </div>
    </div>
  </div>
</div>
</ng-container>
