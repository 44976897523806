<div class="row" *ngIf="empresa && mesa">
  <div class="col">
    <div class="card mb-2" style="box-shadow: none;border: solid 1px #ececec;">
      <div class="card-body p-2">
        <span class="h4 text-black-50"><i class="fas fa-table"></i>
          {{empresa.identificadorMesa}}: </span> <span class="h4 text-left">{{mesa.nome}}

            <span *ngIf="codigoComanda"> -  Comanda <b>{{codigoComanda}}</b></span>
         </span>

        <span *ngIf="garcom && garcom.id" class="text-muted font-13 d-block">
          <i class="fas fa-user mr-1"></i>Garçom: <b>{{garcom.nome}}</b></span>
      </div>
    </div>
  </div>
</div>
