import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class InstagramService extends  ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  obtenhaTokenDePagina(conta: any, accessToken: string, tokenDePagina: string) {
    let dados = {
      at: accessToken,
      atp: tokenDePagina,
      igid: conta.igid,
      idp: conta.idPagina,
      nome: conta.nomeInstagram,
      imagem: conta.imageProfileUrl
    };

    return this.facaPost('/instagram/tokenDePagina', dados);
  }

  obtenhaContaConectada() {
    return this.obtenha('/instagram/contaConectada', {});
  }

  desconectarConta() {
    return this.facaPost('/instagram/desconectarConta', {});
  }

  estendaToken(accessToken: string) {
    let dados = {
      at: accessToken,
    };

    return this.facaPost('/instagram/estendaToken', dados);
  }
}
