import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplatesPromptsDbService } from "../../services/templates-prompts-db.service";
import { AutorizacaoService } from '../../services/autorizacao.service';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ListViewComponent } from '@progress/kendo-angular-listview';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {string} from "blockly/core/utils";
import {template} from "underscore";

@Component({
  selector: 'app-tela-templates-prompts-db',
  templateUrl: './tela-templates-prompts-db.component.html',
  styleUrls: ['./tela-templates-prompts-db.component.scss']
})
export class TelaTemplatesPromptsDbComponent implements OnInit, AfterViewInit {
  templatePrompt: any = {
    id: null,
    descricao: '',
    ativo: true,
    empresaId: null,
  };
  trechosDePrompt: any[];
  dialogOpened = false;
  novaInstrucao = {
    id: null,
    intent: '',
    condicao: '',
    texto: '',
    ativo: true,
    tipo: '',
    listaDeIntents: [],
    escopo: 'empresa',
    exemplos: [],
    textoHtml: ''
  };
  intencoes = ["SEMPRE", "CUMPRIMENTO", "AGRADECIMENTO", "ENVIAR_CARDAPIO", "FAZERPEDIDO",
    "INFORMACOES_PEDIDO", "PROMOCOES", "TAXASDEENTREGA", "PEDIRCITOUPRODUTO", "FORMAS_DE_PAGAMENTO", "FORMAS_DE_PAGAMENTO_ONLINE",
    "ATENDENTE", "HORARIO_ATENDIMENTO", "PEDIRPELOBOT", "PEDIR_AQUI_BOT", "PROBLEMAS", "ENTREGA", "ENDERECO", "FUNCOES_ROBO",
    "IMAGEM", "AUDIO", "VIDEO", "DOCUMENTO", "REPETIR_PEDIDO", "CONFIRMACAO", "NEGACAO",
    "PEDIR_USANDO_BOT", "INFORMACOES_CARDAPIO", "FORA_DO_ESCOPO", "PEDIDO_REALIZADO"];
  intencoesFiltradas = [];
  instrucaoGlobal = false;
  @ViewChild('lstExemplos', {static: false}) lstExemplos: any;

  templates: any[] = [];

  id: any;
  usuario: any;
  @ViewChild(ListViewComponent) listView: ListViewComponent;
  carregando = false;
  termoBusca = '';

  searchTerm: Subject<string> = new Subject<string>();
  listaFiltrada: any[] = [];
  isGlobal = false;

  @ViewChildren('txtPergunta2') textareas: QueryList<ElementRef>;
  @ViewChildren('txtResposta2') textareasResposta: QueryList<ElementRef>;

  constructor(private router: Router, private route: ActivatedRoute,
    private templatePromptsService: TemplatesPromptsDbService,
    private autorizacaoService: AutorizacaoService, private appRef: ApplicationRef) {
    this.intencoesFiltradas = this.intencoes.slice();

    this.route.url.subscribe(url => {
      // Verifica se a URL pertence ao superadmin
      this.isGlobal = url.map(segment => segment.path).join(',').includes('global');
    });

    //carrega o usuário logado
    this.usuario = this.autorizacaoService.getUser() || {};

    this.searchTerm
      .pipe(debounceTime(500)) // Defina o tempo de atraso em milissegundos (aqui definido como 500ms)
      .subscribe( (term: string) => {
        // Chamada da função de busca
        this.realizarBusca();
      });
  }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.carregando = true;

    this.templatePromptsService.carrgueTemplatePrompt(id, this.isGlobal).then(templatePrompt => {
      this.carregando = false;

      this.templatePrompt = templatePrompt;
      this.trechosDePrompt = templatePrompt.trechosDePrompt;

      //formata cada trecho de prompt e coloca em negrito as palavras chaves
      this.trechosDePrompt.forEach(trechoDePrompt => {

        //converte a intent em uma lista de intents
        trechoDePrompt.listaDeIntents = trechoDePrompt.intent.split(',');

        //gera o texto usando os exemplos pergunta e resposta
        if( trechoDePrompt.exemplos.length > 0 ) {
          trechoDePrompt.textoHtml = this.geraTexto(trechoDePrompt.exemplos);
        } else {
          trechoDePrompt.textoHtml = trechoDePrompt.texto;
        }

        trechoDePrompt.textoHtml = this.formateTemplate(trechoDePrompt.textoHtml);
        trechoDePrompt.textoHtml = this.boldWords(trechoDePrompt.textoHtml);
        trechoDePrompt.textoHtml2 = trechoDePrompt.textoHtml;
      });

      this.listaFiltrada = this.trechosDePrompt;
      this.templates = [this.templatePrompt];
    })
    .catch(error => {
      this.carregando = false;
    });
  }

  submitForm() {
    this.templatePromptsService.salveTemplatePrompt(this.templatePrompt).then(() => {
      if( this.isGlobal ) {
        this.router.navigate(['/superadmin/prompts-mia/global']);
      } else {
        this.router.navigate(['/admin/templates-prompts-db']);
      }
    });
  }

  cancelar() {
    this.voltar();
  }

  voltar() {
    this.router.navigate(['/admin/templates-prompts-db']);
  }

  editarTrechoDePrompt(trechoDePrompt: any) {
    //se for trecho global retorna a não ser que usuario seja admin
    if (trechoDePrompt.escopo === 'global' && !this.usuario.admin ) {
      return;
    }

    this.novaInstrucao = trechoDePrompt;
    this.dialogOpened = true;
  }

  crieNovaInstrucao() {
    // Crie um novo objeto vazio para representar a nova instrução
    const novaInstrucao: any = {};

    // Adicione a nova instrução ao array de instruções existente
    this.trechosDePrompt.push(novaInstrucao);

    // Defina a instrução recém-adicionada como ativa
    this.trechosDePrompt.forEach(instrucao => instrucao.ativo = false);
    novaInstrucao.ativo = true;
  }

  abrirDialogNovaInstrucao() {
    this.novaInstrucao = {
      id: null,
      intent: '',
      condicao: '',
      texto: '',
      listaDeIntents: [],
      tipo: '',
      exemplos: [{ pergunta: '', resposta: '' }],
      ativo: true,
      escopo: 'empresa',
      textoHtml: ''
    };

    this.dialogOpened = true;
    this.novaInstrucao.escopo = 'empresa';
  }

  salvarNovaInstrucao(form: any) {
    if (form.valid) {
      this.novaInstrucao.intent = this.novaInstrucao.listaDeIntents.join(',');

      this.templatePromptsService.salvarTrechoDePromptNoTemplate(this.templatePrompt.id, this.novaInstrucao, this.isGlobal).then((objServer) => {
        if( this.novaInstrucao.id == null) {
          this.novaInstrucao.id = objServer.id;

          // Adicione a nova instrução ao array de instruções existente
          this.trechosDePrompt.push(this.novaInstrucao);
        }

        if( this.novaInstrucao.tipo === 'exemplos' ) {
          //gera o texto usando os exemplos pergunta e resposta
          this.novaInstrucao.textoHtml = this.geraTexto(this.novaInstrucao.exemplos);

          this.novaInstrucao.textoHtml = this.formateTemplate(this.novaInstrucao.textoHtml);
          this.novaInstrucao.textoHtml = this.boldWords(this.novaInstrucao.textoHtml);
          this.novaInstrucao.texto = this.novaInstrucao.textoHtml;
        } else {
          this.novaInstrucao.textoHtml = this.formateTemplate(this.novaInstrucao.texto);
          this.novaInstrucao.textoHtml = this.boldWords(this.novaInstrucao.textoHtml);
          this.novaInstrucao.texto = this.novaInstrucao.textoHtml;
        }

        this.trechosDePrompt = this.trechosDePrompt.slice();
        this.realizarBusca();

        this.listView.data = this.listaFiltrada;

        // Feche a caixa de diálogo
        this.dialogOpened = false;

        // Limpe o objeto novaInstrucao
        this.novaInstrucao = {
          id: null,
          intent: '',
          condicao: '',
          tipo: '',
          exemplos: [],
          texto: '',
          listaDeIntents: [],
          ativo: true,
          escopo: 'empresa',
          textoHtml: ''
        };
      });
    }
  }

  handleFilter(value: any) {
    // Filtrar a lista de intenções com base no valor do filtro
    this.intencoesFiltradas = this.intencoes.filter(intencao => intencao.toLowerCase().includes(value.toLowerCase()));
  }

  ativeDesativeTrechoDePrompt(trechoDePrompt: any) {
    const id = trechoDePrompt.id;
    const ativo = trechoDePrompt.ativo;

    this.templatePromptsService.ativarDesativarTrechoDePrompt(this.templatePrompt.id, id,
      ativo).then(() => {
      trechoDePrompt.ativo = ativo;
    });
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.ativo) {
      return { ativo: true }; // Define a cor de fundo para linhas com ativo = false
    } else {
      return { inativo: true }; // Define a cor de fundo para outras linhas
    }
  }

  private formateTemplate(template: string) {
    const regex = /\${(.*?)}/g;
    let match;

    while ((match = regex.exec(template)) !== null) {
      template = template.replace(match[0], `<b>\$\{${match[1]}\}</b>`);
    }
    //substitui (empresa) por um ícone i fontawesome
    template = template.replace(/\(empresa\)/g, '<i class="fas fa-cog" aria-hidden="true"></i>');
    template = template.replace(/\(global\)/g, '<i class="fas fa-globe" aria-hidden="true"></i>');

    return template;
  }

  boldWords(text: string): string {
    text = text.replace(/\[(.*?)\]/g, '<strong>[$1]</strong>');
    text = text.replace(/\${(.*?)}/g, '<strong>${$1}</strong>');
    text = text.replace(/CUPOM::(.*?)/g, '<strong>CUPOM::$1</strong>');
    text = text.replace(/\n/g, '<br>');

    return text;
  }

  fecheJanelaEditarTrecho() {
    this.dialogOpened = false;
  }

  onKeyPress($event: any) {
    const term = $event.target.value;

    this.searchTerm.next(term);
  }

  realizarBusca() {
    this.listaFiltrada = this.trechosDePrompt.filter(item => {
      return item.texto.toLowerCase().includes(this.termoBusca.toLowerCase());
    });

    for (let i = 0; i < this.listaFiltrada.length; i++) {
      let trecho = this.listaFiltrada[i];
      trecho.textoHtml2 = trecho.textoHtml;
    }

    if( this.termoBusca.trim() !== '' ) {
      for (let i = 0; i < this.listaFiltrada.length; i++) {
        let trecho = this.listaFiltrada[i];

        this.destacarPalavra(trecho, this.termoBusca);
      }
    }

    this.listView.data = this.listaFiltrada;
  }

  destacarPalavra(trecho: any, palavra: string) {
    const novaPalavra = `<span class="highlight">${palavra}</span>`;
    const regex = new RegExp(palavra, 'gi');
    trecho.textoHtml2 = trecho.textoHtml.replace(regex, function(match) {
      return `<span class="highlight">${match}</span>`;  // 'match' é a correspondência exata encontrada
    });
  }

  adicionarExemplo() {
    this.novaInstrucao.exemplos = [...this.novaInstrucao.exemplos, { pergunta: '', resposta: '' }];
  }

  resize(txt: any) {
    txt.style.height = 'auto';
    txt.style.height = (txt.scrollHeight) + 'px';
  }

  removerPerguntaResposta(item: any) {
    this.novaInstrucao.exemplos = this.novaInstrucao.exemplos.filter(exemplo => exemplo !== item);
  }

  private geraTexto(exemplos: Array<any>) {
    let texto = '';

    exemplos.forEach((exemplo, index) => {
      if (index > 0) {
        texto += '\n';
      }

      texto += `Cliente: ${exemplo.pergunta} Mia: ${exemplo.resposta}`;
    });

    return texto;
  }

  ngAfterViewInit(): void {
    this.textareas.changes.subscribe((comps: QueryList<ElementRef>) => {
      comps.forEach(comp => {
        setTimeout( () => {
          this.resize(comp.nativeElement);
        }, 0);
      });
    });

    this.textareasResposta.changes.subscribe((comps: QueryList<ElementRef>) => {
      comps.forEach(comp => {
        setTimeout( () => {
          this.resize(comp.nativeElement);
        }, 0);
      });
    });
  }
}
