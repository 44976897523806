export class FormatadorPontos{
  static obtenhaPtsFormatado(tipoDePontuacao: any, tipoDeAcumulo: string, pontos: number){
    if(!pontos) return null;

    if(tipoDeAcumulo === 'Reais')
      return String(`R$ ${pontos.toFixed(2).replace('.', ',')}`)

    let acumulo = tipoDeAcumulo === 'Selos' ? 'selo' : 'ponto';

    if(pontos > 1) acumulo  = acumulo + 's';

    let pts  = pontos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return String(`${pts} ${acumulo}`)
  }
}
