import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EditEvent, GridDataResult, RemoveEvent} from "@progress/kendo-angular-grid";
import {PedidoLoja} from "../objetos/PedidoLoja";


@Component({
  selector: 'app-grid-produtos',
  templateUrl: './lista-de-pedidos.component.html',
  styleUrls: ['./lista-de-pedidos.component.scss']
})
export class ListaDePedidosComponent implements OnInit {
  @Input() tela: any;
  @Input() soExibir: any;
  @Output() onEditar: any  = new EventEmitter();
  public gridDataResult: GridDataResult;
  page = 0;
  tamanhoPagina = 10;
  loading = false;
  pedido: PedidoLoja ;
  alturaGrid = 350;
  desktop = false;

  constructor(){
    this.desktop = window.innerWidth >= 1024;

    this.alturaGrid = window.innerHeight - 540;

    if( !this.desktop ) {
      this.alturaGrid = window.innerHeight - 640;
    }

    if( this.alturaGrid < 200 ) {
      this.alturaGrid = 200;
    }
  }

  ngOnInit(){}

  exibaPedido(pedido: any) {
    this.pedido = pedido;
    this.configureAdicionais()
    this.refreshGrid();

  }

  atualize(indice: number, itemPedido: any){
    this.configureAdicionaisDoItem(this.pedido.atualize(indice, itemPedido))
    this.refreshGrid();
  }

  adicione(item: any) {
    let itemPedido = this.pedido.adicione(item.produto, item.qtde, item.observacao, item.adicionais , item.produtoTamanho, item.sabores);
    this.configureAdicionaisDoItem(itemPedido)
    this.refreshGrid();
  }

  private configureAdicionais() {
    for(let item of this.pedido.itens) {
      this.configureAdicionaisDoItem(item);
    }
  }

  private configureAdicionaisDoItem(item) {
    if (item.adicionais) {
      item.valoresAdicionais = []
      for (let nomeCampo in item.adicionais) {
        if (nomeCampo.startsWith('lista')) {
          for (let nomeOpcao in item.adicionais[nomeCampo]) {
            if (nomeOpcao.startsWith('opcao') && item.adicionais[nomeCampo][nomeOpcao].selecionada) {
              item.valoresAdicionais.push({
                nome: item.adicionais[nomeCampo][nomeOpcao].opcao.nome,
                qtde: item.adicionais[nomeCampo][nomeOpcao].qtde
              })
            }
          }
        }
        if (nomeCampo.startsWith('campo')) {
          item.valoresAdicionais.push({
            nome: item.adicionais[nomeCampo].nome,
            qtde: 1
          })
        }
      }
    }

  }

  refreshGrid() {
    this.gridDataResult = {
      data: this.pedido.itens,
      total: this.pedido.itens.length
    }

  }

  editarProdutoDaLista(event: EditEvent){
    this.onEditar.emit({ item: event.dataItem, indice: event.rowIndex})
  }

  removerProdutoDaLista($event: RemoveEvent) {
    this.pedido.removaItemNaPosicao($event.rowIndex)
    this.refreshGrid()
    this.tela.salvePedido();
  }
}
