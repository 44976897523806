<div class="" style="max-width: 900px; position:  relative"  >
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >

    <div class="bloqueio" *ngIf="integracao && !integracao.ativa"></div>
    <h4 class="mt-2"><b>Minhas chaves</b></h4>

    <div class="row">
      <div class="col ">
        <div class="form-group mt-2">
          <label>Url Webhook (Tracking Event Webhook)</label>

          <input kendoTextBox   name="urlTracking"    class="form-control"
                 [value]="obtenhaBaseUrl()" readonly/>

          <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(obtenhaBaseUrl())"
             [ngClass]="{'text-success': textoCopiado === obtenhaBaseUrl()}"
             title="copiar url webhook de eventos rastreamento pedido" kendoTooltip></i>
        </div>
      </div>
      <div class="col">
        <div class="form-group mt-2">
          <label>Merchant Id</label>
          <input kendoTextBox name="merchantId" class="form-control" required placeholder="merchantId da Loja"
                 [(ngModel)]="integracao.merchantId" [readonly]="integracaoOpendelivery?.id"/>

          <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(integracao.merchantId)"
             [ngClass]="{'text-success': textoCopiado && textoCopiado === integracao.merchantId}"
             title="copiar merchart id" kendoTooltip></i>
        </div>
      </div>
    </div>

    <h4>Credenciais do Serviço Logistica   </h4>

    <div class="row">
      <div class="col">
        <div class="form-group mt-2">
          <label>URL Base Autenticação ( Para obter auth/token )</label>
          <input kendoTextBox name="logisticAuthUrl" placeholder="Ex.: https://api.logistaca.com.br"
                 class="form-control" #logisticAuthUrl="ngModel"
                 [(ngModel)]="integracao.authUrl" required/>

          <div class="invalid-feedback" *ngIf="!integracao.authUrl">
            <p  >Campo é obrigatório</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group mt-2">
          <label>URL Base ( Para logistics/delivery  etc )</label>
          <input kendoTextBox name="logisticBaseUrl" placeholder="Ex.: https://api.logistaca.com.br/v1"
                 class="form-control" #logisticBaseUrl="ngModel"
                 [(ngModel)]="integracao.baseUrl" required/>

          <div class="invalid-feedback"  *ngIf="!integracao.baseUrl">
            <p  >Campo é obrigatório</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group ">
          <label>Client Id</label>
          <input kendoTextBox name="logisticClientId" class="form-control" placeholder="clientId para gerar o token"
                 [(ngModel)]="integracao.clientId"  required/>
          <div class="invalid-feedback"  *ngIf="!integracao.clientId">
            <p  >Campo é obrigatório</p>
          </div>

        </div>
      </div>
      <div class="col">
        <div class="form-group ">
          <label>Client Secret</label>
          <input kendoTextBox name="logisticClientSecret" class="form-control" placeholder="clientSecredt para gerar o token"
                 [(ngModel)]="integracao.clientSecret"  required/>
          <div class="invalid-feedback"  *ngIf="!integracao.clientSecret">
            <p  >Campo é obrigatório</p>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group ">
          <label>AppId</label>
          <input kendoTextBox name="appid" class="form-control" placeholder="App id único"
                 [(ngModel)]="integracao.appid" required />
          <div class="invalid-feedback"  *ngIf="!integracao.appid">
            <p  >Campo é obrigatório</p>
          </div>
        </div>
      </div>

    </div>

    <h4>Configurações </h4>
    <fieldset>
      <legend>Tempos limites</legend>
      <div class="row">
        <div class="form-group col col-sm-4">
          <label>Tempo limite retirada (minutos)</label>
          <kendo-numerictextbox class="form-control ml-2 mr-2" autocomplete="off"  [min]="0" #tempoLimiteRetirada="ngModel"
                                name="tempoLimiteRetirada" [format]="'0 min'" required
                                [(ngModel)]="integracao.tempoLimiteRetirada" placeholder="tempo retirada (max)">     </kendo-numerictextbox>
          <div class="invalid-feedback"  *ngIf="!integracao.tempoLimiteRetirada">
            Campo obrigatório
          </div>
        </div>
        <div class="form-group col  col-sm-4">
          <label>Tempo limite entrega (minutos)</label>
          <kendo-numerictextbox class="form-control ml-2 mr-2" autocomplete="off"  required [min]="0" #tempoLimiteEntrega="ngModel"
                                name="tempoLimiteEntrega" [format]="'0 min'"
                                [(ngModel)]="integracao.tempoLimiteEntrega" placeholder="tempo entrega (max)">     </kendo-numerictextbox>
          <div class="invalid-feedback"  *ngIf="!integracao.tempoLimiteEntrega">
            Campo obrigatório
          </div>
        </div>

      </div>

    </fieldset>

    <fieldset>
      <legend>Veículo</legend>
      <div class="row">
        <div class="form-group col col-sm-6">
          <label>Tipos de veículo</label>

          <kendo-multiselect  name="novaFormaPagamento" [(ngModel)]="integracao.veiculoPadrao.type"
                              [data]="veiculosTipos"
                              placeholder="Informe os veículos" class="form-control"
                              required >
          </kendo-multiselect>

          <div class="invalid-feedback"  *ngIf="!integracao.veiculoPadrao.type">
            Campo  é obrigatório
          </div>

        </div>


        <div class="form-group col col-sm-3">
          <label>Container</label>
          <kendo-combobox   name="bandeira"
                            [(ngModel)]="integracao.veiculoPadrao.container" [data]="conteiners" textField="nome"
                            placeholder="Selecione um container" class="form-control"
                            required   >
          </kendo-combobox>

          <div class="invalid-feedback"  *ngIf="!integracao.veiculosTipos?.container">
            Campo é obrigatório
          </div>
        </div>
        <div class="form-group col col-sm-3">
          <label>Container Tamanho</label>
          <kendo-combobox   name="containerSize"   [(ngModel)]="integracao.veiculoPadrao.containerSize"
                            [data]="conteinersTamanhos" textField="nome"
                            placeholder="Selecione um tamanho do container" class="form-control"
                            required   >
          </kendo-combobox>

          <div class="invalid-feedback" *ngIf="!integracao.veiculoPadrao?.containerSize">
            Campo é obrigatório
          </div>

        </div>

      </div>

    </fieldset>

    <fieldset>
      <legend>Outros</legend>
      <div class="row" *ngIf="false">
        <div class="col">
          <div class="input-group mt-1 mb-2">
            <input  name="notificarRetirada" id='notificarRetirada'    class="k-checkbox  " type="checkbox"
                    [(ngModel)]="integracao.notificarRetirada"/>
            <label  class="k-checkbox-label" for="notificarRetirada">Notificar parceiro quando o <b>pedido for retirado</b></label>


          </div>
        </div>
        <div class="col">
          <div class="input-group mt-1 mb-2">
            <input  name="notificarConclusao" id='notificarConclusao'    class="k-checkbox  " type="checkbox"
                    [(ngModel)]="integracao.notificarConclusao"/>
            <label  class="k-checkbox-label" for="notificarConclusao">Notificar parceiro quando a <b>entrega for concluída</b> </label>


          </div>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <div class="input-group mt-1 mb-2">
            <input  name="retornarNaLoja" id='retornarNaLoja'    class="k-checkbox  " type="checkbox"
                    [(ngModel)]="integracao.automatico"/>
            <label  class="k-checkbox-label" for="retornarNaLoja">
              Solicitar entregador automaticamente quando pedido entrar <b>"Em Preparação"</b> </label>


          </div>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <div class="input-group mt-1 mb-2">
            <input  name="naoEnviarLocalizacao" id='naoEnviarLocalizacao'    class="k-checkbox  " type="checkbox"
                    [(ngModel)]="integracao.naoEnviarLocalizacao"/>
            <label  class="k-checkbox-label" for="naoEnviarLocalizacao">
              Não enviar localização do endereço de entrega (latitude e longitude)</label>


          </div>
        </div>
      </div>

     <!-- <div class="row">
        <div class="col">
          <div class="input-group mt-1 mb-2">
            <input  name="retornarNaLoja" id='retornarNaLoja'    class="k-checkbox  " type="checkbox"
                    [(ngModel)]="integracao.retornarNaLoja"/>
            <label  class="k-checkbox-label" for="retornarNaLoja"><b>Entregador retornar a loja</b> quando pagamento for na entrega</label>


          </div>
        </div>
      </div> -->
    </fieldset>

    <div class="row mt-2">
      <div class="col">

        <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2 mb-2" role="alert">
          {{mensagemErro}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <button class="btn btn-warning waves-effect  " type="button"   [disabled]="processando"
                (click)="ativeIntegracao()"   *ngIf="this.integracao?.id && !this.integracao.ativa">
          Ativar <i class="k-i-loading k-icon" *ngIf="processando" ></i>
        </button>

        <ng-container *ngIf="!this.integracao?.id || this.integracao.ativa">
          <button class="btn btn-success waves-effect" type="submit"   [disabled]="salvando || processando  "  >
            Salvar <i class="k-i-loading k-icon" *ngIf="salvando" ></i>
          </button>

          <button class="btn btn-danger waves-effect ml-2" type="button"   [disabled]="salvando || processando" *ngIf="integracao.id"
                  (click)="desativeIntegracao()" >
            Desativar <i class="k-i-loading k-icon" *ngIf="processando" ></i>
          </button>
        </ng-container>

      </div>
    </div>
  </form>
</div>



