import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RdcrmService extends ServerService {

  constructor(public http: HttpClient) {
    super(http);
  }

  obtenhaNegociacoes(pagina: number) {
    return this.obtenha('/rdcrm/negociacoes', {pagina: pagina});
  }

  obtenhaEtapas() {
    return this.obtenha('/rdcrm/etapas', {});
  }

  atualizeLead(lead: any, novaEtapa) {
    const contato = lead.contacts[0];

    debugger;

    return this.facaPut('/rdcrm/lead', {
      lead: lead.id,
      novaEtapa: novaEtapa,
      contato: contato.name,
      fones: contato.phones
    });
  }
}
