<i class="fas fa-exchange-alt" *ngIf="acao.tipo == 3  "></i>
<i class="fas fa-minus" *ngIf="acao.tipo == 8  "></i>
<i class="fas fa-plus" *ngIf="acao.tipo == 2"></i>
<i class="fas fa-id-card" *ngIf="acao.tipo == 0 "></i>
<i class="fas fa-upload" *ngIf="acao.tipo == 5 || acao.tipo == 6"></i>
<i class="fe-shopping-cart fa-lg" *ngIf="acao.tipo == 9"></i>
<i class="fe-info fa-lg" *ngIf="acao.tipo == 10"></i>

<span *ngIf="exibirDescricao">
  {{acao.descricao}}
</span>

