import { Component, OnInit, ViewChild } from '@angular/core';
import { ChamadasIaService } from '../../services/chamadas-ia.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DatePickerComponent, TimePickerComponent } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'app-tela-chamadas-ia',
  templateUrl: './tela-chamadas-ia.component.html',
  styleUrls: ['./tela-chamadas-ia.component.scss']
})
export class TelaChamadasIaComponent implements OnInit {
  chamadasIa = [];
  dataCriacao = '';
  mensagem = '';
  horarioInicio = '';
  horarioFim = '';
  buscando = false;
  @ViewChild('dtDataCriacao') dtDataCriacao: DatePickerComponent;
  @ViewChild('tpHorarioInicio') tpHorarioInicio: DatePickerComponent;
  @ViewChild('tpHorarioFim') tpHorarioFim: DatePickerComponent;

  constructor(private chamadasIaService: ChamadasIaService, private domSanitizer: DomSanitizer, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.carregarChamadasIa();
  }

  //carrega as chamadas ia do server
  carregarChamadasIa() {
    this.buscando = true;
    let paramDataCriacao = '';
    if( this.dataCriacao ) {
      paramDataCriacao = this.datePipe.transform(this.dataCriacao, 'dd/MM/yyyy');
    }
    let horarioInicioFormatado = '';
    if( this.horarioInicio ) horarioInicioFormatado = this.datePipe.transform(this.horarioInicio, 'HH:mm');
    let horarioFimFormatado = '';
    if( this.horarioFim ) horarioFimFormatado = this.datePipe.transform(this.horarioFim, 'HH:mm');


    this.chamadasIaService.obtenhaChamadasIa(this.mensagem, paramDataCriacao, horarioInicioFormatado, horarioFimFormatado).then( (resposta) => {
      this.chamadasIa = resposta;
      this.buscando = false;
      //processa as chamadas ia fazendo replace de -- por <br>
      this.chamadasIa.forEach( (chamadaIa) => {
        chamadaIa.prompt = chamadaIa.prompt.replace('-------', '');
        chamadaIa.prompt = chamadaIa.prompt.replace('-------', '');
        chamadaIa.prompt = chamadaIa.prompt.replace('-------', '');
        chamadaIa.prompt = chamadaIa.prompt.replace(/--/g, '<br>--');
        chamadaIa.prompt = chamadaIa.prompt.replace(/- /g, '<br>- ');
        chamadaIa.prompt = this.formatString(chamadaIa.prompt);
        //usa o dom sanitizer para permitir o html
        chamadaIa.prompt = this.domSanitizer.bypassSecurityTrustHtml(chamadaIa.prompt);

        //replace all \n por <br>
        chamadaIa.resposta = chamadaIa.resposta.replace(/\n/g, '<br>');
        chamadaIa.resposta = this.domSanitizer.bypassSecurityTrustHtml(chamadaIa.resposta);
      });
    }).catch( (erro) => {
      this.buscando = false;
    });
  }

  voltar() {

  }

  formatString(input: string) {
    let formattedString = input;

    formattedString = formattedString.replace(/--system:(.*?)(?=--system:|--assistant:|--user:|$)/gs, '<div class="system">--system: $1</div>');
    formattedString = formattedString.replace(/--assistant:(.*?)(?=--system:|--assistant:|--user:|$)/gs, '<div class="assistant">$1</div>');
    formattedString = formattedString.replace(/--user:(.*?)(?=--system:|--assistant:|--user:|$)/gs, '<div class="user">$1</div>');

    return formattedString;
  }

  buscarMensagens() {
    this.carregarChamadasIa();
  }
}
