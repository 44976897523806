import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ContatosService} from "../services/contatos.service";
import {CurrencyPipe} from "@angular/common";
import {
  Border, ChartComponent,
  SeriesClickEvent,
  SeriesHoverEvent,
  SeriesLabels,
  SeriesTooltip,
  SeriesTooltipComponent
} from "@progress/kendo-angular-charts";
import {DeviceDetectorService} from "ngx-device-detector";
import {RelatoriosComponent} from "../relatorios/relatorios.component";
import {DashType} from "@progress/kendo-drawing";
import {NgForm} from "@angular/forms";
import {ColorUtils} from "../ColorUtils";

@Component({
  selector: 'app-tela-grafico-fidelidade',
  templateUrl: './tela-grafico-fidelidade.component.html',
  styleUrls: ['./tela-grafico-fidelidade.component.scss']
})
export class TelaGraficoFidelidadeComponent implements OnInit {
  public data: any[] = [];
  relatorio: any;
  public mapData: any = {};
  @Input() campo = 'totalGasto';
  @Input() dtInicio = '2019-11-01';
  @Input() dtFim = '2019-12-01';

  @Input() relatorios: any;

  public objeto: any;
  @Input() conteudo;
  ehCelular: boolean;
  altura = "550";
  borda: Border = {
    color: "rgba(255, 0, 0, 0.8)",
    dashType: 'dash',
    width: 5
  };

  @ViewChild('chartComponent')  chartComponent: ChartComponent;

  constructor(private contatosService: ContatosService, private cp: CurrencyPipe, private deviceService: DeviceDetectorService) {
    this.objeto = this;
    this.conteudo = this.labelContent;

    this.ehCelular = this.deviceService.isMobile();

    if( this.ehCelular ) {
      this.altura = '550';
    }
  }

  ngOnInit() {
  }

  public labelContent(e: any): string {
    let percentual = '';

    if( e.percentage ) {
      percentual = '\n (' + (100.0 * e.percentage).toFixed(2) + '%)'
    }

    return e.dataItem.rotulo + percentual;
  }

  public labelContentLabels(e: any): string {
    let percentual = '';

    if( e.percentage ) {
      percentual = ' (' + (100.0 * e.percentage).toFixed(2) + '%)'
    }

    return e.dataItem.rotulo + percentual;
  }

  obtenhaRegistro(value) {
    const registro = this.mapData[value];

    if( !registro ) {
      return {};
    }

    return registro;
  }

  obtenhaQtdeVisitas(value) {
    const registro = this.mapData[value];

    if( !registro ) {
      return '';
    }

    if( registro.mediaVisitas === 1 ) {
      return '1 visita';
    }

    return parseFloat(registro.mediaVisitas).toFixed(2).replace('.', ',') + ' visitas';
  }

  obtenhaTempoRetornar(value) {
    const registro = this.mapData[value];

    if( !registro ) {
      return '';
    }

    return parseFloat(registro.tempoRetorno).toFixed(1).replace('.', ',');
  }

  obtenhaTotalGasto(value) {
    const registro = this.mapData[value];

    if( !registro || !registro.totalGasto) {
      return 0;
    }

    return parseFloat(registro.totalGasto);
  }

  public atualizeRelatorio(inicioDoMes: string, fimDoMes: string, relatorio: any) {
    this.dtInicio = inicioDoMes;
    this.dtFim = fimDoMes;

    this.data = relatorio.registros;
    this.relatorio = relatorio.dados;
    this.mapData = relatorio.mapData;
  }

  public modificou() {
    this.chartComponent['options'].transitions = false;
    this.chartComponent['refresh']();
  }

  onSeriesClick($event: SeriesClickEvent) {
    const estavaSelecionado = $event.dataItem.selecionado;
    for( let registro of this.data ) {
      registro.selecionado = false;
    }

    $event.dataItem.selecionado = !estavaSelecionado;

    this.relatorios.selecionouCategoria($event.dataItem.rotulo, $event.dataItem);

    $event.sender['options'].transitions = false;
    $event.sender['refresh']();
  }

  public obtenhaCor(registro: any) {
    return ColorUtils.obtenhaCorStatic(registro);
  }
}
