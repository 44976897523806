import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class CardapioService extends ServerService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  atualizeModoVendaCardapio(cardapio: any) {
    return this.facaPost('/cardapio/atualize', cardapio);
  }
}
