<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}" #frm="ngForm" novalidate (ngSubmit)="criarCartao()">

  <div class="row">
    <div class="col-lg-8 col-9">
      <div class="form-group ">
        <label for="numero">Número  do Cartão </label>

        <input type="text" class="form-control" autocomplete="off"  [mask]="'0000-0000-0000-0000'" style="font-family: monospace;"
                             id="numero" name="numero" [(ngModel)]="cartao.numero" #numero="ngModel"
                             required appAutoFocus [autoFocus]="true"  (ngModelChange)="alterouNumeroCartao()" >


        <i class="fa fa-credit-card fa-2x" [ngClass]="{ 'text-muted': !cartao.bandeira,  ' text-success': cartao.bandeira && cartao.numeroValido}" > </i>
        <div class="invalid-feedback">
          <p *ngIf="numero.errors?.required">Obrigatório</p>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-3">
      <div class="form-group ">
        <label for="cvv">CVV</label>
        <input type="text" class="form-control" autocomplete="off" [mask]="'000'"
                             id="cvv" name="cvv" [(ngModel)]="cartao.cvv" #cvv="ngModel"
               required    >
        <div class="invalid-feedback">
          <p *ngIf="cvv.errors?.required">Obrigatório</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-7 col-lg-8">
      <label for="nome">Nome impresso</label>
      <input type="text" class="form-control" autocomplete="off" required
             id="nome" name="nome" [(ngModel)]="cartao.nome" #nome="ngModel"
             placeholder="Nome impresso" value="" >
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Obrigatório</p>
      </div>
    </div>
    <div class="col-5 col-lg-4 ">
      <label for="validade">Data validade</label>
      <kendo-dateinput name="validade" id="validade" format="MM/yyyy" class="form-control"  required #validade="ngModel"  [(ngModel)]="cartao.validade">

      </kendo-dateinput>
      <div class="invalid-feedback">
        <p *ngIf="validade.errors?.required">Obrigatório</p>
      </div>
    </div>

    <div *ngIf="parcelamento && parcelamento.parcelas.length" class="col-12 mt-2 form-group">
      <label for="parcelamento">Número de parcelas</label>

      <kendo-combobox id="parcelamento"  name="parcelamento" [(ngModel)]="cartao.parcelamento" [data]="parcelamento.parcelas " required
                 textField = 'descricao'  #parcelamentoModel="ngModel"
                       placeholder="Selecione a quantidade de parcelas" class="form-control" >
      </kendo-combobox>

      <div class="invalid-feedback">
        <p *ngIf="parcelamentoModel.errors?.required">Obrigatório</p>
      </div>

    </div>

  </div>

  <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible fade show mt-2" role="alert">
    <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemSucesso()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
    <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
    <p *ngIf="errosIugu">
      {{errosIugu | json}}
    </p>
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemErro()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <button type="submit" class="btn btn-info waves-effect waves-light mt-2" [disabled]="processando" [hidden]="esconderPagar">
    <i class="fa fa-credit-card fa-lg mr-2"></i>
    Pagar no Cartão

    <i class="k-icon k-i-loading" *ngIf="processando"></i>

  </button>

</form>
