<div class="paper">

  <div class="titulo">
    <img  height="60" src="/assets/fidelidade/promokit-preto.png">
    <h3 class="empresa mt-3"><b>{{comprovante.empresa.nome}}</b></h3>
    <h4>{{comprovante.plano}} comprovante de troca</h4>
    <p>{{comprovante.horario}}</p>
  </div>

  <div class="dados ">

    <div class="row">
        <div class="col-6">
          <label>Contato</label>
          <p>{{comprovante.contato.nome}}   </p>
        </div>
        <div class="col-6">
          <label>&nbsp; </label>
          <p>{{comprovante.contato.telefone  | tel}}</p>
        </div>
    </div>


    <div class="row">
       <div class="col-8">
         <label>Brinde</label>
         <p>{{comprovante.descricaoBrinde}}</p>
       </div>
      <div class="col-4">
        <label>Valor </label>
        <p>{{comprovante.valorBrinde}}</p>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-12">
        <label class="text-center" style="display: block" >Extrato de pontos  no momento da troca</label>

        <div class="row">
          <div class="col-8 font-italic">Total antes da troca</div>
          <div class="col-4 float-right">{{comprovante.totalAntes}}</div>
        </div>

        <div class="row">
          <div class="col-8 font-italic">Valor do brinde</div>
          <div class="col-4 float-right">{{comprovante.valorBrinde}}</div>
        </div>

        <div class="row">
          <div class="col-8 font-italic">Saldo restante</div>
          <div class="col-4 float-right"><b>{{comprovante.saldoRestante}}</b></div>
        </div>

      </div>
    </div>

  </div>
  <div class="assinatura">

     <div class="campo">
       <label class="text-center">Assinatura</label>
     </div>

     <span class="text-muted   ">Operador: <b>{{comprovante.operador.nome}}</b> em {{comprovante.horario}}</span>

    <Br>
     <span class="text-muted   ">Horário da impressão: {{comprovante.agora}}</span>
  </div>
</div>

