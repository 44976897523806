import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pedido-origem',
  templateUrl: './pedido-origem.component.html',
  styleUrls: ['./pedido-origem.component.scss']
})
export class PedidoOrigemComponent implements OnInit {
  @Input() pedido: any  = {}
  constructor() { }

  ngOnInit(): void {
  }

}
