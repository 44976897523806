import {Component, OnInit, ViewChild} from '@angular/core';
import {ImportadorChinnaService} from "../../services/importador-chinna.service";
import {PainelImportacaoProdutosComponent} from "../painel-importacao-produtos/painel-importacao-produtos.component";
import {NgForm} from "@angular/forms";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-tela-sincronizar-china',
  templateUrl: './tela-sincronizar-china.component.html',
  styleUrls: ['./tela-sincronizar-china.component.scss']
})
export class TelaSincronizarChinaComponent extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: false}) frm: NgForm;
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  mensagemSucesso: any;
  mensagemErro: any;
  carregandoProdutos: boolean;
  tudoSincronizado: boolean;
  buscarProdutos: boolean;
  buscouProdutos: boolean;
  opcoes: any = {};
  empresa: any = {};
  constructor(  private importadorChinnaService: ImportadorChinnaService) {
    super()
  }

  ngOnInit(): void {
    this.buscarProdutos = true
  }

  ehSiteChina(){
    return this.empresa.integracaoDelivery && this.empresa.integracaoDelivery.unidadeChina;
  }
  ehSiteGendai(){
    return this.empresa.integracaoDelivery && this.empresa.integracaoDelivery.unidadeGendai;
  }

  async carregueProdutosNovos(){
    this.fecheMensagemErro();
    this.carregandoProdutos = true;

    let resposta: any;

    resposta = await this.importadorChinnaService.listeNovosProdutos(this.opcoes.sincronizarPrecos,
                                                        this.opcoes.sincronizarImagens).catch( erro => {
      this.mensagemErro = erro
    })

    this.carregandoProdutos = false;
    this.buscouProdutos = true;

    if(resposta){

      if(resposta.categorias.length){
        this.buscarProdutos = false;
      } else {
        this.tudoSincronizado =  true;
      }

      this.painelImportacao.setProdutosSincronizar(resposta.novosProdutos,
        resposta.produtosAtualizar, resposta.produtosRemovidos, resposta.categorias)
    }

  }

  submitFormBusca() {
    this.frm.ngSubmit.emit()
  }

  fecheMensagemErro() {
    delete  this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  feche() {
    this.fecheModal(this.painelImportacao.importou)
  }

}
