import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ConstantsService} from "../ConstantsService";
import {EmpresasService} from "../../superadmin/services/empresas.service";

@Component({
  selector: 'app-ativar-pagbank-connect',
  templateUrl: './ativar-pagbank-connect.component.html',
  styleUrls: ['./ativar-pagbank-connect.component.scss']
})
export class AtivarPagbankConnectComponent implements OnInit {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  account_id: string;
  ativando = false;
  erroInstalacao: string;
  dadosAtivacao: any;
  empresa: any;
  constructor(   private  activatedRoute: ActivatedRoute, private constantsService: ConstantsService,
                 private empresasService: EmpresasService) { }

  ngOnInit(): void {
    //authorization_code
    this.access_token = this.activatedRoute.snapshot.queryParams.access_token;
    this.refresh_token = this.activatedRoute.snapshot.queryParams.refresh_token;
    this.expires_in = this.activatedRoute.snapshot.queryParams.expires_in;
    this.account_id = this.activatedRoute.snapshot.queryParams.account_id;

    if(this.access_token)
      this.ativeNovaInstalacao();
    else
      this.erroInstalacao = this.activatedRoute.snapshot.queryParams.erro || 'Nenhum token de acesso retornado na query string';

    this.constantsService.empresa$.subscribe((empresa: any) => {
      if(empresa)
        this.empresa = empresa;
    })
  }

  ativeNovaInstalacao(){
    this.ativando = true;
    this.empresasService.autorizeNovaLojaPagbank(this.access_token, this.refresh_token, this.expires_in, this.account_id).
    then( (dadosAtivacao: any) => {
      this.dadosAtivacao = dadosAtivacao;
      this.empresa.integracaoGatewayPagamento = dadosAtivacao;
      this.constantsService.mudouEmpresa(this.empresa);
      this.ativando = false;
    }).catch((erro) => {
      this.erroInstalacao = erro;
      this.ativando = false;
    })
  }

}
