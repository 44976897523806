import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {ContatosService} from "../services/contatos.service";
import {EnumTipoDeCartao} from "../../../server/lib/emun/EnumTipoDeCartao";
import {ActivatedRoute, Router} from "@angular/router";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {Location} from '@angular/common';
import {SelosComponent} from "../componentes/selo/selo.component";
import {AutorizacaoService} from "../services/autorizacao.service";
import {AcoesModulosComponent} from "../componentes/acoes-modulos/acoes-modulos.component";
import {NovoContatoComponent} from "../novo-contato/novo-contato.component";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../lib/KendoPopupUtils";

@Component({
  selector: 'app-contato-detalhes',
  templateUrl: './ver-contato.component.html',
  styleUrls: ['./ver-contato.component.scss']
})
export class VerContatoComponent implements OnInit {
  acoes: any = [];
  cartoes: any = [];
  filtro: any = {cartao: null};
  contato: any = {};
  @ViewChild('selosCartao', { static: true } ) selosCartao: SelosComponent;
  @ViewChild('acoesModulo', { static: true } ) acoesModulo: AcoesModulosComponent;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  abraDialogo: boolean;
  telefone: any;
  cpf: any;
  id: any;
  nomeContato: any;
  widget = false;
  usuario: any;
  empresa: any;
  novaTag: any;
  tagSelecionada: any;
  tags = []
  respServerAlterouAtivarDesativar;
  buscandoTag = false;
  salvandoTag = false;
  constructor(   private  contatosService: ContatosService, private router: Router,
               private dialogService: DialogService,
               private activatedRoute: ActivatedRoute, private _location: Location,
               private autorizacaoService: AutorizacaoService, private empresasService: EmpresasService) {

  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngOnInit() {
    let usuarioLogado = this.autorizacaoService.getUser();

    if( usuarioLogado != null )
      this.usuario = usuarioLogado;
    else {
      this.usuario = {};
    }

    this.empresa = this.usuario.empresa;

    this.buscandoTag = true;
    this.empresasService.listeTags().then((tags) => {
      this.tags = tags;
      this.buscandoTag = false;
    })


    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.nomeContato = this.activatedRoute.snapshot.queryParams.nome;

      this.widget = this.inIframe();

      const { contato } = window.history.state;

      if (contato) {
        this.setContato(contato)
      }
      else {
        this.telefone =  this.activatedRoute.snapshot.params['zap'];
        this.cpf =  this.activatedRoute.snapshot.params['cpf'];
        this.id =  this.activatedRoute.snapshot.params['id'];
        this.carregueContato();
      }
    });
  }

  carregueContato(){
    if(this.id){
      this.contatosService.obtenhaContatoPorId(this.id).then( (contatoObtido: any) => {
        this.setContato(contatoObtido);
      });
    } else if(this.telefone){
      this.contatosService.obtenhaContato(this.telefone.toString()).then( (contatoObtido: any) => {
        this.setContato(contatoObtido);
      });
    } else if(this.cpf){
      this.contatosService.obtenhaContatoPorCpf(this.cpf.toString()).then( (contatoObtido: any) => {
        this.setContato(contatoObtido);
      });
    }
  }

  editarContato(contato: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: NovoContatoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.exibaContato(contato);

    windowRef.result.subscribe((result: any) => {
      if(result && result.id) {
        contato = Object.assign(contato, result);
      }
    } );

  }

  setContato(contato: any) {
    this.contato = contato || {};

    if( !contato.id )     return;


    this.cartoes = contato.cartoes;
    this.cartoes.forEach( cartao => cartao.descricao = cartao.plano.nome)
    if(this.cartoes.length)
      this.filtro.cartao = this.cartoes[0];

   if(this.porSelos())
     this.selosCartao.setCartao(this.filtro.cartao)

    if(!this.contato.ultimasAcoes){
      this.carregueUltimasAcoes();
    } else {
      this.acoes = this.contato.ultimasAcoes
    }

    this.acoesModulo.contato = contato;
  }

  carregueUltimasAcoes(){
    this.contatosService.obtenhaUltimasAcoes(this.contato).then( (acoes) => {
      this.contato.ultimasAcoes = acoes;
      this.acoes = this.contato.ultimasAcoes;
    })
  }

  adcionarPontos(contato: any) {
    this.router.navigateByUrl('/admin/contatos/pontos/adicionar' ,
      { state: { contato: contato, cartao: this.filtro.cartao } }).then( () => { });
  }


  trocarPontos(contato: any) {
    this.router.navigateByUrl('/admin/contatos/pontos/trocar' ,
      { state: { contato: contato, cartao: this.filtro.cartao } }).then( () => { });
  }

  alterouCartao(cartao) {
    if(cartao && cartao.plano.tipoDeAcumulo === EnumTipoDeCartao.Selos)
      this.selosCartao.setCartao(cartao)
  }

  porSelos() {
    return this.filtro.cartao && this.filtro.cartao.plano.tipoDeAcumulo === EnumTipoDeCartao.Selos;
  }

  porReais() {
    return this.filtro.cartao && this.filtro.cartao.plano.tipoDeAcumulo === EnumTipoDeCartao.Reais;
  }

  porPontos() {
    return this.filtro.cartao && this.filtro.cartao.plano.tipoDeAcumulo === EnumTipoDeCartao.Pontos;
  }

  voltar() {
    this._location.back();
  }

  verCartao(contato: any) {
    this.router.navigateByUrl('/cliente/' + contato.id + "/" + this.filtro.cartao.id).then( () => { });
  }

  remover(contato: any) {
    this.abraDialogo = true;
  }

  apagueiContato() {
    this.router.navigateByUrl('/admin/contatos').then( () => { });
  }

  abraTelaNovoContato() {
    this.router.navigateByUrl('/admin/contatos/novo?nome=' + this.nomeContato + '&tel=' + this.telefone)
      .then( () => { });
  }

  eventoSalvouContato(contato: any) {
    this.contato = contato;
  }

  alterouDesativarEnvioMsgMarketing(ativado: any) {
    this.respServerAlterouAtivarDesativar = null;

    this.contatosService.ativarEnvioDeMensagemDeMkt(this.contato, ativado).then( (mensagem) => {
      this.respServerAlterouAtivarDesativar = {
        sucesso: true,
        mensagem: mensagem
      };
    }).catch( (erro) => {
      this.respServerAlterouAtivarDesativar = {
        sucesso: false,
        mensagem: erro
      };
    });
  }

  alterouTag(tag: string){
    console.log(this.tagSelecionada)
    delete this.novaTag;

    if(tag && tag.trim() === '') return;


    this.tagSelecionada = this.tags.find((item: any) => item.nome.toUpperCase() === tag.toUpperCase());

    if(! this.tagSelecionada)
      this.novaTag = { nome: tag}
  }

  async salveTag(){
    if(!this.novaTag || !this.novaTag.nome){
       delete this.novaTag;
       return;
    }

    this.salvandoTag = true;
    this.empresasService.salveTag(this.empresa,  this.novaTag).then((tag) => {
       delete this.novaTag;
       this.tags.push(tag)
       this.adicioneTagContato(this.contato, tag);
    }).catch((erro) => {
      this.salvandoTag = false;
       alert('Erro salvar nova tag: ' + erro)
    })
  }

  async adicioneTagSelecionada(){

    await this.adicioneTagContato(this.contato, this.tagSelecionada)
  }

  async adicioneTagContato(contato: any, tag: any){
    this.salvandoTag = true;
    this.contatosService.adicioneTag(contato, tag).then((resp) => {
      this.salvandoTag = false;
      this.contato.tags.push(tag)
    }).catch((erro) => {
      this.salvandoTag = false;
      alert('Erro adicionar tag: ' + erro)
    })
  }
}
