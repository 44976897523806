import {TamanhoPapel} from "./TamanhoPapel";

export class LayoutRelatorioCaixa {
  public tamanhoDescricaoCampo: number
  public tamanhoValorCampo: number

  constructor(public tamanhoPapel: TamanhoPapel) {
    //o valor deve ter um tamanho suficiente para caber um valor monetário como R$ 1.000,00
    this.tamanhoValorCampo = 13
    this.tamanhoDescricaoCampo = this.tamanhoPapel.tamanhoLinha - this.tamanhoValorCampo
  }



  static obtenhaLayout(tamanhoPapel: TamanhoPapel) {
    switch (tamanhoPapel) {
      case TamanhoPapel.Papel58mm:
        return new LayoutRelatorioCaixa(
          TamanhoPapel.Papel58mm
        )
      case TamanhoPapel.Papel58mm32car:
        return new LayoutRelatorioCaixa(
          TamanhoPapel.Papel58mm32car
        )
      case TamanhoPapel.Papel80mm:
        return new LayoutRelatorioCaixa(
          TamanhoPapel.Papel80mm
        )
      case TamanhoPapel.Papel80mm42car:
        return new LayoutRelatorioCaixa(
          TamanhoPapel.Papel80mm42car
        )
      default:
        return new LayoutRelatorioCaixa(
          TamanhoPapel.Papel80mm
        )
    }
  }

  setTamanhoValorCampo(tamanho: number) {
    this.tamanhoValorCampo = tamanho
    this.tamanhoDescricaoCampo = this.tamanhoPapel.tamanhoLinha - this.tamanhoValorCampo
  }

  setTamanhoDescricaoCampo(tamanho: number) {
    this.tamanhoDescricaoCampo = tamanho
    this.tamanhoValorCampo = this.tamanhoPapel.tamanhoLinha - this.tamanhoDescricaoCampo
  }
}
