import {Cartao} from './Cartao';
import {Atividade} from './Atividade';
import * as uuid from "uuid";

const uuidv1 = uuid.v1;

export class PontuacaoRegistrada {
  id: number;
  cartao: Cartao;
  valor = 0;
  atividades: Array<Atividade> = [];
  horario: Date;
  pontos = 0;
  pontosUsados = 0;
  pontosVencidos = 0;
  dataVencimento: Date;
  pontosInformado = 0;
  operador: any;
  codigo: string;
  referenciaExterna: string;
  empresa: any
  pedido: any;
  comanda: any;
  removida: boolean
  constructor() {
    this.codigo = uuidv1();
    this.horario  = new Date();
  }

  getNomesAtividades() {
     return  this.atividades.map(  atividadde => atividadde.nome).join(', ')
  }

  obtenhaQtdePontosVenceu() {
    return this.pontos  - this.pontosUsados;
  }

  obtenhaPontosDisponiveis(){
    //garantir que nao usa pontuaçao vencida
    if(this.estaVencida()) return 0;

    return this.obtenhaPontosUsar();

  }

  obtenhaPontosUsar(){
    return this.pontos - this.pontosUsados - this.pontosVencidos;
  }

  estaVencida(){ // nao usei moment referencia no client
    if(!this.dataVencimento) return false;

    let agora = new Date();
    let vencimento = new Date(this.dataVencimento)

    if(agora.getFullYear() > vencimento.getFullYear()) return true;
    if(agora.getFullYear() < vencimento.getFullYear()) return false;

    if(agora.getMonth() > vencimento.getMonth()) return true;
    if(agora.getMonth() < vencimento.getMonth()) return false;

    if(agora.getDate() > vencimento.getDate()) return true;

    return false;
  }

  otenhaTipoDeAcumulo(){
    return this.cartao.plano.tipoDeAcumulo
  }
}
