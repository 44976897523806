<div class="page-title-right">
  <ol class="breadcrumb m-0">
    <li class="breadcrumb-item active">Fidelidade</li>
  </ol>
</div>
<span>
  <h4 class="page-title">      <i class="fas fa-paint-roller"></i> Temas</h4>

  <p class="h4 text-muted">Ative um dos temas para chamar atenção dos seus clientes e aumentar suas vendas.</p>

  <div class="row">
    <div class="col-12 mb-3">
      <button class="btn btn-lg btn-success" (click)="criarTemaPersonalizado()">
        <i class="fas fa-plus-circle mr-2"></i>Novo Tema Personalizado
      </button>
    </div>

    <ng-container *ngFor="let tema of temasPersonalizados">
      <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <div class="d-flex flex-column">
            <div class="cor-tema" [style.background-color]="tema.corFundo" style="flex: 1; width: 100%; min-height: 40px;"></div>
            <div class="cor-tema" [style.background-color]="tema.corTexto" style="flex: 1; width: 100%; min-height: 40px;"></div>
            <div class="cor-tema" [style.background-color]="tema.corBotao" style="flex: 1; width: 100%; min-height: 40px;"></div>
            <div class="cor-tema" [style.background-color]="tema.corTextoBotao" style="flex: 1; width: 100%; min-height: 40px;"></div>
            <div class="cor-tema" [style.background-color]="tema.corTextoSecundaria" style="flex: 1; width: 100%; min-height: 40px;"></div>
          </div>
          <i class="fas fa-check" *ngIf="empresa.temaPersonalizado && empresa.temaPersonalizado.id === tema.id"></i>
        </div>
        <div class="card-footer">
          <h4 class="card-title mb-0">{{tema.nome}}</h4>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.temaPersonalizado?.id !== tema.id" (click)="ativarTemaPersonalizado(tema)" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
          *ngIf="empresa.temaPersonalizado && empresa.temaPersonalizado.id === tema.id"
                disabled="true">Tema Ativo</button>
        <button class="btn btn-lg btn-outline-primary ml-2" (click)="editarTema(tema)">
          <i class="fas fa-edit"></i>
        </button>
      </div>
    </ng-container>

    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img-top" src="/assets/loja/tema_meucardapioclassico.png" alt="Card image cap">
          <i class="fas fa-check" *ngIf="!empresa.tema"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema" (click)="desativarTema()" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary" *ngIf="!empresa.tema" disabled="true">Tema Ativo</button>
    </div>


      <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema-carnaval-2024.jpeg" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'copa_2022'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'carnaval'" (click)="ativarTema('carnaval')"
                [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'carnaval'"
                disabled="true">Tema Ativo</button>
    </div>

    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_copa_2022.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'copa_2022'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'copa_2022'" (click)="ativarTema('copa_2022')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'copa_2022'"
                disabled="true">Tema Ativo</button>
    </div>

    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_black_friday.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'black_friday'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'black_friday'" (click)="ativarTema('black_friday')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'black_friday'"
                disabled="true">Tema Ativo</button>
    </div>

    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_black_friday2022.jpg" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'black_friday_2022'"></i>
          <div class="ribbon ribbon-top-right"><span>Novo</span></div>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'black_friday_2022'" (click)="ativarTema('black_friday_2022')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'black_friday_2022'"
                disabled="true">Tema Ativo</button>
    </div>
    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_dia_maes.png?v=2" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'dia_maes'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'dia_maes'" (click)="ativarTema('dia_maes')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'dia_maes'"
                disabled="true">Tema Ativo</button>
    </div>
    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_dia_dos_pais.png?v=2" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'dia_pais'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'dia_pais'" (click)="ativarTema('dia_pais')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'dia_pais'"
                disabled="true">Tema Ativo</button>
    </div>
    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_black.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'black'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'black'" (click)="ativarTema('black')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'black'"
                disabled="true">Tema Ativo</button>
    </div>
    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_pascoa.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'pascoa'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'pascoa'" (click)="ativarTema('pascoa')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'pascoa'"
                disabled="true">Tema Ativo</button>
    </div>
    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_natal.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'natal'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'natal'" (click)="ativarTema('natal')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'natal'"
                disabled="true">Tema Ativo</button>
    </div>
    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_ano_novo.png?v=2" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'ano_novo'"></i>
          <div class="ribbon ribbon-top-right"><span>Atualizado</span></div>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'ano_novo'" (click)="ativarTema('ano_novo')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'ano_novo'"
                disabled="true">Tema Ativo</button>
    </div>


    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_arraia.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'arraia'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'arraia'" (click)="ativarTema('arraia')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'arraia'"
                disabled="true">Tema Ativo</button>
    </div>

    <div class="col-3 text-center mb-3">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_dia_namorados.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'dia_namorados'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'dia_namorados'" (click)="ativarTema('dia_namorados')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'dia_namorados'"
                disabled="true">Tema Ativo</button>
    </div>

    <div class="col-3 text-center mb-3" *ngIf="false">
        <div class="card mb-3">
          <img class="card-img" src="/assets/loja/tema_quiosque.png" alt="Card image cap">
          <i class="fas fa-check branco" *ngIf="empresa.tema === 'quiosque'"></i>
        </div>
        <button class="btn btn-lg btn-primary" *ngIf="empresa.tema !== 'quiosque'" (click)="ativarTema('quiosque')" [disabled]="ativando">Ativar Tema</button>
        <button class="btn btn-lg btn-secondary disabled"
                *ngIf="empresa.tema === 'quiosque'"
                disabled="true">Tema Ativo</button>
    </div>
  </div>
</span>
