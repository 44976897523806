<div class="paper">

  <div class="titulo">
    <img class="imagem_empresa" *ngIf="comprovante.empresa?.logo" src="https://promokit.com.br/images/empresa/{{comprovante.empresa.logo}}" style="    width: 65px;"/>
    <img  height="60" *ngIf="!comprovante.empresa?.logo" src="/assets/fidelidade/promokit-preto.png">
    <h3 class="empresa mt-3"><b>{{comprovante.empresa?.nome}}</b></h3>
    <h4>{{comprovante.plano}} comprovante pontuação</h4>
    <p>{{comprovante.horario}}</p>
  </div>

  <div class="dados ">

    <div class="row">
      <div class="col-6">
        <label>Contato</label>
        <p>{{comprovante.contato?.nome}}   </p>
      </div>
      <div class="col-6">
        <label>&nbsp; </label>
        <p>{{comprovante.contato?.telefone  | tel}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <label>Pontuação ganha</label>
        <p>{{comprovante.pontos}}   </p>
      </div>
      <div class="col-4">
        <label>Valor R$</label>
        <p>{{comprovante.valor }}</p>
      </div>
    </div>

    <div class="row" *ngIf="comprovante.referenciaExterna">
      <div class="col-12">
        <label>Referência externa</label>
        <p>{{comprovante.referenciaExterna}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p>
          <label class="mr-1">Saldo total:</label>  <b>{{comprovante.saldo}}</b>
        </p>

      </div>
    </div>
  </div>

  <div class="assinatura">

    <div class="campo">
      <label class="text-center">Assinatura</label>
    </div>

    <span class="text-muted   ">Operador:<b>{{comprovante.operador?.nome}}</b> em {{comprovante.horario}}</span>

    <Br>
    <span class="text-muted   ">Horário da impressão: {{comprovante.agora}}</span>
  </div>
</div>
