import { Component, OnInit } from '@angular/core';
import {ModalKendo} from "../../../lib/ModalKendo";

@Component({
  selector: 'app-payload-json-view',
  templateUrl: './payload-json-view.component.html',
  styleUrls: ['./payload-json-view.component.scss']
})
export class PayloadJsonViewComponent extends ModalKendo implements OnInit {
  payload: any;
  mensagemCopiada: string;
  titulo = 'JSON Pedido'
  constructor(  ) {
    super()
  }

  ngOnInit(): void {
    if(typeof this.payload === 'string')
      this.payload = JSON.parse(this.payload)
  }

  copieJson(){
    let val = JSON.stringify(this.payload);

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.mensagemCopiada = val;

    setTimeout( () => {
      delete this.mensagemCopiada
    }, 5000);
  }
}
