import { Injectable } from '@angular/core';
import {Plano} from "../../../../server/domain/Plano";
import {HttpClient} from "@angular/common/http";
import {ServerService} from "../../services/ServerService";

@Injectable({
  providedIn: 'root'
})
export class PlanosService  extends  ServerService{
  constructor(http: HttpClient) {
    super(http)
  }

  obtenhaPlanos(idEmpresa: string, page: number) {
    return this.http.get('/planos/' + idEmpresa , {params: {p: page + ''}})
      .toPromise();
  }


  salvePlano(plano: Plano) {
    if( !plano.id ) {
       return super.salve('/planos/insira', plano)
    } else {
      return super.salve('/planos/atualize', plano)
    }
  }

  salveRegrasExtras(idPlano: number, regrasExtras: any[]) {
    return super.salve('/planos/regrasExtras', {id: idPlano, regrasExtras: regrasExtras})
  }

  removaPlano(plano: any): Promise<any> {
    return this.remova( "/planos/" + plano.id, {})
  }

  desativePlano(plano: any): Promise<any> {
    return this.facaPut( String(`/planos/${ plano.id}/desative`), plano)
  }

  ativePlano(plano: any): Promise<any> {
    return this.facaPut( String(`/planos/${ plano.id}/ative`), plano)
  }

}
