import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {Atividade} from "../../../server/domain/Atividade";
import {FormaDePagamento} from "../pedidos/objetos/FormaDePagamento";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FormasDePagamentoService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  salveFormaDePagamento(formaDePagamento: FormaDePagamento) {
    return this.salve('/formasDePagamento', formaDePagamento );
  }

  removaFormaDePagamento(forma: FormaDePagamento) {
    return this.remova( "/formasDePagamento/" + forma.id, {})
  }

  obtenhaFormasDePagamentos(empresa: any) {
    return this.obtenha('/formasDePagamento/' + empresa.id, {});
  }

  listeFormasIntegradas(sistema: string){
    return this.obtenha(String(`/formasDePagamento/${sistema}/formas`), {});
  }

  listeTodasFormasIntegradas(){
    return this.obtenha(String(`/formasDePagamento/pdv/formaspagamento/integradas`), {});
  }

  listeBandeiras(){
    return this.obtenha(String(`/formasDePagamento/pdv/bandeiras`), {});
  }

  listeFormasPagamentosPdv(){
    return this.obtenha(String(`/formasDePagamento/pdv/formaspagamento`), {});
  }

  listeFormasPagamentosLoja(){
    return this.obtenha(String(`/formasDePagamento/pdv/formaspagamento/loja`), {});
  }

  listeMetodosPagamentos(){
    return this.obtenha(String(`/formasDePagamento/pdv/metodospagamentos`), {});
  }

  salveBandeira(bandeira: any){
    return this.facaPost(String(`/formasDePagamento/pdv/bandeiras`), bandeira);
  }

  salveFormaPagamento(formapagamento: any){
    return this.facaPost(String(`/formasDePagamento/pdv/formaspagamento`), formapagamento);
  }

  ativeBandeira(formapagamento: any, bandeira: any){
    return this.facaPost(String(`/formasDePagamento/pdv/bandeiras/ative`), {formaId: formapagamento.id, bandeiraId: bandeira.id});
  }

  desativeBandeira(formapagamento: any, bandeira: any){
    return this.facaPost(String(`/formasDePagamento/pdv/bandeiras/desative`), {formaId: formapagamento.id, bandeiraId: bandeira.id});
  }

  ativeFormaPagamentoNaEmpresa(formapagamento: any){
    return this.facaPost(String(`/formasDePagamento/pdv/formaspagamento/me/ative`), formapagamento);
  }

  salveChavePix(formapagamento: any){
    return this.facaPost(String(`/formasDePagamento/pdv/formaspagamento/me/salveChavePix`), formapagamento);
  }

  desativeFormaPagamentoNaEmpresa(formapagamento: any){
    return this.facaPost(String(`/formasDePagamento/pdv/formaspagamento/me/desative`), formapagamento);
  }

  obtenhaLinkAutorizacaoPagBank(){
    return this.obtenha('/pagseguro/me/connect/autorizacao/url', {})
  }

  migreParaBandeiras() {
    return this.facaPost(String(`/formasDePagamento/pdv/formaspagamento/me/migre`), {});
  }
}
