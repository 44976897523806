import {Component, OnInit, ViewChild} from '@angular/core';
import {BannerService} from "../../services/banner.service";
import {NgForm} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-novo-banner',
  templateUrl: './novo-banner.component.html',
  styleUrls: ['./novo-banner.component.scss']
})
export class NovoBannerComponent implements OnInit {
  banner: any = {};
  enviando = false;
  empresa: any;
  mensagemErro: string;
  @ViewChild('frm',  {static: false} ) frm: NgForm;

  constructor(private bannerService: BannerService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if( params.id ) {
        this.bannerService.obtenhaBanner(params.id).then( (banner) => {
          this.banner = banner;
          if(this.banner.validade) this.banner.validade = new Date(this.banner.validade)

        });
      }
    });
  }

  cancelar() {
    this.router.navigateByUrl('/admin/banner').then( () => {
    });
  }

  onSalvar() {
    delete this.mensagemErro;
    if(!this.frm.valid){
      return;
    }

    this.enviando = true;
    this.bannerService.salveBanner(this.banner).then( (resposta) => {
      this.enviando = false;

      this.router.navigateByUrl('/admin/banner').then( () => {
      });
    }).catch( erro => {
      this.mensagemErro = erro;
      this.enviando = false;
    });
  }

  salveFotoBanner($event: any) {
    $event.salvou();
  }

  testarBusca() {
    window.open('/loja/busca?q=' + this.banner.extra);
  }

  testarEndereco() {
    window.open(this.banner.extra);
  }
}
