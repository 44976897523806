<div class="container-corte">
  <div [style.width]="larguraMinima() + 'px'"  >
    <div class="form-group mb-12">
      <label for="foto">{{label}}     <i class="fa fa-edit fa-lg"  *ngIf="getLinkImagem()" (click)="editarImagem=!editarImagem" ></i>
      </label>
      <div *ngIf="getLinkImagem() && !editarImagem">
        <img id="foto" src="/images/empresa/{{getLinkImagem()}}"  [style.width]="config.minWidth + 'px'" >
      </div>

      <div *ngIf="!getLinkImagem() || editarImagem ">

        <div class="area-corte"  [style.width]="larguraMinima() + 'px'"  [style.height]=" alturaMinima()  + 'px'"
             [ngClass]="{'largo': larguraMinima() > 300, 'medio': larguraMinima() >= 200}" *ngIf="!imageChangedEvent">

          <div class="k-dropzone"  >
            <i class="fa fa-image  " ></i>
            <div class="k-button k-upload-button" aria-label="Selecione..." role='button'>
              <input  type="file" (change)="fileChangeEvent($event)"  name="file" />
              <span>Selecione uma foto</span>
            </div>
          </div>
        </div>

        <p class="text-danger" *ngIf="erroLoadImage">
          <b>{{erroLoadImage}}</b>
        </p>

        <div *ngIf="imageChangedEvent " [hidden]="salvar">
          <image-cropper #imageCropper
                         [imageFile]="imageCropData"
                         [imageChangedEvent]="imageChangedEvent"
                         [maintainAspectRatio]="true"
                         [aspectRatio]="config.ratio"
                         [resizeToWidth]="config.resizeWidth"
                         [cropperMinWidth]="config.minWidth"
                         [onlyScaleDown]="true"
                         [roundCropper]="false"
                         format="png"
                         outputType="base64"
                         (imageCropped)="imageCropped($event)"
                         (imageLoaded)="imageLoaded()"
                         (cropperReady)="cropperReady()"
                         (loadImageFailed)="loadImageFailed()"
                         [style.display]="showCropper ? null : 'none'"
                         [alignImage]="'left'"
          ></image-cropper>

          <div class="  mt-2 botoes" >
            <button class="btn btn-success  mr-1" (click)="corteImagem()" [disabled]="cortando" > Cortar</button>
            <button class="btn btn-secondary" (click)="canceleImagem()" [disabled]="cortando"> Cancelar</button>
          </div>

          <p *ngIf="erro" class="text-danger mt-2"><b>{{erro}}</b></p>

        </div>

      </div>
    </div>

    <div *ngIf="salvar" class="mt-2">
      <p>Imagem está correta, podemos salvar? </p>
      <div class="botoes">
        <button class="btn btn-primary mr-1" (click)="salve()" [disabled]="salvando"> <i class="fa fa-save fa-lg fa-fw"></i> Salvar</button>
        <button class="btn btn-outline-secondary" (click)="canceleSalvar()"> <i class="fa fa-times fa-lg fa-fw"></i> Cancelar</button>
      </div>

      <p *ngIf="erro" class="text-danger mt-2"><b>{{erro}}</b></p>

    </div>

  </div>
</div>









