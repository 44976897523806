export class DTOEmpresa {
  id: number;
  nome: string;
  dominio: string;
  rede: string;
  idRede: number;
  ecommerce: boolean
  constructor(empresa: any) {
    this.id = empresa.id;
    this.nome = empresa.nome;
    this.dominio = empresa.dominio;
    this.rede = empresa.rede;
    this.idRede = empresa.idRede;
    this.ecommerce = empresa.tipoDeLoja === 'ECOMMERCE';
  }
}
