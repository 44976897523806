<div class="text-center">
  <i class="dripicons-information h1 text-info"></i>
  <h2 class="mt-2"  >Alerta</h2>
  <ng-container *ngIf="monitoradorPedidos.avisos && monitoradorPedidos.avisos.length > 0">
    <div>
      <h4 class="mt-3 text-left">
        Avisos de mesa (total: {{monitoradorPedidos.avisos.length}})
      </h4>
    </div>

    <hr>
    <div>
      <ul class="list-group">
        <ng-container *ngFor="let aviso of monitoradorPedidos.avisos">
          <li class="list-group-item">{{aviso.mensagem}}</li>
        </ng-container>

      </ul>
    </div>
    <hr>
    <div *ngIf="monitoradorPedidos.temAvisosPedidos()" >
      <h4 class="mt-3 text-left" >
        Avisos de pedidos
      </h4>
    </div>
    <hr>
  </ng-container>

  <div *ngIf="monitoradorPedidos.pedidosContestados.length">
                          <span class="text-danger" *ngIf="monitoradorPedidos.pedidosContestados.length===1">
                            <b> Foi relatado problema com um pedido</b>
                          </span>
                          <span class="text-danger" *ngIf="monitoradorPedidos.pedidosContestados.length > 1">
                           <b>     Foram relatados problemas com {{monitoradorPedidos.pedidosContestados.length}} pedidos</b>
                          </span>
  </div>

  <div *ngIf="monitoradorPedidos.pedidosEnderecoAlterado.length">
                          <span class="text-danger" *ngIf="monitoradorPedidos.pedidosEnderecoAlterado.length===1">
                            <b> Cliente solicitou alteração do endereço de entrega</b>
                          </span>
                         <span class="text-danger" *ngIf="monitoradorPedidos.pedidosEnderecoAlterado.length > 1">
                           <b>     Clientes solicitaram alteração do endereço de entrega</b>
                          </span>
  </div>

  <div *ngIf="monitoradorPedidos.falhasImportacao.length">
    <i class="fa fa-times-circle text-danger mr-1"></i>
    <span class="text-danger" *ngIf="monitoradorPedidos.falhasImportacao.length===1">
                            <b>Ocorreu um erro ao importar um pedido</b>
                          </span>
    <span class="text-danger" *ngIf="monitoradorPedidos.falhasImportacao.length > 1">
                           <b>  Ocorreram {{monitoradorPedidos.falhasImportacao.length}} erros ao importar pedidos</b>
                          </span>
  </div>

  <div *ngIf="monitoradorPedidos.novosPedidos.length===1">
                          <span class="text-danger" *ngIf="umPedidoNovo().aguardandoPagamentoOnline" >
                            *<b>Aguardando pagamento online</b></span>
    <h4 class="mt-3" >
      Você tem um novo pedido:   <b>#{{umPedidoNovo().codigo}}</b></h4>
  </div>

  <div *ngIf="monitoradorPedidos.novosPedidos.length > 1">
    <h4 class="mt-3" >
      Você tem {{monitoradorPedidos.novosPedidos.length}} novos pedidos </h4>

  </div>

  <div *ngIf="monitoradorPedidos.pedidosConfirmados.length > 0">

    <h4 class="mt-3" *ngIf="monitoradorPedidos.pedidosConfirmados.length === 1" >
      O Pagamento do pedido <b> {{monitoradorPedidos.pedidosConfirmados[0].codigo}}</b> foi confirmado
    </h4>

    <h4 class="mt-3" *ngIf="monitoradorPedidos.pedidosConfirmados.length >  1" >
      Os Pagamentos dos pedidos foram confirmados: <b>
                              <span *ngFor="let pedido of monitoradorPedidos.pedidosConfirmados; let ultimo = last;">
                                  {{pedido.codigo}} <span *ngIf="!ultimo">, </span>
                              </span>
    </b>
    </h4>


  </div>

  <button type="button" class="btn btn-light "  (click)="monitoradorPedidos.fecheEZerePedidosNovos()" >Ok</button>


  <button type="button" class="btn btn-info ml-2  "
          *ngIf="monitoradorPedidos.novosPedidos.length === 1 && !monitoradorPedidos.pedidosConfirmados.length"
          (click)="monitoradorPedidos.verPedido(umPedidoNovo())" >
    {{!umPedidoNovo().aceito && !umPedidoNovo().aguardandoPagamentoOnline ? 'Aprovar' : 'Ver'}}  Pedido
  </button>

  <button type="button" class="btn btn-info ml-2  "
          *ngIf="!monitoradorPedidos.novosPedidos.length && monitoradorPedidos.pedidosConfirmados.length === 1"
          (click)="monitoradorPedidos.verPedido(monitoradorPedidos.pedidosConfirmados[0])" >
    Ver Pedido
  </button>

  <button type="button" class="btn btn-info ml-2  "  (click)="verPedidos()"
          *ngIf="monitoradorPedidos.totalPedidosMonitorados() > 1  " >Ver Pedidos</button>


  <button type="button" class="btn btn-danger ml-2  mt-2"  (click)="verPedidosEmDisputa()"
          *ngIf="monitoradorPedidos.pedidosContestados.length " >Ver Contestações</button>

  <button type="button" class="btn btn-danger ml-2  mt-2"  (click)="verPedidosAlteracaoEndereco()"
          *ngIf="monitoradorPedidos.pedidosEnderecoAlterado.length " >Ver Alterações</button>


  <button type="button" class="btn btn-danger ml-2  mt-2"  (click)="verPedidosdErroImportar()"
          *ngIf="monitoradorPedidos.falhasImportacao.length " >Ver Erro</button>


</div>
