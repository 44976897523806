<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a routerLink="/superadmin/empresas">Empresas</a></li>
          </ol>
        </div>
        <h4 _ngcontent-mki-c5="" class="page-title">
          <i class="far fa-building"></i>
          Lojas
        </h4>
      </div>
    </div>
  </div>

  <button (click)="atualize()" class="btn btn-sm btn-primary">Atualizar</button>

  <div class="mt-1"></div>
  <kendo-grid [data]="empresasResultSet" *ngIf="!carregando" [pageSize]="this.tamanhoPagina"
            [skip]="page"
            [style]=""
            [loading]="loading" style="min-height: 300px;"

            [pageSize]="gridState.take"
            [pageable]="true"
            [skip]="gridState.skip"
            [sort]="gridState.sort"
            [pageable]="true"
            [sortable]="true"
            (pageChange)="onPageChange($event)"
            footerStyle="font-size: 11px;">
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum empresa carregada"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <ng-template kendoGridToolbarTemplate [position]="'top'">

    <div class="row">
      <div class="col-6">
        <input kendoTextBox placeholder="Busque por nome da empresa" class="form-control busca" [(ngModel)]="filtro.nome"
               appAutoFocus kendoTextBox (input)="onFilter($event)"/>

      </div>
      <div class="col-3"></div>
    </div>



  </ng-template>
  <kendo-grid-column title=""   width="50px" >
    <ng-template kendoGridCellTemplate   let-rowIndex="rowIndex">
      <span class="text-muted ">{{rowIndex + 1}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="ID" class="coluna_menor"   width="60px;">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span ><b>{{dataItem.id}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Nome" sortable="true" field="nome" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        <a href="https://{{dataItem.dominio}}.promokit.com.br/admin/index" class="text-blue" target="_blank"><b>{{dataItem.nome}}</b></a>

        <span *ngIf="dataItem.online" class="badge badge-success ml-2">
          Online
        </span>
      </span>
      <p class="mt-1 mb-0 text-muted">
        <span>{{dataItem.telefone }}</span>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Link da Loja" width="150px">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a href="https://{{dataItem.dominio}}.promokit.com.br/" class="text-blue" target="_blank"><b>{{dataItem.dominio}}</b></a>
    </ng-template>
  </kendo-grid-column>
    <kendo-grid-column title="Catálogo"  width="200px" *ngIf="catalogos.length > 0" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <kendo-combobox   name="tipo{{dataItem.id}}" [(ngModel)]="dataItem.catalogo" [data]="catalogos" [disabled]="alterandoCatalogo"
                          [allowCustom]="false" placeholder="Selecione um catálogo" class="form-control" [clearButton]="false"
                          [textField]="'nome'"     style="border: none;" required
                          [valueField]="'id'"
                          (opened)="salveCatalogoAnterior(dataItem)"
                          (valueChange)="alterouCatalogo(dataItem, $event)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="Catálogo Modelo"  width="200px" *ngIf="catalogos.length > 0" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <kendo-combobox   name="modelo{{dataItem.id}}" [(ngModel)]="dataItem.modeloCatalogoDaRede" [data]="modelosDaRede(dataItem)" [disabled]="alterandoCatalogo"
                          [allowCustom]="false" placeholder="Selecione um modelo" class="form-control" [clearButton]="false"
                          [textField]="'nome'"     style="border: none;" required
                          [valueField]="'id'"
                          (valueChange)="alterouModeloCatalogo(dataItem, $event)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column title="Cidade"  width="150px" media="(min-width: 900px)" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.enderecoCompleto">
          {{dataItem.enderecoCompleto.cidade.nome}} / {{dataItem.enderecoCompleto.estado.sigla}}
        </span>
    </ng-template>
  </kendo-grid-column>


    <kendo-grid-column title="Estado"  width="150px"  media="(min-width: 900px)" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.enderecoCompleto">
          {{dataItem.enderecoCompleto.estado.sigla}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <!--
  <kendo-grid-column title="Último Ação" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <strong>{{dataItem.tempoAtras}}</strong>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="" class="coluna_menor" width="50px">
    <ng-template kendoGridCellTemplate let-empresa let-rowIndex="rowIndex">
      <app-status-uso-empresa [ultimaAcao]="empresa.ultimaAcao"></app-status-uso-empresa>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Pedidos 24 horas" field="qtdPedidos24horas" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <strong>{{dataItem.qtdPedidos24horas}}</strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Pedidos 7 dias" field="qtdPedidos7dias" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <strong>{{dataItem.qtdPedidos7dias}}</strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Pedidos no mês" field="qtdPedidosMes" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <strong>{{dataItem.qtdPedidosMes}}</strong>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" width="150">
    <ng-template kendoGridCellTemplate   let-empresa>
      <div kendoTooltip  [tooltipTemplate]="template">

        <button kendoGridEditCommand class="k-primary" [primary]="false"  title="editar"><i class="fa fa-edit"></i></button>

        <button  kendoGridRemoveCommand  class="float-right"      [primary]="true" title="desativar"> <i class="fa fa-trash"></i></button>

      </div>


    </ng-template>
  </kendo-grid-command-column>
-->
</kendo-grid>
</div>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.getAttribute("data-title") }}</span>

</ng-template>
<kendo-dialog title="Alteração de catálogo" *ngIf="modalAlterarCatalogo" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja alterar o catálogo da empresa
    "<strong>{{empresaCatalogo.nome}}</strong>" para o "<strong>{{novoCatalogo.nome}}</strong>"?
  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;" *ngIf="alterandoCatalogo"></div>
  <kendo-dialog-actions >
    <button kendoButton [disabled]="alterandoCatalogo" (click)="confirmeAlteracaoCatalogo()" primary="true">Sim</button>
    <button kendoButton [disabled]="alterandoCatalogo" (click)="canceleAlteracaoCatalogo()">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
