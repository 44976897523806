import {Component, OnDestroy, OnInit} from '@angular/core';
import {Mesa} from "../../objetos/Mesa";
import {NovaMesaComponent} from "../../../mesa/nova-mesa/nova-mesa.component";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import {MesaService} from "../../../services/mesa.service";
import {EnumFiltroDePedidos, PedidosService} from "../../../services/pedidos.service";
import {ComandaService} from "../../../services/comanda.service";
import moment from "moment";
import {Router} from "@angular/router";
import {PedidoLocalService} from "../../../services/pedido-local.service";
import {MonitoradorPedidos} from "../../../fidelidade/MonitoradorPedidos";
import {Subscribable, Subscription} from "rxjs";
import {AutorizacaoService} from "../../../services/autorizacao.service";
import {IntegracaoERPService} from "../../../services/integracaoerp.service";

@Component({
  selector: 'app-pedidos-mesa',
  templateUrl: './pedidos-mesa.component.html',
  styleUrls: ['./pedidos-mesa.component.scss']
})
export class PedidosMesaComponent implements OnInit, OnDestroy  {
  objetos: Array<Mesa> = [];
  empresa: any;
  pedidos: any = [];
  carregando = false;
  ultimaAtualizacao: any;
  paginacao = { total: 1000};
  comandas: Array<any> = [];
  filtro = '';
  resumoMesas: any = {}
  inscricaoResumoMesas: Subscription;
  carregouMesas = false;
  modoGarcom: boolean;
  garcom: any;
  constructor(private router: Router, private constantsService: ConstantsService, private mesaService: MesaService,
              public monitoradorPedidos: MonitoradorPedidos, private integracaoERPService: IntegracaoERPService,
              private comandaService: ComandaService, private pedidoLocalService: PedidoLocalService,
              private autorizacaoService: AutorizacaoService) {

  }


  ngOnInit(): void {
    this.constantsService.empresa$.subscribe(   async(empresa) => {
      if (!empresa) return;

      this.empresa = empresa;

      await this.carregueComandas();
      this.carregouMesas = true;

    });

    let usuario = this.autorizacaoService.getUser()
    this.modoGarcom =  usuario.garcom;

    if(this.modoGarcom)
      this.garcom = usuario

   this.inscricaoResumoMesas = this.monitoradorPedidos.resumoMesas$.subscribe( resumoMesas =>  {
    if(!resumoMesas) return;

    let tempo = resumoMesas.totalNovo === 0 ? 3000 : 0;

    setTimeout(  () => {
     this.resumoMesas = resumoMesas;
     this.setMesasAbertasComandas();

     setTimeout( () => {
       this.recarregueComandas();
     }, 500)
    }, tempo);

    });

  }

  ngOnDestroy(): void{
    this.inscricaoResumoMesas.unsubscribe();
  }

  private async setMesasAbertasComandas(){
    if(this.resumoMesas.mesas){
      for( let comanda of this.comandas ) {
        let resumoMesa = this.resumoMesas.mesas.find( mesa => mesa.id === comanda.mesa.id);
        comanda.mesa.totalNovo = resumoMesa ? resumoMesa.totalNovo : 0;

        if( comanda.mesa.totalNovo){
          await this.comandaService.marqueComoLido(comanda)
          this.monitoradorPedidos.zereComadasNaoLidas(comanda);
        }

      }
    }
  }

  private recarregueComandas(){
    let recarregar = false;

    this.resumoMesas.mesas.forEach( mesa => {
      let comandaDaMesa = this.comandas.find( comanda => comanda.mesa.id === mesa.id);

      if(!comandaDaMesa) recarregar = true;

    })

    if(recarregar)
      this.carregueComandas();
  }


  editar(mesa: Mesa) {   }

  abraDialogRemover(mesa: Mesa) {  }

  carregueComandas(): Promise<any>{
    return new Promise((resolve) => {
      this.carregando = true;

      this.comandaService.liste().then( (comandas) => {
        this.carregando = false;

        for( let comanda of comandas ) {
          comanda.tempo = moment(comanda.horarioAbertura).fromNow();
          comanda.mesa.disponivel = false;
          comanda.mesa.totalNovo = 0
          comanda.nome = comanda.mesa.nome;
        }

        this.comandas = comandas;
        this.setMesasAbertasComandas();
        resolve('');
      }).catch( (err) => {
        alert(err)
        this.carregando = false;
        resolve('');
      });
    })


  }

  verDetalhes(comanda: any) {
    if( !comanda ) { return; }

    this.router.navigateByUrl('/admin/fechar-mesa/' + comanda.id,
      /* Removed unsupported properties by Angular migration: queryParams. */ {}).then( () => {})
  }

  novoPedido() {
    this.router.navigateByUrl('/admin/pedidos/novo-mesa').then( () => {});
  }

  novoPedidoMesa(comanda: any) {
    this.pedidoLocalService.remova(comanda.contato.telefone);

    let params: any = {idm: comanda.mesa.id,
      tel: comanda.contato.codigoPais + comanda.contato.telefone,
      nome: comanda.contato.nome,
      modoEdicao: false,
      urlRetorno: '/admin/pedidos-mesas'}

    if( comanda.codigoPdv)
       params.cc =  comanda.codigoPdv

    if(this.modoGarcom)
      params.idg = this.garcom.id

    this.router.navigate(['/admin/pedidos/novo'],
      {queryParams:  params}).then( () => {});
  }

  tenteNovamenteExecutarNotificacao(comanda: any) {
    comanda.executando = true;

    let notificacao: any = Object.assign({}, comanda.errosNotificacao[0]); // ultima

    notificacao.comanda = comanda;

    this.integracaoERPService.execuetNotificacaoMesa(notificacao).then((resposta) => {
      comanda.executando = false;
      comanda.errosNotificacao = [];
      comanda.status = "Fechada";
      comanda.fechou = true;
    }).catch((err) => {
       alert(err)
      comanda.executando = false;
    });
  }
}
