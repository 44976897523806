import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {PedidosService} from "../../services/pedidos.service";
import {DialogService} from "@progress/kendo-angular-dialog";

@Component({
  selector: 'app-acao-pedido-alteracao-endereco',
  templateUrl: './acao-pedido-alteracao-endereco.component.html',
  styleUrls: ['./acao-pedido-alteracao-endereco.component.scss']
})
export class AcaoPedidoAlteracaoEnderecoComponent implements OnInit {
  @Input() pedido: any = {};
  @ViewChild('frm', {}) frm: NgForm;
  public _submitted = false;
  erro: string
  processando: boolean;
  tempoRestante: any;
  constructor( protected pedidosService: PedidosService, private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  respondeu(aceitou){
    this.processando = true;

    this.pedidosService.envieRespostaAlteracaoEndereco(this.pedido, aceitou).then((reposta: any) => {
      this.processando = false;
      delete this.pedido.alteracaoEndereco;
      this.pedido.endereco = reposta;
    }).catch((err) => {
      this.processando = false;
      alert(err)
    })
  }
}
