import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class ImportadorChinnaService    extends ServerService {

  listeNovosProdutos(sincronizarPrecos: boolean, sincronizarImagens: boolean) {
    let params: any = {}
    if(sincronizarPrecos) params.precos = true;
    if(sincronizarImagens) params.imagens = true;

    return this.obtenha(  String(`/importador/chinna/produtos`), params)
  }

  listeProdutosRede(empresaDaRede){
    return this.obtenha(String(`/importadorrede/${empresaDaRede.id}/produtos/novos`) , {});
  }

  sincronizeCatalogoRede(empresaDaRede: any, sincronizarPrecos: boolean , sincronizarImagens: boolean){
    let params: any = {}
    if(sincronizarPrecos) params.precos = true;
    if(sincronizarImagens) params.imagens = true;

    return this.obtenha(String(`/importadorrede/${empresaDaRede.id}/catalogo/sincronize`) , params);
  }

  sincronizeCatalogoDoModelo(empresaDaRede: any, sincronizarPrecos: boolean , sincronizarImagens: boolean){
    let params: any = {}
    if(sincronizarPrecos) params.precos = true;
    if(sincronizarImagens) params.imagens = true;

    return this.obtenha(String(`/importadorrede/${empresaDaRede.id}/catalogo/modelo/sincronize`) , params);
  }


}
