import { Component, OnInit } from '@angular/core';
import {bool} from "aws-sdk/clients/signer";
import {EntregadorService} from "../../services/entregador.service";

@Component({
  selector: 'app-modulo-entregadores',
  templateUrl: './modulo-entregadores.component.html',
  styleUrls: ['./modulo-entregadores.component.scss']
})
export class ModuloEntregadoresComponent implements OnInit {
  periodoEspecifico = true;
  filtro: any = {
    inicio: new Date(),
    fim: new Date()
  };
  hoje: Date = new Date();
  valorMes: any;
  entregadores: any = [{id: "Todos", nome: "Todos"}];
  carregando: boolean;
  relatorioEntregas: any;
  carregandoRelatorio: boolean;
  pedidosEntregador: any;
  carregandoPedidosEntregador: any;
  modoGrupoLojas: any;


  constructor(private entregadorService: EntregadorService) {
    this.filtro.inicio.setHours(0, 0, 0, 0)
    this.filtro.fim.setHours(23, 59, 59)
    this.carregando = true

  }

  ngOnInit(): void {
    this.carregueEntregadores()
    this.carregueRelatorioEntregas()
  }

  carregueEntregadores() {
    this.entregadorService.liste().then((entregadores) => {
      if(entregadores.length > 0) {
        this.entregadores = [{id: "Todos", nome: "Todos"}, {id: "", nome: "----"}].concat(entregadores)
        this.filtro.entregador = this.entregadores[0]
      }
    })

  }
  carregueRelatorioEntregas() {
    this.carregandoRelatorio = true
    this.entregadorService.obtenhaRelatorioPeriodo(this.filtro.inicio, this.filtro.fim,
      this.filtro.entregador).then((relatorioEntregas) => {
      this.relatorioEntregas = relatorioEntregas
      this.carregandoRelatorio = false
    })
  }

  trocouDeAba() {
    this.carregueEntregadores()
  }

  exibirPeriodoEspecifico() {
    this.periodoEspecifico = true
  }

  carregueRelatorio() {
    this.carregueRelatorioEntregas()
  }

  exibirPeriodoMensal() {
    this.periodoEspecifico = false;
  }

  mudouValorMes($event: Date) {

  }

  busqueEntregadores(nome: any) {


  }



  obtenhaNomeArquivoDownload(nomeArquivo: string) {

  }

  verPedidosEntregador(relatorioEntregador: any) {
    this.carregandoPedidosEntregador = true
    this.entregadorService.obtenhaRelatorioPedidos(this.filtro.inicio, this.filtro.fim,
      {id: relatorioEntregador.idEntregador}).then((relatorioPedidos) => {
      this.carregandoPedidosEntregador = false
      this.pedidosEntregador = relatorioPedidos

    })
  }

  voltarPedidosEntregador() {
      this.pedidosEntregador = null
  }
}
