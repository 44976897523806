import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {
  PainelImportacaoProdutosComponent
} from "../../compartilhado/painel-importacao-produtos/painel-importacao-produtos.component";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ImportadorChinnaService} from "../../services/importador-chinna.service";
import {CatalogosService} from "../../superadmin/services/catalogos.service";

@Component({
  selector: 'app-sincronizar-catalogos-modelos',
  templateUrl: './sincronizar-catalogos-modelos.component.html',
  styleUrls: ['./sincronizar-catalogos-modelos.component.scss']
})
export class SincronizarCatalogosModelosComponent implements OnInit {
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  catalogoModelo: any = {};
  empresasSincronizar: any =  [];
  empresasNaoSincronizar: any =  [];
  respostas:   any =  { };
  carregandoProdutos = false ;
  importacao: any;
  emptyStyles: {[key: string]: any} = {  background: '' };
  progressStyles: {[key: string]: any} = {};
  erroBuscarCatalogo: string
  opcoes: any = { sincronizarPrecos : true}
  constructor(private empresasService: EmpresasService,
              private importadorChinnaService: ImportadorChinnaService,
              private catalogosService: CatalogosService,
              private route: ActivatedRoute) {
    if(window.history.state && window.history.state.catalogo){
      this.catalogoModelo = window.history.state.catalogo;
      this.carregueProdutos();
    } else {
      this.catalogosService.listeCatalogos({id: this.route.snapshot.params.id}).then( (resposta) => {
        this.catalogoModelo = resposta[0];
       this.carregueProdutos();
      })

    }
  }

  ngOnInit(): void {   }

  carregueProdutos(){
    this.carregandoProdutos = true;

    this.empresasService.listeEmpresasDoModelo(this.catalogoModelo).then( (resp: any) => {
      this.empresasSincronizar = resp.empresas;
      if(this.empresasSincronizar.length){
        this.catalogosService.listeProdutosRede(this.catalogoModelo.id).then((resposta: any) => {
          this.carregandoProdutos = false;
          this.painelImportacao.setProdutosSincronizar(resposta.produtos, [], [],
            resposta.categorias)
        }).catch((erro) => {
          this.carregandoProdutos = false;
          this.erroBuscarCatalogo = erro;
        })
      } else {
        this.carregandoProdutos = false;
      }
    })
  }

  async sincronizeEmpresa(empresa: any) {
    this.importacao.empresa = empresa;
    let resposta: any = await this.importadorChinnaService.sincronizeCatalogoDoModelo(empresa,
      this.opcoes.sincronizarPrecos, this.opcoes.sincronizarImagens).catch( (erro) => {
      this.respostas.erros.push({ empresa: empresa.nome, erro: erro});
      empresa.falhou = true;
    })
    this.importacao.totalProcessados++;

    if(resposta){
      delete empresa.falhou
      delete empresa.incompleto
      resposta.empresa = empresa.nome;

      if(resposta.erros.length){
        empresa.incompleto = true;
        this.respostas.incompletos.push(resposta)
      } else {
        this.respostas.concluidos.push(resposta)
      }
    }

    delete  this.importacao.empresa;
  }

  async inicieSincronizacao(soFalhou = false, incompletos = false){
    let empresasSincronizar = soFalhou ?  this.empresasSincronizar.filter( e => e.falhou) :
      (incompletos ? this.empresasSincronizar.filter( e => e.incompleto) : this.empresasSincronizar)

    this.respostas  =  { concluidos: [], incompletos: [], erros: []};
    this.importacao = { qtde: empresasSincronizar.length, totalProcessados: 0}
    this.progressStyles = {   color: '#fff',  background: '#1050bb' };

    for(let i = 0; i <  empresasSincronizar.length ; i++){
      await  this.sincronizeEmpresa(empresasSincronizar[i]);
    }

    if(!this.respostas.erros.length){
      this.progressStyles.background = '#8EBC00';
    }  else {
      this.progressStyles.background = '#f1556c';
    }

    this.importacao.finalizada = true;
  }

  async reimporteIncompletos(){
    delete     this.importacao.finalizada;

    await this.inicieSincronizacao(false, true)
  }

  async reimporteFalhas(){
    delete     this.importacao.finalizada;

    await this.inicieSincronizacao(true)

  }

  obtenhaLabel(){
    return {
      format: (value) => {
        return `${value}/${this.importacao.qtde}`;
      },
      position: 'center',
    }
  }

}
