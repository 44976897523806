import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Atividade} from "../../../server/domain/Atividade";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class AtividadeService extends ServerService {
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  salveAtividade(atividade: Atividade) {
    return this.salve('/atividades', atividade );
  }

  listeAtividadesEmpresa(empresa: any ) {
    let url = '/empresas/' + empresa.id  + "/atividades"

    return this.httpCliente.get(url)
      .toPromise().then(this.retorno).catch(this.handleError);
  }
  listeAtividadesPlano(plano: any = null) {
    let url = '/atividades/' + plano.id   ;

    return this.httpCliente.get(url)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  removaAtividade(atividade: any): Promise<any> {
    return this.remova( "/atividades/" + atividade.id, {})
  }


}
