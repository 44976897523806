  <div class="modal-content">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

    <div #pnl class="modal-body">
      <div class="alert alert-success alert-dismissible" *ngIf="mensagemSucesso" class="mt-2">
        <button type="button" class="close" (click)="fecheMensagemSucesso()">
          <span>&times;</span>
        </button>
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
      </div>
      
      <div class="alert alert-danger alert-dismissible" *ngIf="mensagemErro" class="mt-2">
        <button type="button" class="close" (click)="fecheMensagemErro()">
          <span>&times;</span>
        </button>
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
      </div>

      <div class="form-group mb-3">
        <label for="nome">Nome da Categoria</label>
        <input type="text" class="form-control" autocomplete="off"
               id="nome" name="nome" [(ngModel)]="categoria.nome" #nome="ngModel"
               (change)="categoria.nome = categoria.nome.trim()"
               placeholder="Nome da categoria" value="" required appAutoFocus [autoFocus]="true">
        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        </div>
      </div>

      <app-upload-crop-imagem #categoriarUpload ratio="1"  widthExibir="250"
                              *ngIf="empresa.cardapio && empresa.cardapio.exibirSelecaoCategorias"
                              resizeWidth="400" minWidth="400" minHeight="400" [confirmarSalvar]="false"
                              [objeto]="categoria"  [prop]="'imagem'" [label]="'Foto'"  >
      </app-upload-crop-imagem>

      <div *ngIf="empresa && empresa.configImpressao && empresa.configImpressao.multiplasImpressoras">
        <div class="form-group    ">
          <label for="nome">Imprimir automaticamente nos setores</label>
          <div class="row">
            <div class="col-4" *ngFor="let impressora of impressoras; let i = index;">
              <div class="form-group mb-3">
                <label class="k-checkbox-label">
                  <input type="checkbox" name="disponivel{{i}}" class="k-checkbox "
                         [(ngModel)]="impressora.disponivel" [disabled]="impressora.imprimirAutomatico" />
                  {{impressora.setor}}</label>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando"  >Salvar
            <i class="k-icon k-i-loading ml-1" *ngIf="salvando"> </i>
        </button>
        <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                (click)="feche(null)">Fechar</button>
      </div>

    </div>

  </form>
</div>
