<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="row">
    <div class="form-group mb-3 col-12">
      <label for="nome">Nome</label>
      <input type="text" class="form-control" autocomplete="off" appAutoFocus [autoFocus]="true"
             id="nome" name="nome" [(ngModel)]="entregador.nome" #nome="ngModel"
             placeholder="Nome do entregador" value="" required/>
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome do entregador é obrigatório</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group mb-3 col-3">
      <label for="nome">Código</label>
      <input type="text" class="form-control" autocomplete="off"
             id="codigo" name="codigo" [(ngModel)]="entregador.codigo" #codigo="ngModel"
             placeholder="Código" value="" />
    </div>
    <div class="form-group mb-3 col-4">
      <label for="telefone">Telefone</label>
      <div class="input-group ">
        <input kendoTextBox  id="telefone" name="telefone"  #telefone="ngModel" mask="(00)0-0000-0000"
               type="text" class="form-control" autocomplete="off"
               [(ngModel)]="entregador.telefone"  placeholder="Telefone com DDD"  required/>

        <div class="invalid-feedback">
          <p *ngIf="telefone.errors?.required">Telefone é obrigatório</p>
          <p *ngIf="telefone.errors?.mask">Telefone inválido</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group mb-3 col-8">
      <label for="email">E-mail</label>
      <input type="email" class="form-control" autocomplete="off"
             id="email" name="email" [(ngModel)]="entregador.email" #email="ngModel"
             placeholder="Email do entregador" value=""   >
    </div>
    <div class="col">
      <div class="form-group mb-3">
        <label >Ativo </label><br>
        <kendo-switch name="ativo" [(ngModel)]="entregador.ativo"
                      [onLabel]="'Sim'"
                      [offLabel]="'Não'"></kendo-switch>
      </div>

    </div>
  </div>




  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
    <button type="button" (click)="fechar()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>


</form>
