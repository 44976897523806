<div >
   <h4 class="page-title">
    <button class="btn btn-outline-blue btn-rounded mr-1" (click)="voltar()"  [hidden]="modal"  >
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
   </h4>

  <div class="row">
    <div class="col-12  " [ngClass]="{'col-sm-8 col-lg-5': !modal}">

        <div class="row" style="    overflow: hidden;">
          <div class="col-12">
            <h4>Fatura</h4>
            <app-fatura-detalhes [fatura]="fatura"></app-fatura-detalhes>
          </div>

        </div>

      <h4 class="mb-2"> <i class="fa fa-credit-card"></i> Pagamento com cartão</h4>
      <div class="card-box" style="    overflow: hidden;">
        <div class="row">
          <div class="col-12" [hidden]="fatura.paga">
            <cad-cartaocredito #cccompoment [parcelamento]="{numeroParcelas: contrato.numeroParcelas, total: fatura.total}"
                               (onCriou)="vinculeCartao($event)"  *ngIf="contrato.id">

            </cad-cartaocredito>
          </div>

          <div class="col-12">
            <div *ngIf="erro" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
              <i class="mdi mdi-check-all mr-2"></i> {{erro}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemErro()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <h4 class="text-success text-center" *ngIf="fatura.paga">Fatura foi paga com sucesso!</h4>

          </div>
        </div>
      </div>


    </div>
  </div>

</div>
