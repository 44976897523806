import {Component, OnInit, ViewChild} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {Router} from "@angular/router";

import {PayloadJsonViewComponent} from "./payload-json-view/payload-json-view.component";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-tela-pedidos-erros-importar',
  templateUrl: './tela-pedidos-erros-importar.component.html',
  styleUrls: ['./tela-pedidos-erros-importar.component.scss']
})
export class TelaPedidosErrosImportarComponent implements OnInit {
  @ViewChild('gridErrosNovos', { static: true}) gridErrosNovos: any;
  @ViewChild('gridErrosHistorico', { static: true}) gridErrosHistorico: any;
  historicoErros  = [];
  carregando = false;
  page: any = 0;
  pageSize = 10;
  total: number;
  constructor(private pedidosService: PedidosService, private router: Router,
              private autorizacaoService: AutorizacaoService,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.carregueNovosErros();
  }


  carregueNovosErros(){
    if(this.carregando) return;

    this.carregando = true;
    this.pedidosService.listeFalhasImportacao(this.page * this.pageSize, this.pageSize).then( async (resp: any) => {
      this.historicoErros = resp.errosImportacao;
      this.total = resp.total;
      this.carregando = false;

      for(let i = 0 ; i < this.historicoErros.length ; i++)
        if(!this.historicoErros[i].lida)
          await this.pedidosService.marqueFalhaImportacaoLida(this.historicoErros[i]);
    })
  }

  onPageChange($event: PageChangeEvent) {
    this.page = $event.skip;

    window.scrollTo(0, 0);
    this.carregueNovosErros();
  }

  verJson(item: any, event: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: PayloadJsonViewComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.payload = item.payload

    event.stopPropagation();
    event.preventDefault();
  }

  abriuErro($event){

  }

  reimportePedido(falhaImportacao: any){
    falhaImportacao.importando = true;
    this.pedidosService.reimportePedidoIfood(falhaImportacao).then((resposta) => {
      falhaImportacao.importando = false;
      falhaImportacao.integrado = true;
      falhaImportacao.pedido = resposta;

    }).catch((erro) => {
      falhaImportacao.importando = false;
      alert(erro);
    })
  }

  exibirReimportar(){
    let usuario = this.autorizacaoService.getUser();

    //restrito por hora! para nao importar pedidos cancelados
    return usuario && usuario.id === 8;
  }
}
