import {Injectable} from "@angular/core";
import {ServerService} from "../../services/ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CuponsService  extends  ServerService {
  constructor(http: HttpClient) {
    super(http)
  }

  salveCupom(cupom: any) {
    return this.salve('/cupons', cupom);
  }

  desativeCupom(cupom: any) {
    return this.salve('/cupons/desative', cupom);
  }

  ativeCupom(cupom: any) {
    return this.salve('/cupons/ative', cupom);
  }

  listeCupons(filtro: any = {}) {
    return this.obtenha('/cupons', filtro)
  }

  jaUsou(id: number) {
    return this.obtenha( `/cupons/${id}/utilizado`, {})
  }

  listeContatos(cupom: any, inicio: number , total: number, texto: string = null) {
    let params: any = {id: cupom.id, i: inicio, t: total};

    if(texto) params.q = texto;

    return this.obtenha('/cupons/contatos/ativos', params)
  }

  listeContatosDisponiveis(cupom: any, inicio: number , total: number , texto: string = null) {
    let params: any = {id: cupom.id, i: inicio, t: total};

    if(texto) params.q = texto;
    return this.obtenha('/cupons/contatos/disponiveis', params)
  }

  adicioneContatosLista(cupom: any, contatos: number[] = []) {
    return this.salve('/cupons/contatos/add', {cupom: cupom, contatos: contatos});
  }

  removaContatoDaLista(cupom: any, contato: any){
    return this.salve('/cupons/contatos/del', {cupom: cupom, contato: contato});
  }

  removaCupom(cupom: any) {
    return this.remova(String(`/cupons/${cupom.id}`) , cupom)
  }
}
