import {ImpressaoService} from "../services/impressao.service";
import {PedidosService} from "../services/pedidos.service";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {Component, Input, OnInit} from "@angular/core";
import {ProcessadorReciboTexto} from "../services/impressao/ProcessadorReciboTexto";

@Component({
  template: ''
})
export class ComandosImprimirComanda  implements OnInit {
  empresa: any = {};
  modoGrupoLojas: any;
  protected configImpressao: any;
  protected impressoras = [];
  opcoesImpressao = [
    'Completo',
    'Resumido'
  ];

  constructor(protected pedidosService: PedidosService, protected constantsService: ConstantsService,
              protected impressao: ImpressaoService) {
    this.pedidosService = pedidosService;
    this.constantsService = constantsService;
    this.impressao = impressao;

  }

  ngOnInit() {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) return;
      if(!this.modoGrupoLojas){
        this.empresa = empresa;
        this.configImpressao = empresa.configImpressao;
        if(this.configImpressao && this.configImpressao.multiplasImpressoras)
          this.impressoras = this.configImpressao.impressoras;
      }
      this.carregouEmpresa();
    });
  }

  carregouEmpresa(){ }

  imprimaLayoutUnicoComanda(comanda: any){
    if( !comanda ) { return; }

    let layout: string = this.configImpressao ? this.configImpressao.layoutPedido : null;

    if(this.configImpressao && this.configImpressao.imprimirTXT){
      this.imprimaNativamenteComandaNoLayout(comanda, layout);
    } else {
      this.imprimaComanda(comanda, layout)
    }
  }

  imprimaUmDosLeiautesComanda(comanda: any, opcao: any) {
    if( !comanda ) { return; }

    let leiaute = opcao === 'Completo' ? 'PedidoCompleto' : 'PedidoResumido';

    if(this.configImpressao && this.configImpressao.imprimirTXT){
      this.imprimaNativamenteComandaNoLayout(comanda, leiaute);
    } else {
      this.imprimaComanda(comanda, leiaute)
    }
  }

  imprimaComanda(comanda: any, layoutPedido: any) {
    let link = '/imprimir/comanda/' + comanda.id

    if(layoutPedido)
      link += '?l=' + layoutPedido

    window.open(link);
  }

  imprimaNativamenteComandaNoLayout(comanda: any, leiaute: string){
    let impressora = this.configImpressao.impressoras[0];
    impressora.layout = leiaute;
    this.imprimaNativamenteComanda(comanda, impressora)
  }

  imprimaNativamenteComanda(comanda: any, impressora: any) {
    if(!impressora) return alert('Nenhuma impressora configurada');

    this.pedidosService.obtenhaComandaComoPedido(comanda.id).then( async (pedidoCompleto) => {
      try {
        console.log(pedidoCompleto)
        let processadorDeRecibo = new ProcessadorReciboTexto(impressora)
        console.log(impressora)
        if(impressora.modoHTML)
          await this.impressao.imprima('/pedidos/comanda/' + comanda.id + '/comprovante?resumido=' +
            (impressora.layout === 'PedidoResumido'), processadorDeRecibo.impressora)
        else
        await  this.impressao.imprima(processadorDeRecibo.obtenhaRecibo(pedidoCompleto, impressora.layout), processadorDeRecibo.impressora)

      } catch(erro) {
        console.log(erro)
        alert("Houve um erro ao imprimir: " + erro)
      }
    })
  }
}
