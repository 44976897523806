import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
  TelaEscolherDominioEmpresaComponent
} from "../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component";
import {
  TelaEscolherCupomCampanhaComponent
} from "../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component";
import {DialogService} from "@progress/kendo-angular-dialog";

@Component({
  selector: 'app-controle-texto-mensagem',
  templateUrl: './controle-texto-mensagem.component.html',
  styleUrls: ['./controle-texto-mensagem.component.scss']
})
export class ControleTextoMensagemComponent implements OnInit, AfterViewInit {
  @Input() empresa: any;

  @Input() campanha: any = {
    mensagem: ''
  };
  @ViewChild('txtNovaIdeia', { static: false }) txtNovaIdeia: ElementRef;

  qtdeCaracteresRestantes = 160;
  QTDE_MAXIMA_CARACTERES = 160;
  mensagemPossuiNomeContato: boolean;
  mensagemPossuiLinkCardapio: boolean;

  @Input() campos = [{
    nome: 'Nome do Contato',
    texto: '[NomeContato]',
  }, {
    nome: 'Nome Completo Contato',
    texto: '[NomeCompletoContato]',
  }, {
    nome: 'Telefone do Contato',
    texto: '[TelefoneContato]',
  }, {
    nome: 'Empresa',
    texto: '[Empresa]',
  } , {
    nome: 'Link Cartão',
    texto: '[LinkCartao]',
  }, {
    nome: 'Codigo',
    texto: '[CODIGO]',
  }, {
    nome: 'Link Ativar Cartão',
    texto: '[LinkAtivarCartao]',
  }, {
    nome: 'Link Cardápio',
    texto: '[Link_Cardapio]',
  }, {
    nome: 'Link Avaliar Pedido',
    texto: '[Link_Avaliar]'
  }, {
    nome: 'Link Cupom',
    texto: 'Cupom',
  }, {
    nome: 'Link Inteligente Cardápio (Outra Empresa)',
    texto: 'Link_Cardapio_DARK',
  }, {
    nome: 'Nova Mensagem',
    texto: '[Nova_Mensagem]',
  }, {
    nome: 'Saldo Cashback Trigo',
    texto: '[Saldo_Cashback_Trigo]',
  }];

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  resize(txt: any) {
    txt.style.height = 'auto';
    txt.style.height = (txt.scrollHeight) + 'px';
  }

  adicioneCampo(textArea, campanha: any, campo: any) {
    if( campo.texto === 'Link_Cardapio_DARK' ) {
      TelaEscolherDominioEmpresaComponent.abraComoDialog(this.dialogService, this.empresa,
        (dominioEmpresa) => {
          this.insiraCampoNaCaixaDeTexto(textArea, campanha, {
            texto: `[Link_Cardapio_${dominioEmpresa}]`
          });
        });

      return;
    }
    else if( campo.texto === 'Cupom' ) {
      TelaEscolherCupomCampanhaComponent.abraComoDialog(this.dialogService, this.empresa, (cupom) => {
        this.insiraCampoNaCaixaDeTexto(textArea, campanha, {
          texto: `[CUPOM_${cupom}]`
        });
      });

      return;
    }

    this.insiraCampoNaCaixaDeTexto(textArea, campanha, campo);
    this.alterouMensagem(campanha)
  }

  insiraCampoNaCaixaDeTexto(textArea, campanha: any, campo: any) {
    textArea.focus();

    const posicao = this.getCaretPos(textArea);

    campanha.mensagem = campanha.mensagem.substr(0, posicao) + campo.texto + campanha.mensagem.substr(posicao);

    setTimeout( () => {
      textArea.setSelectionRange(posicao + campo.texto.length, posicao + campo.texto.length);
    }, 50);
  }

  alterouMensagem(notificacao: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = notificacao.mensagem.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    this.mensagemPossuiNomeContato = false
    this.mensagemPossuiLinkCardapio = false
    while ((result = reg.exec(notificacao.mensagem)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        this.mensagemPossuiNomeContato = true
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[LinkCartao]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if ( campo === '[Empresa]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[Link_Cardapio]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 30;
        this.mensagemPossuiLinkCardapio = true
      }
    }

    this.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - notificacao.mensagem.length + qtdARemover - qtdAAdicionar;
  }

  adicioneNomeContato(textArea, notificacao: any) {
    const posicao = this.getCaretPos(textArea);

    notificacao.mensagem = notificacao.mensagem.substr(0, posicao) + '[Nome_Contato]' + notificacao.mensagem.substr(posicao);
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      return oField.selectionStart;
    }

    return 0;
  }

  setCaretPosition(elem, caretPos) {
    if (elem !== null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        if (elem.setSelectionRange) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if( this.empresa.meioDeEnvio === 'Whatsapp' ) {
      this.qtdeCaracteresRestantes = 1000;
      this.QTDE_MAXIMA_CARACTERES = 1000;
    }
  }

  atualizeAltura() {
    this.resize(this.txtNovaIdeia.nativeElement);
  }
}
