import { Component, OnInit } from '@angular/core';
import {AutorizacaoService} from "../services/autorizacao.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ClienteService} from "../services/cliente.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  frmLogin: UntypedFormGroup;
  mensagemErro: string;
  enviado = false;
  urlDestino: string;
  empresa: any = {};
  enviando = false;

      constructor(private autorizacao: AutorizacaoService, private fb: UntypedFormBuilder, private route: ActivatedRoute,
              private router: Router, private clienteService: ClienteService) {
    this.frmLogin = this.fb.group({
      'email': ['', [
        Validators.required, Validators.email,
        Validators.minLength(5)
      ]],
      'senha': ['', [
        Validators.required
      ]]
    });

    this.route.queryParams.subscribe(params => {
      this.urlDestino = params['t'];
    });



    this.clienteService.obtenhaEmpresa().then( resposta => {
      this.empresa = resposta.empresa;
    });

  }

  get email() { return this.frmLogin.get('email'); }
  get senha() { return this.frmLogin.get('senha'); }

  ngOnInit() {
  }

  onSubmit() {
    delete  this.mensagemErro
    if ( !this.frmLogin.valid ) {
      this.enviado = true;
      this.mensagemErro = "Existem erros no preenchimento. Por favor, verifique e tente novamente!";
      window.scrollTo(0, 0);
      return;
    }

    this.enviando = true;
    let dados = this.frmLogin.value;

    this.autorizacao.login(dados.email, dados.senha).then((resposta: any) => {
      this.enviando = false;
      if(!resposta.erro){
        let link = this.urlDestino || '/admin/index';
        if(resposta.autenticar){
          this.urlDestino = link;
          link = `${resposta.redirect}?t=${this.urlDestino}`
        }
        this.router.navigateByUrl(link)
      } else {
        this.mensagemErro = resposta.erro
      }
    }).catch((err) => {
      console.error(err)
      this.enviando = false;
      this.mensagemErro = typeof err === 'string' ? err :   'Erro ao tentar fazer login.'
    })

  }

  vaParaRecuperarSenha($event) {

    this.router.navigateByUrl('/admin/recuperar', { state: { email: this.email.value }});
    $event.stopPropagation();
    $event.preventDefault();


  }
}
