import { Component, OnInit } from '@angular/core';
import {QrCodeService} from "../../services/qrcode.service";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-listagem-qrcode',
  templateUrl: './listagem-qrcode.component.html',
  styleUrls: ['./listagem-qrcode.component.scss'],
})
export class ListagemQrcodeComponent implements OnInit {
  qrcodes: any[] = [];
  encodeURIComponent = encodeURIComponent;
  carregando: boolean;
  showConfirmDialog: boolean;
  idQrCodeParaRemover: number;
  mensagemRemocao: any = '';

  constructor(private _location: Location, private router: Router, private qrCodeService: QrCodeService) {}

  ngOnInit(): void {
    this.carregarQrCodes().then( () => {});
  }

  async carregarQrCodes() {
    try {
      this.carregando = true;
      this.qrcodes = await this.qrCodeService.listarTodos();
    } finally {
      this.carregando = false;
    }
  }

  removerQrCode(id: number): void {
    this.qrCodeService.excluir(id).then(() => {
      this.carregarQrCodes().then( () => {});
    });
  }

  adicionarQrCode() {
    this.router.navigate(['/admin/qrcodes/adicionar']);
  }

  voltar() {
    this._location.back();
  }

  async removaQrCode(id: number): Promise<void> {
  }

  showConfirmationDialog(idQrCode: number) {
    this.showConfirmDialog = true;
    this.idQrCodeParaRemover = idQrCode;
  }

  onClose(confirmed: boolean) {
    if (confirmed) {
      this.qrCodeService.excluir(this.idQrCodeParaRemover).then(() => {
        // Remova o QR code da lista ou atualize a lista aqui
        this.mensagemRemocao = 'QR code removido com sucesso.';
        this.carregarQrCodes().then( () => {});
        setTimeout(() => {
          this.mensagemRemocao = null;
        }, 3000); // O tempo em milissegundos que a mensagem será exibida (5 segundos)
      }).catch(erro => {
        console.error('Erro ao remover QR code:', erro);
      });
    }
    this.showConfirmDialog = false;
  }
}
