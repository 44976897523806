import { NgModule } from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {PainelSDRComponent} from "./painel-sdr/painel-sdr.component";
import {TelaSdrComponent} from "./tela-sdr/tela-sdr.component";
import {UserResolver} from "../services/user.resolver";


const routes: Route[] = [
  {
    path: '', component: PainelSDRComponent,
    resolve: { user: UserResolver },
    children: [
      {path: 'index', pathMatch: 'full', component: TelaSdrComponent}
    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminSdrRoutingModule { }
