
<kendo-grid
  [kendoGridBinding]="promocoes"
  [loading]="carregando" style="min-height: 300px;"
  [scrollable]="false"
  footerStyle="font-size: 11px;"
  (edit)="editarPromocao($event.dataItem)"
  (remove)="abraDialogRemover($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="btn btn-blue" (click)="criarPromocao()">
      <i class="fe-plus mr-1"></i>
      Nova promoção
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhuma promoção "
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID"    width="70">
    <ng-template kendoGridCellTemplate let-promocao  >
      <span class="text-blue"><b>{{promocao.id}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Descrição">
    <ng-template kendoGridCellTemplate let-promocao >
      <span>{{promocao.descricao}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Data Início" field="dataInicio"   >
    <ng-template kendoGridCellTemplate let-promocao >
      {{promocao.dataInicio | date: "dd/MM/yyyy"}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Data Fim" field="dataInicio"   >
    <ng-template kendoGridCellTemplate let-promocao >
      {{promocao.dataFim | date: "dd/MM/yyyy"}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" width="150px">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-promocao>
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarPromocao(promocao)">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>
      <button kendoGridRemoveCommand kendoTooltip title="remover"><i class="fa fa-trash"></i></button>

      <button class="btn btn-xs btn-danger ml-1" (click)="desativarPromocao(promocao)"
              kendoTooltip title="Desativar Promoção" *ngIf="promocao.ativa" [disabled]="promocao.processando">
        <i class="far fa-pause-circle fa-lg">  </i>
      </button>
      <button class="btn btn-xs btn-primary ml-1" (click)="ativarPromocao(promocao)"
              kendoTooltip title="Ativar Promoção" *ngIf="!promocao.ativa" [disabled]="promocao.processando">
        <i class="far fa-play-circle fa-lg"  ></i>&nbsp;
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
</kendo-grid>


<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja remover a promoção
    "<strong>{{promocaoApagar.descricao}} - {{promocaoApagar.codigo}}</strong>"?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="confirmeRemocao()" primary="true">Sim</button>
    <button kendoButton (click)="canceleRemocao()">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
