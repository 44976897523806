<div *ngIf="relatorio1 && relatorio2">
  <span>
  {{descMes1}} x {{descMes2}}
  </span>
  <h3 *ngIf="campo === 'qtdeClientes' && relatorio1 && relatorio2" class="mb-1">
    {{relatorio1.qtdeClientes}} clientes x {{relatorio2.qtdeClientes}} clientes
  </h3>

  <h3 *ngIf="campo === 'totalGasto' && relatorio1 && relatorio2">
    <strong>{{relatorio1.totalGasto | currency: 'BRL' }}</strong> x
    <strong>{{relatorio2.totalGasto | currency: 'BRL' }}</strong>
  </h3>
  <kendo-chart style="height: 450px;">
    <ng-template kendoChartDonutCenterTemplate *ngIf="campo === 'qtdeClientes'">
      <h3 class="centro">{{relatorio2.qtdeClientes}} vs. {{relatorio1.qtdeClientes}}</h3>
    </ng-template>
    <ng-template kendoChartDonutCenterTemplate *ngIf="campo === 'totalGasto'">
      <h4 class="centro">
        <strong>{{relatorio2.totalGasto | currency: 'BRL' }}</strong> vs.
        <strong>{{relatorio1.totalGasto | currency: 'BRL' }}</strong>
      </h4>
    </ng-template>
    <kendo-chart-series>
      <kendo-chart-series-item [autoFit]="true" [name]="descMes1" [data]="data" [field]="campo" [holeSize]="60"
                               [type]="tipoGrafico" colorField="cor" categoryField="rotulo"
                               [overlay]="{gradient: 'roundedBevel'}">
        <kendo-chart-series-item-tooltip [ngClass]="{'teste': true}" [color]="'#333'" [background]="'#fafafa'" [class]="'teste'" [visible]="true">
          <ng-template let-value="value" let-category="category" let-series="series">
            <div style="height: 100%;position: absolute;left: 0px;right: 0px;top: 0px;width: 4px;" [style.background]="obtenhaCor({rotulo:category})" class="mb-2"></div>
            <div style="padding-left: 5px;" class="popup">
              <div class="mt-1">
                <p class="mt-0 mb-2">
                  <span style="font-size: 15px;" [style.color]="obtenhaCor({rotulo:category})">
                    <strong>{{ category }}</strong>
                  </span>
                  <span style="text-align: right;position:absolute;top: 10px;right: 10px;">{{series.name}}</span>
                  <br>
                  <span style="font-size: 16px;">{{ obtenhaRegistro(mapData, category).qtdeClientes }} clientes</span>
                  <br>
                </p>

                <div style="height: 1px;width: 100%;opacity: .3;" [style.background]="obtenhaCor({rotulo:category})" class="mb-2"></div>
              </div>
              <div class="row">
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Valor Gasto</p>
                  <p class="valor">
                    {{ obtenhaRegistro(mapData, category).totalGasto | currency: 'BRL' }}
                  </p>
                </div>
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Qtde. Vendas</p>
                  <p class="valor">
                    {{ obtenhaRegistro(mapData, category).qtdeVisitas }}
                  </p>
                </div>
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Ticket Médio</p>
                  <p class="valor">
                    {{ obtenhaRegistro(mapData, category).ticketMedio | currency: 'BRL' }}
                  </p>
                </div>
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Retorna em</p>
                  <p class="valor">
                    {{ obtenhaTempoRetornar(mapData, category) }} dias
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-chart-series-item-tooltip>
        <kendo-chart-series-item-labels
          position="outsideEnd"
          [content]="labelContent" *ngIf="tipoGrafico === 'column'">
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>

      <kendo-chart-series-item [autoFit]="true" [name]="descMes2" [data]="data2" [field]="campo" [holeSize]="120"
                               categoryField="rotulo" [type]="tipoGrafico" colorField="cor" [overlay]="{gradient: 'roundedBevel'}">
        <kendo-chart-series-item-tooltip [ngClass]="{'teste': true}" [color]="'#333'" [background]="'#fafafa'" [class]="'teste'" [visible]="true">
          <ng-template let-value="value" let-category="category" let-series="series">
            <div style="height: 100%;position: absolute;left: 0px;right: 0px;top: 0px;width: 4px;" [style.background]="obtenhaCor({rotulo:category})" class="mb-2"></div>
            <div style="padding-left: 5px;" class="popup">
              <div class="mt-1">
                <p class="mt-0 mb-2">
                  <span style="font-size: 15px;" [style.color]="obtenhaCor({rotulo:category})">
                    <strong>{{ category }}</strong>
                  </span>
                  <span style="text-align: right;position:absolute;top: 10px;right: 10px;">{{series.name}}</span>
                  <br>
                  <span style="font-size: 16px;">{{ obtenhaRegistro(mapData2, category).qtdeClientes }} clientes</span>
                  <br>
                </p>

                <div style="height: 1px;width: 100%;opacity: .3;" [style.background]="obtenhaCor({rotulo:category})" class="mb-2"></div>
              </div>
              <div class="row">
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Valor Gasto</p>
                  <p class="valor">
                    {{ obtenhaRegistro(mapData2, category).totalGasto | currency: 'BRL' }}
                  </p>
                </div>
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Qtde. Vendas</p>
                  <p class="valor">
                    {{ obtenhaRegistro(mapData2, category).qtdeVisitas }}
                  </p>
                </div>
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Ticket Médio</p>
                  <p class="valor">
                    {{ obtenhaRegistro(mapData2, category).ticketMedio | currency: 'BRL' }}
                  </p>
                </div>
                <div class="col-6 mb-1">
                  <p class="mb-1 rotulo">Retorna em</p>
                  <p class="valor">
                    {{ obtenhaTempoRetornar(mapData2, category) }} dias
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-chart-series-item-tooltip>
        <kendo-chart-series-item-labels
          [visible]="!ehCelular"
          position="outsideEnd"
          [content]="labelContent">
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="tipoGrafico === 'donut' && ehCelular" position="bottom">
    </kendo-chart-legend>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item name="liters per 100km"  [title]="{ text: campo === 'totalGasto' ? 'Receita em Reais' : 'Quantidade Clientes' }"
                                   [min]="0" [max]="maiorValor + (maiorValor / 8)" [majorUnit]="maiorValor / 5">
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
  </kendo-chart>
</div>
