import { Component, OnInit } from '@angular/core';
import {CaixaService} from "../services/caixa.service";

@Component({
  selector: 'app-historico-sangrias',
  templateUrl: './historico-sangrias.component.html',
  styleUrls: ['./historico-sangrias.component.scss']
})
export class HistoricoSangriasComponent implements OnInit {
  sangrias: any;
  agora = new Date();

  constructor(private caixaService: CaixaService) {
     caixaService.obtenhaHistoricoSangrias(0, 10).then((sangrias: any) => {
      this.sangrias = sangrias;
     }).catch((erro: any) => {
        console.log(erro);
     })
  }

  ngOnInit(): void {
  }

}
