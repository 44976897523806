import {ApplicationRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CadCartaocreditoComponent} from "../cad-cartaocredito/cad-cartaocredito.component";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {Location} from "@angular/common";
import {ContratoService} from "../../superadmin/services/contrato.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-pagamento-cartao',
  templateUrl: './pagamento-cartao.component.html',
  styleUrls: ['./pagamento-cartao.component.scss']
})
export class PagamentoCartaoComponent implements OnInit {
  @Input() contrato: any = {};
  @Input() fatura: any = {};
  @Input() modal: boolean
  @ViewChild('cccompoment' , { static: false }) cccompoment: CadCartaocreditoComponent;
  erro: any;
  constructor(private empresaService: EmpresasService, private _location: Location,
              private app: ApplicationRef,
              private  contratoService: ContratoService, private router: Router) { }
  pagando: boolean;
  pago: boolean;
  ngOnInit() {
    const { contrato, fatura } = window.history.state;

    if(contrato){
      this.contrato = contrato;
      this.fatura = fatura;
    }

    if(!this.contrato ||  !this.contrato.id){
      this.empresaService.obtenhaContrato().then( _contrato => {
        this.contrato = _contrato;
        this.fatura = _contrato.proximaFatura;
        this.faturaEstaPaga();
      })
    } else {
      setTimeout( () => {
        this.faturaEstaPaga();
      }, 0)
    }
  }

  faturaEstaPaga(){
    if(this.fatura.paga) {
      this.cccompoment.canceleProcessando();
      this.cccompoment.esconderPagar = true;
      this.app.tick();
    }
  }

  vinculeCartao(cartao: any) {
    this.fecheMensagemErro();

    if(this.pagando) return;

    this.pagando = true;
    this.contratoService.vinculeCartaoAssinatura(this.contrato, cartao, this.fatura).then( resp => {
      this.pagando = false;
      this.fatura.paga  = true;
      if(!this.modal){
        this.router.navigateByUrl('/admin/plano');
      } else {
        this.fatura.pago = true;
        this.faturaEstaPaga();
      }

    }).catch( erro => {
      this.erro = erro;
      this.pagando = false;
      this.cccompoment.canceleProcessando();
    })

  }

  fecheMensagemErro() {
    delete this.erro;
  }

  voltar() {
    this._location.back();
  }
}
