import * as Blockly from 'blockly';

export class FieldMeuCampo extends Blockly.FieldMultilineInput {
  constructor(text: string) {
    super(text);
  }

  showEditor_() {
    // A lógica do editor vai aqui.
    var input = window.prompt('Please enter a value', this.getValue());
    if (input !== null) {
      this.setValue(input);
    }
  }
}