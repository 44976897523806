import { Injectable } from '@angular/core';
import {object} from "twilio/lib/base/serialize";
import {PedidoLoja} from "../pedidos/objetos/PedidoLoja";
import {Entrega} from "../pedidos/objetos/Entrega";
import {Endereco} from "../pedidos/objetos/Endereco";
import {Pagamento} from "../pedidos/objetos/Pagamento";
import {FormaDePagamento} from "../pedidos/objetos/FormaDePagamento";
import {FormaDeEntrega} from "../pedidos/objetos/FormaDeEntrega";
import {SaborPizzaLoja} from "../pedidos/objetos/SaborPizzaLoja";
import {ArmazenamentoService} from "./armazenamento.service";

@Injectable({
  providedIn: 'root'
})
export class PedidoLocalService {
  private mapPedidos = {};
  constructor(private armazenamentoService: ArmazenamentoService) {

  }

  obtenhaChave(telefone: any) {
    return '_pedido_' + telefone;
  }

  possuiPedidoEmAndamento(telefone: string) {
    if(this.mapPedidos[telefone] && !this.mapPedidos[telefone].id) return true;

    let jsonPedido: any = this.armazenamentoService.carregue(this.obtenhaChave(telefone));

    if( jsonPedido ) {
      jsonPedido = JSON.parse(jsonPedido);
    }

    return jsonPedido != null && !jsonPedido.id;
  }

  apliqueMascaraCEP(string){
    if( !string ) {
      return null;
    }

    return string.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  }


  obtenhaPedido(telefoneCompleto: string) {

    let pedido = this.mapPedidos[telefoneCompleto];

    if( pedido == null ) {
      const jsonPedido = this.armazenamentoService.carregue(this.obtenhaChave(telefoneCompleto));

      if( jsonPedido ) {
        const objPedido = JSON.parse(jsonPedido);

        pedido = new PedidoLoja();

        pedido.id = objPedido.id
        pedido.codigo = objPedido.codigo
        pedido.cashback = objPedido.cashback
        pedido.integracao = objPedido.integracao
        pedido.pontosReceber = objPedido.pontosReceber
        pedido.timestamp = objPedido.timestamp
        pedido.balcao = objPedido.balcao

//        Object.assign(this.pedido, objPedido);

        if( objPedido.entrega ) {
          pedido.entrega = Object.assign(new Entrega(), objPedido.entrega);

          if( objPedido.entrega.endereco ) {
            const objEndereco = objPedido.entrega.endereco;

            pedido.entrega.endereco = Endereco.fromJson(objEndereco);

            if( objPedido.novosEnderecos ) {
              const enderecos = [];

              for( let i = 0; i < objPedido.novosEnderecos.length; i++ ) {
                let dadosEndereco = objPedido.novosEnderecos[i];

                if( dadosEndereco.id === pedido.entrega.endereco.id ) {
                  enderecos.push(pedido.entrega.endereco);
                  continue;
                }

                enderecos.push(Endereco.fromJson(dadosEndereco));
              }

              pedido.novosEnderecos = enderecos;
            }
          }
        }

        if( objPedido.mesa ) {
          pedido.mesa = objPedido.mesa;
        }

        if(objPedido.dataEntrega) {

          pedido.dataEntrega = new Date(objPedido.dataEntrega);
          pedido.horarioEntrega = new Date(objPedido.horarioEntrega);
        }

        if(objPedido.contato) {
          pedido.contato = objPedido.contato;
        }

        for( const item of objPedido.itens ) {
          if(!item.brinde)
            pedido.adicione(item.produto, item.qtde, item.observacao, item.adicionais, item.produtoTamanho, item.sabores);
          else pedido.itens.push(item);
        }

        if( objPedido.pagamentos ) {
          for( let objPagamento of objPedido.pagamentos ) {
            let pagamento = new Pagamento(objPagamento.id);
            Object.assign(pagamento, objPagamento)

            pedido.pagamentos.push(pagamento);
          }
        }

        pedido.desconto = objPedido.desconto || 0

        pedido.calculeTotal()
      }

      this.mapPedidos[telefoneCompleto] = pedido;
    }

    return pedido;
  }

  salvePedido(pedido: any, telefone: string = '') {
    if( ! (typeof pedido === 'object') ) {
      throw new Error('Objeto não é um pedido');
    }

    if( !telefone ) {
      if( pedido.contato && pedido.contato.telefone)
        telefone = pedido.contato.codigoPais + pedido.contato.telefone;
      else
        telefone = 'balcao'
    }

    this.mapPedidos[telefone] = pedido;

    this.armazenamentoService.salve(this.obtenhaChave(telefone), JSON.stringify(pedido));
  }

  salveUrlAtual(telefone: string, url: string, state: any) {
    this.armazenamentoService.salve('url_' + telefone, JSON.stringify({url: url, state: state} ))
  }

  remova(telefone: any) {
   this.armazenamentoService.remova(this.obtenhaChave(telefone));
   this.armazenamentoService.remova('url_' + telefone);
   delete this.mapPedidos[telefone];
  }

  removaPedido(telefone: any) {
    this.remova(telefone)
  }

  removaUrlSalva(telefone: any) {
    this.armazenamentoService.remova('url_' + telefone);
  }

  public obtenhaPedidoLojaPeloPedidoServer(pedido: any): PedidoLoja {
    let pedidoLoja = new PedidoLoja()
    pedidoLoja.id = pedido.id
    pedidoLoja.codigo = pedido.codigo
    pedidoLoja.contato = { id: pedido.cliente.id, nome: pedido.cliente.nome,
      codigoPais: pedido.cliente.codigoPais,
      telefone: pedido.cliente.telefone  }
    pedidoLoja.mesa = pedido.mesa;

    if(!pedido.cliente.telefone && pedido.cliente.naoIdentificado)
      pedido.cliente.telefone = "00000000000"

    if(pedido.pagamentos) {

      pedido.pagamentos.forEach( (pagamento: any) => {
        if(pagamento.formaDePagamento.nome !== 'cashback'){
          const novoPagamento = new Pagamento(pagamento.id);
          novoPagamento.valor = pagamento.valor;
          novoPagamento.taxa = pagamento.taxa;
          novoPagamento.desconto = pagamento.desconto;
          pedidoLoja.pagamentos.push(novoPagamento);
          novoPagamento.formaDePagamento = Object.assign(new FormaDePagamento(), pagamento.formaDePagamento)
          //pedidoLoja.pagamento.formaDePagamento =
//            pagamento.formaDePagamento.startsWith('cartao') ? TipoDePagamentoEnum.Cartao : TipoDePagamentoEnum.Dinheiro

          if(novoPagamento.formaDePagamento.ehPagamentoEmDinheiro()) {
            pedidoLoja.troco = pedido.troco

            novoPagamento.trocoPara = pagamento.trocoPara ? pagamento.trocoPara : 0;
            pedidoLoja.calculeTroco();
            novoPagamento.temTroco =  pedidoLoja.troco > 0 ? 'sim' :  'nao';
          }
        } else {
          pedidoLoja.setCashbackPagamento(pagamento, pedido.cliente.cartoes)
        }
      })
    }

    if(!pedido.formaDeEntrega || pedido.formaDeEntrega === '') {
      if(pedido.endereco) pedido.formaDeEntrega = FormaDeEntrega.RECEBER_EM_CASA
      else pedido.formaDeEntrega = FormaDeEntrega.RETIRAR
    }

    pedidoLoja.entrega = new Entrega()
    pedidoLoja.entrega.formaDeEntrega = pedido.formaDeEntrega

    if(pedido.endereco) {
      pedidoLoja.entrega.endereco = Endereco.fromJson(pedido.endereco)

      if(pedido.endereco.cep)
        pedidoLoja.entrega.endereco.cep = this.apliqueMascaraCEP(pedido.endereco.cep)
    }

    if(pedido.itens ) {
      for( const item of pedido.itens ) {
        if(!item.troca)
          pedidoLoja.adicione(item.produto, item.qtde, item.observacao, item.adicionais,
            item.produtoTamanho, SaborPizzaLoja.obtenhaPeloServer(item.sabores) );
        //TODO: ao editar, verificar como vem os itens adicionais
        else
          pedidoLoja.itens.push(item)
      }
    }

    if( pedido.taxaEntrega >= 0 )
      pedidoLoja.entrega.taxaDeEntrega = pedido.taxaEntrega


    if(pedido.horarioEntregaAgendada) {
      let horarioAgendado = new Date(pedido.horarioEntregaAgendada)
      pedidoLoja.dataEntrega = new Date(horarioAgendado.toDateString())
      pedidoLoja.horarioEntrega = new Date()
      pedidoLoja.horarioEntrega.setHours(horarioAgendado.getHours())
      pedidoLoja.horarioEntrega.setMinutes(horarioAgendado.getMinutes())
    }

    pedidoLoja.balcao = pedido.balcao
    pedidoLoja.desconto = pedido.desconto
    pedidoLoja.observacoes = pedido.observacoes
    pedidoLoja.pontosReceber = pedido.pontosGanhos

    return pedidoLoja;
  }
}
