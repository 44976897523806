<h4   class="page-title"><i class="fa fa-table"></i> Escolha a Mesa para Abrir</h4>

<div class="card ">
  <div class="card-body">

    <kendo-textbox class="busca" name="filtro" placeholder="Para buscar uma mesa só digitar aqui" [(ngModel)]="filtro" [showSuccessIcon]="false" style="height: 36px;">
      <ng-template kendoTextBoxSuffixTemplate>
        <kendo-textbox-separator></kendo-textbox-separator>
        <button kendoButton [look]="'clear'" [icon]="'search'"></button>
      </ng-template>
    </kendo-textbox>

    <div class="row mt-3">
      <ng-container  *ngFor="let mesa of mesas | filtroObjetosNome: filtro">
        <div class="alert alert-warning mt-2" *ngIf="mesa === -1">
          <h4>
            <i class="fas fa-exclamation-triangle"></i>
            Nenhuma {{empresa.identificadorMesa}} encontrada para <strong>{{filtro}}</strong>
          </h4>
        </div>
        <div class="col-6 col-sm-auto mesa">
          <div class="card" *ngIf="mesa !== -1">
            <div class="card-header d-flex justify-content-center" [class.disponivel]="mesa.disponivel" [class.indisponivel]="!mesa.disponivel">
              <h4 class="mt-0 mb-0 font-16" title="{{mesa.nome}}">
                <b>{{mesa.nome}}</b>
              </h4>

            </div>
            <div class="card-body " style="height: 100px;">
              <div class="row no-gutters d-flex align-items-center justify-content-center">
                <div class="col">
                  <div class="" *ngIf="mesa.disponivel">
                    <p class="card-text">
                      <button class="btn btn-primary mt-1" (click)="mesaEscolhida(mesa)">Escolher</button>
                    </p>
                  </div>
                  <div *ngIf="!mesa.disponivel">
                    <i class="far fa-times-circle h1  mb-0" style="color: #ff3535;"></i>
                  </div>
                </div>
              </div>

              <div *ngIf="empresa?.integracaoDelivery?.sistema ==='ecletica'" class="mt-1 row">
                <div class="col">
                  <ng-container  *ngIf="carregandoMesasIntegradas">
                    <b> {{empresa.integracaoDelivery.sistema }}:</b>
                    <i class="k-i-loading k-icon ml-2"></i>
                  </ng-container>

                  <span  class="ml-2 badge " *ngIf="!carregandoMesasIntegradas &&  mesasIntegracao.length "
                         [ngClass]="{'badge-success':mesa.mesaIntegrada?.status ==='Livre',
                                                        'badge-danger':mesa.mesaIntegrada?.status ==='EmUso',
                                                        'badge-warning':mesa.mesaIntegrada?.status ==='Fechando',
                                                        'badge-light': !mesa.codigoPdv || ( mesa.codigoPdv && !mesa.mesaIntegrada)}">

                    <ng-container *ngIf="mesa.mesaIntegrada">  <b> {{mesa.mesaIntegrada.status}}</b></ng-container>
                    <ng-container *ngIf=" !mesa.codigoPdv">Não integrada</ng-container>
                    <ng-container *ngIf=" mesa.codigoPdv && !mesa.mesaIntegrada">Codigo pdv invalido: <b>{{mesa.codigoPdv}}</b></ng-container>
                </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</div>
