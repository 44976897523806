<div class="row" *ngIf="visualizaRelatorios()">
  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box  com-borda">
      <div class=" ribbon ribbon-dark float-left">
        <span>Total</span>
      </div>
      <h5 class="text-info float-right mt-0 total">
        {{resumoPedidos.qtde | number}}
      </h5>

      <div class="ribbon-content">
        <p></p>
      </div>

    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box ">
      <div class=" ribbon ribbon-success float-left">
        <span>Valor </span>
      </div>
      <div class="text-info float-right mt-0 total">
        {{resumoPedidos.total | currency: "BRL"}}
      </div>

      <div class="ribbon-content">
        <p></p>
      </div>

    </div>
  </div>

  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box ">
      <div class=" ribbon ribbon-warning float-left">
        <span>Taxas </span>
      </div>
      <div class="text-info float-right mt-0 total">
        {{resumoPedidos.totalTaxas | currency: "BRL"}}
      </div>

      <div class="ribbon-content">
        <p></p>
      </div>

    </div>
  </div>
</div>

<div class="row">
  <div class="mb-2">
    <div class="row">
      <div class="col-auto">
        <kendo-formfield>
          <kendo-textbox placeholder="Busque por nome ou telefone do cliente ou por código"
                         name="txtFiltro" [(ngModel)]="objFiltro.q"
                         [clearButton]="true" style="width: 500px"
                         appAutoFocus (afterValueChanged)="onFilter($event)">
            <ng-template kendoTextBoxSuffixTemplate>
              <button kendoButton [look]="'clear'" [icon]="'search'" (click)="onFilter($event)"></button>
            </ng-template>
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col">
        <label class="mr-3">Período: </label>
        <kendo-daterange   >
          <label>
            <span class="label"></span>
            <kendo-dateinput kendoDateRangeStartInput [(ngModel)]="filtro.inicio"  (ngModelChange)="onFilter($event)"></kendo-dateinput>
          </label>
          <label>
            <span class="label ml-2 mr-2">Até </span>
            <kendo-dateinput kendoDateRangeEndInput [(ngModel)]="filtro.fim" (ngModelChange)="onFilter($event)" ></kendo-dateinput>
          </label>
        </kendo-daterange>
      </div>
    </div>
  </div>
</div>


<app-grid-pedidos #gridPedidos [pedidos]="pedidos" [carregando]="carregando" [mesa]="mesa"></app-grid-pedidos>
