import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresaService} from "../../services/empresa.service";

@Component({
  selector: 'app-form-integracao-ifood-centralizado',
  templateUrl: './form-integracao-ifood-centralizado.component.html',
  styleUrls: ['./form-integracao-ifood-centralizado.component.scss']
})
export class FormIntegracaoIfoodCentralizadoComponent implements OnInit {
  @ViewChild('frm', {}) frm:  any;
  @Input() empresa: any;
  @Input() integracaoIfood: any = {};
  mensagemErro: string;
  salvando: boolean;
  confirmarRemover: boolean;
  removendo: boolean;
  constructor(private empresaService: EmpresaService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    delete this.mensagemErro;

    if (this.frm.valid) {
      this.salvando = true;
      this.empresaService.salveIntegracaoIfood(this.empresa, this.integracaoIfood).then((integracao: any) => {
        this.integracaoIfood = integracao;
       // this.empresa.integracaoIfood = this.integracaoIfood;
        this.integracaoIfood.editar = false;
        this.salvando = false;
      }).catch( (erro) => {
        this.mensagemErro = erro;
        this.salvando = false
      })
    }
  }

  removerIntegracao() {

    this.removendo = true;

    this.empresaService.removaIntegracaoIfood(this.empresa).then(  () => {
      this.removendo = false;
      this.integracaoIfood.removida = true;
    }).catch((erro) => {
      this.removendo = false;
      alert(erro)

    })

  }

  confirmeRemover(){
    this.confirmarRemover = true;
  }

  canceleRemover() {
    this.confirmarRemover = false;
  }

  fecharAlerta(){
    this.mensagemErro = null
  }
}
