import {Injectable} from '@angular/core';
import {EnumVencimentoArmazenamento} from "../pedidos/objetos/EnumVencimentoArmazenamento";

@Injectable({
  providedIn: 'root'
})
export class ArmazenamentoService {
  static CHAVE_VENCIMENTO = "expiry_"
  constructor() { }

  private static passou24horas(date: Date)  {
    let tempoVerificar = date.getTime();
    let vinteQuatroHoras =  24 * 1000 * 60 * 60;
    let vinteQuatroHorasAtras = Date.now() - vinteQuatroHoras;

    return tempoVerificar < vinteQuatroHorasAtras;
  }


  private static determineVencimento(chave: any, quandoVence: EnumVencimentoArmazenamento): string {
    let vencimentoStr

    if(quandoVence === EnumVencimentoArmazenamento.NUNCA)
      vencimentoStr = EnumVencimentoArmazenamento.NUNCA
    else {
      let vencimento = new Date()
      vencimento.setDate(vencimento.getDate() + 3)
      vencimentoStr = vencimento.toISOString()
    }

    localStorage.setItem(ArmazenamentoService.CHAVE_VENCIMENTO + chave, vencimentoStr)

    return vencimentoStr
  }

  private static obtenhaDataChaveVencimento(chaveVencimento: any) {
    let vencimentoStr = localStorage.getItem(chaveVencimento)

    if(!vencimentoStr || vencimentoStr === EnumVencimentoArmazenamento.NUNCA) return null

    return new Date(vencimentoStr)
  }

  private static obtenhaDataVencimento(chave: any, determinarAgora: boolean = true,
                                       quandoVence: EnumVencimentoArmazenamento = EnumVencimentoArmazenamento.TRES_DIAS) {
    let vencimento = ArmazenamentoService.obtenhaDataChaveVencimento(ArmazenamentoService.CHAVE_VENCIMENTO
      + chave)

    if(vencimento || !determinarAgora)
      return vencimento

    let vencimentoStr = ArmazenamentoService.determineVencimento(chave, quandoVence)

    return new Date(vencimentoStr);
  }

  salveSemExpirar (chave: any, valor: string) {
    this.salve(chave, valor, EnumVencimentoArmazenamento.NUNCA);
  }

  salve(chave: any, valor: string, quandoVence: EnumVencimentoArmazenamento = EnumVencimentoArmazenamento.TRES_DIAS ) {

    localStorage.setItem(chave, valor)

    ArmazenamentoService.determineVencimento(chave, quandoVence)
  }

  carregue(chave: any, determinarAgora: boolean = true,
           quandoVence: EnumVencimentoArmazenamento = EnumVencimentoArmazenamento.TRES_DIAS): string {
    //obtem o objeto da chave e, caso ele não tenha data de vencimento, determina a data de vencimento
    let vencimento = ArmazenamentoService.obtenhaDataVencimento(chave, determinarAgora, quandoVence);

    if(vencimento && new Date() > vencimento) {
      this.remova(chave) //removendo itens já vencidos
      return null
    }

    return localStorage.getItem(chave)
  }


  remova(chave: any) {
    localStorage.removeItem(chave)
    localStorage.removeItem( ArmazenamentoService.CHAVE_VENCIMENTO + chave)
  }

  removaPorChaveVencimento(chaveVencimento: any) {
    let chave = chaveVencimento.replace(ArmazenamentoService.CHAVE_VENCIMENTO, "")
    this.remova(chave)
  }




  removaExpirados() {
    console.log("*** Verificando se precisa rodar ***")
    let ultimaExecucao = localStorage.getItem("removaExpirados_ultima_execucao")

    if(ultimaExecucao) {
      let dataUltimaExecucao = new Date(ultimaExecucao)
      console.log("dataUltimaExecucao", dataUltimaExecucao )
      if(!ArmazenamentoService.passou24horas(dataUltimaExecucao)) {
        console.log("*** Foi executado nas últimas 24 horas... não precisa executar ***")
        return;
      }

    }

    console.log("*** Removendo itens expirados ***")
    let agora = new Date()
    for (let i = 0; i < localStorage.length; i++){
      let chave = localStorage.key(i);

      if(!chave.startsWith(ArmazenamentoService.CHAVE_VENCIMENTO))
        continue;

      let vencimento =  ArmazenamentoService.obtenhaDataChaveVencimento(chave)

      if(vencimento && agora > vencimento) {
        console.log("Removendo chave expirada " + chave)
        this.removaPorChaveVencimento(chave)
      }


    }

    localStorage.setItem("removaExpirados_ultima_execucao", new Date().toISOString())

  }

}
