import { Injectable } from '@angular/core';
import {ServerService} from "../../services/ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CaixaService extends ServerService {

  constructor(http: HttpClient) {
    super(http);
  }

  //abre um caixa na empresa para o usuário logado
  abraCaixa(saldoInicial: number){
    return this.facaPost('/caixa/abra', {saldoInicial: saldoInicial});
  }


  obtenhaCaixaAtual(online: boolean = false) {
    return this.obtenha('/caixa', {online: online});
  }

  //obtem caixa com id informado
  obtenhaCaixa(id: number){
    return this.obtenha('/caixa/' + id, {});
  }

  //obtem histórico paginado de caixas
  obtenhaHistoricoCaixas(item: number, tamanho: number){
    return this.obtenha('/caixa/historico', {item: item, tamanho: tamanho});
  }

  //obtem historico paginado de sangrias
  obtenhaHistoricoSangrias(item: number, tamanho: number){
    return this.obtenha('/caixa/sangrias', {item: item, tamanho: tamanho});
  }

  //realiza uma sangria no caixa com uma justificativa
  sangreCaixa(valorSangria: number, justificativa: string){
    return this.facaPost('/caixa/sangre', {valorSangria: valorSangria, justificativa: justificativa});
  }

  podeFecharCaixa(){
    return this.obtenha('/caixa/pode-fechar', {});
  }


  fecheCaixa(caixa: any, valorFechamento: number){
    return this.facaPost('/caixa/feche', {valorFechamento: valorFechamento, cid: caixa.id});
  }
}
