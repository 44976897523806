import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PlanosService extends ServerService {

  constructor(http: HttpClient) {
    super(http);
  }

  listePlanos(): Promise<any> {
     return this.obtenha('/planos',{ });
  }

  listeBrindes(plano: any): Promise<any> {
    return this.obtenha(`/planos/${plano.id}/brindes`,{ });
  }

}
