<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        2020 Promokit
      </div>
      <div class="col-md-6" style="display: none;">
        <div class="text-md-right footer-links d-none d-sm-block">
          <a href="javascript:void(0);"></a>
          <a href="javascript:void(0);"></a>
          <a href="javascript:void(0);"></a>
        </div>
      </div>
    </div>
  </div>
</footer>
