import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ConfigMensagensBotService} from "../../services/config-mensagens-bot.service";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-form-config-mensagem-bot',
  templateUrl: './form-config-mensagem-bot.component.html',
  styleUrls: ['./form-config-mensagem-bot.component.scss']
})
export class FormConfigMensagemBotComponent implements OnInit {
  @Input()
  public configMensagemDeBot: any = {}
  campos = [{
    nome: 'Nome do Contato',
    texto: '[NomeContato]',
  }, {
    nome: 'Nome Completo Contato',
    texto: '[NomeCompletoContato]',
  }, {
    nome: 'Telefone do Contato',
    texto: '[TelefoneContato]',
  },
    {
    nome: 'Empresa',
    texto: '[Empresa]',
  }, {
    nome: 'Link Cardápio',
    texto: '[Link_Cardapio]'
  }, {
      nome: 'Link Avaliar Pedido',
      texto: '[Link_Avaliar]'
  }];
  QTDE_MAXIMA_CARACTERES = 1000;

  empresa: any;
  items: any;

  mensagem: any = '';
  mensagemSucesso: any = '';


  constructor(private configMensagensBotService: ConfigMensagensBotService, private autorizacaoService: AutorizacaoService) { }

  ngOnInit(): void {
    this.items = [
      {label: 'Nome Contato', icon: 'pi pi-fw pi-plus'},
      {label: 'Nome Empresa', icon: 'pi pi-fw pi-download'},
      {label: 'Undo', icon: 'pi pi-fw pi-refresh'}
    ]

    let usuario: any = this.autorizacaoService.getUser();
    this.empresa = usuario.empresa;
    this.alterouMensagem(this.configMensagemDeBot);
  }



  onSubmit(frm: NgForm, configMensagemBot: any) {
    if ( !frm.valid ) {
      alert('O formulário não está válido')
      return;
    }

    this.configMensagensBotService.salveConfigMensagem(configMensagemBot).then( resp =>   {
      configMensagemBot.mensagemSucesso = 'Configuração salva com sucesso!';
    });
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = '';
  }

  adicioneCampo(textArea, configMensagem: any, campo: any) {
    const posicao = this.getCaretPos(textArea);

    configMensagem.mensagem = configMensagem.mensagem.substr(0, posicao) + campo.texto + configMensagem.mensagem.substr(posicao);

    textArea.focus();

    setTimeout( () => {
      textArea.setSelectionRange(posicao + campo.texto.length, posicao + campo.texto.length);
    }, 50);
  }

  setCaretPosition(elem, caretPos) {
    if (elem !== null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        if (elem.setSelectionRange) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    }
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      return oField.selectionStart;
    }

    return 0;
  }

  alterouMensagem(configMensagemBot: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = configMensagemBot.mensagem.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(configMensagemBot.mensagem)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[Empresa]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      }
    }

    configMensagemBot.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES -
      configMensagemBot.mensagem.length + qtdARemover - qtdAAdicionar;
  }
}
