import { Component, OnInit } from '@angular/core';
import {TaxasDeEntregaCalculadasService} from "../../services/taxas-de-entrega-calculadas.service";
import {process, State} from "@progress/kendo-data-query";
import {DataStateChangeEvent, PageChangeEvent} from "@progress/kendo-angular-grid";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'app-tela-taxas-de-entregas-calculadas',
  templateUrl: './tela-taxas-de-entregas-calculadas.component.html',
  styleUrls: ['./tela-taxas-de-entregas-calculadas.component.scss']
})
export class TelaTaxasDeEntregasCalculadasComponent implements OnInit {
  view: any;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  carregando = false;
  empresa: any;
  public windowTop = 100;
  public windowLeft = 50;
  public altura = 0;
  exibindoMapa: boolean;
  taxaSelecionada: any;

  constructor(private constantsService: ConstantsService, private taxasDeEntregaCalculadasService: TaxasDeEntregaCalculadasService) {

  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) {
        return;
      }

      this.empresa = empresa;

      this.carregueTaxas();
    });
  }

  public onPageChange(e: PageChangeEvent): void {
    this.carregueTaxas();
  }

  carregueTaxas() {
    this.carregando = true;
    this.taxasDeEntregaCalculadasService.liste(this.gridState.skip, this.gridState.take).then( (resposta) => {
      const origem = this.empresa.latitudeLongitude;
      for( const taxa of resposta.taxas ) {
        const destino = taxa.localizacao;
        const url = `https://www.google.com/maps/dir/?api=1&origin=${origem}&destination=${destino}&travelmode=driving`;
        taxa.linkMapa = url;
      }

      this.view = {
        data: resposta.taxas,
        total: resposta.total
      };
      this.carregando = false;
    });
  }

  public onStateChange(state: State) {
    this.gridState = state;
  }

  exibaMapa(taxa) {
    this.exibindoMapa = true;
    if( taxa.fazEntrega ) {
      this.taxaSelecionada = {
        localizacao: taxa.localizacao,
        msg: "Dentro da área de entrega: " + new Intl.NumberFormat('pt-BR',
          {style: 'currency', currency: 'BRL'}).format(taxa.valor)
      };
    } else {
      this.taxaSelecionada = {
        localizacao: taxa.localizacao,
        msg: taxa.erro
      };
    }

    return false;
  }

  fecharTelaMapa() {
    this.exibindoMapa = false;
  }
}
