import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormaDePagamento} from "../objetos/FormaDePagamento";
import {Pagamento} from "../objetos/Pagamento";
import {ControlContainer, NgForm} from "@angular/forms";
import {TaxaCobranca} from "../../../../projects/loja/src/objeto/TaxaCobranca";

@Component({
  selector: 'app-grid-pagamento',
  templateUrl: './grid-pagamento.component.html',
  styleUrls: ['./grid-pagamento.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class GridPagamentoComponent implements OnInit {
  @Input()  formasDePagamento = [];
  @Input() totalPagar = 0;
  @Input() totalProdutos = 0;
  pagamentos = [];
  @Output() mudouFormaPagamento = new EventEmitter();
  @Output() alterouOpcaoMultiplasFormas = new EventEmitter();
  multiplasFormasPagamentos = false;
  @Input() frm: NgForm;
  public selectableSettings: any = {
    checkboxOnly: false,
    mode: 'single',
    drag: false,
  };
  public mySelection: number[] = [];
  public formaDePagamentoEscolhida: any = {
    id: null,
    descricao: ``,
    valor: 0.0,
    trocoPara: 0
  };
  msgErroAdicionar = '';
  pagamentoEmEdicao: any;
  troco = 0;
  trocoPara = 0;
  temTroco =  'nao';

  constructor() { }

  ngOnInit(): void {

  }

  selecionouForma($evento) {
    this.pagamentoFoiSelecionado($evento.selectedRows[0].dataItem);
  }

  pagamentoFoiSelecionado(formaDePagamentoEscolhida: any) {
    this.formaDePagamentoEscolhida = formaDePagamentoEscolhida;

    let pagamentoSelecionado = this.obtenhaPagamentoSelecionado(this.formaDePagamentoEscolhida);

    if( pagamentoSelecionado )
      this.editarPagamento(pagamentoSelecionado)
    else{
      this.formaDePagamentoEscolhida.valor = this.obtenhaValorRestante()
    }

  }

  obtenhaPagamentoSelecionado(formaDePagamento: FormaDePagamento) {
    for( let pagamento of this.pagamentos ) {
      if( pagamento.formaDePagamento.id === formaDePagamento.id ) {
        return pagamento;
      }
    }

    return null;
  }

  editarPagamento(pagamento: any) {
    delete this.msgErroAdicionar;
    this.pagamentoEmEdicao = Object.assign({}, pagamento);
    this.formaDePagamentoEscolhida = this.formasDePagamento.find((item: any) => item.id === pagamento.formaDePagamento.id)


    if(pagamento.id)
      this.formaDePagamentoEscolhida.valor = pagamento.trocoPara ?  pagamento.trocoPara  : pagamento.valor;

    this.mySelection = [this.formaDePagamentoEscolhida.id];
    this.calculeTroco();
  }

  removaPagamento(pagamento: any) {

    if(pagamento.id) {
      pagamento.removido = true
      pagamento.valorRemovido =    pagamento.valor ;
      pagamento.valor = 0;
      pagamento.trocoPara = 0;
      pagamento.desconto = 0;
      pagamento.taxa = 0;
    }
    else {
      let indicePagamento: number;

      for(let i = 0; i < this.pagamentos.length; i++)
        if(this.pagamentos[i].formaDePagamento.id === pagamento.formaDePagamento.id )
          indicePagamento = i;

      if(indicePagamento >= 0) this.pagamentos.splice(indicePagamento, 1);
    }
  }


  desremoverPagamento(pagamento: any){
    if(pagamento.valorRemovido <= this.obtenhaValorRestanteAhPagar()){
      pagamento.removido = false;
      pagamento.valor  = pagamento.valorRemovido;
    }

  }

  adicionePagamentoUnico() {
    let pagamento = this.obtenhaPagamentoUnico();

    if(!pagamento){
      pagamento = new Pagamento()
      this.pagamentos.push(pagamento)
    }

    delete pagamento.removido;
    pagamento.valor = this.totalPagar;
    pagamento.taxa = 0;
    pagamento.formaDePagamento = this.formaDePagamentoEscolhida;

    if( this.formaDePagamentoEscolhida.taxaCobranca)
      pagamento.setValorComTaxa(this.formaDePagamentoEscolhida.taxaCobranca)

    if(!this.pagarEmDinheiro())
      pagamento.trocoPara = 0;

    this.calculeTroco();
    this.mudouFormaPagamento.emit();
  }

  adicioneFormaDePagamento() {
    this.msgErroAdicionar = '';
    if( !this.formaDePagamentoEscolhida.id ) {
      this.msgErroAdicionar = 'Selecione uma forma de pagamento ';
      return;
    }

    let pagamento: any =
      this.obtenhaPagamentoSelecionado(this.formaDePagamentoEscolhida);

    const valorAPagar = this.obtenhaValorRestanteAhPagar(pagamento);

    if(!pagamento && valorAPagar === 0 ) {
      this.msgErroAdicionar = 'Pedido já tem valor suficiente para ser pago.';
      return;
    }

    if( !this.formaDePagamentoEscolhida.valor || this.formaDePagamentoEscolhida.valor <= 0.0 ){
      if(!this.pagamentoEmEdicao || !this.pagamentoEmEdicao.id){
        this.msgErroAdicionar = 'Valor do pagamento está inválido: ' + this.formaDePagamentoEscolhida.valor
        return;
      }
    }

    if(!pagamento){
      pagamento =  new Pagamento();
      this.pagamentos.push(pagamento)
    } else {
      delete  pagamento.removido;
    }

    pagamento.formaDePagamento = this.formaDePagamentoEscolhida;

    //por equanto multipagametno sem desconto
    //if(pagamento.formaDePagamento && pagamento.formaDePagamento.possuiDesconto ){
     //  let valorDesconto = Math.min(this.formaDePagamentoEscolhida.valor, this.totalProdutos);

     //  pagamento.desconto = Number(( valorDesconto * (pagamento.formaDePagamento.desconto / 100)).toFixed(2))
   // }


    if(this.pagarEmDinheiro()){
      pagamento.trocoPara = this.formaDePagamentoEscolhida.valor;
      pagamento.valor =  this.formaDePagamentoEscolhida.valor > valorAPagar ? valorAPagar :  this.formaDePagamentoEscolhida.valor;
    } else {
      pagamento.valor = this.formaDePagamentoEscolhida.valor;

      if(pagamento.valor > valorAPagar  ) {
        this.msgErroAdicionar = 'Valor não pode ser maior do que valor restante (R$ ' +
          valorAPagar.toFixed(2) + ')';

        return;
      }
    }

    let taxaCobranca = this.formaDePagamentoEscolhida.taxaCobranca;

    if(taxaCobranca)
      pagamento.setValorComTaxa(taxaCobranca)

    this.deseslecionePagamento();
  }



  removaPagamentos(){
    this.pagamentos = this.pagamentos.filter((item: any) => item.id);

    this.pagamentos.forEach((pagamento: any) => {
      this.removaPagamento(pagamento)
    })
  }

  limpePagamentos(){
    this.removaPagamentos();
    this.deseslecionePagamento();
  }

  deseslecionePagamento(){
    delete this.formaDePagamentoEscolhida;
    delete  this.pagamentoEmEdicao;
    this.mySelection = []
    this.formaDePagamentoEscolhida = {
      id: null,
      descricao: ``,
      valor: 0.0,
      trocoPara: 0
    };
  }

  temFormaDePagamento(pagamento: Pagamento) {
    let indicePagamento: number;

    for(let i = 0; i < this.pagamentos.length; i++)
      if(this.pagamentos[i].formaDePagamento.id === pagamento.formaDePagamento.id ||
        (pagamento.id && this.pagamentos[i].id === pagamento.id))
        indicePagamento = i;


    return indicePagamento >= 0;

  }



  obtenhaValorRestante(){
    let pagamento = this.pagamentoEmEdicao ||
      this.obtenhaPagamentoSelecionado(this.formaDePagamentoEscolhida);

    let valorAPagar = this.obtenhaValorRestanteAhPagar(pagamento);

    return Number(valorAPagar.toFixed(2));
  }

  obtenhaValorRestanteAhPagar(pagamentoEditando: any = null) {
    let valor = this.totalPagar

    let totalPago = 0.0;
    for(let pagamento of this.pagamentos) {
      if(pagamento.removido) continue;
      if(!pagamentoEditando || pagamentoEditando.id !== pagamento.id  ){
        totalPago += pagamento.valor;
        if(pagamento.taxa) totalPago -= pagamento.taxa
      }

    }

    valor -= totalPago;

    if( valor < 0 ) {
      valor = 0;
    }

    return Number(valor.toFixed(2));
  }

  pagarEmDinheiro(){
    return this.formaDePagamentoEscolhida && this.formaDePagamentoEscolhida.nome === 'dinheiro'
  }

  novoValorPagar(novoTotal: number){
    if(this.totalPagar === novoTotal) return;

    this.totalPagar = novoTotal;
    if(this.multiplasFormasPagamentos)
      this.limpePagamentos();
    else {
      let pagamento = this.obtenhaPagamentoUnico();

      if(pagamento) pagamento.valor = this.totalPagar
    }
  }

  obtenhaPagamentoUnico(){
    return this.pagamentos.find((item: any) => !item.removido);
  }

  obtenhaErroPagamento(){
    if(!this.frm.valid)
      return ''

    if(!this.pagamentos.length)
      return  this.multiplasFormasPagamentos ? 'Adicione uma forma de pagamento ' : 'Escolha uma forma de pagamento ';

    if(this.multiplasFormasPagamentos) {
      let valorRestante = this.obtenhaValorRestante();

      if(valorRestante > 0) return 'Ainda está faltando adicionar R$ ' + valorRestante + ' no pagamento'

    }

    return null;
  }

  calculeTroco() {
    this.troco = 0;

    if (this.pagarEmDinheiro() ) {
      if(this.multiplasFormasPagamentos)
        this.troco = Number (( this.formaDePagamentoEscolhida.valor - this.obtenhaValorRestante()).toFixed(2));
      else{
        let pagamento = this.obtenhaPagamentoUnico();

        if(this.trocoPara){
          pagamento.trocoPara = this.trocoPara;
          this.troco =   Number( (this.trocoPara - pagamento.valor).toFixed(2))
        }
      }


      if (this.troco < 0) this.troco = 0;

    }
  }

  formasDePagamentoDisponiveis() {
    return this.formasDePagamento.filter((item: any) => !item.online && item.nome !== 'cashback')
  }

  mudouMultiplasFormas() {
     //if(this.pagamentos.length > 1)
     this.limpePagamentos();

    this.alterouOpcaoMultiplasFormas.emit()
  }

  setPagamentos(pagamentos: Array<Pagamento>) {
    this.pagamentos = pagamentos;

    this.multiplasFormasPagamentos = this.pagamentos.filter((item: any) => !item.removido).length > 1;

    if(!this.multiplasFormasPagamentos){
      let pagamento   =  this.obtenhaPagamentoUnico();

      if(pagamento){
        pagamento.valor = this.totalPagar;
        this.formaDePagamentoEscolhida =
          this.formasDePagamento.find((item: any) => item.id === pagamento.formaDePagamento.id);

        if(    pagamento.trocoPara) {
          this.trocoPara = pagamento.trocoPara
          this.temTroco = 'sim' ;
          this.calculeTroco()
        }
      }
    }
  }

  mudouOpcaoParaSemTroco() {
    this.temTroco = "nao";
    for( let pagamento of this.pagamentos )
    {
      pagamento.trocoPara = 0;
      pagamento.troco = 0;
    }
    this.trocoPara = 0;
  }
}
