import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {DialogRef} from "@progress/kendo-angular-dialog";
import {NgForm} from "@angular/forms";
declare var moment;
@Component({
  selector: 'app-cad-pausas-programadas',
  templateUrl: './cad-pausas-programadas.component.html',
  styleUrls: ['./cad-pausas-programadas.component.scss']
})
export class CadPausasProgramadasComponent implements OnInit {
  windowRef: DialogRef;
  mensagemErro = '';
  @ViewChild('frm',  {static: false} ) frm: NgForm;
  salvando = false;
  excluindo = false;
  empresa: any;
  pausaProgramada: any = { dataInicio: new Date(), dataFim: new Date()};

  public format = 'dd/MM/yyyy HH:mm';

  constructor(private empresaService: EmpresasService) {
  }

  ngOnInit(): void {
    if(!this.pausaProgramada.id){
      const m = { minute: 0, second: 0, millisecond: 0 };
      this.pausaProgramada.dataInicio = moment().add(1, 'd').set({ minute: 0, second: 0, millisecond: 0 }).toDate();
      this.pausaProgramada.dataFim = moment().add(2, 'd').set({ minute: 0, second: 0, millisecond: 0 }).toDate();
    } else {
      this.pausaProgramada.dataInicio = new Date( this.pausaProgramada.dataInicio)
      this.pausaProgramada.dataFim = new Date( this.pausaProgramada.dataFim)
    }

  }

  onSalvar() {
    if(!this.frm.valid || this.salvando) return;

    this.salvando = true;

    this.empresaService.salvePausaPromagrada(this.empresa, this.pausaProgramada).then( (resposta: any) => {
       if(!this.pausaProgramada.id){
         this.pausaProgramada.id = resposta.id;
         this.empresa.pausasProgramadas.push(this.pausaProgramada)
       }
       this.salvando = false;
       this.windowRef.close(this.pausaProgramada);
    }).catch( erro => {
       this.mensagemErro = erro;
       this.salvando = false;
    })

  }

  fechar() {
    this.windowRef.close();
  }

  exclua(){
    if(this.excluindo) return;

    this.excluindo = true;

    this.empresaService.excluaPausaPromagrada(this.empresa, this.pausaProgramada).then( (resposta: any) => {
      this.excluindo = false;
      this.pausaProgramada.removida = true;
      this.windowRef.close(this.pausaProgramada);
    }).catch( erro => {
      this.mensagemErro = erro;
      this.excluindo = false;
    })



  }
}
