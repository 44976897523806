<kendo-tabstrip   [class]="'tabs'">

  <kendo-tabstrip-tab [title]="'Integração Nativa'" id="nativa" [selected]="true">
    <ng-template kendoTabContent>
      <div class="row" *ngIf="!integracaoDelivery.id || integracaoDelivery.editar">
        <div class="col-12 " style="max-width: 800px;" >
          <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >

            <div class="form-group mt-2">
              <label class="mb-1">Sistema integrar:  </label>

              <kendo-dropdownlist name="sistema" id="sistema" class="form-control" [data]="integracoes"
                                  [textField]="'descricao'"   [valueField]="'id'"
                                  [(ngModel)]="integracaoDelivery.integracao" required  >

              </kendo-dropdownlist>

              <div class="invalid-feedback" *ngIf="!integracaoDelivery.integracao">
                <p  >Sistema é obrigatório</p>
              </div>

            </div>

            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='bluesoft'">
              <div class="row">
                <div class="col-5">
                  <div class="form-group mt-2">
                    <label>Grupo </label>
                    <input kendoTextBox   name="grupo"  placeholder="grupo da loja"
                           class="form-control"   #grupo="ngModel"  (focusout)="carregueLojas()"
                           [(ngModel)]="integracaoDelivery.configuracoesEspecificas.grupo" required/>

                    <div class="invalid-feedback">
                      <p  >Grupo é obrigatório</p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mt-2">
                    <label>Ecommerce Key </label>
                    <input kendoTextBox   name="idEcommerce"  placeholder="id do e-commerce"
                           class="form-control"   #idEcommerce="ngModel"
                           [(ngModel)]="integracaoDelivery.configuracoesEspecificas.idEcommerce" required/>

                    <div class="invalid-feedback">
                      <p  >Id Ecommerce é obrigatório</p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mt-2">
                    <label>Canal de venda </label>
                    <input kendoTextBox   name="canalVenda"  placeholder="id do canal de venda"
                           class="form-control"   #canalVenda="ngModel"
                           [(ngModel)]="integracaoDelivery.configuracoesEspecificas.canalVendaKey" required/>

                    <div class="invalid-feedback">
                      <p  >Canal de venda  é obrigatório</p>
                    </div>
                  </div>
                </div>

                <div class="col-12 mb-2">
             <span class="text-muted">
               Grupo lojas é localizada em "https://erp.bluesoft.com.br/<b>grupo</b>"
             </span>
                </div>
              </div>
            </ng-container>

            <div class="form-group mb-2" *ngIf="temToken()">
              <label for="token">Token </label>
              <input kendoTextBox id="token" name="token"  placeholder="Token"
                     class="form-control"   #token="ngModel"    (focusout)="carregueLojas()"
                     [(ngModel)]="integracaoDelivery.token" required/>

              <div class="invalid-feedback">
                <p  >Token é obrigatório</p>
              </div>

            </div>

            <div class="form-group mb-2" *ngIf="selecionarLoja()">
              <label for="rede">Loja
                <i class="k-icon k-i-loading ml-1" *ngIf="carregandoLojas"></i>
              </label>
              <kendo-dropdownlist   name="loja" [(ngModel)]="integracaoDelivery.loja"  required  [disabled]="carregandoLojas"
                                    [textField]="'nomeFantasia'"
                                    [data]="lojas" [filterable]="true"     [kendoDropDownFilter]="filterSettings" (selectionChange)="selecionouLoja()"
                                    placeholder="Selecione uma loja" class="form-control"
                                    style="border: none;">
              </kendo-dropdownlist>
            </div>

            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='menew'">
              <div class="row">
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="estabelecimento">Id do Estabelecimento </label>
                    <input kendoTextBox id="Estabelecimento" name="estabelecimento"  placeholder="Id do Estabelecimento"
                           class="form-control"   #estabelecimento="ngModel"
                           [(ngModel)]="integracaoDelivery.estabelecimento" required/>

                    <div class="invalid-feedback">
                      <p  >Estabelecimento é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>



            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='teknisa'">
              <div class="row">
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="estabelecimento">Código da filial no sistema Teknisa </label>
                    <input kendoTextBox   name="subsidiaryCode"  placeholder="Código da filial"
                           class="form-control"   #subsidiaryCode="ngModel"
                           [(ngModel)]="integracaoDelivery.subsidiaryCode" required/>

                    <div class="invalid-feedback">
                      <p> Código da filial é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='totvs'">
              <div class="row">
                <div class="col">
                  <div class="form-group mt-2">
                    <label  >Código do Estabelecimento no cadastro do <b>TOTVS Chef</b> (Número da licença)</label>
                    <input kendoTextBox id="Chave" name="Chave"  placeholder="Código do Estabelecimento"
                           class="form-control"   #codigoEstabelecimento="ngModel"
                           [(ngModel)]="integracaoDelivery.codigoEstabelecimento" required/>

                    <div class="invalid-feedback">
                      <p  >Código do Estabelecimento é obrigatório</p>
                    </div>
                  </div>
                </div>

              </div>
            </ng-container>


            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='saipos'">
              <div class="row">
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="idPartner">Id Partner </label>
                    <input kendoTextBox id="idPartner" name="idPartner"  placeholder="Id Partner"
                           class="form-control"   #idPartner="ngModel"
                           [(ngModel)]="integracaoDelivery.idPartner" required/>

                    <div class="invalid-feedback">
                      <p  >Id Partner é obrigatório</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="estabelecimento">Codigo da loja </label>
                    <input kendoTextBox   name="estabelecimento"  placeholder="Código da loja"
                           class="form-control"   #estabelecimento="ngModel"
                           [(ngModel)]="integracaoDelivery.cod_store" required/>

                    <div class="invalid-feedback">
                      <p  >Estabelecimento é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='ecletica'">
              <div class="row">
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="rede">Rede </label>
                    <input kendoTextBox id="rede" name="token"  placeholder="Rede"
                           class="form-control"   #rede="ngModel"
                           [(ngModel)]="integracaoDelivery.rede" required/>

                    <div class="invalid-feedback">
                      <p  >Rede é obrigatório</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="rede">Loja </label>
                    <input kendoTextBox id="loja" name="loja"  placeholder="Loja"
                           class="form-control"   #loja="ngModel"
                           [(ngModel)]="integracaoDelivery.loja" required/>

                    <div class="invalid-feedback">
                      <p  >Loja é obrigatório</p>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="empresa.idRede === 1 ">
                  <div class="col" >
                    <div class="form-group mt-2">
                      <label for="rede">Loja China</label>
                      <kendo-dropdownlist id="unidadeChina" name="unidadeChina" [(ngModel)]="integracaoDelivery.unidadeChina"
                                          [data]="chinas" [filterable]="true"     [kendoDropDownFilter]="filterSettings" (selectionChange)="selecionouLojaChina()"
                                          placeholder="Selecione uma loja china" class="form-control"
                                          style="border: none;">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </ng-container>

                <div class="col" *ngIf="empresa.idRede === 2">
                  <div class="form-group mt-2">
                    <label for="rede">Loja Gendai</label>
                    <kendo-dropdownlist id="unidadeGendai" name="unidadeGendai" [(ngModel)]="integracaoDelivery.unidadeGendai" (selectionChange)="selecionouLojaGendai()"
                                        [data]="gendais" [filterable]="true"     [kendoDropDownFilter]="filterSettings"
                                        placeholder="Selecione uma loja gendai" class="form-control"
                                        style="border: none;">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6"  >
                  <div class="form-group mb-2 ">
                    <div class="input-group mt-2">
                      <input  name="integrarComComandas" id='integrarComComandas'    class="k-checkbox  " type="checkbox"
                              [(ngModel)]="integracaoDelivery.integrarComComandas"/>
                      <label  class="k-checkbox-label">Integração com Comandas</label>
                    </div>
                  </div>
                </div>


                <div class="col-12 col-sm-6" *ngIf="empresa.idRede === 2 || empresa.idRede === 1">
                  <div class="form-group mb-2 ">
                    <div class="input-group mt-2">
                      <input  name="naoSincronizarGlobal" id='naoSincronizarGlobal'    class="k-checkbox  " type="checkbox"
                              [(ngModel)]="integracaoDelivery.naoSincronizarGlobal"/>
                      <label  class="k-checkbox-label">Não sincronizar com site TrendFoods</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6"  >
                  <div class="form-group mb-2 ">
                    <div class="input-group mt-2">
                      <input  name="observacoesNoInicio" id='observacoesNoInicio'    class="k-checkbox  " type="checkbox"
                              [(ngModel)]="integracaoDelivery.observacoesNoInicio"/>
                      <label  class="k-checkbox-label">Enviar observações no primeiro item do pedido</label>
                    </div>
                  </div>
                </div>

                <div class="col-12  " >
                  <div class="form-group mb-2 ">
                    <div class="input-group mt-2">
                      <input  name="naoSincronizarDisponiveis" id='naoSincronizarDisponiveis'    class="k-checkbox  " type="checkbox"
                              [(ngModel)]="integracaoDelivery.naoSincronizarDisponiveis"/>
                      <label  class="k-checkbox-label">Não sincronizar automáticamente produtos estão indisponíveis</label>
                    </div>

                  </div>
                </div>

                <div class="form-group mb-2 col-12 ">
                  <div class="input-group mt-2">
                    <input  name="integrarComDMWS" id='integrarComDMWS'    class="k-checkbox  " type="checkbox"
                            [(ngModel)]="integracaoDelivery.integrarComDMWS"/>
                    <label  class="k-checkbox-label">Integração mesas/comandas com serviço <b>DMWS</b></label>
                  </div>
                </div>
              </div>

            </ng-container>


            <div class="row" *ngIf="integracaoDelivery.integracao?.sistema ==='gcom'">
              <div class="col" *ngIf="empresa.idRede === 2">
                <div class="form-group mt-2">
                  <label for="rede">Loja Gendai</label>
                  <kendo-dropdownlist   name="unidadeGendai" [(ngModel)]="integracaoDelivery.unidadeGendai" (selectionChange)="selecionouLojaGendai()"
                                        [data]="gendais" [filterable]="true"     [kendoDropDownFilter]="filterSettings"
                                        placeholder="Selecione uma loja gendai" class="form-control"
                                        style="border: none;">
                  </kendo-dropdownlist>
                </div>
              </div>

              <div class="col"  *ngIf="empresa.idRede === 1 ">
                <div class="form-group mt-2">
                  <label for="rede">Loja China</label>
                  <kendo-dropdownlist name="unidadeChina" [(ngModel)]="integracaoDelivery.unidadeChina"
                                      [data]="chinas" [filterable]="true"     [kendoDropDownFilter]="filterSettings" (selectionChange)="selecionouLojaChina()"
                                      placeholder="Selecione uma loja china" class="form-control"
                                      style="border: none;">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>

            <ng-container *ngIf="integracaoDelivery.integracao?.sistema ==='RPInfo'">
              <div class="row">
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="configuracaoLoja">Loja/Unidade </label>
                    <input kendoTextBox id="configuracaoLoja" name="configuracaoLoja"  placeholder="Loja"
                           class="form-control"   #configuracaoLoja="ngModel"
                           [(ngModel)]="integracaoDelivery.loja" required/>

                    <div class="invalid-feedback">
                      <p  >Loja é obrigatório</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mt-2">
                    <label for="especie">Espécie </label>
                    <input kendoTextBox id="especie" name="especie"  placeholder="Espécie"
                           class="form-control"   #especie="ngModel"
                           [(ngModel)]="integracaoDelivery.configuracoesEspecificas.especie" required/>

                    <div class="invalid-feedback">
                      <p  >Espécie é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col">
                  <div class="form-group mt-2">
                    <label for="especie">Código do Documento </label>
                    <input kendoTextBox id="codigoDocumento" name="codigoDocumento"  placeholder="Código"
                           class="form-control"   #codigoDocumento="ngModel"
                           [(ngModel)]="integracaoDelivery.configuracoesEspecificas.codigoDocumento" required/>

                    <div class="invalid-feedback">
                      <p  >Espécie é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col">
                  <div class="form-group mt-2">
                    <label for="especie">URL da API </label>
                    <input kendoTextBox id="link" name="link"  placeholder="URL da API"
                           class="form-control"   #codigoDocumento="ngModel"
                           [(ngModel)]="integracaoDelivery.configuracoesEspecificas.link" required/>

                    <div class="invalid-feedback">
                      <p >A URL da API é obrigatória</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>


            <button class="btn btn-success mt-2" type="submit" [disabled]="salvando">
              <i class="k-icon k-i-loading " *ngIf="salvando"></i>  Salvar
            </button>

            <button class="btn btn-secondary mt-2 ml-1" type="button" (click)="integracaoDelivery.editar = false" [hidden]="!integracaoDelivery.id" >
              Voltar
            </button>

            <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
              {{mensagemErro}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </form>
        </div>
      </div>

      <div   *ngIf="integracaoDelivery.id"  class="text-center" [hidden]="integracaoDelivery.editar">

        <img src="/assets/icons/icon-success.png" height="100px" >

        <h3>Sistema <b>{{integracaoDelivery.tipo}}</b>  integrado <b>{{integracaoDelivery.sistema}}</b>
        </h3>

        <h5 *ngIf="integracaoDelivery.rede">
          Rede: <b>{{integracaoDelivery.rede}}</b>
          Loja: <b>{{integracaoDelivery.loja}}</b>
          <span *ngIf="integracaoDelivery.unidadeChina" class="ml-1">China: <b>{{integracaoDelivery.unidadeChina}}</b></span>
          <span *ngIf="integracaoDelivery.unidadeGendai" class="ml-1">Gendai: <b>{{integracaoDelivery.unidadeGendai}}</b></span>
        </h5>

        <h5 *ngIf="integracaoDelivery?.configuracoesEspecificas?.integrarComDMWS">
           <b>DMWS Ativo</b>
        </h5>


        <h5 *ngIf="integracaoDelivery.configuracoesEspecificas && integracaoDelivery.configuracoesEspecificas.loja">
          Loja: <b>{{integracaoDelivery.configuracoesEspecificas.loja.id}}: {{integracaoDelivery.configuracoesEspecificas.loja.nome}}</b>
        </h5>


        <h5 *ngIf="integracaoDelivery.configuracoesEspecificas && integracaoDelivery.configuracoesEspecificas.cod_store">
          Loja: <b>{{integracaoDelivery.configuracoesEspecificas.cod_store}}</b>
        </h5>



        <h5 *ngIf="integracaoDelivery.naoSincronizarDisponiveis">
          Produtos indisponíveis não sincronizar
        </h5>

        <h5 *ngIf="integracaoDelivery.configuracoesEspecificas && integracaoDelivery.configuracoesEspecificas.integrarComComandas">
          Integração Mesas <b>por comanda </b>
        </h5>

        <h5 *ngIf="integracaoDelivery.configuracoesEspecificas && integracaoDelivery.configuracoesEspecificas.observacoesNoInicio">
          Enviar observações dos itens de pedido <b> no inicio</b>
        </h5>


        <p>Integração ativa desde de <b>{{integracaoDelivery.data | date: 'dd/MMM/yyyy'}}</b></p>

        <p *ngIf="integracaoDelivery.token" style="    max-width: 500px;margin: 0 auto;" class="abreviar">
          Token: <b>{{integracaoDelivery.token}}</b></p>


        <button class="btn btn-blue mr-2" (click)="editarIntegracao()" [hidden]="confirmarRemover">
          Editar</button>


        <button class="btn btn-danger" (click)="confirmeRemover()" [hidden]="confirmarRemover">
          Remover</button>


        <h4 class="mt-2 " *ngIf="confirmarRemover">
          Confirma a remoção da integração  ?

          <div class="mt-1">
            <button class="btn btn-success mr-1"  (click)="removerIntegracao()" [disabled]="removendo">Sim</button>
            <button class="btn btn-light" (click)="canceleRemover()">Cancelar</button>
          </div>

        </h4>
      </div>
    </ng-template>


  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="'Open Delivery (Comerciante)'" id="opencomerciante" >
    <ng-template kendoTabContent>
      <app-cad-integracao-opendelivery [empresa]="empresa"></app-cad-integracao-opendelivery>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Integração pela API'" id="api" >
    <ng-template kendoTabContent>
      <app-grid-chaves-integracao></app-grid-chaves-integracao>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>

