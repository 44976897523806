import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {PontuacaoRegistrada} from "../../../server/domain/PontuacaoRegistrada";
import {AtividadeService} from "../services/atividade.service";
import {UITipoDePontuacaoPorValor} from "../objetos/UITipoDePontuacaoPorValor";
import {ContatosService} from "../services/contatos.service";
import {UITipoDePontuacaoQtdSelos} from "../objetos/UITipoDePontuacaoQtdSelos";
import {TipoDePontuacaoPorValor} from "../../../server/domain/TipoDePontuacaoPorValor";
import {IUITipoDePontuacao} from "../objetos/IUITipoDePontuacao";
import {TipoDePontuacaoQtdFixa} from "../../../server/domain/TipoDePontuacaoQtdFixa";
import {AbstractControl, ControlContainer, FormControl, NgForm, NgModel} from "@angular/forms";
import {DropDownFilterSettings, MultiSelectComponent} from "@progress/kendo-angular-dropdowns";
import {UITipoDePontuacaoPorPontos} from "../objetos/UITipoDePontuacaoPorPontos";
import {TipoDePontuacaoPorPontos} from "../../../server/domain/TipoDePontuacaoPorPontos";
import {UITipoDePontuacaoQtdeFixaAtividade} from "../objetos/UITipoDePontuacaoQtdeFixaAtividade";
import {UITipoDePontuacaoQtdeVariavelAtividade} from "../objetos/UITipoDePontuacaoQtdeVariavelAtividade";
import {EnumTipoDeCartao} from "../../../server/lib/emun/EnumTipoDeCartao";
import {UITipoDePontuacaoCashback} from "../objetos/UITipoDePontuacaoCashback";
import {NumericTextBoxComponent} from "@progress/kendo-angular-inputs";
import {formatCurrency} from "@angular/common";
import {EnumTipoDePontuacao} from "../../../server/lib/emun/EnumTipoDePontuacao";
import {IUITipoDePontuacaoFactory} from "../objetos/IUITipoDePontuacaoFactory";
import {ConstantsService} from "../fidelidade/ConstantsService";
declare var _, $;

@Component({
  selector: 'app-inserir-pontos',
  templateUrl: './inserir-pontos.component.html',
  styleUrls: ['./inserir-pontos.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InserirPontosComponent implements OnInit, AfterViewInit {
  @ViewChild('atividadeModel') atividadeModel: NgModel;
  @ViewChild('atividade') atividade: MultiSelectComponent;
  @ViewChild('valorVenda') valorVenda: NumericTextBoxComponent;
  @ViewChild('pontosInformado') pontosInformado: NumericTextBoxComponent;
  @Input() frm: NgForm;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  cartao: any =  { atividades: []};
  contato: any = {};
  pontuacaoRegistrada: PontuacaoRegistrada = new PontuacaoRegistrada();
  usarSaldo = false;
  caixa = {saldo: 0, totalVenda: 0};
  pontuando = false;
  empresa: any;

  constructor(private atividadeService: AtividadeService, private contatosService: ContatosService,
              private ngZone: NgZone, private constantsService: ConstantsService) {
  }

  ngOnInit() {
    this.constantsService.empresa$.subscribe((empresa) => {
      if (!empresa) return;

      this.empresa = empresa;
    });
  }

  public setCartao(contato: any, cartao: any, valor: any) {
    this.contato = contato || {};
    this.cartao = cartao || {};
    if (valor > 0)
      this.pontuacaoRegistrada.valor = valor;

    if (this.contato.cartoes) {
      this.contato.cartoes.forEach((cc: any) => {
        cc.nomePlano = cc.plano.nome;
      })
      this.setAtividades();
    }


  }

  setAtividades(valor = null){
    if(!this.cartao || !this.cartao.plano   ) return;

    if(!this.cartao.atividades){
      this.atividade.loading = true;
      this.atividadeService.listeAtividadesPlano(this.cartao.plano).then( (atividades) => {
         this.atividade.loading = false;
         this.cartao.atividades = atividades;
         this.foqueElementosTela();
      })
    } else {

      this.foqueElementosTela();

    }
  }

  valorMudou() {
    let uiTipo = this.obtenhaTipodePontuacao()

    if(!uiTipo || !this.pontuacaoRegistrada.atividades) {
      this.pontuacaoRegistrada.pontos = 0;
    } else {
      this.pontuacaoRegistrada.cartao = this.cartao;
      this.pontuacaoRegistrada.pontos =  uiTipo.calculePontuacao(this.pontuacaoRegistrada, null);
    }
  }

  reset(){
     this.setCartao(null, null, null)
     this.frm.resetForm( {valorVenda: 0 })
     this.pontuacaoRegistrada.atividades = [];
     this.pontuacaoRegistrada.valor = 0;
     this.pontuacaoRegistrada.pontos = 0;
     this.usarSaldo = false;
     this.pontuando = false;
  }

  registrePontuacao() {
    let parametros: any = {
      ic:  this.cartao.id ,
      valor: this.pontuacaoRegistrada.valor,
      pontosInformado: this.pontuacaoRegistrada.pontosInformado,
      pontos: this.pontuacaoRegistrada.pontos,
      referenciaExterna: this.pontuacaoRegistrada.referenciaExterna,
      idas: this.pontuacaoRegistrada.atividades.map( atividade =>  atividade.id).join(', ')
    };

    if(this.usarSaldo)
      parametros.cashback = this.caixa.saldo;

    return new Promise((resolve: any, reject: any) => {

      if (this.pontuando)
        return reject('pontuação já está sendo realizada, aguarde...');
      this.pontuando = true;

      this.contatosService.novaPontuacao(parametros).then((resposta) => {
        this.cartao.pontos = resposta.novaPontuacao;
        this.reset();
        resolve(resposta);
      }).catch((erro) => {
        this.pontuando = false;
        reject(erro)
      });
    })

  }

  private obtenhaTipodePontuacao(): IUITipoDePontuacao {
    if(!this.cartao || !this.cartao.plano || !this.cartao.plano.tipoDePontuacao)
      return null;

    return IUITipoDePontuacaoFactory.obtenhaTipodePontuacao(this.empresa, this.cartao.plano.tipoDePontuacao)

  }

  aletrouAtividade(atividadeSelecionada: any) {
    this.pontuacaoRegistrada.valor  = _.reduce(this.pontuacaoRegistrada.atividades,
                                        (valor, atividade) => (atividade.valor || 0) + valor, 0);
    this.valorMudou();
  }

  alterouPlano() {
    if(this.cartao && !this.cartao.atividades)
      this.setAtividades();
    else{
      this.foqueElementosTela();
    }
   this.aletrouAtividade(null)
  }

  informarPontuacao() {
    if(!this.cartao || !this.cartao.plano) return false;

    if(this.temCashbackDefinido()) return false;

    return   this.cartao.plano.tipoDePontuacao.tipo === EnumTipoDePontuacao.QtdeVariavelPorAtividade
  }

  temCashbackDefinido(): boolean{
    return this.pontuacaoRegistrada.atividades.length === 1 && this.pontuacaoRegistrada.atividades[0].cashback > 0;
  }

  getPontos(){
    let pontos: any = this.pontuacaoRegistrada.pontos;

    if(!this.cartao || !this.cartao.plano)
      return pontos;

    if(  this.cartao.plano.tipoDeAcumulo === EnumTipoDeCartao.Reais)
       return  formatCurrency(pontos, 'pt-BR', 'R$');

    return pontos;
  }
  getLabel() {

    let label = 'Novos'

    if(!this.cartao || !this.cartao.plano)
      return label;

    if(this.cartao.plano.tipoDeAcumulo !== EnumTipoDeCartao.Reais)
          return  label + " " + this.cartao.plano.tipoDeAcumulo

    return 'Cashback'
  }

  usarSaldoCartao($event) {
    this.usarSaldo = true;
    this.caixa.totalVenda =    this.pontuacaoRegistrada.valor;
    this.caixa.saldo  =   this.cartao.pontos <=  this.caixa.totalVenda ?  this.cartao.pontos :  this.caixa.totalVenda  ;
    this.recalculeComCashback();

    $event.stopPropagation();
    $event.preventDefault();

  }

  cancelarUsoSaldo($event){
    this.usarSaldo = false;
    this.caixa.saldo = 0;
    this.recalculeComCashback();
    this.caixa.totalVenda = 0;
    $event.stopPropagation();
    $event.preventDefault();
  }

  recalculeComCashback(){
    this.pontuacaoRegistrada.valor =  this.caixa.totalVenda -    this.caixa.saldo;
    this.valorMudou();
  }

  podeUsarSaldo() {
    if(!this.cartao.id || !this.pontuacaoRegistrada.atividades || !this.pontuacaoRegistrada.atividades.length)
      return false;

    return this.cartao.plano.tipoDePontuacao.tipo ===  EnumTipoDePontuacao.Cashback && this.cartao.pontos > 0 ;
  }

  foqueElementosTela() {
    if(!this.cartao || !this.cartao.atividades) return;

    setTimeout( () => {
      if(this.cartao.atividades.length === 1){
        this.valorVenda.focus();
        this.pontuacaoRegistrada.atividades = this.cartao.atividades;
        if(this.pontuacaoRegistrada.valor)
          this.valorMudou();

      } else {
        this.atividade.focus(); // não funcionou
        this.atividade.toggle(true);
        if(this.pontuacaoRegistrada.valor)
          this.valorMudou();
      }
    }, 100);
  }

  submit($event) {
    this.frm.ngSubmit.emit()
  }

  ngAfterViewInit(): void {
  }
}
