import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ChavesIntegracaoService extends ServerService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  todas() {
    return this.obtenha('/integracao/chaves/' , { });

  }

  ativeChave(chaveIntegracao: any) {
    return this.facaPut('/integracao/ative/' + chaveIntegracao.id, {segredo: chaveIntegracao.segredo})
  }

  desativeChave(chaveIntegracao: any) {
    return this.facaPut('/integracao/desative/' + chaveIntegracao.id, {segredo: chaveIntegracao.segredo})
  }

  ativeAcessoDireto(chaveIntegracao: any) {
    return this.facaPut('/integracao/ative/acessoDireto/' + chaveIntegracao.id, {segredo: chaveIntegracao.segredo})
  }

  desativeAcessoDireto(chaveIntegracao: any) {
    return this.facaPut('/integracao/desative/acessoDireto/' + chaveIntegracao.id, {segredo: chaveIntegracao.segredo})
  }

  atualizeSistemaIntegrado(chaveIntegracao: any) {
    return this.facaPut('integracao/chave/' + chaveIntegracao.id + '/sistema', {
      id: chaveIntegracao.sistemaIntegrado.id
    })
  }



}
