<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Eventos do Pedido <b>{{pedido.id}}</b>
  </h4>

</kendo-dialog-titlebar>
<div   class="modal-body">

  <kendo-grid [data]="eventos"
              [loading]="carregando" style="min-height: 300px;">
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem eventos"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>
    <kendo-grid-column title="ID" width="350"  >
      <ng-template kendoGridCellTemplate let-dataItem  >
        <span class="text-blue"><b>{{dataItem.eventId}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Tipo"  >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span><b>{{dataItem.eventType}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Horário"  width="250" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="text-muted"><b>{{dataItem.createdAt | date: 'dd/MM/YYYY HH:mm:ss'}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Lido"   width="100">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="badge badge-success" *ngIf="dataItem.reconhecido">Sim</span>
        <span class="badge badge-danger" *ngIf="!dataItem.reconhecido">Não</span>
      </ng-template>


    </kendo-grid-column>

  </kendo-grid>
</div>
