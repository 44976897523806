import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ConstantsService} from "../ConstantsService";

@Component({
  selector: 'app-ativar-hub-pagarme',
  templateUrl: './ativar-hub-pagarme.component.html',
  styleUrls: ['./ativar-hub-pagarme.component.scss']
})
export class AtivarHubPagarmeComponent implements OnInit {
  codigoAutorizacao: string;
  ativando = false;
  erroInstalacao: string;
  dadosAtivacao: any;
  empresa: any;
  constructor(   private  activatedRoute: ActivatedRoute, private constantsService: ConstantsService,
                 private empresasService: EmpresasService) { }

  ngOnInit(): void {
    //authorization_code
    this.codigoAutorizacao = this.activatedRoute.snapshot.queryParams.authorization_code;

    if(this.codigoAutorizacao)
      this.ativeNovaInstalacao();
    else
      this.erroInstalacao = 'Nenhum código autorização retornado na query string';

    this.constantsService.empresa$.subscribe((empresa: any) => {
       if(empresa)
          this.empresa = empresa;
    })
  }

  ativeNovaInstalacao(){
    this.ativando = true;
    this.empresasService.ativeNovaLoja(this.codigoAutorizacao).then( (dadosAtivacao: any) => {
      this.ativando = false;

      this.dadosAtivacao = dadosAtivacao;

      this.empresa.integracaoGatewayPagamento = dadosAtivacao;
      this.constantsService.mudouEmpresa(this.empresa);

    }).catch((erro) => {
      this.erroInstalacao = erro;
      this.ativando = false;
    })
  }

}
