<div #kanbanBoard class="kanban-board">
    <div class="kanban-column" *ngFor="let coluna of colunas;let i = index"
         [style.max-width]="largura"
         [style.min-width]="largura"
         [style.width]="largura"
    >
        <div class="flag-container" [ngClass]="'cor' + i" >
            <h5 class="text-truncate flag-text" [ngClass]="'cor' + i" title="{{coluna}}">{{coluna}}
                <span class="font-11">({{itensAgrupados[coluna] ? itensAgrupados[coluna].length : 0}})</span></h5>
        </div>
        <div class="container-scroll" #ctlKanbanColuna [id]="coluna"
             cdkDropList [cdkDropListData]="itensAgrupados[coluna]"
             [ngClass]="{
    'hovering': arrastando && coluna !== colunaOrigem
  }"

             [cdkDropListConnectedTo]="getConnectedLists(colunas, coluna)"  (cdkDropListDropped)="drop($event)">
            <div class="kanban-card"
                 (cdkDragStarted)="iniciou($event, coluna)" (cdkDragEnded)="finalizou($event)"
                 (cdkDragMoved)="onDragMoved($event)"
                     *ngFor="let pedido of itensAgrupados[coluna]" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <ng-container [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{$implicit: pedido}"></ng-container>
                </div>
        </div>
    </div>
</div>

