import {Component, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {IntegracaoERPService} from "../../services/integracaoerp.service";
import {PainelImportacaoProdutosComponent} from "../../compartilhado/painel-importacao-produtos/painel-importacao-produtos.component";
import {ImportadorChinnaService} from "../../services/importador-chinna.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sincronizar-catalogo-rede',
  templateUrl: './sincronizar-catalogo-rede.component.html',
  styleUrls: ['./sincronizar-catalogo-rede.component.scss']
})
export class SincronizarCatalogoRedeComponent implements OnInit {
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  empresasSincronizar: any =  [];
  empresasNaoSincronizar: any =  [];
  respostas:   any =  { };
  carregandoProdutos = false ;
  importacao: any
  emptyStyles: {[key: string]: any} = {  background: '' };
  progressStyles: {[key: string]: any} = {};
  erroBuscarCatalogo: string
  opcoes: any = {}
  nomeLojas = 'China In Box'
  constructor(private empresasService: EmpresasService, private importadorChinnaService: ImportadorChinnaService,
              private route: ActivatedRoute) {
    this.carregandoProdutos = true;
    let paths =  this.route.snapshot.routeConfig.path.split('-');

    let rede = paths[paths.length - 1];

    if(rede === 'gendai') this.nomeLojas = 'Gendai'

    if(rede.endsWith('gcom')) this.nomeLojas += '(GCOM)'

    this.empresasService.listeEmpresasRedeTrendsfood(rede).then( (resposta) => {
       this.empresasSincronizar = resposta.empresas.filter( e => e.integracaoDelivery &&  !e.integracaoDelivery.naoSincronizarGlobal);
       this.empresasNaoSincronizar = resposta.empresas.filter( e => !e.integracaoDelivery ||  e.integracaoDelivery.naoSincronizarGlobal);
       if(this.empresasSincronizar.length){
        this.importadorChinnaService.listeProdutosRede(this.empresasSincronizar[0]).then( (resposta2: any) => {
          this.carregandoProdutos = false;
          this.painelImportacao.setProdutosSincronizar(resposta2.novosProdutos, resposta2.produtosAtualizar, [],
            resposta2.categorias)
        }).catch((erro) => {
           this.carregandoProdutos = false;
           this.erroBuscarCatalogo = erro;
        })
       } else {
         this.carregandoProdutos = false;
       }
    });
  }

  async reimporteFalhas(){
    delete     this.importacao.finalizada;

    this.inicieSincronizacao(true)

  }

  async reimporteIncompletos(){
    delete     this.importacao.finalizada;

    this.inicieSincronizacao(false, true)
  }

  async inicieSincronizacao(soFalhou = false, incompletos = false){
    let empresasSincronizar = soFalhou ?  this.empresasSincronizar.filter( e => e.falhou) :
      (incompletos ? this.empresasSincronizar.filter( e => e.incompleto) : this.empresasSincronizar)

    this.respostas  =  { concluidos: [], incompletos: [], erros: []};
    this.importacao = { qtde: empresasSincronizar.length, totalProcessados: 0}
    this.progressStyles = {   color: '#fff',  background: '#1050bb' };

    for(let i = 0; i <  empresasSincronizar.length ; i++){
      await  this.sincronizeEmpresa(empresasSincronizar[i]);
    }

    if(!this.respostas.erros.length){
      this.progressStyles.background = '#8EBC00';
    }  else {
      this.progressStyles.background = '#f1556c';
    }

    this.importacao.finalizada = true;
  }

  ngOnInit(): void {
  }


  async sincronizeEmpresa(empresa: any) {
    this.importacao.empresa = empresa;
    let resposta: any = await this.importadorChinnaService.sincronizeCatalogoRede(empresa,
            this.opcoes.sincronizarPrecos, this.opcoes.sincronizarImagens).catch( (erro) => {
      this.respostas.erros.push({ empresa: empresa.nome, erro: erro});
      empresa.falhou = true;
    })
    this.importacao.totalProcessados++;

    if(resposta){
      delete empresa.falhou
      delete empresa.incompleto
      resposta.empresa = empresa.nome;

      if(resposta.erros.length){
        empresa.incompleto = true;
        this.respostas.incompletos.push(resposta)
      } else {
        this.respostas.concluidos.push(resposta)
      }
    }

    delete  this.importacao.empresa;
  }

  obtenhaLabel(){
    return {
      format: (value) => {
        return `${value}/${this.importacao.qtde}`;
      },
      position: 'center',
    }
  }
}
