<div  style="max-width: 700px" class="card">
  <div class="card-body"  >
    <div    class="text-center" [hidden]="integracaoIfood.editar" *ngIf="integracaoIfood?.id">

      <img src="/assets/icons/logo-ifood.png" height="100px" >

      <p class="text-black-50 mb-1">Id da loja:  <span class="text-primary"><b>{{integracaoIfood.idLoja}}</b></span></p>

      <p class="mb-1">Integração ativa desde de <b>{{integracaoIfood.dataAtivacao | date: 'dd/MMM/yyyy'}}</b></p>

      <p     class="text-muted" mb-1>
        Validade token: <b>{{integracaoIfood.validadeToken | date: 'dd/MMM/yyyy HH:mm'}}</b></p>

      <button class="btn btn-blue mr-2" (click)="integracaoIfood.editar = true" [hidden]="confirmarRemover">
        Editar</button>

      <button class="btn btn-danger" (click)="confirmeRemover()" [hidden]="confirmarRemover">
        Remover</button>

      <h4 class="mt-2 " *ngIf="confirmarRemover">
        Confirma a remoção da integração  ?

        <div class="mt-1">
          <button class="btn btn-success mr-1"  (click)="removerIntegracao()" [disabled]="removendo">Sim</button>
          <button class="btn btn-light" (click)="canceleRemover()">Cancelar</button>
        </div>

      </h4>

    </div>

    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmit()"  *ngIf="!integracaoIfood.id || integracaoIfood.editar">
      <div class="row">
        <div class="col">
          <h5>Integração com Pedidos do Ifood</h5>
          <div class="form-group mt-4">
            <label>Id da Loja </label>
            <input kendoTextBox   name="grupo"  placeholder="Id da loja no Ifood"
                   class="form-control"   #idLoja="ngModel"
                   [(ngModel)]="integracaoIfood.idLoja" required/>

            <div class="invalid-feedback">
              <p  >Id da Loja é obrigatório</p>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <button class="btn btn-success mt-2" type="submit" [disabled]="salvando">
            <i class="k-icon k-i-loading " *ngIf="salvando"></i>  Salvar
          </button>

          <button class="btn btn-secondary mt-2 ml-1" type="button" (click)="integracaoIfood.editar = false" [hidden]="!integracaoIfood.id" >
            Voltar
          </button>

          <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
            {{mensagemErro}}
            <button type="button" class="close" aria-label="Close" (click)="fecharAlerta()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

    </form>

  </div>




</div>
