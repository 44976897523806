import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class MiaService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  gereSugestao(ia: any) {
    return fetch('/chatgpt/index');

    //return this.http.get('/chatgpt/index' , {params: ia, observe: 'events', reportProgress: true, responseType: 'text'});
  }
}
