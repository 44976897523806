import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SelosComponent} from "../selo/selo.component";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {EnumTipoDeCartao} from "../../../../server/lib/emun/EnumTipoDeCartao";
import {ModulosService} from "../../services/modulos.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'info-fidelidade',
  templateUrl: './info-fidelidade.component.html',
  styleUrls: ['./info-fidelidade.component.scss']
})
export class InfoFidelidadeComponent implements OnInit {
  @ViewChild('selosCartao', { static: true } ) selosCartao: SelosComponent;

  @Input() public cartoes: any = [];
  @Input() public empresa: any;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  possuiFidelidade: boolean;


  constructor(private constantsService: ConstantsService) {
    this.constantsService.moduloFidelidade$.subscribe( data => this.possuiFidelidade  = data  )

  }

  ngOnInit() {
  }

  alterouCartao() {

  }
}
