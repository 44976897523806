import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class CountryService extends   ServerService {
  constructor(http: HttpClient) {
    super(http)
  }

  getCountries() {
    return this.http.get('/countries/'  , {})
      .toPromise();

  }
}
