import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class IntegracaoERPService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  listeBandeiras(tipo: string) {
    return this.obtenha('/integracaoerp/cartoes/bandeiras?tipo=' + tipo , {});
  }

  listeBandeirasOpendelivery(tipo: string) {
    return this.obtenha('/opendelivery/cartoes/bandeiras/' + tipo , {});
  }

  listeLojas(token: string, grupo: string) {
    return this.obtenha(String(`/integracaoerp/bluesoft/${grupo}/lojas?token=${token}`) , {});
  }

  listeProdutos(){
    return this.obtenha('/integracaoerp/produtos/novos' , {});
  }

  obtenhaProduto(codigo: string){
    return this.obtenha('/integracaoerp/produtos/sincronizar/' + codigo , {});
  }

  inicieImportacaoProdutos(openDelivery = false){
    let params: any = {}

    if(openDelivery) params.openDelivery = 1

    return this.obtenha('/integracaoerp/produtos/novos/async' , params);
  }

  obtenhaStatusImportacao(openDelivery = false){
    let params: any = {}

    if(openDelivery) params.openDelivery = 1

    return this.obtenha('/integracaoerp/produtos/importacao/status' , params);
  }



  inicieSincronizarPrecosAsync(todos: boolean, openDelivery = false){
    let params: any = {};

    if(todos) params.todos = true;
    if(openDelivery) params.openDelivery = 1

    return this.obtenha('/integracaoerp/produtos/sincronizar/precos/async' , params);
  }

  inicieSincronizarEstoqueAsync(todos: boolean, openDelivery = false){
    let params: any = {};

    if(todos) params.todos = true;
    if(openDelivery) params.openDelivery = 1

    return this.obtenha('/integracaoerp/produtos/sincronizar/estoque/async' , params);
  }

  execuetNotificacaoMesa(  notificacao: any){
    return this.facaPost('/integracaoerp/ecletica/notificacao/mesa/execute' , notificacao);
  }
}
