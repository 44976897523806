
<h5>Confira as opções de pagamento para esta fatura.</h5>

<div class="row">
  <div *ngIf="fatura.pix" class="col" style="max-width: 250px">
    <h4 class="mb-1">  Pix</h4>
    <img class="pix" [src]="fatura.pix.qrcode" height="150" >

    <button class="btn btn-success btn-copia" (click)="copyMessage(fatura.pix.qrcodeText)"
            [ngClass]="{'btn-success': mensagemCopiada === fatura.pix.qrcodeText,'btn-blue': mensagemCopiada !== fatura.pix.qrcodeText}"
    >{{mensagemCopiada !== fatura.pix.qrcodeText ? 'Pix Copia e Cola' : 'Pix Copiado'}} </button>
  </div>

  <div *ngIf="fatura.boleto" class="col">
    <h4 class="mb-1">    Boleto Bancário</h4>

    <img   [src]="fatura.boleto.urlCodigoBarras" width="350" >

    <span class="k-display-block cpointer" (click)="copyMessage(fatura.boleto.codigoBarras)"
          title="copiar codigo barras" kendoTooltip>
                {{fatura.boleto.codigoBarras}}
      <i class="k-i-copy k-icon" [ngClass]="{'text-success': mensagemCopiada === fatura.boleto.codigoBarras}"></i>
              </span>


    <a class="btn btn-success   mt-1" href="{{fatura.boleto.link}}" target="_blank"
       *ngIf="fatura.boleto">
      <i class="fe-printer fa-lg mr-1">  </i>Imprimir boleto
    </a>
  </div>

  <div *ngIf="fatura.cartao" class="col">
    <h4 class="mb-1">Cartão de Crédito</h4>



    <button class="btn btn-success" (click)="abraModalPagarCartao()">    <i class="fa fa-credit-card fa-lg"></i>
      Pagar no cartão </button>

  </div>
</div>
