import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {ConstantsService} from "../fidelidade/ConstantsService";

@Injectable({
  providedIn: 'root'
})
export class BannerService extends ServerService {

  constructor (public http: HttpClient) {
    super(http);
  }

  removaBanner(banner: any): Promise<any> {
    return this.remova('/banner/' + banner.id, {});
  }

  salveCapaEmpresa(): Promise<any> {
    return null;
  }

  salveBanner(banner: any) {
    return this.salve('/banner', banner );
  }

  obtenhaBanners(empresa: any) {
    return this.obtenha('/banner/liste', {});
  }

  obtenhaBanner(idBanner) {
    return this.obtenha('/banner/' + idBanner, {});
  }
}
