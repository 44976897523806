import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Renderer2,
  ChangeDetectionStrategy,
  SimpleChanges, OnChanges, ApplicationRef, ChangeDetectorRef
} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-bandeira-logo',
  templateUrl: './bandeira-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BandeiraLogoComponent implements OnChanges {
  @Input() bandeira: any;
  @Input() descricao: string;
  textoString: string;
  @ViewChild('svg', { static: true }) svg: ElementRef;
  @ViewChild('img', { static: true }) img: ElementRef;
  constructor(private el: ElementRef,  private app: ApplicationRef,
              private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.bandeira) {
      this.textoString = this.descricao ? this.descricao : this.bandeira.nome;

      if(this.bandeira.imagem && this.bandeira.imagem.indexOf('svg') >= 0){
        this.svg.nativeElement.innerHTML = this.bandeira.imagem;

        const svgElement = this.el.nativeElement.querySelector('svg');

        if(svgElement){
          svgElement.style.maxWidth = '30px';
          svgElement.style.maxHeight = '20px';
        }
      } else {
        this.bandeira.imagemUrl = this.bandeira.imagem;
      }

    }
  }

  // Converte a imagem binária em uma URL de objeto (data URL)
  convertBinaryToDataUrl(binaryData: any): void {
    if(this.bandeira.imagemUrl) return;

    const blob = new Blob([binaryData]);
    const reader = new FileReader();

    reader.onload = () => {
      const dataUrl: any = reader.result;
      // Use o DomSanitizer para tornar a URL segura
      this.bandeira.imagemUrl = this.sanitizer.bypassSecurityTrustUrl(dataUrl) as SafeUrl;
      this.cd.detectChanges(); // Manual change detection
      console.log(this.bandeira.imagemUrl )
    };

    reader.readAsDataURL(blob);
  }
}
