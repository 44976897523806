import { Component, OnInit } from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {Brinde} from "../../../../../server/domain/obj/Brinde";
import {BrindeService} from "../../../services/brinde.service";
import {PlanosService} from "../../services/planos.service";
import {ModalKendo} from "../../../lib/ModalKendo";
declare var _;

@Component({
  selector: 'app-cad-brinde',
  templateUrl: './cad-brinde.component.html',
  styleUrls: ['./cad-brinde.component.scss']
})
export class CadBrindeComponent extends ModalKendo implements OnInit {
  empresa: any;
  brinde: Brinde;
  planos: any;
  mensagemSucesso: any;
  mensagemErro: any;
  artigos = ['um', 'uma'];
  salvando = false;
  constructor(  private route: ActivatedRoute,
              private  brindeService: BrindeService, private planosService: PlanosService
              ) {
    super()
  }

  ngOnInit() {
    if(!this.brinde)
       this.setBrinde( new Brinde())

  }

  setBrinde(brinde: Brinde){
    this.brinde = brinde;

    this.planosService.obtenhaPlanos(this.empresa.id, 0).then( (resposta: any) => {
      this.planos = resposta.data;
      if(this.brinde.plano){
        this.planos.forEach( (plano) => {
          if(plano.id === this.brinde.plano.id)
            this.brinde.plano = plano;
        })
      }
    });
  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  onSubmit(frm) {
    if(this.salvando) return;

    if(frm.valid){
      this.salvando = true;
      delete this.mensagemErro;
      this.brinde.plano.empresa = this.empresa;
      this.brindeService.salveBrinde(this.brinde).then( resposta => {
        this.salvando = false;
        if(!this.brinde.id) this.brinde.id = resposta.id;
        this.fecheModal(this.brinde)
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro   = erro;
      })
    }else {
      this.mensagemErro = 'Verifique os campos obrigatórios'
    }
  }
}
