import { ConstantsService } from './../../fidelidade/ConstantsService';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  FileRestrictions,
  SelectEvent,
  SuccessEvent,
  UploadComponent,
  UploadProgressEvent
} from "@progress/kendo-angular-upload";
import {GeoService} from "../../services/geo.service";
import {EnderecoService} from "../../services/endereco.service";
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormasDeEntregaService } from '../../services/formas-de-entrega.service';
import {Router} from "@angular/router";
@Component({
  selector: 'app-form-upload-kml',
  templateUrl: './form-upload-kml.component.html',
  styleUrls: ['./form-upload-kml.component.scss']
})
export class FormUploadKmlComponent implements OnInit {
  msgErroUpload = '';
  restricoes: FileRestrictions = {
    allowedExtensions: ['.kml']
  };
  files: any;
  dadosMapa: any;

  enviandoArquivo = false;

  @Input() formaDeEntrega: any;
  @Output() enviouArquivo = new EventEmitter();
  @Output() falhouEnvioArquivo = new EventEmitter();
  @Output() terminouUpload = new EventEmitter();
  @Output() selecionouArquivo = new EventEmitter();
  @Output() atualizouAlcances = new EventEmitter();

  @Output() novaLocalizacao = new EventEmitter();

  @ViewChild('uploadComponent') upload: UploadComponent;
  endereco: any = {};
  testandoEndereco = false;
  msgErro = '';
  public windowTop = 100;
  public windowLeft = 50;
  public altura = 0;
  calculando: boolean;


  constructor(private geoService: GeoService, private enderecoService: EnderecoService,
    private formBuilder: UntypedFormBuilder, private formasDeEntregaService: FormasDeEntregaService,
    private constantsService: ConstantsService, private router: Router) {
  }

  ngOnInit(): void {
    this.altura = window.innerHeight - 200;

    this.geoService.obtenhaDadosMapa().then( (dados: any) => {
      this.dadosMapa = dados;
    }).catch( (erro) => {
    });
  }

  evtSelecionouArquivo($event: SelectEvent) {
    if( $event.files[0].extension !== '.kml') {
      return;
    }

    this.selecionouArquivo.emit($event);
  }

  uploadFalhou($event: any) {
    this.msgErroUpload = $event.response.error.erro;
    this.falhouEnvioArquivo.emit(this.msgErroUpload);
  }

  usuarioEnviouArquivo($event: SuccessEvent) {
    const respUpload = $event.response.body;

    if( respUpload.sucesso ) {
      this.enviouArquivo.emit(respUpload.data);
      this.formaDeEntrega.arquivoKML = respUpload.data.arquivo;

      this.dadosMapa = respUpload.data.projeto;
    }
  }

  progressoUpload($event: UploadProgressEvent) {
    console.log('upload', $event);
  }

  evtTerminouUpload($event: any) {
    this.terminouUpload.emit($event);
  }

  salvouEndereco(endereco: any) {
    this.calculando = true;
    this.testandoEndereco = false;

    this.enderecoService.calculeTaxaDeEntrega('Receber em casa', endereco, 100).toPromise().
    then( (resposta: any) => {
      this.calculando = false;

      if( !resposta.sucesso ) {
        this.novaLocalizacao.emit({
          localizacao: resposta.data,
          msg: resposta.erro
        });

        return;
      }

      this.novaLocalizacao.emit({
        localizacao: resposta.data.localizacao,
        msg: "Dentro da área de entrega: " + new Intl.NumberFormat('pt-BR',
          {style: 'currency', currency: 'BRL'}).format(resposta.data.taxaDeEntrega)
      });
      this.testandoEndereco = false;
    }).catch( (erro: any) => {
    });
  }

  fecharTelaTestar() {
    this.testandoEndereco = false;
  }

  abraTelaTestarEndereco() {
    this.testandoEndereco = true;
  }

  apagarArquivoKML() {
    this.geoService.apagueKML().then( (sucesso: any) => {
      this.formaDeEntrega.arquivoKML = '';
      this.formaDeEntrega.arquivoGeoJson = '';
      this.dadosMapa = {};
    }).catch( (erro) => {
    });
  }

  fnAtualizouAlcances(alcances) {
    this.atualizouAlcances.emit(alcances);
  }

  abraTelaCadastrarTaxasDeEntrega() {
    this.router.navigateByUrl('/admin/loja');
  }
}
