import {Component, Input, OnInit} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {CadPausasProgramadasComponent} from "../cad-pausas-programadas/cad-pausas-programadas.component";
import {DialogService} from "@progress/kendo-angular-dialog";

declare  var moment;
@Component({
  selector: 'app-tela-pausas-programadas',
  templateUrl: './tela-pausas-programadas.component.html',
  styleUrls: ['./tela-pausas-programadas.component.scss']
})
export class TelaPausasProgramadasComponent implements OnInit {
  @Input() empresa: any = {};
  mesesProgramados = [];
  constructor(private empresaService: EmpresasService, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.empresa.pausasProgramadas.forEach( (pausaProgramada: any) => {
      let mesDescricao = moment(pausaProgramada.dataInicio).format('MMMM');

      if(this.mesesProgramados.indexOf(mesDescricao) === -1)
        this.mesesProgramados.push(mesDescricao)


      pausaProgramada.mesDescricao = mesDescricao;

    })
  }

  novaPausa() {

    let windowRef: any = this.dialogService.open({
      title: 'Cadastrar Pausa Programada',
      content: CadPausasProgramadasComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: 500
    });

    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.windowRef = windowRef

    windowRef.result.subscribe((result: any) => {
      if(result){

      }
    })

  }

  editarPausa(pausaProgramada: any){
    let windowRef: any = this.dialogService.open({
      title: 'Cadastrar Pausa Programada',
      content: CadPausasProgramadasComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: 500
    });

    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.pausaProgramada = Object.assign({}, pausaProgramada);
    windowRef.content.instance.windowRef = windowRef

    windowRef.result.subscribe((result: any) => {
      if(result){
        Object.assign(pausaProgramada, result);
      }
    })
  }

  obtenhaPausasDoMes(mes: string) {
    return this.empresa.pausasProgramadas.filter( (pausaProgramada: any) => pausaProgramada.mesDescricao === mes)
  }

  obtenhaTempoIndisponivel(pausaProgramada: any) {
    let diffHoras = moment(pausaProgramada.dataFim).diff(moment(pausaProgramada.dataInicio), 'h');

    if(diffHoras <= 24)
       return String(`${diffHoras} horas`)

    let dias = moment(pausaProgramada.dataFim).diff(moment(pausaProgramada.dataInicio), 'd');

    if(dias <= 1)
      return String(`${dias} dia`)

    return String(`${dias} dias`)

  }
}
