<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fe-search ml-2"></i> Buscar contatos
</h4>

<div class="row">
  <div class="col-12">
    <div class="card filtro-avancado" >
      <div class="card-body">
        <div class="row pb-4">
          <div class="col-xl-2 col-12">
            <label>Tipo de Cliente</label>
          </div>
          <div class="col-xl-10 col-12">
            <span *ngFor="let tipo of tiposClientes" class="mr-2">
              <input type="radio" [id]="'status' + tipo.label" name="status"  [value]="tipo"  [(ngModel)]="filtro.status.valor" class="k-radio" kendoRadioButton/>
              <label class="k-radio-label" [for]="'status' + tipo.label">{{tipo.label}}</label>
            </span>
          </div>
        </div>

        <div class="row  pb-4 ">
          <div class="col-xl-2  col-12">
            <label>Plano</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-12 col-xl-4">
                <kendo-combobox id="plano" name="plano" [(ngModel)]="filtro.plano.valor" [data]="planos" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione um Plano" class="form-control"
                                textField="label"  style="border: none;"
                >
                </kendo-combobox>
              </div>
            </div>
          </div>
        </div>

        <div class="row  pb-4 " *ngIf="tags.length">
          <div class="col-xl-2  col-12">
            <label>Tag</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-12 col-xl-4">
                <kendo-combobox id="tags" name="tags" [(ngModel)]="filtro.tag.valor" [data]="tags" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione uma tag" class="form-control"
                                textField="label"  style="border: none;"
                >
                </kendo-combobox>
              </div>
            </div>
          </div>
        </div>

        <div class="row  pb-4">
          <div class="col-xl-2  col-12">
            <label>DDD</label>
          </div>
          <div class="col-xl-10 col-12">
            <input type="checkbox" id="apenasDddLocal" name="apenasDddLocal" class="k-checkbox "   [(ngModel)]="filtro.apenasDddLocal.valor" />
            <label class="k-checkbox-label" for="apenasDddLocal">Apenas clientes do DDD local</label>
          </div>
        </div>


        <div class="row  pb-4 ">
          <div class="col-xl-2  col-12">
            <label>Aniversariantes </label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-12 col-xl-8">
                <kendo-daterange>
                  <label>
                    <span class="label"></span>
                    <kendo-dateinput kendoDateRangeStartInput [(value)]="filtro.aniversario.inicio"></kendo-dateinput>
                  </label>
                  <label>
                    <span class="label ml-2 mr-2">Até </span>
                    <kendo-dateinput kendoDateRangeEndInput [(value)]="filtro.aniversario.fim"></kendo-dateinput>
                  </label>
                </kendo-daterange>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4 linha-top">
          <div class="col-xl-2 col-12">
            <label>Última compra</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-4 col-xl-4">
                <kendo-combobox id="ultimasOperador" name="ultimasOperador" [(ngModel)]="filtro.ultimas.operador" [data]="operadoresMais"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"   style="border: none;" [suggest]="true"
                                [valueField]="'value'" (selectionChange)="vaiUsarFiltro(filtro.ultimas,valores,unidades)">
                </kendo-combobox>
              </div>
              <div class="col-4  col-xl-2">
                <kendo-combobox id="ultimasValor" name="ultimasValor" [(ngModel)]="filtro.ultimas.valor" [data]="valores"
                                placeholder="Selecione" class="form-control" [suggest]="true" textField="label"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
              <div class="col-4  col-xl-2">
                <kendo-combobox id="ultimasUnidade" name="ultimasUnidade" [(ngModel)]="filtro.ultimas.unidade" [data]="unidades"
                                [allowCustom]="false" placeholder="Selecione" class="form-control" [suggest]="true"
                                textField="label"  style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
              <div class="col-xl-2  d-none d-sm-block">
                <label class="atras">atrás</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="col-xl-2  col-12 ">
            <label>Sexo</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-12 col-xl-4">
                <kendo-combobox id="sexo" name="sexo" [(ngModel)]="filtro.sexo.valor" [data]="sexos" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"  style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
            </div>

          </div>
        </div>

        <div class="row pt-4 pb-4">
          <div class="col-xl-2  col-12">
            <label>Brindes</label>
          </div>
          <div class="col-xl-10 col-12">
            <input type="checkbox" id="jaTrocou" name="jaTrocou" class="k-checkbox "   [(ngModel)]="filtro.jaTrocou.valor" />
            <label class="k-checkbox-label" for="jaTrocou">Já trocou por brindes</label>

            <input type="checkbox" id="podeTrocar" name="podeTrocar" class="k-checkbox ml-2" [(ngModel)]="filtro.podeTrocar.valor" />
            <label class="k-checkbox-label ml-2" for="podeTrocar">Pode trocar por brindes</label>


          </div>
        </div>

        <div class="row pt-4 linha-top">
          <div class="col-xl-2 col-12">
            <label>Pontuação</label>
          </div>
          <div class="col-xl-10 col-12">

            <div class="row">
              <div class="col-8 col-xl-4">
                <kendo-combobox id="operadorPontuacao" name="operadorPontuacao" [(ngModel)]="filtro.pontuacao.operador" [data]="operadores"
                                [suggest]="true" [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"  style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
              <div class="col-4 col-xl-2">
                <input type="number" name="valorPontuacao" class="form-control" [(ngModel)]="filtro.pontuacao.valor" >
              </div>
            </div>

          </div>
        </div>

        <div class="row pt-4">
          <div class="col-xl-2 col-12">
            <label>Ticket médio</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-8 col-xl-4">
                <kendo-combobox id="operadorTicketMedio" name="operadorTicketMedio" [(ngModel)]="filtro.ticketMedio.operador" [data]="operadores" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"  style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
              <div class="col-4 col-xl-2">
                <input type="number" name="valorTicketmedio"  class="form-control" [(ngModel)]="filtro.ticketMedio.valor" >
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="col-xl-2 col-12">
            <label>Total comprado</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-8 col-xl-4">
                <kendo-combobox id="operadorTotalcomprado" name="operadorTotalcomprado" [(ngModel)]="filtro.totalComprado.operador" [data]="operadores" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"  style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
              <div class="col-4 col-xl-2">
                <input type="number" name="valorTotalcomprado" class="form-control" [(ngModel)]="filtro.totalComprado.valor" >
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="col-xl-2 col-12">
            <label>Qtd. Compras</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-8 col-xl-4">
                <kendo-combobox id="operadorQtdcomprado" name="operadorQtdcomprado" [(ngModel)]="filtro.qtdComprado.operador" [data]="operadores" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"   style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
              <div class="col-4 col-xl-2">
                <input type="number" name="valorQtdcomprado"  class="form-control" [(ngModel)]="filtro.qtdComprado.valor" >
              </div>
            </div>

          </div>
        </div>

        <div class="row pt-4 pb-4">
          <div class="col-xl-2 col-12">
            <label>Não foi notificado</label>
            <label >Nos Últimos</label>
          </div>
          <div class="col-xl-10 col-12">
            <div class="row">
              <div class="col-6 col-xl-4 ultimos">
                <kendo-combobox id="valorNN" name="valorNN" [(ngModel)]="filtro.naoNotificado.valor" [data]="valores" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"   style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>

              <div class="col-6 col-xl-2">
                <kendo-combobox id="unidadeNN" name="unidadeNN" [(ngModel)]="filtro.naoNotificado.unidade" [data]="unidades" [suggest]="true"
                                [allowCustom]="false" placeholder="Selecione" class="form-control"
                                textField="label"  style="border: none;"
                                [valueField]="'value'">
                </kendo-combobox>
              </div>
            </div>
          </div>
        </div>

        <span [hidden]="!tiposPets.length">
            <div class="row pt-4 linha-top"  >
                <div class="col-xl-2 col-12">
                  <label>Tipos de Pet</label>
                </div>
                <div class="col-12 col-xl-4">
                      <kendo-combobox id="tipoPet" name="tipoPet" [(ngModel)]="filtro.tipoPet.valor" [data]="tiposPets" [suggest]="true"
                                      [allowCustom]="false" placeholder="Selecione o tipo" class="form-control"
                                      style="border: none;">
                      </kendo-combobox>
                    </div>
           </div>
            <div class="row pt-4  "  >
                 <div class="col-xl-2 col-12">
                    <label>Genero do Pet</label>
                  </div>
                 <div class="col-12 col-xl-4">
                     <kendo-combobox id="generoPet" name="generoPet" [(ngModel)]="filtro.generoPet.valor" [data]="generosPets" [suggest]="true"
                                     [allowCustom]="false" placeholder="Selecione o genero" class="form-control"
                                     style="border: none;">
                      </kendo-combobox>
                 </div>
            </div>
        </span>

      </div>
    </div>
  </div>
  <div class="col-12 mb-4">
    <button class="btn btn-blue width-lg" (click)="filtre()" >Pesquisar</button>
    <button class="btn btn-white width-lg" (click)="voltar()">Cancelar</button>
  </div>
</div>
