import { Injectable } from '@angular/core';
import {ClienteService} from "./cliente.service";
import {NotificacoesService} from "./notificacoes.service";
import {StatusWhatsapp} from "../objetos/StatusWhatsapp";
import {BehaviorSubject} from "rxjs";
import {ArmazenamentoService} from "./armazenamento.service";

class StatusEnvio {
  enviando: boolean;
  horario: Date = new Date();

  enviou() {
    this.enviando = false;
  }

  enviandoMsg() {
    this.enviando = true;
    this.horario = new Date();
  }

  estahEnviando() { //se tempo menor que 10 segundos
    const tempo = new Date().getTime() - this.horario.getTime();

    return this.enviando && tempo < 10000;
  }
}


@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  statusEnvio = new StatusEnvio();
  mensagens = [];
  mapMensagens = {};

  private status = new BehaviorSubject<StatusWhatsapp>(new StatusWhatsapp(false, false, false, null));
  private novaMensagem = new BehaviorSubject<any>({});

  status$ = this.status.asObservable();
  novaMensagem$ = this.novaMensagem.asObservable();

  constructor(private clienteService: ClienteService, private notificacoesService: NotificacoesService,
              private armazenamentoService: ArmazenamentoService) { }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public envieMensagemNoWhatsapp(msg: any) {
    console.log('envieMensagem', msg);

    if( this.statusEnvio.estahEnviando() ) {
      console.log('\t\tdescartando mensagens');

      this.obtenha(msg.id).then( (msgObtida: any) => {
        console.log('msg obtida', msgObtida);
        if( !msgObtida ) {
          this.mensagens.push(msg);
        }
      });

      return;
    }

    this.obtenha(msg.id).then( (msgObtida: any) => {
      if( msgObtida && msgObtida.status === 'ENVIADA' ) {
          return this.pegueProximaMensagem({
            sucesso: true,
            idMensagem: msg.id,
            msg: msg
          }, 0);
      }

      msg.status = 'ENVIANDO';

      this.salveMensagem(msg).then( () => {
        this.notifiqueIframeEnviarMsg(msg);
      });
    });
  }

  public enviouMensagem(respostaEnvio: any) {
    return new Promise<void>( (resolve, reject) => {
      console.log('enviou Mensagem', respostaEnvio);

      //this.enviando = false;
      this.statusEnvio.enviou();
      this.novaMensagem.next(respostaEnvio.msg);

      let delay = 0;

      let status = 'NOVA';
      if( respostaEnvio && (respostaEnvio.sucesso || respostaEnvio.status !== 'NAO_PRONTO') ) {
        status = respostaEnvio.status;

        this.notifiqueServidorQueEnviouMensagens(respostaEnvio.idMensagem, respostaEnvio.status, respostaEnvio.idw).then( () => {
        });
      } else { //enviar novamente
        this.mensagens.unshift(respostaEnvio.msg);
        delay = 2000;
      }

      const msg = respostaEnvio.msg;

      msg.status = status;

      this.salveMensagem(msg).then( async () => {
        resolve();

        console.log('qtde mensagens: ' + this.mensagens.length);

        await this.pegueProximaMensagem(respostaEnvio, delay);
      });
    });
  }

  public async pegueProximaMensagem(respostaEnvio: any, delay: number) {
    if( this.mensagens.length > 0 ) {
      await this.sleep(delay);

      const novaMensagem = this.mensagens.shift();

      console.log('pegando próxima da fila', novaMensagem);

      this.envieMensagemNoWhatsapp(novaMensagem);
    } else { //fila está vazia
      console.log('obtendo proxima do server');

      this.obtenhaProximaMensagemNoServidor(respostaEnvio, respostaEnvio.msg);
    }
  }

  public obtenhaProximaMensagemNoServidor(respostaEnvio: any, msg: any) {
    if( !msg.id ) {
      return;
    }

    let tempo = 0;
    if( respostaEnvio.msg.tipoDeNotificacao === 'Marketing' ) {
      tempo = Math.floor(Math.random() * 3000) + 1000;
    }

    setTimeout(() => {
      this.clienteService.proximaMensagem(msg.id).then( () => {});
    }, tempo);
  }

  private async aviseEnvieiMensagens(idMensagem: string, status: string, idWhatsapp: string) {
    return this.clienteService.envieiMensagem(idMensagem, status, idWhatsapp);
  }

  private async notifiqueServidorQueEnviouMensagens(idMensagem: string, status: string, idWhatsapp: string) {
    if( !idMensagem ) {
      return;
    }

    let resposta = null;

    try {
      resposta = await this.aviseEnvieiMensagens(idMensagem, status, idWhatsapp);
    } catch( erro ) {
      console.log('Erro ao tentar notificar servidor');
      console.log(erro);

      if (!resposta) {
        setTimeout(() => {
          this.notifiqueServidorQueEnviouMensagens(idMensagem, status, idWhatsapp);
        }, 3000);
      }
    }
  }

  obtenha(idMensagem: number) {
    return new Promise( (resolve, reject) => {
      if( !idMensagem ) {
        return resolve(null);
      }

      const msg = this.mapMensagens[idMensagem];

      resolve(msg);
    });
  }

  salveMensagem(mensagem: any) {
    return new Promise<void>( (resolve, reject) => {
      if( !mensagem.id ) {
        return resolve();
      }

      const objeto = {
        id: mensagem.id,
        status: mensagem.status
      };

      if (mensagem.status === 'ENVIADA') {
        const idMensagemNumero = parseInt(this.armazenamentoService.carregue('idm2', false), 10);

        console.log('[WhatsappService] id: ' + mensagem.id + " -> " + idMensagemNumero);

        this.armazenamentoService.salveSemExpirar('idm2', mensagem.id);
      }

      this.mapMensagens[mensagem.id] = objeto;
      resolve();
    });
  }

  public notifiqueIframeEnviarMsg(msg: any) {
    this.statusEnvio.enviandoMsg();

    if( msg.imagem ) {
      this.toDataUrl(this.obtenhaUrlServidor() + msg.imagem, (dados: any) => {
        msg.dadosImagem = dados;
        window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: msg}, "*");
      });
    } else {
      window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: msg}, "*");
    }
  }

  private toDataUrl(url, callback) {
    console.log('baixando imagem: ' + url);

    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  public obtenhaUrlServidor() {
    return window.location.protocol + '//' + window.location.hostname +
      (window.location.port ?  ':' + window.location.port : '');
  }


  obtenhaRespostaMensagemLista(empresa: any, contato: any, opcao: any): Promise<any> {
    return this.notificacoesService.obtenhaRespostaMensagemLista(empresa, contato, opcao);
  }

  processeMensagemSaudacao(empresa: any, contato: any): Promise<any> {
    return this.notificacoesService.obtenhaMensagemSaudade(contato);
  }

  processeMensagemCodigoValidacao(empresa: any, contato: any): Promise<any> {
    return this.notificacoesService.obtenhaMensagemCodigoValidacao(contato);
  }

  monitoreStatusIntegracao() {
    console.log('[Whatsapp.Service] checando se status whtasapp:');

    window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {tipo: 'STATUS_WHATSAPP'}}, "*");

    setTimeout(() => {
      this.monitoreStatusIntegracao();
    }, 10000);
  }

  mudeStatus(data: StatusWhatsapp) {
    this.status.next(data);
  }

  marqueDigitando(telefone: string) {
    window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {
      tipo: 'DIGITANDO',
      telefone: telefone
    }}, "*");
  }

  notifiqueMensagensAntigas() {
    window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {
        tipo: 'NOTIFIQUE_MENSAGENS_ANTIGAS'
      }}, "*");
  }

  abraConversa(telefone) {
    window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {
        tipo: 'ABRA_CHAT',
        telefone: telefone
      }}, "*");
  }
}
