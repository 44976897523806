<div class="mt-1 pontos"  >
  <div class="row contato" *ngIf="contato.id">

    <div class="col-5">
      <card-contato [contato]="contato"></card-contato>
    </div>

    <div class="col-7" >
      <div class="card-box text-center" style="padding: 0px;">
        <div class="row">
          <div class="col-12 col-sm-5" *ngIf="cartao">
            <saldo-cartao [cartao]="cartao"  class="pontos"></saldo-cartao>

          </div>
          <div class="col-12 col-sm-7">
            <span class="text-black ultima"  >{{ contato.ultimaVisita | date: 'dd/MM/yyyy HH:mm' }}</span>
            <label>Última visita </label>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="linha"></div>

  <div>

    <div class="form-group mt-3" *ngIf="contato.id && contato.cartoes.length > 1">
      <label for="cartao">Plano Fidelidade:  </label>
      <kendo-combobox id="cartao" name="cartao" [(ngModel)]="cartao"  [data]="contato.cartoes"   [required]="contato.cartoes && contato.cartoes.length > 1"
                      [allowCustom]="false" [clearButton]="false"   placeholder="Selecione um Plano" class="form-control"  (ngModelChange)="alterouPlano()"
                      [kendoDropDownFilter]="filterSettings"  textField="nomePlano"   [disabled]="pontuando ">


      </kendo-combobox>
      <div class="invalid-feedback">
        Plano é obrigatório
      </div>
    </div>

    <div class="form-group mt-3"  >
      <label  >Atividades   </label>
      <kendo-multiselect id="atividade" name="atividade"  #atividade   #atividadeModel=ngModel   [(ngModel)]="pontuacaoRegistrada.atividades" [data]="cartao?.atividades"
                      [filterable]="true" [allowCustom]="false"   placeholder="Selecione uma ou mais atividades" class="form-control"
                      [required]="cartao?.atividades?.length != 1"   (ngModelChange)="aletrouAtividade($event)"
                      [kendoDropDownFilter]="filterSettings"
                      [disabled]="!cartao.id || pontuando" [readonly]="cartao?.atividades?.length === 1"
                      [valueField]="'id'" [textField]="'nome'">
      </kendo-multiselect>

      <div class="invalid-feedback"  >
        Selecione pelo menos uma atividade.
      </div>
    </div>

    <div class="row" *ngIf="informarPontuacao()">
      <div class="form-group mb-3 col-9">
        <label  >Pontuação ganha   </label>
        <div class="input-group">
          <kendo-numerictextbox [attr.type]="'number'"    class="form-control" format="n0" decimals="0"   name="pontosInformado"
                                [(ngModel)]="pontuacaoRegistrada.pontosInformado"    [disabled]="pontuando"
                                #pontosInformado appAutoFocus  (ngModelChange)="valorMudou()"     >
          </kendo-numerictextbox>

          <div class="invalid-feedback" >
            Informe a pontuação
          </div>
        </div>
      </div>
    </div>

    <div  class="row" *ngIf="cartao.plano && cartao.plano.referenciaExterna">
      <div class="form-group mb-3 col-9">
        <label  >Referência externa   </label>
        <input  name="referenciaExterna" [(ngModel)]="pontuacaoRegistrada.referenciaExterna"   class="form-control" appAutoFocus
                   [required]="cartao.plano?.referenciaExterna" [disabled]="pontuando "/>
        <div class="invalid-feedback" >
          Informe uma referência externa
        </div>
      </div>

    </div>

    <div class="row troca" *ngIf="cartao && usarSaldo">
      <div class="form-group col-12 mb-3 " >
        <label>Valor total</label>

        <div class="input-group">
          <kendo-numerictextbox name="valorFinal"   format="c2"     class="form-control" [(ngModel)]="caixa.totalVenda"
                                [min]="0"       [required]="usarSaldo" (ngModelChange)="recalculeComCashback()"  [disabled]="pontuando ">
          </kendo-numerictextbox>
        </div>
      </div>


      <div class="form-group col-12  mb-3" >
        <label>Saldo utilizado</label>

        <div class="input-group">
          <kendo-numerictextbox id="saldoUsar" name="saldoUsar" #saldoUsar=ngModel [(ngModel)]="caixa.saldo"  format="c2" class="form-control" required
                 (ngModelChange)="recalculeComCashback()"  [max]="cartao.pontos" [min]="0"
                 [required]="usarSaldo"   [disabled]="pontuando "   >

          </kendo-numerictextbox>

          <div class="invalid-feedback" >
            <span *ngIf="saldoUsar.errors?.required">Informe  o saldo a ser utilizado </span>
            <span *ngIf="saldoUsar.errors?.maxError">Valor  máximo é  {{saldoUsar.errors.maxError.maxValue   | currency: "BRL"}} </span>
            <span *ngIf="saldoUsar.errors?.minError">Valor  mínimo é  {{saldoUsar.errors.minError.minValue  | currency: "BRL"}} </span>
          </div>
        </div>
      </div>

      <div class="form-group mb-3 col-6"  >
        <label  >Valor Venda</label>

        <div class="input  mb-3 ml-2">
            {{pontuacaoRegistrada.valor | currency: "BRL"}}
        </div>

        <button type="button" class="btn btn-outline-danger btn-sm" (click)="cancelarUsoSaldo($event)" [disabled]="pontuando ">
          <b>Cancelar uso do saldo</b>   </button>

      </div>


      <div class="form-group col-6 text-center mb-3">
        <label class="mb-0"  >
          {{getLabel()}}
        </label>
        <div class="mt-1">
          <span class="text-black font30">  {{ getPontos()}}</span><br>
        </div>
      </div>

    </div>

    <div class="row venda mb-3"  *ngIf="!usarSaldo">
      <div class="form-group  col-6"  >
        <label>Valor Venda</label>
        <div class="input-group">
          <kendo-numerictextbox [attr.type]="'number'" id="valorVenda" #valorVenda  class="form-control" format="c2"   name="valorVenda"
                                 [(ngModel)]="pontuacaoRegistrada.valor"   [disabled]="usarSaldo || pontuando"
                                 required (ngModelChange)="valorMudou()"  (keyup.enter)="submit($event)"  >
          </kendo-numerictextbox>
          <div class="invalid-feedback" >
            Informe um valor de venda.
          </div>
        </div>


      </div>

      <div class="form-group col-6 text-center ">
        <label class="mb-0"  >
          {{getLabel()}}
        </label>
        <div class="mt-1">
          <span class="text-black font30"> {{ getPontos() }}</span><br>
        </div>
      </div>

      <div   *ngIf="podeUsarSaldo()" class="mt-1 col-12">
        <button type="button" class="btn btn-outline-dark " (click)="usarSaldoCartao($event)" [disabled]="pontuando ">
          Usar saldo <b>{{ cartao.pontos | currency:"BRL"}}</b></button>
      </div>
    </div>

  </div>
</div>

