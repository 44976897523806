export enum EnumTipoDeOrigem {
  Cadastrado = 'cadastrado',
  ImportadoCsv = 'csv',
  ImportadoIfood = 'ifood',
  ImportadoEcletica = 'ecletica',
  ImportadoBluesoft = 'bluesoft',
  ImportadoSaipos = 'saipos',
  ImportadoMenew = 'menew',
  ImportadoRPInfo = 'rpinfo',
  ImportadoChinaInBox = 'chinainbox',
  ImportadoTeknisa= 'teknisa',
  ImportadoGcom = 'gcom',
  ImportadoOpenDelivery= 'opendelivery',
}
