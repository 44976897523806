<button class="btn btn-blue float-right" (click)="novaPausa()" > Nova Pausa Programada</button>

<div class="clearfix"></div>

<div class="alert alert-info mt-2 text-center" role="alert" *ngIf="!empresa.pausasProgramadas.length">
  <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
    <span aria-hidden="true">&times;</span>
  </button>
  <i class="fa fa-clock fa-lg mr-2"></i>
  Nenhuma pausa programada
</div>


<div class="mt-2"  *ngFor="let mes of mesesProgramados">
  <h3><i class="fa fa-calendar-alt mr-2"></i> {{mes}}</h3>
  <div class="card-box cpointer" *ngFor="let pausaProgramada of obtenhaPausasDoMes(mes)" (click)="editarPausa(pausaProgramada)"
   [hidden]="pausaProgramada.removida" [ngClass]="{'ativa' : pausaProgramada.agora}">
    <div class="row "  >
      <div class="col-6">
        <h4> {{pausaProgramada.descricao}}</h4>
        <p class="text-muted">
          Indisponível por {{obtenhaTempoIndisponivel(pausaProgramada)}}
        </p>
      </div>
      <div class="col-6">

        <div class="row" style="width: 250px;">
          <div class="col">
            {{pausaProgramada.dataInicio | date: "dd/MM/yyyy"}}
            <br>
            <span class="text-muted">
              De {{pausaProgramada.dataInicio | date: "HH:mm"}}
           </span>

            <i class="fa fa-arrow-right fa-lg" style="    position: relative;right: -30px;top: -10px;}"></i>
          </div>

          <div class="col">
            {{pausaProgramada.dataFim | date: "dd/MM/yyyy"}}   <br>
            <span class="text-muted">
                Até {{pausaProgramada.dataFim | date: "HH:mm"}}
             </span>
          </div>

        </div>


      </div>

    </div>
  </div>

</div>
