import {ApplicationRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {UsuarioService} from "../../services/usuario.service";
import {ApplicationComponent} from "aws-sdk/clients/applicationinsights";

@Component({
  selector: 'app-tela-sdr',
  templateUrl: './tela-sdr.component.html',
  styleUrls: ['./tela-sdr.component.scss']
})
export class TelaSdrComponent implements OnInit {
  @ViewChild('frm',  {static: false} ) frm: NgForm;

  assinatura: string;
  usuario: any = {
    assinarMensagens: false
  };
  msgSucesso = '';
  msgErroMarcarLabel = '';

  constructor(private app: ApplicationRef, private autorizacaoService: AutorizacaoService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.autorizacaoService.usuarioLogado$.subscribe( (usuarioLogado) => {
      if( !usuarioLogado ) return;

      this.usuario = usuarioLogado;

      this.notifiqueExtensaoAssinarMensagens();
    });
  }

  salveAlteracao() {
    this.usuarioService.salveAssinatura(this.usuario.assinarMensagens).then( (resposta: any) => {
      this.msgSucesso = 'Salvou assinatura';
      this.autorizacaoService.atualizeUsuario(this.usuario);
      setTimeout( () => {
        this.msgSucesso = '';
      }, 2000);

      this.notifiqueExtensaoAssinarMensagens();
    });
  }

  notifiqueExtensaoAssinarMensagens() {
    window.parent.postMessage({
      tipo: "NOVA_MENSAGEM", text: {
        tipo: 'SALVAR_ASSINATURA',
        assinatura: this.usuario.assinarMensagens ? this.usuario.nome : ''
      }
    }, "*");
  }

  async marcarLabel() {
    if( window['root'].chatAtual.isGroup ) {
      this.msgErroMarcarLabel = 'Não funciona em grupos';
      setTimeout( () => {
        this.msgErroMarcarLabel = '';
      }, 2000);

      return;
    }

    window.parent.postMessage({
      tipo: "NOVA_MENSAGEM", text: {
        tipo: 'ADICIONE_TAG_CONTATO',
        telefone: window['root'].chatAtual.id._serialized,
        tag: this.usuario.nome
      }
    }, "*");
  }

  msgNaoAtendeu() {

  }

  msgAtendeu() {

  }
}
