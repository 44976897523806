export class FiltroPedidoUltis{
  static obtenhaDados(inicio: number, total: any, filtro: any,
                      filtroPedido: any){
    let dados: any = { i: inicio, t: total};

    if(filtro.emAberto) dados.a = true;
    if(filtro.apenasEncerrados) dados.e = true;
    if(filtro.orderByDesc) dados.odbd = true;
    if(filtro.naoImpressos) dados.ni = true;

    if(filtro.pagosENaoPagos) dados.pgnpg = true;
    else if(filtro.apenasPagos) dados.pg = true;
    else  if(filtro.naoPagos) dados.snp = true;

    if(filtro.contato) dados.cid = filtro.contato.id;
    if(filtro.dtInicio) dados.di = filtro.dtInicio;
    if(filtro.dtFim) dados.df = filtro.dtFim;
    if(filtro.dia) dados.dia = filtro.dia;
    if(filtro.ultimoPedido) dados.ultimo = filtro.ultimoPedido;
    if(filtro.ultimaAtualizacao) dados.hu = filtro.ultimaAtualizacao;
    if(filtro.origens)  dados.o = filtro.origens.join(',');
    if(filtro.destino)  dados.des = filtro.destino;
    if(filtro.formaDeEntrega) dados.fe = filtro.formaDeEntrega.id;
    if(filtro.formasDePagamento) dados.fps = filtro.formasDePagamento.join(',')
    if(filtro.onlineAguardando) dados.onids = filtro.onlineAguardando.join(',')
    if(filtro.cupons) dados.cps = filtro.cupons.join(',')
    if(filtro.q) dados.q = filtro.q
    if(filtro.r) dados.r = 1;

    if(filtro.avisos) dados.av = 1;

    dados.f = filtroPedido;

    return dados
  }
}
