import {AfterViewInit, ApplicationRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ContratoService} from "../superadmin/services/contrato.service";
import {CadCartaocreditoComponent} from "../fidelidade/cad-cartaocredito/cad-cartaocredito.component";
import {ModalKendo} from "../lib/ModalKendo";


@Component({
  selector: 'app-ativacao-assinatura',
  templateUrl: './ativacao-assinatura.component.html',
  styleUrls: ['./ativacao-assinatura.component.scss']
})
export class AtivacaoAssinaturaComponent extends ModalKendo implements OnInit , AfterViewInit{
  @ViewChild('cccompoment') cccompoment: CadCartaocreditoComponent;
  contrato: any = { assinatura: {}};
  empresa: any = {  };
  ativando: boolean;
  erro: string;
  pago: boolean;
  reload = true;
  mensagemCopiada: string;
  mensagemAtivacao: string;
  constructor(private contratoService: ContratoService,
              private elRef: ElementRef,
              private app: ApplicationRef ) {
    super()
  }

  ngOnInit() {
    this.pago = this.contrato.proximaFatura && this.contrato.proximaFatura.paga;

    if(!this.contrato || !this.contrato.assinatura || this.contrato.pagaNoCartao){
      this.mensagemAtivacao = 'cartao'
    } else if(this.contrato.pagarViaPix){
      this.mensagemAtivacao = 'pix'
    } else if(this.contrato.pagaNoBoleto){
      this.mensagemAtivacao = 'boleto'
    }
  }

  ngAfterViewInit() {
    const modalContentHeight = this.elRef.nativeElement.offsetHeight;
    const windowHeight = window.innerHeight;

    if (modalContentHeight > windowHeight) {
      //this.elRef.nativeElement.style.overflowY = 'scroll';
    }
  }

  ativeViaCartao(cartao: any) {
    this.fecheMensagemErro();

    if(this.ativando) return;

    this.ativando = true;
    this.contratoService.ativeContratoViaCartao(this.contrato, cartao).then( resp => {
      this.ativando = false;
      this.pago = true;

      if(this.reload){
        setTimeout( () => {
          window.location.reload();
        }, 1000);
      }
      this.app.tick();

    }).catch( erro => {
      this.erro = erro;
      this.ativando = false;
      this.cccompoment.canceleProcessando();
    })
  }

  fecheMensagemErro() {
    delete this.erro;
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.mensagemCopiada = val;

    setTimeout( () => {
       delete this.mensagemCopiada
    }, 5000);
  }
}
