import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ContatosService} from "../services/contatos.service";

@Component({
  selector: 'app-comprovante-pontuacao',
  templateUrl: './comprovante-pontuacao.component.html',
  styleUrls: ['./comprovante-pontuacao.component.scss', '../componentes/imprimir.component.scss']
})
export class ComprovantePontuacaoComponent implements OnInit {
  comprovante: any = {};

  constructor(private contatosService: ContatosService,  private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let codigo = params['codigo'];

      this.contatosService.obtenhaComprovantePontucao(codigo).then( (comprovante) => {
        this.comprovante = comprovante;
      });
    });
  }

}
