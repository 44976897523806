<kendo-dropdownlist
  [data]="countries"
  [textField]="'alpha2'"
  [valueField]="'alphaPhone'"
  [(ngModel)]="selectedCountry"
  [popupSettings]="{
        width: 200,
        animate: false
      }"
  [class]="'custom-dropdown'"
  (valueChange)="onCountryChange($event)">
  <ng-template kendoDropDownListValueTemplate  let-dataItem>
    <div class="country-item" *ngIf="dataItem && dataItem.flag">
      <img [src]="dataItem ? dataItem.flag : ''" alt="{{dataItem.alpha2}}" width="20" height="20" />
       ({{ dataItem.phone }})
    </div>
  </ng-template>
  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <div class="country-item" *ngIf="dataItem">
      <img [src]="dataItem.flag" alt="{{dataItem.alpha2}}" width="20" height="20" />
      {{ dataItem.alpha2 }} ({{ dataItem.phone }})
    </div>
  </ng-template>
</kendo-dropdownlist>
