<ng-container *ngIf="novoPrecoExibir">
  <span class="riscado text-muted">{{precoExibir | currency:"BRL"}}</span>
  <span class="text-success"><b>
              {{novoPrecoExibir | currency:"BRL"}}</b>
           </span>
</ng-container>

<ng-container *ngIf="!novoPrecoExibir">
         <span class="text-success"><b>{{produto.valorMinimo? "A partir de " : ""}}

           <ng-container *ngIf="!atualizarPreco">
                {{precoExibir  | currency: "BRL"}}

                <i class="fa fa-edit fa-lg text-muted" (click)="editarPreco()" *ngIf="!produto.valorMinimo && podeEditar"></i>
           </ng-container>

            <ng-container *ngIf="atualizarPreco">
              <kendo-numerictextbox id="valorPorPonto" [min]="0"  class="form-control" name="preco" #preco=ngModel
                                         [format]="obtenhaFormatoPreco()"   [(ngModel)]="precoEditado" required>
              </kendo-numerictextbox>

              <div class="btn-group">
                  <button class="btn btn-sm mt-1 btn-block btn-success" (click)="salvarPreco()"
                   [disabled]="salvando">
                    <ng-container *ngIf="!salvando">salvar</ng-container>
                    <i class="k-icon k-i-loading" *ngIf="salvando"></i></button>
                  <button class="btn btn-sm mt-1 btn-block btn-light" (click)="cancelarPreco()" [disabled]="salvando">cancelar</button>
              </div>


            </ng-container>

          </b>
       </span>
</ng-container>




