asdfasdfasdf

<div class="bg-light p-1 pl-2 headerCampo mt-2">
  <h4>Tamanho
    <div class="float-right">
              <span class="badge" [class.badge-danger]="!itemPedido.produtoTamanho"
                    [class.badge-success]="itemPedido.produtoTamanho">OBRIGATÓRIO</span>
    </div>
  </h4>
  <span    class="text-muted">Escolha 1 opção</span>
</div>

<p class="alert alert-danger mt-1" role="alert" *ngIf="exibirErros && !itemPedido.produtoTamanho ">
  <i class="fa fa-times mr-2"></i> Escolha o tamanho
</p>

<div class="mt-2 ml-0 radio radio-blue" >
  <div *ngFor="let tamanho of produto.tamanhos; let i = index; let last = last" [class.mb-2]="!last" [class.mt-2]="!last" style=""
       [hidden]="!tamanho.disponivel">


    <label for="campo_tamanho_{{i}}" class="mb-1">
      <input id="campo_tamanho_{{i}}" [(ngModel)]="itemPedido.produtoTamanho" (ngModelChange)="alterouTamanhoProduto()"
             name="campo_tamanho_{{i}}" type="radio" kendoRadioButton
             [value]="tamanho"     [required]="true"/>

      <span class="nome_opcao">{{tamanho.descricao}}</span>

      <span class="badge badge-primary ml-1 preco-extra" *ngIf="tamanho.preco">&nbsp;
                  +{{tamanho.preco | currency:'BRL'}}</span>
    </label>

    <br>
    <span class="text-muted descricao" style="margin-left: 33px;display:block">
      {{itemPedido.produto.template.identificador}} {{tamanho.descricao}}  {{tamanho.qtdePedacos}} {{tamanho.qtdePedacos  > 1 ? 'pedaços':'pedaço'}}
    </span>

  </div>
</div>

<div class="bg-light p-1 pl-2 headerCampo mt-2"   >
  <h4> Quantidade de sabores
    <div class="float-right">
              <span class="badge" [class.badge-danger]="!itemPedido.produtoTamanho"
                    [class.badge-success]="itemPedido.produtoTamanho">OBRIGATÓRIO</span>
    </div>
  </h4>

</div>


<div class="mt-2 ml-0 radio radio-blue" >
  <div *ngFor="let qtde  of obtenhaQtdeSabores(); let i = index; let last = last" [class.mb-2]="!last" [class.mt-2]="!last" style="">
    <label>
      <input id="qtde_sabor_{{i}}" [(ngModel)]="qtdeSabores" (ngModelChange)="alterouQtdeSabores()"
             name="qtde_sabor_{{i}}" type="radio" kendoRadioButton
             [value]="qtde"     [required]="true"/>

      <span class="nome_opcao" *ngIf="qtde===1"> Inteira 1 Sabor </span>
      <span class="nome_opcao"  *ngIf="qtde > 1">  {{qtde}} Sabores </span>

    </label>
  </div>

</div>



<ng-container *ngIf="qtdeSabores > 1">
  <div class="bg-light p-1 pl-2 headerCampo mt-2"   >
    <h4>Sabores
      <i class="fas fa-search" (click)="habilitarBusca()"></i>
      <input *ngIf="clicouBusca" style="margin-bottom: 5px" kendoTextBox placeholder="Busque pelo nome" class="form-control busca"
                                                                              name="txtFiltro" [(ngModel)]="filtroSabor"
                                                                              (input)="onFilter()"/>
      <div class="float-right">
              <span class="badge" [class.badge-danger]="totalSaboresSelecionados() !== qtdeSabores"
                    [class.badge-success]="itemPedido.produtoTamanho">OBRIGATÓRIO</span>
      </div>

    </h4>


    <span class="text-muted" >Escolha  {{qtdeSabores}} sabores </span>
  </div>

  <p class="alert alert-danger mt-1" role="alert" *ngIf="exibirErros && totalSaboresSelecionados() !== qtdeSabores ">
    <i class="fa fa-times mr-2"></i> Escolha {{qtdeSabores}} sabores
  </p>

  <h5 *ngIf="cobrarMaiorPedacao">O valor total da pizza será calculado pelo sabor de maior preço</h5>
  <h5 *ngIf="!cobrarMaiorPedacao">O valor total da pizza será calculado média dos preços</h5>

  <div class="mt-2 ml-0"   >
    <div *ngFor="let saborPizza of saboresPizzaFiltrados; let i = index; let last = last;"  >

      <div [class.mb-2]="!last" [class.mt-2]="!last" style=""  >

        <div class="col"  [class.pl-0]="saborPizza.linkImagem">
          <div [class.mb-1]="!saborPizza.descricao"   style="display: flex;align-items: center;justify-content: space-between;">
            <label class="k-checkbox-label mb-1">
              <div>
                <span class="nome_opcao">{{saborPizza.nome}}   </span>
              </div>
              <span class="badge badge-primary ml-1 preco-extra" *ngIf="saborPizza.preco"> {{saborPizza.preco | currency:'BRL'}}</span>
            </label>

            <div>
              <div class="col flex-fixed-width-item pl-0 pr-0" style="background: #fff; min-width: 115px;   margin-left: 10px;   "  >
                <button type="button" class="btn btn-outline-light" style="color: #787878; padding-top: 2px; padding-bottom: 2px" (click)="diminuirQtdeSabor(saborPizza)"
                        [disabled]="saborPizza.qtde == 0" ><i class="fas fa-minus"></i></button>

                <input name="sabor_{{i}}_qtd"
                       [(ngModel)]="saborPizza.qtde" [value]="saborPizza.qtde" style="width: 30px;border: none;text-align: center;font-size: 16px;background: #fff;" disabled/>
                <button type="button" class="btn btn-outline-light" style="color: #787878; padding-top: 2px; padding-bottom: 2px" (click)="aumentarQtdeSabor(saborPizza)"
                        [disabled]="totalSaboresSelecionados() == qtdeSabores"><i class="fas fa-plus"></i></button>
              </div>
            </div>

          </div>

          <div class="mb-2" *ngIf="saborPizza.descricao">
            <span  class="text-muted descricao" style="margin-left: 11px;display:block" >{{saborPizza.descricao}}</span>
          </div>

        </div>
      </div>

    </div>
  </div>
</ng-container>
