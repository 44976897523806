<div class="modal-content">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel">
       <i class='fa fa-image fa-2x' ></i> Cadastro de Fotos
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div #pnl class="modal-body">
      <div class="alert alert-success alert-dismissible fade show mt-2" *ngIf="mensagemSucesso" role="alert">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="alert alert-danger alert-dismissible fade show mt-2" *ngIf="mensagemErro" role="alert">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="form-group mb-3">
        <label for="nome">Título da Foto</label>
        <input type="text" class="form-control" autocomplete="off"
               id="nome" name="titulo" [(ngModel)]="foto.titulo" #titulo="ngModel"
               placeholder="Titulo da Foto" value="" required appAutoFocus [autoFocus]="true">
        <div class="invalid-feedback">
          <p *ngIf="titulo.errors?.required">Titulo é obrigatório</p>
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="nome">Descrição da Foto</label>
        <input type="text" class="form-control" autocomplete="off"
               id="descricao" name="descricao" [(ngModel)]="foto.descricao" #descricao="ngModel"
               placeholder="Descrição da Foto" value="" required    >
        <div class="invalid-feedback">
          <p *ngIf="titulo.errors?.required">Descrição é obrigatório</p>
        </div>
      </div>
      <app-upload-imagem [objeto]="foto" [maxsize]="1200"  [prop]="'link'"> </app-upload-imagem>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light"  >Salvar</button>
        <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="fecheModal()">Fechar</button>
      </div>
    </div>

  </form>
</div>
