import { Component, OnInit } from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {PedidosService} from "../../services/pedidos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-opendelivery-events',
  templateUrl: './modal-opendelivery-events.component.html',
  styleUrls: ['./modal-opendelivery-events.component.scss']
})
export class ModalOpendeliveryEventsComponent extends ModalKendo implements OnInit {
  pedido: any = {};
  eventos = [];
  carregando = true;
  constructor( private  grupolojasService: GrupolojasService,
              private  pedidosService: PedidosService) {
    super()
  }

  ngOnInit(): void {
    this.pedidosService.listeEventosPedido(this.pedido.guid).then((eventos) => {
      this.carregando = false;
      this.eventos = eventos;
    }).catch((erro) => {
      this.carregando = false;
      alert(erro);
    })
  }


}
