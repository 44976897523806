import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {NgForm} from "@angular/forms";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-cad-templatepizza',
  templateUrl: './cad-templatepizza.component.html',
  styleUrls: ['./cad-templatepizza.component.scss']
})
export class CadTemplatepizzaComponent extends ModalKendo implements OnInit {
  templates: any =  [];
  templateSelecionado: any;
  novoTemplate: any;
  catalogo: any;
  empresa: any;
  templateMassa: any;
  templateBorda: any;
  mensagemSucesso: any;
  campoOrdenar: any;
  erroSalvar: string
  erroExcluir: string
  salvando: boolean
  excluindo: boolean
  camposOrdenar = [{campo: 'nome', descricao: 'Nome' },
                   {campo: 'valor', descricao: 'Valor'} ]
  deParaEmEdicao: any;
  salvandoPontos: boolean;
  constructor(  private route: ActivatedRoute,
               private empresasService: EmpresasService) {
    super()
  }

  ngOnInit(): void {
    this.templates.forEach((item: any) => item.descricao = String(`${item.id} - ${item.nome}`))
    this.selecioneTemplate(this.templates[0]);
  }

  selecioneTemplate(template: any){
    this.templateSelecionado = template;
    this.templateMassa = this.obtenhaAdicional('Massa');
    this.templateBorda = this.obtenhaAdicional('Borda');

    if(this.templateBorda && this.templateBorda.opcoes){
      this.templateBorda.opcoes.forEach( (opcao) => {
        if(opcao.tamanho)
          opcao.tamanho = this.templateSelecionado.tamanhos.find( tamanho => tamanho.id === opcao.tamanho.id);

      })
    }

    if(this.templateMassa && this.templateMassa.opcoes){
      this.templateMassa.opcoes.forEach( (opcao) => {
        if(opcao.tamanho)
          opcao.tamanho = this.templateSelecionado.tamanhos.find( tamanho => tamanho.id === opcao.tamanho.id);
      })
    }

    if(!this.templateSelecionado) return;

    this.templateSelecionado.cobrarTaxaExtra = this.templateSelecionado.taxaExtra > 0;

    if(this.templateSelecionado.campoOrdenar)
      this.campoOrdenar = this.camposOrdenar.find( campoOrdenar => campoOrdenar.campo === this.templateSelecionado.campoOrdenar)

    this.templateSelecionado.tamanhos.forEach(( tamanho: any) => {
      if(! tamanho.deParaTamanhoSabores) return;

      tamanho.deParaTamanhoSabores.forEach((dePara: any) => {
        dePara.listaQtdesSabores  = this.obtenhaQtdeSabores(tamanho);
        dePara.qtdeSabores =   dePara.listaQtdesSabores.find((item: any) => item.id ===  dePara.qtdeSabores);

        if(!dePara.qtdeSabores &&    dePara.listaQtdesSabores.length === 1 )
          dePara.qtdeSabores =  dePara.listaQtdesSabores[0]
      })

       if( !tamanho.deParaTamanhoSabores.length)
         this.adicionarDePara(tamanho)
    })

  }

  onSubmitNovoTemplate(frm){
    if(!frm.valid) return
    this.novoTemplate.salvando = true;
    this.empresasService.salveTemplate(this.empresa, this.novoTemplate).then( template => {
       this.templates.push(template)
       this.selecioneTemplate(template)
       delete this.novoTemplate;
    }).catch((erro) => {
      this.novoTemplate.salvando = false;
      this.erroSalvar = erro;
    })
  }

  onSubmit() { }

  limpeMensagens() {  }

  novoTamanho() {
    this.templateSelecionado.tamanhos.push({});
  }

  novaOpcaoAdicional(adicional: any) {
    if(!adicional.opcoes) adicional.opcoes = [];

    adicional.opcoes.push({})
  }

  adicionarDePara(tamanho: any){
    delete tamanho.erro;

    if(tamanho.deParaTamanhoSabores.length + 1 <= tamanho.qtdeSabores){
      tamanho.deParaTamanhoSabores.push({  listaQtdesSabores : this.obtenhaQtdeSabores(tamanho)})
    } else {
      tamanho.erro = String(`Quantidade máxima de sabores é: ${tamanho.qtdeSabores}`)
    }

  }


  removaTamanho(tamannho: any) {
    if(tamannho.id){
      tamannho.removido = true;
    } else {
      let i = this.templateSelecionado.tamanhos.indexOf(tamannho);

      this.templateSelecionado.tamanhos.splice(i, 1 )
    }

  }

  obtenhaAdicional(descricao: string) {
    if(this.templateSelecionado){
      let _adicional =
        this.templateSelecionado.adicionais.find( adicional => adicional.descricao.toLowerCase() === descricao.toLowerCase());

      return _adicional  ? _adicional : {}
    }

    return {};
  }

  salvarTemplateTamanho(tamanho: any, frm: NgForm) {
    if(!frm.valid ||  tamanho.salvando)
      return;

    tamanho.salvando = true;

    this.empresasService.salveTamanhoTemplate(this.templateSelecionado, tamanho).then( (resp) => {
        tamanho.salvando = false;
        if(!tamanho.id)  tamanho.id = resp.id;
        frm.form.markAsPristine();
        frm.form.markAsUntouched()

        tamanho.salvando = false;
    }).catch((erro) => {
        tamanho.salvando = false;
        alert(erro)
    })
  }

  excluaTemplate(){
    this.excluindo = true;
    delete this.erroExcluir;

    this.empresasService.removaTemplate(this.templateSelecionado).then( (resp) => {
      this.excluindo = false;

      let i = this.templates.indexOf(this.templateSelecionado);

      this.templates.splice(i, 1);

      this.selecioneTemplate(this.templates[0]);
    }).catch((erro) => {
      this.excluindo = false;
      this.erroExcluir = erro;
    })

  }


  salveIdentificador() {
    this.templateSelecionado.salvando = true;

    this.empresasService.atualizeIdentificadorTemplate(    this.templateSelecionado).then( (resp) => {
      this.templateSelecionado.salvando = false;
    }).catch((erro) => {
      this.templateSelecionado.salvando = false;
      alert(erro)
    })
  }

  salvarTemplateAdicional(templateAdicional: any, opcao: any, frm: NgForm){
    if(!frm.valid ||  opcao.salvando)
      return;

    opcao.salvando = true;

    this.empresasService.salveAdicionalTemplate(this.templateSelecionado, templateAdicional, opcao).then( (resp) => {
      opcao.salvando = false;
      if(!opcao.id)  opcao.id = resp.id;
      frm.form.markAsPristine();
      frm.form.markAsUntouched()

      opcao.salvando = false;
    }).catch((erro) => {
      opcao.salvando = false;
      alert(erro)
    })


  }

  onSubmitTipoDeCobranca(frm: NgForm) {
    if(!frm.valid) return;

    this.fecheMensagemSucesso();

    this.empresasService.salveTipoDeCobranca(this.templateSelecionado).then ( resp => {
      frm.form.markAsPristine();
      frm.form.markAsUntouched();
      this.mensagemSucesso = 'Tipo de cobrança salvo com sucesso!'
    }).catch((erro) => {
      alert(erro)
    })

  }

  onSubmitVendas(frm: NgForm) {
    this.fecheMensagemSucesso();
    this.salvando = true;
    this.empresasService.atualizeTemplate(this.templateSelecionado).then ( resp => {
      this.salvando = false;
      frm.form.markAsPristine();
      frm.form.markAsUntouched();
      this.mensagemSucesso = 'Template atualizado com sucesso!'
    }).catch((erro) => {
      this.salvando = false;
      alert(erro)
    })
  }



  salvarDePara(tamanho: any, dePara: any, frm: NgForm){
    if(!frm.valid ||  dePara.salvando)
      return;

    dePara.salvando = true;
    delete tamanho.erro;
    delete tamanho.mensagem;

    this.empresasService.salveDeParaTamanho(this.templateSelecionado, tamanho, dePara).then( (resp) => {
      if (!dePara.id) dePara.id = resp.id;
      dePara.salvando = false;

      frm.form.markAsPristine();
      frm.form.markAsUntouched();

      tamanho.mensagem = String(`Código salvo com sucesso`)

      setTimeout( () => { delete tamanho.mensagem; },  5000);
    }).catch((erro) => {
      dePara.salvando = false;
      tamanho.erro = erro;
    })

  }

  removerSalvarDePara(tamanho: any, dePara: any, i: number){
    delete tamanho.erro;
    delete tamanho.mensagem;

    if( dePara.removendo) return;

    if(dePara.id){
      dePara.removendo = true;
      this.empresasService.removaDeParaTamanho(this.templateSelecionado, tamanho, dePara).then(() => {
        dePara.removendo = false;
        tamanho.deParaTamanhoSabores.splice(i, 1);
      }).catch((erro) => {
        dePara.removendo = false;
        tamanho.erro = erro;
      })
    } else {
      tamanho.deParaTamanhoSabores.splice(i, 1);
    }
  }

  onSubmitDePara(frm: NgForm, tamannho: any){
    if(frm.valid){
      tamannho.deParaTamanhoSabores.push(tamannho.deParaEmEdicao)
      delete tamannho.deParaEmEdicao;
    }
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  canceleSalvarTamanho(tamannho: any) {
    this.templateSelecionado.tamanhos.splice( this.templateSelecionado.tamanhos.indexOf(tamannho), 1)
  }

  canceleSalvarOpcao(templateMassa: any, opcao: any) {
    templateMassa.opcoes.splice(  templateMassa.opcoes.indexOf(opcao), 1)
  }

  mudoCampoOrdenar(campoOrdenar: any) {
    this.templateSelecionado.campoOrdenar = campoOrdenar.campo
  }

  adicionarTemplate(){
    this.novoTemplate = { ativo: true , modelo:  null, identificador: 'Pizza'}
  }

  confirmeExcluirTemplate(){
    this.templateSelecionado.excluir = true;
  }



  alterouTemplate(template) {
    this.selecioneTemplate(template)
  }

  obtenhaQtdeSabores(tamanho: any){
    if( tamanho.qtdeSabores === 1) return [{id: 1, valor: "1 sabor"}]

    let qtdes = [{id: 0, valor: 'Todos'}]

    for(let i = 1 ; i <= tamanho.qtdeSabores; i++)
      qtdes.push({id: i, valor: i > 1 ?  String(`${i} sabores`) :  String(`${i} sabor`) });

    return qtdes
  }

  onSubmitSalvarPontos(frm: NgForm){
    if(frm.valid){
      this.salvandoPontos = true;
      this.empresasService.salvePontuacaoTamanhoTemplate(this.templateSelecionado).then( (resp) => {
        this.salvandoPontos = false;

        frm.form.markAsPristine();
        frm.form.markAsUntouched()
      }).catch((erro) => {
        alert(erro)
      })
    } else {
      alert('Pontuação inválida')
    }

  }

  pontuarFidelidade() {
    if(!this.empresa || !this.empresa.integracaoPedidoFidelidade)
      return false;

    return this.empresa.integracaoPedidoFidelidade.plano.tipoDePontuacao.tipo === 'qtde-variavel-por-atividade'

  }


  acumulaReais(){
    if(!this.empresa || !this.empresa.integracaoPedidoFidelidade)
      return false;

    return this.empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo === 'Reais';
  }

  getCashbackExibir(atividade){
    if(atividade.cashback)
      return String(`${(atividade.cashback * 100).toFixed(1)}%`);

  }

  obtenhaFormatoPercentual(){
    return String(`0.0"%"`);
  }


}
