<app-controle-kanbam #ctlKanban [cardTemplate]="cardTemplate" [data]="todo" [coluna]="'etapa'"
                     [largura]="'300px'" [colunas]="colunas"
                     (cardMoved)="atualizeCRM($event)"
></app-controle-kanbam>

<ng-template #cardTemplate let-pedido>
  <mat-card>
    <mat-card-title-group>
      <mat-card-title style="white-space: break-spaces">{{pedido.name}}
      </mat-card-title>
      <mat-card-subtitle>
        <span class="text-muted">
          {{pedido?.organization?.name}}
        </span>
      </mat-card-subtitle>
      <span class="float-right font-weight-bold font-15 preco"></span>
    </mat-card-title-group>
    <mat-card-content>
      <ng-container *ngFor="let contato of pedido.contacts">
            <span class="mb-1 d-block" color="#333">
              <i class="fas fa-address-book"></i> {{contato.name}}
            </span>
        <div *ngFor="let fone of contato.phones">
          <span class="font-12 text-success">
            <i class="fab fa-whatsapp fa-lg mr-1" ></i> {{fone.phone}}
          </span>
        </div>
      </ng-container>
    </mat-card-content>
    <mat-card-footer style="height: 45px;display: none;">
      <div class="card-footer">
        <div class="card-time">
          <!-- icone de relógio -->
          <i class="far fa-clock"></i>

          <span [ngClass]=" { 'negrito': !pedido?.visualizado }">
                00:00 </span>
        </div>
      </div>
    </mat-card-footer>
  </mat-card>
</ng-template>
