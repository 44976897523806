import { Component, OnInit } from '@angular/core';
import {Foto} from "../../../../server/domain/Foto";
import {ActivatedRoute} from "@angular/router";
import {FotoService} from "../../services/foto.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-cad-foto',
  templateUrl: './cad-foto.component.html',
  styleUrls: ['./cad-foto.component.scss']
})
export class CadFotoComponent extends ModalKendo implements OnInit {
  empresa: any;
  mensagemSucesso: any;
  mensagemErro: any;
  foto: Foto = new Foto();
  constructor(  private route: ActivatedRoute,   private fotoService: FotoService) {
    super()
  }

  ngOnInit() {
    if(!this.foto)
      this.setFoto( new Foto())
  }

  setFoto(foto: Foto){
    this.foto = foto;
  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  onSubmit() {
    delete this.mensagemErro;
    this.foto.empresa = this.empresa;
    this.fotoService.salveFoto(this.foto).then( resposta => {
      if(!this.foto.id) this.foto.id = resposta.id;
      this.fecheModal(this.foto)
    }).catch( erro => {
      this.mensagemErro   = erro;
    })
  }
}
