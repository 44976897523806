<div class="body">
  <div class="mt-2">

    <div class="float-left">
      <label class="escuro">Detalhes da fatura</label>
    </div>

    <div class="float-right">
      <label class="escuro">Vencimento</label><br>
      <span>{{fatura.dataVencimento}}</span>
    </div>

  </div>

  <div class="clearfix"></div>

  <div class="mt-2">
    <table class="table  ">
      <thead>
      <tr>
        <th class="escuro">Descrição</th>
        <th class="escuro" style="     width: 90px; ">
          <span class="float-right">Valor</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let lacamento of fatura.lancamentos">
        <td>{{lacamento.descricao}}</td>
        <td >
          <span class="float-right">
              {{lacamento.total | currency: "BRL"}}
          </span>

        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="2">
          <label class="float-right">
            Total:
            <span class="font-16 ml-2 escuro"> <b>{{fatura.total | currency: "BRL"}}</b></span>
          </label>
        </td>
      </tr>
       <tr *ngIf="fatura.paga">
         <td colspan="2">
           <label class="float-right">
              {{fatura.descricaoPagamento}}
                <span *ngIf="fatura.parcelas"> <b>parcelado em {{fatura.parcelas}}x</b></span>

           </label>
         </td>
       </tr>
      </tfoot>
    </table>
  </div>

</div>

<div class="linha-fundo"></div>
