<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    <i class="fa fa-file-import fa-lg mr-1"></i>Importar Produtos
  </h4>

</kendo-dialog-titlebar>
<div   class="modal-body">
  <h4 *ngIf="carregandoProdutos" >
    Buscando o catálogo de produtos, isso pode demorar alguns minutos...
    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>

  </h4>


  <div class="row mt-4 mb-2" *ngIf="informarCodigo">
    <div class="col col-sm-4 centralizado d-block" style="margin: 0 auto;">
      <label> Informe o código do produto</label>
      <input type="text" class="form-control" autocomplete="off"
             name="codigoPdv" [(ngModel)]="codigoPdv"  placeholder="Código do produto"   required   >


      <button class="btn btn-blue btn-sm btn-block mt-1" (click)="busqueProdutoPorCodigo()" [disabled]="buscando">
        <i class="k-i-loading k-icon " *ngIf="buscando"></i>
        Buscar</button>
    </div>
  </div>


  <div class="alert alert-info mt-2 mb-2" *ngIf="!carregandoProdutos && tudoSincronizado">
    <h6 *ngIf="!codigoPdv">
      Não há nenhum produto novo no catálogo.
    </h6>
    <h6 *ngIf="codigoPdv">
      Produto já está sincronizado no catálogo
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="fecharAlerta()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <div *ngIf="erro" class="alert alert-danger mt-2 mb-2">
    {{erro}}
    <button type="button" class="close" aria-label="Close" (click)="erro = null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-painel-importacao-produtos #painelImportacao  [hidden]="carregandoProdutos || tudoSincronizado || informarCodigo">

  </app-painel-importacao-produtos>
</div>

<div class="modal-footer"    >

  <button class="btn btn-danger mr-2" (click)="painelImportacao.sincronizeTodos()"
          [disabled]="carregandoProdutos || painelImportacao.importacao.sincronizando || tudoSincronizado || informarCodigo"
          [hidden]="painelImportacao.importacao.finalizou"   >

    <i class="k-icon k-i-loading" *ngIf="painelImportacao.importacao.sincronizando"></i>
    Sincronizar todos</button>

  <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
          (click)="feche()">Cancelar</button>
</div>
