import { Component, OnInit } from '@angular/core';
import {FidelidadeService} from "../../services/fidelidade.service";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {State} from "@progress/kendo-data-query";
import {PlanosService} from "../../services/planos.service";
import {Router} from "@angular/router";
import {ContatosService} from "../../services/contatos.service";
declare var moment;
@Component({
  selector: 'app-relatorio-pontuacao',
  templateUrl: './relatorio-pontuacao.component.html',
  styleUrls: ['./relatorio-pontuacao.component.scss']
})
export class RelatorioPontuacaoComponent implements OnInit {
  dataGrid: any = { data: [], total: 0};
  gridState: State = {
    sort: [],
    skip: 0,
    take: 100
  };

  dataGridTrocas: any = { data: [], total: 0};
  gridStateTrocas: State = {
    sort: [],
    skip: 0,
    take: 100
  };
  carregando = true;
  filtro: any = { contato: null};
  planos = [];
  brindes = [];
  totalItens = 0;
  totalItensTrocas = 0;
  contatosBusca = []
  buscandoContatos = false;
  timeoutBusca: any;
  codigoPais = "+55"
  contatoFiltro: any;
  contatoFiltroTroca: any;
  tabNome: string;
  contatoSelecionado: any;
  constructor(private fidelidadeService: FidelidadeService, private planoService: PlanosService,
              public contatosService: ContatosService,
              private router: Router) {
    this.filtro.inicio = moment().add(-30, 'd').toDate();
    this.filtro.fim = new Date();

  }

  async ngOnInit(){
    await this.carreguePontuacoesHistorica();
    this.planos = await this.planoService.listePlanos();

    if(this.planos.length){
      this.filtro.plano = this.planos[0];
      await this.carregueBrindes();
    }


    setTimeout( () => {
        this.busqueContatos(this.codigoPais, 'a');
    }, 500);
  }

  async alterouPlano(){
     if(this.tabResgateSelecionada()){
     await this.carregueTrocasHistorico()
    } else {
      await this.carreguePontuacoesHistorica();
    }
    await   this.carregueBrindes();
  }

  async carregueBrindes(){
    if(this.filtro.plano)
      this.brindes =  await this.planoService.listeBrindes(this.filtro.plano);
  }

  obtenhaIdContatoFiltro(descricao: string){

    let contato = this.contatosBusca.find((item) => item.descricao === descricao)

    return contato ? contato.id : null
  }

   async carreguePontuacoesHistorica(){
     this.carregando = true;
     let dtInicio = moment(this.filtro.inicio).format('YYYYMMDD');
     let dtFim = moment(this.filtro.fim).format('YYYYMMDD');

     let idContato = this.obtenhaIdContatoFiltro(this.contatoFiltro)  ;

     let pontuacoes =
        await this.fidelidadeService.listePontuacoesRegistradas(this.gridState.skip, this.gridState.take,
          dtInicio, dtFim, this.filtro.plano, idContato);
      this.carregando = false;

      if(pontuacoes){

        this.totalItens =  this.gridState.skip   +   pontuacoes.length;

        if(pontuacoes.length ===   this.gridState.take)
          this.totalItens += 1; // proxima pagina

        this.dataGrid = {
           data: pontuacoes,
           total: this.totalItens
        }

      }
  }

  async carregueTrocasHistorico(){
    let dtInicio = moment(this.filtro.inicio).format('YYYYMMDD');
    let dtFim = moment(this.filtro.fim).format('YYYYMMDD');

    let idContato = this.obtenhaIdContatoFiltro(this.contatoFiltroTroca)  ;

    let idBrinde =  this.filtro.brinde ?  this.filtro.brinde.id : null;

    let trocas =
      await this.fidelidadeService.listeRegastesFidelidade(this.gridState.skip, this.gridState.take,
        dtInicio, dtFim, this.filtro.plano, idContato, idBrinde);


    this.carregando = false;

    if(trocas){

      this.totalItensTrocas =  this.gridStateTrocas.skip   +   trocas.length;

      if(trocas.length ===   this.gridStateTrocas.take)
        this.totalItensTrocas += 1; // proxima pagina

      this.dataGridTrocas = {
        data: trocas,
        total: this.totalItensTrocas
      }

    }
  }

  async selecionouTab($event: any) {
    this.tabNome = $event.title;
    if (this.tabResgateSelecionada())
        await this.carregueTrocasHistorico();

  }

  tabResgateSelecionada(){
    return this.tabNome === 'Resgates'
  }


  async onPageChange(e: PageChangeEvent) {
    this.gridState.skip = e.skip;
    await this.carreguePontuacoesHistorica();
  }

  public onStateChange(state: State) {
    this.gridState = state;
  }

  alterouValor(item){
    debugger
    console.log(item)
  }
  alterouCampoBusca(valor: string) {
    if(valor && valor.length  > 2){
      if(this.timeoutBusca) clearTimeout(this.timeoutBusca)

      this.timeoutBusca =  setTimeout(() => {
        this.busqueContatos(this.codigoPais, valor)
      }, 1500)
    }

  }

  busqueContatos(codigoPais, termo) {
    this.buscandoContatos = true;
    this.contatosService.obtenhaContatosAC(termo ).then( (contatos: any) => {
      this.buscandoContatos = false;
      this.contatosBusca = contatos;
      this.contatosBusca.forEach((contato) => {
         contato.descricao = `${contato.nome} ${contato.telefone}`
      })
    }).catch( (erro) => {
      this.buscandoContatos = false;
    });
  }

  verContato(contato: any){
    let link = '/admin/contatos/'

    if(contato.telefone)
      link = String(`${link}/${contato.codigoPais}${contato.telefone}`)
    else
      link = String(`${link}/cpf/${contato.cpf}`)

    this.router.navigate([link], { state: { contato: contato }});

    return false;
  }

  obtenhaNomeArquivoDownload(nome: string) {
    let sufixo = '', horario = new Date().getTime();

    let inicio =  moment(this.filtro.inicio).format('DD-MM-YYYY');
    let fim =  moment(this.filtro.fim).format('DD-MM-YYYY');

    if(this.filtro.plano)
      nome = String(`${nome}-${this.filtro.plano.nome.toLowerCase()}`)

    if(this.filtro.brinde)
      nome = String(`${nome}-${this.filtro.brinde.nome.toLowerCase()}`)

    sufixo = String(`${inicio}_e_${fim}`)

    return String(`${nome}(${sufixo}).xls`)
  }

}
