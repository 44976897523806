<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <span>
          <i class="fas fa-utensils" style="font-size: 22px;"></i> {{tituloPagina}} <i class="fas fa-beer fa-lg"></i>
      </span>
    </h4>
  </div>



  <div class="card-body">
    <app-tela-mesa-escolhida #telaMesaEscolhida></app-tela-mesa-escolhida>

    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmit()" *ngIf="pedido">

      <div class="borda" [hidden]="!pedido?.contato?.telefone">
        <div class="form-group mb-1">
          <label class="text-muted">Nome do Cliente: </label>
          <label id="nomeCliente" style="color: #000;" class="ml-1">
            <strong>{{this.pedido?.contato?.nome}}</strong>
          </label>
        </div>

        <div class="form-group mb-1" *ngIf="!this.pedido?.contato?.naoIdentificado">
          <label for="telefone" class="text-muted">Telefone</label>
          <label id="telefone" style="color: #000;" class="ml-1">
            <strong>{{this.pedido?.contato?.telefone | tel:  this.pedido?.contato?.codigoPais }}</strong>
          </label>
        </div>

        <div class="form-group mb-1" *ngIf="pedido?.endereco">
          <label for="endereco" class="text-muted">Endereço de Entrega:</label>
          <div id="endereco" style="color: #000;">
            <span>{{pedido?.entrega?.endereco?.logradouro | json}}, {{pedido?.entrega?.endereco?.numero}}{{pedido?.entrega?.endereco?.complemento != null ? ", " + pedido.entrega?.endereco.complemento : ""}} -
              {{pedido?.entrega?.endereco?.bairro}} {{pedido?.entrega?.endereco?.cidade?.nome}}/{{pedido?.entrega?.endereco?.estado?.sigla}}
            </span>
          </div>
        </div>
      </div>
      <div>
        <hr style="margin-top: 0.5rem;margin-bottom: 0.5rem">
        <h4 class="d-inline" style="    top: 5px;position: relative;">Produtos</h4>

        <a href="" class="btn-secondary btn d-inline float-right mb-1 btn-sm" (click)="abrirItensPedido();">Alterar produtos</a>

        <div class="clearfix"></div>

        <app-grid-produtos #listaDePedidos [tela]="this" [soExibir]="true"></app-grid-produtos>


      </div>

      <div class="mt-2"></div>

      <div style="padding-right: 15px;">
        <div class="form-group mb-0">
          <label>Subtotal</label>
          <strong style="float: right">{{pedido.subTotal | currency: 'BRL'}}</strong>
        </div>


        <div class="form-group mb-0" *ngIf="pedido.entrega.taxaDeEntrega ">
          <label>Taxa de entrega</label>
          <strong style="float: right;">{{pedido.obtenhaValorTaxaEntrega() | currency: 'BRL' }}</strong>
        </div>


        <div class="form-group mb-0" *ngIf="pedido.taxaFormaDePagamento ">
          <label>Taxa forma de pagamento </label>
          <strong style="float: right;">{{pedido.taxaFormaDePagamento | currency: 'BRL' }}</strong>
        </div>


        <div class="form-group mb-0" *ngIf="pedido.desconto">
          <label>Desconto</label>
          <strong style="float: right;">{{pedido.desconto | currency: 'BRL' }}</strong>
        </div>

        <div class="form-group mb-0" *ngIf="pedido.descontoFormaDePagamento">
          <label>Desconto forma de pagamento</label>
          <strong style="float: right;">{{pedido.descontoFormaDePagamento | currency: 'BRL' }}</strong>
        </div>

        <div class="form-group mb-0">
          <label>Total do pedido</label>
          <strong style="float: right;font-size: 16px;color: #333;">{{pedido.total | currency: 'BRL' }}</strong>
        </div>

        <ng-container *ngIf="pedido.dataEntrega">
          <hr class="hr">

          <div class="form-group mb-0">
            <label class="text-muted">Agendado para</label>
            <strong class="float-right strong">{{pedido.dataEntrega | date: 'dd/MM/yyyy'}} às {{pedido.horarioEntrega  | date: 'HH:mm'}}</strong>
          </div>

        </ng-container>

        <hr class="hr">

        <div *ngIf="pedido.pagamentos.length">
          <div class="form-group mb-0"  >
            <label class="text-muted">Pagamento em </label>

            <ng-container  *ngIf="pedido.cashback && pedido.cashback.valor">
                <span class="float-right strong">
              <strong > Cashback   {{pedido.cashback.valor | currency:"BRL"}}</strong>
            </span>

              <br>
            </ng-container>

            <ng-container *ngFor="let pagamento of pedido.pagamentos">
              <strong class="float-right strong" *ngIf="!pagamento.removido" >
                {{pagamento.formaDePagamento?.descricao}}  {{pagamento.totalComTaxa || pagamento.valor | currency:"BRL"}}
              </strong>
              <div class="clearfix"></div>

            </ng-container>


          </div>

          <hr class="hr pontilhado">

          <ng-container *ngIf="pedido.temTroco()">
            <div  class="mt-0">
              <label class="text-muted">Atenção para o troco </label>
              <div class="form-group mb-0">
                <label>Dinheiro: </label>
                <strong class="  font-14 float-right">{{pedido.obtenhaTrocoPara() | currency: 'BRL' }}</strong>
              </div>

              <div class="form-group mb-0">
                <label>&nbsp;Troco: </label>
                <strong class="strong font-16 float-right">{{pedido.troco | currency: 'BRL' }}</strong>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="pedido.pontosReceber">
            <hr class="hr pontilhado" *ngIf="pedido.temTroco()">

            <div  class="mt-0">
              <label class="text-muted mt-1">Pontos receber</label>

              <strong  class="float-right strong">
                {{pedido.pontosReceber}}
              </strong>
            </div>
          </ng-container>

        </div>

        <div *ngIf="pedido.observacoes" class="mt-1">
          <label>Observacões: </label>
          <p>{{pedido.observacoes}}</p>
        </div>

      </div>

      <div class="mt-3">
        <div class="alert alert-success mb-2" role="alert" *ngIf="msg">
          <i class="mdi mdi-check-all mr-2"></i> {{msg}}
        </div>

        <div class="alert alert-danger mt-2" role="alert" *ngIf="msgErro">
          <i class="mdi mdi-block-helper mr-2"></i> {{msgErro}}
        </div>

        <button class="btn btn-primary btn-block" type="button" (click)="envieConfirmacaoPedido()"
                *ngIf="!pedido?.contato?.naoIdentificado && pedido?.contato?.telefone" [disabled]="enviandoConfirmacao || !telaMesaEscolhida.carregou">
          <i class="fas fa-paper-plane"></i> Enviar Pedido para o Cliente
        </button>
        <button class="btn btn-blue btn-block" type="submit" [disabled]="criandoPedido || !telaMesaEscolhida.carregou">
          <i class="k-icon k-i-loading " *ngIf="criandoPedido"></i>
          Confirmar Pedido</button>
      </div>
    </form>
  </div>
</div>
