import {Component, Input, OnInit} from '@angular/core';
import {AdicionalPedidoService} from "../services/adicional-pedido.service";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {ProdutoService} from "../services/produto.service";

@Component({
  selector: 'app-cad-adicionais-pedido',
  templateUrl: './cad-adicionais-pedido.component.html',
  styleUrls: ['./cad-adicionais-pedido.component.scss']
})
export class CadAdicionaisPedidoComponent implements OnInit {
  empresaComAdicionais: any = {};
  empresa: any;

  constructor(public adicionalPedidoService: AdicionalPedidoService, private constantsService: ConstantsService,
              private produtoService: ProdutoService) {
  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( data => {

      if( !data ) { return; }

      this.empresa = data;

      this.empresaComAdicionais = {
        id: this.empresa.id,
        catalogo: this.empresa.catalogo,
        camposAdicionais: []
      };

      this.produtoService.obtenhaAdicionaisPedido(this.empresa).then( (adicionais) => {
        this.empresaComAdicionais.camposAdicionais = adicionais;
      });
    });

  }
}
