import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EnderecoService} from "../../../services/endereco.service";
import {NgForm} from "@angular/forms";
import {EmpresasService} from "../../services/empresas.service";

@Component({
  selector: 'app-nova-restricao-cidade',
  templateUrl: './nova-restricao-cidade.component.html',
  styleUrls: ['./nova-restricao-cidade.component.scss']
})
export class NovaRestricaoCidadeComponent implements OnInit {
  estados: any;
  cidades: any;
  estadoEscolhido: any;
  cidadeEscolhida: any;
  windowRef: any;
  enviando = false;
  mensagemErro = '';
  @ViewChild('frm',  {static: false} ) frm: NgForm;
  @Input() empresa;

  constructor(private enderecoService: EnderecoService, private empresasService: EmpresasService) { }

  ngOnInit(): void {
    this.enderecoService.obtenhaEstados().then( (estados) => {
      this.estados = estados;
    });
  }

  mudouEstado($event: any) {
    if( this.estadoEscolhido == null ) {
      return;
    }

    this.enderecoService.obtenhaCidades(this.estadoEscolhido).then( (cidades) => {
      if(cidades && cidades.length)
        this.cidades = cidades;
    });
  }

  fechar() {
    this.windowRef.close();
  }

  onSalvar() {
    delete this.mensagemErro;
    if(!this.frm.valid) return;

    this.enviando = true;
    this.empresasService.salveCidadeEntrega(this.empresa, this.cidadeEscolhida).then( () => {
      this.enviando = false;
      this.windowRef.close({atualize: true});
    }).catch( (erro) => {
      this.mensagemErro = erro;
      this.enviando = false;
    });
  }
}
