// json-display.component.ts
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-json-display',
  templateUrl: './json-display.html', // Aponte para o arquivo HTML
  styles: [`
    p{
      margin-bottom: 0;
      font-family: monospace;
    }
    .key{
      color: black; margin-right: 1em;
    }
    ul{
      list-style-type: none;
      padding: 0;
      margin: 0 0 0 2em;
    }
    .nested-object {

    }
    .json-property {
      margin-left: 1em;
    }
    .brace {
      color: black;
    }
    .collapse-button {
      margin-right: 0.5em;
      border: none;
      margin-left: -1.5em;
    }

    .recuo {
        margin-left: -15px;
    }
  `]
})
export class JsonDisplayComponent {
  @Input() data: any;
  @Input() level: any = 0;
  @Input() collapsedKeys: Set<string> = new Set<string>();

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  isNull(value: any){
    return value == null;
  }

  toggleCollapse(key: string): void {
    if (this.isCollapsed(key)) {
      this.collapsedKeys.delete(key);
    } else {
      this.collapsedKeys.add(key);
    }
  }

  isCollapsed(key: string): boolean {
    return this.collapsedKeys.has(key);
  }


}
