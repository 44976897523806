<div class="card">
  <div class="card-body">
    <app-tela-upload-favicon [empresa]="empresa">
    </app-tela-upload-favicon>
  </div>
</div>

<app-upload-crop-imagem #capaUpload ratio="4" resizeWidth="1240" minWidth="992" minHeight="240"
                        [objeto]="empresa"  [prop]="'capa'" [label]="'Capa'" (onEnviou)="salveCapaEmpresa($event)"> </app-upload-crop-imagem>




<div class="clearfix mt-2">&nbsp;</div>

<app-upload-crop-imagem #logoUpload [objeto]="empresa" ratio="1" resizeWidth="200" minWidth="200" minHeight="200"
                          [prop]="'logo'" [label]="'Logo'" (onEnviou)="salveLogoEmpresa($event)"> </app-upload-crop-imagem>


<h2>Banners</h2>

<button class="btn btn-primary" (click)="gerenciarBanners()">Gerenciar Banners</button>

<span *ngIf="fotosDoAmbiente">
  <h4>Imagens do Ambiente da empresa</h4>
  <kendo-grid [pageSize]="paginacao.size"
              [skip]="paginacao.skip"
              [style]=""
              [kendoGridBinding]="fotos"
              [loading]="loading" style="min-height: 300px;"
              [scrollable]="false"
              footerStyle="font-size: 11px;"
              [pageable]="{
                buttonCount: paginacao.buttonCount,
                info: paginacao.info,
                type: paginacao.type,
                pageSizes: paginacao.pageSizes,
                previousNext: paginacao.previousNext
              }"
              (edit)="editarFoto($event)"
              (remove)="abraDialogRemoverfoto($event)"
              (pageChange)="mudouPaginacao({$event : $event})">
    <ng-template kendoGridToolbarTemplate [position]="'top'">
      <button class="k-button btn-primary" (click)="novaFoto()">
        Novo
      </button>
    </ng-template>
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem fotos"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>
    <kendo-grid-column title="ID" class="coluna_menor"   width="70">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>{{dataItem.id}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Imagem" class="coluna_menor" width="120">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <img [src]="'/images/empresa/'+dataItem.link" style="width: 80px;">
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Descricao" class="coluna_menor">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span><b>{{dataItem.titulo}}</b></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Nome" class="coluna_menor">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>{{dataItem.descricao}}</span>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="Foto" class="coluna_menor">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>{{dataItem.link}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" width="150">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand [primary]="true" (selectedChange)="editarFoto(dataItem)">Editar</button>
        <button kendoGridRemoveCommand><i class="fa fa-trash"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
  <kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="fechar()" [minWidth]="250" [width]="450">
    <div *ngIf="fotoSelecionada && !excluindoFoto">
      <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir a foto "<strong>{{fotoSelecionada.titulo}}?</strong>"</p>
    </div>
    <div *ngIf="excluindoFoto">
      <p style="margin: 30px; text-align: center;">Excluindo a foto "<strong>{{fotoSelecionada.titulo}}"...</strong></p>
      <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
    </div>
    <div *ngIf="mensagemRemocao">
      <p style="margin: 30px; text-align: center;">{{mensagemRemocao}}</p>
    </div>

    <div class="modal-footer" *ngIf="!excluindoFoto">
      <button type="button" *ngIf="fotoSelecionada" (click)="removaFoto()" class="btn btn-primary waves-effect waves-light" >Remover</button>
      <button type="button" *ngIf="fotoSelecionada" (click)="fechar()" primary="true" class="btn btn-light waves-effect">Cancelar</button>
      <button type="button" *ngIf="!fotoSelecionada" (click)="fechar()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
    </div>
  </kendo-dialog>
</span>


