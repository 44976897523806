import { NgModule } from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {PainelRedeComponent} from "./painel-rede/painel-rede.component";
import {LojasRedeComponent} from "../lojas-rede/lojas-rede.component";
import {FinanceiroDeliveryComponent} from "../../fidelidade/financeiro-delivery/financeiro-delivery.component";
import {FinanceiroMesasComponent} from "../../fidelidade/financeiro-mesas/financeiro-mesas.component";
import {SincronizarCatalogoRedeComponent} from "../sincronizar-catalogo-rede/sincronizar-catalogo-rede.component";
import {MarketingRedeComponent} from "../marketing-rede/marketing-rede.component";
import {NovaCampanhaRedeComponent} from "../marketing-rede/nova-campanha-rede/nova-campanha-rede.component";
import {CatalogosComponent} from "../../catalogo/catalogos/catalogos.component";
import {TelaProdutosCatalogoComponent} from "../../superadmin/tela-produtos-catalogo/tela-produtos-catalogo.component";
import {ListaEmpresasCampanhaRedeComponent} from "../marketing-rede/lista-empresas/lista-empresas-campanha-rede.component";
import {PerformanceWhatsappComponent} from "../../fidelidade/performance-whatsapp/performance-whatsapp.component";
import {RelatoriosComponent} from "../../relatorios/relatorios.component";
import {TemplatesMensagensComponent} from "../templates-mensagens/templates-mensagens.component";
import {
  SincronizarCatalogosModelosComponent
} from "../sincronizar-catalogos-modelos/sincronizar-catalogos-modelos.component";
import {UserResolver} from "../../services/user.resolver";

const routes: Route[] = [
  {
    path: '', component: PainelRedeComponent,
    resolve: { user: UserResolver },
    children: [
      {path: 'index', pathMatch: 'full', component: LojasRedeComponent},
      {path: 'lojas', pathMatch: 'full', component: LojasRedeComponent},
      {path: 'marketing', pathMatch: 'full', component: MarketingRedeComponent},
      {path: 'marketing/nova-campanha', pathMatch: 'full', component: NovaCampanhaRedeComponent},
      {path: 'marketing/lista-empresas/:idc', pathMatch: 'full', component: ListaEmpresasCampanhaRedeComponent},
      {path: 'marketing/nova-campanha/:idc', pathMatch: 'full', component: NovaCampanhaRedeComponent},
      {path: 'financeiro-delivery', pathMatch: 'full', component: FinanceiroDeliveryComponent},
      {path: 'financeiro-mesas', pathMatch: 'full', component: FinanceiroMesasComponent},
      {path: 'performance-whatsapp', pathMatch: 'full', component: PerformanceWhatsappComponent},
      {path: 'relatorios', pathMatch: 'full', component: RelatoriosComponent},
      {path: 'catalogo-rede-china', pathMatch: 'full', component: SincronizarCatalogoRedeComponent},
      {path: 'catalogo-rede-chinagcom', pathMatch: 'full', component: SincronizarCatalogoRedeComponent},
      {path: 'catalogo-rede-gendai', pathMatch: 'full', component: SincronizarCatalogoRedeComponent},
      {path: 'catalogo-rede-gendaigcom', pathMatch: 'full', component: SincronizarCatalogoRedeComponent},
      {path: 'catalogo-modelo/:id', pathMatch: 'full', component: SincronizarCatalogosModelosComponent},
      {path: 'catalogo', component: CatalogosComponent},
      {path: 'produtos', component: TelaProdutosCatalogoComponent},

      {path: 'templates-mensagens', pathMatch: 'full', component: TemplatesMensagensComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PainelRedeRoutingModule { }
