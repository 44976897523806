import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {string} from "blockly/core/utils";

@Component({
  selector: 'app-controle-listagem-trecho-prompt',
  templateUrl: './controle-listagem-trecho-prompt.component.html',
  styleUrls: ['./controle-listagem-trecho-prompt.component.scss']
})
export class ControleListagemTrechoPromptComponent implements OnInit {
  @Input() lista = [];
  @Input() permiteEdicao = false;
  @Input() carregando = false;
  @Output() onClicouEditarTrecho = new EventEmitter();
  @Output() onClicouAtivarDesativarTrecho = new EventEmitter();

  showPopover = false;

  constructor() { }

  ngOnInit(): void {
  }

  editarTrechoDePrompt(dataItem: any) {
    this.onClicouEditarTrecho.emit(dataItem);
  }

  ativeDesativeTrechoDePrompt(dataItem: any) {
    this.onClicouAtivarDesativarTrecho.emit(dataItem);
  }

  _processeTextoParaHtml(dataItem: any, novaLinha = '<br>') {
    return ControleListagemTrechoPromptComponent.processeTextoParaHtml(dataItem, novaLinha);
  }

  static processeTextoParaHtml(dataItem: any, novaLinha = '<br>') {
    if(dataItem.textoHtml) {
      return dataItem.textoHtml;
    }

    //gera o texto usando os exemplos pergunta e resposta
    if( dataItem.exemplos.length > 0 ) {
      dataItem.textoHtml = this.geraTexto(dataItem.exemplos);
    } else {
      dataItem.textoHtml = dataItem.texto;
    }

    dataItem.textoHtml = this.formateTemplate(dataItem.textoHtml);
    dataItem.textoHtml = this.boldWords(dataItem.textoHtml, novaLinha);

    return dataItem.textoHtml;
  }

  private static geraTexto(exemplos: Array<any>) {
    let texto = '';

    exemplos.forEach((exemplo, index) => {
      if (index > 0) {
        texto += '\n';
      }

      texto += `<b>Cliente</b>: ${exemplo.pergunta} <br><b>Mia</b>: ${exemplo.resposta}<br>`;
    });

    return texto;
  }

  private static formateTemplate(template: string) {
    const regex = /\${(.*?)}/g;
    let match;

    while ((match = regex.exec(template)) !== null) {
      template = template.replace(match[0], `<b>\$\{${match[1]}\}</b>`);
    }
    //substitui (empresa) por um ícone i fontawesome
    template = template.replace(/\(empresa\)/g, '<i class="fas fa-cog" aria-hidden="true"></i>');
    template = template.replace(/\(global\)/g, '<i class="fas fa-globe" aria-hidden="true"></i>');

    return template;
  }
  static boldWords(text: string, novaLinha = '<br>'): string {
    text = text.replace(/\[(.*?)\]/g, '<strong>[$1]</strong>');
    text = text.replace(/\${(.*?)}/g, '<strong>${$1}</strong>');
    text = text.replace(/CUPOM::(.*?)/g, '<strong>CUPOM::$1</strong>');
    text = text.replace(/\n/g, novaLinha);

    return text;
  }
}
