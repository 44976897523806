
export enum EnumTipoDeAcao {
  NOVO_CONTATO,
  NOVO_CARTAO,
  GANHOU_PONTOS,
  TROCOU_PONTOS,
  TROCOU_TELEFONE,
  IMPORTOU_CONTATO,
  IMPORTOU_PET,
  AJUSTE_SALDO ,
  PONTOS_VENCIDOS ,
  NOVO_PEDIDO,
  PEDIDO_ALTERADO,

}
