<div class="row">
  <div class="  " [ngClass]="{'col-md-4': !modal, 'col-10': modal}">
    <div class="row justify-content-between">
      <div class="col font-15">
        <div class="font-weight-500">
          <a href="" (click)="editar(pedido, $event)">
            <span *ngIf="pedido.mesa">
            {{empresa.identificadorMesa}}  {{pedido.mesa.nome}}
            </span>
          #{{pedido.codigo}}</a>
        </div>


      </div>
      <div class="col text-right font-15"  >
        <span class=" preco font-18">
                 {{pedido.total | currency: 'BRL' }}
        </span>


        <ng-container *ngIf=" (!configImpressao || !configImpressao?.multiplasImpressoras) && !pedido.aguardandoPagamentoOnline">
          <button *ngIf="!configImpressao ||  (configImpressao.layoutPedido !== 'Ambos')" class="btn btn-rounded btn-blue waves-effect waves-light imprimir"
                  (click)="imprimaPedidoDoLayout()"
                  [disabled]="imprimindo"  title="imprimir" kendoTooltip [hidden]="modoGrupoLojas">
            <i class="fa fa-print fa-lg" ></i>
          </button>

          <ng-container *ngIf="configImpressao?.layoutPedido === 'Ambos'">
            <button class="btn btn-rounded btn-blue waves-effect waves-light imprimir"
                    (click)="imprimaPedido()" [disabled]="imprimindo"  title="Completo" kendoTooltip>
              <i class="fa fa-print fa-lg" ></i>
            </button>
            <button class="btn btn-rounded btn-danger waves-effect waves-light imprimir-resumido" [disabled]="imprimindo"
                    (click)="imprimaPedidoResumido()" title="Resumido" kendoTooltip>
              <i class="fa fa-print fa-lg" ></i>
            </button>
          </ng-container>

        </ng-container>

        <kendo-dropdownbutton   [data]="configImpressao.impressoras" [popupSettings]="{animate: true}"
                                (itemClick)="imprimaNativamente($event)" *ngIf="configImpressao?.multiplasImpressoras && !pedido.aguardandoPagamentoOnline"
                                [textField]="'setor'"

                                [icon]="'k-icon k-i-printer'" >

          Imprimir
        </kendo-dropdownbutton >
      </div>


    </div>
    <div class="row mt-1">
      <div class="col font-15 " >
        <app-pedido-status [pedido]="pedido"></app-pedido-status>
        <span *ngIf="pedido.pagamentoOnline" class="badge badge-primary ml-1">Online</span>
      </div>
      <div class="col font-11 text-right" *ngIf="permiteAlterarStatus">
        <button (click)="mudarStatus(pedido)" class="btn btn-primary btn-xs" type="button" *ngIf="pedido.aceito">
          Alterar</button>

        <button (click)="verPedido(pedido)" class="btn btn-blue btn-xs" type="button" *ngIf="!pedido.aceito">
          Aceitar</button>


      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Pago:</label>
        <span class="font-weight-700" [class.text-danger]="!pedido.pago" [class.text-success]="pedido.pago" > {{pedido.pago ?  "Pago" : "Receber na Entrega"}}</span>
      </div>
    </div>
    <div class="row" *ngIf="pedido.pontosGanhos && pedido.pago">
      <div class="col">
        <p  >
          <i class="fe-star-on   text-blue"></i>
          Pontuação creditada: <b>{{pedido.pontosGanhos}}</b></p>
      </div>
    </div>
  </div>
</div>
