import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-intencoes',
  templateUrl: './lista-intencoes.component.html',
  styleUrls: ['./lista-intencoes.component.scss']
})
export class ListaIntencoesComponent implements OnInit {
  carregando = false;
  intencoes = []

  constructor() { }

  ngOnInit(): void {
  }

  editarIntencao(dataItem: any) {

  }
}
