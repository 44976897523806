<div class="row" *ngIf="historicoCaixas.length">
  <div class="col-md-12">
    <kendo-grid [data]="historicoCaixas" [height]="400"  [pageable]="true" [pageSize]="pageSize" (pageChange)="proximaPagina($event)">
      <kendo-grid-column field="operador" title="Usuário" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.online ? 'Online' : dataItem.operador.nome }}
      </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="horaAbertura" title="Aberto às" [width]="75" >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.horaAbertura | date:'dd/MM/yyyy HH:mm' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="horaFechamento" title="Fechado às" [width]="75" >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.horaFechamento; else aberto">{{ dataItem.horaFechamento  | date:'dd/MM/yyyy HH:mm' }}</span>
          <ng-template #aberto>
            <label class="statusAberto">ABERTO</label>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="saldoInicial" title="Saldo inicial" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.saldoInicial | currency:'BRL' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="resumo.saldoDinheiro" title="Saldo final(Dinheiro)" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.saldoDinheiro | currency:'BRL' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="resumo.saldo" title="Saldo final(Total)" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.saldo | currency:'BRL' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Ações" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button  class="btn btn-success
       waves-effect waves-light btn-novo" (click)="verDetalhes(dataItem)" ><i class="fas fa-list"></i></button>
          <button  (click)="imprimir(dataItem)" class="btn btn-dark
       waves-effect waves-light btn-novo"><i class="fas fa-print"></i></button>

        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<div class="container-fechado" *ngIf="!historicoCaixas.length || carregando">
  <div *ngIf="!carregando && !historicoCaixas.length" class="mensagem">
    <p>Nenhum histórico de caixa encontrado.</p>
  </div>


  <div *ngIf="carregando" class="mensagem">
    <i class="k-icon k-i-loading mr-1" style="font-size: xxx-large"></i>
    <p class="mt-2">
      Carregando histórico de caixa...
    </p>
  </div>

</div>

<ng-template #aguardeImpressao>
  <span>
  <i class="k-icon k-i-loading mr-1"></i>
    Aguarde enquanto o relatório é impresso...
  </span>

</ng-template>
<kendo-dialog *ngIf="caixaDetalhado" (close)="fecharTransacoes()" >
  <kendo-dialog-titlebar>
    Detalhes do caixa {{ caixaDetalhado.id }} - {{ caixaDetalhado.horarioAbertura | date:'dd/MM/yyyy HH:mm' }}
  </kendo-dialog-titlebar>
  <div style="width: 60vw">
    <app-grid-transacoes-caixa [caixa]="caixaDetalhado" [resumo]="resumoCaixaDetalhado" [editavel]="false"></app-grid-transacoes-caixa>
  </div>

</kendo-dialog>


