import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {TipoDeNotificacaoEnum} from "../../../../server/domain/TipoDeNotificacaoEnum";
import {NotificacoesService} from "../../services/notificacoes.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ConstantsService} from "../ConstantsService";
import {TelaEscolherCupomCampanhaComponent} from "../../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {TelaEscolherDominioEmpresaComponent} from "../../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component";
import {MensagemTextAreaUtils} from "../../lib/MensagemTextAreaUtils";
import {TemplatesService} from "../../services/templates.service";

@Component({
  selector: 'app-form-notificacao',
  templateUrl: './form-notificacao.component.html',
  styleUrls: ['./form-notificacao.component.scss']
})
export class FormNotificacaoComponent implements OnInit {
  NOTIFICACAO_MENSAGEM_SAUDACAO = 'Mensagem Saudação Whatsapp Pedido';

  @Input() notificacao: any = {
    menu: {
      opcoes: []
    },
    novaOpcao: {}
  };
  mensagem: any = '';
  mensagemSucesso: any = '';
  erro;
  campos = MensagemTextAreaUtils.campos
  templates: any = []
  items: any;

  QTDE_MAXIMA_CARACTERES = 160;
  empresa: any;
  tempoMensagemWhatsapp = 12;

  msgOpcao = '';
  erroOpcao = '';

  constructor(private notificacoesService: NotificacoesService, private constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService, private dialogService: DialogService,
              private templatesService: TemplatesService) {
  }

  ngOnInit() {
    this.items = [
      {label: 'Nome Contato', icon: 'pi pi-fw pi-plus'},
      {label: 'Nome Empresa', icon: 'pi pi-fw pi-download'},
      {label: 'Undo', icon: 'pi pi-fw pi-refresh'}
    ];

    this.empresa = this.autorizacaoService.getUser().empresa;

    if (this.empresa.meioDeEnvio === 'Whatsapp' || this.empresa.meioDeEnvio === 'Mock')
      this.QTDE_MAXIMA_CARACTERES = 1000;


    this.constantsService.empresa$.subscribe((empresa) => {
      if (!empresa)     return;
      this.empresa = empresa;

      if (this.empresa.configWhatsapp)
        this.tempoMensagemWhatsapp = this.empresa.configWhatsapp.tempoMsgSaudacao / (60 * 60 * 1000);


      if (this.empresa.integracaoOpendeliveryLogistica || this.empresa.integracaoUberdirect) {
        if (!this.campos.find((item: any) => item.texto === '[LinkRastreamento]')) {
          this.campos.splice(10, 0, {
                nome: 'Link Rastreamento Entrega',
                texto: '[LinkRastreamento]',
              },
          )
        }
      }
    });

    this.templatesService.daEmpresaOuDaRede().then((templates) => {
      this.templates = templates
    })

    this.alterouMensagem(this.notificacao);
  }


  adicioneCampo(textArea, notificacao: any, campo: any) {
    MensagemTextAreaUtils.adicioneCampo(textArea, notificacao, campo, this.dialogService, this.empresa)
    this.alterouMensagem(this.notificacao)
  }

  adicioneNomeEmpresa(notificacao: any) {
    notificacao.mensagem = '[Empresa]';
  }

  onSubmit(frm: NgForm, notificacao: any) {
    notificacao.mensagemSucesso = '';

    if (!frm.valid) {
      return;
    }

    if( notificacao.novaOpcao ) {
      this.erro = 'Você deve confirmar primeiro a opção de menu acima.';

      return;
    }

    notificacao.tempoMensagemWhatsapp = this.tempoMensagemWhatsapp;

    this.notificacoesService.salveNotificacao(notificacao).then(resp => {
      notificacao.mensagemSucesso = 'Notificação salva com sucesso!';
    }).catch((erro) => {
      this.erro = erro;
    })
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = '';
  }

  informarQtdeDiasAtiva(notificacao: any) {
    return notificacao.tipoDeNotificacao === TipoDeNotificacaoEnum.SentimosSuaFalta ||
        notificacao.tipoDeNotificacao === TipoDeNotificacaoEnum.PontosExpirar ||
        notificacao.tipoDeNotificacao === TipoDeNotificacaoEnum.ClientePerdido
  }

  alterouMensagem(notificacao: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = notificacao.mensagem.match(expressaoRegularURL);

    if (urls) {
      for (let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(notificacao.mensagem)) !== null) {
      const campo: string = result[0];

      if (campo === '[NomeContato]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if (campo === '[LinkCartao]') {
        qtdARemover += campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if (campo === '[LinkPedido]') {
        qtdARemover += campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if (campo === '[Empresa]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if (campo === '[CODIGO]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if (campo === '[SALDO]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 10;
      } else if (campo === '[LinkAtivarCartao]') {
        qtdARemover += campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      }
    }

    notificacao.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - notificacao.mensagem.length + qtdARemover - qtdAAdicionar;
  }


}
