import {Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormasDePagamentoService} from "../../services/formas-de-pagamento.service";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {NovaFormaDePagamentoComponent} from "../nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {ActivatedRoute} from "@angular/router";
import {TabStripComponent} from "@progress/kendo-angular-layout";
import {DOCUMENT} from "@angular/common";

declare  var Hub;

@Component({
  selector: 'app-ativar-formas-de-pagamento',
  templateUrl: './ativar-formas-de-pagamento.component.html',
  styleUrls: ['./ativar-formas-de-pagamento.component.scss']
})
export class AtivarFormasDePagamentoComponent implements OnInit , OnDestroy{
  @ViewChild('tabstrip', { static: true }) private tabstrip: TabStripComponent;
  @ViewChild('pagarmehub',  {static: false} ) pagarmehub: any;
  loading: any;
  empresa: any = {};
  formasDePagamento: Array<FormaDePagamento> = [];
  formasDePagamentoOnline: Array<FormaDePagamento> = [];
  gridDataResult: any = []
  nomeTabOnline = 'Pagamentos online/site';
  timeout: any;
  constructor(private formasDePagamentoService: FormasDePagamentoService,  private dialogService: DialogService,
              private _renderer2: Renderer2,  @Inject(DOCUMENT) private  _document: Document,
              private  activatedRoute: ActivatedRoute,
              private constantsService: ConstantsService) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.formasDePagamentoService.listeFormasPagamentosLoja().then((resp: any) => {
      this.formasDePagamento = resp.offline;
      this.formasDePagamentoOnline = resp.online;
      this.loading = false;
    })

    this.addScriptHugPagarme();

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;

      if(!empresa.integracaoGatewayPagamento || empresa.integracaoGatewayPagamento.gateway === 'pagarme'){
        this.configureHubPargarme(empresa.integracaoGatewayPagamento ? empresa.integracaoGatewayPagamento.instalacaoId : null);

        setTimeout(() => {
          let formapagamento = this.activatedRoute.snapshot.params.formapagamento;

          if(formapagamento && formapagamento !== 'offline' )
            this.exibaTabOnline('pagarme')
        })
      }
    })
  }

  private addScriptHugPagarme() {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src =  '/assets/js/hub.min.js';
    this._renderer2.appendChild(this._document.body, script);
  }

  configureHubPargarme(installId: string = null){
    if(typeof Hub === 'function' && this.pagarmehub){
      let redirectUrl =  String(`${window.location.origin}/admin/loja/retorno/pagarme`)
      let config: any = {
        publicAppKey : "21a6596d-5eeb-4324-9dc2-c5742d5db0d1",
        redirectUrl : redirectUrl , // "https://my-service.com/callback",
        language: "pt-BR",
        environment: "production", // "production", "dev"
      };

      if(installId)
        config.installId = installId;

      Hub(config);

      //button disparando form do angular pq esta sem tipo
      this.marqueTipoBotaoInserido();
    } else {
      if(this.timeout) clearTimeout(this.timeout);

      this.timeout =  setTimeout(() => {
        console.log('aguardando scripts/tela: scripts: ' + (typeof Hub) + '-> tela: ' + (this.pagarmehub != null) )
        this.configureHubPargarme(installId)
      }, 1000);
    }

  }


  ngOnDestroy(): void {
    if(this.timeout) clearTimeout(this.timeout);
  }


  exibaTabOnline(nomeiopagamento: string){
    const tabs = this.tabstrip.tabs.toArray(); // Obtenha todas as abas como um array
    const tabFormasPagamento: any = tabs.find((_tab: any) => _tab.title ===  this.nomeTabOnline);

    if (tabFormasPagamento)
      this.tabstrip.selectTab(tabs.indexOf(tabFormasPagamento))
  }

  marqueTipoBotaoInserido(){
    const buttonInsideSpan = this.pagarmehub.nativeElement.querySelector('button');
    if (buttonInsideSpan) {
      buttonInsideSpan.setAttribute('type', 'button');
    } else {
      setTimeout(() => {
        this.marqueTipoBotaoInserido()
      }, 1000)
    }
  }


  obtenhaFormasPagamentoMetodo(metodo: string, prefixo = null) {
    if (prefixo)
      return this.formasDePagamento.filter((item: any) =>
        item.formaDePagamentoPdv && item.formaDePagamentoPdv.metodo.startsWith(prefixo))


    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.metodo === metodo)
  }

  obtenhaFormasPagamentoTipo(tipo: string ) {

    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.tipo === tipo)
  }

  obtenhaFormasPagamentoIfoodPdv() {
    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.nome === 'Ifood Loja')
  }

  obtenhaFormasPagamentoIfoodOnline() {
    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.nome === 'Ifood Online')
  }

  novoOnline(){
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Pagamento Online',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;
    telaNovaFormaDePagamento.novaForma(true, this.empresa);
    telaNovaFormaDePagamento.windowRef = windowRef;

    windowRef.result.subscribe((result: any) => {
      if(result && result.id)
        this.formasDePagamentoOnline.push(result)

    });
  }

}
