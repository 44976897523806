import {Component, OnInit, ViewChild} from '@angular/core';
import {Mesa} from "../../pedidos/objetos/Mesa";
import {NumeroWhatsapp} from "../../pedidos/objetos/NumeroWhatsapp";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NgForm} from "@angular/forms";
import {NumerosWhatsappService} from "../../services/numeros-whatsapp.service";
import {MesaService} from "../../services/mesa.service";

@Component({
  selector: 'app-nova-mesa',
  templateUrl: './nova-mesa.component.html',
  styleUrls: ['./nova-mesa.component.scss']
})
export class NovaMesaComponent implements OnInit {
  mesa: Mesa;
  windowRef: DialogRef;
  empresa: any;
  mensagemErro = '';
  @ViewChild('frm',  {static: false} ) frm: NgForm;
  enviando = false;
  mesasIntegracao = [];
  carregandoMesas: boolean;
  constructor(private dialogService: DialogService, private mesaService: MesaService) {
    this.mesa = new Mesa();
  }

  async ngOnInit() {
    if(this.escolherMesaIntegrada()){
      if( !this.mesasIntegracao.length){
        this.carregandoMesas = true;
        if(this.mesa.codigoPdv)
          this.mesasIntegracao  = [ { num:  this.mesa.codigoPdv}]

        this.mesasIntegracao = await this.mesaService.obtenhaMesasIntegradas();
      }

      if(this.mesa.codigoPdv && !this.mesa.mesaIntegrada)
        this.mesa.mesaIntegrada = this.mesasIntegracao.find((mesa: any) => mesa.num === Number(this.mesa.codigoPdv) )

      this.carregandoMesas = false;
    }
  }

  selecioneMesaDoNumero(){
    let mesaIntegrada  = this.mesasIntegracao.find((item: any) => item.num === Number(this.mesa.nome))

    if(mesaIntegrada)
      this.mesa.mesaIntegrada = mesaIntegrada;
  }

  escolherMesaIntegrada(){
    if(!this.empresa || !this.empresa.integracaoDelivery) return false;

    let integracao = this.empresa.integracaoDelivery;
    let config = integracao.configuracoesEspecificas;

    return integracao.sistema === 'ecletica' && config && config.integrarComDMWS
  }

  onSalvar() {
    delete this.mensagemErro;
    if(!this.frm.valid) return;

    if(this.mesa.mesaIntegrada)
      this.mesa.codigoPdv  = this.mesa.mesaIntegrada.num;

    this.enviando = true;
    this.mesaService.salveMesa(this.mesa).then( (resposta) => {
      this.windowRef.close();
      this.enviando = false;
    }).catch( erro => {
      this.mensagemErro = erro;
      this.enviando = false;
    });
  }

  remova() {

  }

  fechar() {
    this.windowRef.close();
  }
}
