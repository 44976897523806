import { Component, OnInit } from '@angular/core';
import {AutorizacaoService} from "../../../services/autorizacao.service";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import {Router} from "@angular/router";

@Component({
  selector: 'app-painel-rede',
  templateUrl: './painel-rede.component.html',
  styleUrls: ['./painel-rede.component.scss']
})
export class PainelRedeComponent implements OnInit {
  usuario: any = {};
  empresa: any = {};

  constructor(private router: Router, private autorizacaoService: AutorizacaoService,
              private constantsService: ConstantsService) {
    this.autorizacaoService.usuarioLogado$.subscribe( (usuario) => {
      if( !usuario ) return;

      this.usuario = usuario;
    });

    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if (!empresa) return;
      this.empresa = empresa;
    });
  }

  ngOnInit(): void {
  }

  logout() {
    if(this.autorizacaoService.logout() !== null)  {
      this.router.navigate(['/admin-rede/login'], { }).then( () => {});
    }
  }
}
