import {Component, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {DialogRef} from "@progress/kendo-angular-dialog";
import {NgForm} from "@angular/forms";
import {VitrinesService} from "../../superadmin/services/vitrines.service";

@Component({
  selector: 'app-cadastro-vitrine',
  templateUrl: './cadastro-vitrine.component.html',
  styleUrls: ['./cadastro-vitrine.component.scss']
})
export class CadastroVitrineComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;
  vitrine: any = {};
  windowRef: DialogRef;
  salvando: any;
  mensagemErro: any;
  constructor(private vitrinesService: VitrinesService) { }

  ngOnInit(): void {
  }

  onSalvar() {
    if (this.frm.valid) {
      this.salvando = true;
      this.vitrinesService.salveVitrine(this.vitrine).then( (resp) => {
        this.vitrine.id = resp.id;
        this.salvando = false;
        this.windowRef.close(this.vitrine);
      }).catch((erro: any) => {
        this.salvando = false;
        this.mensagemErro = erro;
      })
    }
  }

  fechar() {
    this.windowRef.close();
  }

}
