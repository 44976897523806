<h4   class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  Editando Template de Prompt</h4>

<form (ngSubmit)="submitForm()">
  <div class="form-group">
    <label for="prompt">Nome:</label>
    <div><h4 class="mt-0">{{templatePrompt.nome}}</h4></div>
  </div>
  <div class="form-group">
    <label for="prompt">Descrição:</label>
    <input type="text" class="form-control" id="prompt" [(ngModel)]="templatePrompt.descricao">
  </div>
  <div class="form-group">
    <label for="txtTemplate">Prompt:</label>
    <textarea class="form-control" id="txtTemplate" rows="12" name="txtTemplate" [(ngModel)]="templatePrompt.template" #txtMensagem="ngModel"
              #textArea required maxlength="12000" (input)="resize(textArea)"></textarea>
  </div>
  <button type="submit" class="btn btn-primary">Salvar</button>
</form>
