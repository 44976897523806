import {Component, OnInit, ViewChild} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {GridPedidosComponent} from "../grid-pedidos/grid-pedidos.component";
import {TipoDePagamentoEnum} from "../objetos/TipoDePagamentoEnum";
import {Pagamento} from "../objetos/Pagamento";
import {NgForm} from "@angular/forms";
import {ComandosImprimirComanda} from "../ComandosImprimirComanda";
import {ImpressaoService} from "../../services/impressao.service";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {PontuacaoRegistrada} from "../../../../server/domain/PontuacaoRegistrada";
import {IUITipoDePontuacaoFactory} from "../../objetos/IUITipoDePontuacaoFactory";
import {FormatadorPontos} from "../objetos/FormatadorPontos";
import {GridPagamentoComponent} from "../grid-pagamento/grid-pagamento.component";
import {MonitoradorPedidos} from "../../fidelidade/MonitoradorPedidos";
import {ComandaService} from "../../services/comanda.service";
import {EmpresaService} from "../../services/empresa.service";

@Component({
  selector: 'app-tela-fechar-comanda',
  templateUrl: './tela-fechar-comanda.component.html',
  styleUrls: ['./tela-fechar-comanda.component.scss']
})
export class TelaFecharComandaComponent extends ComandosImprimirComanda implements OnInit {
  @ViewChild('gridPedidos', { static: false}) gridPedidos: GridPedidosComponent;
  @ViewChild('gridPagamentos') gridPagamentos: GridPagamentoComponent;
  desconto = 0;
  comanda: any;
  carregou: boolean;
  carregando = false;
  tipoDePagamento = TipoDePagamentoEnum;
  formasDePagamento = [];
  @ViewChild('frm', { static: false})  frm: NgForm;
  troco: number;
  fechando: boolean ;
  erro: string;
  fecharNoParceiro = false;
  imprimindo: boolean;
  formasPagamentoMesa: any = []
  carregandoFormasPagamentos = false;
  erroFechaParceiro: boolean;
  fecharSemPrechamento = false;
  constructor(protected constantsService: ConstantsService, protected pedidosService: PedidosService,
              private router: Router, private activatedRoute: ActivatedRoute, protected impressao: ImpressaoService,
              private comandaService: ComandaService, private empresaService: EmpresaService,
              private pedidoLocalService: PedidoLocalService,     public monitoradorPedidos: MonitoradorPedidos) {
    super(pedidosService, constantsService, impressao);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.queryParams.subscribe( (queryParams) => {
      this.formasDePagamento = [];

      const idComanda = this.activatedRoute.snapshot.params.id;

      this.pedidosService.obtenhaComandaComoPedido(idComanda).then( (comanda: any) => {
        this.setComanda(comanda);
      }).catch( (erro) => {
        this.carregou = true;
      });
    });
  }

  private async setComanda(comanda: any) {
    this.comanda = comanda;

    if(comanda.desconto){
      this.desconto = comanda.desconto;
      this.comanda.valor += this.comanda.desconto;
    }

    setTimeout( () => {
      if( !this.gridPedidos ) { return; }

      this.gridPedidos.atualizeGridPedidos(this.comanda.pedidos, 'mesa');
    }, 0);

    this.fecharNoParceiro =   comanda.fecharNoParceiro;
    if(! this.comanda.pagamento)
      this.comanda.pagamento = new Pagamento();

    await this.setFormasPagamentoMesa();

    this.formasDePagamento.forEach(formaDePagamento => {
      let formaDePagamentoComanda = this.comanda.pagamento.formaDePagamento;

      if( this.comanda.cashback && formaDePagamento.nome.toLowerCase() === 'cashback')
        this.comanda.cashback.formaDePagamento = formaDePagamento;

      if( formaDePagamentoComanda && formaDePagamentoComanda.id === formaDePagamento.id )
        this.comanda.pagamento.formaDePagamento = formaDePagamento;

    });

    this.carregou = true;
    this.calculePontosAhReceber();

  }

  voltar() {
    this.router.navigateByUrl('/admin/pedidos-mesas', {}).then( () => {});
  }

  calculeTroco() {
    setTimeout( () => {
      let valor = this.totalDaComanda();

      if(this.comanda.pagamento && this.comanda.pagamento.trocoPara) {
        this.troco = this.comanda.pagamento.trocoPara - valor;

        if(this.troco < 0) this.troco = 0;
      }
      else
        this.troco = 0;
    }, 0);
  }

  enviePreFechamento(){
    const dados: any = {
      id: this.comanda.id,
      desconto: this.desconto
    }

    this.fechando = true;
    this.pedidosService.enviePrefechamentoComanda(dados).then( (resposta) => {
      this.fechando = false;
      this.setComanda( resposta.comanda)
      if(resposta.erroNotificar)
        this.erro = resposta.erroNotificar;

    }).catch((erro) => {
      this.fechando = false;
      this.erro = erro;
    })

    return false;
  }

  cancelarFecharDireto(){
    this.comanda.preFechamentoDisponivel = true;
    this.fecharSemPrechamento = false;
  }

  onSubmit($event: any) {
    if( !this.frm.valid || this.fechando)
      return;

    delete this.erro;

    //pre fe-chamento opcional
    if(this.comanda.preFechamentoDisponivel){
      this.comanda.preFechamentoDisponivel = false;
      this.fecharSemPrechamento = true;
      //retornar para escolher formas de pagamentos
      return;
    }



    const dados: any = {
      id: this.comanda.id,
      fecharNoParceiro: this.fecharNoParceiro,
      desconto: this.desconto,
      cobrarTaxaServico:  this.ehParaCobrarTaxa()
    }

    if(this.totalDaComanda() > 0){
      this.erro = this.gridPagamentos.obtenhaErroPagamento();

      if(this.erro != null) return;

      dados.pagamentos = this.gridPagamentos.pagamentos;
    }

    this.fechando = true;

    delete this.erroFechaParceiro;
    this.pedidosService.fecheComanda(dados).then( (resposta) => {
      this.fechando = false;
      this.setComanda( resposta.comanda)
      if(resposta.erroNotificar)
        this.erro = resposta.erroNotificar;

    }).catch((erro) => {
      this.fechando = false;
      this.erro = erro;
      if(erro.indexOf('fechar') >= 0 || erro.indexOf('foi enviado') >= 0)
        this.erroFechaParceiro = true;
    })

  }

  async setFormasPagamentoMesa(){
    if(this.comanda.preFechamentoDisponivel || this.comanda.emFechamento){
      this.carregandoFormasPagamentos = true;
      this.formasPagamentoMesa = await this.empresaService.listeFormasAtivasMesa();
      this.carregandoFormasPagamentos = false;
    } else
     this.formasPagamentoMesa = this.empresa.formasDePagamento;
  }

  fecharEImprimir() {
    this.onSubmit(true);
  }

  novoPedido() {
    this.pedidoLocalService.remova(this.comanda.cliente.telefone);
    this.router.navigate(['/admin/pedidos/novo'],
      {queryParams: {idm: this.comanda.mesa.id,
          tel: this.comanda.cliente.codigoPais + this.comanda.cliente.telefone,
          nome: this.comanda.cliente.nome,
          modoEdicao: false,
          urlRetorno: '/admin/pedidos-mesas'}}).then( () => {});
  }


  trocaMesa() {
    this.router.navigate(['/admin/pedidos/trocar-mesa'],
      {queryParams: {idc: this.comanda.id, idm: this.comanda.mesa.id}}).then( () => {});
  }

  recarregueComanda(comanda){

    this.pedidosService.obtenhaComandaComoPedido(comanda.id).then( (resposta: any) => {
      this.setComanda(resposta)
    }).catch( (erro) => {

    });

  }

  editarPedido(pedido: any) {
    this.router.navigateByUrl('/admin/pedidos/' + pedido.guid).then( () => {});
  }

  totalDaComanda(){
    if( this.ehParaCobrarTaxa())
        return  this.comanda.totalComTaxa;

    return this.totalSemTaxa();
  }

  ehParaCobrarTaxa(){
    if(this.empresa.cobrarTaxaServico) return this.comanda.cobrarTaxaServico;

    return false;
  }

  totalSemTaxa(){
    return this.comanda.valor - this.desconto;
  }

  alterouCobarTaxa(){
    this.recalculeTotalComanda();

    let dados: any = {id: this.comanda.id, cobrarTaxaServico: this.comanda.cobrarTaxaServico}

    this.pedidosService.atualizeCobrarTaxa(dados).then( (resposta) => {
    }).catch((erro) => {
      this.comanda.cobrarTaxaServico = !this.comanda.cobrarTaxaServico;
      alert(erro)
    })
  }

  recalculeTotalComanda() {
    if (this.ehParaCobrarTaxa()){
      let taxa = this.empresa.valorTaxaServico / 100;
      let totalSemTaxa = this.totalSemTaxa();

      this.comanda.taxaServico = totalSemTaxa * taxa;
      this.comanda.totalComTaxa = totalSemTaxa + this.comanda.taxaServico;

      this.comanda.taxaServico = Number(this.comanda.taxaServico.toFixed(2))
      this.comanda.totalComTaxa = Number(this.comanda.totalComTaxa.toFixed(2))
    } else {
      this.comanda.taxaServico  = 0;
      this.comanda.totalComTaxa  = this.comanda.valor;
    }

    if(this.gridPagamentos)
      this.gridPagamentos.novoValorPagar(this.totalDaComanda())

    this.calculePontosAhReceber();
  }

  calculePontosAhReceber(){
    this.comanda.pontosReceber = null;

    if(this.empresa.integracaoPedidoFidelidade){
      let integracao = this.empresa.integracaoPedidoFidelidade;

      if(!integracao.pontuarMesas) return;

      let pontuacaoRegistrada: PontuacaoRegistrada = new PontuacaoRegistrada();

      pontuacaoRegistrada.valor =  this.totalSemTaxa();
      pontuacaoRegistrada.cartao = integracao.cartao ? integracao.cartao : {};
      pontuacaoRegistrada.cartao.plano = integracao.plano;
      pontuacaoRegistrada.atividades = [integracao.atividade];

      let tipoDePontuacao = integracao.plano.tipoDePontuacao;

      let itens = [];

      this.comanda.pedidos.forEach((pedido: any) => {
        if(!pedido.cancelado){
          itens.push(...pedido.itens)
        }
      })

      let pontos =
        IUITipoDePontuacaoFactory.obtenhaTipodePontuacao(this.empresa, tipoDePontuacao).
        calculePontuacao(pontuacaoRegistrada, itens);

      this.comanda.pontosReceber =
        FormatadorPontos.obtenhaPtsFormatado(integracao.plano.tipoDePontuacao, integracao.plano.tipoDeAcumulo, pontos)
    }

  }

 async imprimirComanda($event: any, nativa = false){
    if(this.imprimindo) return;

    this.imprimindo = true;

    try{
      if(this.desconto !== this.comanda.desconto)
        await this.comandaService.atualizeDesconto(this.comanda, this.desconto);

      if(nativa){
        this.imprimaNativamenteComanda(this.comanda, $event);
      } else {
        if(this.configImpressao && this.configImpressao.layoutPedido  === 'Ambos'){
          this.imprimaUmDosLeiautesComanda(this.comanda, $event)
        } else {
          this.imprimaLayoutUnicoComanda(this.comanda);
        }
      }
    } finally {
      this.imprimindo = false;
    }
  }

}
