import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pedido-ribbon',
  templateUrl: './pedido-ribbon.component.html',
  styleUrls: ['./pedido-ribbon.component.scss']
})
export class PedidoRibbonComponent implements OnInit {
  @Input() pedido: any;
  constructor() { }

  ngOnInit(): void {
  }

}
