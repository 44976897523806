import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AutorizacaoService} from "./autorizacao.service";


@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any | null> {
  constructor(private authService: AutorizacaoService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any | null> {
    return this.authService.carregueUsuario();
  }
}
