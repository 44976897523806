<div class="botoes">
  <div class="row">
    <div class="col-6  col-sm-4 col-lg-3 col-xl-2" *ngIf="temModuloPedidos">
      <div class="card">
          <div class="card-body text-center" style="padding: 0.1rem;padding-bottom: 1rem;">
            <p class="mb-0"><img class="img-fluid" [src]="'/qrcode/qrcodeloja'"/></p>
            <h5 class="card-title mb-1">Link da loja</h5>
            <p class="card-text link_loja d-none d-md-block"><i class="fas fa-external-link-alt" style="color: #6DB31B;"></i> &nbsp;
              <a [href]="empresa.linkLoja" target="_blank">{{empresa.linkLoja}}</a>
            </p>
            <p class="card-text"><a href="/qrcode/qrcodeloja" class="btn btn-primary btn-sm" download>Download</a></p>
          </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temPermisaoAdicionarPontos()">
      <div class="botao waves-effect" (click)="open()" >
        <img src="/assets/fidelidade/Banner_01.png" class="fundo"/>
        <h4 class="branco">Novo Cartão</h4>
        <div>
          <img src="assets/fidelidade/icones/novocartao.svg" style="height: 50px;"/>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temPermisaoAdicionarPontos()">
      <div class="botao waves-effect roxo" (click)="abraAdicionarPontos()" >
        <img src="/assets/fidelidade/Banner_02.png" class="fundo"/>
        <h4 class="branco">Adicionar Pontos</h4>
        <div>
          <img src="assets/fidelidade/icones/novoselo.svg" style="height: 50px;"/>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temPermisaoResgatarBrindes()">
      <div class="botao waves-effect roxo" (click)="abraTrocarPontos()">
        <img src="/assets/fidelidade/Banner_03.png" class="fundo"/>
        <h4 class="branco">Trocar Pontos</h4>
        <div>
          <img src="assets/fidelidade/icones/trocar-selo.svg" style="height: 50px;"/>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temModuloPedidos && !temModuloFidelidade">
      <div class="botao waves-effect roxo" (click)="abraNovoPedido()">

        <img src="/assets/fidelidade/Banner_03.png" class="fundo"/>
        <h4 class="branco">Novo Pedido</h4>
        <div>
          <i class="fe-plus-circle" style="font-size: 45px;color: white;"></i>
          <!--<img src="assets/fidelidade/icones/contatos.svg" style="height: 50px;"/>-->
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temModuloPedidos">
      <div class="botao waves-effect roxo" (click)="abraAcompanharPedidos()">

        <img src="/assets/fidelidade/Banner_04.png" class="fundo"/>
        <h4 class="branco">Acompanhar Pedidos</h4>
        <div>
          <i class="fe-shopping-cart" style="font-size: 45px;color: white;"></i>
          <!--<img src="assets/fidelidade/icones/contatos.svg" style="height: 50px;"/>-->
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="exibindoNaExtensao">
      <div class="botao waves-effect" (click)="adicionarTags()">

        <img src="/assets/fidelidade/Banner_01.png" class="fundo"/>
        <h4 class="branco">Criar Tags Whatsapp</h4>
        <div>
          <i class="fas fa-tags" style="font-size: 45px;color: white;"></i>
          <!--<img src="assets/fidelidade/icones/contatos.svg" style="height: 50px;"/>-->
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temModuloPedidos && exibindoNaExtensao">
      <div class="botao waves-effect" [class.disabled]="enviandoContatos" style="padding: 12px" (click)="salvarContatosPromokit()"
           title="Salva todos seus contatos do Whatsapp no Promokit" kendoTooltip>

        <img src="/assets/fidelidade/Banner_01.png" class="fundo"/>
        <h4 class="branco font-16">Salvar Contatos do Whatsapp</h4>
        <div>
          <i class="fas fa-phone" style="font-size: 38px;color: white;"></i>
          <!--<img src="assets/fidelidade/icones/contatos.svg" style="height: 50px;"/>-->
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="temModuloPedidos && !temModuloFidelidade">
      <div class="botao waves-effect roxo" (click)="abraAcompanharMensagens()" >
        <img src="/assets/fidelidade/Banner_02.png" class="fundo"/>
        <h4 class="branco">Acompanhar Mensagens</h4>
        <div>
          <i class="fas fa-inbox" style="font-size: 45px;color: white;"></i>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="!temModuloPedidos && temModuloFidelidade">
      <div class="botao waves-effect roxo" (click)="abraVerContatos()">
        <img src="/assets/fidelidade/Banner_04.png" class="fundo" *ngIf="!temModuloPedidos"/>
        <img src="/assets/fidelidade/Banner_01.png" class="fundo" *ngIf="temModuloPedidos "/>
        <h4 class="branco">Ver Contatos</h4>
        <div>
          <img src="assets/fidelidade/icones/contatos.svg" style="height: 50px;"/>
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" *ngIf="!temModuloPedidos && !temModuloFidelidade">
      <div class="botao waves-effect roxo" (click)="abraNovoProduto()">

        <img src="/assets/fidelidade/Banner_03.png" class="fundo"/>
        <h4 class="branco">Ver Produtos</h4>
        <div>
          <i class="fe-plus-circle" style="font-size: 45px;color: white;"></i>

        </div>
      </div>
    </div>

  </div>
</div>

