<div class="form-group mb-12" [ngClass]="{'lg': lg, 'sm': sm}" [hidden]="!showUploader">
  <label for="maisFotos"  >{{label}}:</label>
  <div *ngIf="getLinkPrimeiraImagem() && !editarImagem" class="container-imagem">
    <div style="display: flex; align-content: center; justify-content: center">
      <img src="/images/empresa/{{getLinkPrimeiraImagem()}}" alt="foto" >
      <i class="fas fa-times-circle" style="position: absolute; top: 0; right: 0; cursor: pointer; color: #C21807" (click)="removerFoto()"></i>
    </div>

  </div>

  <div *ngIf="possuiMaisDeUmaImagem()" class="container-imagem" style="display: flex; justify-content: center">
    <ng-container *ngFor="let imagem of listaImagens; let i = index;"  >
      <div   *ngIf="i > 0"  style="margin-left: 5px; margin-right: 5px;width: 80px; height: 80px; display: inline-block; position: relative; background: #EFEFEF">
        <img  src="/images/empresa/{{getLinkImagem(i)}}" class="foto-pequena" alt="foto" >
        <i class="fas fa-times-circle" style="position: absolute; top: 0; right: 0; cursor: pointer; color: #C21807" (click)="removerFoto(i)"></i>
      </div>
    </ng-container>

  </div>

  <div *ngIf="!editarImagem && qtdRestantes > 0">
    <kendo-upload id="maisFotos" name="maisFotos"   (select)="selecionouArquivo($event)" class="hide-upload-buttons"
                  (error)="onErroUploadMaisArquivos($event)" [showFileList]="false"
                  [(ngModel)]="files" [maxlength]="10" [batch]="true" (complete)="terminouUpload()"
                  [multiple]="true" [autoUpload]="false"   [saveUrl]="uploadUrl"  [removeUrl]="uploadRemoveUrl"  [restrictions]="restricoes" >
      <kendo-upload-messages
        [select]="'Adicionar até ' + qtdRestantes + ' imagens'"
        uploadSelectedFiles="Enviar imagens"
        clearSelectedFiles="Limpar">
      </kendo-upload-messages>
    </kendo-upload>
  </div>
  <div class="acoes-salvar p-1" *ngIf="exibirSalvar()" >
    <p class="">
      Podemos salvar as alterações efetuadas?
    </p>
    <div>
      <button class="btn btn-success"  (click)="salveImagem()" [disabled]="salvando">
        <i class="fe-save fa-lg"></i>
        Confirmar
      </button>
      <button class="btn btn-light ml-2"  (click)="canceleSalvar()" [disabled]="salvando">
        <i class="fa fa-times fa-la"></i>
        Cancelar
      </button>
    </div>
    <span *ngIf="erroSalvar"  class="text-danger mt-1 d-block"><b>{{erroSalvar}}</b></span>
  </div>

</div>


<span *ngIf="!showUploader">

  <div *ngFor="let image of imagensCortar; let i = index">
     <div    *ngIf="currentImageIndex === i">
            <image-cropper #imageCropper

                           [imageChangedEvent]="{ target: { files: [image.rawFile] } }"
                           [maintainAspectRatio]="true"
                           [aspectRatio]="4/3"
                           [resizeToWidth]="600"
                           [cropperMinWidth]="400"
                           [onlyScaleDown]="true"
                           [roundCropper]="false"
                           [transform]="transform"
                           format="png"
                           outputType="base64"
                           (imageCropped)="imageCropped($event)"
                           (imageLoaded)="imageLoaded()"
                           (cropperReady)="cropperReady()"
                           (loadImageFailed)="loadImageFailed()"
                           [style.display]="showCropper ? null : 'none'"
                           [alignImage]="'left'"
            ></image-cropper>

        <div class="borda-topo">
             <div class="  mt-2 botoes  " style="float: left" >
                  <button class="btn btn-success  mr-1" (click)="confirmeCrop()" [disabled]="enviando" >
                    <i class="k-icon k-i-loading mr-1" *ngIf="enviando"></i>
                    {{imagensCortar.length > currentImageIndex + 1 ? 'Próxima Imagem': 'Cortar'}}</button>

                  <button class="btn btn-outline-secondary" (click)="canceleCortarImagem()" [disabled]="enviando"> Cancelar</button>
            </div>

           <div class="  mt-2 botoes " style="float: right"  >
               <button class="btn  btn-outline-blue mr-1" (click)="zoomIn()">+</button>
               <button class="btn   btn-outline-blue" (click)="zoomOut()">-</button>
            </div>

        </div>

     </div>


    <span *ngIf="erroSalvar"  class="text-danger mt-1 d-block"><b>{{erroSalvar}}</b></span>
  </div>

</span>




