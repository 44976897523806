<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point"></i>
  </button>
  &nbsp; <i class="fa fa-qrcode"></i>&nbsp;
  <span>Lista de QR Codes</span>
  <button (click)="adicionarQrCode()" class="btn btn-primary ml-4">
    Adicionar QrCode
  </button>
</h4>

<!-- Adicione a classe "mensagem-remocao" -->
<div *ngIf="mensagemRemocao" class="alert alert-success mensagem-remocao">{{ mensagemRemocao }}</div>

<div *ngIf="carregando" class="loading-container">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>

<div class="mt-4" *ngIf="!carregando">
  <div class="row">
    <div class="col">
      <kendo-grid [data]="qrcodes">
        <kendo-grid-column field="id" title="ID" [width]="80"></kendo-grid-column>
        <kendo-grid-column field="nome" title="Nome" [width]="200"></kendo-grid-column>
        <kendo-grid-column title="URL" [width]="400">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [href]="dataItem.url" target="_blank">
              <i class="fas fa-link"></i> {{dataItem.url}}
            </a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="horarioCriacao" title="Horário de Criação" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Imagem" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex flex-column align-items-center">
              <img [src]="'/qrcode/' + encodeURIComponent(dataItem.url)" alt="QRCode" width="100" height="100">
              <p class="card-text"><a [href]="'/qrcode/' + encodeURIComponent(dataItem.url)"
                                      class="btn btn-primary btn-sm btn-download" [download]="dataItem.nome + '.png'">Download</a></p>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Ações" [width]="240">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button class="btn btn-warning btn-sm mr-2" routerLink="/admin/qrcodes/edit/{{dataItem.id}}">Editar</button>
            <button class="btn btn-danger btn-sm mr-2" (click)="showConfirmationDialog(dataItem.id)">Remover</button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>

<!-- Add this to the component's template -->
<app-confirm-dialog *ngIf="showConfirmDialog" (onClose)="onClose($event)"></app-confirm-dialog>
