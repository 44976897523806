import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ServerService} from "./ServerService";
import {TipoDeNotificacaoEnum} from "../../../server/domain/TipoDeNotificacaoEnum";

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService  extends ServerService {

  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  todas() {
    return this.httpCliente.get('/notificacoes')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  carregue(tipo: string) {
    return this.httpCliente.get('/notificacoes/' + encodeURIComponent(tipo))
        .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveNotificacao(notificacao: any) {
    return this.salve('/notificacoes', notificacao);
  }

  obtenhaMesagemProcessada(contato: any, tipo: TipoDeNotificacaoEnum) {
    return this.httpCliente.get('/notificacoes/mensagem/processe', { params: {cid: contato.id, tipo: tipo }})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtenhaMensagemCodigoValidacao(contato: any) {
    const dados = {
      id: contato.id,
      msId: contato.codigoValidacaoPendente
    };

    const queryParams = new HttpParams({ fromObject: dados });

    return this.httpCliente.get('/notificacoes/mensagem/notificacaoCodigoValidacao', { params: queryParams})
      .toPromise().then(this.retorno).catch(this.handleError);
  }
  obtenhaMensagemSaudade(contato: any) {
    const dados = {
      nome: contato.nome,
      telefone: encodeURIComponent(contato.codigoPais + contato.telefone)
    };
    const queryParams = new HttpParams({ fromObject: dados });

    console.log( dados);
    return this.httpCliente.get('/notificacoes/mensagem/notificacaoSaudade', { params: queryParams})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  envieNotificacaoLinkExtrato(contato: any) {
    return this.httpCliente.post('/notificacoes/extrato/envie', { id: contato.id })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveMensagem(nome: string, telefone: string, msg: String) {
    return this.facaPost('/whatsapp/historico', {nome: nome, tel: telefone, msg: msg});
  }

  obtenhaRespostaMensagemLista(empresa: any, contato: any, opcaoEscolhida: any) {
    const dados = {
      nome: contato.nome,
      telefone: contato.telefone,
      ido: opcaoEscolhida,
    };

    return this.facaPost('/notificacoes/mensagem/respostaMensagemLista', dados);
  }
}
