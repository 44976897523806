import { Component, OnInit } from '@angular/core';
import {EmpresasService} from "../superadmin/services/empresas.service";
import {Router} from "@angular/router";
import {LabelSettings} from "@progress/kendo-angular-progressbar";

@Component({
  selector: 'app-meuplano',
  templateUrl: './meu-plano.component.html',
  styleUrls: ['./meu-plano.component.scss']
})
export class MeuPlanoComponent implements OnInit {
  contrato: any = {};
  constructor(private empresaService: EmpresasService, private router: Router) { }
  public labelText = '';
  public value = 50;
  public progressStyles: {[key: string]: any} = {
    color: '',
    background: ''
  };

  public emptyStyles: {[key: string]: any} = {
    background: ''
  };

  public labelSettings: LabelSettings = {
    position: 'end',
    format: () => this.labelText
  };

  private atualizeAparencia(background: string, color: string): void {
    this.labelText = this.contrato.limiteContatos;
    this.progressStyles.background = background;
    this.progressStyles.color = color;
    this.emptyStyles.color = color;
  }

  ngOnInit() {
    this.empresaService.obtenhaContrato().then( contrato => {
      this.contrato = contrato;
      this.exibaBarraAtivos();
    })
  }

  exibaBarraAtivos(){
    if(this.contrato.percentualAtivos <= 60){
      this.atualizeAparencia('#428bca', '#428bca');
    } else if (this.contrato.percentualAtivos <= 80) {
      this.atualizeAparencia( '#f5a51a', '#f5a51a');
    } else {
      this.atualizeAparencia( '#e74b3c', '#e74b3c');
    }
  }

  informarCartao() {
      this.router.navigateByUrl('/admin/pagamento/cartao',
        { state: { contrato: this.contrato , fatura: this.contrato.proximaFatura} })
  }

  mudarParaCartao(){
    this.router.navigateByUrl('/admin/pagamento/cartao/desconto',
      { state: { contrato: this.contrato , fatura: this.contrato.proximaFatura} })


  }

  atualizarPlano() {
    this.contrato.percentualAtivos = 90;
    this.exibaBarraAtivos();
  }

  verFatura(fatura: any) {
    window.open(fatura.boleto.link)
  }
}
