<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="row">
    <div class="form-group mb-3 col-7">
      <label for="nome">Nome</label>
      <input type="text" class="form-control" autocomplete="off" appAutoFocus [autoFocus]="true"
             id="nome" name="nome" [(ngModel)]="vitrine.nome" #nome="ngModel"
             placeholder="Nome da virtine" value="" required/>
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3 col-3">
      <label  >Ordem</label>

      <input type="number" class="form-control" autocomplete="off"
             name="ordem" [(ngModel)]="vitrine.ordem" #ordem="ngModel"
             placeholder="Ordem da virtine" value="" required/>
      <div class="invalid-feedback">
        <p *ngIf="ordem.errors?.required">Ordem é obrigatório</p>
      </div>
    </div>

  </div>

  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
    <button type="button" (click)="fechar()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>

</form>
