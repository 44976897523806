import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ProdutoService} from "../../services/produto.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-form-copiar-adicional',
  templateUrl: './form-copiar-adicional.component.html',
  styleUrls: ['./form-copiar-adicional.component.scss']
})
export class FormCopiarAdicionalComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;
  @Input() produto: any  = {};
  @Input() catalogo: any  = {};
  @Input() clonarAdicional: boolean;

  @Output() onCopiou = new EventEmitter();

  produtoCopiar: any;
  produtos: any = [];
  buscando: any;
  configuracoes: any = {};
  erro: any;
  copiando: any;

  constructor(private produtosService: ProdutoService) { }

  ngOnInit(): void {
    setTimeout( () => {
      this.search('');
    }, 1000);
  }


  search(q) {
    this.buscando  = true;
    this.produtosService.obtenhaProdutosAC(this.catalogo, q).then((dados) => {
      this.buscando = false;
      this.produtos = dados;
    }).catch((erro) => {
      this.buscando = false;
    });
  }

  obtenhaAdicionaisSelecionados(){
    if(!this.produtoCopiar || !this.produtoCopiar.id) return [];
    return this.produtoCopiar.camposAdicionais.filter( opcao =>  opcao.selecionada );
  }

  temAdicionaisSelecionados(){
    return this.obtenhaAdicionaisSelecionados().length;
  }

  onSubmit() {
    if(this.copiando) return;
    if(this.frm.valid){
       let adicionais = this.obtenhaAdicionaisSelecionados();

       if(!adicionais.length) return;

       this.copiando = true;
       this.produtosService.copieAdicionais(
         this.catalogo, this.produto, this.produtoCopiar, adicionais, this.clonarAdicional).then( (novosAdicionais) => {
         this.copiando = false;
         this.onCopiou.emit(novosAdicionais)
       }).catch( err => {
         this.copiando = false;
         this.erro = err || 'Ops! Nao foi possivel copiar os adicionais.'
       })
    }
  }

  valide($event: any) {

  }

  alterouCampoBusca($event: any) {

  }

  cancelar() {
    this.onCopiou.emit(null)
  }


}
