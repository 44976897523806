import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class ImportadorCsvService  extends ServerService {
  importe(idEmpresa: number, file: any, opcoes) {
    return this.upload('/importador/empresa/' + idEmpresa + '/csv', file, opcoes)
  }

  obtenhaPrecosCsv(catalogo: number, file: any, opcoes){
    return this.upload('/importador/catalogo/' + catalogo + '/precos/csv', file, opcoes)
  }
}
