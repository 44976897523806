import {Component, OnInit, ViewChild} from '@angular/core';
import {ContatosService} from "../services/contatos.service";
import {BuscarContatoComponent} from "../componentes/buscar-contato/buscar-contato.component";
import {NgForm} from "@angular/forms";
import * as locale from "../locale/calendar-ptbr";
import {ModalKendo} from "../lib/ModalKendo";
import {DataHoraUtils} from "../lib/DataHoraUtils";

@Component({
  selector: 'app-novo-contato',
  templateUrl: './novo-contato.component.html',
  styleUrls: ['./novo-contato.component.scss']
})
export class NovoContatoComponent extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: true})  frm: NgForm;
  @ViewChild('buscarContatoComponent', {static: true}) buscarContatoComponent: BuscarContatoComponent;
  mensagemSucesso: string ;
  mensagemErro: string ;
  salvando: boolean  ;
  contato: any;
  ptBr: any;
  dataNascimento: Date;
  exibirEmail;
  agora: Date;
  constructor( private contatosService: ContatosService ) {
    super()
    this.agora  = new Date();
  }

  ngOnInit() {
    this.ptBr = locale.ptBR
    this.exibirEmail = this.contato.email != null;
  }

  exibaContato(contato: any) {
    this.contato = Object.assign({}, contato);
    if(this.contato.dataNascimento)
        this.contato.dataNascimento = DataHoraUtils.obtenhaDataDaString(this.contato.dataNascimento);

    this.buscarContatoComponent.exibaContato(this.contato);
  }

  onSubmit() {
    delete   this.mensagemErro;
    this.buscarContatoComponent.valide();

    if ( !this.frm.valid )
      return;

    this.contato.telefone = this.buscarContatoComponent.obtenhaTelefone();

    this.contatosService.salveContato(this.contato).then( (resp) => {
      this.mensagemSucesso = 'Contato atualizado com sucesso!';
      this.fecheModal(this.contato)
    }).catch( (erro) => {
      this.salvando = false;
      this.mensagemErro = erro;
    });
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = null;
  }
}
