<form [ngClass]="{'needs-validation': !_submitted, 'was-validated': _submitted}"
      novalidate #frm="ngForm"  >
  <div class="card-box ribbon-box disputa"  >
    <div  class="mb-3 mt-2"   *ngIf="pedido.disputa">
      <h4 class="titulo"><b>
        <i class="k-icon k-i-times-circle mr-1"></i>Cliente relatou problemas com o pedido</b></h4>

      <div class="cardbox" [hidden]="!pedido.disputa.justificativa">
        <p *ngIf="pedido.disputa.cancelamentoParcial">
           <b>   Cliente solicitou um cancelamento parcial do pedido</b>
        </p>

        <p *ngIf="pedido.disputa.cancelamento">
           <b>  Cliente solicitou o cancelamento do pedido</b>
        </p>

        <p *ngIf="pedido.disputa.reembolso">
           <b>  Cliente solicitou o reembolso do pedido</b>
        </p>

        <p *ngIf="pedido.disputa.justificativa">
          Motivo da solicitação: <br>
          "<b>{{pedido.disputa.justificativa}}"</b>
        </p>

      </div>

      <div class="produtos cardbox" *ngIf="pedido.disputa.itens" >
        Produto(s) com problema:
        <div *ngFor="let item of pedido.disputa.itens" class="produto mb-2">
          <h5> {{item.quantity}}x  <b> {{item.descricao}}</b></h5>

          <p class="preco "><b>{{ (item.amount.value / 100) | currency: "BRL"}}</b></p>

          <p>Problema: "<b>{{item.reason}}</b>"</p>
        </div>

      </div>

      <div class="produtos cardbox" *ngIf="pedido.disputa.complementos" >
        Complementos(s) com problema:
        <div *ngFor="let item of pedido.disputa.complementos" class="produto mb-2">
          <h5> {{item.quantity}}x - Cod.: {{item.externalCode}} - "{{item.nome}}"</h5>

          <p class="preco "><b>{{ (item.amount.value / 100) | currency: "BRL"}}</b></p>

          <p>Problema: "<b>{{item.reason}}</b>"</p>
        </div>

      </div>


      <div class="evidencias cardbox" *ngIf="pedido.disputa.evidencias" >
       <h5 class="text-center">   <b>Fotos:</b></h5>

        <div  class="container-imagem mt-2" style="display: flex; justify-content: center">
          <div *ngFor="let evidencia of pedido.disputa.evidencias" class="foto mb-2">
            <img src="{{evidencia.urlPromokit}}" alt="Imagem Protegida" class="cpointer" (click)="verImagem(evidencia.urlPromokit)">

          </div>
        </div>


      </div>

      <ng-container *ngIf="!pedido.disputa.finalizada && !this.pedido.disputa.aguardandoProposta">
        <div class="text-center mt-2 mb-2">
          <h5>Tempo para sua resposta: </h5>

          <div class="tempo-restante text-center">
            <b>{{tempoRestante}}</b></div>

        </div>

        <div class="  mb-2">
          Caso não responda a tempo, o cliente ainda poderá solicitar o cancelamento pelo ifood.
        </div>

        <div class= "botoes  "   [hidden]="pedido.confirmarRejeitarDisputa || pedido.proposta">

          <button  type="button" class="btn  btn-blue   btn-block"  (click)="pedido.proposta=  {}"
                  [disabled]="processando" *ngIf="pedido.disputa.propostasDisponiveis && pedido.disputa.propostasDisponiveis.length">
            Fazer proposta
          </button>

          <button  type="button" class="btn btn-danger btn-block   " (click)="aceitarCancelarPedidoDaDisputa()" [disabled]="processando">
            <i class="k-i-loading k-icon " *ngIf="processando"></i>  Aceitar Cancelamento
          </button>

          <button  type="button" class="btn btn-outline-secondary btn-block    " (click)="confirmeNaoAceitarCancelarPedidoDaDisputa()" [disabled]="processando">
            <i class="k-i-loading k-icon " *ngIf="processando"></i> Não aceitar Cancelamento
          </button>


        </div>

        <ng-container *ngIf="pedido.proposta">
          <div class="form-group mb-0 mt-1 mb-2"   >
            <label    >Selecione a Proposta:</label><br>
            <kendo-dropdownlist class="form-control" name="acaoRetorno"
                                [data]="pedido.disputa.propostasDisponiveis"
                                [filterable]="false" required
                                [textField]="'descricao'" [valueField]="'id'"
                                [(ngModel)]="pedido.proposta.acao"  >
            </kendo-dropdownlist>


            <div class="invalid-feedback" *ngIf="!pedido.proposta.acao">
              Selecione a proposta
            </div>
          </div>


          <div class="form-group mb-2 mt-2"  *ngIf="pedido.proposta.acao">

            <label for="valor">Valor  do <b>{{pedido.proposta.acao.descricao}}</b>
              <span class="text-muted ml-2">( máximo {{pedido.proposta.acao.valorMaximo | currency: "BRL"}} )</span></label>
            <div class="input-group">
              <kendo-numerictextbox [attr.type]="'number'" id="valor" #valor="ngModel"    name="valor"  class="form-control" format="c2"
                                    [(ngModel)]="pedido.proposta.valor"  [max]="pedido.proposta.acao.valorMaximo"  [min]="1"
                                    required    >
              </kendo-numerictextbox>


              <div class="invalid-feedback" *ngIf="pedido.proposta.valor != null">
                <span *ngIf="valor.errors?.maxValue">
                  Valor máximo é {{pedido.proposta.acao.valorMaximo | currency: "BRL"}}
                </span>
                <span *ngIf="valor.errors?.minValue">
                  Valor proposta inválido
                </span>
                <span *ngIf="valor.errors?.required">
                    Informe o valor da proposta
                </span>
              </div>
            </div>
          </div>

          <div class="btn-group">
            <button  type="button" class="btn  btn-blue   " (click)="enviePropostaDisputa()" [disabled]="processando">
              <i class="k-i-loading k-icon " *ngIf="processando"></i> Enviar proposta
            </button>

            <button  type="button" class="btn  btn-outline-secondary  ml-1"   [disabled]="processando" (click)="pedido.proposta = null">
              Voltar
            </button>
          </div>


        </ng-container>

        <ng-container *ngIf="pedido.confirmarRejeitarDisputa">
          <div class="form-group mb-3" >
            <label for="motivoRejeitar"><i class="fas fa-comment-dots"></i>Por qual motivo?</label>

            <textarea type="text" class="form-control" autocomplete="off"  minlength="5" maxlength="255" required
                      id="motivoRejeitar" name="motivoRejeitar" [(ngModel)]="pedido.motivoRejeitarDisputa"
                      placeholder="Informe o motivo" value=""  >
            </textarea>

            <div class="invalid-feedback">
              Informe um motivo para o nao aceitar o cancelamento
            </div>
          </div>

          <div class="btn-group">
            <button   type="button"class="btn btn-danger   " (click)="naoAceitarCancelarPedidoDaDisputa()" [disabled]="processando">
              <i class="k-i-loading k-icon " *ngIf="processando"></i> Confirmar não cancelamento
            </button>

            <button  type="button" class="btn  btn-outline-secondary   ml-1"   [disabled]="processando" (click)="volteAceitarCancelarPedidoDisputa()">
              Voltar
            </button>
          </div>

        </ng-container>

        <p class="alert alert-danger mt-2" *ngIf="erroDisputa">
          {{erroDisputa}}
        </p>

      </ng-container>

      <div class="cardbox resposta mt-1" *ngIf="pedido.disputa.aguardandoProposta">
        <p  >
          <span class="text-black-50"><b>Loja enviou uma proposta, aguardando resposta do cliente</b></span>
        </p>
      </div>

      <div class="cardbox resposta mt-1" *ngIf="pedido.disputa.finalizada">
        <p  >
          <span class="text-black-50"><b>Disputa foi encerrada:</b></span>

          <span *ngIf="pedido.disputa.resultadoDisputa==='ACCEPTED'">
                <br><b>A loja concordou com a solicitação</b></span>

          <span *ngIf="pedido.disputa.resultadoDisputa==='REJECTED'">
                <br><b>A loja não concordou com a solicitação</b></span>


          <span *ngIf="pedido.disputa.resultadoDisputa==='ALTERNATIVE_REPLIED'">
                <br><b>A loja propôs uma outra solução</b></span>


          <span *ngIf="pedido.disputa.motivoRejeicao">
               <br> Resposta da loja <b>"{{pedido.disputa.motivoRejeicao}}"</b>
            </span>

        </p>
      </div>


    </div>
  </div>
</form>

