import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ConstantsService} from "../fidelidade/ConstantsService";
import {mark} from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import {EmpresaService} from "../services/empresa.service";
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

declare var L;

@Component({
  selector: 'app-tela-areas-de-entrega',
  templateUrl: './tela-areas-de-entrega.component.html',
  styleUrls: ['./tela-areas-de-entrega.component.scss']
})
export class TelaAreasDeEntregaComponent implements OnInit, AfterViewInit {
  map: any;
  formaDeEntrega: any;
  enviandoArquivo = false;
  marker: any;
  marcaLoja: any;
  infowindowMarcaLoja: any;

  mudandoLocalizacao: boolean;
  empresa: any;
  iconLoja: any;
  salvando: boolean;
  msgAlterouLocalizacao = '';
  circulosMap = [];
  poligonos = [];
  infosWindow: any[] = [];

  _localizacao = '';
  @Input() exibirFormKML = true;
  @Input() componente = false;

  apiLoaded: Observable<boolean>;

  constructor(httpClient: HttpClient, private constantsService: ConstantsService, private empresaService: EmpresaService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.constantsService.empresa$.subscribe( async(empresa: any) => {
      if( !empresa ) return;

      this.empresa = empresa;

      await this.carregueMapa(empresa);
    })
  }

  obtenhaLocalizacaoEmpresa() {
    const latLong = this.empresa.latitudeLongitude.split(",");

    const latitude = parseFloat(latLong[0]);
    const longitude = parseFloat(latLong[1]);

    return { lat: latitude, lng: longitude };
  }

  async carregueMapa(empresa: any) {
    const myLatLng = this.obtenhaLocalizacaoEmpresa();

    //@ts-ignore
    const { Map } = await google.maps.importLibrary("maps");
    //@ts-ignore
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
      "geometry",
    );

    this.map = new Map(document.getElementById("map") as HTMLElement, {
      center: myLatLng,
      zoom: 13
    });

    this.formaDeEntrega = this.obtenhaFormaDeEntregaReceberEmCasa(empresa);

    const arquivoGeoJson = '/images/empresa/kml/' + this.formaDeEntrega.arquivoGeoJson;
    await this.exibaArquivoGeoJSON(arquivoGeoJson);

    this.crieMarcaLoja(myLatLng);

    this.adicioneRaios(myLatLng);

    this.map.addListener('drag', () => {
      console.log(this.mudandoLocalizacao);
      if( !this.mudandoLocalizacao ) return;

      console.log('drag');

      const center = this.map.getCenter();

      this.marcaLoja.setPosition(center);
      this.infowindowMarcaLoja.setPosition(center);
    });
  }

  obtenhaFormaDeEntregaReceberEmCasa(empresa: any) {
    return empresa.formasDeEntrega.find( (forma) => {
      return forma.formaDeEntrega.nome === 'Receber em casa'
    });
  }

  enviouArquivoKML(resposta: any) {
    this.enviandoArquivo = false;

    this.exibaArquivoGeoJSON(resposta.caminho);
  }

  exibaArquivoGeoJSON(caminhoKML: string) {
    return new Promise( (resolve, reject) => {
      for (const poligono of this.poligonos) {
        poligono.setMap(null);
      }
      this.poligonos = [];

      fetch(caminhoKML)
        .then(res => res.text())
        .then(kmltext => {
          const objeto = JSON.parse(kmltext);

          for (let feature of objeto.features) {
            var polygonCoords = feature.geometry.coordinates[0].map(function (coord) {
              return {lat: coord[1], lng: coord[0]};
            });

            try {
              // Criar o polígono e adicionar ao mapa
              // @ts-ignore
              const polygon = new google.maps.Polygon({
                paths: polygonCoords,
                strokeColor: feature.properties.stroke,
                strokeOpacity: feature.properties.strokeOpacity,
                map: this.map,
                strokeWeight: 2,
                fillColor: feature.properties.fill,
                fillOpacity: feature.properties['fill-opacity']
              });

              this.poligonos.push(polygon);

              // @ts-ignore
              const infowindow = new google.maps.InfoWindow({
                content: '<h3>' + feature.properties.name + '</h3>',
              });
              infowindow.setZIndex(999999999);

              this.infosWindow.push(infowindow);

              polygon.addListener('click', (evt) => {
                for (let info of this.infosWindow) {
                  info.close();
                }

                infowindow.setPosition({
                  lat: evt.latLng.lat(),
                  lng: evt.latLng.lng(),
                });
                infowindow.open(this.map);
              });
            } catch (erro2) {
              console.error(erro2)
            }
          }

          resolve(true);
        }).catch((erro) => {
        resolve(false);
      });
    });
  }

  escolheuArquivo($event: any) {
    this.enviandoArquivo = true;
  }

  falhouEnvioArquivo($event: any) {
    this.enviandoArquivo = false;
  }

  terminouUpload($event: any) {
    this.enviandoArquivo = false;
  }

  adicioneMarca(objMarca: any) {
    const localizacao = objMarca.localizacao;

    const latLong = localizacao.split(",");

    const latitude = parseFloat(latLong[0]);
    const longitude = parseFloat(latLong[1]);

    const myLatLng = { lat: latitude, lng: longitude };

    //@ts-ignore
    const infowindow = new google.maps.InfoWindow({
      content: `<h5>${objMarca.msg}</h5>`
    });

    if( this.marker ) {
      this.marker.setMap(null);
    }

    //@ts-ignore
    this.marker = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      label: {
        text: 'P',
        color: 'white',
        fontSize: '16px'
      }
    });

    infowindow.setPosition(myLatLng);

    infowindow.open(this.map, this.marker);
    this.map.setCenter(myLatLng);
    this.map.setZoom(14);

    /*
    debugger;


    const localizacao = objMarca.localizacao;

    const lat = localizacao.split(",")[0];
    const lng = localizacao.split(",")[1];

    this.marker = L.marker([lat, lng], {
      autoPan: true,
      draggable: false
    }).addTo(this.map);

    this.marker.bindPopup('<h4>' + objMarca.msg + '</h4>').openPopup();

    this.map.panTo(this.marker.getLatLng());
     */
  }

  private adicioneRaios(mylatlng: any) {
    for( const circulo of this.circulosMap ) {
      //circulo.removeFrom(this.map);
      circulo.setMap(null);
    }

    this.circulosMap = [];

    const latLong = this.empresa.latitudeLongitude.split(",");

    const latitude = latLong[0];
    const longitude = latLong[1];

    const alcances = this.formaDeEntrega.alcances.sort((a, b) => {
      return a.alcance > b.alcance ? -1 : 1;
    });

    for( const alcance of alcances ) {
      const estiloCirculo = {radius: alcance.alcance * 1000, stroke: true, color: '#333333',
        dashArray: '5, 5', dashOffset: '1', weight: .8, opacity: 1, fillColor: "transparent", zIndex: 1};

      const tooltip = `
       <h4>
       <strong>Raio: ${alcance.alcance} KM</strong>
       </h4>
       <h5>
       <strong>R$ ${alcance.taxa.toFixed(2)}</strong></h5>
       `;

      // @ts-ignore
      const infowindow = new google.maps.InfoWindow({
        content: tooltip,
        ariaLabel: "Uluru",
      });

      infowindow.setZIndex(999999999);

      const circlePath = [];
      const numberOfPoints = 100; // Número de pontos na polilinha
      const angleIncrement = 400 / numberOfPoints;

      // @ts-ignore
      const circulo = new google.maps.Circle({
        strokeColor: "#666",
        strokeWeight: 1,
        strokeOpacity: 1.0,
        fillOpacity: 0.0,
        map: this.map,
        center: mylatlng,
        radius: alcance.alcance * 1000,
      });

      circulo.addListener('click', (evt) => {
        if( this.mudandoLocalizacao ) {
          return;
        }

        for( let info of this.infosWindow ) {
          info.close();
        }

        infowindow.setPosition({
          lat: evt.latLng.lat(),
          lng: evt.latLng.lng(),
        });
        infowindow.open(this.map);

        evt.domEvent.preventDefault();
      });

      this.circulosMap.push(circulo);
      this.infosWindow.push(infowindow);
    }
  }


  private crieMarcaLoja(myLatLng: any = null) {
    if( this.marcaLoja ) {
      this.marcaLoja.setMap(null);
    }

    //@ts-ignore
    this.marcaLoja = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      title: "Localização da Empresa."
    });

    //@ts-ignore
    this.infowindowMarcaLoja = new google.maps.InfoWindow({
      content: '<h3>Minha Loja</h3>' +
      '<button id="btnPosicaoEmpresa" class="btn btn-sm btn-primary">Mudar Localização Empresa2</button>',
    });

    this.marcaLoja.addListener('click', (evt) => {
      this.infowindowMarcaLoja.setPosition(myLatLng);

      this.infowindowMarcaLoja.open(this.map);

      setTimeout( () => {
        var botao = document.getElementById('btnPosicaoEmpresa');

        botao.addEventListener('click', (evt) => {
          this.mudandoLocalizacao = true;

          this.map.setZoom(15);
          this.infowindowMarcaLoja.close();
        });
      }, 1);
    });

    this.marcaLoja.setZIndex(99999);
  }

  salvarNovaLocalizacao() {
    const lat = this.map.getCenter().lat();
    const long = this.map.getCenter().lng();

    this.salvando = true;
    this.empresaService.salveNovaLocalizacao(this.empresa, lat, long).then( (resposta: any) => {
      this.salvando = false;
      this.empresa.latitudeLongitude = lat + "," + long;

      //this.crieMarcaLoja();

      this.map.setZoom(13);
      this.adicioneRaios(this.obtenhaLocalizacaoEmpresa());

      this.msgAlterouLocalizacao = 'Localização alterada';

      setTimeout( () => {
        this.mudandoLocalizacao = false;
        this.msgAlterouLocalizacao = '';
      }, 1500);

    }).catch( (erro) => {
      this.salvando = false;
    });
  }

  cancelarMudarLocalizacao() {
    this.crieMarcaLoja();

    this.map.zoomOut(1);

    this.mudandoLocalizacao = false;
  }

  @Input()
  set localizacao(localizacao: any) {
    setTimeout( () => {
      this.adicioneMarca(localizacao);
    }, 1000);
  }

  atualizeAlcances(alcances) {
    this.formaDeEntrega.alcances = alcances;

    this.adicioneRaios(this.obtenhaLocalizacaoEmpresa());
  }
}
