<h4>
  <span *ngIf="!adicionalDeProduto.id">Novo</span>
  <span *ngIf="adicionalDeProduto.id">Editar</span>
  Adicional de Produto

  <span class="h4 d-inline-block p-1 ml-1" style="border-radius: 5px;background: #f1ecff;color: #5f2cb6"
        title="Esse adicional é compartilhado com outros." kendoTooltip *ngIf="adicionalDeProduto.compartilhado">
                              <i class="fas fa-link"></i>
                            </span>
</h4>



<form [ngClass]="{'needs-validation': !frmAdicional.submitted, 'was-validated': frmAdicional.submitted}"
      novalidate #frmAdicional="ngForm" (ngSubmit)="salvarAdicionalDeProduto()">
  <div class="row">
    <div class="col-7">
      <div class="form-group mb-3">
        <label for="nome">Nome do Adicional</label>
        <input type="text" class="form-control" autocomplete="off"
               [disabled]="empresa.id && catalogo.compartilhado"
               id="nome" name="nome" [(ngModel)]="adicionalDeProduto.nome" #nome="ngModel"
               placeholder="Nome do Adicional do Produto" value="" required appAutoFocus [autoFocus]="true">
        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        </div>
        <p *ngIf="adicionalDeProduto.compartilhado" class="text-warning">
          Esta alteração irá afetar também nos outros produtos.
        </p>
      </div>

      <div class="form-group mb-3"   >
        <label class="k-checkbox-label">
          <input type="checkbox"     name="obrigatorio" class="k-checkbox "   [(ngModel)]="adicionalDeProduto.obrigatorio" />
          Obrigatório
        </label>
      </div>

      <div class="form-group mb-3">
        <label class="mb-2">Forma de escolha:   </label><br>
        <span    class="ml-4 radio radio-blue">
                <input id="tipo-escolha-simples" name="tipo" type="radio"   [disabled]="empresa.id && catalogo.compartilhado"  kendoRadioButton class=""
                       [(ngModel)]="adicionalDeProduto.tipo" value="escolha-simples"
                       [required]="true"/>
                <label for="tipo-escolha-simples" class="ml-1">Escolha Simples</label>

            </span>
        <span    class="ml-4 radio radio-blue">
                <input id="tipo-multipla-escolha" name="tipo" type="radio"    kendoRadioButton class=""
                       [(ngModel)]="adicionalDeProduto.tipo" value="multipla-escolha"
                       [required]="true"/>
                <label for="tipo-multipla-escolha" class="ml-1">Múltipla Escolha</label>

            </span>

        <div class="row mb-2" *ngIf="adicionalDeProduto.tipo == 'multipla-escolha'">
          <div class="col-6">
            <div class="form-group mt-2 ml-4">
              <label for="qtdMinima" class="text-muted">Quantidade mínima que deve escolher:</label>
              <kendo-numerictextbox id="qtdMinima" class="form-control" autocomplete="off" [disabled]="empresa.id && catalogo.compartilhado"
                                    [min]="0"  name="qtdMinima" required (ngModelChange)="alterouMinimo($event)"
                                    [(ngModel)]="adicionalDeProduto.qtdMinima">
              </kendo-numerictextbox>
              <div class="invalid-feedback" *ngIf="adicionalDeProduto.qtdMinima == null">
                <p>Informe a quantidade mínima</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2 ml-4">
              <label for="qtdMinima" class="text-muted">Quantidade máxima que pode escolher:</label>
              <kendo-numerictextbox   class="form-control" autocomplete="off" [disabled]="empresa.id && catalogo.compartilhado"
                                    [min]="minimoMaximo"  name="qtdMaxima" #qtdMaxima="ngModel" required
                                    [(ngModel)]="adicionalDeProduto.qtdMaxima">
              </kendo-numerictextbox>
              <div class="invalid-feedback" >
                <p *ngIf="adicionalDeProduto.qtdMaxima == null">Informe a quantidade máxima</p>
                <p *ngIf="adicionalDeProduto.qtdMaxima  < minimoMaximo">Valor mínimo é {{minimoMaximo}}</p>
              </div>

            </div>
          </div>
          <div class="col-12">
            <div class=" ml-4">
              <label class="k-checkbox-label">
                <input type="checkbox"   name="podeRepetirItem" class="k-checkbox "   [disabled]="empresa.id && catalogo.compartilhado" [(ngModel)]="adicionalDeProduto.podeRepetirItem" />
                Permite escolher opção mais de uma vez</label>
            </div>

          </div>
          <div kendoToolTip class="col-12">
            <label class="mt-2 mb-2">Tipo de cobrança:   </label><br>
            <span    class="ml-4 radio radio-blue">
                <input id="cobranca-soma" title="A soma dos selecionados será adicionada ao total"
                       [disabled]="empresa.id && catalogo.compartilhado" name="tipoDeCobranca" type="radio"    kendoRadioButton class=""
                       [(ngModel)]="adicionalDeProduto.tipoDeCobranca" value="SOMA" [defaultChecked]="true"
                       [required]="true"/>
                <label for="cobranca-soma" class="ml-1">Soma</label>

            </span>
            <span    class="ml-4 radio radio-blue">
                <input id="cobranca-media"
                       [disabled]="empresa.id && catalogo.compartilhado"
                       title="A média dos selecionados será adicionada ao total" name="tipoDeCobranca" type="radio"    kendoRadioButton class=""
                       [(ngModel)]="adicionalDeProduto.tipoDeCobranca" value="MEDIA"
                       [required]="true"/>
                <label for="cobranca-media"   class="ml-1">Média</label>

            </span>

            <span    class="ml-4 radio radio-blue">
                <input id="cobranca-maior"

                       [disabled]="empresa.id && catalogo.compartilhado"
                       title="O maior valor selecionado será adicionado ao total" name="tipoDeCobranca" type="radio"    kendoRadioButton class=""
                       [(ngModel)]="adicionalDeProduto.tipoDeCobranca" value="MAIOR"
                       [required]="true"/>
                <label for="cobranca-maior" class="ml-1">Maior </label>

            </span><br>



            <span class="ml-4 text-muted" *ngIf="adicionalDeProduto.tipoDeCobranca === 'SOMA'">A soma dos selecionados será adicionada ao total</span>
            <span class="ml-4 text-muted" *ngIf="adicionalDeProduto.tipoDeCobranca === 'MEDIA'">A média dos selecionados será adicionada ao total</span>
            <span class="ml-4 text-muted" *ngIf="adicionalDeProduto.tipoDeCobranca === 'MAIOR'">O maior valor selecionado será adicionado ao total</span>
          </div>

        </div>


        <div class="invalid-feedback" *ngIf="!adicionalDeProduto.tipo">
          <p>Informe a forma de escolha da opção adicional</p>
        </div>

      </div>


    </div>

    <div class="col-5 vl ontainer-adicionais">
      <label>Confira como ficará o campo extra:</label>

      <app-site-campo-adicional-edicao #campoAdicionalEdicao [campoAdicional]="adicionalDeProduto" [itemPedido]="itemPedidoExemplo" [posicao]="0">

      </app-site-campo-adicional-edicao>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div>
        <label>Opções Disponíveis</label>
      </div>

      <p *ngIf="adicionalDeProduto.compartilhado" class="text-warning">
        Esta alteração irá afetar também nos outros produtos. Se você apagar opções, valerá para todos produtos.
      </p>

      <kendo-grid    [kendoGridBinding]="adicionalDeProduto.opcoesDisponiveis"
                     editable="incell"
                     [loading]="carregando" style="min-height: 150px;"
                     (edit)="editarOpcao($event)"
                     (remove)="removerOpcao($event)"
                     (pageChange)="carregue($event)" height="350">
        <ng-template kendoGridToolbarTemplate [position]="'top'">
          <div class="row">
             <div class="col-2">
               <button class="k-button btn-primary" (click)="adicionarOpcao()">
                 Adicionar
               </button>
             </div>
             <div class="col-8">
               <div class="form-group mb-2">
                 <label for="campoOrdem" class="d-inline-block mr-2">Ordem exibição:</label>
                 <kendo-combobox  (valueChange)="mudoCampoOrdenar($event)" textField="descricao" valueField="campo"
                                 [(ngModel)]="campoOrdenar" [data]="camposOrdenar"

                                 class="form-control w-75 d-inline-block" id="campoOrdem" name="campoOrdem"
                                 placeholder="Selecione em qual ordem sera exibida"
                 >
                 </kendo-combobox>

               </div>
             </div>
          </div>

        </ng-template>
        <kendo-grid-messages
          loading="Carregando"
          noRecords="Sem opções disponíveis"
        >
        </kendo-grid-messages>
        <kendo-grid-column  width="90px"  title="Id">

          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  >
             <span class="text-blue">  <b>{{dataItem.id}}</b></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column  width="50px">

          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  >
            <img style="max-width: 20px" [src]="'/images/empresa/'+ dataItem.linkImagem"  *ngIf="dataItem.linkImagem" />
            <span *ngIf="!dataItem.linkImagem">-</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Nome">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  >
            <span [ngClass]="{'removida': dataItem.removida}">{{dataItem.nome}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Valor" width="125">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  *ngIf="!catalogo || !empresa.id || !catalogo.precoPorEmpresa">
            <span [ngClass]="{'removida': dataItem.removida}">{{dataItem.valor | currency: 'BRL'}}</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  *ngIf="catalogo && empresa.id && catalogo.precoPorEmpresa">
            <span [ngClass]="{'removida': dataItem.removida}">
              {{(dataItem.valorNaEmpresa) | currency: 'BRL'}}</span>
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column title="Cod. Pdv" width="125"><!-- *ngIf="empresa.integracaoDelivery">-->
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
            <span [ngClass]="{'removida': dataItem.removida}">{{dataItem.codigoPdv  }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Disponível" width="100" *ngIf="!catalogo.disponibilidadePorEmpresa || !empresa.id">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
            <input  name="disponivel{{rowIndex}}" [disabled]="empresa.id && catalogo.compartilhado && !catalogo.disponibilidadePorEmpresa"
                    class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox" (ngModelChange)="marqueAtualizar(dataItem)"
                    [(ngModel)]="dataItem.disponivel"/>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Disponível" width="100" *ngIf="empresa.id && catalogo.disponibilidadePorEmpresa">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >

            <input  name="disponivel{{rowIndex}}"
                    class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox" (ngModelChange)="marqueAtualizar(dataItem)"
                    [(ngModel)]="dataItem.disponibilidadeNaEmpresa"/>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column title="" width="120">
          <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
            <ng-container *ngIf="!dataItem.removida">
              <button kendoGridEditCommand [primary]="true"   ><i class="fa fa-edit"></i></button>
              <button kendoGridRemoveCommand  class="ml-1"   >
                <i class="fa fa-trash "></i></button>
            </ng-container>


            <button  class="btn btn-light btn-xs"   *ngIf="dataItem.removida"
                     (click)="cancelarRemocao($event, dataItem)" >
              <i class="fa fa-undo"></i></button>

          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
        <input type="hidden" [(ngModel)]="adicionalDeProduto.opcoesDisponiveis" name="opcoesDisponiveis" #opcoesDisponiveis="ngModel" [minLength]="1">
        <div class="invalid-feedback">
          <p *ngIf="opcoesDisponiveis.errors?.minimoLista">O adicional de produto deve ter ao menos uma opção.</p>
        </div>
    </div>
  </div>


  <div class="modal-footer mt-2">

    <p *ngIf="erro" class="text-danger mt-2 mb-2">
      {{erro}}
    </p>

    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando"  >
      <i class="k-i-loading k-icon " *ngIf="salvando"> </i>
      Salvar</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="cancelar()"
            [disabled]="salvando" >Cancelar</button>
  </div>
</form>

<kendo-dialog title="Opção" *ngIf="abraDialogoOpcao" (close)="fecharDialogOpcao()" [minWidth]="250" [width]="800">

  <app-form-opcaoadicional #formCadastroOpcao [adicionalDeProduto]="adicionalDeProduto"  [produto] = produto
                           [podeEditar]="!empresa.id || !catalogo.compartilhado"
                           [podeEditarPrecos]="!empresa.id || !catalogo.compartilhado || possuiPermissaoEditarPrecos"
                           [pizzaria] = pizzaria  [catalogo]="catalogo" [templatesPiza]="templatesPiza" [sistemaIntegrado]="sistemaIntegrado"
                           (onSalvarOpcao)="salvouOpcaoDeAdicional($event)"
                           (onFechouModal)="fecheDialogOpcao()">

  </app-form-opcaoadicional>
</kendo-dialog>

<kendo-dialog title="Remover Opção" *ngIf="abraDialogoRemover" (close)="fecharDialogRemover()" [minWidth]="250" [width]="450">
  <div class="form-group mb-3">
    Tem certeza que você deseja remover a opção "{{opcaoRemover.nome}}"?
  </div>
  <div class="modal-footer">
    <button type="button" (click)="confirmouRemoverOpcao()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" (click)="fecharDialogRemover()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</kendo-dialog>
