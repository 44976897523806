import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConfigMensagensBotService  extends ServerService {

  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  todas() {
    return this.httpCliente.get('/bots/configMensagens')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveConfigMensagem(configMensagem: any) {
    return this.salve('/bots/configMensagens', configMensagem);
  }

}
