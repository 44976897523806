import {AfterContentInit, Directive, ElementRef} from '@angular/core';
import {NumericTextBoxComponent} from "@progress/kendo-angular-inputs";

@Directive({
  selector: '[appRemovaWheel]'
})
export class RemovaWheelDirective implements AfterContentInit {

  constructor(private el: ElementRef, private controle: NumericTextBoxComponent) {
  }

  public ngAfterContentInit() {
    this.controle.numericInput.nativeElement.addEventListener('wheel', (e) => {
      e.stopImmediatePropagation();
    });
  }
}
