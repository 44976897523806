import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NovoCartaoComponent} from "../novo-cartao/novo-cartao.component";
import {ChartComponent} from "@progress/kendo-angular-charts";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {AutorizacaoService} from "../services/autorizacao.service";
import {VendasFidelidadeService} from "../services/vendas-fidelidade.service";
import {ContatosService} from "../services/contatos.service";
import moment from "moment";
import {TelaGraficoFidelidadeComponent} from "../tela-grafico-fidelidade/tela-grafico-fidelidade.component";
import {TelaGraficoComparacaoComponent} from "../tela-grafico-comparacao/tela-grafico-comparacao.component";
import {DatePickerComponent} from "@progress/kendo-angular-dateinputs";
import {CurrencyPipe} from "@angular/common";
import {DeviceDetectorService} from "ngx-device-detector";
import {TelaContatosPorCategoriaComponent} from "../tela-contatos-por-categoria/tela-contatos-por-categoria.component";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {ColorUtils} from "../ColorUtils";
import {EnumTipoDeAcao} from "../../../server/lib/emun/EnumTipoDeAcao";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {ArmazenamentoService} from "../services/armazenamento.service";
import {RedesService} from "../services/redes.service";

moment.locale('pt-BR');

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss']
})
export class RelatoriosComponent implements OnInit {
  @ViewChild(NovoCartaoComponent) dialogNovoCartao;
  @ViewChild(ChartComponent, {static: true})   chart ;
  resumo = {total: 0, qtdeAtivos: 0 , qtdeInativos: 0 };
  series: Observable<any>;
  seriesLabels: [] = [];
  graficos = ['dia' , 'mes' , 'ano' ];
  tipoGrafico ;
  ultimasAcoes: any = [];
  tipoDeAcaoEnum = EnumTipoDeAcao;
  resumoVenda: any = {
    total: 0, pontos: 0,  mesFormatado: 'Sem Vendas'
  };

  public value: Date = new Date();
  public hoje: Date = new Date();
  dataAtual = null;

  public valorMes1: Date = new Date();
  public valorMes2: Date = new Date();

  @ViewChild('telaGraficoPorQtde') telaGraficoPorQtde: TelaGraficoFidelidadeComponent;
  @ViewChild('telaGraficoPorValor') telaGraficoPorValor: TelaGraficoFidelidadeComponent;
  @ViewChild('telaContatosPorCategoriaComponent') telaContatosPorCategoriaComponent: TelaContatosPorCategoriaComponent;


  @ViewChild('telaGraficoComparacaoPorQtde') telaGraficoComparacaoPorQtde: TelaGraficoComparacaoComponent;
  @ViewChild('telaGraficoComparacaoPorValor') telaGraficoComparacaoPorValor: TelaGraficoComparacaoComponent;
  @ViewChild('telaGraficoComparacaoPorQtdeDonut') telaGraficoComparacaoPorQtdeDonut: TelaGraficoComparacaoComponent;
  @ViewChild('telaGraficoComparacaoPorValorDonut') telaGraficoComparacaoPorValorDonut: TelaGraficoComparacaoComponent;

  mesAtual = '';

  dataMes1: any;
  dataMes2: any;
  carregando: boolean;
  ehCelular = false;
  expandiuConfiguracoes = false;
  contatos: any = [];
  dataGraficoFidelidade: moment.Moment;
  categoriaSelecionada: string;
  grupoSelecionado: any = {};
  empresa: any = {};
  usuarioLogado: any;
  dataInicioVendas: string;
  dataFimVendas: string;
  rede: boolean;
  filtro: any = {}
  relatoriosMes = {};
  redes = []
  atualizouGraficos = false;
  constructor(  private router: Router, private constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService , private vendasService: VendasFidelidadeService,
              private contatoService: ContatosService, private cp: CurrencyPipe,
              private deviceService: DeviceDetectorService, private empresasService: EmpresasService,
              private armazenamentoService: ArmazenamentoService, private redesService: RedesService) {

    this.series = vendasService;
    this.tipoGrafico = this.graficos[0];
    this.usuarioLogado = this.autorizacaoService.getUser();

    this.empresa = this.usuarioLogado.empresa;

    this.dataMes1 = moment().subtract(1, 'month');
    this.dataMes2 = moment();

    this.valorMes1 = this.dataMes1.toDate();
    this.valorMes2 = this.dataMes2.toDate();

    this.ehCelular = this.deviceService.isMobile();
    this.rede = this.router.url.indexOf('/admin-rede') >= 0;

    if(this.rede ) {
      this.redesService.redesEmpresa().then(redes => {
        this.redes = redes;
      });

      this.filtro.rede = this.redes[0]
    }

    this.carregueResumoVendas();
    this.exibaGraficoDia();
  }

  carregueResumoVendas(){
    let dados: any = {};

    if(this.rede)   dados.r = 1;
    if(this.filtro.rede) dados.rid = this.filtro.rede.id;

    this.contatoService.obtenhaResumo(dados).then( resumo => {
      this.resumo = resumo;
      this.relatoriosMes = {};

      setTimeout( () => {
        this.dataAtual = moment().startOf('month');
        if( moment().date() < 10 ) {
          this.dataAtual = this.dataAtual.subtract(1, 'months')
        }

        this.calculeVendas();

        this.value = this.dataAtual.toDate();
        this.atualizeTela(this.dataAtual, this.telaGraficoPorQtde, this.telaGraficoPorValor);
      }, 100);
    });
  }

  public static obtenhaCorStatic(registro: any) {
    let cor = '#28B4C8';

    if( registro.rotulo === 'Novo' ) {
      cor = '#28B4C8';
    } else if( registro.rotulo === 'Novo Importado' ) {
      cor = '#2798a6';
    } else if( registro.rotulo === 'VIP' ) {
      cor = '#2DCB75';
    } else if( registro.rotulo === 'VIP Em Risco' ) {
      cor = '#59e494';
    } else if( registro.rotulo === 'Recorrente' ) {
      cor = '#2060ce';
    } else if( registro.rotulo === 'Recorrente Risco' ) {
      cor = '#5191ff';
    } else if( registro.rotulo === 'Regular' ) {
      cor = '#FF7300';
    } else if( registro.rotulo === 'Regular Em Risco' ) {
      cor = '#ffbe74';
    } else if( registro.rotulo === 'Em Risco' ) {
      cor = '#FFD246';
    } else if( registro.rotulo === 'Perdido' ) {
      cor = '#ee503d';
    }

    return cor;
  }

  obtenhaCor(registro: any) {
    return ColorUtils.obtenhaCorStatic(registro);
  }

  ngOnInit() {

  }

  exibaGraficoDia() {
    this.tipoGrafico = 'dia';
    this.vendasService.queryDia(this.seriesLabels, this.resumoVenda) ;
  }

  exibaGraficoMes() {
    this.tipoGrafico = 'mes';
    this.vendasService.queryMes(this.seriesLabels, this.resumoVenda) ;

  }

  exibaGraficoAno() {
    this.tipoGrafico = 'ano';
    this.vendasService.queryAno(this.seriesLabels, this.resumoVenda) ;
  }

  mudouValor(controle: DatePickerComponent, $event) {
    const novaData = moment($event);
    this.dataAtual = novaData;
    this.dataGraficoFidelidade = novaData;

    this.calculeVendas();

    this.atualizeTela(novaData, this.telaGraficoPorQtde, this.telaGraficoPorValor);
  }

  atualizeTela(novaData: any, telaGraficoPorQtde: TelaGraficoFidelidadeComponent, telaGraficoPorValor: TelaGraficoFidelidadeComponent) {
    const inicioDoMes = novaData.startOf('month').format('YYYY-MM-DD');
    const fimDoMes = novaData.endOf('month').format('YYYY-MM-DD');
    this.mesAtual = novaData.format('MMMM YYYY');
    this.carregando = true;
    this.telaContatosPorCategoriaComponent.limpeGrid();
    this.obtenhaDados(novaData, (relatorio) => {
      this.carregando = false;
      telaGraficoPorQtde.atualizeRelatorio(inicioDoMes, fimDoMes, relatorio);
      telaGraficoPorValor.atualizeRelatorio(inicioDoMes, fimDoMes, relatorio);

      this.atualizeGraficos();
    });

  }

  mudouValorCompararMeses() {
    this.relatoriosMes = {};
    this.calculeVendas();

    this.empresasService.atualizeConfiguracoesClassificacao(this.empresa).then( () => {
      this.armazenamentoService.salveSemExpirar('pkuser', JSON.stringify(this.usuarioLogado));
    });

    this.atualizeTela(this.dataAtual, this.telaGraficoPorQtde, this.telaGraficoPorValor);


  }

  mudouValorCompararMes1($event: Date) {
    this.dataMes1 = moment($event);

    this.atualizeGraficos();
  }

  mudouValorCompararMes2($event: Date) {
    const novaData = moment($event);

    this.dataMes2 = novaData;

    this.atualizeGraficos();
  }

  selecionouTab(tab){
    if(!this.atualizouGraficos)
     setTimeout(() => {
       this.atualizeGraficos();
     }, 100)
  }

  atualizeGraficos() {
    this.obtenhaDados(this.dataMes1, (relatorio1) => {
      this.obtenhaDados(this.dataMes2, (relatorio2) => {
        if(this.telaGraficoComparacaoPorQtde){
          this.atualizouGraficos = true;
          this.telaGraficoComparacaoPorQtde.atualizeRelatorio(this.dataMes1, this.dataMes2, relatorio1, relatorio2);
          this.telaGraficoComparacaoPorValor.atualizeRelatorio(this.dataMes1, this.dataMes2, relatorio1, relatorio2);
          this.telaGraficoComparacaoPorQtdeDonut.atualizeRelatorio(this.dataMes1, this.dataMes2, relatorio1, relatorio2);
          this.telaGraficoComparacaoPorValorDonut.atualizeRelatorio(this.dataMes1, this.dataMes2, relatorio1, relatorio2);
        }
      });
    });
  }

  public obtenhaDados(mes: any, cb: Function) {
    const inicioDoMes = mes.startOf('month').format('YYYY-MM-DD');
    const fimDoMes = mes.endOf('month').format('YYYY-MM-DD');
    const relatorio: any = {};

    relatorio.descMes = mes.format('MMMM YYYY');
    relatorio.inicioDoMes = inicioDoMes;
    relatorio.fimDoMes = fimDoMes;
    relatorio.mapData = {};

    if(this.relatoriosMes[relatorio.descMes]) return cb(this.relatoriosMes[relatorio.descMes])

    this.contatoService.obtenhaRelatorioFidelidade(inicioDoMes, fimDoMes, this.empresa.qtdeVisitasRecorrente,
      this.empresa.qtdeDiasEmRisco, this.empresa.qtdeDiasPerdido, this.empresa.qtdeComprasVIP,
      this.empresa.ticketMedioVIP, this.empresa.qtdeDiasPeriodo, this.rede,
      (this.filtro.rede ? this.filtro.rede.id : null)).then( (dados: any) => {
      for( let registro of dados.registros ) {
        registro.cor = this.obtenhaCor(registro);

        registro.tela = this;
        let rotulo = '';

        if( this.tipoGrafico === 'donut' ) {
          rotulo = registro.rotulo;
        }

        registro.rotuloQtdeClientes = rotulo + ' ' + registro.qtdeClientes + ' clientes';
        registro.rotuloTotalGasto = rotulo + ' ' + this.cp.transform(registro.totalGasto, 'BRL');

        relatorio.mapData[registro.rotuloQtdeClientes] = registro;
        relatorio.mapData[registro.rotuloTotalGasto] = registro;
        relatorio.mapData[registro.rotulo] = registro;
      }

      relatorio.registros = dados.registros;
      relatorio.dados = dados;

      this.relatoriosMes[ relatorio.descMes] = relatorio;

      cb(relatorio);
    });
  }

  expanda() {
    this.expandiuConfiguracoes = !this.expandiuConfiguracoes;
  }

  selecionouCategoria(rotulo: string, grupoSelecionado: any) {
    this.categoriaSelecionada = rotulo;
    this.grupoSelecionado = grupoSelecionado;

    const inicioDoMes = this.dataAtual.startOf('month').format('YYYY-MM-DD');
    const fimDoMes = this.dataAtual.endOf('month').format('YYYY-MM-DD');

    this.telaGraficoPorValor.modificou();
    this.telaGraficoPorQtde.modificou();

    this.telaContatosPorCategoriaComponent.carregando = true;
    this.contatoService.obtenhaClientesFidelidadePorCategoria(rotulo, inicioDoMes, fimDoMes, this.empresa.qtdeVisitasRecorrente,
      this.empresa.qtdeDiasEmRisco, this.empresa.qtdeDiasPerdido, this.empresa.qtdeComprasVIP, this.empresa.ticketMedioVIP,
      this.empresa.qtdeDiasPeriodo, this.rede, (this.filtro.rede ? this.filtro.rede.id : null)).then( (dados: any) => {
      this.contatos = dados.registros;
      this.telaContatosPorCategoriaComponent.alterouOsProdutos(this.categoriaSelecionada, this.mesAtual, this.contatos,
          this.filtro.rede  ? this.filtro.rede.nome : '');
      this.telaContatosPorCategoriaComponent.carregando = false;
    });

    setTimeout( () => {
      window.scroll({
        top: document.body.scrollHeight + 1000,
        behavior: 'smooth'
      });
    }, 0);
  }

  private calculeVendas() {
    this.dataInicioVendas = moment(this.dataAtual).startOf('month').subtract(this.empresa.qtdeDiasPeriodo, 'day').format('DD MMM YYYY');
    this.dataFimVendas = moment(this.dataAtual).endOf('month').format('DD MMM YYYY');
  }
}
