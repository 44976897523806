import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NotificacaoAppService} from "../../../services/notificacao-app.service";
declare let moment;

@Component({
  selector: 'app-lista-notificacoes-app',
  templateUrl: './lista-de-notificacoes-app.component.html',
  styleUrls: ['./lista-de-notificacoes-app.component.scss']
})
export class ListaDeNotificacoesAppComponent implements OnInit {
  notificacoes = [];
  total = 0;
  carregando = false;
  tiposDeEnvio: any = ['Teste'];

  constructor(private router: Router, private notificacaoAppService: NotificacaoAppService) { }

  ngOnInit(): void {
    this.carregando = true;

    this.notificacaoAppService.obtenhaNotificacoes().then( (resposta: any) => {
      this.carregando = false;
      this.notificacoes = resposta.data;
      this.total = resposta.total;

      this.notificacoes.forEach( ( async (campanha: any) => {
        const diferenca = moment(campanha.horarioEnvio).diff(moment());

        /*
        if( diferenca < 0 ) {
          campanha.horario = 'Deveria ter sido enviada';
        } else {
          campanha.horario = 'Será enviada ' + moment.duration(diferenca).humanize(true);
        }
        let qtdeLidas =  await this.campanhaService.obtenhaQtdeLidas(campanha);
        this._ngZone.run(() => {
          campanha.qtdeLidas = qtdeLidas;
        });
         */
      }));
    }).catch( (erro: any) => {
      this.carregando = false;
    });
  }

  novaCampanha() {
    this.router.navigateByUrl( '/admin/nova-campanha-app').then( () => {});
  }
}
