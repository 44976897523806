<kendo-grid [pageSize]="paginacao.size"
            [skip]="paginacao.skip"
            [style]=""
            [kendoGridBinding]="templatesDeMensagem"
            [loading]="carregando" style="min-height: 300px;"
            [scrollable]="false"
            footerStyle="font-size: 11px;"
            [pageable]="{
              buttonCount: paginacao.buttonCount,
              info: paginacao.info,
              type: paginacao.type,
              pageSizes: paginacao.pageSizes,
              previousNext: paginacao.previousNext
            }"
            (edit)="editarTemplate($event)"
            (pageChange)="mudouPaginacao($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="  btn btn-blue"  (click)="novoTemplateDeMensagem()">
      <i class="fe-plus mr-1"></i>
      Adicionar template de mensagem
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="nenhum template cadastrado"
    loading="Carregando"
    pagerItemsPerPage="itens por página"
  >
  </kendo-grid-messages>


  <kendo-grid-column field="id" title="ID"   width="70">  </kendo-grid-column>

  <kendo-grid-column title="Nome" width="200" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span><b>{{dataItem.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Mensagem" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="text-primary">{{dataItem.mensagem}}</span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-command-column title="" width="100">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarTemplate($event)">Editar</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
