<h4 _ngcontent-mki-c5="" class="page-title"><i _ngcontent-eic-c2="" class="fab fa-whatsapp"></i> Mensagens do Chatbot</h4>

<div class="card">
  <div class="card-body">
    <div class="card-body" *ngIf="loading">
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
    </div>

    <kendo-tabstrip class="nav-bordered " *ngIf="!loading">
      <kendo-tabstrip-tab [title]="configMensagemDeBot.tipoDeMensagem" *ngFor="let configMensagemDeBot of listaConfigsMensagem">
        <ng-template kendoTabContent>
          <app-form-config-mensagem-bot [configMensagemDeBot]="configMensagemDeBot"></app-form-config-mensagem-bot>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>

  </div>
</div>
