import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {ContatosService} from "../../services/contatos.service";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'botoes-home',
  templateUrl: './botoes-home.component.html',
  styleUrls: ['./botoes-home.component.scss']
})
export class BotoesHomeComponent implements OnInit {
  @Input() public empresa: any;
  @Input() tela: any;
  temModuloFidelidade: boolean ;
  temModuloPedidos: boolean ;
  marcando: boolean;
  enviandoContatos: boolean;
  exibindoNaExtensao: boolean;

  constructor(private router: Router, public constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService, private contatoService: ContatosService) {

    this.constantsService.moduloFidelidade$.subscribe( data => this.temModuloFidelidade  = data  )
    this.constantsService.moduloPedido$.subscribe( data => this.temModuloPedidos  = data)

    this.exibindoNaExtensao = this.inIframe();
  }

  ngOnInit() {
  }

  temPermisaoAdicionarPontos(){
    if(!this.temModuloFidelidade) return false;

    return RotaGuardClient.adicionarPontosFidelidade( this.autorizacaoService.getUser())
  }

  temPermisaoResgatarBrindes(){
    if(!this.temModuloFidelidade) return false;

    return RotaGuardClient.resgatarBrindesFidelidade( this.autorizacaoService.getUser())
  }


  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  open() {
    this.router.navigateByUrl('/admin/contatos/novo').then( (resp) => {});
  }

  abraAdicionarPontos() {
    this.router.navigateByUrl('/admin/contatos/pontos/adicionar').then( (resp) => {});
  }

  abraTrocarPontos() {
    this.router.navigateByUrl('/admin/contatos/pontos/trocar').then( (resp) => {});
  }

  abraVerContatos() {
    this.router.navigateByUrl('/admin/contatos').then( (resp) => {});
  }

  abraAcompanharPedidos() {
    this.router.navigateByUrl('/admin/pedidos').then( (resp) => {});
  }

  abraAcompanharMensagens() {
    this.router.navigateByUrl('/admin/mensagens').then( (resp) => {});
  }

    abraNovoPedido() {
      this.router.navigateByUrl('/admin/pedidos/novo' + '?urlRetorno=' + location.pathname).then( (resp) => {});
    }

  abraNovoProduto() {
    this.router.navigateByUrl('/admin/produtos' + '?urlRetorno=' + location.pathname).then( (resp) => {});
  }

  adicionarTags() {
    this.marcando = true;
    this.contatoService.obtenhaContatosStatus().then( (contatos) => {
      window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {
          tipo: 'ADICIONE_TAG',
          contatos: contatos
        }}, "*");
    });
  }

  salvarContatosPromokit() {
    this.enviandoContatos = true;

    this.tela.notifique('Carregando contatos Whatsapp');

    this.contatoService.obtenhaContatosStatus().then( (contatos) => {
      window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {
          tipo: 'SALVAR_CONTATOS_WHATSAPP'
        }}, "*");
    }).catch( (erro: any) => {
    });
  }

  terminouEnviarContatos() {
    this.enviandoContatos = false;
  }
}
