import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EnderecoService} from "../../services/endereco.service";
import {NgForm} from "@angular/forms";
import {ConstantsService} from "../ConstantsService";
import {FormaDeEntrega} from "../../pedidos/objetos/FormaDeEntrega";

@Component({
  selector: 'app-form-endereco-contato',
  templateUrl: './form-endereco-contato.component.html',
  styleUrls: ['./form-endereco-contato.component.scss']
})
export class FormEnderecoContatoComponent implements OnInit , AfterViewInit{
  @ViewChild('frm', { static: true})  frm: NgForm;
  @ViewChild('txtCEP', {static: true}) private txtCEP: any;
  @Output() onSalvou =   new EventEmitter();
  @Input() cepObrigatorio = false;
  @Input()  endereco: any = {};
  @Input() nomeLocal = true;
  @Input() textoBotaoSalvarEndereco = 'Salvar Endereço';
  buscandoCEP: boolean;
  estados: any = [];
  cidades: any = [];
  maskedInputController;
  public bairroObrigatorio = true;
  mask: any;
  cep: string;
  iframe = false;
  zonasDeEntrega: any = []
  selecionarBairroDaZona: boolean;
  public mask2 = '00.000-000';
  entregaPorZona: boolean;
  formaDoPedido: any = {

  };

  constructor(private enderecoService: EnderecoService,
              private  constantsService: ConstantsService) {

    this.mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  ngOnInit(): void {
    this.iframe = this.inIframe();
  }

  ngAfterViewInit(): void {
    if(this.endereco.id)
      this.endereco.estado = this.endereco.cidade.estado;

    this.enderecoService.obtenhaEstados().then( (estados) => {
      this.estados = estados;

      this.constantsService.empresa$.subscribe( (empresa) => {
        if(empresa && empresa.id){

          if(!this.endereco.cidade && empresa.enderecoCompleto) {
            this.endereco.cidade = empresa.enderecoCompleto.cidade;
            this.endereco.estado = empresa.enderecoCompleto.cidade.estado;
          }

          for (let i = 0; i < empresa.formasDeEntrega.length; i++) {
            const formaDeEntrega = empresa.formasDeEntrega[i];

            if (formaDeEntrega.nome === FormaDeEntrega.RECEBER_EM_CASA)
              this.formaDoPedido = formaDeEntrega;
          }

          if (this.formaDoPedido && this.formaDoPedido.tipoDeCobranca === 'zona') {
            this.entregaPorZona = true;
          }

          if(this.endereco.cidade)
            this.carregueEstadoECidade(this.endereco.cidade.nome, this.endereco.cidade.estado.sigla)
        }

      })

    });
  }

  tenteSelecionarZonaPeloBairro(nomeBairro: string) {
    let zonaProcurada = null;
    for( let i = 0; i < this.zonasDeEntrega.length; i++ ) {
      const zona = this.zonasDeEntrega[i];


      const nomeZona = zona.nome.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      nomeBairro = nomeBairro.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      if( nomeZona === nomeBairro ) {
        zonaProcurada = zona;
      }
    }

    if( zonaProcurada ) {
      this.endereco.zonaDeEntrega = zonaProcurada;
      this.endereco.bairro = zonaProcurada.nome;
    } else {
      this.endereco.zonaDeEntrega = null;
    }
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  mudouEstado(estado: any) {
    if( estado == null ) {
      return;
    }

    this.enderecoService.obtenhaCidades(estado).then( (cidades) => {
      this.cidades = cidades;
    })
  }

  alterou($evento: Event) {
    if( this.endereco.cep.length < 8 ) {
      return;
    }

    this.buscandoCEP = true;

    this.enderecoService.busquePorCEP(this.unmask(this.endereco.cep)).then( (resposta) => {
      this.buscandoCEP = false;
      this.endereco.logradouro = resposta.logradouro;

      if( this.selecionarBairroDaZona ) {
        this.tenteSelecionarZonaPeloBairro(resposta.bairro);
      } else {
        this.endereco.bairro = resposta.bairro;
      }

      const localidade = resposta.localidade;

      const nomeCidade = localidade.split('/')[0];
      const sigla = localidade.split('/')[1];

      this.carregueEstadoECidade(nomeCidade, sigla);
    }).catch( () => {
      this.buscandoCEP = false;
    });
  }



  private carregueEstadoECidade(nomeCidade: string, sigla: string) {
    for( const estado of this.estados ) {
      if( sigla === estado.sigla ) {
        this.enderecoService.obtenhaCidades(estado).then( (cidades) => {
          this.cidades = cidades;

          for( const cidade of this.cidades ) {

            if( nomeCidade.toUpperCase() === cidade.nome.toUpperCase() ) {
              this.endereco.estado = estado;
              this.endereco.cidade = cidade;
              break;
            }
          }
        });

        break;
      }
    }
  }
  unmask(val) {
    return val ? val.replace(/\D+/g, '') : val;
  }


  onSubmit() {
    if(this.frm.valid)
      this.onSalvou.emit(this.endereco)
  }

  selecionouZona() {
    setTimeout( () => {
      if(this.selecionarBairroDaZona)
        this.endereco.bairro = this.endereco.zonaDeEntrega.nome;
    }, 0)
  }
}
