import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EntregadorService extends ServerService {

  constructor(http: HttpClient) {
    super(http);
  }

  liste(nome: any = null) {
    return this.obtenha('/entregadores/' + (nome ? "?nome=" + nome : ""), { });
  }

  salveEntregador(entregador: any) {
    return this.salve('/entregadores', entregador);
  }

  removaEntregador(entregador: any) {
    return this.remova(String(`/entregadores/${entregador.id}`) , {})
  }

  obtenhaRelatorioPeriodo(inicio: any, fim: any, entregador: any) {
    return this.obtenha('/entregadores/relatorio/periodo?inicio=' + inicio + '&fim=' + fim  +
      (entregador ? "&idEntregador=" + entregador.id : ""), {})
  }

  obtenhaRelatorioPedidos(inicio: any, fim: any, entregador: any) {
    return this.obtenha('/entregadores/relatorio/pedidos/' + entregador.id + '?inicio=' + inicio + '&fim=' + fim
      , {})
  }
}
