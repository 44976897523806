<div class=" ribbon ribbon-info float-right"
     [ngClass]="{'ribbon-info': pedido.status === 'Novo' ,
                                     'ribbon-warning': pedido.status === 'Em preparação' ,
                                     'ribbon-success': pedido.status === 'Saiu para entrega',
                                     'ribbon-blue': pedido.status === 'Pronto',
                                     'ribbon-primary': pedido.status === 'Entregue',
                                     'ribbon-danger': pedido.cancelado  ,
                                      'ribbon-secondary':   pedido.aguardandoPagamentoOnline
                                     }">
  <span>{{pedido.status}}</span>
</div>
