import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-site-campo-adicional-edicao',
  templateUrl: './site-campo-adicional-edicao.component.html',
  styleUrls: ['./site-campo-adicional-edicao.component.scss']
})
export class SiteCampoAdicionalEdicaoComponent implements OnInit {
  @Input()  campoAdicional: any;
  @Input()  itemPedido: any;
  @Input()  posicao: number;
  constructor() { }

  ngOnInit() {


  }

  exibaAdicionalDoProduto(campoAdicional: any, itemPedido: any, posicao = 0){
    this.campoAdicional = campoAdicional;
    this.itemPedido = itemPedido;
    this.posicao = posicao;
    this.itemPedido['lista' + this.posicao] = {}
    this.itemPedido['lista' + this.posicao].totalSelecionado = 0
    let i = 0

    for(let opcao of this.campoAdicional.opcoesDisponiveis) {
      this.itemPedido['lista' + this.posicao]['opcao_' + i] = {
        selecionada: false
      }

      this.itemPedido['lista' + this.posicao]['opcao_' + i].qtde = 0
      i++;
    }
  }

  diminuirQtde(opcao: any, indice) {
    let itemPedidoOpcoes = this.itemPedido['lista' + this.posicao]
    let itemPedidoOpcao = itemPedidoOpcoes['opcao_' + indice]

    if(itemPedidoOpcao.qtde === 0) return;

    if(itemPedidoOpcao.qtde === 1) itemPedidoOpcao.selecionada = false;

    itemPedidoOpcao.qtde--
    itemPedidoOpcoes.totalSelecionado--
  }

  aumentarQtde(opcao: any, indice) {
    let itemPedidoOpcoes = this.itemPedido['lista' + this.posicao]

    if(itemPedidoOpcoes.totalSelecionado == this.campoAdicional.qtdMaxima)
      return;


    let itemPedidoOpcao = itemPedidoOpcoes['opcao_' + indice]

    if(itemPedidoOpcao.qtde === 0) itemPedidoOpcao.selecionada = true;

    itemPedidoOpcao.qtde++
    itemPedidoOpcoes.totalSelecionado++

  }

  vaiMudarSelecao(event: any, opcao: any, indice: any) {
    let itemPedidoOpcao = this.itemPedido['lista' + this.posicao]['opcao_' + indice]
    let itemPedidoOpcoes = this.itemPedido['lista' + this.posicao]

    let contagemAtual = itemPedidoOpcoes.totalSelecionado
    let vaiSelecionar = !itemPedidoOpcao.selecionada

    if(vaiSelecionar && contagemAtual === this.campoAdicional.qtdMaxima) event.preventDefault();
  }

  mudouSelecao(selecionou: any, opcao: any) {
    let itemPedidoOpcoes = this.itemPedido['lista' + this.posicao]
    if(selecionou) itemPedidoOpcoes.totalSelecionado++
    else itemPedidoOpcoes.totalSelecionado--
  }
}

