import {EnumDisponibilidadeProduto} from "../../lib/emun/EnumDisponibilidadeProduto";
import {Produto} from "../Produto";
import {Empresa} from "../Empresa";

export class ProdutoNaEmpresa {
  id: number;

  constructor(public produto: Produto, public empresa: any,
              public preco: number = null, public disponibilidade: EnumDisponibilidadeProduto = null,
              public temEstoque: boolean = true, public exibirPrecoSite: boolean = true,
              public exibirPrecoNoCardapio: boolean = true, public disponivelNaMesa: boolean = true,
              public disponivelParaDelivery: boolean = true, public novoPreco: number = null, public destaque: boolean = null,
              public mensagemPedido: string = null) {
  }
}

/*
        tem_estoque = #{temEstoque},
        exibir_preco_site = #{exibirPrecoSite},
        exibir_preco_no_cardapio = #{exibirPrecoNoCardapio},
        exibir_no_site = #{exibirNoSite},
        disponibilidade = #{disponibilidade},
        disponivel_na_mesa = #{disponivelNaMesa},
        disponivel_para_delivery = #{disponivelParaDelivery},
        nao_sincronizar = #{naoSincronizar},
        mensagem_pedido = #{mensagemPedido}
 */
