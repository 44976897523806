<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Cadastro de {{usuario.garcom ? "Garçom" : "Usuário"}}
  </h4>
</kendo-dialog-titlebar>
<div #pnl class="modal-body">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
    <div class="alert alert-success alert-dismissible fade show mt-2" *ngIf="mensagemSucesso" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="alert alert-danger alert-dismissible fade show mt-2" *ngIf="mensagemErro" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row">
       <div class="col-7" [ngClass]="{'col-12': !cadastrarCodigoPdv()}">
         <div class="form-group mb-3">
           <label for="nome">Nome do {{usuario.garcom ? "Garçom" : "Usuário"}}</label>
           <input type="text" class="form-control" autocomplete="off"
                  id="nome" name="nome" [(ngModel)]="usuario.nome" #nome="ngModel"
                  placeholder="Nome do usuário" value="" required appAutoFocus [autoFocus]="true">
           <div class="invalid-feedback">
             <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
           </div>
         </div>
       </div>
       <div class="col-5" *ngIf="cadastrarCodigoPdv()">
         <div class="form-group mb-3">
           <label  >Codigo no PDV <i class="k-icon k-i-loading ml-2" *ngIf="carregandoNoPdv"></i></label>

           <kendo-combobox name="operadorPdv" #operadorPdv="ngModel" [data]="operadoresPdv" style="width: 140px; display: inline-block;"
                           class="form-control"     [allowCustom]="false"  [clearButton]="false"
                           [(ngModel)]="usuario.operadorPdv" [valueField]="'cod_fun'"   [textField]="'nome_login'">
           </kendo-combobox>
         </div>

       </div>
    </div>



    <ng-container *ngIf="!cadastroSemLogin">
      <div class="form-group mb-3">
        <label for="email">E-mail do {{usuario.garcom ? "Garçom" : "Usuário"}}</label>
        <input type="email" class="form-control" autocomplete="off"
               id="email" name="email" [(ngModel)]="usuario.email" #email="ngModel"
               placeholder="Email do usuário" value="" required  >
        <div class="invalid-feedback">
          <p *ngIf="email.errors?.required">Email é obrigatório</p>
        </div>
      </div>
      <ng-container *ngIf="usuario.garcom && !usuario.id">
        <div class="form-group mb-3" >
          <label for="senha">Senha</label>
          <input type="text"  class="form-control" id="senha"
                 [(ngModel)]="usuario.senha" #senha="ngModel"
                 placeholder="Informe sua senha" required>
          <div class="invalid-feedback">
            <p *ngIf="senha.errors?.required">Email é obrigatório</p>
          </div>
        </div>

      </ng-container>


      <div class="form-group mb-3">
        <label for="cpf">Cpf</label>

        <kendo-maskedtextbox class="form-control" autocomplete="off" cpfValido mask="000.000.000-00"
                             id="cpf" name="cpf" [(ngModel)]="usuario.cpf" #cpf="ngModel"
                             placeholder="Cpf do usuário"       ></kendo-maskedtextbox>
        <div class="invalid-feedback">
          <p *ngIf="cpf.errors?.required">CPF é obrigatório</p>
          <p *ngIf="cpf.errors?.cpfInvalido">CPF é invalido</p>
        </div>
      </div>

      <div class="form-group mb-3" *ngIf="!usuario.garcom">
        <label for="email">Whatsapp do {{usuario.garcom ? "Garçom" : "Usuário"}}</label>
        <kendo-combobox id="numeroWhatsapp" name="numeroWhatsapp" [data]="numerosWhatsapp" placeholder="Número Whatsapp"
                        class="form-control"  [allowCustom]="false"
                        [required]="true"
                        #numeroWhatsapp="ngModel" [(ngModel)]="usuario.numeroWhatsapp" [textField]="'whatsapp'" [valueField]="'id'">
        </kendo-combobox>
        <div class="invalid-feedback">
          <p *ngIf="numeroWhatsapp.errors?.required">Número Whatsapp é obrigatório</p>
        </div>
      </div>
    </ng-container>

    <div class="row">

      <div class="col-5">
        <div class="form-group mb-3">
          <label >Ativo </label><br>
          <kendo-switch name="ativo" [(ngModel)]="usuario.ativo"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>

      </div>

      <div class="col-7" *ngIf="usuario.garcom && cadastroSemLogin">
        <div class="form-group mb-3">
          <label >Garçom padrão de todas comandas  </label><br>
          <kendo-switch name="garcomPadrao" [(ngModel)]="usuario.garcomPadrao"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>
      </div>
    </div>


    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light"  >Salvar</button>
      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="fecheModal()">Fechar</button>
    </div>

  </form>
</div>
