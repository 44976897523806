import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {NumeroWhatsapp} from "../pedidos/objetos/NumeroWhatsapp";
import {Mesa} from "../pedidos/objetos/Mesa";
import {FormaDePagamento} from "../pedidos/objetos/FormaDePagamento";

@Injectable({
  providedIn: 'root'
})
export class MesaService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  salveMesa(mesa: Mesa) {
    return this.salve('/mesas', mesa);
  }

  removaMesa(objetoApagar: Mesa) {
    return this.remova( "/mesas/" + objetoApagar.id, {})
  }

  obtenhaMesas(empresa: any) {
    return this.obtenha('/mesas/' + empresa.id, {});
  }

  obtenhaMesasIntegradas() {
    return this.obtenha('/mesas/me/integradas'  , {});
  }
}
