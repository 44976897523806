import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CampanhaService} from "../../services/campanha.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-exibir-campanha',
  templateUrl: './exibir-campanha.component.html',
  styleUrls: ['./exibir-campanha.component.scss']
})
export class ExibirCampanhaComponent implements OnInit {
  @Input() campanha: any = {};
  @Input() modoAprovacao: boolean;

  mensagem = '';
  mensagemWarning = '';
  aceitandoCampanhaRede = false;
  usuarioLogado: any;

  constructor(private router: Router, private campanhaService: CampanhaService,
              private autorizacaoService: AutorizacaoService) {
    this.autorizacaoService.usuarioLogado$.subscribe( (usuario) => {
      if( !usuario ) return;

      this.usuarioLogado = usuario;
    });
  }

  ngOnInit(): void {
  }

  editarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/nova-campanha/' + campanha.id, {}).then( () => {});
  }

  irParaTelaTestarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/tela-enviar-mensagens/' + campanha.id, {state: {data: campanha, testar: true }}).
    then( () => {});
  }

  irParaTelaEnviarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/tela-enviar-mensagens/' + campanha.id, {state: {data: campanha }}).
      then( () => {});
  }

  verDetalhesCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/campanha/' + campanha.id + '/detalhes' , {state: {data: campanha }}).
      then( () => {});
  }

  testarCampanha(campanha: any) {
    this.router.navigateByUrl( `/admin/tela-enviar-mensagens/${campanha.id}` , {state: {data: campanha }}).
    then( () => {});
  }

  aprovarCampanha(campanha: any) {
    this.mensagem = '';
    this.mensagemWarning = '';

    this.campanhaService.aprove(campanha).then( (resposta) => {
      this.mensagem = resposta.msg;
      campanha.statusAprovacao = resposta.status;
    });
  }

  reprovarCampanha(campanha: any) {
    this.mensagem = '';
    this.mensagemWarning = '';

    this.campanhaService.reprove(campanha).then( (resposta) => {
      this.mensagemWarning = resposta.msg;
      campanha.statusAprovacao = resposta.status;
    });
  }

  aceitarCampanhaRede(campanha: any) {
    this.mensagem = '';
    this.mensagemWarning = '';
    this.aceitandoCampanhaRede = true;

    this.campanhaService.aceitarCampanhaRede(campanha).then( (resposta) => {
      this.aceitandoCampanhaRede = false;
      this.mensagem = resposta.msg;
      this.campanha.foiAceita = true;
      this.campanha.status = 'Nova';
      campanha.statusAprovacao = resposta.status;
    });
  }

  rejeitarCampanhaRede(campanha: any) {
    this.mensagem = '';
    this.mensagemWarning = '';
    this.aceitandoCampanhaRede = true;

    this.campanhaService.rejeitarCampanhaRede(campanha).then( (resposta) => {
      this.aceitandoCampanhaRede = false;

      this.mensagem = resposta.msg;
      this.campanha.foiAceita = false;
      this.campanha.status = 'Rejeitada';
      campanha.statusAprovacao = resposta.status;
    });
  }
}
