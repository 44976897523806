import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) { return ''; }

    return this.telefone(value);
  }

  telefone(v: string){
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2-");

    if (v.length < 14) {
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    } else {
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v
  }

}
