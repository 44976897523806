import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from "@progress/kendo-angular-dialog";
import {NgForm} from "@angular/forms";
import {CatalogosService} from "../../superadmin/services/catalogos.service";

@Component({
  selector: 'app-form-catalogo',
  templateUrl: './form-catalogo.component.html',
  styleUrls: ['./form-catalogo.component.scss']
})
export class FormCatalogoComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;
  catalogo: any = {};
  mensagemErro: any;
  salvando: any;
  windowRef: DialogRef;

  constructor(private catalogosService: CatalogosService) { }

  ngOnInit(): void {
  }

  onSalvar() {
    if(this.frm.valid){
      this.salvando = true;
      this.catalogosService.salveCatalogo(this.catalogo).then( (resp) => {
        if(!this.catalogo.id)
          this.catalogo.id = resp.id;
        this.windowRef.close(this.catalogo);
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro = erro;
      })
    }
  }

  fechar() {
    this.windowRef.close();
  }
}
