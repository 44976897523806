<!-- json-display.component.html -->
<div>
  <span ngNonBindable>&#123;</span>
  <ul class="obj">
    <li *ngFor="let key of getObjectKeys(data)" class="json-property">
      <ng-container *ngIf="isObject(data[key])">

        <button (click)="toggleCollapse(key)" class="collapse-button">
          {{ isCollapsed(key) ? '+' : '-' }}
        </button>
        <b class="key">{{ key }}:</b>
        <div *ngIf="!isCollapsed(key)">
          <div class="nested-object ">
            <app-json-display [data]="data[key]" [level]="level + 1" [collapsedKeys]="collapsedKeys"></app-json-display>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isObject(data[key])">
        <p class=""  >
          <b class="key">{{ key }}:</b>
          <span *ngIf="isString(data[key])" style="color: green">{{ data[key] | json }}</span>
          <span *ngIf="isNumber(data[key])" style="color: blue">{{ data[key] | json }}</span>
          <span *ngIf="isBoolean(data[key])" style="color: red">{{ data[key] | json }}</span>
          <span *ngIf="isNull(data[key])" style="color: gray"> null</span>
        </p>
      </ng-container>
    </li>
  </ul>

  <span ngNonBindable>}</span>
</div>

