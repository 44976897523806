<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="salveDisponibilidade()">
  <div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel">
       Disponibilidade
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div   class="modal-body">

      <div class="row">
        <div class="form-group col">
          <label for="dataInicio">Data Inicio</label>

          <div class="input-group">

            <kendo-datepicker [(ngModel)]="disponibilidade.dataInicio" id="dataInicio"    name="dataInicio" [format]="'dd/MM/yyyy'"
                              class="form-control   k-display-inline-block"    >
              <kendo-datepicker-messages
                today="Hoje"
                toggle="Alterar data"
              ></kendo-datepicker-messages>

            </kendo-datepicker>

          </div>
        </div>

        <div class="form-group col">
          <label for="dataInicio">Data Fim</label>

          <div class="input-group">
            <kendo-datepicker [(ngModel)]="disponibilidade.dataFim" id="dataFim"    name="dataFim" [format]="'dd/MM/yyyy'"
                              class="form-control   k-display-inline-block"    >
              <kendo-datepicker-messages
                today="Hoje"
                toggle="Alterar data"
              ></kendo-datepicker-messages>

            </kendo-datepicker>

          </div>
        </div>
      </div>

      <div  class="card card-body" *ngFor="let periodo of disponibilidade.periodos; let i = index">
        <label> {{i + 1}}° Periodo  </label>
        <label>Dias da semana</label>
        <span class="text-muted">Selecione os dias da semana</span>
        <p class="k-block">
          <button type="button" class="mr-1"  kendoButton (selectedChange)="onSelectedChange(ds, $event)"
                  *ngFor="let ds of periodo.dias"
                  [look]="'outline'"     [toggleable]="true" [selected]="ds.disponivel"  >{{ds.label}}</button>


        </p>
        <span class="text-danger mb-2" *ngIf="periodo.selecionarDiasSemana">
         É obrigatório informar um ou mais dias da semana</span>
        <label>Horário</label>
        <span class="text-muted">Opcionalmente um horario para essa disponibilidade</span>
        <div class="row">
          <div class="form-group  col" >
            <kendo-timepicker #trInicio="ngModel" [(ngModel)]="periodo.horaInicio" name="horaInicio{{i}}"
                              [required]="false"   class="form-control"
                              [min]="horarioMinimoInicio()" [max]="trFim.valid ? periodo.horaFim : null">

            </kendo-timepicker>

            <div class="invalid-feedback"  >
              <p *ngIf="trInicio.errors?.timeRangeError">Hora início inválida</p>
              <p *ngIf="trInicio.errors?.required">Informe hora início</p>
            </div>
          </div>

          <div class="form-group  col"  >
            <kendo-timepicker #trFim="ngModel" [(ngModel)]="periodo.horaFim" name="horaFim{{i}}"
                              [min]="periodo.horaInicio" [required]="false" class="form-control">

            </kendo-timepicker >

            <div class="invalid-feedback"  >
              <p *ngIf="trFim.errors?.timeRangeError">Hora fim inválido</p>
              <p *ngIf="trFim.errors?.required">Informe hora fim</p>
            </div>
          </div>
        </div>

        <button class="   btn btn-sm mb-1 btn-danger" (click)="removaPeriodo(periodo, i)" *ngIf="i > 0">
          <i class="fe-trash"></i>  Remover
        </button>
      </div>

      <button class="   btn btn-sm mb-1 btn-blue" (click)="novoPeriodo()">
        <i class="fe-plus "></i>  Novo periodo
      </button>

    <p *ngIf="mensagemSucesso" class="text-success ">
      <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
    </p>

    <p  *ngIf="mensagemErro" class="text-danger ">
      <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
    </p>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light"  [disabled]="salvando" >
      Salvar</button>

    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
            (click)="fecheModal()">
      Fechar</button>
  </div>
</div>
</form>
