import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {FotoService} from "../../services/foto.service";
import {CadFotoComponent} from "../cad-foto/cad-foto.component";
import {UploadImagemComponent} from "../upload-imagem/upload-imagem.component";
import {UploadCropImagemComponent} from "../upload-crop-imagem/upload-crop-imagem.component";
import {Router} from "@angular/router";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html',
  styleUrls: ['./fotos.component.scss']
})
export class FotosComponent implements OnInit {
  @Input() empresa: any;
  @ViewChild('capaUpload') public capaUpload: UploadCropImagemComponent;
  @ViewChild('logoUpload') public logoUpload: UploadImagemComponent;
  paginacao = {
    size: 10,
    skip: 0,
    total: 0,
    buttonCount: 5,
    info: true,
    type: "numeric"  ,
    pageSizes: false,
    previousNext: true
  }
  salvarCapa = false;
  salvarLogo = false;
  loading = false;
  fotos: any = []
  abraDialogo: boolean;
  fotoSelecionada: any;
  excluindoFoto: boolean;
  mensagemRemocao: string;
  private removeu: boolean;
  @Input() fotosDoAmbiente: any = true;

  constructor(private router: Router, private fotoService: FotoService,
              private dialogService: DialogService) {
  }

  ngOnInit() {
    if(this.empresa.id && this.fotosDoAmbiente) {
      this.fotoService.liste(this.empresa.id).then(  (fotos) => {
        this.fotos = fotos;
      })
    }

  }
  novaFoto() {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadFotoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result: any) => {
        if(result && result.id){
          this.fotos.push(result)
          this.paginacao.size = this.fotos.length;
        }
      });
  }

  editarFoto({ sender, rowIndex, dataItem }) {
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadFotoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;

    windowRef.content.instance.setFoto(dataItem);
  }

  mudouPaginacao({$event}: { $event: PageChangeEvent }) {

  }

  salveCapaEmpresa($event) {
    this.fotoService.salveCapaEmpresa(this.empresa).then( () => {
      $event.salvou();
    }).catch( err => {
      $event.erro(err)
    })
  }

  salveLogoEmpresa($event) {
    this.fotoService.salveLogoEmpresa(this.empresa).then( () => {
      $event.salvou();
    }).catch( err => {
      $event.erro(err)
    })
  }

  abraDialogRemoverfoto($event: RemoveEvent) {
    this.fotoSelecionada = $event.dataItem;
    this.abraDialogo = true;

  }

  fechar() {
    this.abraDialogo = false;
    this.mensagemRemocao = null;
    this.fotoSelecionada = null;
    this.excluindoFoto = false;

  }

  removaFoto() {
    this.excluindoFoto = true;

    this.fotoService.removaProduto(this.fotoSelecionada).then((resposta) => {

      this.fotoService.liste(this.empresa.id).then( (fotos: any) => {
        this.removeu = true;
        this.excluindoFoto = false;
        this.mensagemRemocao = "A foto '" + this.fotoSelecionada.titulo + "' foi removida ";
        this.fotoSelecionada = null;
        this.fotos =  fotos;
        this.paginacao.size = this.fotos.length;
      })

    }).catch((reason) => {
      this.excluindoFoto = false;
      this.fotoSelecionada = null;
      this.mensagemRemocao = reason;
    })
  }

  gerenciarBanners() {
    this.router.navigateByUrl('/admin/banner').then( () => {});
  }
}
