<div class="row">
  <div class="col-12 col-sm-6  col-md-5"  *ngIf="!empresa.integracaoFidelidade?.id">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title"> Pontuação Automática  </h3>

        <ng-container *ngIf="!empresa.integracaoPedidoFidelidade">
          <ng-container *ngIf="!usuario.admin">
            <h4 class="text-warning">
              <i class="k-icon k-i-warning mr-1"></i> Nenhuma integração ativa</h4>
          </ng-container>

          <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                novalidate #frm="ngForm" (ngSubmit)="onSubmit()" *ngIf="usuario.admin">

            <div class="form-group mt-2">
              <label class="mb-1">Plano integrar:</label>

              <kendo-combobox name="planoIntegrado" class="form-control" [data]="planos" textField="nome"
                              [(ngModel)]="planoIntegrado" required >

              </kendo-combobox>

              <div class="invalid-feedback">
                <p>Plano é obrigatório</p>
              </div>

            </div>

            <ng-container *ngIf="!planoIntegrarVariavelPorAtividade()">

              <div class="form-group mb-3" *ngIf="planoIntegrado && (planoIntegrado.tipoDePontuacao.tipo  === 'qtde-fixa-por-atividade')">
                <label for="selosPorAtividade">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} ganhos por Pedido</label>
                <kendo-numerictextbox id="selosPorAtividade" [min]="1" [max]="100"  required
                                      class="form-control" name="selosPorAtividade" #selosPorAtividade=ngModel [(ngModel)]="planoIntegrado.selosPorAtividade">
                </kendo-numerictextbox>

                <div class="invalid-feedback">
                  <p *ngIf="selosPorAtividade.errors?.required">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} é obrigatório</p>
                  <p *ngIf="selosPorAtividade.errors?.minError">Valor mínimo é  {{selosPorAtividade.errors?.minError?.minValue}}</p>
                  <p *ngIf="selosPorAtividade.errors?.maxError">Valor máximo é  {{selosPorAtividade.errors?.maxError?.maxValue}}</p>
                </div>
              </div>


              <div class="form-group mb-3" *ngIf="planoIntegrarAcumulaReais()">
                <label for="cashback">Cashback</label>
                <kendo-numerictextbox   id="cashback" name="cashback" class="form-control" autocomplete="off"  placeholder="Retorno do cashback"
                                        [format]="obtenhaFormatoPercentual()"  [step]="1"  [min]="1"  [max]="100"
                                        [(ngModel)]="planoIntegrado.cashback" #cashback="ngModel"       >

                </kendo-numerictextbox>
                <div class="invalid-feedback">
                  <p *ngIf="cashback.errors?.required">Cashback é obrigatório</p>
                  <p *ngIf="cashback.errors?.maxError">Valor  máximo é {{cashback.errors?.maxError?.maxValue}} </p>
                  <p *ngIf="cashback.errors?.minError">Valor  mínimo é {{cashback.errors?.minError?.maxValue}} </p>
                </div>
              </div>

            </ng-container>

            <ng-container *ngIf="planoIntegrarVariavelPorAtividade()">
              <div class="form-group mb-3" *ngIf="planoIntegrarAcumulaReais()">
                <label for="cashbackPadrao">Cashback padrão dos produtos</label>
                <kendo-numerictextbox   id="cashbackPadrao" name="cashbackPadrao" class="form-control" autocomplete="off"
                                        placeholder="Retorno do cashback"
                                        [format]="obtenhaFormatoPercentual()"  [step]="0"  [min]="1"  [max]="100"
                                        [(ngModel)]="planoIntegrado.cashback" #cashbackPadrao="ngModel"       >

                </kendo-numerictextbox>
                <div class="invalid-feedback">
                  <p *ngIf="cashbackPadrao.errors?.required">Cashback é obrigatório</p>
                  <p *ngIf="cashbackPadrao.errors?.maxError">Valor  máximo é {{cashbackPadrao.errors?.maxError?.maxValue}} </p>
                  <p *ngIf="cashbackPadrao.errors?.minError">Valor  mínimo é {{cashbackPadrao.errors?.minError?.maxValue}} </p>
                </div>
              </div>

              <div class="form-group mb-3" *ngIf="!planoIntegrarAcumulaReais()">
                <label for="pontuacaoPadrao">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} padrão dos produtos</label>
                <kendo-numerictextbox id="pontuacaoPadrao" [min]="0" [max]="100"  required
                                      class="form-control" name="pontuacaoPadrao" #pontuacaoPadrao=ngModel [(ngModel)]="planoIntegrado.selosPorAtividade">
                </kendo-numerictextbox>

                <div class="invalid-feedback">
                  <p *ngIf="pontuacaoPadrao.errors?.required">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} é obrigatório</p>
                  <p *ngIf="pontuacaoPadrao.errors?.minError">Valor mínimo é  {{pontuacaoPadrao.errors?.minError?.minValue}}</p>
                  <p *ngIf="pontuacaoPadrao.errors?.maxError">Valor máximo é  {{pontuacaoPadrao.errors?.maxError?.maxValue}}</p>
                </div>
              </div>

            </ng-container>


            <div class="form-group mt-2  " *ngIf="planoIntegrado">
              <div class="input-group mt-2">
                <input  name="pontuarSoLoja"  id="pontuarSoLoja"   class="k-checkbox  " type="checkbox"
                        [(ngModel)]="planoIntegrado.pontuarSoLoja" />
                <label  class="k-checkbox-label" for="pontuarSoLoja">Pontuar somente pedidos feitos na loja</label>
              </div>
            </div>

            <div class="mt-2 mb-2">
              <div class="alert alert-success alert-dismissible fade show mb-2" role="alert" *ngIf="mensagemSucesso">
                <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              
              <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="mensagemErro">
                <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <button type="submit" class="btn btn-primary waves-effect waves-light width-lg" [disabled]="ativando">
              <i class="k-icon k-i-loading mr-1" *ngIf="ativando"></i>
              Ativar</button>


          </form>
        </ng-container>

        <div *ngIf="empresa.integracaoPedidoFidelidade" class="text-center">

          <img src="/assets/icons/icon-success.png" height="100px" >

          <h4> {{empresa.integracaoPedidoFidelidade.plano.nome}}</h4>

          <ng-container *ngIf="!empresa.integracaoPedidoFidelidade.editar">
            <ng-container *ngIf="!pontuacaoVariavelPorAtividade()">
              <h4 *ngIf="empresa.integracaoPedidoFidelidade.atividade.cashback" >
                <span class="text-success">
                    Cashback: <b> {{ empresa.integracaoPedidoFidelidade.atividade.cashbackFormatado }}</b>
                </span>

                <i class="fa fa-edit fa-lg ml-2 " title="editar" kendoTooltip (click)="editarCasback()"></i>
              </h4>

              <h4 *ngIf="empresa.integracaoPedidoFidelidade.atividade.pontosGanhos > 0" >
                <span class="text-success">
                   {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}} por pedido:
                  <b> {{empresa.integracaoPedidoFidelidade.atividade.pontosGanhos}}</b>
               </span>

                <i class="fa fa-edit fa-lg ml-2 " title="editar" kendoTooltip (click)="editarValorAtividade()"></i>
              </h4>
            </ng-container>
            <ng-container *ngIf="pontuacaoVariavelPorAtividade()">
              <h4 *ngIf="acumulaReais()" >
                Cashback Padrão dos produtos:
                <span class="text-success">
                    <b> {{ empresa.integracaoPedidoFidelidade.atividade.cashbackFormatado }}</b>
                </span>

                <i class="fa fa-edit fa-lg ml-2 " title="editar" kendoTooltip (click)="editarCasback()"></i>
              </h4>
              <h4 *ngIf="!acumulaReais()" >
                Pontuação Padrão dos produtos:

                  <span class="text-success">
                    <b> {{empresa.integracaoPedidoFidelidade.atividade.pontosGanhos}}</b>  {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}}
                  </span>

                  <i class="fa fa-edit fa-lg ml-2 " title="editar" kendoTooltip (click)="editarValorAtividade()"></i>
              </h4>
            </ng-container>


            <div class="form-group mb-0">
              <label>Pontuar <b>delivery/retirada</b> somente pedidos realizados na catalogo online</label>
              <div class="mt-0 font-24 valor d-inline-block ml-1">
                <kendo-switch name="pontuarSoLoja" [(ngModel)]="empresa.integracaoPedidoFidelidade.pontuarSoLoja"
                              [onLabel]="'Sim'" (ngModelChange)="alterouPontuarLoja($event)"
                              [offLabel]="'Não'"></kendo-switch>
              </div>

            </div>

            <div class="form-group">
              <label>Pontuar<b> mesas/comandas</b></label>
              <div class="mt-0 mb-1 font-24 valor d-inline-block ml-1">
                <kendo-switch name="pontuarSoLoja" [(ngModel)]="empresa.integracaoPedidoFidelidade.pontuarMesas"
                              [onLabel]="'Sim'" (ngModelChange)="alterouPontuarMesas($event)"
                              [offLabel]="'Não'"></kendo-switch>
              </div>

            </div>

            <p>Integração com loja ativa desde de {{empresa.integracaoPedidoFidelidade.data | date: 'dd/MM/yyyy'}}</p>

            <p class="text-danger" *ngIf="!empresa.integracaoPedidoFidelidade.plano.ativo">
                 <b> O Plano "{{empresa.integracaoPedidoFidelidade.plano.nome}}" esta desativado</b>
            </p>

            <button class="btn btn-danger" (click)="confirmeDesativar()"  [hidden]="confirmarDesativar" *ngIf="usuario.admin">
              Desativar</button>
          </ng-container>

          <ng-container *ngIf="empresa.integracaoPedidoFidelidade.editar">
            <ng-container *ngIf="novoCashback != null">
              <div class="form-group mb-3 text-left"  >
                <label  >Cashback</label>
                <kendo-numerictextbox   name="cashback" class="form-control" autocomplete="off"  placeholder="Retorno do cashback"
                                        [format]="obtenhaFormatoPercentual()" [step]="1"  [min]="1"  [max]="100"
                                        [(ngModel)]="novoCashback" #cashback="ngModel"       >

                </kendo-numerictextbox>
                <div class="invalid-feedback">
                  <p *ngIf="cashback.errors?.required">Cashback é obrigatório</p>
                  <p *ngIf="cashback.errors?.maxError">Valor  máximo é {{cashback.errors?.maxError?.maxValue}} </p>
                  <p *ngIf="cashback.errors?.minError">Valor  mínimo é {{cashback.errors?.minError?.maxValue}} </p>
                </div>
              </div>

              <div class="mt-1">
                <button class="btn btn-success mr-1"  (click)="atualizeValorCashback()" [disabled]="alterando">
                  <i class="k-i-loading k-icon" *ngIf="alterando"></i>  Alterar</button>
                <button class="btn btn-light" (click)="canceleEditarCashback()">Cancelar</button>
              </div>

            </ng-container>
            <ng-container *ngIf="novoValorAtividade != null">
              <div class="form-group mb-3 text-left"  >
                <label for="selosPorAtividade">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} ganhos
                 {{pontuacaoVariavelPorAtividade()? ' padrão por produto': ' por Pedido'}}
                </label>
                <kendo-numerictextbox   [min]="1" [max]="100"  required
                                        class="form-control" name="selosPorAtividade" #selosPorAtividade=ngModel [(ngModel)]="novoValorAtividade">
                </kendo-numerictextbox>

                <div class="invalid-feedback">
                  <p *ngIf="selosPorAtividade.errors?.required">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} é obrigatório</p>
                  <p *ngIf="selosPorAtividade.errors?.minError">Valor mínimo é  {{selosPorAtividade.errors?.minError?.minValue}}</p>
                  <p *ngIf="selosPorAtividade.errors?.maxError">Valor máximo é  {{selosPorAtividade.errors?.maxError?.maxValue}}</p>
                </div>
              </div>
              <div class="mt-1">
                <button class="btn btn-success mr-1"  (click)="atualizeValorAtividadeIntegrada()" [disabled]="alterando">
                  <i class="k-i-loading k-icon" *ngIf="alterando"></i>  Alterar</button>
                <button class="btn btn-light" (click)="canceleEditarCashback()">Cancelar</button>
              </div>
            </ng-container>


          </ng-container>

          <h5 class="mt-2 " *ngIf="confirmarDesativar">
            Confirma a desativação da integração  com modulo de fidelidade?

            <div class="mt-1">
              <button class="btn btn-success mr-1"  (click)="desativarIntegracaoFidelidade()" [disabled]="desativando">Sim</button>
              <button class="btn btn-light" (click)="canceleDesativar()">Cancelar</button>
            </div>

          </h5>
        </div>
      </div>
    </div>

  </div>

  <div class="col-12 col-sm-4"  *ngIf="atividadeManual">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title"> Pontuação Manual  </h3>

        <div class="text-center">
          <img src="/assets/icons/icon-success.png" height="100px" >


          <h4> {{empresa.integracaoPedidoFidelidade.plano.nome}}</h4>

          <ng-container *ngIf="!atividadeManual.editar">

            <h4 *ngIf="atividadeManual.cashback != null" >
              <span class="text-success">
                  Cashback: <b> {{atividadeManual.cashbackFormatado }}  </b>
              </span>

                <i class="fa fa-edit fa-lg ml-2 " title="editar" kendoTooltip (click)="editarCasbackManual()"></i>
            </h4>

            <h4 *ngIf="atividadeManual.pontosGanhos > 0" >
              <span class="text-success">
                 {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}} por pedido:
                <b> {{atividadeManual.pontosGanhos}}</b>
             </span>

              <i class="fa fa-edit fa-lg ml-2 " title="editar" kendoTooltip (click)="editarValorAtividade()"></i>
            </h4>

            <p>Atividade: <b>{{atividadeManual.nome}}</b></p>

          </ng-container>

          <ng-container *ngIf="atividadeManual.editar">
            <ng-container *ngIf="novoCashbackManual != null">
              <div class="form-group mb-3 text-left"  >
                <label  >Cashback</label>
                <kendo-numerictextbox   name="cashbackManual" class="form-control" autocomplete="off"  placeholder="Retorno do cashback"
                                        [format]="obtenhaFormatoPercentual()" [step]="1"  [min]="1"  [max]="100"
                                        [(ngModel)]="novoCashbackManual" #cashbackManual="ngModel"       >

                </kendo-numerictextbox>
                <div class="invalid-feedback">
                  <p *ngIf="cashbackManual.errors?.required">Cashback é obrigatório</p>
                  <p *ngIf="cashbackManual.errors?.maxError">Valor  máximo é {{cashbackManual.errors?.maxError?.maxValue}} </p>
                  <p *ngIf="cashbackManual.errors?.minError">Valor  mínimo é {{cashbackManual.errors?.minError?.maxValue}} </p>
                </div>
              </div>

              <div class="mt-1">
                <button class="btn btn-success mr-1"  (click)="atualizeValorCashbackManual()" [disabled]="alterandoManual">
                  <i class="k-i-loading k-icon" *ngIf="alterandoManual"></i>  Alterar</button>
                <button class="btn btn-light" (click)="canceleEditarManual()">Cancelar</button>
              </div>

            </ng-container>
            <ng-container *ngIf="novoValorAtividade != null">
              <div class="form-group mb-3 text-left"  >
                <label for="selosPorAtividade">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} ganhos por Pedido</label>
                <kendo-numerictextbox   [min]="1" [max]="100"  required
                                        class="form-control" name="selosPorAtividade" #selosPorAtividade=ngModel [(ngModel)]="novoValorAtividade">
                </kendo-numerictextbox>

                <div class="invalid-feedback">
                  <p *ngIf="selosPorAtividade.errors?.required">Quantidade de {{planoIntegrado?.tipoDeAcumulo}} é obrigatório</p>
                  <p *ngIf="selosPorAtividade.errors?.minError">Valor mínimo é  {{selosPorAtividade.errors?.minError?.minValue}}</p>
                  <p *ngIf="selosPorAtividade.errors?.maxError">Valor máximo é  {{selosPorAtividade.errors?.maxError?.maxValue}}</p>
                </div>
              </div>
              <div class="mt-1">
                <button class="btn btn-success mr-1"  (click)="atualizeValorAtividadeManual()" [disabled]="alterandoManual">
                  <i class="k-i-loading k-icon" *ngIf="alterandoManual"></i>  Alterar</button>
                <button class="btn btn-light" (click)="canceleEditarManual()">Cancelar</button>
              </div>
            </ng-container>

          </ng-container>



        </div>
      </div>
    </div>

  </div>

  <div class="col-12 col-sm-6  col-md-5" *ngIf="integrarComGcom() || empresa.integracaoFidelidade?.id ">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title"> Fidelidade <b>Gcom</b>   </h3>


        <ng-container *ngIf="!empresa.integracaoFidelidade.id">
          <ng-container *ngIf="!usuario.admin">
            <h4 class="text-warning">
              <i class="k-icon k-i-warning mr-1"></i> Nenhuma integração ativa</h4>
          </ng-container>
          <form [ngClass]="{'needs-validation': !frmFidelidade.submitted, 'was-validated': frmFidelidade.submitted}"
                novalidate #frmFidelidade="ngForm" (ngSubmit)="onSubmitFidelidade()" *ngIf="usuario.admin">
            <div class="form-group mt-2">
              <label>Id da Loja</label>
              <input kendoTextBox   name="idEcommerce"  placeholder="id da loja"
                     class="form-control"   #idLoja="ngModel"
                     [(ngModel)]="empresa.integracaoFidelidade.idLoja" required/>

              <div class="invalid-feedback">
                <p  >Id Loja é obrigatório</p>
              </div>
            </div>

            <div class="alert alert-success alert-dismissible fade show mb-2" role="alert" *ngIf="mensagemSucesso">
              <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="mensagemErro">
              <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <button type="submit" class="btn btn-primary waves-effect waves-light width-lg" [disabled]="ativando">
              <i class="k-icon k-i-loading mr-1" *ngIf="ativando"></i>
              Ativar</button>

          </form>
        </ng-container>


        <div *ngIf="empresa.integracaoFidelidade.id" class="text-center">

          <img src="/assets/icons/icon-success.png" height="100px" >

          <p>Integração com loja <b>{{empresa.integracaoFidelidade.loja}}</b>
            ativa desde de {{empresa.integracaoFidelidade.dataAtivacao | date: 'dd/MM/yyyy'}}</p>

          <button class="btn btn-danger" (click)="confirmeDesativar()"  [hidden]="confirmarDesativar" *ngIf="usuario.admin">
            Desativar</button>

          <h5 class="mt-2 " *ngIf="confirmarDesativar">
            Confirma a desativação da integração  com modulo de fidelidade?

            <div class="mt-1">
              <button class="btn btn-success mr-1"  (click)="desativarIntegracaoFidelidadeGcom()" [disabled]="desativando">Sim</button>
              <button class="btn btn-light" (click)="canceleDesativar()">Cancelar</button>
            </div>

          </h5>


        </div>

      </div>
    </div>
  </div>
</div>
