import {Component, Input, OnInit} from '@angular/core';
import {ContatosService} from "../../services/contatos.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'card-contato',
  templateUrl: './card-contato.component.html',
  styleUrls: ['./card-contato.component.scss']
})
export class CardContatoComponent implements OnInit {
  @Input() public contato: any = {};
  @Input() public exibirUltimaVisita: any;
  @Input() public exibirEmail: boolean;
  @Input() public exibirEndereco: boolean;

  constructor(private contatosService: ContatosService) { }

  ngOnInit() {
  }


  onRemoveTag(e){

    let tag = this.contato.tags.find((item: any) => item.nome === e.sender.label)

    if(tag){
      this.contatosService.removaTag(this.contato, tag).then(() => {
        const index = this.contato.tags
          .map((c) => c.nome)
          .indexOf(e.sender.label);

        this.contato.tags.splice(index, 1)

      }).catch((erro) => {
        alert(erro)
      })
    } else{
      alert('Tag invalida: ' + e.sender.nome)
    }

  }
}
