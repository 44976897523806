import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IntegracaoOpendeliveryService} from "../../services/integracaoopendelivery.service";

@Component({
  selector: 'app-cad-integracao-opendelivery',
  templateUrl: './cad-integracao-opendelivery.component.html',
  styleUrls: ['./cad-integracao-opendelivery.component.scss']
})
export class CadIntegracaoOpendeliveryComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('frm', {}) frm:  any;
  integracaoOpendelivery: any = { cliente: {}}
  salvando = false;
  processando = false;
  removendo = false;
  mensagemErro: string;
  gerandoChaves = false;
  textoCopiado: string
  constructor(private integracaoOpendeliveryService: IntegracaoOpendeliveryService) { }

  ngOnInit(): void {
    if(this.empresa.integracaoOpendelivery)
      this.integracaoOpendelivery = this.empresa.integracaoOpendelivery;
  }

  gereChaves(){
    this.gerandoChaves = true;
    this.integracaoOpendeliveryService.gereChaves().then((integracaoOpendelivery) => {
        this.integracaoOpendelivery = integracaoOpendelivery;
        this.gerandoChaves = false;
    }).catch((err) => {
       this.gerandoChaves = false;
       alert(err)
    })
  }

  onSubmit(){
    delete this.mensagemErro;

    if (this.frm.valid) {
      this.salvando = true;
      this.integracaoOpendeliveryService.salveParametrizacaoMerchant(this.integracaoOpendelivery).then((integracaoOpendelivery) => {
        this.integracaoOpendelivery = integracaoOpendelivery;
        this.salvando = false;
      }).catch((err) => {
        this.salvando = false;
        this.mensagemErro = err;
      })
    } else {
      this.mensagemErro = 'Verifique os campos obrigatorios'
    }
  }

  desativeIntegracao(){
    this.processando = true;

    this.integracaoOpendeliveryService.desativeIntegracaoComerciante(this.integracaoOpendelivery).then((integracao) => {
      this.integracaoOpendelivery.ativa = false;
      this.processando = false;
    }).catch((err) => {
      this.processando = false;
      this.mensagemErro = err;
    })

  }

  ativeIntegracao(){
    this.processando = true;

    this.integracaoOpendeliveryService.ativeIntegracaoComerciante(this.integracaoOpendelivery).then((integracao) => {
      this.integracaoOpendelivery.ativa = true;
      this.processando = false;
    }).catch((err) => {
      this.processando = false;
      this.mensagemErro = err;
    })
  }


  obtenhaBaseUrlOrders(){
    return String(`https://${location.host}/opendelivery/orders/v1`)
  }

  obtenhaBaseUrlAuth(){
    return String(`https://${location.host}/opendelivery`)
  }

  gerouChaves(){
    return this.integracaoOpendelivery.id && this.integracaoOpendelivery.cliente && this.integracaoOpendelivery.cliente.id
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.textoCopiado = val;
  }


}
