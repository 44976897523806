import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServerService} from "./ServerService";

@Injectable()
export class UsuarioService  extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  obtenhaGarcom(id: any): Promise<any> {
    return this.obtenha('/usuario/garcom/' + id, {});
  }

  salveFiltro(filtro: any): Promise<any> {
    return this.salve('/usuario/filtros' , filtro );
  }

  listeFiltros(): Promise<any>{
    return this.obtenha('/usuario/filtros' , {} );
  }

  salveAssinatura(assinar: boolean) {
    return this.facaPost('/usuario/salveAssinatura', {assinar: assinar});
  }
}
