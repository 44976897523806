<kendo-listview [data]="lista" class="lst-trechos-Prompt" [loading]="carregando">
  <ng-template kendoListViewLoaderTemplate>
    <div class="loader-container">
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
    </div>
  </ng-template>
  <ng-template kendoListViewItemTemplate let-dataItem>
    <kendo-popover #info width="400px" position="bottom">
      <ng-template kendoPopoverTitleTemplate>
        <strong>Trecho Original</strong>
      </ng-template>

      <ng-template kendoPopoverBodyTemplate>
        <span *ngIf="dataItem.trechoOriginal.tipo === 'texto'" title="Instruções" kendoTooltip class="tipo font-16">
                        <i class="fas fa-file-alt" style="color: #666;"></i>
                      </span>
                <span
                  *ngIf="dataItem.trechoOriginal.tipo === 'exemplos'"
                  title="Exemplos" class="tipo font-16"
                  kendoTooltip>
                        <i class="fas fa-question-circle"></i>
                      </span>&nbsp;
        <span [innerHTML]="dataItem.modificado ? _processeTextoParaHtml(dataItem.trechoOriginal) : ''"> </span>
      </ng-template>
    </kendo-popover>

    <div style="background: #fff;padding: 1px;margin-top: 3px;" class="item pl-1" [ngClass]="{'modificado': dataItem.escopo === 'global' && dataItem.modificado, 'texto-desativado': !dataItem.ativo, 'global': dataItem.escopo === 'global', 'personalizado': dataItem.escopo === 'empresa'}"
         showOn="none" kendoPopoverAnchor  [popover]="info"  [showOn]="dataItem.modificado ? 'hover' : 'none'">
      <div style="display: inline-block">
        <span *ngIf="dataItem.escopo === 'global'" title="Global" class="tipo font-16" style="color: #B18606;">
                <i class="fas fa-globe"></i>
              </span>
        <span
          *ngIf="dataItem.escopo === 'empresa'"
          title="Personalizado" class="tipo font-16"
          kendoTooltip style="color: #028D02;">
                <i class="fas fa-cog"></i>
        </span>
      </div>
      <div style="display: inline-block;width: 28px;height: 25px;position: absolute;" class="mr-1 text-center" [class.editavel]="permiteEdicao">
        <button type="button" class="btn btn-sm btn-primary btn-editar hoverable" *ngIf="permiteEdicao"
                style="padding: 0.16rem 0.44rem;" (click)="editarTrechoDePrompt(dataItem)">
          <i class="fas fa-edit" style="color: #626262"></i>
        </button>
        <span *ngIf="dataItem.tipo === 'texto'" title="Instruções" kendoTooltip class="tipo font-16">
                <i class="fas fa-file-alt" style="color: #666;"></i>
              </span>
        <span
          *ngIf="dataItem.tipo === 'exemplos'"
          title="Exemplos" class="tipo font-16"
          kendoTooltip>
                <i class="fas fa-question-circle"></i>
              </span>
      </div>
      <span class="font-weight-bold" style="margin-left: 30px;">[{{dataItem.intent}}]</span> -
      <span [innerHTML]="_processeTextoParaHtml(dataItem)"></span>
      <kendo-switch class="ml-1" style="font-size: 8px;" *ngIf="permiteEdicao"
                    [id]="'ativo_' + dataItem.id"
                    [(ngModel)]="dataItem.ativo"
                    [name]="'ativo' + dataItem.id"
                    (ngModelChange)="ativeDesativeTrechoDePrompt(dataItem)"
      ></kendo-switch>
    </div>
  </ng-template>
</kendo-listview>
