import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grid-transacoes-caixa',
  templateUrl: './grid-transacoes-caixa.component.html',
  styleUrls: ['./grid-transacoes-caixa.component.scss']
})
export class GridTransacoesCaixaComponent implements OnInit {
  @Input()
  caixa: any = {};

  @Input()
  resumo: any = {};

  @Input()
  editavel = false;

  agora = new Date();


  constructor() { }

  ngOnInit(): void {
  }

  getTotal(tipo: string) {
    return this.caixa.transacoes.map((t: any) => t.tipo === tipo ? t.valor : 0).reduce((a: any, b: any) => a + b, 0)
  }

  excluirTransacao(rowIndex: any) {
    //implementar
  }


}
