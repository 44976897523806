import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef, ViewChild
} from '@angular/core';
import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragMove,
  CdkDropList,
  moveItemInArray,
  transferArrayItem
} from "@angular/cdk/drag-drop";
import {Task} from "../tela-crm-leads/tela-crm-leads.component";

@Component({
  selector: 'app-controle-kanbam',
  templateUrl: './controle-kanbam.component.html',
  styleUrls: ['./controle-kanbam.component.scss']
})
export class ControleKanbamComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() cardTemplate: TemplateRef<any>;
  @Input() data: any[];
  @Input() coluna: string;
  @Input() colunas = [];
  @Input() largura = '380px';

  public itensAgrupados: { [key: string]: any[] } = {};

  @ViewChild('kanbanBoard') kanbanBoard: ElementRef;

  arrastando = false;
  colunaOrigem = '';

  @Output() cardMoved = new EventEmitter<any>();

  dropContainer: CdkDropList;

  constructor() { }

  ngOnInit(): void {
  }

  private groupByColuna() {
    if( !this.data ) {
      return;
    }

    // Inicializar o objeto de agrupamento e a lista de colunas únicas
    this.itensAgrupados = {};

    for( let coluna of this.colunas ) {
      this.itensAgrupados[coluna] = [];
    }

    this.data.forEach((item) => {
      // Pega o valor da propriedade pela qual você deseja agrupar
      const key = item[this.coluna];

      // Se a chave ainda não existir no objeto de agrupamento, adicioná-la
      if (!this.itensAgrupados[key]) {
        this.itensAgrupados[key] = [];

        // Adicionar a chave à lista de colunas únicas
      }

      // Adicionar o item ao grupo
      this.itensAgrupados[key].push(item);
    });
  }

  inProgress: any[] = [
    // ... outros itens
  ];

  done: any[] = [
    // ... outros itens
  ];

  drop(event: CdkDragDrop<Task[]>): void {
    if (event.previousContainer === event.container) {
      //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    } else {
      const item = event.previousContainer.data[event.previousIndex];

      this.cardMoved.emit({
        item: item,
        novaEtapa: event.container.id,
        etapaAnterior: event.previousContainer.id
      });

      transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  atualize() {
    this.groupByColuna();
  }

  getConnectedLists(colunas, excludeId: string): string[] {
    const c = colunas.filter(id => id !== excludeId);

    return c;
  }

  permitir = false;

  entrouNaLista($event) {
    this.permitir = true;
  }

  iniciou($event: any, coluna) {
    this.arrastando = true;
    this.dropContainer = $event.source.dropContainer;
    this.colunaOrigem = coluna;

    this.dropContainer.sortingDisabled = true;
  }

  finalizou($event: CdkDragEnd) {
    this.arrastando = false;
    this.dropContainer.sortingDisabled = false;
  }

  ngAfterViewInit(): void {
  }

  private lastScrollTime = 0;


  onDragMoved(event: CdkDragMove<any>) {
    const viewRect: ClientRect = this.kanbanBoard.nativeElement.getBoundingClientRect();
    const pointerX = event.pointerPosition.x;
    const offset = 10; // Ajuste a seu critério
    const speedFactor = 0.8; // Fator para tornar a rolagem mais lenta ou mais rápida

    // Rolar para a direita
    if (pointerX > viewRect.right - offset) {
      this.kanbanBoard.nativeElement.scrollLeft += (pointerX - viewRect.right + offset) * speedFactor;
    }

    // Rolar para a esquerda
    if (pointerX < viewRect.left + offset) {
      this.kanbanBoard.nativeElement.scrollLeft -= (viewRect.left + offset - pointerX) * speedFactor;
    }
  }
}
