import { Component, OnInit } from '@angular/core';
import {BrindeService} from "../services/brinde.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-comprovante-troca',
  templateUrl: './comprovante-troca.component.html',
  styleUrls: ['./comprovante-troca.component.scss', '../componentes/imprimir.component.scss']
})
export class ComprovanteTrocaComponent implements OnInit {
  comprovante: any = {};

  constructor(private brindeService: BrindeService,  private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let codigo = params['codigo'];
      this.brindeService.obtenhaComprovanteResgate(codigo).then( (comprovante) => {
          this.comprovante = comprovante;

        setTimeout( () => {
          window.print()
        }, 500)
      });
    })



  }
}
