<div class="paper" [hidden]="carregando" *ngIf="pedido">

  <div class="titulo" *ngIf="!resumido">
    <img class="imagem_empresa" src="https://promokit.com.br/images/empresa/{{pedido.empresa.logo}}" style="    width: 65px;"/>
    <h3 class="empresa mt-3"><b>{{pedido.empresa?.nome}}</b></h3>
    <h4>Comanda</h4>

  </div>

  <div class="dados mt-3 ">
    <div class="row">
      <div class="col-6">
        <label>Abertura</label>
        <p>{{pedido.horarioAberturaDescricao}} </p>
      </div>
      <div class="col-6">
        <label>Fechamento</label>
        <p>{{pedido.horarioFechamentoDescricao}}</p>
      </div>
      <ng-container *ngIf="!resumido && pedido.cliente">
        <div class="col-7">
          <label>Cliente</label>
          <p>{{comanda?.cliente?.nome}}</p>
        </div>

        <div class="col-5" *ngIf="pedido.cliente && comanda?.cliente?.cpf">
          <label>cpf  </label>
          <p>{{comanda?.cliente?.cpf  }}</p>
        </div>

        <div class="col-5" *ngIf="pedido.cliente">
          <label>Telefone  </label>
          <p>{{comanda?.cliente?.telefone  | tel}}</p>
        </div>

        <div class="col-12" *ngIf="pedido.horarioEntregaAgendada">
          <label>Pedido agendado para {{pedido.horarioEntregaAgendada | date: 'dd/MM/yyyy HH:mm'}}</label>
        </div>
      </ng-container>
    </div>


    <div class="row pt-2 itens">
      <div class="col-12">
        <label class="text-center" style="display: block" >
          <span *ngIf="pedido.mesa"> Mesa {{pedido.mesa.nome}} - </span>
          Detalhes Comanda

        </label>

        <div class="row">
          <div class="font-italic" [class.col-6]="!resumido"
               [class.col-9]="resumido"><label><b>Descrição</b></label></div>
          <div class="col-3 font-italic" ><label><b>Qtde.</b></label></div>
          <div class="col-3 font-italic" *ngIf="!resumido"><label><b>Total</b></label></div>
        </div>

         <ng-container *ngIf="pedido.cancelado">
           <div class="row item" *ngFor="let item of pedido.itens">
             <div [class.col-7]="!resumido"
                  [class.col-9]="resumido">{{item.descricao}}
               <span *ngIf=" item.adicionaisImprirmir?.length > 0" class="font-12"><br>
                <ng-container *ngFor="let last = last;let adicional of item.adicionaisImprirmir ">
                  {{adicional.descricao}}{{last ? '' : ', '}}<br>
                </ng-container>
              </span>

               <span *ngIf="item.observacao" class="font-11"><br>"{{item.observacao}}"</span>
             </div>
             <div class="col-2  ">{{item.descricaoQtde}}</div>
             <ng-container *ngIf="!resumido">
               <div class="col-3 " *ngIf="!item.troca">
                 <span class="float-right">{{item.total | currency: "BRL"}}</span>
               </div>
               <div class="col-3 float-right" *ngIf="item.troca">

                 <span class="float-right"> {{item.total | number}} pts</span>
               </div>
             </ng-container>
           </div>
         </ng-container>


          <ng-container *ngIf="!resumido">
            <div class="row">
              <div class="col-8">
                <label class="float-right ">Total produtos (=)</label>
              </div>

              <div class="col-4">
                <span class="float-right">{{(pedido.valor + pedido.desconto)| currency: "BRL" }}</span>
              </div>
            </div>
            <div class="row" *ngIf="pedido.desconto">
              <div class="col-8">
                <label class="float-right" *ngIf="!pedido.cupom">Descontos  (-)</label>
                <label class="float-right" *ngIf="pedido.cupom">Cupom  {{pedido.cupom}}  (-)</label>
              </div>

              <div class="col-4">
                <span class="float-right">{{pedido.desconto | currency: "BRL" }}</span>
              </div>
            </div>

            <div class="row" *ngIf="pedido.cashback">
              <div class="col-8">
                <label class="float-right">Descontos (cashback):</label>
              </div>

              <div class="col-4">
                <span class="float-right">{{pedido.cashback | currency: "BRL" }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <label class="float-right font-15"  >Total (=)</label>
              </div>
              <div class="col-4">
                <p class="float-right font-15">{{pedido.valor | currency: "BRL" }}</p>
              </div>
            </div>
            <ng-container *ngIf="pedido.cobrarTaxaServico">
              <div class="row" >
                <div class="col-8">
                  <label class="float-right">Taxa Servico:</label>
                </div>

                <div class="col-4">
                  <span class="float-right">{{pedido.taxaServico | currency: "BRL" }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <label class="float-right font-15"  >Total com taxa (=)</label>
                </div>
                <div class="col-4">
                  <p class="float-right font-15">{{pedido.totalComTaxa | currency: "BRL" }}</p>
                </div>
              </div>
            </ng-container>


            <div class="row" *ngIf="pedido.foiPagoOnline">

              <div class="col-12">

                <span class="float-right">
                   Forma de pagamento:<br>
                 <b> Pedido pago online, nao cobrar do cliente</b>
                </span>
              </div>
            </div>
            <ng-container *ngIf="pedido.status !== 'Cancelada'">
              <div class="row"  >
                <div class="col-8">
                  <label class="float-right ">Status Pagamento:</label>
                </div>
                <div class="col-4">
                  <span class="float-right">{{pedido.status === 'Aberta' ? 'A Receber' : 'Pago'}}</span>
                </div>
              </div>

              <div class="row "  *ngFor="let pagamento of pedido.pagamentos; let primeiro = first;">
                <div class="col-7" *ngIf="primeiro && pedido.pagamentos.length === 1">
                  <label class="float-right" >Pagamento em:</label>
                </div>
                <div class="col-12"  *ngIf="primeiro && pedido.pagamentos.length > 1">
                  <label class="float-right">Formas de pagamento:</label>
                </div>
                <div class="col-5" [ngClass]="{'col-12': pedido.pagamentos.length > 1}" >
              <span class="float-right">
                {{pagamento.formaDePagamento.descricao}}
                <span *ngIf="pedido.pagamentos.length > 1"> ({{pagamento.valor | currency: "BRL" }})</span>
              </span>
                </div>
                <div class="col-12"  *ngIf="pagamento.levarTroco" style="padding-top: 0;">
                  <div class="row">
                    <div class="col-8">
                      <span class="float-right">Troco para:</span>
                    </div>
                    <div class="col-4">
                      <span class="float-right">    {{pagamento.trocoPara | currency: "BRL"}}</span>
                    </div>

                    <div class="col-8">
                      <span class="float-right">Valor do troco:</span>
                    </div>
                    <div class="col-4">
                      <span class="float-right">    {{pagamento.valorTroco | currency: "BRL"}}</span>
                    </div>
                  </div>

                </div>
              </div>
            </ng-container>

          </ng-container>

        <p  *ngIf="pedido.observacoes" class="mt-3"> Observações:   "{{pedido.observacoes}}"</p>

        <p  *ngIf="pedido.pontosGanhos && !resumido" class="mt-3 float-right"> Pontuação fidelidade     {{pedido.pontosGanhosDescricao}} </p>
      </div>
    </div>

  </div>


  <div class="assinatura" *ngIf="pedido.id && !resumido">

    <div class="campo">
      <label class="text-center">Assinatura</label>
    </div>

    <span class="text-muted   ">Operador: <b>{{pedido.operador?.nome}}</b> em {{pedido.horarioDescricao}}</span>

    <Br>
    <span class="text-muted   ">Horário da impressão: {{pedido.agoraDescricao}}</span>
  </div>
  <div class="assinatura" style="margin-top: 20px;" *ngIf="pedido.id && resumido">
    <span class="text-muted   ">Horário da impressão: {{pedido.agoraDescricao}}</span>
  </div>

  <!--<button (click)="imprima()">versão texto</button>-->
</div>
