import {ApplicationRef, Component, OnInit} from '@angular/core';
import {CloudWhatsappService} from "../../services/cloud-whatsapp.service";

declare var fbq;
declare var FB;

@Component({
  selector: 'app-config-whatsapp',
  templateUrl: './config-whatsapp.component.html',
  styleUrls: ['./config-whatsapp.component.scss']
})
export class ConfigWhatsappComponent implements OnInit {
  conectado = false;
  accessToken = '';
  telefones: any;
  carregando: any = false;
  associando = false;
  dadosIntegracao: any = {
    associado: false,
    telefones: []
  };

  constructor(private appRef: ApplicationRef, private cloudWhatsapp: CloudWhatsappService) { }

  ngOnInit(): void {
    this.cloudWhatsapp.carregueDados().then( (dadosIntegracao) => {
      this.dadosIntegracao = dadosIntegracao;
      this.telefones = this.dadosIntegracao.telefones;
    });
  }


  launchWhatsAppSignup() {
    // Launch Facebook login
    FB.login((response) => {
      this.carregando = true;
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;
        //Use this token to call the debug_token API and get the shared WABA's ID
        this.accessToken = accessToken;
        this.cloudWhatsapp.obtenhaTelefones(accessToken).then( (telefones) => {
          this.carregando = false;
          this.telefones = telefones;
          this.appRef.tick();
        })
      } else {
        this.carregando = false;
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      scope: 'business_management,whatsapp_business_management,whatsapp_business_messaging'
    });
  }

  associarWhatsapp(telefone: any) {
    telefone.associando = true;

    this.appRef.tick();
    this.cloudWhatsapp.associe(this.accessToken, telefone).then( (resposta) => {
      telefone.associando = false;
      telefone.associado = true;
      this.telefones = [telefone];
      this.dadosIntegracao = {
        associado: true,
        telefones: this.telefones
      };

      this.appRef.tick();
    });
  }

  desassociarWhatsapp(telefone: any) {
    this.appRef.tick();
  }

  desconectar() {
    this.cloudWhatsapp.desassocie().then( (resposta) => {
      this.dadosIntegracao.associado = false;
      this.telefones = [];
      this.dadosIntegracao = {
        associado: false,
        telefones: []
      };

      this.appRef.tick();
    });
  }
}
