<h4 class="page-title">
<button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point"></i>
</button>
Chamadas IA
</h4>

<div style="max-width: 900px;">
    <div class="row">
        <div class="form-group col-12 col-md-12">
            <label for="dataCriacao">Mensagem:</label>
            <div class="input-group">
                <input type="text" name="mensagem" [(ngModel)]="mensagem" class="form-control p-3" placeholder="Buscar mensagens"
                (keydown.enter)="buscarMensagens()"/>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="buscarMensagens()">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group col-6 col-md-3">
            <label for="dataCriacao">Data de criação:</label>
            <kendo-datepicker #dtDataCriacao name="dataCriacao" [(ngModel)]="dataCriacao" class="form-control" kendoTimePickerClearable></kendo-datepicker>
        </div>
        <div class="form-group col-6 col-md-3">
            <label for="horarioCriacaoInicio">Horário Início:</label>
            <kendo-timepicker #tpHorarioInicio name="horarioInicio" format="HH:mm" [(ngModel)]="horarioInicio" class="form-control"></kendo-timepicker>
        </div>
        <div class="form-group col-6 col-md-3 font-12">
            <label for="horarioCriacaoFim">Horário Fim:</label>
            <kendo-timepicker #tpHorarioFim name="horarioFim" [(ngModel)]="horarioFim" class="form-control"></kendo-timepicker>
        </div>
        <div class="form-group col-6 col-md-3">
            <label>&nbsp;</label>
            <button class="btn btn-primary btn-block" (click)="buscarMensagens()">Buscar</button>
        </div>
    </div>

    <ng-container *ngIf="buscando">
      <div  class="text-center mt-5 font-18">
        <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;color: #333;line-height: 40px"></div>
        <br>
        Carregando...
      </div>
    </ng-container>
    <ng-container *ngIf="!buscando">
      <div *ngIf="!chamadasIa || chamadasIa.length === 0" class="text-center mt-5">
        <i class="fas fa-exclamation-circle fa-5x text-danger"></i>
        <p class="mt-3">Não há chamadas IA para os parâmetros informados.</p>
      </div>

      <kendo-listview [data]="chamadasIa" class="list-group">
        <ng-template kendoListViewItemTemplate let-chamadaIa>
          <div class="list-group-item" style="overflow: auto;">
            <small>
              <span>{{ chamadaIa.dataCriacao | date: 'dd/MM/yyy'}} {{chamadaIa.horarioCriacao}}</span>&nbsp;
              <strong>Total Tokens: {{chamadaIa.totalTokens}}</strong>
              <strong class="ml-1">API: {{chamadaIa.api}}</strong>
            </small>
            <div *ngIf="chamadaIa.intent"><strong>{{chamadaIa.intent}}</strong></div>
            <div class="d-flex w-100 justify-content-between">
              <div class="mb-1">
                <div [innerHTML]="chamadaIa.prompt"></div>
              </div>
            </div>
            <div class="assistant" [innerHTML]="chamadaIa.resposta"></div>
          </div>
        </ng-template>
      </kendo-listview>
    </ng-container>
</div>

