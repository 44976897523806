<div class="card-body" *ngIf="loading">
  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
</div>

<div *ngIf="empresa && !loading">
  <div class="alert alert-success" role="alert" *ngIf="msgSucesso">
    <i class="mdi mdi-check-all mr-2"></i> {{msgSucesso}}
  </div>

  <div class="">
    <div class="row">
      <div class="col">
        <button class="k-button btn btn-primary" (click)="novo()">
          <i class="fe-plus mr-1"></i>
          Nova Mesa
        </button>

        <kendo-textbox class="ml-2" name="filtro" [(ngModel)]="filtro" [showSuccessIcon]="false" style="height: 36px;width: 30em;">
          <ng-template kendoTextBoxSuffixTemplate>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton [look]="'clear'" [icon]="'search'"></button>
          </ng-template>
        </kendo-textbox>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-6" *ngFor="let mesa of objetos | filtroObjetosNome: filtro">
        <div class="alert alert-warning mt-2" *ngIf="mesa === -1">
          <h4>
            <i class="fas fa-exclamation-triangle"></i>
            Nenhuma mesa encontrada para <strong>{{filtro}}</strong>
          </h4>
        </div>

        <div class="card" *ngIf="mesa !== -1">
          <div class="card-body">
            <div>
              <h4 class="mt-0">
                <b>{{mesa.nome}}</b>
                <div style="position: absolute;top: 25px;right: 15px;">
                  <button (click)="editar(mesa)" class="k-primary k-button">
                    <i class="fa fa-edit" kendoTooltip title="editar"></i>
                  </button>
                  <button kendoTooltip title="remover" class="ml-1 k-button k-grid-remove-command"
                          (click)="abraDialogRemover(mesa)"><i class="fa fa-trash"></i></button>
                </div>
              </h4>
              <hr>
              <div class="card mb-3" style="max-width: 540px;">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img [src]="'/qrcode/mesa/mesa_' + mesa.nome" style="width: 200px;"/>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Link do cardápio da mesa {{mesa.nome}}</h5>
                      <p class="card-text">
                        <i class="fas fa-external-link-alt" style="color: #6DB31B;"></i>&nbsp;  <a [href]="empresa.linkLoja + '/mesa/' + mesa.id">{{empresa.linkLoja}}/mesa/{{mesa.id}}</a>
                      </p>
                      <p class="card-text"><a [href]="'/qrcode/mesa/mesa_' + mesa.nome" class="btn btn-primary btn-sm" download>Download</a></p>
                      <p class="card-text">Gera Comanda: <strong>{{mesa.naoGerarComanda ? 'Não': 'Sim'}}</strong></p>
                      <p class="card-text">Somente Leitura: <strong>{{mesa.somenteLeitura ? 'Sim': 'Não'}}</strong></p>
                      <p class="card-text" *ngIf="mesa.codigoPdv">
                         Cod. Pdv: <strong>{{mesa.codigoPdv}}</strong>

                        <span class="ml-2" *ngIf="mesa.mesaIntegrada?.status">
                          Status Pdv: <span class="badge "
                                            [ngClass]="{'badge-success':mesa.mesaIntegrada.status ==='Livre',
                                                        'badge-danger':mesa.mesaIntegrada.status ==='EmUso',
                                                        'badge-warning':mesa.mesaIntegrada.status ==='Fechando'}">

                          {{mesa.mesaIntegrada.status}}</span>
                        </span>

                      </p>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="objetos.length === 0">
        <div class="col-12">
          <div class="alert alert-warning">
            <h4>
              <i class="fas fa-exclamation-triangle"></i>
              Você ainda não tem mesas criadas
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja apagar a mesa <strong>{{objetoApagar.whatsapp}}</strong>?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" primary="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
