import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";


@Component({
  selector: 'app-ifoodimagem',
  template: `<img [src]="imagemURL" alt="Imagem Protegida" style="height: auto; width: 100%">`
})
export class ImageIfoodComponent {
  @Input() imagemURL: string;
}

