export enum EnumOperacaoSistema{
    GerenciarLoja = 1,
    CadastrarProduto = 2,
    CadastrarContatos = 3,
    EditarPedido = 4,
    AterarStatusPedido = 5,
    CancelarPedido = 6,
    CadastrarNotificacoes = 7,
    VisulizarVendas = 8,
    GerenciarIntegracoes = 9,
    OperarCaixa = 10,
    AlterarPrecoCardapioCompartilhado = 11,
    PontuarFidelidadeManual =  12,
    TrocarBrindesFidelidadeManual =  13
}
