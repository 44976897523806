<span class="badge badge-pill badge-danger" *ngIf="contato && contato.bloqueado"> <i class="fe-user"></i>Bloqueado</span>
<ng-container *ngIf="!contato.bloqueado">
  <span class="badge badge-pill badge-success" *ngIf="contato && contato.status === 'Ativo'"> <i class="fe-user"></i>Ativo</span>
  <span class="badge badge-pill badge-danger" *ngIf="contato && (contato.status === 'Inativo' || contato.status === 'Perdido')"> <i class="fe-user"></i>Perdido</span>
  <span class="badge badge-pill regular" *ngIf="contato && contato.status === 'Regular'"> <i class="fe-user"></i> Regular</span>
  <span class="badge badge-pill badge-warning regular" *ngIf="contato && contato.status === 'Regular Em Risco'"> <i class="fe-user"></i> Regular Em Risco</span>
  <span class="badge badge-pill badge-danger vip" *ngIf="contato && contato.status === 'VIP'"> <i class="fe-user"></i> VIP</span>
  <span class="badge badge-pill badge-danger vip" *ngIf="contato && contato.status === 'VIP Em Risco'"> <i class="fe-user"></i> VIP Em Risco</span>
  <span class="badge badge-pill badge-danger novo" *ngIf="contato && contato.status === 'Novo'"> <i class="fe-user"></i> Novo</span>
  <span class="badge badge-pill badge-danger novo_importado" *ngIf="contato && contato.status === 'Novo Importado'"> <i class="fe-user"></i> Novo Importado</span>
  <span class="badge badge-pill badge-danger recorrente" *ngIf="contato && contato.status === 'Recorrente'"> <i class="fe-user"></i> Recorrente</span>
  <span class="badge badge-pill badge-warning recorrente" *ngIf="contato && contato.status === 'Recorrente Risco'"> <i class="fe-user"></i> Recorrente Risco</span>
  <span class="badge badge-pill badge-warning" *ngIf="emPerigo()"> <i class="fe-user"></i>Em perigo</span>
  <span class="badge badge-pill badge-warning" *ngIf="contato && contato.status === 'Em Risco'"> <i class="fe-user"></i>Em Risco</span>
  <span class="badge badge-pill badge-light" *ngIf="importado()"> <i class="fe-user"></i>Importado</span>
</ng-container>

