<h4   class="page-title" ><i class="fe-shopping-cart"></i> Pedidos</h4>

<h5  [hidden]="!empresa?.integracoesIfood?.length" #appendTo class="mb-2">
  Aceitar pedidos automaticamente
  <kendo-switch [(ngModel)]="empresa.aceitarPedidoAutomatico" (ngModelChange)="alterouAceitarAutomatico($event)"
                name="aceitarPedidoAutomatico" id="aceitarPedidoAutomatico"  ></kendo-switch>
</h5>

<div class="card">
  <div class="card-body">
    <kendo-tabstrip class="nav-bordered mt-3" >
      <kendo-tabstrip-tab [title]="'Em aberto'" [selected]="true">
        <ng-template kendoTabContent>
          <app-tela-acompanhar-pedidos [empresa]="empresa" ></app-tela-acompanhar-pedidos>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Agendados'">
        <ng-template kendoTabContent>
          <app-tela-acompanhar-pedidos [apenasAgendados]="true" [empresa]="empresa"></app-tela-acompanhar-pedidos>
        </ng-template>
      </kendo-tabstrip-tab>


      <kendo-tabstrip-tab [title]="'Finalizados'">
        <ng-template kendoTabContent>
              <app-tela-pedidos-pagos></app-tela-pedidos-pagos>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Cancelados'">
        <ng-template kendoTabContent>
          <app-tela-pedidos-cancelados></app-tela-pedidos-cancelados>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

</div>



