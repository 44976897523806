<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel">
      Importador no Catálogo <b> {{catalogo?.nome }}</b> via CSV
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="this.feche()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
     <span [hidden]="!buscarProdutos || importando">
        <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
              novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >
          <div class="alert alert-success" role="alert" *ngIf="mensagemSucesso">
            <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
            <button type="button" class="close" aria-label="Fechar" (click)="fecheMensagemSucesso()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
            <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
            <button type="button" class="close" aria-label="Fechar" (click)="fecheMensagemErro()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="row">
            <div class="form-group mb-3 col-8" *ngIf="!importou">
              <label for="arquivo">Arquivo csv: </label>
              <kendo-fileselect id="arquivo" name="arquivo"   (select)="selecionouArquivo($event)"
                                [(ngModel)]="files"
                                [multiple]="false"  [restrictions]="restricoes" required  >
                <kendo-fileselect-messages
                  select="Selecione..."
                  clearSelectedFiles="Limpar">
                </kendo-fileselect-messages>
              </kendo-fileselect>
              <div class="invalid-feedback">
                <p *ngIf="files.errors?.required">Arquivo é obrigatório</p>
              </div>
            </div>
            <div class="form-group mb-3 col-4" *ngIf="!importou">
              <label>Separador decimal do preço:</label><br>
              <kendo-dropdownlist name="separadorDecimal" [data]="opcoesSeparadorDecimal"  class="dropdown" valueField="codigo"  textField="descricao"
                                  [(ngModel)]= "separadorDecimal"></kendo-dropdownlist>
            </div>
          </div>

          <div *ngIf="conteudoArquivo">
            <div  class="pt-2 row" >
              <div class="col-12">
                <h6 class="float-left">Produtos a importar: <span>{{conteudoArquivo.length}}</span></h6>

                <div class="clearfix"></div>
              </div>
            </div>

            <div class="container-scroll row pt-3" *ngIf="conteudoArquivo.length">
              <div class="col-12">
                <table >
                  <thead>
                  <tr >
                    <th *ngFor="let item of  colunasImportar" [ngClass]="{'esconder': item.removida || item.indice===0}" >
                      <kendo-dropdownlist name="colunaImportar{{item.indice+1}}" [data]="tipoImportar.colunas"  class="dropdown"  textField="descricao"
                                          [(ngModel)]= "item.coluna"></kendo-dropdownlist>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>

                  </thead>
                  <tbody class="mt-1 border-top">
                  <ng-container *ngFor="let linha of  conteudoArquivo  | slice:0:10; let ilinha = index;" >
                    <ng-container *ngIf="ilinha > 0 || !removeuPrimeiraLinha">
                      <tr>
                        <td *ngFor="let coluna of linha;let i = index;"  [ngClass]="{'esconder': colunasRemovidas.indexOf(i)>=0  || i==0}">
                          {{coluna && coluna.length > 20 ? coluna.substring(0, 20) : coluna}}
                        </td>
                        <td>{{linha.erro}}</td>
                        <td>
                          <button class="btn btn-danger btn-xs float-left" (click)="removaLinha(linha,ilinha)"  *ngIf="ilinha==0"><i class="fa fa-trash"></i></button>
                        </td>

                      </tr>
                    </ng-container>

                  </ng-container>
                  </tbody>

                </table>
              </div>

            </div>


          </div>

          <div class="modal-footer">
            <button type="submit" *ngIf="!importou && !somentePrecos" class="btn btn-primary waves-effect waves-light"  >Importar</button>
            <button type="submit" *ngIf="!importou && somentePrecos" class="btn btn-primary waves-effect waves-light" [disabled]="carregando"  >

                <i class="k-icon k-i-loading" *ngIf="carregando"></i>
              Carregar Tabela</button>
            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="feche()">Fechar</button>
          </div>
        </form>

        <div *ngIf="importando" class="modal-body">
          <div class="col-12">
            Aguarde enquanto importamos o catálogo...
            <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>
          </div>
        </div>
      </span>
    <span    [hidden]="buscarProdutos" >
      <div class="alert alert-info alert-dismissible fade show mt-2" *ngIf="tudoSincronizado" role="alert">
        <h6>Não há nenhum preço para atualizar</h6>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <app-painel-importacao-produtos #painelImportacao [hidden]="tudoSincronizado"    ></app-painel-importacao-produtos>

    </span>
  </div>

  <div class="modal-footer" >
    <span [hidden]="buscarProdutos">
       <button type="submit"   class="btn btn-danger waves-effect waves-light"  (click)="painelImportacao.sincronizeTodos()"
               [disabled]="tudoSincronizado || painelImportacao.importacao.sincronizando">


      <i class="k-icon k-i-loading" *ngIf="painelImportacao.importacao.sincronizando" ></i>
      Sincronizar todos</button>

    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
            (click)="volteTela()">Voltar</button>
    </span>

  </div>

</div>
