<div class="vh-100">
  <div class="linha-caixa justify-content-center align-items-center h-100">
    <div class="caixa_login">
      <div class="text-center w-75 mx-auto" *ngIf="empresa.logo">
        <img class="imagem_empresa" src="/images/empresa/{{empresa.logo}}"/>
      </div>


      <form (ngSubmit)="onSubmit()" [formGroup]="frmLogin">
        <div class="form-group mb-3" [ngClass]="{'has-error': email.invalid && (email.dirty || email.touched)}">
          <label for="email">E-mail</label>
          <input class="form-control" type="email" id="email" required="" formControlName="email" placeholder="Digite seu e-mail">
          <div *ngIf="email.invalid && enviado" class="erro col-lg-9">
            <div *ngIf="email.errors.required">
              Email é obrigatório
            </div>
            <div *ngIf="email.errors.minlength">
              Email deve ter pelo menos 5 caracteres
            </div>
            <div *ngIf="email.errors.email">
              Você deve informar um email válido
            </div>
          </div>

        </div>

        <div class="form-group mb-3" [ngClass]="{'has-error': senha.invalid && (senha.dirty || senha.touched)}">
          <label for="senha">Senha</label>
          <input class="form-control" type="password" required="" id="senha" formControlName="senha" placeholder="Digite sua senha">
          <div *ngIf="senha.invalid && enviado" class="erro  col-lg-9">
            <div *ngIf="senha.errors.required">
              Senha é obrigatória
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <div class="">
            <label class="" for="checkbox-signin">
              <input type="checkbox" class="k-checkbox" id="checkbox-signin" checked kendoCheckBox>
              &nbsp;Continuar logado
            </label>
          </div>
        </div>

        <div class="form-group mb-0 text-center">
          <button class="btn btn-block" type="submit" [disabled]="enviando" [ngClass]="{disabled: enviando}"> Acessar </button>
          <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
            <div [innerHTML]="mensagemErro"></div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        </div>

      </form>

      <div class="text-center">
        <div class="col-12 text-center mt-3">
          <p class="mb-1">Esqueceu  sua <a href=""  (click)="vaParaRecuperarSenha($event)" class=" ml-1">
            <b>senha?</b></a>
          </p>

          <!--<p> <a href="pages-recoverpw.html" class="text-white-50 ml-1">Forgot your password?</a></p>-->
          <p class="">Ou solicite seu<a routerLink="/admin/signup" class=" ml-1"><b>cadastro</b></a></p>
        </div> <!-- end col -->



        <p class="text-muted">{{empresa?.nome}}</p>

      </div>

    </div>
  </div>
</div>

