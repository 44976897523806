import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfiguracoesMiaService} from "../../services/configuracoes-mia.service";
import {NgForm} from "@angular/forms";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {NotificacoesService} from "../../services/notificacoes.service";

declare var moment;

@Component({
  selector: 'app-tela-config-atendente-virtual',
  templateUrl: './tela-config-atendente-virtual.component.html',
  styleUrls: ['./tela-config-atendente-virtual.component.scss']
})
export class TelaConfigAtendenteVirtualComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;

  public configuracoes = {
    nome: '',
    status: 'DESATIVADA',
    telefonesTeste: '',
    comportamentoForaDoEscopo: '',
    responderSobreProdutos: false,
    tempoPausaMia: 1800,
    recuperarCarrinho: false,
    tempoRecuperarCarrinho: 20 * 60
  }
  msgSucesso = '';
  telefones: Array<string> = [];
  empresa: any;
  comportamentoForaDoEscopoEnum: string;
  notificacao: any;

  constructor(private configuracoesMiaService: ConfiguracoesMiaService, private constantsService: ConstantsService,
              private notificacoesService: NotificacoesService) { }

  ngOnInit(): void {
      this.notificacoesService.carregue('Carrinho Abandonado').then((notificacao) => {
        if( !notificacao ) {
          notificacao = {
            ativada: true,
            fazerPreview: true
          };
        }

        this.notificacao = notificacao;
        //carrega a configuracao
        this.configuracoesMiaService.obtenhaConfiguracoes().then((configuracoes) => {
            this.configuracoes = configuracoes;
            this.telefones = this.configuracoes.telefonesTeste.split(',');
        });

        //carrega a empresa
        this.constantsService.empresa$.subscribe((empresa) => {
            if (empresa && empresa.id) {
                this.empresa = empresa;
            }
        });
      });
  }

  fechar() {

  }

  onSubmit() {
    this.msgSucesso = '';
    if( !this.configuracoes.nome ) {
      return;
    }

    this.configuracoes.telefonesTeste = this.telefones.join(",");
    this.configuracoesMiaService.salveConfiguracoes(this.configuracoes).then((configuracaoSalva) => {
      this.configuracoes = configuracaoSalva;
      this.msgSucesso = 'Salvou configurações da Mia';
    });
  }

  onTelefonesMudou($event: any[]) {
    this.telefones = $event;
  }

  formatTime(seconds: number): string {
    const duration = moment.duration(seconds, 'seconds');
    let formattedTime = '';

    if (duration.hours() > 0) {
      formattedTime += `${duration.hours()} ${this.pluralize(duration.hours(), 'hora', 'horas')} `;
    }

    if (duration.minutes() > 0 || duration.hours() > 0) {
      formattedTime += `${duration.minutes()} ${this.pluralize(duration.minutes(), 'minuto', 'minutos')} `;
    }

    if( duration.seconds() > 0 ) {
      formattedTime += `${duration.seconds()} ${this.pluralize(duration.seconds(), 'segundo', 'segundos')}`;
    }

    return formattedTime;
  }

  pluralize(quantity: number, singular: string, plural: string): string {
    return quantity === 1 ? singular : plural;
  }
}
