import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AutorizacaoService} from "../services/autorizacao.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  frmCadastrar: UntypedFormGroup;
  enviado = false;
  mensagemErro: string;
  urlDestino: string;
  enviando: boolean;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private autorizacao: AutorizacaoService,private router: Router) {
    this.frmCadastrar = this.fb.group({
      'nome': ['', [
        Validators.required,
        Validators.minLength(5)
      ]],

      'email': ['', [
        Validators.required, Validators.email,
        Validators.minLength(5)
      ]],
      'senha': ['', [
        Validators.required
      ]],
      'confirmacaoSenha': ['', [
        Validators.required
      ]],
      'aceitouTermos': ['', [
        Validators.requiredTrue
      ]]
    });

    this.route.queryParams.subscribe(params => {
      this.urlDestino = params['t'];
    });


  }

  get nome() { return this.frmCadastrar.get('nome'); }
  get email() { return this.frmCadastrar.get('email'); }
  get senha() { return this.frmCadastrar.get('senha'); }
  get confirmacaoSenha() { return this.frmCadastrar.get('confirmacaoSenha'); }
  get aceitouTermos() { return this.frmCadastrar.get('aceitouTermos'); }


  ngOnInit() {
  }

  onSubmit() {
    this.enviado = true;

    if ( !this.frmCadastrar.valid ) {
      this.mensagemErro = "Existem erros no preenchimento. Por favor, verifique e tente novamente!";
      window.scrollTo(0, 0);
      return;
    }

    let dados = this.frmCadastrar.value;

    if(this.senha.value != this.confirmacaoSenha.value) {
      this.mensagemErro = "Senha e confirmação de senha estão diferentes. Corrija e tente novamente!";
      window.scrollTo(0, 0);
      return;
    }


    this.autorizacao.cadastrar(dados.nome, dados.email, dados.senha).then((resposta: any) => {

      if(resposta.sucesso) {
        console.log('Indo para ' + this.urlDestino)
        if(this.urlDestino) {
          console.log("Possui url destino")
          this.router.navigate([this.urlDestino] );

        }
        else {
          console.log("Não possui url destino")
          this.router.navigate(["/admin/login", {}])
        }


      } else
        this.mensagemErro = resposta.mensagem;


    })


  }
}
