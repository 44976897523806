import {Component, EventEmitter, OnInit} from '@angular/core';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {EmpresasService} from "../../superadmin/services/empresas.service";

@Component({
  selector: 'app-tela-escolher-dominio-empresa',
  templateUrl: './tela-escolher-dominio-empresa.component.html',
  styleUrls: ['./tela-escolher-dominio-empresa.component.scss']
})
export class TelaEscolherDominioEmpresaComponent implements OnInit {
  mensagemErro = '';
  windowRef: DialogRef;
  enviando = false;

  codigo = '';

  onEscolheuCupom = new EventEmitter();

  constructor(private empresasService: EmpresasService) { }

  static abraComoDialog(dialogService: DialogService, empresa: any, fnEscolheuCupom) {
    let windowRef: any = dialogService.open({
      title: 'Informe o domínio da empresa',
      content: TelaEscolherDominioEmpresaComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: 500
    });

    windowRef.content.instance.empresa = empresa;
    windowRef.content.instance.windowRef = windowRef

    const tela: TelaEscolherDominioEmpresaComponent = windowRef.content.instance;

    tela.onEscolheuCupom.subscribe((dominio) => {
      if( fnEscolheuCupom ) fnEscolheuCupom(dominio);
    });

    windowRef.result.subscribe((result: any) => {
      if(result){

      }
    });
  }

  ngOnInit(): void {
  }

  onSalvar() {
    if( this.codigo.indexOf(' ' ) !== -1 ) {
      alert('O campo código não pode conter espaços');
      return;
    }
    this.mensagemErro = '';

    this.empresasService.valideDominio(this.codigo).then( (valido) => {
      this.onEscolheuCupom.emit(this.codigo);
      this.windowRef.close();
    }).catch( (erro) => {
      this.mensagemErro = erro;
    });
  }

  fechar() {
    this.windowRef.close();
  }
}
