import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
declare const _;
declare const moment;

@Injectable({
  providedIn: 'root'
})
export class VendasFidelidadeService extends BehaviorSubject<any> {
  public loading = false;
  private BASE_URL = '/contatos/resumoVendas/:referencia';
  private vendasPorDia
  private vendasPorMes
  private vendasPorAno
  private series
  private labels;

  constructor(private http: HttpClient) {
    super(null);

    if( moment ) moment.locale('pt-br');
  }

  public queryDia(labels: any, resumoVenda: any): void {
    this.fetchDia()
      .subscribe((resposta: any) => {
          this.setValores(labels, resumoVenda, resposta)
      });
  }

  private setValores(labels: any, resumoVenda: any, resposta: any){
    labels.splice(0);
    resposta.labels.forEach( label => {
      labels.push(label);
    });

    resumoVenda.total = Number ( (Math.round(resposta.total * 100) / 100).toFixed(2) );
    resumoVenda.pontos =   Number ( (Math.round(resposta.pontos * 100) / 100).toFixed(2) );
    super.next(resposta.series)
  }

  public queryMes(labels: any, resumoVenda: any): void{
    this.fetchMes()
      .subscribe((resposta: any) => {
        this.setValores(labels, resumoVenda, resposta)
      });
  }

  public queryAno(labels: any, resumoVenda: any){
    this.fetchAno()
      .subscribe((resposta: any) => {
        this.setValores(labels, resumoVenda, resposta)
      });
  }

  private fetchAno(): any{
    let url = this.BASE_URL.replace(':referencia', 'ano')
    this.loading = true;
    return this.http
      .get(url)
      .pipe(
        map( (response: any) => {
          this.vendasPorAno = response.data;
          this.series = [];
          this.labels = [];
          let total = 0, pontos = 0;
          let anos = _.groupBy(this.vendasPorAno, (resumo) => resumo.ano);

          // tslint:disable-next-line:forin
          for (  let ano in anos){
            let totalAno = _.reduce(anos[ano], (valor: number, resumoDiario: any) => resumoDiario.total + valor, 0);
            let pontosAno = _.reduce(anos[ano], (valor: number, resumoDiario: any) => resumoDiario.pontos + valor, 0);
            this.series.push({value: totalAno, color: '#48d746'});
            this.labels.push(ano)

            total += totalAno;
            pontos += pontosAno;
          }

          return {series: this.series, labels: this.labels, total: total, pontos: pontos};
        } ),
        tap(() => this.loading = false)
      );
  }

  private fetchMes(): any{
    let url = this.BASE_URL.replace(':referencia', 'mes')
    this.loading = true;
    return this.http
      .get(url)
      .pipe(
        map( (response: any) => {
          this.vendasPorMes = response.data;

          this.series = []
          this.labels = [];
          let meses = _.groupBy(this.vendasPorMes, (resumo: any) => {
            return moment(resumo.mes, 'MM').format('MMM') + ' ' + resumo.ano;
          });

          let total = 0, pontos = 0;

          for( let mes in meses ) {
            let totalMes = _.reduce(meses[mes], (valor: number, resumoDiario: any) => resumoDiario.total + valor, 0);
            let pontosMes = _.reduce(meses[mes], (valor: number, resumoDiario: any) => resumoDiario.pontos + valor, 0);

            this.series.push({value: totalMes, color: '#48d746'});
            this.labels.push( mes );
            total += totalMes;
            pontos += pontosMes;
          }

          return {series: this.series, labels: this.labels, total: total, pontos: pontos};
        } ),
        tap(() => this.loading = false)
      );
  }

  private fetchDia(): any {
    let url = this.BASE_URL.replace(':referencia', 'dia')
    this.loading = true;
    return this.http
      .get(url)
      .pipe(
        map( (response: any) => {
          this.vendasPorDia = response.data;
          this.series = []
          this.labels = [];
          let total = 0, pontos = 0;

          for (let i = 0; i < this.vendasPorDia.length; i++) {
            let resumo: any = this.vendasPorDia[i];
            let serie: any = { value: resumo.total, color: '#48d746' };
            let label: any = (resumo.dia + '').padStart(2, '0') + " " +
              moment(resumo.mes, 'MM').format('MMM');

            this.labels.push(label);
            this.series.push(serie);

            total += resumo.total;
            pontos += resumo.pontos;
          }
          return {series: this.series, labels: this.labels, total: total, pontos: pontos};
        } ),
        tap(() => this.loading = false)
      );
  }
}
