import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DialogService} from "@progress/kendo-angular-dialog";
import {NovaRestricaoCidadeComponent} from "../nova-restricao-cidade/nova-restricao-cidade.component";
import {EmpresasService} from "../../services/empresas.service";
import {DataBindingDirective} from "@progress/kendo-angular-grid";
import {DataResult, process} from '@progress/kendo-data-query';

@Component({
  selector: 'app-form-restringir-cidades',
  templateUrl: './form-restringir-cidades.component.html',
  styleUrls: ['./form-restringir-cidades.component.scss']
})
export class FormRestringirCidadesComponent implements OnInit, AfterViewInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  cidadesEscolhidas = [
  ];
  gridView: any[];

  @Input() empresa;
  carregando = false;
  modalDeletar: any;
  cidadeARemover: any;

  constructor(private dialogService: DialogService, private empresasService: EmpresasService) { }

  ngOnInit(): void {
  }

  novaCidade() {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Escolha uma cidade que você aceita pedidos',
      content: NovaRestricaoCidadeComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaRestricao: NovaRestricaoCidadeComponent = windowRef.content.instance;
    //telaNovaFormaDePagamento.novaForma();
    telaNovaRestricao.empresa = this.empresa;
    telaNovaRestricao.windowRef = windowRef;

    windowRef.result.subscribe((result: any) => {
      if( result.atualize ) {
        this.empresasService.obtenhaCidadesEntrega(this.empresa).then( (cidades) => {
          this.cidadesEscolhidas = cidades;
          this.onFilter('');
        });
      }
    });
  }

  adicionarCidade() {

  }

  removaCidade(cidade: any, i: number) {
    cidade.removida = true;
  }

  ngAfterViewInit(): void {
    this.atualizeLista();
  }

  atualizeLista() {
    this.carregando = true;
    this.empresasService.obtenhaCidadesEntrega(this.empresa).then( (cidades) => {
      this.cidadesEscolhidas = cidades;
      this.onFilter('');
      this.carregando = false;
    }).catch( erro => {
      this.carregando = false;
    });
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.cidadesEscolhidas, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'nome',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'estado.nome',
            operator: 'contains',
            value: inputValue
          }
        ],
      }
    }).data;

    this.dataBinding.skip = 0;
  }

  remover(cidade: any) {
    this.cidadeARemover = cidade;
    this.modalDeletar = true;
  }

  close(opcao: string) {
    if( opcao === 'sim') {
      this.empresasService.removaCidadeEntrega(this.empresa, this.cidadeARemover).then( () => {
        this.modalDeletar = false

        this.atualizeLista();
      });

      return;
    }

    this.modalDeletar = false;
  }

  clicou() {
  }
}
