<div  class="topo-fixo">
  <div  class="row"  >
    <div class="col-11">
      <h3>
                  <span *ngIf="importacao.totalImportar">
                    <b>{{importacao.totalImportar}}</b> {{importacao.totalImportar === 1 ? 'Produto' : 'Produtos' }}

                    {{modoExibicao ? "Encontrados" : "Adicionar"}}

                  </span>

        <span *ngIf="importacao.totalImportar && importacao.totalAtualizar">, </span>

        <span *ngIf="importacao.totalAtualizar">
                     <b>{{importacao.totalAtualizar}}</b>  {{importacao.totalAtualizar === 1 ? 'Produto' : 'Produtos'}}   Atualizar
                  </span>

        <span *ngIf="importacao.totalRemover">
                     <b>{{importacao.totalRemover}}</b>  {{importacao.totalRemover === 1 ? 'Produto' : 'Produtos'}}   Remover
                  </span>
      </h3>

    </div>

    <div class="col-5" *ngIf="naoImportados.length && importacao.finalizou">
      <p class="text-danger  mt-2">
        <b>{{naoImportados.length}}</b> produto(s) não foram importados! Verifique na listagem abaixo.
      </p>

      <button class="btn btn-danger  " (click)="reimporteProdutos()"
              [hidden]="importacao.finalizou && !naoImportados.length"  >

        Importar somente os que deram  erro</button>
    </div>

    <div class="col-12"   [hidden]="modoExibicao" >
      <h6 class="text-black-50">Produtos sincronizados
        <i class="k-icon k-i-loading" *ngIf="importacao.sincronizando"></i>
      </h6>

      <kendo-progressbar [label]="labelSincronizados" [value]="importacao.totalProcessados" [style.width.%]="100" [emptyCssStyle]="emptyStyles"
                         [max]="importacao.total" [progressCssStyle]="progressStyles"></kendo-progressbar>

    </div>
  </div>

</div>

<div class="card" *ngFor="let categoriaSincronizar of produtosSincronizarPorCategoria"
     [hidden]="importacao.sincronizando &&  !categoriaSincronizar.sincronizando">
  <div class="categoria cpointer" (click)="toogleExibirProdutos(categoriaSincronizar)">
    <div class="row">
      <div class="col-7">

        <h4>{{categoriaSincronizar.nomeCategoria}}
          <i class="k-icon k-i-plus mr-1" [hidden]="categoriaSincronizar.exibirProdutos"></i>
          <i class="k-icon k-i-minus mr-1" [hidden]="!categoriaSincronizar.exibirProdutos"></i>
          <span class="badge badge-success ml-1" *ngIf="categoriaSincronizar.totalImportar">
                  {{categoriaSincronizar.totalImportar}}
            {{ categoriaSincronizar.totalImportar === 1 ? ' produto' : ' produtos'}} adicionar
                </span>

          <span class="badge badge-warning ml-1" *ngIf="categoriaSincronizar.totalAtualizar">
                  {{categoriaSincronizar.totalAtualizar}}
            {{ categoriaSincronizar.totalAtualizar === 1 ? ' produto ' : ' produtos'}} atualizar
                </span>

          <span class="badge badge-danger ml-1" *ngIf="categoriaSincronizar.totalRemover">
                  {{categoriaSincronizar.totalRemover}}
            {{ categoriaSincronizar.totalRemover === 1 ? ' produto ' : ' produtos'}} remover
                </span>

        </h4>
      </div>

      <div class="col-5" [hidden]="modoExibicao"  >

        <button class="k-button k-primary float-right"   [hidden]=" categoriaSincronizar.sincronizando || categoriaSincronizar.finalizou"
                  (click)="importeCategoria(categoriaSincronizar)"
                kendoTooltip title="Sincronizar categoria">   <i class="fa fa-save "></i>
        </button>

        <span  [hidden]="!categoriaSincronizar.sincronizando">
           <br>
           <kendo-progressbar [label]="obtenhaLabel(categoriaSincronizar)" [value]="categoriaSincronizar.totalProcessados"    [style.width.%]="100"  [emptyCssStyle]="emptyStyles"
                              [max]="categoriaSincronizar.total" [progressCssStyle]="categoriaSincronizar.progressStyles"></kendo-progressbar>
        </span>

      </div>
    </div>

  </div>

  <div [hidden]="!categoriaSincronizar.exibirProdutos"   >
    <kendo-grid   [data]="categoriaSincronizar.produtos"
                  [loading]="buscando"  [hidden]="categoriaSincronizar.importando">

      <ng-template kendoGridToolbarTemplate [position]="'top'">    </ng-template>

      <kendo-grid-column title="Cod." field="codigoPdv"    width="110"> </kendo-grid-column>
      <kendo-grid-column title="Imagem" class="coluna_menor" width="125">
        <ng-template kendoGridCellTemplate let-produto >

          <ng-container *ngIf="produto.novaImagem">
            <img [src]="produto.novaImagem.urlImagemExterna" style="width: 80px;">

            <span class="badge badge-warning mt-1">atualizar foto</span>
          </ng-container>

          <ng-container *ngIf="!produto.novaImagem">
            <img [src]="produto.urlImagemExterna ? produto.urlImagemExterna : produto.linkImagem" style="width: 80px;" *ngIf="produto.linkImagem">

            <i class="fe-image fa-2x" *ngIf="!produto.linkImagem"></i>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Nome" field="nome"     >
        <ng-template kendoGridCellTemplate let-produto  >
          <b>{{produto.nome }}</b>
          <span class="badge badge-success ml-1" *ngIf="produto.importar">novo</span>
          <span class="badge badge-danger ml-1" *ngIf="produto.remover">removido</span>
          <span class="badge badge-warning ml-1" *ngIf="produto.sincronizar &&  produto.novoPreco">atualizar preço</span>
          <span class="badge badge-warning ml-1" *ngIf="produto.voltouParaOSite">voltou ao catalogo</span>
          <span *ngIf="!produto.id && produto.disponibilidad === 2 " class="badge badge-danger">indisponível</span>

          <ng-container *ngIf="produto.sincronizar">
            <span class="badge badge-success ml-1" *ngIf="produto.qtdeAdicionaisNovos">
                   +{{produto.qtdeAdicionaisNovos}}     {{produto.qtdeAdicionaisNovos === 1 ? 'novo adicional ' : 'novos adicionais'}}

            </span>

            <span class="badge badge-warning ml-1" *ngIf="produto.qtdeAdicionaisAtualizar">
                   +{{produto.qtdeAdicionaisAtualizar}}     {{produto.qtdeAdicionaisAtualizar === 1 ? 'adicional atualizar ' : 'adicionais atualizar'}}

            </span>
          </ng-container>

          <span class="badge badge-primary ml-1" *ngIf="produto.sincronizar && produto.opcoesAtualizadas.length">
                +{{produto.opcoesAtualizadas.length}}

            {{produto.opcoesAtualizadas.length === 1 ? 'adicional ' : 'adicionais'}}
            sincronizar
              </span>


          <span class="badge badge-danger ml-1" *ngIf="produto.sincronizar && produto.camposAdicionaisRemovidos.length">
                +{{produto.camposAdicionaisRemovidos.length}}
            {{produto.camposAdicionaisRemovidos.length === 1 ? 'adicional removido' : 'adicionais removidos'}}
        </span>

          <span class="badge badge-danger ml-1" *ngIf="produto.sincronizar && produto.opcoesRemovidas.length">
                +{{produto.opcoesRemovidas.length}}

            {{produto.opcoesRemovidas.length === 1 ? 'opção removida' : 'opções removidos'}}

              </span>

          <p class="font-11 text-muted" *ngIf="produto.novaDescricao"> <b><span class="badge badge-warning">nova descriçao</span></b>: "{{produto.novaDescricao}}"</p>

        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Preço"   width="180" >
        <ng-template kendoGridCellTemplate let-produto  >
          <ng-container  *ngIf="(produto.importar || !produto.precoAtualizado) && !produto.faixaPreco">
            <ng-container *ngIf="produto.novoPreco">
              <span class="riscado">{{ produto.preco  | currency: "R$"}}</span>  por
              <span  class="text-success"><b> {{produto.novoPreco  | currency: "R$"}} </b></span>
            </ng-container>
            <span  class="text-success" *ngIf="!produto.novoPreco">
                 <b>{{ produto.preco | currency: "R$"}}</b>
              </span>
          </ng-container>

          <span *ngIf="produto.faixaPreco"  class="text-success">
            {{produto.faixaPreco}}
        </span>

          <ng-container *ngIf="produto.sincronizar && produto.precoAtualizado != null">
            <span class="preco-antigo text-muted">{{produto.precoAntigo | currency: "R$"}}</span>
            agora

            <span  class="preco-novo  text-success"  >
                <b>{{produto.precoAtualizado | currency: "R$"}}</b>
              </span>
          </ng-container>

        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" width="80" [class]="{'comandos': true}">

        <ng-template kendoGridCellTemplate let-produto >
              <span *ngIf="produto.sincronizou || (!importacao.sincronizando && !produto.sincronizando)" [hidden]="modoExibicao">
                    <button kendoGridEditCommand [primary]="true" [hidden]="produto.importou"
                            *ngIf="produto.importar" (click)="importeProduto(produto)"
                            kendoTooltip title="Importar produto">   <i class="fa fa-save "></i>
                    </button>


                    <button kendoGridEditCommand [primary]="true"  [hidden]="produto.sincronizado"
                            *ngIf="produto.sincronizar "(click)="atualizeProduto(produto)"
                            kendoTooltip title="Atualizar produto">
                       <i class="fa fa-sync"></i>
                    </button>
              </span>


          <i class="k-icon k-i-loading fa-2x" *ngIf="produto.sincronizando"></i>
          <i class="k-icon k-i-check-circle text-success fa-2x" *ngIf="produto.sincronizado || produto.importou"></i>
          <i class="k-icon k-i-error text-danger fa-2x" *ngIf="produto.erro"> </i>

        </ng-template>

      </kendo-grid-command-column>
      <ng-template kendoGridDetailTemplate let-produto     style="background: #fff;">
        <div class="alert alert-danger alert-dismissible fade show mb-2" *ngIf="produto.erro" role="alert">
          Não foi possível importar esse produto: <br>
          {{produto.erro}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="alert alert-warning alert-dismissible fade show" *ngIf="produto.naoTemAdicionais" role="alert">
          Nenhum adicional para importar ou atualizar
          <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <kendo-grid
          [data]="obtenhaAdicionais(produto)"
          [loading]="buscando"      [hidden]="produto.naoTemAdicionais">

          <kendo-grid-column title="Cod." field="codigoPdv"   width="150" > </kendo-grid-column>
          <kendo-grid-column title="Nome da opção"      >
            <ng-template kendoGridCellTemplate let-opcao  >
              {{opcao.nome}}

              <p class="font-11 text-muted" *ngIf="opcao.novaDescricao">
                <b><span class="badge badge-warning">nova descriçao</span></b>: "{{opcao.novaDescricao}}"</p>

              <span *ngIf="!opcao.id && !opcao.disponivel " class="badge badge-danger">indisponível</span>
              <span *ngIf="opcao.id && opcao.removida " class="badge badge-danger">removida</span>

              <ng-container *ngIf="!opcao.id">
                <p *ngIf="opcao.descricao" class="text-muted mb-0" style="font-size: 11px">{{opcao.descricao}}</p>
                <span class="badge badge-success ml-1" >  novo  </span>
              </ng-container>



              <span class="badge badge-warning ml-1" *ngIf="!opcao.removida && opcao.novoPreco">
                atualizar   </span>

            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Preço"    width="120"  >
            <ng-template kendoGridCellTemplate let-opcao  >
              <ng-container *ngIf="opcao.novoPreco">
                <span class="preco-antigo text-muted">{{opcao.precoAntigo | currency: "R$"}}</span>
                agora
                <span  class="preco-novo  text-success"  >
                    <b>{{opcao.novoPreco | currency: "R$"}}</b>
                    </span>
              </ng-container>

              <span *ngIf="opcao.faixaPreco"  class="text-success">
              {{opcao.faixaPreco}}
            </span>


              <ng-container *ngIf="!opcao.novoPreco && !opcao.faixaPreco">
                <span class="text-success"><b>{{opcao.valor | currency: "R$"}}</b></span>
              </ng-container>

            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="" field="nomeAdicional"   width="10"   >

            <ng-template kendoGridGroupHeaderTemplate let-value=value  >
              Adicional - {{value}}
            </ng-template>
            <ng-template kendoGridCellTemplate let-opcao  >
            </ng-template>

          </kendo-grid-column>
          <kendo-grid-column      width="80">
            <ng-template kendoGridCellTemplate let-opcao  >
              <i class="k-icon k-i-check-circle text-success fa-lg" *ngIf="produto.sincronizado"></i>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>

    </kendo-grid>
  </div>

</div>
