import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FotoService} from "../../services/foto.service";
import {ConstantsService} from "../ConstantsService";
import {Router} from "@angular/router";

@Component({
  selector: 'app-wizard-config-empresa',
  templateUrl: './wizard-config-empresa.component.html',
  styleUrls: ['./wizard-config-empresa.component.scss']
})
export class WizardConfigEmpresaComponent implements OnInit {
  @ViewChild('tabLogo', {static: true}) tabLogo: ElementRef;
  @ViewChild('tabCapa', {static: true}) tabCapa: ElementRef;
  @ViewChild('tabFormasEtrega', {static: true}) tabFormasEtrega: ElementRef;
  @ViewChild('tabProdutos', {static: true}) tabProdutos: ElementRef;
  @ViewChild('frmFormaEntrega') frmFormaEntrega: any;
  progressoWizard: any = '0%';
  empresa: any = {};
  passo: string;

  constructor(private fotoService: FotoService, private constantsService: ConstantsService,
              private router: Router) { }

  ngOnInit() {
    this.passo = 'logo';
    this.constantsService.empresa$.subscribe( data => {
      this.empresa = data || {};
      if(this.empresa.id){
        this.empresa.salvouLogo = this.empresa.logo != null;
        this.empresa.salvouCapa = this.empresa.capa != null;
        this.setProgresso();
        if(this.completouWizard()){
          this.varaParaInicio();
        } else {
          setTimeout( () => { this.frmFormaEntrega.exibaFormasEmpresa();  }, 0)
        }
      }
    });
  }

  salveCapaEmpresa($event: any) {
    this.fotoService.salveCapaEmpresa(this.empresa).then( () => {
      $event.salvou();
      this.proximoPasso();
    }).catch( err => {
      $event.erro(err)
    })
  }

  salveLogoEmpresa($event: any) {
    this.fotoService.salveLogoEmpresa(this.empresa).then( () => {
       this.empresa.salvouLogo = true;
       $event.salvou();
       this.proximoPasso();
    }).catch( err => {
      $event.erro(err)
    })
  }

  proximoPasso() {
    if(this.completouWizard()){
       this.varaParaInicio()
    }

    this.setProgresso();

    if(this.passo === 'logo'){
      this.vaParaCapa();
    } else if(this.passo === 'capa'){
      this.vaParaFormasEntrega();
    } else if(this.passo === 'formas'){
      this.vaParaProdutos();
    }
  }

  passoAnterior() {
    if(this.passo === 'formas'){
      this.vaParaCapa();
    } else if(this.passo === 'produtos'){
      this.vaParaFormasEntrega();
    } else if(this.passo === 'capa'){
      this.vaParaLogo();
    }
  }

  vaParaLogo(){
    this.tabLogo.nativeElement.click();
  }

  vaParaCapa(){
    this.tabCapa.nativeElement.click();
  }

  vaParaFormasEntrega() {
    this.tabFormasEtrega.nativeElement.click();
  }

  vaParaProdutos() {
    this.tabProdutos.nativeElement.click();
  }

  setProgresso(){
    let progresso = 0;

    if(this.empresa.capa) progresso += 25;
    if(this.empresa.logo) progresso += 25;
    if(this.empresa.formasDeEntrega && this.empresa.formasDeEntrega.length) progresso += 25;
    if(this.empresa.produtos && this.empresa.produtos.length) progresso += 25;

    this.progressoWizard = progresso + "%";

  }

  private completouWizard() {
      return this.empresa.capa && this.empresa.logo &&
             this.empresa.formasDeEntrega.length > 0  && this.empresa.produtos && this.empresa.produtos.length > 0;
  }

  salvouProduto(produto: any) {
    if(produto && produto.id)  this.empresa.produtos = [produto]

    this.proximoPasso();

  }

  private varaParaInicio() {
    location.href =  location.protocol + "//" + location.host + "/admin/index";
  }

  completouPasso() {
    if(this.passo === 'logo') return this.empresa.salvouLogo;
    if(this.passo === 'capa') return this.empresa.salvouCapa;
    if(this.passo === 'formas') return this.empresa.formasDeEntrega.length;

    return false;
  }
}
