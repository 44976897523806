import { Component, OnInit } from '@angular/core';
import {RecebimentoService} from "../../superadmin/services/recebimento.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-relatorio-recebimentos',
  templateUrl: './relatorio-recebimentos.component.html',
  styleUrls: ['./relatorio-recebimentos.component.scss']
})
export class RelatorioRecebimentosComponent implements OnInit {
  resumo: any = {};
  carregando = true;
  constructor(private activatedRoute: ActivatedRoute,
              private recebimentoService: RecebimentoService) { }

  ngOnInit(): void {
    let ano = this.activatedRoute.snapshot.params.ano;
    let mes = this.activatedRoute.snapshot.params.mes;

    this.recebimentoService.listeResumoRecebimentosEcletica(ano, mes).then ( resposta => {
      this.carregando = false;
      this.resumo = resposta;
    })
  }
}
