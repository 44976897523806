import {IUITipoDePontuacao} from "./IUITipoDePontuacao";

export class UITipoDePontuacaoPorValor implements IUITipoDePontuacao {
  valorPorPonto: number;
  empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any) {
    if (!pontuacaoRegistrada) return 0;
    return Math.floor(pontuacaoRegistrada.valor / this.valorPorPonto);
  }
}
