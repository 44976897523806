import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContatosService} from "../../services/contatos.service";
import {PedidosService} from "../../services/pedidos.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {State} from "@progress/kendo-data-query";

@Component({
  selector: 'app-historico-modulos',
  templateUrl: './historico-modulos.component.html',
  styleUrls: ['./historico-modulos.component.scss']
})
export class HistoricoModulosComponent implements OnInit {
  _empresa: any;
  _contato: any;
  private inicializou: boolean;
  @Output() onEstornouPontos   = new EventEmitter();
  acoes: any[] = [];
  pedidos: any[] = [];
  temModuloPedidos: boolean;
  temModuloFidelidade: boolean;
  maisVendidos: any[] = [];
  filtro: any = {};
  acaoEstornar: any;
  motivoEstorno: string
  erroEstornar: string
  estornando: boolean
  carregandoUltimosPedidos = true
  carregandoUltimasAcoes = true;
  carregandoEmails = true;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  gridStateAcoes: State = {
    sort: [],
    skip: 0,
    take: 20
  };
  pedidosResultSet: any = {
    data: [],  total: 0
  } ;
  gridStateEmails: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  emailsResultSet: any = {
    data: [],  total: 0
  } ;
  acoesResultSet: any = {
    data: [],  total: 0
  } ;
  constructor(private contatosService: ContatosService,
              private pedidosService: PedidosService,
              private constantsService: ConstantsService) {
    this.constantsService.moduloFidelidade$.subscribe( data => this.temModuloFidelidade  = data  )
    this.constantsService.moduloPedido$.subscribe( data => this.temModuloPedidos  = data)
  }

  ngOnInit() {
    this.inicialize()
  }

  @Input()
  set empresa(val: any) {
    this._empresa = val;

  }

  get empresa() {
    return this._empresa;
  }

  @Input()
  set contato(val: any) {
    this._contato = val;
    this.inicialize()
  }

  get contato() {
    return this._contato;
  }

  private inicieModuloPedidos() {
    if(!this.temModuloPedidos) return;

    this.carregueUltimosPedidos();
    this.carregueHistoricoProdutos();
    this.carregueHistoricoEmails();
  }


  private inicieModuloFidelidade() {

    if(!this.contato.ultimasAcoes){
      this.carregueUltimasAcoes();
    } else {
      this.acoes = this.contato.ultimasAcoes
    }

  }


  carregueUltimasAcoes(){
    if(!this.contato || !this.contato.id) return;
    this.carregandoUltimasAcoes = true;
    this.contatosService.obtenhaUltimasAcoes(this.contato, null,
      this.gridStateAcoes.skip , this.gridStateAcoes.take, this.filtro.pontuado).then( (acoes) => {
      this.carregandoUltimasAcoes = false;
      this.contato.ultimasAcoes = acoes;

      this.acoesResultSet = {
        data: acoes,
        total: acoes.length + this.gridStateAcoes.skip + 1
      };
    })
  }

  carregueUltimosPedidos() {
    this.carregandoUltimosPedidos = true;
    this.pedidosService.listePedidosContatoEncerrados(this.gridState.skip , this.gridState.take, this._contato).then((resposta) => {
      this.carregandoUltimosPedidos = false;

      this.pedidosResultSet = {
        data: resposta.pedidos,
        total: resposta.pedidos.length + this.gridState.skip + 1
      };

    }).catch((erro) => {
      alert('Houve um erro ao carregar os pedidos: ' + erro);
      this.carregandoUltimosPedidos = false;
      this.pedidos = []
    })


  }

  carregueHistoricoProdutos() {
    this.pedidosService.obtenhaProdutosVendidoContato(this.contato, this.filtro.inicio ? this.filtro.inicio.toLocaleDateString() : null,
      this.filtro.fim ? this.filtro.fim.toLocaleDateString() : null).then((maisVendidos: any) => {
      this.maisVendidos = maisVendidos

    })
  }

  carregueHistoricoEmails(){

    this.contatosService.listeEmails(this._contato, this.gridStateEmails.skip , this.gridStateEmails.take).then( (emails: any) => {
      this.emailsResultSet = {
        data: emails,
        total: emails.length + this.gridStateEmails.skip + 1
      };
      this.carregandoEmails = false;
    }).catch((erro) => {
      this.carregandoEmails = false;
      alert('Houve um erro ao carregar os emails: ' + erro);
    })

  }

  private inicialize() {
    if(!this.inicializou && this.empresa && this.contato) {
      this.inicieModuloFidelidade()
      this.inicieModuloPedidos()
    }
  }

  confirmarCancelarPontos(acao: any) {
    this.acaoEstornar = acao;
  }

  fecheEstornar(estornou: boolean = false){
    if(estornou){
      this.acaoEstornar.removida = true;
      this.onEstornouPontos.emit(this.acaoEstornar);
    }

    delete   this.acaoEstornar;

  }

  confirmouEstorno(){
    if( this.estornando) return;

    delete   this.erroEstornar;

    if(!this.acaoEstornar.pontuacao){
      this.erroEstornar = 'Nenhum pontuação associada a essa ação'
      return;
    }

    if(!this.motivoEstorno) {
      this.erroEstornar = 'Informe o motivo do estorno.'
      return;
    }

    if(this.motivoEstorno.length <= 5) {
      this.erroEstornar = 'Informe um motivo valido (mais de 5 letras)'
      return;
    }

    this.estornando = true;
    this.contatosService.estornePontos(this.contato, this.acaoEstornar.pontuacao, this.motivoEstorno).then( (resp) => {
      this.estornando = false;
      this.fecheEstornar(true);
    }).catch((erro) => {
      this.erroEstornar = erro;
      this.estornando = false;
    })
  }

  mudouPaginacao(e: PageChangeEvent) {
    this.gridState.skip = e.skip;
    this.carregueUltimosPedidos();

  }

  mudouPaginacaoAcoes(e: PageChangeEvent){
    this.gridStateAcoes.skip = e.skip;
    this.carregueUltimasAcoes();
  }

  mudouPaginacaoEmails(e: PageChangeEvent) {
    this.gridStateEmails.skip = e.skip;
    this.carregueHistoricoEmails();

  }
}
