import {Component, OnDestroy, OnInit} from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {HttpClient} from "@angular/common/http";
import {PedidosService} from "../../services/pedidos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-cotacao-delivery',
  templateUrl: './modal-cotacao-delivery.component.html',
  styleUrls: ['./modal-cotacao-delivery.component.scss']
})
export class ModalCotacaoDeliveryComponent extends ModalKendo implements OnInit, OnDestroy  {
  pedido: any = {};
  cotacao: any = {}
  solicitando: boolean;
  ignorarParceiro: boolean;
  erro: string;
  constructor( private  grupolojasService: GrupolojasService,
               private httpCliente: HttpClient,
               private  pedidosService: PedidosService) {
    super()
  }


  ngOnInit(): void {
    this.cotacao = this.pedido.delivery.cotacao;
  }

  ngOnDestroy(): void {
  }

  solicteParceiro(){
    this.solicitando  = true;
      this.pedidosService.soliciteEntregadorIfood(this.pedido, this.cotacao.idLoja).then((delivery: any) => {
        this.solicitando  = false;
        this.fecheModal(delivery)
      }).catch((err) => {
         this.erro = err;
        this.solicitando  = false;
      })
  }

  canceleCotacaoDelivery(){
    this.solicitando  = true;
    this.pedidosService.canceleCotacaoDeliveryIfood(this.pedido).then((delivery: any) => {
      this.solicitando  = false;
      this.fecheModal(delivery)
    }).catch((err) => {
      this.erro = err;
      this.solicitando  = false;
    })
  }

  obtenhaMinutos(tempo){
    return  `${(tempo / 60).toFixed(0)} min`
  }

}
