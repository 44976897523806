<div class="form-group mb-3">
  <div class="alert alert-warning alert-dismissible fade show mb-2" *ngIf="!possuiModuloDominioProprio" role="alert">
    <i class="fas fa-exclamation-circle mr-2"></i> Você precisa contratar o módulo domínio para usar essa função.
    <br>Entre em contato, através do grupo de suporte!
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [ngClass]="{'needs-validation': !frmCardapio.submitted, 'was-validated': frmCardapio.submitted}"
        novalidate #frmCardapio="ngForm" (ngSubmit)="onSubmitCardapio()">


    <div *ngIf="exibirSalvar" class="mb-2 alert alert-blue">

      <b>Imagem está correta?</b>
      <button type="submit" class="btn btn-primary waves-effect waves-light  ml-2" [disabled]="salvando">Confirmar</button>
      <button type="button" class="btn btn-light  waves-effect waves-light  ml-2" (click)="canceleImagem()">Voltar</button>

    </div>



    <div class="alert alert-success alert-dismissible fade show mb-2" *ngIf="mensagemSucesso" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>



    <app-upload-imagem [objeto]="empresa" [label]="'Imagem Favicon Do Site'" [prop]="'favicon'"
                       [restricoes]="{allowedExtensions: ['.ico']}" [exibirImagem]="false"
                       [desabilitado]="!possuiModuloDominioProprio"
                       (onEnviou)="trocouImagem($event)"></app-upload-imagem>
  </form>


</div>
