<div class="row">
  <div class="col">
    <button class="btn btn-blue" (click)="novoPedido()">Abrir mesa</button>

    <kendo-textbox class="busca" name="filtro" placeholder="Para buscar uma mesa só digitar aqui" [(ngModel)]="filtro" [showSuccessIcon]="false" style="">
        <ng-template kendoTextBoxSuffixTemplate>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton [look]="'clear'" [icon]="'search'"></button>
        </ng-template>
    </kendo-textbox>
  </div>
</div>

<div class="row mt-4" *ngIf="carregando">
  <div class="col text-center">
    <i class="k-icon k-i-loading mr-1 h1"></i>
  </div>
</div>

<div class="row mt-3" *ngIf="!carregando">
  <div class="col-6 col-sm-auto" *ngFor="let comanda of comandas | filtroObjetosNome: filtro">
    <div class="alert alert-warning mt-2" *ngIf="comanda === -1">
      <h4>
        <i class="fas fa-exclamation-triangle"></i>
        Nenhuma comanda encontrada para <strong>{{filtro}}</strong>
      </h4>
    </div>

    <div class="card" *ngIf="comanda !== -1">
      <div class="card-header" [class.disponivel]="comanda.mesa.disponivel" [class.indisponivel]="!comanda.mesa.disponivel">
        <h4 class="mt-0 mb-0 float-left">
          <b>{{comanda.mesa.nome}}</b>
        </h4>
        <div class="float-right" *ngIf="comanda.mesa.totalNovo >  0">
              <span class="badge badge-danger rounded-circle noti-icon-badge  ml-2" >
            {{comanda.mesa.totalNovo}}</span>
            {{comanda.mesa.totalNovo > 1 ? 'novos pedidos' : 'novo pedido'}}
        </div>


      </div>
      <div class="card-body d-flex align-items-center" style="min-height: 150px;" [class.justify-content-center]="comanda.mesa.disponivel">
        <div class="row no-gutters comanda">
          <div class="col">
            <div class="" *ngIf="!comanda.mesa.disponivel">
              <p class="card-title ">
                <span class="text-black-50 d-none d-sm-inline" style="font-weight: normal">Total:</span>
                <strong class="h4"> {{comanda.valor | currency: 'BRL'}}</strong>
              </p>
              <p class="card-title d-none d-sm-block"><span class="text-black-50" style="font-weight: normal"><i class="far fa-clock"></i></span> {{comanda.tempo}}</p>
              <p class="card-text botoes d-none d-sm-block">
                <button class="btn btn-primary btn-sm mt-1" download (click)="verDetalhes(comanda)">Detalhes</button>

                <button class="btn btn-blue btn-sm mt-1 ml-1" download (click)="novoPedidoMesa(comanda)">Novo Pedido</button>
              </p>
              <div class="row d-sm-none">
                <p class="card-text botoes ">
                  <button class="btn btn-primary btn-sm mt-1" download (click)="verDetalhes(comanda)"><i class="fas fa-info-circle fa-lg"></i></button>

                  <button class="btn btn-blue btn-sm mt-1 ml-1" download (click)="novoPedidoMesa(comanda)"><i class="fas fa-cart-plus fa-lg"></i></button>


                </p>
              </div>


              <div *ngIf="comanda.errosNotificacao?.length" class="card-text ">
                <h5 class="text-danger"> <i class="k-icon k-i-error mr-1"></i>Sistema parceiro tentou fechar essa comanda, mas houve erro:</h5>
                <p class="text-muted mb-0" *ngFor="let notificacaoErro of comanda.errosNotificacao">
                 <i>{{notificacaoErro.horario | date: 'dd/MM/yyyy hh:mm'}}</i> - {{notificacaoErro.erro}}</p>

                <button class="btn btn-danger btn-sm mt-1 " (click)="tenteNovamenteExecutarNotificacao(comanda)" [disabled]="comanda.executando">
                  <i class="k-icon k-i-loading mr-1" *ngIf="comanda.executando"></i>
                  Tentar novamente</button>
              </div>

            </div>
            <div class="" *ngIf="comanda.mesa.disponivel">
              <p>Mesa disponível</p>
              <button class="btn btn-blue btn-sm" download>Novo Pedido</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="comandas.length === 0">
    <div class="col-12">
      <div class="alert alert-warning">
        <h4>
          <i class="fas fa-exclamation-triangle"></i>
          Você não tem comandas em aberto.
        </h4>
      </div>
    </div>
  </div>
</div>
