<div>
  <h4 class="page-title">
    <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    &nbsp; <i class="fe-user "></i> Enviar Mensagens </h4>

  <p>
    Você está pronto para enviar sua campanha? Clique no botão Iniciar Envio para começar.
  </p>

  <h4>Campanha: {{campanha.nome}}</h4>

  <ng-container *ngIf="!carregou">
    <div style="text-align: center;" class="mt-5">
    <i class="k-icon k-i-loading" style="font-size: 48px;"></i>
    </div>
  </ng-container>

  <ng-container *ngIf="carregou">
    <div class="card" *ngIf="contatos.length === 0" style="max-height: 500px;overflow: auto;border: solid 1px #e2e2e2;">
      <div class="card-body">
        <h3>
          <i class="fas fa-exclamation-triangle"></i> Não há contatos para enviar mensagens. Verifique os filtros da campanha.
        </h3>

        A seguinte configuração pode estar influenciando:
        <div *ngIf="campanha.naoEnviarMsgParaQuemRecebeuRecente" class="mb-1">
          <div class="alert alert-danger">
        <span class="font-14"><strong>Não enviar mensagem</strong> para contatos que receberam mensagem nos
          últimos <strong>{{campanha.qtdeDiasUltimaNotificacao}} dia(s).</strong></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card contatos" *ngIf="contatos.length > 0" style="max-height: 500px;overflow: auto;border: solid 1px #e2e2e2;">
      <div *ngFor="let contato of contatos" class="card-box contato">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div class="row">
              <div class="col-2 d-flex align-items-center" style="padding-left: 5px;top: -5px;">
                <i class="fas fa-paper-plane" style="font-size: 42px;"></i>
              </div>
              <div class="col">
                <card-contato [contato]="contato"></card-contato>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="card-box text-center" style="padding: 0.5em;">
              <div class="row">
                <div class="col-4">
                  <span class="text-black font24"> {{getCartao(contato) != null ? getCartao(contato).pontos : '-'}}</span><br>
                  <label style="    top: -8px;">{{getCartao(contato) != null ? getCartao(contato).plano.tipoDeAcumulo: ''}}</label>
                </div>

                <div class="col-8">
                  <span class="text-black"  > {{ contato.ultimaVisita | date: 'dd/MM/yyyy HH:mm'}}</span><br>
                  <label>Última visita</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-5 col-md-4 col-12">
            <div>
              <div class="row">
                <div class="fa-pull-left botoes"  >
                  <p class="text-black">
                    <strong [innerHTML]="contato.mensagemProcessada">
                    </strong>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex align-content-center mt-3 mx-auto pb-3" *ngIf="contatos.length > 0 && !enviando">
      <div class="card w-100">
        <div class="card-body">
          <h4>Total de Mensagens: <strong>{{totalContatos}}</strong></h4>
          <ng-container  *ngIf="(totalContatos - saldo.qtde) > 0">
            <div class="alert alert-warning" role="alert">
              Seu saldo atual é {{saldo.qtde}}. Você não tem saldo suficiente para enviar esse campanha.
              <strong>Será cobrado R$ 0,09 por mensagem excedente na próxima fatura</strong>.
            </div>
            <h4>Mensagens excedentes: <strong>{{totalContatos - saldo.qtde}}</strong></h4>
            <h4>Valor: <strong>{{(totalContatos - saldo.qtde) * 0.09 | currency: 'BRL'}}</strong></h4>
          </ng-container>
        </div>
      </div>

      <div class="w-100">
        <div class="alert alert-warning" role="alert" *ngIf="campanha.tipoDeEnvio === 'Agendado'">
          <i class="fa fa-exclamation-triangle mr-2"></i> Campanha Agendada é enviada automaticamente no horário programado.
          <b>{{campanha.horarioEnvio | date: 'dd/MM/yyyy HH:mm:SS'}}</b>
        </div>
      </div>

      <div class="w-100">
        <div class="row mt-2" *ngIf="campanha.statusAprovacao !== 'Aprovada' && campanha.foiTestada">
          <div class="col">
            <div class="alert alert-warning">
              <p>
                <strong>Atenção!</strong>
              </p>

              Campanha foi enviada para aprovação. Entre em contato com o suporte via <b>Whatsapp</b> para acelerar a aprovação.
            </div>
          </div>
        </div>
        <button class="btn btn-lg btn-primary mr-2" (click)="iniciarEnvio()" *ngIf="campanha.foiTestada && campanha.statusAprovacao === 'Aprovada'">
          Iniciar Envio
        </button>

        <button class="btn btn-lg btn-dark" (click)="testarEnvio(tplTestarEnvio)">
          Testar Campanha
        </button>
      </div>
    </div>

    <div class="contatos row" *ngIf="enviando">
      <div class="col-12">
        <div *ngIf="!tarefa.terminou">
          <h3 class="mt-0">Progresso do Envio</h3>
          <h4 class="mt-3">Mensagens Enviadas: {{tarefa.qtdeContatosProcessados}} / {{tarefa.totalContatos}}</h4>
          <kendo-progressbar [value]="progresso" [label]="{visible: true,format: 'percent',position: 'start'}"
                             class="form-control" [progressCssStyle]="{background: '#6DB31B'}"
                             style="line-height: 32px;border: solid 1px #d4edda;"></kendo-progressbar>
          <h5>Enviando mensagem para:</h5>
          <div class="card-box contato mt-2" *ngIf="contatoAtual != null;">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="row">
                  <div class="col-2 d-flex align-items-center" style="padding-left: 5px;top: -5px;">
                    <i class="fas fa-check-double" style="font-size: 42px;color: #6EFF67;"></i>
                  </div>
                  <div class="col">
                    <card-contato [contato]="contatoAtual"></card-contato>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                <div class="card-box text-center" style="padding: 0.5em;">
                  <div class="row">
                    <div class="col-4">
                      <span class="text-black font24"> {{contatoAtual.cartao.pontos}}</span><br>
                      <label style="    top: -8px;">{{contatoAtual.cartao.plano.tipoDeAcumulo}}</label>
                    </div>

                    <div class="col-8">
                      <span class="text-black"  > {{contatoAtual.ultimaVisita | date: 'dd/MM/yyyy HH:mm'}}</span><br>
                      <label>Última visita</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-5 col-md-4 col-12">
                <div>
                  <div class="row">
                    <div class="fa-pull-left botoes"  >
                      <p class="text-black">
                        <strong [innerHTML]="contatoAtual.mensagemProcessada">
                        </strong>
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-box" *ngIf="tarefa.terminou">
          <h3 style="color: #155724;">
            <i class="far fa-check-circle" style="font-size: 40px;vertical-align: middle"></i>
            <span style="color: #155724;margin-left: 10px;">Mensagens Enviadas</span>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="k-overlay" *ngIf="window != null"></div>

<ng-template #tplTestarEnvio>
  <div *ngIf="foiTestada">
    <div class="d-flex justify-content-center">
      <h4>Campanha foi testada!</h4>
    </div>
    <div class="d-flex mt-2">
      <button type="button" class="btn btn-block btn-primary" (click)="fecheModalFiltro()">Fechar</button>
    </div>

    <div class="mt-2"></div>
  </div>
  <form class="k-form" (ngSubmit)="envieMensagemTeste(txt)" *ngIf="!foiTestada && !checarSeDeuCerto">
    <label class="k-form-field">
      <span>Número de Teste</span>
      <input type="text" class="form-control" autocomplete="off"
             name="celularTeste"     mask="(00) 0-0000-0000"   #txt
             placeholder="(__)_________"     appAutoFocus [autoFocus]="true">
    </label>

    <p class="text-danger" *ngIf="erroSalvarFiltro">
      <b>{{erroSalvarFiltro}}</b>
    </p>

    <div class="text-right mt-4">
      <button class="btn btn-primary">Enviar Mensagem Teste</button>
      <button type="button" class="btn btn-secondary ml-2" (click)="fecheModalFiltro()">Fechar</button>
    </div>
  </form>
  <form class="k-form" (ngSubmit)="marqueCampanhaComoEnviada()" *ngIf="!foiTestada && checarSeDeuCerto">
    <label class="k-form-field">
      <span>A mensagem de teste enviada está correta?</span>
    </label>

    <div class="text-right mt-4">
      <button class="btn btn-primary">Sim</button>
      <button class="btn btn-secondary ml-2" type="button" (click)="mensagemIncorreta()">Não</button>
      <button type="button" class="btn btn-secondary ml-2" (click)="fecheModalFiltro()">Fechar</button>
    </div>
  </form>
</ng-template>
