import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileRestrictions, RemoveEvent, SelectEvent} from "@progress/kendo-angular-upload";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: "app-upload-imagem",
  templateUrl: './upload-imagem.component.html',
  styleUrls: ['./upload-imagem.component.scss']
})
export class UploadImagemComponent implements OnInit {
  @Input() objeto: any = {} ;
  @Input() desabilitado = false;
  @Input() label: any =  'Imagem' ;
  @Input() prop = 'linkImagem';
  @Input() lg ;
  @Input() sm ;
  @Input() maxsize ;
  @Output() onEnviou = new EventEmitter();
  @Output() onSalvar = new EventEmitter();
  uploadUrl: any = '/upload/imagem';
  uploadRemoveUrl: any;
  public imagePreview: any;
  @Input() restricoes:  FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png']
  };
  files: any = [];
  editarImagem = false;
  backupImagem: string;
  fezUpload: boolean;
  erroSalvar: string;
  erroUpload: string;
  salvando: boolean;
  @Input()
  menor = false;
  @Input()
  exibirImagem = true;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.fezUpload = false;
    if(this.maxsize)
      this.uploadUrl = String(`${this.uploadUrl}/${this.maxsize}`)
  }

  selecionouArquivo(e: SelectEvent){
    e.files.forEach((file) => {
      console.log(`File selected: ${file.name}`);
      if (!file.validationErrors) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };
          this.imagePreview = image;
        };
        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  successUpload(e: any){
    this.backupImagem =  this.objeto[this.prop];
    this.objeto[this.prop] = e.response.body.data.file;
    this.editarImagem = false;
    this.fezUpload = true;
    this.onEnviou.emit(this.getLinkImagem());
  }

  canceleImagem(){
    this.objeto[this.prop] = this.backupImagem;
    this.files = [];

  }

  canceleSalvar(){
    this.canceleImagem();
    this.finalizeSalvar();
  }

  finalizeSalvar(){
    this.fezUpload = false;
    this.editarImagem = false;
    delete this.erroSalvar;
  }


  public removeEventHandler(e: RemoveEvent): void {
    console.log(`Removing ${e.files[0].name}`);
    delete  this.imagePreview;
  }

  getLinkImagem() {
    return this.objeto[this.prop]
  }

  obtenhaUrlPdf() {
    const url = '/images/empresa/' + this.getLinkImagem();

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ehImagem() {
    const arquivo = this.objeto[this.prop];

    if( arquivo.endsWith('.jpg') || arquivo.endsWith('.jpeg') || arquivo.endsWith('.png') ) {
      return true;
    }

    return false;
  }

  exibirSalvar(){
    return this.objeto.id && this.fezUpload && this.temAcaoSalvar()
  }

  temAcaoSalvar() {
    return this.onSalvar.observers.length
  }

  salveImagem() {
    this.salvando = true;
    this.onSalvar.emit( (erro) => {
      this.salvando = false;
      if(!erro){
        this.finalizeSalvar();
      } else {
        this.erroSalvar = erro;
      }

    })
  }

  onErroUpload($event: any) {
    console.log($event)
    if($event.response && $event.response.error)
      alert($event.response.error)

  }
}
