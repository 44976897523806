import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerService } from './ServerService';

@Injectable({
  providedIn: 'root'
})
export class FormasDeEntregaService extends ServerService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  carregue(): Observable<any> {
    return this.http
      .get(`/formasDeEntrega/alcances`);
  }

  salveAlcance(alcance: any) {
    return this.salve('/formasDeEntrega', alcance);
  }

  removaAlcance(alcance: any) {
    return this.remova('/formasDeEntrega/' + alcance.id, {});
  }
}
