import {Injectable} from "@angular/core";
import {ServerService} from "../../services/ServerService";
import {HttpClient} from "@angular/common/http";
import {FiltroPedidoUltis} from "../../services/FiltroPedidoUltis";
import {EnumFiltroDePedidos} from "../../services/pedidos.service";
import {IOperacoesModuloPedidos} from "./ioperacoesmodulopedidos";

@Injectable({
  providedIn: 'root'
})
export class GrupolojasService extends  ServerService implements IOperacoesModuloPedidos{
  constructor(http: HttpClient) {
    super(http)
  }

  logado(){
    return this.obtenha('/api/grupolojas/me' , {});
  }

  listePedidos(inicio: number, total: number, filtro: any = {},
               filtroPedido: EnumFiltroDePedidos = EnumFiltroDePedidos.todos){

    let dados: any = FiltroPedidoUltis.obtenhaDados(inicio, total, filtro, filtroPedido);

    return this.obtenha('/grupolojas/pedidos', dados);
  }

  listePedidosCancelados(inicio: number, total: number, contato: any = null,  dtInicio: string = null, dtFim: string= null,
                         filtro: EnumFiltroDePedidos = EnumFiltroDePedidos.todos, q: string){
    let dados: any = { i: inicio, t: total, cancelados: true};

    if(contato) dados.cid = contato.id;
    if(dtInicio) dados.di = dtInicio;
    if(dtFim) dados.df = dtFim;
    dados.q = q;
    dados.f = filtro;

    return this.obtenha('/grupolojas/pedidos', dados);
  }

  obtenhaPorCodigo(codigo: any, idEmpresa: any) {
    return this.obtenha(String(`/grupolojas/pedidos/${idEmpresa}/${codigo}`), {})
  }

  foiVisualizado(pedido: any, idEmpresa: any){
    return this.salve(String(`/grupolojas/pedidos/visualizado/${idEmpresa}/${pedido.id}`), pedido)
  }

  obtenhaPorGuid(codigo: any,  idEmpresa: any ) {
    return this.obtenha(String(`/grupolojas/pedidos/chave/${idEmpresa}/${codigo}`), {  });
  }


  listeUltimoPedidosAtras(tempo: number, origens = []){
    return this.obtenha('/grupolojas/pedidos/ultimo/' + tempo, { o: origens.join(',')});
  }

  altereEntregador(pedido: any, entregador: any) {
    let params: any = { guid: pedido.guid, entregador: entregador};

    return this.facaPut(`/grupolojas/pedidos/' + pedido.guid + '/entregador/${pedido.empresa.id}/${pedido.id}`, params)
  }


  altereStatus(pedido: any, status: any, notificar: boolean){
    let params: any = { id: pedido.id, status: status};

    if(notificar) params.notificar = true;

    return this.salve(String(`/grupolojas/pedidos/status/${pedido.empresa.id}/${pedido.id}`), params)
  }

  cancelePedido(pedido: any, motivo: any, notificar: boolean){
    let params: any = { guid: pedido.guid, motivo: motivo};

    if(notificar) params.notificar = true;

    return this.facaPut(String(`/grupolojas/pedidos/cancele/${pedido.empresa.id}/${pedido.id}`), params)
  }

  altereFoiPago(pedido: any, foiPago: boolean, formaDePagamento: any){
    return this.salve(String(`/grupolojas/pedidos/pago/${pedido.empresa.id}/${pedido.id}`),
      { id: pedido.id, pago: foiPago, formaDePagamento: formaDePagamento})
  }

  notifiqueSistemaIntegrado(pedido: any){
    return this.facaPost(String(`/grupolojas/pedidos/integracao/notifique/${pedido.empresa.id}/${pedido.id}`), { id: pedido.id});
  }


}
