<div class="card">
  <div class="card-body">
    <div>
  <div *ngIf="campanha.tipoDeEnvio === 'Unico'">
    <i class="fab fa-whatsapp"
       style="font-size: 52px;line-height: 2em;float: left;"></i>
    <span class="badge badge-pill badge-success" *ngIf="campanha.campanhaRede">
                        Campanha da Rede
                      </span>
  </div>
  <div *ngIf="campanha.tipoDeEnvio === 'Agendado'">
    <i class="far fa-clock mr-2" style="font-size: 52px;line-height: 2em;float: left;"></i>
  </div>
  <div class="row">
    <div class="col-7">
      <div class="row">
        <div class="col-auto" *ngIf="campanha.linkImagem">
          <img class="img-fluid rounded" [src]="'/images/empresa/' + campanha.linkImagem" style="width: 200px"/>
        </div>
        <div class="col">
          <h3>
            <a [href]="'https://' + campanha.empresa.dominio + '.promokit.com.br/admin/nova-campanha/' + campanha.id" target="_blank">
            {{campanha.id}}. {{campanha.nome}}
            </a>
          </h3>
          <p>
            <span class="font-16">Por {{campanha.origemContatos}} <strong>{{campanha.filtro?.nome}}</strong></span>
          </p>
          <p><i>{{campanha.mensagem}}</i></p>
        </div>
      </div>

      <div class="mt-1">
        <span class="badge badge-pill badge-success" *ngIf="campanha.status === 'Nova' && campanha.status !== 'Nova'"> <i class="fe-user"></i> Nova</span>
        <span class="badge badge-pill badge-secondary" *ngIf="campanha.status === 'Enviada'"> <i class="fe-user"></i>Enviada</span>
        <span class="badge badge-pill badge-warning" *ngIf="campanha.status === 'DEV'"> <i class="fe-user"></i>Enviando</span>


        <div *ngIf="!campanha.foiTestada && campanha.tipoDeEnvio === 'Agendado' && campanha.status === 'Nova'">
                      <span class="badge badge-pill badge-danger">
                        Campanha agendada deve ser testada pra ser enviada.
                      </span>

          <button class="ml-2 btn btn-sm btn-secondary"
                  (click)="testarCampanha(campanha);">Testar Campanha</button>
        </div>

        <span class="badge badge-pill badge-warning" *ngIf="campanha.foiTestada && campanha.tipoDeEnvio === 'Agendado' && campanha.status === 'Nova'">
                      <i class="far fa-clock" ></i>
          {{campanha.horario}}
            </span>
      </div>

      <p class="mt-1 mb-2 font-18" title="Data de criação da campanha">
        <i class="fas fa-calendar-day mr-1"></i> <b>{{campanha.dataCriacao | date: 'dd/MM/yyyy HH:mm:SS'}}</b>
      </p>

      <p class="mt-2 mb-1" *ngIf="campanha.status === 'Nova' && campanha.tipoDeEnvio === 'Agendado'">
        Horário Envio: <b>{{campanha.horarioEnvio | date: 'dd/MM/yyyy HH:mm:SS'}}</b>
      </p>
      <p *ngIf="campanha.limitarQtdeContatos" class="mb-1">
        <span class="font-14">Qtde Mensagens:
          <span class="font-20"><strong>{{campanha.qtdeContatosEnviarMsg}}</strong> contatos</span>
        </span>
      </p>
      <div *ngIf="campanha.naoEnviarMsgParaQuemRecebeuRecente" class="mb-1">
        <div class="alert alert-danger">
        <span class="font-14"><strong>Não enviar mensagem</strong> para contatos que receberam mensagem nos
          últimos <strong>{{campanha.qtdeDiasUltimaNotificacao}} dia(s).</strong></span>
        </div>
      </div>
      <p class="mt-1">
        <span class="font-16">Status Aprovação: <strong>{{campanha.statusAprovacao}}</strong></span>
      </p>

      <div class="row mt-2" *ngIf="!campanha.foiTestada && !modoAprovacao">
        <div class="alert alert-primary">
          <p>
            <strong>Importante!</strong>
          </p>
          Você deve testar a campanha para que ela seja enviada.
        </div>
      </div>
      <div class="row mt-2" *ngIf="campanha.statusAprovacao === 'Pendente' && !modoAprovacao">
        <div class="col">
          <div class="alert alert-warning">
            <p>
              <strong>Atenção!</strong>
            </p>

            Campanha foi enviada para aprovação. Entre em contato com o suporte via <b>Whatsapp</b> para acelerar a aprovação.
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="row d-flex align-items-center" style="height: 120px;">
        <div class="col">
          <h1 class="mb-0">{{campanha.qtdeEnviadas  }}</h1>
          <p>enviados</p>
        </div>
        <div class="col">
          <h1 class="mb-0">{{campanha.qtdeMensagens }}</h1>
          <p>total</p>
        </div>
        <!--
        <div class="col">
          <h1 class="mb-0">{{campanha.qtdeLidas }}</h1>
          <p>clientes clicaram</p>
        </div>
        -->
        <div class="col acoes" *ngIf="!modoAprovacao">
          <div class="row">
            <span class="badge badge-pill badge-success mb-2" [ngClass]="{'badge-danger': campanha.status === 'Cancelada'}">{{campanha.status}}</span>
            <div class="col" *ngIf="campanha.status === 'Rejeitada'">
              <div class="alert alert-warning" role="alert">
                <span class="msg">
                  Você não aceitou a campanha.
                </span>
              </div>|
            </div>
            <div class="col" *ngIf="campanha.status === 'Aceitar'">
              <ng-container *ngIf="campanha.campanhaRede">
                <ng-container *ngIf="campanha.campanhaRede.ativa">
                  <span class="">Deseja aceitar a campanha criada pela Rede?</span>
                  <button class="btn btn-blue btn-block" (click)="aceitarCampanhaRede(campanha)" [disabled]="aceitandoCampanhaRede">Sim</button>
                  <button class="btn btn-danger btn-block"  (click)="rejeitarCampanhaRede(campanha)" [disabled]="aceitandoCampanhaRede">Não</button>
                </ng-container>
                <ng-container *ngIf="!campanha.campanhaRede.ativa">
                  <div class="alert alert-warning" role="alert">
                <span class="msg">
                  Campanha não foi enviada. Motivo: Não foi aceita e o prazo limite já passou!
                </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <ng-container *ngIf="!campanha.campanhaRede || (campanha.campanhaRede && usuarioLogado.admin)">
              <button class="btn btn-blue btn-block"  (click)="editarCampanha(campanha)" *ngIf="campanha.status === 'Nova' || campanha.status === 'Enviando' || campanha.status === 'ParouEnvio'">Editar Campanha</button>
              <button class="btn btn-primary btn-block"   (click)="irParaTelaTestarCampanha(campanha)" *ngIf="campanha.status === 'Nova' && campanha.statusAprovacao !== 'Aprovada'">Testar Campanha</button>
              <button class="btn btn-primary btn-block"   (click)="irParaTelaEnviarCampanha(campanha)" *ngIf="campanha.status === 'Nova' && campanha.statusAprovacao === 'Aprovada'">Enviar Campanha</button>
              <button class="btn btn-success btn-block"   (click)="verDetalhesCampanha(campanha)" *ngIf="campanha.status === 'Enviada'">Relatório de Mensagens</button>
            </ng-container>
          </div>
        </div>
        <div class="col acoes" *ngIf="modoAprovacao && campanha.statusAprovacao === 'Pendente'">
          <div class="row">
            <button class="btn btn-primary btn-block"  (click)="aprovarCampanha(campanha)"><i class="far fa-thumbs-up"></i> Aprovar</button>
            <button class="btn btn-danger btn-block"   (click)="reprovarCampanha(campanha)"><i class="far fa-thumbs-down"></i> Reprovar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

    <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="mensagem">
        <i class="mdi mdi-check-all mr-2"></i> <span class="msg">
          {{mensagem}}
        </span>
      </div>
      <div class="alert alert-warning" role="alert" *ngIf="mensagemWarning">
        <i class="fa fa-times mr-2"></i> <span class="msg">
          {{mensagemWarning}}
        </span>
      </div>
    </div>
  </div>
  </div>
</div>
