import {Component, Input, OnInit} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";

@Component({
  selector: 'app-razao-cancelamento-ifood',
  templateUrl: './razao-cancelamento-ifood.component.html',
  styleUrls: ['./razao-cancelamento-ifood.component.scss']
})
export class RazaoCancelamentoIfoodComponent implements OnInit {
  @Input() pedido: any;
  razaoCancelamento: any;
  razoesCancelamentoIfood = [];
  erroParaCancelar: string;
  constructor(protected pedidosService: PedidosService) { }

  ngOnInit(): void {  }

  obtenhaRazoesIfood(){
    if(!this.pedido || this.razoesCancelamentoIfood.length) return this.razoesCancelamentoIfood;

    if(!this.erroParaCancelar){
      this.pedidosService.obtenhaRazoesCancelamentoIfood(this.pedido).then((razoes: any) => {
        this.razoesCancelamentoIfood = razoes;
        if(!razoes.length)
          this.erroParaCancelar = 'Pedido não pode ser mais cancelado no ifood'

      }).catch((erro) => {
        this.erroParaCancelar = erro;
      })
    }
  }
}
