<div class="dados-contato"  *ngIf="!exibirEmail">
  <label class="font-15"><b> {{contato?.nome ? contato.nome : contato?.cpf}} </b></label><br>

  <div class="row">
    <div class=" col pr-0"   [ngClass]="{'col-6': exibirUltimaVisita}" >
      <span class="d-block">{{contato?.telefone | tel: contato?.codigoPais }}</span>

      <span *ngIf="contato && contato.quemIndicou != null">
        <span><strong>Indicação: {{contato.quemIndicou.nome }}</strong></span> <br></span>

      <contato-status [contato]="contato"></contato-status>
    </div>
    <div class="col-6 pr-0 pl-0" *ngIf="exibirUltimaVisita">
      <div class="text-center d-block d-sm-none ultima-visita"  >
        <span class="text-black"  > <i class="fa fa-calendar"></i> {{ contato?.ultimaVisita | date: 'dd/MM/yyyy HH:mm'}}</span><br>
        <label class="text-muted font-13 ">Última visita</label>
      </div>
    </div>

  </div>
</div>

<div class="dados-contato" *ngIf="exibirEmail">
  <label class="font-15 d-block" >
    <b> {{contato?.nome ? contato.nome : contato?.cpf}}  </b>
    <contato-status [contato]="contato"></contato-status>
  </label>

  <div class=" pr-0"     >
    <span class="d-block" *ngIf="contato?.telefone">
        <i class="fa-phone fa mr-1"></i>
        {{contato.telefone | tel: contato?.codigoPais }}

     </span>
    <span class="d-block abreviar"  *ngIf="contato?.email" >
          <i class="fe-mail  mr-1"></i>
      {{contato.email  }}
    </span>
  </div>

  <div class="col-12 pr-0 pl-0" *ngIf="exibirEndereco && contato?.enderecos?.length " style="max-height: 135px;overflow-y: scroll;">
    <div id="endereco" class="mt-1" *ngFor="let endereco of contato.enderecos">
        <resumo-endereco [endereco]="endereco"></resumo-endereco>
    </div>
  </div>

  <kendo-chip
    *ngFor="let tag of contato.tags"
    [label]="tag.nome"
    [removable]="true"
    (remove)="onRemoveTag($event)"
  ></kendo-chip>

</div>
