import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerService } from './ServerService';

@Injectable({
  providedIn: 'root'
})
export class ChamadasIaService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  //carrega as chamadas ia do server
  obtenhaChamadasIa(mensagem: string, dia: string, horarioInicio: string, horarioFim: string) {
    return this.obtenha('chamadas-ia/liste', {
      q: mensagem,
      d: dia,
      hi: horarioInicio,
      hf: horarioFim
    });
  }
}
