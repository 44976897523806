import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CampanhaService} from "../services/campanha.service";
declare let moment;

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  campanhas = [];
  total = 0;
  tiposDeEnvio = [
    {nome: 'Todas Campanhas', id: 'todos'},
    {nome: 'Recorrentes', id: 'recorrentes'},
    {nome: 'Enviar Uma Vez', id: 'envio-unico'},
    {nome: 'Desativadas', id: 'desativadas'}
  ];
  opcoesDeFiltro = [
    'todos',
    'recorrentes',
    'envio-unico',
    'desativadas'
    ];
  filtroSelecionado: any = 'todos';
  carregando = false;

  constructor(private router: Router, private campanhaService: CampanhaService,
              private _ngZone: NgZone) {
  }
  ngOnInit() {
    this.obtenhaCampanhas();
  }

  obtenhaCampanhas() {
    this.carregando = true;
    this.campanhas = [];

    this.campanhaService.obtenhaCampanhas(this.filtroSelecionado).then( (resposta: any) => {
      this.carregando = false;
      this.campanhas = resposta.campanhas;
      this.total = resposta.total;

      this.campanhas.forEach( ( async (campanha: any) => {
        const diferenca = moment(campanha.horarioEnvio).diff(moment());

        if( diferenca < 0 ) {
          campanha.horario = 'Deveria ter sido enviada';
        } else {
          campanha.horario = 'Será enviada ' + moment.duration(diferenca).humanize(true);
        }
        //TODO: Alterar a forma de calcular a qtde de mensagens lidas
        /*
        let qtdeLidas =  await this.campanhaService.obtenhaQtdeLidas(campanha);
        this._ngZone.run(() => {
          campanha.qtdeLidas = qtdeLidas;
        });
         */
      }));
    }).catch( (erro: any) => {
      this.carregando = false;
    });
  }

  novaCampanha() {
    this.router.navigateByUrl( '/admin/nova-campanha').then( () => {});
  }

  selecionouTipoDeCampanha($event: any) {

    if( $event.title === 'Todas Campanhas' ) {
      this.filtroSelecionado = this.opcoesDeFiltro[0];
    } else if( $event.title === 'Recorrentes' ) {
      this.filtroSelecionado = this.opcoesDeFiltro[1];
    } else if( $event.title === 'Enviar Uma Vez') {
      this.filtroSelecionado = this.opcoesDeFiltro[2];
    }

    this.obtenhaCampanhas();
  }
}
