<div class="dashboard-container">
  <h1>Dashboard Mia</h1>

  <div class="card">
    <h4 class="p-2">Intents e Frequências</h4>
    <div style="height: 400px;max-width: 1000px;">
      <kendo-chart>
        <kendo-chart-legend position="left"></kendo-chart-legend>
        <kendo-chart-series>
          <kendo-chart-series-item type="pie" [data]="intentsFrequenciaPercentual" field="percentual" categoryField="intent"
          [labels]="{ visible: true, content: labelContent }">
          <kendo-chart-series-item-tooltip>
            <ng-template let-item="dataItem">
              Intent: {{ item.intent }}<br>
              Valor: {{ item.percentual | percent:'1.2-2' }}
            </ng-template>
          </kendo-chart-series-item-tooltip>
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
  </div>

  <div class="card">
    <h4 class="p-2">Mensagens, Intents e Frequências</h4>
    <div style="max-width: 800px;">
      <kendo-grid [data]="intentsFrequenciaPercentual"
                  (detailExpand)="abrirRespostasMia($event.dataItem, $event.dataItem.intent)">
        <kendo-grid-column field="intent" title="Intent">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.intent }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="frequencia" title="Frequência"></kendo-grid-column>
        <kendo-grid-column field="percentual" title="Percentual" format="{0:p2}"></kendo-grid-column>

        <ng-template kendoGridDetailTemplate let-dataItem>
          <div class="respostas-container">
            <h5>Respostas da Mia para o intent: <strong>{{dataItem.intent}}</strong></h5>
            <kendo-listview [kendoListViewBinding]="dataItem.respostas" *ngIf="dataItem.respostas"
                            [pageSize]="pageSize"
                            [skip]="dataItem.skip"
                            [pageable]="true"
                            (pageChange)="onPageChange($event)">
              <ng-template kendoListViewItemTemplate let-resposta="dataItem" let-index="index">
                <div class="listview-item p-1">
                  <div class="item-content">
                    <div class="card mb-1">
                      <div class="card-header bg-light">
                        <strong>{{ resposta.sequencia }}. <b>Cliente: </b>{{ resposta.mensagem }}</strong>
                        <small class="text-muted ml-2">{{ resposta.dataCriacao | date:'dd/MM/yyyy' }} {{ resposta.horarioCriacao }}</small>
                      </div>
                      <div class="card-body p-2">
                        <p class="card-text"><b>Mia:</b> {{ resposta.resposta }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-listview>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </div>


</div>
