import {Component, OnInit, ViewChild} from '@angular/core';
import {GridComponent, GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {AvaliacaoService} from "../../services/avaliacao.service";

@Component({
  selector: 'app-tela-lista-avaliacoes',
  templateUrl: './tela-lista-avaliacoes.component.html',
  styleUrls: ['./tela-lista-avaliacoes.component.scss']
})
export class TelaListaAvaliacoesComponent implements OnInit {
  avaliacoes: any;
  total: any;
  loading = false;
  gridDataResult: GridDataResult;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = false;
  public previousNext = true;
  page = 0;
  tamanhoPagina = 10;
  @ViewChild('grid', {static: true})
  public grid: GridComponent;
  modoGrupoLojas = false;
  notaMediaUltimos30Dias: any = {
    qtde: 0,
    media: 0,
  };
  notaGeral: any = {
    qtde: 0,
    media: 0,
  };

  constructor(private avaliacaoService: AvaliacaoService) { }

  ngOnInit(): void {
    this.carregue({skip: 0} as PageChangeEvent);
  }

  carregue($event: PageChangeEvent) {
    this.page = $event.skip;
    this.loading = true;

    this.loading = true;
    this.avaliacaoService.liste(this.page * this.tamanhoPagina, this.page).then( (respAvaliacoes) => {
      this.loading = false;
      if (!respAvaliacoes) {
        return
      }

      this.notaGeral = respAvaliacoes.notaMediaGeral;
      this.notaMediaUltimos30Dias = respAvaliacoes.notaMediaUltimos30Dias;
      this.avaliacoes = respAvaliacoes.avaliacoes;
      this.total = respAvaliacoes.total;

      this.gridDataResult = {
        data: this.avaliacoes,
        total: this.total
      };
    });
  }
}
