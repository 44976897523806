import { Component, OnInit } from '@angular/core';
import {LabelSettings} from "@progress/kendo-angular-progressbar";
import {IntegracaoERPService} from "../../services/integracaoerp.service";
import {ProdutoService} from "../../services/produto.service";
import {GroupDescriptor, process} from "@progress/kendo-data-query";
import {timer} from "rxjs";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-tela-sincronizar-estoque',
  templateUrl: './tela-sincronizar-estoque.component.html',
  styleUrls: ['./tela-sincronizar-estoque.component.scss']
})
export class TelaSincronizarEstoqueComponent extends  ModalKendo implements OnInit {
  empresa: any = {};
  produtosSincronizar: any = [];
  produtosSincronizarFiltro: any = [];
  buscando: boolean
  tudoSincronizado: boolean
  sincronizado: boolean
  erro: any;
  salvando = false;
  filtro: string;
  percentualSalvos: any = 0;
  public label: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center'
  };
  emptyStyles: {[key: string]: any} = {  background: '' };
  progressStyles: {[key: string]: any} = {}
  sincronizouSemErros: boolean;
  timerImportacao: any;
  ultimaSincronizacao: any;
  constructor(private integracaoERPService: IntegracaoERPService,
              private produtoService: ProdutoService) {
    super()
  }

  ngOnInit(): void {
    this.ultimaSincronizacao = this.empresa.integracaoDelivery.ultimaSincronizacaoEstoque;
    this.carregueEstoqueNovosAsync();
  }

  async carregueEstoqueNovosAsync(todos = false){
    this.buscando = true;

    this.integracaoERPService.inicieSincronizarEstoqueAsync(todos).then(
      () => {
        this.timerImportacao =  timer(2000, 1000 * 5  ).subscribe( () => {
          this.verifqueStatusImportacao();
        });
      }
    ).catch( erro => {
      this.erro = erro
      this.buscando = false;
    })

  }


  verifqueStatusImportacao(){
    this.integracaoERPService.obtenhaStatusImportacao().then( (resposta) => {
      if(resposta.processando) return;
      this.pareImportacao(resposta.erro);

      this.produtosSincronizar = resposta;
      this.produtosSincronizarFiltro = [...this.produtosSincronizar]

      if(!this.produtosSincronizar.length )
        this.tudoSincronizado = true;

    }).catch( erro => {
      this.pareImportacao(erro)
    })
  }

  pareImportacao(erro){
    if(erro)
      this.erro = erro
    this.buscando = false;
    this.timerImportacao.unsubscribe();
  }

  rowCallback = (args) => ({

    'hide-row':  !args.dataItem.salvando
  });

  async salvarDisponiblidades(){
    this.salvando = true;
    this.progressStyles = {   color: '#fff',  background: '#1050bb' };
    this.sincronizouSemErros = true;
    for(let i = 0; i <  this.produtosSincronizar.length ; i++){
      let produto = this.produtosSincronizar[i];

      this.produtosSincronizarFiltro = [produto]
      await this.salveEstoque(produto)
      this.percentualSalvos = Math.floor(( (i + 1) / this.produtosSincronizar.length) * 100)
    }

    if( this.produtosSincronizar.find((prod: any) => prod.erro)){
      this.progressStyles.background = '#f1556c';
    } else {
      this.progressStyles.background = '#8EBC00';
    }

    if(this.sincronizouSemErros)
      this.produtoService.finalizouSincronizarEstoque().then(
        (ultimaSincronizacao: any) => {
          this.empresa.integracaoDelivery.ultimaSincronizacaoEstoque = ultimaSincronizacao;
          this.ultimaSincronizacao = ultimaSincronizacao;
        }
      )

    this.produtosSincronizarFiltro = [...this.produtosSincronizar]
    this.salvando = false;
    this.sincronizado = true;
  }

  async salveEstoque(produto){
    produto.salvando = true;
    delete   produto.erro;
    await this.produtoService.atualizeDisponilidadeERP(this.empresa, produto).catch( erro => {
      produto.salvando = false;
      produto.erro = erro;
      this.sincronizouSemErros = false;
    })

    if(!produto.erro){
      produto.salvando = false;
      produto.sincronizado = true;
    }
  }

  fecheModal() {
    super.fecheModal( this.sincronizado )
  }

  onFilter($event: Event) {

  }

  public expandInStockProducts({ dataItem, index }: any): boolean {
    if(this.salvando) return false;

    return  dataItem.opcoesAtualizadas.length > 0 || dataItem.tamanhosAtualizados.length > 0;
  }

  obtenhaAdicionaisAgrupados(produto: any) {
    let groups: GroupDescriptor[] =  [{ field: 'nomeAdicional' }];

    return process(produto.opcoesAtualizadas, { group: groups });
  }

  busqueTodosEstoque() {
    this.tudoSincronizado = false;
    this.carregueEstoqueNovosAsync(true);
  }
}
