<h4 _ngcontent-mki-c5="" class="page-title"><i class="fe-star-on fa-lg" style="color: #f5b106;"></i> Avalições de Pedidos</h4>

<div class="row">
  <div class="col-4 col-xl-3">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted mb-1"> Nota média <span>Geral</span> </h4>
      <h5 class="card-text">
        {{notaGeral.media | number}} <ngb-rating [(rate)]="notaGeral.media" [max]="5" [readonly]="true">
        <ng-template let-fill="fill" let-index="index">
          <span class="star"  [class.filled]="index < notaGeral.media" [class.bad]="notaGeral.media <= 3">&#9733;</span>
        </ng-template>
      </ngb-rating>
      </h5>
      <h6 class="mt-0">
        {{notaGeral.qtdeAvaliacoes}} avaliações
      </h6>
    </div>
  </div>

  <div class="col-4 col-xl-3">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted mb-1"> Nota média <span class="font-14">(últimos 30 dias)</span> </h4>
      <h5 class="card-text">
        {{notaMediaUltimos30Dias.media | number}} <ngb-rating [(rate)]="notaMediaUltimos30Dias.media" [max]="5" [readonly]="true">
        <ng-template let-fill="fill" let-index="index">
          <span class="star"  [class.filled]="index < notaMediaUltimos30Dias.media" [class.bad]="notaMediaUltimos30Dias.media <= 3">&#9733;</span>
        </ng-template>
      </ngb-rating>
      </h5>
      <h6 class="mt-0">
        {{notaMediaUltimos30Dias.qtdeAvaliacoes}} avaliações
      </h6>
    </div>
  </div>
</div>

<kendo-grid #grid [data]="gridDataResult" [pageSize]="this.tamanhoPagina"
            [skip]="page"
            [style]=""
            [loading]="loading" style="min-height: 300px;"
            [scrollable]=""
            footerStyle="font-size: 11px;"
            [pageable]="{
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext
            }"
            (pageChange)="carregue($event)">
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Sem contatos"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column field="status" title="Cliente" class="coluna_menor" width="100px">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
      <strong>
      <a [href]="'/admin/contatos/'+  avaliacao.pedido.cliente.codigoPais + avaliacao.pedido.cliente.telefone">{{avaliacao.pedido.cliente.nome}}</a>
      </strong>
      <br>
      <a [href]="'/admin/contatos/' +  avaliacao.pedido.cliente.codigoPais + avaliacao.pedido.cliente.telefone">
      <i class="fab fa-whatsapp pr-1 alinhamento-icone whatsapp fa-lg" style="color: #82c91e;"></i> <span>{{avaliacao.pedido.cliente.telefone | mask: '(99) 9-9999-9999' }}</span> <br>
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="nome" title="Pedido" class="coluna_menor text-center" width="70px">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
        <a [routerLink]=" (modoGrupoLojas ? ('/admin-grupo/pedidos/detalhes/'+ avaliacao.pedido.empresa.id + '/') : '/admin/pedidos/detalhes/') + avaliacao.pedido.guid"
           [ngClass]=" {'negrito': !avaliacao.pedido?.visualizado }" class="text-blue" >
          #{{avaliacao.pedido.codigo}} </a>
      <span>
        <br>
        <span><b>{{avaliacao.pedido.total | currency: "BRL"}}</b></span>
      </span>

        <span class="d-block badge badge-info mt-1" *ngIf="avaliacao.pedido?.origem">
        {{avaliacao.pedido.origem}}
        </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Data Avaliação"  field="horarioVenda"   media="(min-width: 900px)"  width="80px;">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
      <span [ngClass]=" { 'negrito': !avaliacao.pedido?.visualizado }">
        {{avaliacao.data  | date:  'dd/MM/yy' }} </span> <br>

      <span class="badge" [class]="{'badge-success': avaliacao.tempoAvaliar < 2, 'badge-warning': avaliacao.tempoAvaliar < 7, 'badge-danger': avaliacao.tempoAvaliar > 7}">
        {{avaliacao.tempoAvaliarDescricao.replace('há ', '').replace('em ', '')}} após o pedido
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Data Pedido"  field="horarioVenda"   media="(min-width: 900px)"  width="80px;">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
      <span [ngClass]=" { 'negrito': !avaliacao.pedido?.visualizado }">
        {{avaliacao.pedido.horarioVenda  | date:  'dd/MM/yy HH:mm' }} </span> <br>

      <span class="negrito badge badge-success">
        {{avaliacao.pedido.duracaoDescricao}} atrás
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="nome" title="Nota" class="coluna_menor text-center" width="80px">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
      <ngb-rating [(rate)]="avaliacao.nota" [max]="5" [readonly]="true">
        <ng-template let-fill="fill" let-index="index">
          <span class="star"  [class.filled]="index < avaliacao.nota" [class.bad]="avaliacao.nota <= 3">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="gostouDaEntrega" title="Gostou da Entrega" class="coluna_menor text-center" width="40px">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
      <i class="fas fa-thumbs-up fa-lg text-success" *ngIf="avaliacao.gostouDaEntrega"></i>
      <i class="fas fa-thumbs-down fa-lg text-danger" *ngIf="!avaliacao.gostouDaEntrega"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="id" title="Comentário" class="coluna_menor" width="200px">
    <ng-template kendoGridCellTemplate let-avaliacao let-rowIndex="rowIndex">
      <span class="text-muted">
        "{{avaliacao.comentario}}"
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
