import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";


@Injectable({
  providedIn: 'root'
})
export class FotoService extends ServerService {
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  liste(idEmpresa: number= null) {
    let link =  '/fotos'

    if(idEmpresa)
      link =  '/empresas/' + idEmpresa + "/fotos" ;

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveFoto(foto: any): Promise<any> {
    return this.salve('/fotos', foto );
  }

  salveFotoCategoria(foto: any): Promise<any> {
    return this.salve('/fotos/categoria', foto );
  }

  salveCapaEmpresa(empresa: any) {
    return this.salve('/fotos/empresa/capa', {id: empresa.id, capa: empresa.capa} );
  }

  salveLogoEmpresa(empresa: any) {
    return this.salve('/fotos/empresa/logo', {id: empresa.id, logo: empresa.logo} );
  }

  removaProduto(foto: any) {
    return this.remova( "/fotos/" + foto.id, {})
  }

}
