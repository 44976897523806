import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  selector: '[maximo][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValorMaximoValidator, multi: true }
  ]
})
export class ValorMaximoValidator  implements  Validator {
  @Input() maximo: number;

  constructor() {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    const valor = c.value;

    return ( valor > this.maximo) ? {"minimo": true} : null;
  }
}
