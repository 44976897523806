import {TelaEscolherDominioEmpresaComponent} from "../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component";
import {TelaEscolherCupomCampanhaComponent} from "../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component";
import {DialogService} from "@progress/kendo-angular-dialog";

export class MensagemTextAreaUtils {
  static campos: any = [{
    nome: 'Nome do Contato',
    texto: '[NomeContato]',
  }, {
    nome: 'Nome Completo',
    texto: '[NomeCompletoContato]',
  }, {
    nome: 'Telefone do Contato',
    texto: '[TelefoneContato]',
  }, {
    nome: 'Nome do Cliente',
    texto: '[Nome_Cliente]',
  }, {
    nome: 'Telefone do Cliente',
    texto: '[Telefone_Cliente]',
  }, {
    nome: 'Empresa',
    texto: '[Empresa]',
  }, {
    nome: 'Link Cartão',
    texto: '[LinkCartao]',
  }, {
    nome: 'Codigo',
    texto: '[CODIGO]',
  }, {
    nome: 'Link Ativar Cartão',
    texto: '[LinkAtivarCartao]',
  }, {
    nome: 'Saldo',
    texto: '[SALDO]',
  }, {
    nome: 'Link do Pedido',
    texto: '[LinkPedido]',
  }, {
    nome: 'Dados do Pedido',
    texto: '[DadosPedido]',
  }, {
    nome: 'Link Cardápio',
    texto: '[Link_Cardapio]'
  }, {
    nome: 'Link Avaliar Pedido',
    texto: '[Link_Avaliar]'
  }, {
    nome: 'Link Cupom',
    texto: 'Cupom',
  }, {
    nome: 'Link Outra Empresa',
    texto: 'Link_Cardapio_DARK',
  }, {
    nome: 'Nova Mensagem',
    texto: '[Nova_Mensagem]',
  }, {
    nome: 'Tempo de Entrega',
    texto: '[TempoEntrega]'
  }];
  static adicioneCampo(textArea, mensagem: any, campo: any, dialogService: DialogService, empresa: any) {
    if( campo.texto === 'Link_Cardapio_DARK' ) {
      TelaEscolherDominioEmpresaComponent.abraComoDialog(dialogService, empresa,
        (dominioEmpresa) => {
          this.insiraCampoNaCaixaDeTexto(textArea, mensagem, {
            texto: `[Link_Cardapio_${dominioEmpresa}]`
          });
        });

      return;
    }
    else if( campo.texto === 'Cupom' ) {
      TelaEscolherCupomCampanhaComponent.abraComoDialog(dialogService, empresa, (cupom) => {
        this.insiraCampoNaCaixaDeTexto(textArea, mensagem, {
          texto: `[CUPOM_${cupom}]`
        });
      });

      return;
    }

    this.insiraCampoNaCaixaDeTexto(textArea, mensagem, campo);
  }

  static insiraCampoNaCaixaDeTexto(textArea, template: any, campo: any) {
    const posicao = MensagemTextAreaUtils.getCaretPos(textArea);

    template.mensagem = template.mensagem.substr(0, posicao) + campo.texto + template.mensagem.substr(posicao);

    textArea.focus();

    setTimeout( () => {
      textArea.setSelectionRange(posicao + campo.texto.length, posicao + campo.texto.length);
      textArea.focus();
    }, 50);
  }

  static getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      return oField.selectionStart;
    }

    return 0;
  }
}
