import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-icone-acao',
  templateUrl: './icone-acao.component.html',
  styleUrls: ['./icone-acao.component.scss']
})
export class IconeAcaoComponent implements OnInit {
  @Input() acao: any = {}
  @Input() exibirDescricao = true;
  constructor() { }

  ngOnInit() {
  }

}
