import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ComandosImprimirComanda} from "../pedidos/ComandosImprimirComanda";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {ControleKanbamComponent} from "../controle-kanbam/controle-kanbam.component";
import {RdcrmService} from "../services/rdcrm.service";

export interface Task {
  title: string;
  description: string;
  enderecoCompleto: string;
  formaEntrega: string;
}

@Component({
  selector: 'app-tela-crm-leads',
  templateUrl: './tela-crm-leads.component.html',
  styleUrls: ['./tela-crm-leads.component.scss']
})
export class TelaCrmLeadsComponent implements OnInit {
  @Input() comanda;
  @Input() modoGrupoLojas: any = false;
  @Input() modoSimples = false;
  @Output() onAtualizouComanda = new EventEmitter();
  entregadores: any = [];
  todo: any[] = [];
  @ViewChild('ctlKanban', { static: true }) ctlKanban: ControleKanbamComponent;
  colunas: string[] = [];
  private pagina: number;

  constructor(protected rdCrm: RdcrmService, protected constantsService: ConstantsService) {
  }

  ngOnInit(): void {
    this.colunas = [
       'Smart Lead',
      'Conectado',
      'Reunião Agendada (Demonstração)',
      'Demonstração',
        'Revisão',
        'Fechamento',
        'Implantação'
    ];

    this.rdCrm.obtenhaEtapas().then( (respEtapas) => {
      this.colunas = [];

      for( let etapa of respEtapas ) {
        this.colunas.push(etapa.name);
      }

      this.todo = [];
      this.pagina = 1;
      this.busqueNegociacoes();
    });
  }

  busqueNegociacoes() {
    this.rdCrm.obtenhaNegociacoes(this.pagina).then((respNegociacoes) => {
      let temMais = false
      respNegociacoes.deals.forEach((deal) => {
        deal.etapa = deal?.deal_stage?.name;

        this.todo.push(deal);
      });

      if( respNegociacoes.has_more ) {
        this.pagina++;
        this.busqueNegociacoes();
      }
      setTimeout( () => {
        this.ctlKanban.atualize();
      }, 0);
    });
  }

  protected readonly status = status;

  atualizeCRM($event: any) {
    this.rdCrm.atualizeLead($event.item, $event.novaEtapa).then((resp) => {

    });
  }
}
