<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <img src="assets/fidelidade/icones/novoselo.svg" style="height: 32px;"/> Adicionar Pontos
    </h4>
  </div>

  <div class="card-body">
    <form class="needs-validation" novalidate  (ngSubmit)="adicionePontosSubmit()" #frm="ngForm" [ngClass]="{'was-validated':frm.submitted }" >
    <app-buscar-contato #buscarContato mensagem="Escolha o contato antes de adicionar os pontos"></app-buscar-contato>

    <app-inserir-pontos   #appInserirPts [frm]="frm"></app-inserir-pontos>

      <div class="alert alert-warning alert-dismissible fade show mt-1" role="alert" *ngIf="codigoResgate" (click)="fecheMensagemResgate()">
        <a href="/imprimir/troca/{{codigoResgate}}" target="_blank">
          <i class="fa fa-print fa-lg mr-2">  </i> Saldo usado com sucesso. Imprima o comprovante de resgate
        </a>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="mensagemSucesso" (click)="fecheMensagemSucesso()">
        <a href="/imprimir/pontuacao/{{codigoPontuacao}}" target="_blank">
          <i class="fa fa-print fa-lg mr-2"> </i>
          Pontuação adicionada com sucesso. <br>
          Nova pontuação é de

          <span class="font-18">
            <b> {{mensagemSucesso.novaPontuacao}} {{mensagemSucesso.tipo}}</b>
          </span>
        </a>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="alert alert-danger alert-dismissible fade show" *ngIf="mensagemErro" role="alert">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

  <mensagem-bloqueio [bloqueado]="bloqueado"></mensagem-bloqueio>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light mr-2" [disabled]="enviando " *ngIf="!bloqueado">Adicionar Pontos</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="voltar()">Fechar</button>
  </div>
</form>
  </div>
</div>
