import {Component, OnInit, ViewChild} from '@angular/core';
import {ContatosService} from "../../services/contatos.service";
import {FormEnderecoContatoComponent} from "../form-endereco-contato/form-endereco-contato.component";
import {ConstantsService} from "../ConstantsService";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-cad-endereco-contato',
  templateUrl: './cad-endereco-contato.component.html',
  styleUrls: ['./cad-endereco-contato.component.scss']
})
export class CadEnderecoContatoComponent extends ModalKendo implements OnInit {
  @ViewChild('formEndereco', { static: true})  formEndereco: FormEnderecoContatoComponent;
  mensagemErro: string
  contato: any
  endereco: any = {}
  removendo: boolean;
  enderecoDeEntrega = false;

  constructor(private contatosService: ContatosService,
              private constantsService: ConstantsService) {
    super()
  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if(empresa){
        let formaDeEntregaComZona =
          empresa.formasDeEntrega.find( (formaDeEntrega) => formaDeEntrega.tipoDeCobranca === 'zona' &&
            formaDeEntrega.zonasDeEntrega &&  formaDeEntrega.zonasDeEntrega.length)

        if(formaDeEntregaComZona){
          this.formEndereco.selecionarBairroDaZona = formaDeEntregaComZona.selecionarBairroDaZona;
          this.formEndereco.zonasDeEntrega = formaDeEntregaComZona.zonasDeEntrega;
        }
      }
    })
  }

  setEndereco(endereco, enderecoDeEntrega  = false){
    this.formEndereco.endereco = Object.assign({}, endereco);

    if(enderecoDeEntrega){
      this.formEndereco.nomeLocal = false;
      this.enderecoDeEntrega = true;
    }


  }

  salvarEndereco(endereco: any) {
    delete this.mensagemErro;

    this.contatosService.salveEndereco(endereco).then( (resp) => {
      endereco.enderecoCompleto = resp.enderecoCompleto;
      this.fecheModal(endereco)
    }).catch( (erro) => {
      this.mensagemErro = erro;
    })

  }

  removerEndereco() {
    this.removendo = true;

    delete this.mensagemErro;

    let endereco = this.formEndereco.endereco;

    this.contatosService.removaEndereco(endereco).then( (resp) => {
      this.removendo = false;
      endereco.removido = true;
      this.fecheModal(endereco)
    }).catch( (erro) => {
      this.removendo = false;
      this.mensagemErro = erro;
    })
  }
}
