<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <span>
           <i class="fas fa-utensils" style="font-size: 22px;"></i> {{tituloPagina}} <i class="fas fa-beer fa-lg"></i>
      </span>
    </h4>
  </div>

  <div class="card-body">
    <app-form-endereco-contato  (onSalvou)="salvouEndereco($event)" #formEndereco [endereco]="endereco">
    </app-form-endereco-contato>
  </div>
</div>
