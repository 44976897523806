export class ObjetoComAdicionais {
  adicionais: any;
  valoresAdicionais: any;
  constructor() {
    this.adicionais = {}
  }

  calculePosicaoAdicionais(camposAdicionais: Array<any>) {
    let campoAdicional: any;
    let multiplaEscolha = 0, escolhaUnica = 0;
    for(campoAdicional of camposAdicionais) {
      if(campoAdicional.tipo === 'multipla-escolha') {
        campoAdicional.posicao = multiplaEscolha++
        this.setListaMultiplaEscolha(campoAdicional)
      } else {
        campoAdicional.posicao = escolhaUnica++
      }
    }

    if(this.valoresAdicionais){
      this.valoresAdicionais.forEach( (valorAdicional: any) => {
        let i = 0;
        while (this.adicionais[String(`campo${i}`)]){
          let campoSimples = this.adicionais[String(`campo${i}`)];
          if(campoSimples.nome === valorAdicional.nome)
            this.adicionais[String(`escolhido_campo${i}`)] = campoSimples.id
          i++
        }
      })
    }
  }

  private setListaMultiplaEscolha(campoAdicional: any){
    if(!this.adicionais['lista' + campoAdicional.posicao]){
      this.adicionais['lista' + campoAdicional.posicao] = {}
      this.adicionais['lista' + campoAdicional.posicao].totalSelecionado = 0
      this.adicionais['lista' + campoAdicional.posicao].tipoDeCobranca = campoAdicional.tipoDeCobranca
    }

    for(let opcao of campoAdicional.opcoesDisponiveis) {
      if(!this.adicionais['lista' + campoAdicional.posicao]['opcao_' + opcao.id]){
        this.adicionais['lista' + campoAdicional.posicao]['opcao_' + opcao.id] = {
          selecionada: false,
          opcao: opcao
        }

        if(campoAdicional.podeRepetirItem) this.adicionais['lista' + campoAdicional.posicao]['opcao_' + opcao.id].qtde = 0
      }
    }
  }

  prepareAdicionaisSabores( produto: any){
    let sabores  = (this as any).sabores;

    if(!sabores) return;

    let camposAdicionais = produto.camposAdicionais;
    let contadorMultipla = camposAdicionais.filter((adicional: any) => adicional.tipo === 'multipla-escolha').length ;
    let contadorAdicionalSimples = camposAdicionais.filter((adicional: any) => adicional.tipo === 'escolha-simples').length;

    let limparSabores  = true, proximaChave = contadorAdicionalSimples;

    while (limparSabores){
      let chave = String(`campo${proximaChave}`)
      if(this.adicionais[chave]){
        delete this.adicionais[chave]
        delete this.adicionais[String(`escolhido_campo${proximaChave}`)]
        proximaChave++
      } else {
        limparSabores = false;
      }
    }

    limparSabores = true;
    proximaChave = contadorMultipla;

    while (limparSabores){
      let chave = String(`lista${proximaChave}`)
      if(this.adicionais[chave]){
        delete this.adicionais[chave]
        proximaChave++
      } else {
        limparSabores = false;
      }
    }

    sabores.forEach((sabor) => {
      if (sabor.produto !== produto.id) {
        for(let campoAdicionalSabor of sabor.camposAdicionais) {
          if(campoAdicionalSabor.tipo === 'multipla-escolha') {
            campoAdicionalSabor.posicao = contadorMultipla++
            this.setListaMultiplaEscolha(campoAdicionalSabor)

          } else if(campoAdicionalSabor.tipo === 'escolha-simples') {
            campoAdicionalSabor.posicao = contadorAdicionalSimples++
          }
        }
      }
    })

  }


  public valideCampoAdicional(campoAdicional: any) {
    if(campoAdicional.esconder || campoAdicional.semOpcaoDisponivel) return true;

    if(campoAdicional.tipo.indexOf('escolha-simples') !== -1 ) {
      let valorCampo = this.adicionais['campo' + campoAdicional.posicao]

      if(campoAdicional.obrigatorio) {
        return valorCampo != null
      }
      return true
    }

    if(campoAdicional.tipo.indexOf('multipla-escolha') !== -1 ) {
      let valorCampo = this.adicionais['lista' + campoAdicional.posicao];

      let qtdMinima = campoAdicional.qtdMinima || 0;
      let qtdMaxima = campoAdicional.qtdMaxima || Object.keys(valorCampo).length;

      let qtdSelecionados = 0

      for(let nomeOpcao in  valorCampo) {
        let dadosOpcao = valorCampo[nomeOpcao]

        if(dadosOpcao && dadosOpcao.selecionada) {
          let qtde = dadosOpcao.qtde

          if(!qtde) qtde = 1

          qtdSelecionados += qtde
        }
      }

      if(qtdSelecionados === 0 && !campoAdicional.obrigatorio) return true;

      return qtdSelecionados >= qtdMinima && qtdSelecionados <= qtdMaxima
    }
  }
}
