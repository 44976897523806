<div *ngIf="empresa.modulos && empresa.modulos.length > 0 && possuiModuloPedidos && pedidos.length > 0">
  <h4>Pedidos em Aberto</h4>
  <div class="card contato">
    <div class="card-body">
      <div *ngFor="let pedido of pedidos; let ehUltimo = last;">
        <info-pedido [contato]="contato"   [pedido]="pedido" [permiteAlterarStatus]="true" [permiteEditarPedido]="true"></info-pedido>

        <hr *ngIf="!ehUltimo" />
      </div>
    </div>
  </div>
</div>
