import {
  AfterContentInit,
  AfterViewInit,
  ApplicationRef, ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ModulosService} from "../../services/modulos.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {BotsService} from "../../services/bots.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {TelaNovoPedidoComponent} from "../../pedidos/tela-novo-pedido/tela-novo-pedido.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'info-contato',
  templateUrl: './info-contato.component.html',
  styleUrls: ['./info-contato.component.scss']
})
export class InfoContatoComponent implements OnInit, OnChanges {
  @Input() public contato: any = {};
  @Input() public exibirUltimaVisita: any;
  @Input() public exibirEndereco: any = false;
  @Input() public limiteEndereco: number ;
  @Input() public empresa: any;
  @Input() public inserirtTag: boolean;
  possuiFidelidade: boolean;
  possuiPedidos: boolean;
  possuiModuloChatbot: boolean;
  sessao: any;
  @Input()
  atendente = false;
  @Input()
  statusMia = false;
  usuario: any;

  @Input()
  desativarParaSempre: boolean = false;

  constructor(private app: ApplicationRef, private route: ActivatedRoute, private constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService, private pedidoLocalService: PedidoLocalService,
              private botsService: BotsService, private _cdr: ChangeDetectorRef) {
    this.constantsService.moduloFidelidade$.subscribe( data => this.possuiFidelidade  = data  )
    this.constantsService.moduloPedido$.subscribe( data => this.possuiPedidos  = data)
    this.constantsService.moduloChatbot$.subscribe( data => {
      this.possuiModuloChatbot = data
    });
  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  assumirControle() {
    this.botsService.desativeComunicacao(this.empresa, this.contato).then( () => {});
  }

  ativarChatBot() {
    this.desativarParaSempre = false;

    this.botsService.ativarComunicacao(this.contato.codigoPais + this.contato.telefone).then( () => {});
  }

  obtenhaEnderecosExibir(){
    if(this.limiteEndereco && this.contato.enderecos)
      return this.contato.enderecos.slice(0, this.limiteEndereco);

    return this.contato.enderecos;

  }

  ativouDesativouParaSempre(desativarParaSempre: boolean) {
    this.atendente = true;

    this.botsService.desativeComunicacao(this.empresa, this.contato,this.desativarParaSempre).then(() => {});
  }
}
