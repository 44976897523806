import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModulosService {

  constructor() { }

  possuiModulo(empresa: any, nomeModulo: string) {
    if(!empresa || !empresa.modulos) return false;

    let possui = empresa.modulos.some((modulo) => { return modulo.nome === nomeModulo})
    return possui
  }
}
