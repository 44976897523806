import {Plano} from "../Plano";
import {Empresa} from "../Empresa";

export class Brinde {
  id: number;
  nome: string;
  linkImagem: string;
  artigo: string;
  valorEmPontos: number;
  plano: Plano;
  empresa: Empresa;

  constructor(id: number= null, nome: string= null, linkImagem: string= null,
              valorEmPontos: number= null, plano: Plano= null, artigo: string = null){
    this.id = id;
    this.nome = nome;
    this.linkImagem = linkImagem;
    this.valorEmPontos = valorEmPontos;
    this.plano = plano;
    this.empresa = plano ? plano.empresa : null;
    this.artigo = artigo;
  }

}
