import { Component, OnInit } from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {PedidosService} from "../../services/pedidos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-foodydelivery-detathes',
  templateUrl: './modal-foodydelivery-detathes.component.html',
  styleUrls: ['./modal-foodydelivery-detathes.component.scss']
})
export class ModalFoodydeliveryDetathesComponent extends ModalKendo implements OnInit {

  pedido: any = {};
  notificacoes = [];
  carregando = true;
  constructor( private  grupolojasService: GrupolojasService,
              private  pedidosService: PedidosService) {
    super()
  }


  ngOnInit(): void {
    this.pedidosService.listeNotificacaoesFoodydelivery(this.pedido.id).then((notificacoes) => {
      this.carregando = false;
      this.notificacoes = notificacoes;
    }).catch((erro) => {
      this.carregando = false;
      alert(erro);
    })
  }


}
