import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AdicionalPedidoService extends ServerService {
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  salveAdicional(empresa: any, produto: any, adicional: any){
    adicional.entidade = 'pedido';

    if(!adicional.id){
      return this.httpCliente.post('/produtos/adicional', { eid: empresa.id, tipo: 'pedido', adicional: adicional } )
        .toPromise().then(this.retorno).catch(this.handleError);
    } else {
      return this.httpCliente.put('/produtos/adicional', {eid: empresa.id, adicional: adicional, tipo: 'pedido' } )
        .toPromise().then(this.retorno).catch(this.handleError);
    }
  }

  removaAdicional(empresa: any, adicional: any) {
    return this.httpCliente.put('/produtos/adicional/delete', { pid: empresa.catalogo.id, adicional: adicional } )
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  atualizeOrdemAdicionais(catalogo: any, produto: any, adicionais: any[]) {
    let dados = []
    for (let i = 0; i < adicionais.length; i++) {
      dados.push({
        id: adicionais[i].id,
        objeto: {
          id: catalogo.id
        },
        ordem: adicionais[i].ordem
      });
    }

    return this.httpCliente.post('/produtos/adicional/reordenar', {cid: catalogo.id, pid: produto.id, adicionais: dados}).toPromise()
      .then(this.retorno).catch(this.handleError);
  }
}
