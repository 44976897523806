import { BotsService } from './../../services/bots.service';
import { Component, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-listview';

interface MensagemIntentFrequencia {
  mensagem: string;
  intent: string;
  frequencia: number;
}

interface IntentFrequenciaPercentual {
  intent: string;
  frequencia: number;
  percentual: number;
}

interface DadoGraficoPizza {
  intent: string;
  frequencia: number;
}

@Component({
  selector: 'app-dashboard-mia',
  templateUrl: './dashboard-mia.component.html',
  styleUrls: ['./dashboard-mia.component.scss']
})
export class DashboardMiaComponent implements OnInit {
  mensagensIntentsFrequencia: MensagemIntentFrequencia[] = [];
  intentsFrequenciaPercentual: IntentFrequenciaPercentual[] = [];
  dadosGraficoPizza: DadoGraficoPizza[] = [];

  pageSize = 5;
  skip = 0;

  constructor(private botsService: BotsService) {}

  ngOnInit() {
    this.carregarDados();
  }

  async carregarDados() {
    this.botsService.obtenhaMensagensIntentsFrequencia().subscribe((resposta: any) => {
      if( !resposta ) {
        return;
      }

      this.mensagensIntentsFrequencia = resposta.data;

      this.calcularIntentsFrequenciaPercentual();
      this.prepararDadosGraficoPizza();
    });
  }

  calcularIntentsFrequenciaPercentual() {
    const intentsMap = new Map<string, number>();
    let totalFrequencia = 0;

    // Contar frequências por intent
    for (const item of this.mensagensIntentsFrequencia) {
      const frequenciaAtual = intentsMap.get(item.intent) || 0;
      intentsMap.set(item.intent, frequenciaAtual + item.frequencia);
      totalFrequencia += item.frequencia;
    }

    // Calcular percentuais e criar array final
    this.intentsFrequenciaPercentual = Array.from(intentsMap.entries()).map(([intent, frequencia]) => ({
      intent,
      frequencia,
      percentual: frequencia / totalFrequencia
    }));

    // Ordenar por frequência decrescente
    this.intentsFrequenciaPercentual.sort((a, b) => b.frequencia - a.frequencia);
  }

  prepararDadosGraficoPizza() {
    // Pegar as top 5 intents por frequência
    const top5Intents = this.intentsFrequenciaPercentual.slice(0, 5);

    // Calcular a soma das frequências das top 5 intents
    const somaTop5 = top5Intents.reduce((sum, item) => sum + item.frequencia, 0);

    // Preparar dados para o gráfico
    this.dadosGraficoPizza = top5Intents.map(item => ({
      intent: item.intent,
      frequencia: (item.frequencia / somaTop5) * 100 // Converter para porcentagem
    }));

    // Adicionar categoria "Outros" se necessário
    if (this.intentsFrequenciaPercentual.length > 5) {
      const outrosFrequencia = this.intentsFrequenciaPercentual
        .slice(5)
        .reduce((sum, item) => sum + item.frequencia, 0);

      this.dadosGraficoPizza.push({
        intent: 'Outros',
        frequencia: (outrosFrequencia / somaTop5) * 100
      });
    }
  }

  abrirRespostasMia(dataItem: any, intent: string) {
    this.botsService.busqueRespostasMia(intent).subscribe((respostas: any) => {
      if( !respostas ) {
        return;
      }

      //adiciona um sequencia para cada resposta
      dataItem.respostas = respostas.data.map((resposta: any, index: number) => ({
        ...resposta,
        sequencia: index + 1
      }));

      this.atualizarDadosPaginados();
    });
  }

  labelContent(e: any) {
    if( e.dataItem.percentual < 0.03 ) {
      return '';
    }

    return `${e.dataItem.intent}: ${(e.dataItem.percentual * 100).toFixed(2)}%`;
  }

  onPageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;

    // Atualize a visualização dos dados
    this.atualizarDadosPaginados();
  }

  atualizarDadosPaginados(): void {
    // Aqui você deve atualizar os dados exibidos na lista
    // baseado no novo skip e pageSize
    // Por exemplo:
    this.intentsFrequenciaPercentual.forEach( (item: any) => {
      if (item.respostas) {
        item.respostasPaginadas = item.respostas.slice(this.skip, this.skip + this.pageSize);
      }
    });
  }
}
