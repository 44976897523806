<h4   class="page-title">
  <i class="fas fa-images"></i>
  Novo Banner</h4>

<div class="card" style="max-width: 1050px;">
  <div class="card-body">
    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

      <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
        <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
      </span>
      </div>

      <div class="row">
        <div class="form-group mb-3 col">
          <label for="nome">Nome</label>
          <input type="text" class="form-control" autocomplete="off"
                 id="nome" name="nome" [(ngModel)]="banner.nome" #nome="ngModel"
                 placeholder="Nome do Banner" value="" required appAutoFocus [autoFocus]="true">
          <div class="invalid-feedback">
            <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
          </div>
        </div>
      </div>

      <app-upload-crop-imagem #capaUpload ratio="4" resizeWidth="1240" minWidth="992" minHeight="240"
                              [objeto]="banner"  [prop]="'linkImagem'" [label]="'Foto'" (onEnviou)="salveFotoBanner($event)"> </app-upload-crop-imagem>


      <div class="clearfix mt-2">&nbsp;</div>

      <div class="row">
        <div class="form-group mb-3 col">
          <label for="nome">O que fazer ao clicar no Banner?</label>
          <div>
            <div>
            <span class="radio radio-blue">
              <input id="comando_nada" name="comando" type="radio"    kendoRadioButton class="k-radio"
                     [(ngModel)]="banner.comando" value="NADA" #comando="ngModel"
                     required/>
              <label for="comando_nada" class="ml-1">&nbsp;Não fazer nada</label>
            </span>
            </div>
            <div>
              <span class="radio radio-blue">
                <input id="comando_link" name="comando" type="radio"    kendoRadioButton class="k-radio"
                       [(ngModel)]="banner.comando" value="LINK" #comando="ngModel"
                       required/>
                <label for="comando_link" class="ml-1">&nbsp;Abrir Um Site</label>
              </span>
              <div class="row" *ngIf="banner.comando === 'LINK'">
                <div class="form-group mb-3 col">
                  <label for="nome">Endereço do Site</label>
                  <div class="input-group">
                    <input type="text" class="form-control" autocomplete="off"
                             name="extra_link" [(ngModel)]="banner.extra" #nome="ngModel"
                           placeholder="https://www.site.com.br" value="" required appAutoFocus [autoFocus]="true">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button" (click)="testarEndereco();">Testar Endereço</button>
                    </div>

                    <div class="invalid-feedback">
                      <p *ngIf="nome.errors?.required">Link é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="radio radio-blue">
                <input id="comando_busca" name="comando" type="radio"    kendoRadioButton class="k-radio"
                       [(ngModel)]="banner.comando" value="BUSCA" #comando="ngModel"
                       required/>
                <label for="comando_busca" class="ml-1">&nbsp;Exibir Produtos</label>
              </span>

              <div class="row" *ngIf="banner.comando === 'BUSCA'">
                <div class="form-group mb-3 col">
                  <label for="nome">Exibir apenas produtos da busca:</label>
                  <div class="input-group">
                    <input type="text" class="form-control" autocomplete="off"
                           id="extra_link" name="extra_link" [(ngModel)]="banner.extra" #nome="ngModel"
                           placeholder="Informe qual a busca" value="" appAutoFocus [autoFocus]="true">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button" (click)="testarBusca();">Testar Busca</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="invalid-feedback">
            <p *ngIf="comando.errors?.required">Você deve dizer o que acontecer ao clicar no link.</p>
          </div>
        </div>

        <div class="form-group mb-3 col ">
          <label for="validade">Data validade</label>

          <div class="input-group">

            <kendo-datepicker [(ngModel)]="banner.validade" id="validade"    name="validade" [format]="'dd/MM/yyyy'"
                              class="form-control   k-display-inline-block"   >
              <kendo-datepicker-messages
                today="Hoje"
                toggle="Alterar data"
              ></kendo-datepicker-messages>

            </kendo-datepicker>

          </div>
        </div>

      </div>

      <ng-container *ngIf="banner.online && banner.configMeioDePagamento">
        <div class="row mb-1">
          <div class="col-12">
            <label for="nome">Meio de Pagamento</label>
          </div>
          <div class="col">
            <input type="radio" name="meioDePagamento" id="meioCielo" kendoRadioButton #meioDePagamento="ngModel"
                   [(ngModel)]="banner.configMeioDePagamento.meioDePagamento" value="cielo">
            <label class="k-radio-label" for="meioCielo">Cielo</label>


            <input type="radio" name="meioDePagamento" id="meioPagSeguro" kendoRadioButton class="ml-3" #meioDePagamento="ngModel"
                   [(ngModel)]="banner.configMeioDePagamento.meioDePagamento" value="pagseguro"/>

            <label class="k-radio-label" for="meioPagSeguro">Pagseguro</label>


            <p class="form-text text-muted">Fornecedor da solução de pagamentos</p>
            <div class="invalid-feedback">
              <p *ngIf="meioDePagamento.errors?.required">Nome é obrigatório</p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="banner.configMeioDePagamento.meioDePagamento === 'cielo'">
          <div class="row">
            <div class="form-group mb-1 col">
              <label for="nome">Nome Fatura Cartão</label>
              <input type="text" class="form-control" autocomplete="off" maxlength="13"
                     id="nomeFaturaCartao" name="nomeFaturaCartao" [(ngModel)]="banner.configMeioDePagamento.nomeFaturaCartao" #nomeFaturaCartao="ngModel"
                     placeholder="Nome na fatura do cartão do seu cliente" value="" [required]="banner.online">
              <p class="form-text text-muted">Esse é o nome que seu cliente vai ver na fatura do cartão de crédito dele</p>
              <div class="invalid-feedback">
                <p *ngIf="nomeFaturaCartao.errors?.required">Nome é obrigatório</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-1 col">
              <label for="nome">Client ID</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="clientID" name="clientID" [(ngModel)]="banner.configMeioDePagamento.clientID" #clientID="ngModel"
                     placeholder="Client ID" value="" [required]="banner.online">
              <div class="invalid-feedback">
                <p *ngIf="clientID.errors?.required">Client ID é obrigatório</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mb-3 col">
              <label for="nome">Client Secret</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="clientSecret" name="clientSecret" [(ngModel)]="banner.configMeioDePagamento.clientSecret" #clientSecret="ngModel"
                     placeholder="Client Secret" value="" [required]="banner.online">
              <div class="invalid-feedback">
                <p *ngIf="clientSecret.errors?.required">Client Secret é obrigatório</p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="banner.configMeioDePagamento.meioDePagamento === 'pagseguro'">
          <div class="row">
            <div class="form-group mb-3 col">
              <label for="nome">Email</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="email" name="email" [(ngModel)]="banner.configMeioDePagamento.email" #email="ngModel"
                     placeholder="Email" value="" [required]="banner.configMeioDePagamento.meioDePagamento === 'pagseguro'">
              <div class="invalid-feedback">
                <p *ngIf="email.errors?.required">Email é obrigatório</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-3 col">
              <label for="nome">Token</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="token" name="token" [(ngModel)]="banner.configMeioDePagamento.token" #token="ngModel"
                     placeholder="Token" value="" [required]="banner.configMeioDePagamento.meioDePagamento === 'pagseguro'">
              <div class="invalid-feedback">
                <p *ngIf="token.errors?.required">Token é obrigatório</p>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="enviando" >Salvar</button>
        <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="banner.id">Remover</button>-->
        <button type="button" (click)="cancelar()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
      </div>
    </form>
  </div>
</div>

