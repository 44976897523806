<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  &nbsp; <i class="fe-user "></i> Nova Notificação Aplicativo</h4>

<div class="row">
  <div class="col">
    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="salvarNotificacao()">
      <div class="form-group mb-3 mt-2" style="position: relative;" *ngIf="false">
        <div style="max-width: 400px">

        </div>
      </div>

      <div class="form-group mb-3">
        <label for="nome">Nome da Campanha</label>
        <input type="text" class="form-control" autocomplete="off"
               id="nome" name="nome" [(ngModel)]="campanha.nome" #nome="ngModel"
               placeholder="Nome da Notificação" value="" required appAutoFocus [autoFocus]="true">
        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        </div>
      </div>

      <div class="form-group mb-3">
        <label for="nome">Título da Notificação</label>
        <input type="text" class="form-control" autocomplete="off"
               id="titulo" name="titulo" [(ngModel)]="campanha.titulo" #titulo="ngModel"
               placeholder="Título da Notificação" value="" required appAutoFocus [autoFocus]="true">
        <div class="invalid-feedback">
          <p *ngIf="titulo.errors?.required">Título da Notificação é obrigatório</p>
        </div>
      </div>

      <div class="form-group mb-3 mt-2" style="position: relative;">
        <label for="txtMensagem">Texto da Notificação</label>
        <div>
                <textarea class="form-control" id="txtMensagem" rows="5" name="txtMensagem" [(ngModel)]="campanha.mensagem" #txtMensagem="ngModel" #textArea
                          required appAutoFocus (ngModelChange)="alterouMensagem(campanha)" maxlength="1000"></textarea>
          <div>
            <span [ngClass]="{'text-warning': qtdeCaracteresRestantes < 50, 'text-success': qtdeCaracteresRestantes >= 50, 'text-danger': qtdeCaracteresRestantes < 20}">{{qtdeCaracteresRestantes}} caracteres restantes</span>
          </div>
          <div class="invalid-feedback" *ngIf="txtMensagem && txtMensagem.errors">
            <div *ngIf="txtMensagem.errors.required">
              Informe a mensagem que será enviada ao cliente.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mb-3">
        <label for="nome">Ao clicar na notificação abrir qual link?</label>
        <div class="input-group">
          <input type="url" class="form-control" autocomplete="off"
                 id="link" name="link" [(ngModel)]="campanha.link" #link="ngModel" (keypress)="mudouLink()"
                 pattern="https://.*"
                 placeholder="Link da Notificação" value="" required appAutoFocus [autoFocus]="true">
          <button class="input-group-append btn btn-secondary" type="button"
             [class.btn-primary]="campanha.foiTestada"
                  [disabled]="!campanha.link"
             (click)="testeLink(campanha);">
            <i class="fas fa-link font-16"></i>&nbsp;&nbsp;Testar Link
          </button>
        </div>
        <div class="invalid-feedback">
          <p *ngIf="link.errors?.required">Link da Notificação é obrigatório</p>
          <p *ngIf="link.errors?.pattern">Link da Notificação inválido. Exemplo de link válido:
            <strong>https://{{empresa.dominio}}.meucardapio.ai</strong>
          </p>
        </div>

        <div class="card mt-1">
          <div class="card-body" *ngIf="linkEstaCorreto && !campanha.foiTestada">
            <h4 class="mt-0">O link que você testou está certo?</h4>
            <button class="btn btn-blue" type="button" (click)="campanha.foiTestada = true;">Sim</button>
            <button class="btn btn-danger ml-1" type="button" (click)="linkEstaCorreto = false;campanha.foiTestada = false;">Não</button>
          </div>
          <div class="card-body text-center" *ngIf="campanha.foiTestada">
            <h4 class="text-success">
              <i class="far fa-check-circle"></i>
              Campanha foi testada!
            </h4>
          </div>
        </div>
      </div>

      <div class="form-group mb-3">
        <app-upload-imagem [objeto]="campanha" [label]="'Imagem da Notificação'" [prop]="'urlImagem'"
                           (onEnviou)="trocouImagem($event)"></app-upload-imagem>

      </div>

      <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro" [innerHTML]="mensagemErro">
        {{mensagemErro}}
      </div>

      <div class="row d-flex align-content-center mt-1 mb-5 mx-auto">
        <button class="btn btn-lg btn-primary">
          Salvar Notificação
        </button>

        <button type="button" class="btn btn-secondary btn-lg ml-2" (click)="voltar()">
          Cancelar
        </button>
      </div>
    </form>
  </div>
  <div class="col-fixed text-center">
    <h3>Visualização</h3>

    <div class="position-relative">
      <img src="/assets/loja/preview_notificacao.png"/>
      <p>Android</p>
      <ng-template [ngTemplateOutlet]="preview"></ng-template>
    </div>

    <div class="position-relative">
      <img src="/assets/loja/preview_notificacao.png"/>
      <p>IOS</p>

      <ng-template [ngTemplateOutlet]="preview"></ng-template>
    </div>
  </div>
</div>


<ng-template #preview>
  <div class="position-absolute text-left" style="background: #fff;padding: 10px;top: 80px;left: 30px;width: 345px;border-radius: 5px;">
    <div class="row">
      <div class="col">
        <span class="font-weight-bold;font-13"><strong>{{campanha.titulo ? campanha.titulo : "Informe o título"}}</strong></span>
        <p>{{campanha.mensagem ? campanha.mensagem : "Informe a mensagem"}}</p>
      </div>
      <div class="col-auto d-flex align-content-center justify-content-center">
        <i class="far fa-image" style="font-size: 40px;border: solid 1px #e2e2e2;padding: 10px;background: #e9ecef;"
           *ngIf="!campanha.urlImagem"></i>
        <img [src]="'/images/empresa/' + campanha.urlImagem" style="width: 64px;height: 64px;object-fit: cover" *ngIf="campanha.urlImagem"/>
      </div>
    </div>
  </div>
</ng-template>
