<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel">
        {{ jaTemGarcom ? 'Informe o número da Comanda' :  (comanda.id ? 'Vincular Garçom a Comanda' : 'Escolher Garçom')}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">
    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit(frm)">
      <div class="row">
        <div class="form-group mb-3 col-12" *ngIf="!jaTemGarcom">
          <label  >Garçom da Mesa</label>

          <kendo-dropdownlist #responsavel="ngModel" name="responsavel" [(ngModel)]="comanda.garcom" [data]="garcons"
                              placeholder="Selecione um responsavel" class="form-control" textField="nome" required >

          </kendo-dropdownlist>

          <div class="invalid-feedback">
            <p *ngIf="responsavel.errors?.required">Garçom é obrigatório</p>
          </div>
        </div>

        <div class="form-group mb-3 col-12"  *ngIf="informarComanda()"  >
          <label  >Codigo comanda no <b>{{empresa.integracaoDelivery.sistema}}</b></label>
          <input type="text" class="form-control" autocomplete="off"
                 name="codigoPdv" [(ngModel)]="comanda.codigoPdv" #codigoPdv="ngModel"
                 placeholder="Codido comanda no PDV"       required  >
          <div class="invalid-feedback">
            <p *ngIf="codigoPdv.errors?.required">Código do comanda é obrigatório</p>
          </div>
        </div>


      </div>

      <div class="row">
        <div class="col">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="erro">
            {{erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light"  >
          <i class="k-i-loading k-icon" *ngIf="vinculando"></i>Salvar</button>
        <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="this.fecheModal()">Fechar</button>
      </div>

    </form>

  </div>
</div>

