<kendo-grid
  [data]="formasDePagamento"
  [loading]="loading" style="min-height: 100px; max-width: 800px"

  (edit)="editar($event)" >
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhuma forma de pagamento"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID"    width="50">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span class="text-blue"><b>{{dataItem.id || '-'}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Descrição" width="250px">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <ng-container *ngIf=" dataItem.bandeira">
        <app-bandeira-logo [bandeira]=" dataItem.bandeira" [descricao]="dataItem.descricao"></app-bandeira-logo>
      </ng-container>

      <span *ngIf="!dataItem.bandeira"><b>{{dataItem.descricao}}</b></span>

      <ng-container *ngIf="informarChavePix(dataItem)">
        <input type="text" class="form-control" autocomplete="off" (change)="mudouChavePix(dataItem)"
                name="chavePix" [(ngModel)]="dataItem.chavePix" #chavePix="ngModel"  [disabled]="dataItem.salvando"
               placeholder="Informe a chave pix" value="" required appAutoFocus [autoFocus]="!dataItem.chavePix">
      </ng-container>


    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Gateway"    width="100px" *ngIf="online">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

         <span [ngClass]="{'pagarme': ehPagarme(dataItem)}">
          <b>  {{dataItem.configMeioDePagamento?.meioDePagamento}}</b>
         </span>

    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Ativado"    width="80px" *ngIf="!ocultarBotoes && !ocultarAtivar">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-switch name="ativo" [(ngModel)]="dataItem.exibirCardapio"
                    [onLabel]="'Sim'" (ngModelChange)="alterouAtivarPagamento(dataItem, $event)"
                    [offLabel]="'Não'"></kendo-switch>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" width="100px" *ngIf="!ocultarBotoes"  >
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-formaPagamento >
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editar(formaPagamento)" *ngIf="formaPagamento.id">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>

    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>
