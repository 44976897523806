<div class="left-side-menu">

  <div class="">
    <div class="nav-user">
      <a class="nav-link ">
        <img style="width:32px; height: 32px;" src="/assets/fidelidade/icones/user-icon-cinza.png" alt="user-image" class="float-left">
        <div class="dadosUsuario">
          <span class="ml-1"><b>{{usuario.nome}}</b></span><br>
          <span class="ml-1 empresa">
           {{usuario.empresa.id}} - {{usuario.empresa.nome}}
         </span>
        </div>
      </a>
    </div>

    <div id="sidebar-menu" [hidden]="!carregou">
      <ul class="metismenu" id="side-menu">
        <li *ngIf="false">
          <a   (click)="naveguePara('index')" [ngClass]="{'text-primary text-bold':url=='index'}" href="javascript: void(0);">
            <i class="fe-home"></i>
            <span> Visão Geral</span>
          </a>
        </li>
        <li>
          <a   (click)="naveguePara('index')" [ngClass]="{'text-primary text-bold':url=='index'}" href="javascript: void(0);">
            <i class="fas fa-store"></i>
            <span> Lojas</span>
          </a>
        </li>
        <li>
          <a (click)="naveguePara('templates-mensagens')" [ngClass]="{'text-primary text-bold':url.indexOf('templates-mensagens') >= 0}" href="javascript: void(0);">
            <i class="fab fa-whatsapp fa-lg"></i>
            <span> Templates de Mensagens</span>
          </a>
        </li>
        <li>
          <a   (click)="naveguePara('marketing')" [ngClass]="{'text-primary text-bold':url.indexOf('marketing') >= 0}" href="javascript: void(0);">
            <i class="fe-trending-up"></i>
            <span> Marketing</span>
          </a>
        </li>
        <li>
          <a (click)="naveguePara('financeiro-delivery')" [ngClass]="{'text-primary text-bold':url.indexOf('financeiro') >= 0}"  href="javascript: void(0);">
            <i class="fe-bar-chart-2"></i>
            <span> Relatórios </span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">
            <li>
              <a (click)="naveguePara('performance-whatsapp')" [ngClass]="{'text-primary text-bold':url=='performance-whatsapp'}"  href="javascript: void(0);">
                Performance Whatsapp</a>
            </li>
            <li>
              <a  (click)="naveguePara('financeiro-delivery')" [ngClass]="{'text-primary text-bold':url=='financeiro-delivery'}"  href="javascript: void(0);">
                Vendas Delivery</a>
            </li>
            <li>
              <a  (click)="naveguePara('financeiro-mesas')" [ngClass]="{'text-primary text-bold':url=='financeiro-mesas'}"  href="javascript: void(0);">
                Vendas Mesas</a>
            </li>
          </ul>
        </li>

        <li>
          <a (click)="naveguePara('relatorios')" [ngClass]="{'text-primary text-bold':url=='relatorios'}" href="javascript: void(0);">
            <i class="fas fa-chart-pie"></i>
            <span> Inteligência do Negócio </span>
          </a>
        </li>


        <li *ngIf="usuario?.adminRede">
          <a   (click)="naveguePara('catalogo')" [ngClass]="{'text-primary text-bold':url.indexOf('catalogo') >= 0}" href="javascript: void(0);">
            <i class="fa fa-cubes"></i>
            <span>Catálogos</span>
          </a>
        </li>

        <li *ngIf="rede === 'chinainbox'">
          <a   (click)="naveguePara('catalogo-rede-china')" [ngClass]="{'text-primary text-bold':url.indexOf('catalogo-rede-china') >= 0}" href="javascript: void(0);">
            <i class="fa fa-sync"></i>
            <span>Catálogos Trendfoods</span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">
           <!-- <li>
              <a   (click)="naveguePara('catalogo-rede-china')" [ngClass]="{'text-primary text-bold':url=='catalogo-rede-china'}" href="javascript: void(0);">

                <span>China In Box(Ecletica)</span>
              </a>
            </li>

            <li>
              <a   (click)="naveguePara('catalogo-rede-chinagcom')" [ngClass]="{'text-primary text-bold':url=='catalogo-rede-chinagcom'}" href="javascript: void(0);">

                <span>China In Box (Gcom)</span>
              </a>
            </li> -->

            <li>
              <a   (click)="naveguePara('catalogo-rede-gendai')" [ngClass]="{'text-primary text-bold':url=='catalogo-rede-gendai'}" href="javascript: void(0);">

                <span>Gendai (Ecletica)</span>
              </a>
            </li>


            <li>
              <a   (click)="naveguePara('catalogo-rede-gendaigcom')" [ngClass]="{'text-primary text-bold':url=='catalogo-rede-gendaigcom'}" href="javascript: void(0);">

                <span>Gendai (Gcom)</span>
              </a>
            </li>

             </ul>
        </li>
      </ul>
    </div>
    <div class="clearfix"></div>
    <!--<button type="button" class="btn btn-primary width-lg mx-auto" style=" display: block;">Criar promoção</button>-->
  </div>
</div>


