import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService  extends ServerService {

  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  todos() {
    return this.httpCliente.get('/templates')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  empresasQueUsam(template: any , nome: any = null) {
    return this.httpCliente.get('/templates/empresasQueUsam/' + template.id + (nome ? "?nome=" + nome : ""))
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  daEmpresaOuDaRede() {
    return this.httpCliente.get('/templates/empresaOuRede')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveTemplate(template: any) {
    return this.salve('/templates', template);
  }


  todosDaRede(rede: any) {
    return this.httpCliente.get('/templates/rede/' + rede.id)
      .toPromise().then(this.retorno).catch(this.handleError);
  }
}

