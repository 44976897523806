declare let moment;

export class FiltroTelaUI {
  id: number;
  nome: string;
  flag: string;
  inicio: Date;
  fim: Date;
  variavel: string;
  viewCompras: boolean;
  valor: any;

  constructor(id: number, nome: string, flag: string, variavel: string = null,  viewCompras: boolean = false){
    this.id = id;
    this.nome = nome;
    this.flag = flag;
    this.variavel = variavel;
    this.viewCompras = viewCompras;
  }

  getVariavel(unidade: string){
    let variavel = this.variavel;
    console.log('unidade:' + unidade)

    if(unidade === 'dia')
      variavel = this.variavel.concat("Dia")
    else if(unidade === 'mes')
      variavel = this.variavel.concat("Mes")
    else if(unidade === 'ano')
      variavel = this.variavel.concat("Ano")

    return variavel;
  }

  setQueryMysql(query: any, dadosFiltro: string){
    if(this.variavel){
      let partes = dadosFiltro.split(','),
        valor = partes[0],
        operador = partes[1],
        unidade = partes.length > 2 ?  partes[2] : null,
        sufixo = this.variavel;

      // tslint:disable-next-line:radix
      query['filtroValor' + sufixo] = parseInt(valor);

      const key = this.getVariavel(unidade);

      if(operador === '>'){
        query[key.concat("Maior")] = true;
      } else if(operador === '<'){
        query[key.concat("Menor")] = true;
      } else if (operador === '=') {
        query[key.concat("Igual")] = true;
      } else {
        query[key] = true;
      }
    } else {
      query[this.flag] = dadosFiltro;
    }

    if(this.viewCompras)
      query.viewCompras = true;

    console.log(query)
  }

  // tslint:disable-next-line:member-ordering
  static getParamsRequest( filtro: any ) {
    let params: any = {};

    // tslint:disable-next-line:forin
    for( let k in filtro ) {
      let dadosFiltro =  filtro[k];

      if(k !== 'q'){
        params[dadosFiltro.flag] = FiltroTelaUI.getValor(dadosFiltro);
      } else {
        params[k]  = dadosFiltro;
      }
    }

    return params;
  }

  // tslint:disable-next-line:member-ordering
  static getLabel(filtroTela: any){
    let valor = filtroTela.valor;

    if(filtroTela.inicio)
      return moment(filtroTela.inicio).format('DD MMM') + " até " +
        moment(filtroTela.fim).format('DD MMM');

    if(valor && valor.label)
      valor =  valor.label;

    if(filtroTela.unidade){
      if(valor > 1){
        if( filtroTela.unidade.value === 'mes' ) {
          valor =   valor + " meses";
        } else {
          valor = valor + " " + filtroTela.unidade.value + "s";
        }
      } else {
        valor =   valor + " " + filtroTela.unidade.value;
      }
    }

    if(filtroTela.operador &&  typeof  filtroTela.operador !== 'string')
      valor =  filtroTela.operador.label + " " + valor ;


    return valor;
  }
  // tslint:disable-next-line:member-ordering
  static getValor(filtro: any) {
    if(filtro.inicio)
      return moment(filtro.inicio).format('YYYYMMDD') + "," + moment(filtro.fim).format('YYYYMMDD');

    let valor = filtro.valor;

    if (valor != null){
      if(Array.isArray(valor)) {
        return valor;
      } else if (valor  instanceof Date){
        return  moment(valor).format('YYYYMMDD');
      } else{
        if (valor.value != null)
          valor = valor.value;

        if(filtro.operador)
          valor = valor +  "," + filtro.operador.value;

        if(filtro.unidade)
          valor = valor +  "," + filtro.unidade.value;

        return valor;
      }
    } else {
      return valor;
    }

  }
  // tslint:disable-next-line:member-ordering
  static getQuerySQL(querystring: any, operador: any = null){
    let query: any = {};

    if(querystring.q)
      query.like = '%' + querystring.q + "%";

    if(querystring.r) query.rede =  operador.adminRede
    if(querystring.rid) query.redeId =  Number(querystring.rid);

    FiltroTelaUI.liste().forEach( (filtroAvancado) => {
      let dadosFiltro =  querystring[filtroAvancado.flag];
      if(dadosFiltro != null)
        filtroAvancado.setQueryMysql(query, dadosFiltro)
    })

    return query;
  }
  // tslint:disable-next-line:member-ordering
  static liste(): Array<FiltroTelaUI>{
    return [ FiltroTelaUI.ultimaCompra(), FiltroTelaUI.pontuacao(), FiltroTelaUI.plano() ,
      FiltroTelaUI.ticketMedio(),  FiltroTelaUI.totalComprado(), FiltroTelaUI.status(),
      FiltroTelaUI.qtdeCompras(), FiltroTelaUI.naoNotificado(), FiltroTelaUI.aniversario(),
      FiltroTelaUI.jaTrocou(), FiltroTelaUI.podeTrocar(), FiltroTelaUI.sexo(), FiltroTelaUI.tipoPet(),
      FiltroTelaUI.generoPet(), FiltroTelaUI.apenasDddLocal(), FiltroTelaUI.tag()
           ];
  }
  // tslint:disable-next-line:member-ordering
  static getUnidades(){
    return [ {value: 'dia' , label: 'Dia'}, { value: 'mes', label: 'Mês'}, {value: 'ano', label: 'Ano'}];
  }
  // tslint:disable-next-line:member-ordering
  static getOperadores(){
    return [ {value: '>', label: "Maior que"}, {value: '<', label: "Menor que"}, {value: '=', label: "Igual"} ];
  }
  // tslint:disable-next-line:member-ordering
  static getOperadoresMais(){
    return [ {value: '>', label: 'Mais que'}, {value: '<',   label: 'Menos que'}, {value: '=',   label: "Exatos"}];
  }
  // tslint:disable-next-line:member-ordering
  static ultimaCompra(){
    return new FiltroTelaUI(1, 'Ultima compra', 'fu', 'ultimaCompra');
  }
  // tslint:disable-next-line:member-ordering
  static pontuacao(){
    return new FiltroTelaUI(2, 'Pontuação', 'fp' , 'pontuacao');
  }
  // tslint:disable-next-line:member-ordering
  static ticketMedio(){
    return new FiltroTelaUI(3, 'Tikcet médio', 'ftm', 'ticketMedio', true);
  }
  // tslint:disable-next-line:member-ordering
  static totalComprado(){
    return  new FiltroTelaUI(4, 'Total comprado', 'ftc', 'totalComprado', true);
  }
  // tslint:disable-next-line:member-ordering
  static qtdeCompras(){
    return  new FiltroTelaUI(5, 'Qtd. Compras', 'fqc', 'qtdeCompras', true)
  }
  // tslint:disable-next-line:member-ordering
  static naoNotificado(){
    return  new FiltroTelaUI(6, 'Não notificado', 'fnn', 'naoNotificado');
  }
  // tslint:disable-next-line:member-ordering
  static status(){
    return  new FiltroTelaUI(7, 'Contatos', 'status');
  }
  // tslint:disable-next-line:member-ordering
  static aniversario(){
    return  new FiltroTelaUI(8, 'Aniversariantes', 'anv');
  }
  // tslint:disable-next-line:member-ordering
  static plano(){
    return  new FiltroTelaUI(9, 'Plano', 'plano');
  }
  // tslint:disable-next-line:member-ordering
  static jaTrocou(){
    return  new FiltroTelaUI(10, 'Já resgatou brindes', 'jatrocou');
  }
  // tslint:disable-next-line:member-ordering
  static podeTrocar(){
    return  new FiltroTelaUI(11, 'Pode resgatar brindes', 'podetrocar');
  }
  // tslint:disable-next-line:member-ordering
  static sexo(){
    return  new FiltroTelaUI(13, 'Sexo', 'sexo');
  }
  // tslint:disable-next-line:member-ordering
  static tipoPet(){
    return  new FiltroTelaUI(14, 'Pet do tipo', 'tipopet');
  }
// tslint:disable-next-line:member-ordering
  static generoPet(){
    return  new FiltroTelaUI(14, 'Genero', 'generopet');
  }

// tslint:disable-next-line:member-ordering
  static apenasDddLocal() {
    return new FiltroTelaUI(16, 'Apenas DDD local', 'dddlocal')
  }

  static tag(){
    return  new FiltroTelaUI(17, 'Tag', 'tag');
  }
}





