<div class="modal-content">
  <div class="modal-header" >
    <h4 class="modal-title" id="myModalLabel">
      Cotação Entregador {{pedido.delivery.origem}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">
     <h4> Pedido <b>{{this.pedido.codigo}}</b> valor <span class="dinheiro">{{pedido.total | currency: "R$"}}</span></h4>

     <p class="mb-1"><b>Taxa de entrega: </b> <span class="dinheiro">{{pedido.taxaEntrega | currency: "R$"}}</span></p>
     <p  class="mb-1"><b>Formas de pagamento: </b>
        <span *ngFor="let pagamento of pedido.pagamentos">
            {{pagamento.formaDePagamento.descricao}} - <b> {{pagamento.valor |  currency: "R$"}}</b>
        </span>
     </p>

    <p class="mb-1"><b>Endereço entrega: </b> <span class="valor text-primary">{{pedido.enderecoCompleto}}</span></p>


     <h4>Cotação <span class="badge badge-danger" >ifood</span>
         <span *ngIf="pedido.delivery.cotacao.nomeLoja"> para Loja
            "{{pedido.delivery.cotacao.nomeLoja}}"</span>
     </h4>

     <div class="caixa">
           <div class="row">

             <div class="col-3 campo">Distancia:</div>
             <div class="col-9 valor">+- {{cotacao.distancia / 10000}} Km</div>

             <div class="col-3 campo">Tempo:</div>
             <div class="col-9 valor">{{obtenhaMinutos(cotacao.tempo.min )}} ~ {{obtenhaMinutos(cotacao.tempo.max )}}</div>

             <div class="col-3 campo">Valor:</div>
             <div class="col-9 valor dinheiro">{{cotacao.valor  | currency: "R$"}}</div>

             <div class="col-12 mt-2">
               <p class="text-muted">Cotação valida até {{cotacao.expiracao  | date :"dd/MM/yyyy HH:mm"}} </p></div>
           </div>
       </div>


    <div *ngIf="erro" class="text-danger mt-2">
      <p><b>                  <i class="fa fa-times-circle mr-1"></i> {{erro}}</b></p>
    </div>

    <button  class="btn btn-success   waves-effect waves-light btn-atualizar mt-2"   type="button"
             [disabled]="solicitando"  (click)="solicteParceiro()">
      <i class="fas fa-motorcycle ml-1 "  ></i>
      Solicitar Entregador    <i class="k-i-loading k-icon" *ngIf="solicitando "></i>
    </button>


    <button  class="btn btn-danger ml-2   waves-effect waves-light btn-atualizar mt-2"   type="button"
             [disabled]="solicitando"  (click)="canceleCotacaoDelivery()">
      <i class="fas fa-times ml-1 "  ></i>
      Cancelar Cotação  <i class="k-i-loading k-icon" *ngIf="solicitando "></i>
    </button>


  </div>
</div>
