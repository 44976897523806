import { Component, OnInit } from '@angular/core';
import {ModalKendo} from "../../lib/ModalKendo";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {
  PayloadJsonViewComponent
} from "../../pedidos/tela-pedidos-erros-importar/payload-json-view/payload-json-view.component";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-modal-request-parceiro',
  templateUrl: './modal-request-parceiro.component.html',
  styleUrls: ['./modal-request-parceiro.component.scss']
})
export class ModalRequestParceiroComponent  extends ModalKendo implements OnInit {
  pedido: any = {};
  requests = [];
  carregando = true;
  constructor( private dialogService: DialogService  ) {
    super()
  }


  ngOnInit(): void {
    this.requests = this.pedido.requests;
    this.carregando = false;
  }

  verJson(item: any, event: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: PayloadJsonViewComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.payload = item

    event.stopPropagation();
    event.preventDefault();
  }


}
