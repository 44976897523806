export class SaborPizzaLoja{
  preco = 0;
  total = 0;
  nome: string
  descricao: string;
  produtoTamanho: any
  produto: number;
  obrigatorio = false
  camposAdicionais: any;
  constructor(produto: any, tamanho: any,   public qtde = 0) {
    this.nome = produto.nome;
    this.descricao = produto.descricao;
    this.produto =  produto.id;
    this.produtoTamanho = tamanho;
    this.preco = tamanho.preco;
    this.camposAdicionais = produto.camposAdicionais;
    if(this.camposAdicionais)
      this.camposAdicionais.forEach((adicionalSabor: any) => { adicionalSabor.sabor = produto.nome })


  }


  static obtenhaPeloServer(sabores: any) {
    if(!sabores || sabores.length === 0 ) return sabores;

    return sabores.map( (sabor: any) => new SaborPizzaLoja(sabor.produto, sabor.produtoTamanho, 1))

  }
}

export class CalculadoraPrecoSabor {

  static calculeValorSabores( template: any, sabores: Array<SaborPizzaLoja> ){
    let tipoDeCobranca = template.tipoDeCobranca;
    let preco = 0;

    if(tipoDeCobranca === 'maior'){
      let maiorPreco =  sabores[0].preco;

      sabores.forEach( (saborPizza: SaborPizzaLoja) => {
        if(saborPizza.preco > maiorPreco)
          maiorPreco = saborPizza.preco
      } )
      preco =  maiorPreco
    } else  if(tipoDeCobranca === 'media'){
      let totalSabores = sabores.reduce((sum: number, item: any) => item.qtde + sum, 0);

      let media = sabores.reduce( (sum: any, sabor: any) => sum + sabor.qtde * sabor.preco, 0) / totalSabores;
      preco = media;
    } else {
      throw Error('Tipo de conbraça não suportado: ' + tipoDeCobranca)
    }

    if(template.taxaExtra > 0 && sabores.length  > 1)
      preco += template.taxaExtra

    return Number (preco.toFixed(2) );

  }


}
