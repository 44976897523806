import {Component, Input, OnInit} from '@angular/core';
import {ContatosService} from "../services/contatos.service";
import {CurrencyPipe} from "@angular/common";
import {setTime} from "@progress/kendo-angular-dateinputs/dist/es2015/util";
import * as _ from 'underscore';
import {SeriesClickEvent} from "@progress/kendo-angular-charts";
import {DeviceDetectorService} from "ngx-device-detector";
import {RelatoriosComponent} from "../relatorios/relatorios.component";
import {ColorUtils} from "../ColorUtils";

@Component({
  selector: 'app-tela-grafico-comparacao',
  templateUrl: './tela-grafico-comparacao.component.html',
  styleUrls: ['./tela-grafico-comparacao.component.scss']
})
export class TelaGraficoComparacaoComponent implements OnInit {
  static contador = 0;

  public data: any[] = [];
  relatorio1: any;
  relatorio2: any;
  public mapData: any = {};
  @Input() campo = 'totalGasto';

  public data2: any[] = [];
  public data3: any[] = [];
  public mapData2: any = {};

  public objeto: any;

  carregou = false;

  mes1: any;
  mes2: any;
  descMes1: any;
  descMes2: any;
  @Input() tipoGrafico: any = 'donut';

  id: string;
  ehCelular = false;
  maiorValor = 0.0;

  public valueAxes: any[] = [{
    title: '',
    min: 0,
    majorUnit: 200,
    max: 1000
  }];

  public crossingValues: number[] = [0, 0, 10, 10];

  constructor(private contatosService: ContatosService, private cp: CurrencyPipe, private deviceService: DeviceDetectorService) {
    this.objeto = this;

    this.ehCelular = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.id = (TelaGraficoComparacaoComponent.contador ++) + '';
  }

  public labelContent(e: any): string {
    let percentual = '';

    if( e.percentage ) {
      percentual = ' (' + (100.0 * e.percentage).toFixed(2) + '%)'
    }

    return e.dataItem.rotulo2 + percentual;
  }

  public labelContentLabels(e: any): string {
    if( e.dataItem == null ) {
      return '';
    }

    let percentual = '';

    return e.dataItem.rotulo2;
  }

  obtenhaRegistro(mapData: any, value) {
    const registro = mapData[value];

    if( !registro ) {
      return {};
    }

    return registro;
  }

  obtenhaQtdeVisitas(value) {
    const registro = this.mapData[value];

    if( !registro ) {
      return '';
    }

    if( registro.mediaVisitas === 1 ) {
      return '1 visita';
    }

    return parseFloat(registro.mediaVisitas).toFixed(2).replace('.', ',') + ' visitas';
  }

  obtenhaTempoRetornar(mapData, value) {
    const registro = mapData[value];

    if( !registro ) {
      return '';
    }

    return parseFloat(registro.tempoRetorno).toFixed(1).replace('.', ',');
  }

  obtenhaTotalGasto(mapData, value) {
    const registro = mapData[value];

    if( !registro || !registro.totalGasto) {
      return 0;
    }

    return parseFloat(registro.totalGasto);
  }

  public atualizeRelatorio(mes1: any, mes2: any, relatorio1: any, relatorio2: any) {
    this.mes1 = mes1;
    this.mes2 = mes2;

    this.descMes1 = relatorio1.descMes;
    this.descMes2 = relatorio2.descMes;

    const registros1 = this.processe(this.mapData, relatorio1.registros);
    this.processe(this.mapData2, relatorio2.registros);

    const lista1 = [];
    const lista2 = [];

    this.maiorValor = 0;

    for( let registro of registros1 ) {
      const registro2 = this.mapData2[registro.rotulo];

      const valor = registro[this.campo];
      const valor2 = registro2[this.campo];

      if( this.maiorValor < valor ) {
        this.maiorValor = valor;
      }
      if( this.maiorValor < valor2 ) {
        this.maiorValor = valor2;
      }
      if( valor === 0 ) {
        if( valor2 === 0 ) {
          continue;
        }
      }

      lista1.push(registro);
      lista2.push(registro2);
    }

    this.data = lista1;
    this.relatorio1 = relatorio1.dados;

    this.data2 = lista2;
    this.relatorio2 = relatorio2.dados;

    this.carregou = true;
  }

  public processe(mapData: any, registros) {
    const lista = [];

    for( let _registro of registros ) {
      const registro = _.clone(_registro);

      let rotulo = '';

      if( this.tipoGrafico === 'donut' ) {
        rotulo = registro.rotulo;
      }

      if( this.campo === 'qtdeClientes' ) {
        registro.rotulo2 = rotulo + ' ' + registro[this.campo] + ' clientes';
      } else if( this.campo === 'totalGasto' ) {
        registro.rotulo2 = rotulo + ' ' + this.cp.transform(registro[this.campo], 'BRL');
      }

      mapData[registro.rotulo] = registro;
      mapData[registro.rotulo2] = registro;

      lista.push(registro);
    }

    return lista;
  }

  onSeriesClick($event: SeriesClickEvent) {
    alert(4);
  }

  public obtenhaCor(registro: any) {
    return ColorUtils.obtenhaCorStatic(registro);
  }
}
