
export class Endereco {
  id: number;
  descricao: string;
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  numero: string;
  cidade: any;
  estado: any;
  descricaoCompleta: string
  pontoDeReferencia: string
  zonaDeEntrega: any
  localizacao: string;
  constructor(id: number, cidade: any, cep: string, logradouro: string,
              complemento: string, bairro: string, numero: any, descricao: string = null) {
    this.id  = id;
    this.cidade = cidade;
    this.cep = cep;
    this.logradouro = logradouro;
    this.complemento = complemento;
    this.bairro = bairro;
    this.numero = numero;
    this.descricao = descricao;
  }

  // tslint:disable-next-line:member-ordering
  static novo() {
    return new Endereco(null , null, null, null, null,  null, null,
      null);
  }

  static fromJson(dadosEndereco: any): Endereco {
    const objetoEndereco = new Endereco(dadosEndereco.id, dadosEndereco.cidade, dadosEndereco.cep,
      dadosEndereco.logradouro, dadosEndereco.complemento, dadosEndereco.bairro, dadosEndereco.numero,
      dadosEndereco.descricao);

    objetoEndereco.pontoDeReferencia = dadosEndereco.pontoDeReferencia
    objetoEndereco.zonaDeEntrega = dadosEndereco.zonaDeEntrega
    objetoEndereco.localizacao = dadosEndereco.localizacao

    return objetoEndereco;
  }

  obtenhaEnderecoCompleto() {
    let endereco = this.logradouro || '' ;


    if(this.descricao) endereco = this.descricao  + ": " + endereco

    if(this.complemento)  endereco  = endereco + ', ' + this.complemento;

    if(this.numero) endereco = endereco + ' ' + this.numero

    endereco = endereco[0].toUpperCase() + endereco.slice(1).toLocaleLowerCase()

    if(this.bairro) endereco = endereco +  ' ' + this.bairro[0].toUpperCase() + this.bairro.slice(1).toLocaleLowerCase();

    if(this.pontoDeReferencia) endereco  = endereco + ', Ponto de referência: ' + this.pontoDeReferencia;

    if(this.cidade)  endereco = endereco + ", " + this.cidade.nome + "/" + this.cidade.estado.sigla;

    return endereco;
  }
}
