<h4 class="page-title">
  <img src="/assets/icons/logo-pagarme.png" class="logo-pagarme">
  Ativação nova Loja
</h4>

<div class="card mt-3">
  <div class="card-box">

    <div class="alert alert-danger mb-2 mt-3" role="alert"   *ngIf="erroInstalacao">
      <p>{{erroInstalacao}}</p>
    </div>


    <ng-container *ngIf="ativando" >
      <p> Ativando nova conta <b>pagar-me</b>...</p>
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>

    </ng-container>

    <ng-container *ngIf="dadosAtivacao">
      <div class="text-center" style="max-width: 500px">
        <img src="/assets/icons/icon-success.png" height="100px"  >

        <h4>Integração com a conta da loja no <b>Pagarme</b> realizada com sucesso ! </h4>

        <a [routerLink]="'/admin/loja/formaspagamento/online'"  class="btn btn-blue">Ver formas de pagamentos</a>
      </div>

    </ng-container>
  </div>
</div>
