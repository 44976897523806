<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a routerLink="/superadmin/empresas">Empresas</a></li>
          </ol>
        </div>
        <h4 _ngcontent-mki-c5="" class="page-title">
          <i class="fab fa-whatsapp font-22" style="color: #199d0f;font-weight: bold"></i>

          Performance Lojas no Whatsapp
        </h4>
      </div>
    </div>
  </div>
</div>

<kendo-daterange  class="mr-2" >
  <label>
    <span class="label lh">De <br></span>
    <kendo-dateinput style="width: 16em"  [(ngModel)]="filtro.inicio" kendoDateRangeStartInput
                     (ngModelChange)="limpeDataFinal()">
      <kendo-dateinput-messages
        today="Hoje"
        toggle="Alterar data"
        cancel="Cancelar"
        dateTab="Data"
        timeTab="Hora"
        now="Agora"
        accept="Ok"
      ></kendo-dateinput-messages>

    </kendo-dateinput>
  </label>
  <label class="ml-2 ">
    <span class="label  lh">Até </span><br>
    <kendo-dateinput style="width: 16em" [(ngModel)]="filtro.fim" kendoDateRangeEndInput
                     (ngModelChange)="carregueRelatorio();" (valueChange)="fechePopup(popup)">
      <kendo-dateinput-messages
        today="Hoje"
        toggle="Alterar data"
        cancel="Cancelar"
        dateTab="Data"
        timeTab="Hora"
        now="Agora"
        accept="Ok"
      ></kendo-dateinput-messages>
    </kendo-dateinput>
  </label>
  <kendo-daterange-popup
    #popup
  ></kendo-daterange-popup>

  <label class="ml-2 ">
    <button class="btn btn-primary" (click)="carregueRelatorio()">Buscar</button>
  </label>
</kendo-daterange>

<kendo-grid [data]="gridData"
            [style]=""
            [loading]="loading" style="min-height: 300px;"
            footerStyle="font-size: 11px;">
  <ng-template kendoGridToolbarTemplate>
    <button type="button" kendoGridExcelCommand icon="file-excel">Exportar para Excel</button>
  </ng-template>

  <kendo-grid-column field="indice" title="-"   width="70">  </kendo-grid-column>
  <kendo-grid-column field="id" title="ID"   width="70">  </kendo-grid-column>
  <kendo-grid-column field="nome" title="Nome"   width="200">  </kendo-grid-column>
  <kendo-grid-column field="qtdeConversas" title="Qtde. Conversas Iniciadas por Cliente"   width="150">  </kendo-grid-column>
  <kendo-grid-column field="qtdeMensagensCampanha" title="Qtde. Mensagems Campanhas"   width="150">  </kendo-grid-column>
  <kendo-grid-column field="qtdeDePedidos" title="Qtde. Pedidos"   width="200">  </kendo-grid-column>

  <kendo-grid-excel fileName="PerformanceWhatsapp.xlsx"></kendo-grid-excel>
</kendo-grid>
