import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresaService} from "../../services/empresa.service";

@Component({
  selector: 'app-tela-integracao-ifood',
  templateUrl: './tela-integracao-ifood.component.html',
  styleUrls: ['./tela-integracao-ifood.component.scss']
})
export class TelaIntegracaoIfoodComponent implements OnInit {
  @Input() empresa: any = {};
  sincronizando  = false;
  novaIntegracao: any;
  mensagemErro: string;
  constructor(private  empresaService: EmpresaService) { }

  ngOnInit(): void {
    this.empresaService.obtenhaAtivacaoPendente(this.empresa).then((ifooodUserCode: any) => {
      if(ifooodUserCode){
        this.novaIntegracaoIfood();
        this.novaIntegracao.ifooodUserCode = ifooodUserCode;
      } else {
        if(!this.empresa.integracoesIfood.length)
           this.novaIntegracaoIfood()
      }
    })
  }

  novaIntegracaoIfood(){
    this.novaIntegracao  = { nova: true, orderApi: true}
  }

  sincronizarFormasPagamentos(){
    this.sincronizando = true;

    this.empresaService.sincronizeFormasPagamentoIfood().then((res: any) => {
      this.sincronizando = false;
    }).catch( (erro) => {
      this.mensagemErro = erro;
      this.sincronizando = false
      alert(erro)
    })
    return false;
  }
}
