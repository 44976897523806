import { Component, OnInit } from '@angular/core';
import {CuponsService} from "../../../superadmin/services/cupons.service";
import {
  TelaAssociarCupomContatosComponent
} from "../../tela-associar-cupom-contatos/tela-associar-cupom-contatos.component";
import {CadCupomComponent} from "../../cad-cupom/cad-cupom.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {GroupDescriptor, process} from "@progress/kendo-data-query";
import {GridDataResult} from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-grid-cupons',
  templateUrl: './grid-cupons.component.html',
  styleUrls: ['./grid-cupons.component.scss']
})
export class GridCuponsComponent implements OnInit {
  cupons:  any = []
  mensagemCopiada: string;
  cupomRestrito: any;
  modalDeletar: boolean;
  cupomApagar: any;
  carregando: boolean;
  public gridDataResult: GridDataResult;
  public groups: GroupDescriptor[] = [{ field: 'status' }];
  constructor( private dialogService: DialogService,
               private cuponsService: CuponsService) { }

  ngOnInit(): void {
  }

  setCupons(cupons: any) {
    this.cupons = cupons;
    this.cupons.forEach((cupom: any) => {
       if(cupom.ativo && !cupom.vencido){
         cupom.status = 'Ativos'
       } else {
         if(cupom.vencido){
           cupom.status = 'Vencidos'
         } else {
           cupom.status = 'Desativados'
         }
       }
    })
    this.gridDataResult = process(this.cupons, { group: this.groups });
    this.carregando = false;
  }

  abraDialogRemover($event: any) {
    this.cupomApagar = $event.dataItem;

    this.modalDeletar = true;
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.mensagemCopiada = val;
  }

  exibaListaContatos(cupom: any) {
    if(cupom.restrito){
      this.cupomRestrito = cupom;
    }
  }

  canceleRemocao() {
    this.modalDeletar = false;
    delete    this.cupomApagar;
  }

  confirmeRemocao() {
    let i = this.cupons.indexOf(this.cupomApagar);

    this.cuponsService.removaCupom(this.cupomApagar).then( () => {
      this.cupons.splice(i, 1);
    }).catch( erro => {
      alert(erro)
    })

    this.canceleRemocao();

  }

  desativarCupom(cupom: any) {
    cupom.ativo = false;
    this.cuponsService.desativeCupom(cupom).then( () => {
    }).catch( erro => {
      cupom.ativo = true;
      alert(erro)
    })
  }

  ativarCupom(cupom: any) {
    cupom.ativo = true;
    this.cuponsService.ativeCupom(cupom).then( () => {

    }).catch( erro => {
      cupom.ativo = false;
      alert(erro)
    })
  }

  criarCupom() {
    this.abraModalCupom();

  }

  editarCupom(cupom: any) {
    this.abraModalCupom(cupom);
  }

  private abraModalAssociarContatos(cupom: any = null){
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: String(`Cupom ${cupom.nome}  `),
      content: TelaAssociarCupomContatosComponent,
      minWidth: 500,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaCupom: TelaAssociarCupomContatosComponent = windowRef.content.instance;

    telaCupom.windowRef = windowRef;

    if(cupom && cupom.id)
      telaCupom.cupom = cupom;

    windowRef.result.subscribe((result: any) => {

    });
  }

  private abraModalCupom(cupom: any = null) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Cupom ',
      content: CadCupomComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: altura
    });

    const telaCupom: CadCupomComponent = windowRef.content.instance;

    telaCupom.windowRef = windowRef;

    if(cupom && cupom.id)
      telaCupom.cupom = cupom;

    windowRef.result.subscribe((result: any) => {
      if(result && result.id){
        this.carregando = true;
        this.cupons = [];
        this.cuponsService.listeCupons({ listavel: true }).then( (resposta) => {
          this.setCupons(resposta)
        })
      }

    });
  }

  getDia(dia: number){
    let dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']


    return dias[dia]

  }



}
