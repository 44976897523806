import {Empresa} from "./Empresa";

export class Foto {
  id: number;
  titulo: string;
  descricao: string;
  link: string;
  empresa: Empresa;
  constructor(id: number= null, titulo: string= null, descricao: string= null, link: string= null, empresa: Empresa= null){
    this.id = id;
    this.titulo = titulo;
    this.descricao = descricao;
    this.link = link;
    this.empresa = empresa;
  }
}
