<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit(frm, configMensagemDeBot)">
  <div #pnl class="modal-body" style="max-width: 1024px;">
    <div class="alert alert-success mt-2" role="alert" *ngIf="configMensagemDeBot.mensagemSucesso" (click)="fecheMensagemSucesso()">
      <i class="mdi mdi-check-all mr-2"></i> {{configMensagemDeBot.mensagemSucesso}}
    </div>
    <div class="form-group mb-3">
      <div class="row">
        <div class="col">
          <label  >Encurtar Links</label>
          <div class="ui-g" style="width:250px;margin-bottom:10px">
            <kendo-switch [(ngModel)]="configMensagemDeBot.encurtarLinks" name="encurtarLinks" id="encurtarLinks"></kendo-switch>
          </div>

        </div>
      </div>

    </div>

    <div class="form-group mb-3" style="position: relative;">
      <label for="txtMensagem">Mensagem</label>
      <div>
        <div style="position: absolute;right: 0px;top: -13px;">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-blue dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>Adicionar Campo <i class="mdi mdi-chevron-down"></i></button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button type="button"  ngbDropdownItem (click)="adicioneCampo(textArea, configMensagemDeBot, campo)" *ngFor="let campo of campos">
                {{campo.nome}}
              </button>
            </div>
          </div>
        </div>

        <textarea class="form-control" id="txtMensagem" rows="10" name="txtMensagem" [(ngModel)]="configMensagemDeBot.mensagem" #txtMensagem="ngModel" #textArea
                  required appAutoFocus (ngModelChange)="alterouMensagem(configMensagemDeBot)" [maxlength]="QTDE_MAXIMA_CARACTERES"></textarea>
        <div>
          <span [ngClass]="{'text-warning': configMensagemDeBot.qtdeCaracteresRestantes < 50, 'text-success': configMensagemDeBot.qtdeCaracteresRestantes >= 50, 'text-danger': configMensagemDeBot.qtdeCaracteresRestantes < 20}">{{configMensagemDeBot.qtdeCaracteresRestantes}} caracteres restantes</span>
        </div>
        <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched || frm.submitted)" class="invalid-feedback">
          <div *ngIf="txtMensagem.errors.required">
            Informe uma mensagem que será enviada ao cliente.
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="text-center mb-1">
      <button type="submit" class="btn btn-primary width-lg" >Salvar</button>
    </div>
  </div>
</form>
