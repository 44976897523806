import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {MudarStatusComponent} from "../componentes/mudar-status/mudar-status.component";
import {ActivatedRoute} from "@angular/router";
import {PedidosService} from "../services/pedidos.service";
import {TelaExpandidaComponent} from "../pedidos/TelaExpandida";

@Component({
  selector: 'app-tela-mudar-status',
  templateUrl: './tela-mudar-status.component.html',
  styleUrls: ['./tela-mudar-status.component.scss']
})
export class TelaMudarStatusComponent extends TelaExpandidaComponent implements OnInit {
  @ViewChild('mudarStatusComponent', { static: true}) mudarStatusComponent: MudarStatusComponent;
  public contato: any = {};
  public empresa: any = {};
  public pedido: any = {};
  carregou: any;

  constructor(private _location: Location, private activatedRoute: ActivatedRoute,
              private pedidosService: PedidosService) {
    super();
  }

  ngOnInit() {

    this.pedidosService.obtenhaPorCodigo(this.activatedRoute.snapshot.params.codigo).then((pedido) => {

      this.pedido = pedido || {};
      this.contato = pedido.cliente;
      this.empresa = pedido.empresa;
      this.carregouPedido();

    })

  }

  carregouPedido(){
    this.carregou = true;
    this.activatedRoute.queryParams.subscribe(params => {

      this.mudarStatusComponent.urlRetorno  = params.urlRetorno;
    })
  }

  voltar() {
    this._location.back();
  }
}
