import { Injectable } from '@angular/core';
import {ServerService} from "../../services/ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CatalogosService extends  ServerService {
  constructor(http: HttpClient) {
    super(http)
  }

  listeCatalogos(filtro: any = {}) {
    return this.obtenha('/catalogos', filtro)
  }

  listeProdutosRede(idCatalogoRede) {
    return this.obtenha('/catalogos/produtos-rede/' + idCatalogoRede, {})
  }

  listeNovosProdutosdDoModelo(sincronizarPrecos: boolean, sincronizarImagens: boolean) {
    let params: any = {}
    if(sincronizarPrecos) params.precos = true;
    if(sincronizarImagens) params.imagens = true;

    return this.obtenha('/catalogos/produtos-rede/me/novos', params)
  }

  obtenhaCatalogo(id: any) {
    return this.obtenha('/catalogos/' + id, {})
  }

  salveCatalogo(catalogo: any) {
    return this.salve('/catalogos', catalogo)
  }

  altereCatalogoDaEmpresa(empresa: any) {
    return this.salve('/catalogos/altere/empresa/' + empresa.id + '/' + empresa.catalogo.id, {})
  }

  altereModeloCatalogoDaEmpresa(empresa: any) {
    return this.facaPost('/catalogos/empresa/' + empresa.id + '/modelo', empresa)
  }

  removaCategoria(catalogo: any, categoriaExcluir: any) {
    return this.http.delete('/catalogos/' + catalogo.id + '/categoria/' + categoriaExcluir.id).toPromise()
      .then(this.retorno).catch(this.handleError);
  }

  reordenarCategorias(catalogo: any){
    return this.facaPost(String(`/catalogos/${catalogo.id}/categorias/reset`), {})
  }

  salveCategoria(catalogo: any, categoria: any, rede: boolean = false) {
    if(rede)
      categoria.rede = true;
    return this.http.post('/catalogos/' + catalogo.id + '/categoria', categoria)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  atualizeCategorias(catalogo: any, categorias: any) {
    return this.http.put('/catalogos/' + catalogo.id + '/categorias/posicoes', categorias)
      .toPromise().then(this.retorno).catch(this.handleError);
  }


}
