import {IUITipoDePontuacao} from "./IUITipoDePontuacao";
import {UITipoDePontuacaoCashback} from "./UITipoDePontuacaoCashback";

export class UITipoDePontuacaoQtdeVariavelAtividade implements IUITipoDePontuacao {
  empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any, itens: any) {
    if (!pontuacaoRegistrada) return 0;

    let atividade = pontuacaoRegistrada.atividades[0];

    if(itens && itens.length){
      return itens.reduce((soma: number, item: any) =>  soma + (  item.obtenhaPontosFidelidade(atividade )), 0)
    } else {
      if(atividade.cashback)
        pontuacaoRegistrada.pontosInformado = new UITipoDePontuacaoCashback(this.empresa).calculePontuacao(pontuacaoRegistrada)
    }

    return pontuacaoRegistrada.pontosInformado || 0;
  }
}
