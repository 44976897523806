export class ColorUtils {
  public static obtenhaCorStatic(registro: any) {
    let cor = '#28B4C8';

    if( registro.rotulo === 'Novo' ) {
      cor = '#28B4C8';
    } else if( registro.rotulo === 'Novo Importado' ) {
      cor = '#2798a6';
    } else if( registro.rotulo === 'VIP' ) {
      cor = '#2DCB75';
    } else if( registro.rotulo === 'VIP Em Risco' ) {
      cor = '#59e494';
    } else if( registro.rotulo === 'Recorrente' ) {
      cor = '#2060ce';
    } else if( registro.rotulo === 'Recorrente Risco' ) {
      cor = '#5191ff';
    } else if( registro.rotulo === 'Regular' ) {
      cor = '#FF7300';
    } else if( registro.rotulo === 'Regular Em Risco' ) {
      cor = '#ffbe74';
    } else if( registro.rotulo === 'Em Risco' ) {
      cor = '#FFD246';
    } else if( registro.rotulo === 'Perdido' ) {
      cor = '#ee503d';
    }

    return cor;
  }
}
