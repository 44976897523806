import {Component, OnInit, ViewChild} from '@angular/core';
import {ConstantsService} from "../ConstantsService";
import {FormFormasEntregaComponent} from "../../superadmin/form-formasdeentrega/form-formasdeentrega.component";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ActivatedRoute} from "@angular/router";
import {TabStripComponent} from "@progress/kendo-angular-layout";

@Component({
  selector: 'app-tela-config-empresa',
  templateUrl: './tela-config-empresa.component.html',
  styleUrls: ['./tela-config-empresa.component.scss']
})
export class TelaConfigEmpresaComponent implements OnInit {
  @ViewChild('tabstrip', { static: false }) private tabstrip: TabStripComponent;
  @ViewChild('frmFormaEntrega') frmFormaEntrega: FormFormasEntregaComponent;
  @ViewChild('appFotos') appFotos: any;
  empresa: any;
  usuario: any;
  salvando: boolean;
  mensagemSucesso: any;
  agora: Date;
  fusos = [{ descricao: 'UTC -02:00 (Fernando Noronha Time - FNT)', valor: -2},
    { descricao: 'UTC -03:00 (Brasília Time - BRT)', valor: -3 },
    { descricao: 'UTC -04:00 (Amazonas Time - AMT)', valor: -4 },
    { descricao: 'UTC -05:00 (Acre Time - ACT)', valor: -5 }
  ];
  fusoHorario: any;
  nomeTabFormasPagamento = 'Formas de Pagamento';
  constructor(private constantsService: ConstantsService, private autorizacaoService: AutorizacaoService,
              private  activatedRoute: ActivatedRoute,
              private empresasService: EmpresasService) {
    this.agora = new Date();

  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();

    this.constantsService.empresa$.subscribe( empresa => {
      if(empresa){
        this.empresa = empresa;
        this.setFusoEmpresa();
        setTimeout( () => {
          if(this.frmFormaEntrega) this.frmFormaEntrega.exibaFormasEmpresa();

          let formapagamento = this.activatedRoute.snapshot.params.formapagamento;
          let parceiro = this.activatedRoute.snapshot.params.parceiro;

          if(formapagamento) this.exibaFormapagamentoOnline()

          if(parceiro) this.exibaAbaIntegracaoParceiro(parceiro)

          }, 0)
      }

    });

  }

  exibaFormapagamentoOnline(meiopagamento: string = 'pagarme'){
    //meiopagamento === 'pagarme'

    const tabs = this.tabstrip.tabs.toArray(); // Obtenha todas as abas como um array
    const tabFormasPagamento: any = tabs.find((_tab: any) => _tab.title ===  this.nomeTabFormasPagamento);

    if (tabFormasPagamento)
      this.tabstrip.selectTab(tabs.indexOf(tabFormasPagamento))
  }

  exibaAbaIntegracaoParceiro(parceiro: string){
    const tabs = this.tabstrip.tabs.toArray(); // Obtenha todas as abas como um array
    const tabFormasPagamento: any = tabs.find((_tab: any) => _tab.title.toUpperCase().indexOf(parceiro.toUpperCase()) >= 0);

    if (tabFormasPagamento)
      this.tabstrip.selectTab(tabs.indexOf(tabFormasPagamento))
  }

  moduloAtivo(nome: string) {
    if(!this.empresa) return false;

    return this.empresa.modulos.find( modulo => modulo.nome === nome) != null;
  }


  salveConfig() {
    if(this.salvando) return;

    this.salvando = true;

   this.empresasService.atualizeConfigPedidoMesaNaoIndentificada(this.empresa).then( () => {
     this.salvando = false;
     this.mensagemSucesso = 'Configuração salva com sucesso!'
   }).catch( (erro: string) => {
     this.salvando = false;
     alert(erro)
   })

  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }


  private setFusoEmpresa() {
    this.fusoHorario = this.fusos.find( fuso => fuso.valor === this.empresa.fusoHorario);
  }


  selecionouFuso() {

    this.empresasService.atualizeFusoHorario(this.empresa, this.fusoHorario.valor).then( () => {
      this.empresa.fusoHorario = this.fusoHorario.valor;
    }).catch((err) => {
      this.setFusoEmpresa();
      alert(err)
    })
  }
}
