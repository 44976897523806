import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class TemplatesPromptsDbService extends ServerService {
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  liste(global: boolean) {
    //adiciona o parametro global apenas se for true
    let url = '/prompt-templates-db/liste';
    if(global) {
      url += '?g=1';
    }

    return this.http.get(url)
    .toPromise().then(this.retorno).catch(this.handleError);
  }

  carrgueTemplatePrompt(id: number, global: boolean) {
    let url = '/prompt-templates-db/' + id;
    if(global) {
      url += '?g=1';
    }

    return this.obtenha(url, {});
  }

  salveTemplatePrompt(templatePrompt: any) {
    return this.salve('/prompt-templates-db', templatePrompt);
  }

  salvarTrechoDePromptNoTemplate(templateId: number, trechoDePrompt: any, global: any) {
    trechoDePrompt.global = global;

    return this.salve('/prompt-templates-db/' + templateId + '/salvar-trecho', trechoDePrompt);
  }

  ativarDesativarTrechoDePrompt(templateId: any, idTrecho: number, ativo: boolean) {
    return this.facaPost('/prompt-templates-db/' + templateId + '/ativar-desativar-trecho/' +
      idTrecho, {ativo: ativo});
  }
}
