<kendo-dialog-titlebar (close)="fecheModal()"   [hidden]="exibirApenasAtualizarEntregador">
  <h4 class="modal-title" id="myModalLabel">
    Mudar Status do Pedido {{pedido.id}}
  </h4>
</kendo-dialog-titlebar>

<div   class="modal-body">
  <div class="row"  [hidden]="!pedido.id" >

    <div class="col-12 mt-2">
      <info-contato [contato]="contato" [exibirUltimaVisita]="false" [empresa]="empresa"
                    *ngIf="!exibirApenasAtualizarEntregador && !pedido.balcao"></info-contato>

      <div class="card contato">
        <div class="card-body">
          <ng-container *ngIf="pedido.id && !exibirApenasAtualizarEntregador">
            <info-pedido [contato]="contato"  [pedido]="pedido" [modoGrupoLojas]="modoGrupoLojas" [permiteAlterarStatus]="false" [modal]="true"></info-pedido>

            <hr  />
          </ng-container>

          <mudar-status (onAtualizouStatus)="alterouStatusPedido($event)" [pedido]="pedido"   [contato]="contato" [modoGrupoLojas]="modoGrupoLojas"
                        [exibirApenasAtualizarEntregador]="exibirApenasAtualizarEntregador" [novoStatus]="novoStatus"></mudar-status>
        </div>
      </div>

    </div>

  </div>
</div>
