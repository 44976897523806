<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="form-group mb-3 col" *ngIf="!importou">
        <label for="arquivo">Arquivo csv: </label>
        <kendo-upload #uploadComponent id="arquivo" name="arquivo"   (select)="selecionouArquivo($event)"
                      [(ngModel)]="campanha.files" #arquivo="ngModel" [saveUrl]="'/campanha/validarContatos'"
                      [multiple]="false"  [restrictions]="restricoes" required
                      (error)="uploadFalhou($event)"
                      (success)="enviouArquivoDeContatos($event)">
          <kendo-fileselect-messages
            select="Selecione..."
            clearSelectedFiles="Limpar">
          </kendo-fileselect-messages>
        </kendo-upload>
        <div class="alert alert-danger" role="alert" *ngIf="msgErroUpload">
          <i class="fas fa-exclamation-triangle"></i>
          <span class="msg-erro">
                      {{msgErroUpload}}
                    </span>
        </div>
        <div class="invalid-feedback">
          <p *ngIf="arquivo.errors?.required">Arquivo é obrigatório</p>
        </div>
      </div>
    </div>

    <ng-container *ngIf="contatos.length > 0">
      <div class="card contatos pt-2">
        <div *ngFor="let contato of contatos | slice:0:5" class="card-box contato">
          <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
              <card-contato [contato]="contato"></card-contato>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>{{contatos.length}} contatos carregados.</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
