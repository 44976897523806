import {Injectable} from "@angular/core";
import {ServerService} from "../../services/ServerService";

@Injectable({
  providedIn: 'root'
})
export class RecebimentoService  extends  ServerService {
  obtenhaAssinaturas(inicio: number, total: number, filtro = {}){
    let params = { i: inicio, t: total};

    Object.assign(params, filtro)

    return this.obtenha('/recebimentos/assinaturas', params)
  }

  obtenhaFaturas(inicio: number, total: number, filtro = {}){
    let params = { i: inicio, t: total};

    Object.assign(params, filtro)

    return this.obtenha('/recebimentos/faturas', params)
  }

  obtenhaFaturasComResumo(assinatura){
    return this.obtenha(String(`/recebimentos/assinatura/${assinatura.id}/resumo`), { });
  }

  obtenhaResumoPeriodo(dtInicio: any, dtFim: any){
    return this.obtenha('/recebimentos/competencia/resumo', {dic: dtInicio, dfc: dtFim});
  }

  listeResumoRecebimentosEcletica(ano: string , mes: string) {
    return this.obtenha(String(`/iugu/recebimentos/${ano}/${mes}`), { });
  }
}
