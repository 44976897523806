import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CampanhaRedeService} from "../../../services/campanha-rede.service";

@Component({
  selector: 'app-exibir-campanha-rede',
  templateUrl: './exibir-campanha-rede.component.html',
  styleUrls: ['./exibir-campanha-rede.component.scss']
})
export class ExibirCampanhaRedeComponent implements OnInit {
  @Input() campanha: any = {};
  @Input() modoAprovacao: boolean;

  mensagem = '';
  mensagemWarning = '';
  enviando = false;
  mensagemErro: any;
  mensagemSucesso: string;

  constructor(private router: Router, private campanhaRedeService: CampanhaRedeService) { }

  ngOnInit(): void {
  }

  editarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin-rede/marketing/nova-campanha/' + campanha.id, {}).then( () => {});
  }

  verDetalhesCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin-rede/campanha/' + campanha.id + '/detalhes' , {state: {data: campanha }}).
      then( () => {});
  }

  testarCampanha(campanha: any) {
    this.router.navigateByUrl( `/admin-rede/tela-enviar-mensagens/${campanha.id}` , {state: {data: campanha }}).
    then( () => {});
  }

  replicarCampanha(campanha: any) {
    this.enviando = true;
    this.campanhaRedeService.replicarCampanha(campanha).then( resp =>   {
      this.mensagemSucesso = resp;
      campanha.foiReplicada = true;
    }).catch(erro => {
      this.enviando = false;
      this.mensagemErro = erro;
      alert(erro);
    });
  }


  desativarCampanha(campanha: any) {
    this.enviando = true;
    this.campanhaRedeService.desative(campanha).then( resp =>   {
      this.mensagemSucesso = 'Campanha desativada com sucesso!';
      this.enviando = false;
      campanha.ativa = false;
    }).catch(erro => {
      this.enviando = false;
      this.mensagemErro = erro;
    });
  }

  ativarCampanha(campanha: any) {
    this.enviando = true;
    this.campanhaRedeService.ative(campanha).then( resp =>   {
      campanha.ativa = true;
      this.mensagemSucesso = 'Campanha ativada com sucesso!';
      this.enviando = false;
    }).catch(erro => {
      this.enviando = false;
      this.mensagemErro = erro;
    });
  }

  verQuemAceitouCampanha(campanha: any) {
    this.router.navigateByUrl( `/admin-rede/marketing/lista-empresas/${campanha.id}` , {state: {data: campanha }}).
    then( () => {});
  }
}
