import {ConstantsService} from "../ConstantsService";
import {VendasPedidoService} from "../../services/vendas-pedido.service";
import {PedidosService} from "../../services/pedidos.service";
import {GridPedidosComponent} from "../../pedidos/grid-pedidos/grid-pedidos.component";
import {CuponsService} from "../../superadmin/services/cupons.service";
import {ImpressaoService} from "../../services/impressao.service";
import {Component, OnInit, ViewChild} from "@angular/core";

import {ActivatedRoute, Router} from "@angular/router";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {
  aggregateBy,
  AggregateDescriptor,
  AggregateResult, GroupDescriptor,
  orderBy,
  process,
  SortDescriptor
} from "@progress/kendo-data-query";
import {ExcelExportData} from "@progress/kendo-angular-excel-export";
import {RedesService} from "../../services/redes.service";
import {ProcessadorReciboTexto} from "../../services/impressao/ProcessadorReciboTexto";
import {PromocoesService} from "../../compartilhado/services/promocoes.service";
import {SelectEvent} from "@progress/kendo-angular-layout";

declare var moment;
@Component({
  selector: 'app-financeiro-delivery',
  templateUrl: './financeiro-delivery.component.html',
  styleUrls: ['./financeiro-delivery.component.scss', '../financeiro/financeiro.component.scss']
})
export class FinanceiroDeliveryComponent implements OnInit {
  @ViewChild('gridPedidos', { static: false}) gridPedidos: GridPedidosComponent;
  @ViewChild('tabVendas', { static: false}) tabVendas: any;
  filtro: any = {pagos: true, naoPagos: false, delivery: true, mesa: false};

  empresa: any = {};
  resumoVendas = {
    empresa: this.empresa,
    dataInicial: this.filtro.inicio,
    dataFinal: this.filtro.fim,
    total: 0,
    totalComTaxa: 0,
    totalPagamentosComTaxa: 0,
    taxaDeEntrega: 0,
    totalTaxaFormaPagamento: 0,
    qtde: 0,
    qtdeLinhas: 0,
    ticketMedio: 0,
    formasDePagamento:  [],
    formasDeEntrega:  [],
    cupons:  [],
    lojas:  [],
    diarias: [],
    promocoes: []
  }
  resumoProdutos: any = {
    produtos: [],
    carregando: false,
    totalDescontos: 0
  }
  resumoCupons: any = {
    cupons: [],
    carregando: false
  }

  resumoPromocoes: any = {
    promocoes: [],
    carregando: false
  }
  hoje: Date = new Date();
  dataAtual = null;
  valorMes: Date = new Date();
  carregando: boolean;
  vendasDoDia: any;
  vendasProduto: any;
  cupons = []
  promocoes = []

  configImpressao: any;
  rede = false;
  usuario: any = null;
  carregandoResumoFormaPagamento: boolean
  resumoPorFormasPagamento: any = []
  sortCupom:  SortDescriptor[] = [{dir: 'desc', field: 'qtde'}  ];
  sortLoja:  SortDescriptor[] = [{dir: 'desc', field: 'qtde'}  ];
  vendasCuponsResultSet: any
  vendasPromocoesResultSet: any
  vendasLojasResultSet: any
  redes: any = [];
  periodoEspecifico: boolean;

  public aggregates: AggregateDescriptor[] = [
    { field: "qtde", aggregate: "sum" },
    { field: "total", aggregate: "sum" },
    { field: "totalDesconto", aggregate: "sum" },
  ];

  groupable: any = { showFooter: true };
  public group: GroupDescriptor[] = [
    { field: "Produtos", aggregates: this.aggregates },
  ];

  public totalCupons: AggregateResult;
  private timeoutCarregandoResumo: NodeJS.Timer;
  private sortPromocoes: SortDescriptor[] =  [{dir: 'desc', field: 'qtde'}  ];
  private totalPromocoes: AggregateResult;
  balcao = false;
  constructor(private router: Router, private constantsService: ConstantsService,
              private vendasService: VendasPedidoService, private pedidosService: PedidosService,
              private  cuponsService: CuponsService, private impressaoService: ImpressaoService,
              private promocoesService: PromocoesService,
              private autorizacaoService: AutorizacaoService, private redesService: RedesService) {

    this.rede = this.router.url.indexOf('/admin-rede') >= 0;

    if( this.router.url.indexOf('/financeiro-balcao') >= 0)
      this.balcao  = true;

    this.constantsService.empresa$.subscribe( (empresa: any) => {

      if(empresa) {
        this.empresa = empresa
        this.configImpressao = this.empresa.configImpressao;
      }
    });

    this.usuario = this.autorizacaoService.getUser();


    if(this.rede )
      this.redes = this.redesService.redesEmpresa();

    this.valorMes = new Date();

    this.mudouValorMes(this.valorMes);
  }

  ngOnInit(): void {  }

  async carregueResumoFormas() {
    return new Promise((resolve, reject) => {
      if(!this.resumoPorFormasPagamento.length)  {
        this.carregandoResumoFormaPagamento = true;

        let filtro: any = this.obtenhaFiltro();

        filtro.agfp = true;

        this.vendasService.obtenhaResumo(filtro).then( (resposta) => {
          this.resumoPorFormasPagamento = resposta.formasDePagamento || [];
          this.resumoVendas.formasDePagamento =  this.resumoPorFormasPagamento;
          this.carregandoResumoFormaPagamento = false;
          resolve('');
        }).catch((erro) => {
          this.carregandoResumoFormaPagamento = false;
          alert(erro); reject()
        })
      } else {
        resolve('');
      }
    });
  }

  mudouValorMes(mes: Date) {
    this.filtro.inicio = moment(mes).startOf('month').toDate();
    this.filtro.fim = moment(mes).endOf('month').toDate();

    this.carregueResumoVendas();
  }

  carregueResumoVendas() {
    if(this.carregando)
      clearTimeout(this.timeoutCarregandoResumo)

    this.carregando = true;

    this.timeoutCarregandoResumo = setTimeout(() => {
      this.vendasService.obtenhaResumo(this.obtenhaFiltro()).then( (resposta) => {
        this.carregando = false;
        this.resumoVendas = resposta;
        this.resumoProdutos = { produtos: [], totalDescontos: 0 };
        this.resumoPorFormasPagamento =  [];
        delete this.vendasDoDia;
        delete this.vendasProduto;
        this.vendasCuponsResultSet = orderBy(process(this.resumoVendas.cupons, {}).data, this.sortCupom);
        this.vendasPromocoesResultSet = orderBy(process(this.resumoVendas.promocoes, {}).data, this.sortPromocoes);
        this.totalCupons = aggregateBy(this.resumoVendas.cupons, this.aggregates);
        this.totalPromocoes = aggregateBy(this.resumoVendas.promocoes, this.aggregates);

        if(this.resumoVendas.lojas)
          this.vendasLojasResultSet = orderBy(process(this.resumoVendas.lojas, {}).data, this.sortLoja);

        this.cuponsService.listeCupons( {}).then( (cupons => {
          this.cupons = cupons || [];
        }))

        this.promocoesService.listePromocoes({}).then((promocoes => {
          this.promocoes = promocoes || [];
        }))


        setTimeout( () => {
          if(this.tabVendas.activeId === 'produtos')
            this.carregueResumoProdutos()
        }, 0)
      })
      }, 1000)
  }

  private carregueResumoProdutos() {

   if(!this.filtro.porAdicionais){
     this.group[0].field = 'Produtos'
     this.resumoProdutos.carregando = true;
     this.vendasService.obtenhaResumoPrudutos(this.obtenhaFiltro()).then( (resposta) => {
       this.resumoProdutos.carregando = false;
       this.resumoProdutos = resposta;
     })
   }  else {
     this.group[0].field = 'Adicionais'
     this.resumoProdutos.carregando = true;
     this.vendasService.obtenhaResumoAdicionais(this.obtenhaFiltro()).then( (resposta) => {
       this.resumoProdutos.carregando = false;
       this.resumoProdutos = resposta;
       this.resumoProdutos.produtos = resposta.adicionais;
     })
   }
  }


  obtenhaFiltro( ){
    let dados: any = {   }

    if(this.balcao) dados.bal  = true;
    else dados.delivery  =  true


    let dtInicio = this.filtro.inicio ?  moment(this.filtro.inicio).format('YYYYMMDD HH:mm:ss') : null;
    let dtFim = this.filtro.fim ? moment(this.filtro.fim).format('YYYYMMDD HH:mm:ss') : null;

    let formaDePagamento = this.filtro.formasDePagamento && this.filtro.formasDePagamento.length > 0 ?
      this.filtro.formasDePagamento.map( forma => forma.id).join(',') : null;

    let cupons = this.filtro.cupons && this.filtro.cupons.length > 0 ?
      this.filtro.cupons.map( cupon => this.rede ? cupon.codigo : cupon.id).join(',') : null;

    let promocoes = this.filtro.promocoes && this.filtro.promocoes.length > 0 ?
      this.filtro.promocoes.map( promocao => promocao.id).join(',') : null;


    if(dtInicio) dados.di = dtInicio;
    if(dtFim) dados.df = dtFim;
    if(this.rede)   dados.r = 1;
    if(this.filtro.rede) dados.rid = this.filtro.rede.id;

    if(formaDePagamento) dados.fp = formaDePagamento;
    if(cupons){
      if(this.rede)
        dados.ccps = cupons;
      else
        dados.cps = cupons;
    }

    if(promocoes)
      dados.prs = promocoes;

    if(this.filtro.formaDeEntregaEmpresa) dados.fe = this.filtro.formaDeEntregaEmpresa.formaDeEntrega.id;

    if(this.filtro.pagos) dados.pg = 1;
    if(this.filtro.naoPagos) dados.np = 1;
    if(this.filtro.mesa) dados.ms = 1;
    if(this.filtro.balcao) dados.bl = 1;
    if(this.filtro.delivery) dados.dl = 1;

    console.log("filtro calculado", dados)

    return dados;
  }

  verPedidos(resumoDia: any) {
    this.vendasDoDia = resumoDia;
    if(this.vendasDoDia.pedidos && this.vendasDoDia.pedidos.length) return;

    this.vendasDoDia.carregando = true;

    let dia = moment(this.vendasDoDia.dia, 'DD/MM/YYYY').format('YYYYMMDD')
    let formaDeEntrega = this.filtro.formaDeEntregaEmpresa ? this.filtro.formaDeEntregaEmpresa.formaDeEntrega : null;
    let formasDePagamento = this.filtro.formasDePagamento && this.filtro.formasDePagamento.length > 0 ?
      this.filtro.formasDePagamento.map( forma => forma.id)  : null;

    let cupons = this.filtro.cupons && this.filtro.cupons.length > 0 ?
      this.filtro.cupons.map( cupon => cupon.id) : null;

    let promocoes = this.filtro.promocoes && this.filtro.promocoes.length > 0 ?
      this.filtro.promocoes.map( promocao => promocao.id) : null;

    let params: any = {
      dtInicio: dia, dtFim: dia,
      destino: 'delivery',
      formaDeEntrega: formaDeEntrega,
      formasDePagamento: formasDePagamento,
      cupons: cupons,
      promocoes: promocoes
    };

    if( this.rede ) {
      params.r = 1;
    }

    if(this.filtro.pagos && this.filtro.naoPagos ) {
      params.pagosENaoPagos = true
    } else if(this.filtro.pagos){
      params.apenasPagos  = true
    } else if(this.filtro.naoPagos){
      params.naoPagos  = true
    }

    this.pedidosService.listePedidos(0, 10000, params).then( (resposta) => {
      this.vendasDoDia.pedidos = resposta ? (resposta.pedidos || []) : [];
      this.vendasDoDia.carregando = false;

      this.gridPedidos.atualizeGridPedidos(this.vendasDoDia.pedidos, String(`dia ${dia}`));
    }).catch( () => {
      this.vendasDoDia.carregando = false;
    });

  }

  verDetalhesVendasProduto(resumoProduto: any) {
    this.vendasProduto = resumoProduto;

    if(!this.vendasProduto.adicionais){
      this.vendasService.obtenhaResumoAdicionaisPruduto(this.vendasProduto.id, this.obtenhaFiltro()).then( (resposta) => {
         this.vendasProduto.adicionais = resposta.adicionais;
      })
    }
  }


  async selecionouTab($event: SelectEvent) {
    if($event.title.indexOf('Produtos') >= 0  && ( !this.resumoProdutos.produtos || !this.resumoProdutos.produtos.length))
      this.carregueResumoProdutos();

    if($event.title.indexOf('Formas') >= 0)
     await this.carregueResumoFormas();

  }

  voltarVendasDia() {
    delete this.vendasDoDia;
  }

  voltarVendasProdutos(){
    delete this.vendasProduto;
  }

  obtenhaDescricaoFiltro() {
    if(moment(this.filtro.inicio).isSame(moment(this.filtro.fim), 'day'))
      return String(` do dia ${moment(this.filtro.inicio).format('DD/MM/YYYY')}`)

    return  String(`entre ${moment(this.filtro.inicio).format('DD/MM/YYYY')} e  ${moment(this.filtro.fim).format('DD/MM/YYYY')}`);
  }

  obtenhaNomeArquivo() {
    let sufixo = this.vendasProduto ? this.vendasProduto.nome.replace(/\s/g, '-') : '';
    return String(`produtos-adicionais-${sufixo}.xls`)
  }

  obtenhaNomeArquivoDownload(nome: string) {
    let sufixo = '', horario = new Date().getTime();

    let inicio =  moment(this.filtro.inicio).format('DD-MM-YYYY');
    let fim =  moment(this.filtro.fim).format('DD-MM-YYYY');

    if(this.filtro.rede)
      nome = String(`${nome}-${this.filtro.rede.nome.toLowerCase()}`)

    sufixo = String(`${inicio}_e_${fim}`)

    return String(`${nome}(${sufixo}).xls`)
  }

  imprimaRelatorio(impressora: any = null) {
    if(this.configImpressao && this.configImpressao.imprimirTXT)
      return this.imprimaNativamente(this.configImpressao.impressoras[0]);

    let link = '/imprimir/relatorio/delivery'

    let query = []

    if(this.filtro.inicio) query.push('inicio=' + moment(this.filtro.inicio).format('YYYYMMDD HH:mm:ss'))
    if(this.filtro.fim) query .push('fim=' + moment(this.filtro.fim).format('YYYYMMDD HH:mm:ss'))

    let formaDePagamento = this.filtro.formasDePagamento && this.filtro.formasDePagamento.length > 0 ?
      this.filtro.formasDePagamento.map( forma => forma.id).join(',') : null;

    let cupons = this.filtro.cupons && this.filtro.cupons.length > 0 ?
      this.filtro.cupons.map( cupon => cupon.id).join(',') : null;

    let promocoes = this.filtro.promocoes && this.filtro.promocoes.length > 0 ?
      this.filtro.promocoes.map( promocao => promocao.id).join(',') : null;


    if(formaDePagamento) query.push('fp=' + formaDePagamento);
    if(cupons) query.push('cps=' + cupons);
    if(promocoes) query.push('prs=' + promocoes);
    if(this.filtro.formaDeEntregaEmpresa) query.push('fe=' + this.filtro.formaDeEntregaEmpresa.formaDeEntrega.id);
    if(this.filtro.naoPagos) query.push('np=1');

    if(this.filtro.delivery) query.push('dl=1');
    if(this.filtro.mesa) query.push('ms=1');


    if(query.length > 0)
      link += '?' + query.join('&')

    window.open(link);
  }

  private async imprimaNativamente(impressora: any) {
    this.resumoVendas.dataInicial = this.filtro.inicio
    this.resumoVendas.dataFinal = this.filtro.fim
    this.resumoVendas.empresa = this.empresa

    await this.carregueResumoFormas();

    if(!impressora) impressora = this.configImpressao.impressoras[0]

    console.log(this.resumoVendas)
    let relatorio: any = new ProcessadorReciboTexto(impressora).obtenhaRelatorio(this.resumoVendas)
    console.log(relatorio)
    this.impressaoService.imprimaTexto(relatorio, impressora)
  }

  downloadCsvProdutos() {
    let link = '/exportar/vendas/produtos/csv?';
    let filtro = this.obtenhaFiltro();

    Object.keys(filtro).forEach( key => {
      link  = String(`${link}${key}=${filtro[key]}&`) ;
    })

    window.open(link  );
  }

  downloadCsvCupons() {
    let link = '/exportar/vendas/cupons/csv?';
    let filtro = this.obtenhaFiltro();

    Object.keys(filtro).forEach( key => {
      link  = String(`${link}${key}=${filtro[key]}&`) ;
    })

    window.open(link  );
  }

  downloadCsvPromocoes() {
    let link = '/exportar/vendas/promocoes/csv?';
    let filtro = this.obtenhaFiltro();

    Object.keys(filtro).forEach( key => {
      link  = String(`${link}${key}=${filtro[key]}&`) ;
    })

    window.open(link  );
  }

  downloadCsvPromocoesContatos() {
    let link = '/exportar/vendas/promocoes/contatos/csv?';
    let filtro = this.obtenhaFiltro();

    Object.keys(filtro).forEach( key => {
      link  = String(`${link}${key}=${filtro[key]}&`) ;
    })

    window.open(link  );
  }

  downloadCsvCuponsContatos(){
    let link = '/exportar/vendas/cupons/contatos/csv?';
    let filtro = this.obtenhaFiltro();

    Object.keys(filtro).forEach( key => {
      link  = String(`${link}${key}=${filtro[key]}&`) ;
    })

    window.open(link  );
  }


  sortChange($event: any) {
    this.sortCupom = $event;
    this.vendasCuponsResultSet = orderBy(process(this.resumoVendas.cupons, {}).data, this.sortCupom);
  }

  sortChangeLojas(sort: SortDescriptor[]){
    this.sortLoja = sort;
    this.vendasLojasResultSet = orderBy(process(this.resumoVendas.lojas, {}).data, this.sortLoja);
  }

  sortChangePromocoes(sort: SortDescriptor[]){
    this.sortPromocoes = sort;
    this.vendasPromocoesResultSet = orderBy(process(this.resumoVendas.promocoes, {}).data, this.sortPromocoes);
  }

  exibirPeriodoEspecifico() {
    this.periodoEspecifico = true;
  }

  exibirPeriodoMensal() {
    this.periodoEspecifico = false;
  }

  public produtosMaisTaxas = (): any  => {
    let myState: any = {};

    let produtos = this.resumoProdutos.produtos;

    produtos.push({})

    myState.skip = 0;
    myState.take = produtos.length;

    const result: ExcelExportData =  {
      data: process(produtos, myState).data
    };
    return result;
  };
}
