import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NotificacaoAppService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  salveNotificacaoApp(notificacaoApp: any): Promise<any> {
    return this.salve('/notificacaoapp', notificacaoApp );
  }

  obtenhaNotificacoes() {
    return this.obtenha('/notificacaoapp/liste' , {});
  }

  aprove(notificacaoApp: any): Promise<any> {
    return this.facaPost('/notificacaoapp/aprove', {id: notificacaoApp.id});
  }

  reprove(notificacaoApp: any): Promise<any> {
    return this.facaPost('/notificacaoapp/reprove', {id: notificacaoApp.id});
  }

  envieNotificacaoApp(notificacaoApp: any): Promise<any> {
    return this.facaPost('/notificacaoapp/envie', {id: notificacaoApp.id});
  }

  obtenhaNotificacoesPendentes(): Promise<any> {
    return this.obtenha('/notificacaoapp/liste' ,  {
      p: 1
    });
  }

  obtenhaNotificacao(id: any) {
    return this.obtenha('/notificacaoapp/' + id, {});
  }
}

