<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point"></i>
  </button>
  &nbsp; <i class="fa fa-qrcode"></i>&nbsp;
  <span *ngIf="!qrCode.id">Novo QrCode</span>
  <span *ngIf="qrCode.id">Editar QrCode</span>
</h4>

<div *ngIf="carregando" class="loading-container">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>

<div *ngIf="!carregando">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}" #frm="ngForm" novalidate (ngSubmit)="adicionarQrCode()">

  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label for="nome">Nome</label>
        <input type="text" class="form-control" autocomplete="off" required
               id="nome" name="nome" [(ngModel)]="qrCode.nome" #nome="ngModel"
               placeholder="Nome" value="" >
        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome do QR code Obrigatório</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label for="url">URL</label>
        <input type="text" class="form-control" autocomplete="off" required
               id="url" name="url" [(ngModel)]="qrCode.url" #url="ngModel"
               placeholder="URL" value="" (ngModelChange)="verificarUrl()">
        <div class="invalid-feedback">
          <p *ngIf="url.errors?.required">URL é Obrigatória</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row" [class.shake]="shake">
    <div class="col-md-12">
      <p *ngIf="qrCode.url" class="mt-2">
        Testar Link:
        <a [href]="qrCode.url" target="_blank" (click)="testeLink()">
          <i class="fas fa-external-link-alt"></i> {{ qrCode.url }}
        </a>
        <a [href]="qrCode.url" target="_blank" (click)="testeLink()" class="btn btn-sm btn-primary">
          Testar Link
        </a>

        <small *ngIf="!linkTestado && url.dirty" class="form-text text-danger">
          Por favor, teste o link antes de adicionar o QrCode. Para testar basta clicar no link acima.
        </small>

        <small *ngIf="linkTestado" class="form-text text-success">
          O link foi testado com sucesso.
        </small>
      </p>
    </div>
  </div>


    <div class="alert alert-success mt-2" role="alert" *ngIf="mensagemSucesso" (click)="fecheMensagemSucesso()">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
    </div>

    <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro" (click)="fecheMensagemErro()">
      <i class="mdi mdi-check-all mr-2"></i> <span [innerHTML]="mensagemErro"></span>
    </div>

  <button type="submit" class="btn btn-info waves-effect waves-light mt-2" [disabled]="processando">
    <i class="fa fa-plus fa-lg mr-2"></i>
    Adicionar QrCode

    <i class="k-icon k-i-loading" *ngIf="processando"></i>
  </button>
</form>
</div>
