<h4 class="page-title"> <i class="fe-home"></i>
  Bem vindo, primeiro vamos configurar sua loja e criar um produto</h4>

<div class="card">
  <div class="card-body">

    <form>
      <div id="progressbarwizard">

        <ul class="nav nav-pills bg-light nav-justified form-wizard-header mb-3">
          <li class="nav-item " [hidden]="passo !== 'logo'">
            <a #tabLogo href="#logo" (click)="passo='logo'" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2 active"  >
              <i class="mdi mdi-image fa-lg mr-1"></i>
              <span class="d-inline d-sm-inline">Logo </span>
            </a>
          </li>
          <li class="nav-item " [hidden]="passo !== 'capa'">
            <a #tabCapa href="#capa" (click)="passo='capa'" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2  "  >
              <i class="mdi mdi-image fa-lg mr-1"></i>
              <span class="d-inline d-sm-inline">Capa</span>
            </a>
          </li>
          <li class="nav-item" [hidden]="passo !== 'formas'">
            <a #tabFormasEtrega href="#formas"  (click)="passo='formas'"  data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2 "  >
              <i class="fa  fa-home fa-lg mr-1"></i>
              <span class="d-inline d-sm-inline">Formas de Entrega</span>
            </a>
          </li>
          <li class="nav-item" [hidden]="passo !== 'produtos'">
            <a #tabProdutos href="#produtos" (click)="passo='produtos'"  data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2"  >

              <i class="fas fa-box-open fa-lg mr-1"></i>
              <span class="d-inline d-sm-inline">Produtos</span>
            </a>
          </li>
        </ul>

        <div class="tab-content b-0 mb-0 pt-0">

          <div id="bar" class="progress mb-3" style="height: 10px;">
            <div class="bar progress-bar progress-bar-striped progress-bar-animated bg-success" [style.width]="progressoWizard"></div>
          </div>

          <div class="tab-pane active" id="logo">
            <div class="row">
              <div class="col-12 centralizado" style="max-width: 200px;">
                <br>
                <app-upload-crop-imagem   [objeto]="empresa" ratio="1" resizeWidth="200" minWidth="200" minHeight="200"
                                        [prop]="'logo'" [label]="'Logo da empresa'" (onEnviou)="salveLogoEmpresa($event)"> </app-upload-crop-imagem>

              </div> <!-- end col -->
            </div> <!-- end row -->
          </div>

          <div class="tab-pane  " id="capa">
            <div class="row">
              <div class="col-12 centralizado" style="max-width: 992px;">
                <br>
                <app-upload-crop-imagem   ratio="4" resizeWidth="1240" minWidth="992" minHeight="240"
                                        [objeto]="empresa"  [prop]="'capa'" [label]="'Capa da loja'" (onEnviou)="salveCapaEmpresa($event)"> </app-upload-crop-imagem>

              </div> <!-- end col -->
            </div> <!-- end row -->
          </div>


          <div class="tab-pane " id="formas">
            <div class="row">
              <div class="col-12    centralizado mb-4" style="max-width: 750px;">
                <label class="font-16 mt-2 mb-2">Formas que os pedidos serão entregue</label>
                <app-form-formasdeentrega #frmFormaEntrega  (onSalvou)="proximoPasso()" [empresa]="empresa" ></app-form-formasdeentrega>
              </div> <!-- end col -->
            </div> <!-- end row -->
          </div>

          <div class="tab-pane" id="produtos">
            <div class="row">
              <div class="col-12">
                <label class="font-16 mt-2 mb-2 text-center d-block">Cadastre pelo menos 1 produto</label>


                <app-cad-produto-simples (onSalvou)="salvouProduto($event)" [empresa]="empresa"></app-cad-produto-simples>

              </div> <!-- end col -->
            </div> <!-- end row -->
          </div>


          <ul class="list-inline mb-0 wizard mt-2">
            <li class="next list-inline-item float-right">
              <button  class="btn btn-blue width-lg" (click)="proximoPasso()" [disabled]="!completouPasso()">Próximo </button>
            </li>
            <li class="previous list-inline-item">
              <button   class="btn  btn-blue width-lg" (click)="passoAnterior()">Anterior</button>
            </li>

          </ul>

        </div> <!-- tab-content -->
      </div> <!-- end #progressbarwizard-->
    </form>

  </div> <!-- end card-body -->
</div>
