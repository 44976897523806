<div *ngIf="caixa.status === 'ABERTO'" class="caixa-aberto">
  CAIXA ABERTO
</div>
<div *ngIf="caixa.status === 'FECHADO'" class="caixa-fechado">
  CAIXA FECHADO
</div>
<div class="info-line">
  <div *ngIf="caixa.operador">
    <span><strong>Usuário: </strong>{{ caixa.operador.nome }}</span>
  </div>
  <div>
    <span><strong>Aberto às: </strong>{{caixa.horarioAbertura | date:'dd/MM/yyyy HH:mm'}}</span>
  </div>
  <div>
    <span><strong>Saldo inicial: </strong>{{caixa.saldoInicialDinheiro | currency:'BRL'}}</span>
  </div>
  <div *ngIf="caixa.status === 'ABERTO'">
    <span><strong>Saldo atual(Din/Tot): </strong>{{resumo.saldoDinheiro | currency:'BRL'}}/{{resumo.saldo | currency:'BRL'}}</span>
  </div>
  <div *ngIf="caixa.status === 'FECHADO'">
    <span><strong>Saldo final(Din/Tot): </strong>{{resumo.saldoDinheiro | currency:'BRL'}}/{{resumo.saldo | currency:'BRL'}}</span>
  </div>
</div>
<kendo-grid [data]="caixa.transacoes"  [height]="400" >
  <kendo-grid-column field="data" title="Data/Hora" [width]="150" >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.data | date:'short':'':'pt-BR' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="descricao" title="Descrição" [width]="200"></kendo-grid-column>

  <kendo-grid-column field="valor" title="Entrada" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{ dataItem.tipo === 'ENTRADA' ? (dataItem.valor | currency:'BRL' ) : "" }}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
      {{getTotal('ENTRADA') | currency: 'BRL'}}
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column field="valor" title="Saída" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.tipo === 'SAIDA' ? (dataItem.valor | currency:'BRL' ) : "" }}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
      {{getTotal( 'SAIDA') | currency: 'BRL'}}
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column field="formaDePagamento.descricao" title="Forma de Pagamento" [width]="150"></kendo-grid-column>
  <kendo-grid-column field="pedido.codigo" title="Pedido/Comanda" width="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a *ngIf="dataItem.pedido && dataItem.pedido.id" [routerLink]="['/admin/pedidos/detalhes/', dataItem.pedido.guid]">
        #{{dataItem.pedido.codigo}}
      </a>
      <span *ngIf="dataItem.comanda && dataItem.comanda.id">
        {{dataItem.comanda.id}} - {{dataItem.comanda.mesa.nome}}
      </span>
      <span *ngIf="(!dataItem.pedido || !dataItem.pedido.id) && (!dataItem.comanda || !dataItem.comanda.id)">-</span>
    </ng-template>
  </kendo-grid-column>
  <!--
  <kendo-grid-command-column *ngIf="editavel"  width="80">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <button kendoButton look="bare" [disabled]="!dataItem.estornavel" icon="delete" (click)="excluirTransacao(rowIndex)"></button>
    </ng-template>
  </kendo-grid-command-column>
  -->
  <ng-template kendoGridToolbarTemplate>
    <button type="button" kendoGridExcelCommand icon="file-excel">
      Exportar para Excel
    </button>
  </ng-template>

  <kendo-grid-excel [fileName]="'TRANSACOES_CAIXA_' + caixa.id + '_EMISSAO_' + (agora | date:'ddMMyyyy_HHmm')"></kendo-grid-excel>
</kendo-grid>
