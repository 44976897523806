import {enableProdMode, ɵresetCompiledComponents} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://ebe74d57fad0f3854958b8e9afc2fb01@meucardapio.app.br:8443/2',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
      namePlaceholder: 'Seu Nome',
      showBranding: false,
      formTitle: 'Nos ajude a melhorar',
      nameLabel: "Nome",
      emailLabel: "E-mail",
      messagePlaceholder: 'Digite aqui sua mensagem ou erro.',
      messageLabel: "Mensagem",
      buttonLabel: 'Enviar Sugestão ou Erro.',
      cancelButtonLabel: 'Cancelar',
      submitButtonLabel: 'Enviar'
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false})
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/meucardapio.app.br:8443\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

//if (environment.production) {
  enableProdMode();
//}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
