import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {EmpresasService} from "../../services/empresas.service";

@Component({
  selector: 'app-form-zona-de-entrega',
  templateUrl: './form-zona-de-entrega.component.html',
  styleUrls: ['./form-zona-de-entrega.component.scss']
})
export class FormZonaDeEntregaComponent implements OnInit {
  @ViewChild('frm',  {static: false} ) frm: NgForm;
  zonaDeEntrega: any = {}
  empresa: any = {}
  windowRef: any;
  mensagemErro: string;
  salvando: boolean;

  constructor(private empresasService: EmpresasService) { }

  ngOnInit(): void {
  }


  fechar() {
    this.windowRef.close();
  }


  onSalvar() {
    delete this.mensagemErro;
    if (!this.frm.valid) return;

    this.salvando = true;

    this.empresasService.salveZonaEntrega(this.empresa, this.zonaDeEntrega).then( (zona) => {
      this.salvando = false;
      if(!this.zonaDeEntrega.id) zona.nova = true;
      this.windowRef.close(zona);
    }).catch( (erro) => {
      this.mensagemErro = erro;
      this.salvando = false;
    });

  }
}
