import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

@Component({
  selector: 'app-tela-escolher-cupom-campanha',
  templateUrl: './tela-escolher-cupom-campanha.component.html',
  styleUrls: ['./tela-escolher-cupom-campanha.component.scss']
})
export class TelaEscolherCupomCampanhaComponent implements OnInit {
  mensagemErro = '';
  windowRef: DialogRef;
  enviando = false;

  codigo = '';

  onEscolheuCupom = new EventEmitter();

  constructor() { }

  static abraComoDialog(dialogService: DialogService, empresa: any, fnEscolheuCupom) {
    let windowRef: any = dialogService.open({
      title: 'Informar o cupom',
      content: TelaEscolherCupomCampanhaComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: 500
    });

    windowRef.content.instance.empresa = empresa;
    windowRef.content.instance.windowRef = windowRef

    const tela: TelaEscolherCupomCampanhaComponent = windowRef.content.instance;

    tela.onEscolheuCupom.subscribe((cupom) => {
      if( fnEscolheuCupom ) fnEscolheuCupom(cupom);
    });

    windowRef.result.subscribe((result: any) => {
      if(result){

      }
    });
  }

  ngOnInit(): void {
  }

  onSalvar() {
    if( this.codigo.indexOf(' ' ) !== -1 ) {
      alert('O campo código não pode conter espaços');
      return;
    }
    this.onEscolheuCupom.emit(this.codigo);
    this.windowRef.close();
  }

  fechar() {
    this.windowRef.close();
  }
}
