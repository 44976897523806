import { Component, OnInit } from '@angular/core';
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {PedidosService} from "../../services/pedidos.service";
import {LabelFn, LabelSettings} from "@progress/kendo-angular-progressbar";
import {ModalKendo} from "../../lib/ModalKendo";

declare var moment;

@Component({
  selector: 'app-modal-finalizar-pedidos',
  templateUrl: './modal-finalizar-pedidos.component.html',
  styleUrls: ['./modal-finalizar-pedidos.component.scss']
})
export class ModalFinalizarPedidosComponent extends ModalKendo implements OnInit {
  StatusPedido: any = { Entregue: 4 };
  pedidos: any = [];
  pedidosFinalizar: any = [];
  pedidosFinalidados: any = [];
  pedidosErros: any = [];
  pedidosIgnorados: any = []
  confirmou: boolean;
  finalizou: boolean;
  labelFinalizados: LabelSettings;
  tarefa: any = { total: 0, processados: 0, erros: 0}
  emptyStyles: {[key: string]: any} = {  background: '' };
  progressStyles: {[key: string]: any} = {};
  dataLimite: Date;
  constructor( private  grupolojasService: GrupolojasService,
              private  pedidosService: PedidosService) {
    super()
  }

  ngOnInit(): void {
    this.labelFinalizados  = {
      format: this.formatterGlobal,
      position: 'center',
    };

    this.tarefa.total = this.pedidos.length;
    this.dataLimite = moment().add(-1, 'd').toDate();
    this.filterPedidos();
  }

  filterPedidos(){
   this.pedidosFinalizar =
     this.pedidos.filter((pedido: any) => {
          if(pedido.aguardandoPagamentoOnline) return false;

          let horario = (pedido.horarioEntregaAgendada || pedido.horario);

          return  moment(horario).isSameOrBefore(this.dataLimite, 'd')
     } );
  }

  public formatterGlobal: LabelFn = (value: number): string => {
    return `${value}/${this.tarefa.total}`;
  }


  fecheModal(recarregar = false) {
     super.fecheModal(recarregar)
  }

  async confirmouFinalizar() {
    this.confirmou = true;

    this.progressStyles = {   color: '#fff',  background: '#1050bb' };

    this.pedidosErros = [];

    for(let i = 0; i < this.pedidosFinalizar.length; i++){
      await this.finalizePedido(this.pedidosFinalizar[i])
      this.tarefa.processados++;
    }

    if(!this.tarefa.erros){
      this.progressStyles.background = '#8EBC00';
    }  else {
      this.progressStyles.background = '#f1556c';
    }

    this.finalizou = true;
  }

  async finalizePedido(pedido: any){
    if(pedido.erroExterno && !pedido.referenciaExterna){
      this.pedidosIgnorados.push(pedido)
      return;
    }


    if(pedido.statusOrdem !== this.StatusPedido.Entregue ){
      let resposta = await this.pedidosService.altereStatus(pedido, this.StatusPedido.Entregue, false).catch((erro) => {
        pedido.erro = erro;
        this.pedidosErros.push(pedido)
        this.tarefa.erros = true;
      });

      if(!resposta) return;

      pedido.status = resposta.status;
    }

    if(!pedido.pago){
      let resposta =  await this.pedidosService.altereFoiPago(pedido, true, null).catch((erro) => {
        pedido.erro = erro;
        this.pedidosErros.push(pedido)
        this.tarefa.erros = true;
      });
      if(!resposta) return;

      pedido.pago = true;
    }

    this.pedidosFinalidados.push(pedido)
  }
}
