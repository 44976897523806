import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EnumFiltroDePedidos, PedidosService} from "../../services/pedidos.service";
import {ActivatedRoute} from "@angular/router";
import {GridPedidosComponent} from "../grid-pedidos/grid-pedidos.component";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {RotaGuardClient} from "../../guards/auth-loja.guard";

declare var moment;
declare var _;
@Component({
  selector: 'app-tela-pedidos-pagos',
  templateUrl: './tela-pedidos-pagos.component.html',
  styleUrls: ['./tela-pedidos-pagos.component.scss']
})
export class TelaPedidosPagosComponent implements OnInit {
  @ViewChild('gridPedidos', { static: false}) gridPedidos: GridPedidosComponent;
  pedidos: [];
  paginacao = { total: 1000};
  carregando: any = false;
  filtro: any = { inicio: null, fim: null};
  resumoPedidos: any = { qtde: 0, total: 0, totalTaxas: 0 };
  @Input() mesa = false;
  objFiltro: any = {
    q: ''
  };
  usuario = {};
  timerBusca: any;
  constructor(private autorizacaoService: AutorizacaoService,
              private pedidosService: PedidosService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();
    this.filtro.inicio = moment().add(-7, 'd').toDate();
    this.filtro.fim = new Date();

    this.carreguePedidos();
  }

  visualizaRelatorios(){
    return RotaGuardClient.visualizaRelatorios(this.usuario)
  }

  onFilter($event: Event) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout(() =>  {
      this. carreguePedidos();
    }, 1000)
  }


  carreguePedidos(){
    if(this.carregando) return;

    let dtInicio = moment(this.filtro.inicio).format('YYYYMMDD');
    let dtFim = moment(this.filtro.fim).format('YYYYMMDD');

    this.carregando = true;

    let filtro = EnumFiltroDePedidos.pedidos;
    if( this.mesa )
      filtro = EnumFiltroDePedidos.comanda;

    this.pedidosService.listePedidos(0, this.paginacao.total,
      { apenasEncerrados: true, dtInicio: dtInicio, dtFim: dtFim, q: this.objFiltro.q }, filtro).then( (resposta) => {
      this.setPedidos(resposta.pedidos || []);
      this.gridPedidos.atualizeGridPedidos(this.pedidos, 'finalizados');
      this.carregando = false;
    }).catch( () => {
      this.carregando = false;
    });
  }


  private setPedidos(resposta: any) {
    this.pedidos = resposta || [];
    this.resumoPedidos.qtde = this.pedidos.length;
    this.resumoPedidos.totalTaxas = _.reduce( this.pedidos, (valor, pedido) => valor + pedido.taxaEntrega, 0);
    this.resumoPedidos.total = _.reduce( this.pedidos, (valor, pedido) => valor + pedido.total, 0);

    this.resumoPedidos.total -=    this.resumoPedidos.totalTaxas;

  }
}
