import { Component, OnInit } from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ActivatedRoute} from "@angular/router";
import {ModalTrocarcartaoComponent} from "./modal-trocarcartao/modal-trocarcartao.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-assinatura-trocarcartao',
  templateUrl: './assinatura-trocarcartao.component.html',
  styleUrls: ['./assinatura-trocarcartao.component.scss']
})
export class AssinaturaTrocarcartaoComponent implements OnInit {

  usuario: any = {};

  constructor( private empresasService: EmpresasService,
               private dialogService: DialogService,
               private activatedRoute: ActivatedRoute) {

    let token =  this.activatedRoute.snapshot.params.token;

    this.empresasService.obtenhaContratoAssinatura(token).then(  (contrato: any) => {
      this.abraModalTrocarCartao(contrato);
    });

  }

  abraModalTrocarCartao(contrato: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalTrocarcartaoComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.contrato = contrato;
    windowRef.content.instance.reload =  false;

  }

  ngOnInit(): void {
  }
}
