import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PedidosService} from "../../services/pedidos.service";

@Component({
  selector: 'app-comprovante-comanda',
  templateUrl: './comprovante-comanda.component.html',
  styleUrls: ['./comprovante-comanda.component.scss', '../../componentes/imprimir.component.scss']
})
export class ComprovanteComandaComponent implements OnInit {
  comanda: any = { cliente: {}, empresa: {}};
  carregando: boolean;
  texto: string
  layoutPedido = 'PedidoCompleto';
  resumido: boolean;
  pedido: any;

  constructor(private activatedRoute: ActivatedRoute, private pedidosService: PedidosService) {
    let id =  this.activatedRoute.snapshot.params.id;
    let modoTexto = this.activatedRoute.snapshot.queryParams.t === '1';
    let layoutPedido = this.activatedRoute.snapshot.queryParams.l;
    this.carregando = true;

    this.pedidosService.obtenhaComandaComoPedido(id).then( (comanda: any) => {
      this.comanda = comanda;

      this.pedido = comanda;

      this.resumido = layoutPedido === 'PedidoResumido';
      this.carregando = false;

      setTimeout( () => {
        //impressao.imprima(this.texto)
        window.print()
      }, 500)
    });

  }



  ngOnInit() {
  }
}
