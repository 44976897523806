import {Component, Input, OnInit} from '@angular/core';
import {PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {BrindeService} from "../../../services/brinde.service";
import {CadBrindeComponent} from "../cad-brinde/cad-brinde.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../../lib/KendoPopupUtils";

@Component({
  selector: 'app-brindes',
  templateUrl: './brindes.component.html',
  styleUrls: ['./brindes.component.scss']
})
export class BrindesComponent  implements OnInit {
  @Input() private empresa: any;
  paginacao = {
     size: 10,
     skip: 0,
     total: 0,
     buttonCount: 5,
     info: true,
     type: "numeric"  ,
     pageSizes: false,
     previousNext: true
  }

  loading = false;
  brindes: any = []
  abraDialogo: boolean;
  brindeSelecionado: any;
  excluindoBrinde: boolean;
  mensagemRemocao: string;
  private removeu: boolean;
  constructor(private brindeService: BrindeService, private dialogService: DialogService) {

  }

  ngOnInit() {
     this.brindeService.listeDaEmpresa(this.empresa).then( (brindes => {
          this.brindes = brindes;
     }))
  }

  editarBrinde({ sender, rowIndex, dataItem }) {

    this.exibaModalBrinde(dataItem)


  }

  exibaModalBrinde(brindeEditar: any = null) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadBrindeComponent,
      minWidth: 250,
      width: window.innerWidth > 500 ? 500 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100
    });


    windowRef.content.instance.empresa = this.empresa;

    if(brindeEditar)
      windowRef.content.instance.setBrinde(brindeEditar);

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe(
      (result: any) => {
        if(brindeEditar) return;

        if(result && result.id){
          this.brindes.push(result)
          this.paginacao.size = this.brindes.length;
        }
      });



  }

  mudouPaginacao($event: PageChangeEvent) {
    console.log($event)
  }

  abraDialogRemoverBrinde($event: RemoveEvent) {
    this.brindeSelecionado = $event.dataItem;
    this.abraDialogo = true;

  }

  fechar() {
    this.abraDialogo = false;
    this.mensagemRemocao = null;
    this.brindeSelecionado = null;
    this.excluindoBrinde = false;

  }

  removaBrinde() {
    this.excluindoBrinde = true;

    this.brindeService.removaBrinde(this.brindeSelecionado).then((resposta) => {

      this.brindeService.listeDaEmpresa(this.empresa).then( brindes => {
        this.removeu = true;
        this.excluindoBrinde = false;
        this.mensagemRemocao = "O brinde '" + this.brindeSelecionado.nome + "' foi removido ";
        this.brindeSelecionado = null;

        this.brindes =  brindes;
        this.paginacao.size = this.brindes.length;
      })

    }).catch((reason) => {
      this.excluindoBrinde = false;
      this.brindeSelecionado = null;
      this.mensagemRemocao = reason;
    })

  }
}
