import {Component, OnInit, ViewChild} from '@angular/core';
import {PedidoDetalhesComponent} from "../pedido-detalhes/pedido-detalhes.component";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-pedido-aceitar',
  templateUrl: './modal-pedido-aceitar.component.html',
  styleUrls: ['./modal-pedido-aceitar.component.scss']
})
export class ModalPedidoAceitarComponent extends ModalKendo implements OnInit {
  pedido: any = {}
  @ViewChild('detalhesPedido', { static: true} ) detalhesPedido: PedidoDetalhesComponent;
  constructor(  ) {
    super()
  }

  ngOnInit(): void {

    this.detalhesPedido._pedido = this.pedido;
  }



}
