export class DataHoraUtils{
  static obtenhaDiaMesAno(data: Date){
    let dataFormtada: string;

    if(data &&  data   instanceof Date){
      let ano = data.getFullYear();
      let mes  = ( data.getMonth() + 1).toString().padStart(2, '0');
      let dia  = data.getDate().toString().padStart(2, '0');

      dataFormtada = String(`${ano}-${mes}-${dia}`)
    }

    return dataFormtada;


  }

  static obtenhaDataDaString(data: string){
    if(!data || typeof data !== 'string') return data;

    let datasDia: any = data.substring(0, 10).split('-')
    let date = new Date();

    let ano = datasDia[0];
    let mes = Number(datasDia[1])
    let dia = datasDia[2];

    date.setFullYear(ano)
    date.setMonth(mes - 1)
    date.setDate(dia)

    return date;
  }
}
