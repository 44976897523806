import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {UploadImagemComponent} from "../../superadmin/upload-imagem/upload-imagem.component";
import {CardapioService} from "../../services/cardapio.service";

@Component({
  selector: 'app-upload-cardapio',
  templateUrl: './upload-cardapio.component.html',
  styleUrls: ['./upload-cardapio.component.scss']
})
export class UploadCardapioComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('upload') upload: UploadImagemComponent;
  cardapio: any = {};
  mensagemSucesso: any;
  mensagemSucessoCardapio = '';
  menasgemErro: any;
  salvando: any;
  exibirSalvar: any;

  constructor(private empresaService: EmpresasService, private cardapioService: CardapioService) { }

  ngOnInit(): void {
    this.cardapio = this.empresa.cardapio || {};
  }

  onSubmitCardapio() {
    this.mensagemSucesso = '';
    this.salvando = true;

    this.empresaService.salveCardapio(this.empresa, this.cardapio).then( (resp) => {
      if(!this.cardapio.id)
        this.cardapio.id = resp.id;
      this.salvando = false;
      this.mensagemSucesso = "Cardápio salvo com sucesso!";
      this.exibirSalvar = false;
    }).catch( (erro) => {
      this.salvando = false;
      alert(erro)
    });
  }


  fecheMensagemSucesso() {
    this.mensagemSucesso = '';
  }

  canceleImagem() {
    this.upload.canceleImagem();
    this.exibirSalvar = false;
  }

  onSubmitConfigsCardapio() {
    this.mensagemSucessoCardapio = '';
    this.cardapioService.atualizeModoVendaCardapio(this.cardapio).then( (resp) => {
      this.mensagemSucessoCardapio = 'Cardápio salvo com sucesso!';
    });
  }
}
