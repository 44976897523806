<h4 class="page-title text-left">Configuração Instagram BOT</h4>

<div class="alert alert-primary" *ngIf="!conectado">
  <strong>Você precisa conectar sua conta no Instagram para que nós possamos instalar o Bot.</strong>
</div>

<div *ngIf="conectado && contaSelecionada" class="alert alert-success">
  <strong><i class="mdi mdi-check-all mr-2 font-20"></i> Parabéns, sua conta está conectada ao Instagram!</strong>
</div>

<div *ngIf="!conectado">
  <button class="btn btn-primary mr-2" (click)="atualizarToken()">&nbsp;
    <strong>Atualizar Token</strong>
  </button>

  <button class="btn btn-blue" (click)="conectar()">&nbsp;
    <strong>Continuar Com Facebook</strong>
  </button>
</div>

<div *ngIf="conectado">
  <button class="btn btn-primary mr-2" (click)="atualizarToken()">&nbsp;
    <strong>Atualizar Token</strong>
  </button>

  <button class="btn btn-danger" (click)="desconectar()">
    Desconectar Facebook
  </button>
</div>

<div class="mt-3">

  <ng-container *ngIf="carregandoInstagram">
    <div style="text-align: center;">
      <div>
      <div class="k-i-loading ml-1 mr-1" style="font-size: 60px;"></div>
      </div>
      <div class="p-4">
        <p class="mt-2"><strong>{{msgCarregando}}</strong></p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="contas && !contaSelecionada && !carregandoInstagram">
    <p>Selecione uma página para conectar na sua conta</p>
    <div class="card" *ngFor="let conta of contas">
      <div class="card-body">
        <div *ngIf="conta.msgErro" class="alert alert-danger">
          <strong><i class="mdi mdi-block-helper mr-2 font-20"></i> {{conta.msgErro}}</strong>
        </div>

        <div>
          <img src="{{conta.imageProfileUrl}}" style="border-radius: 50%;width: 68px"/>
          <span class="font-16 ml-1"><strong>@{{conta.nomeInstagram}}</strong></span>
          <button class="btn btn-primary ml-2" (click)="selecionouInstagram(conta)">Selecionar</button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="contaSelecionada">
    <h4>Conta conectada ao Instagram</h4>

    <div class="card">
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col-auto" style="padding-right: 0px;">
              <img src="{{contaSelecionada.imageProfileUrl}}" style="border-radius: 50%;width: 68px" class="pull-left"/>
            </div>
            <div class="col justify-content-center align-content-center mt-1">
              <span class="font-16"><strong>@{{contaSelecionada.nomeInstagram}}</strong></span>
              <p class="text-muted">
                <strong>{{mensagem}}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>

    </p>
  </ng-container>


