<div class="modal-content">

    <div class="modal-body">

     <div class="row">
        <div class="col-lg-4 col-12 border-right assinatura">
          <h3>{{empresa?.nome}}</h3>
          <h4>Ative seu plano pagando a 1° Fatura</h4>

          <div *ngIf="contrato.id">
           <div class="mt-2 nome-plano">
             <label>Plano:</label>  <span class="font-16 ml-2 text-success">
            <b> {{contrato.plano?.nome}}</b></span> | {{contrato.limiteContatos | number}} contatos ativos.

           </div>

         </div>

         <div *ngIf="contrato.proximaFatura">
          <app-fatura-detalhes [fatura]="contrato.proximaFatura"></app-fatura-detalhes>
         </div>

          <div *ngIf="!contrato.id">
            <div class="alert alert-info mt-2" role="alert">
              <i class="mdi mdi-check-all mr-2"></i> Aguardando contrato ser registrado no sistema.
              <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </div>

          <div *ngIf="!contrato.proximaFatura">
            <div class="alert alert-info mt-2" role="alert">
              <i class="mdi mdi-check-all mr-2"></i> Aguardando criação da fatura
              <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </div>


          <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="erro">
            <i class="mdi mdi-check-all mr-2"></i> {{erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div  *ngIf="contrato.proximaFatura?.boleto">


            <img class="boleto" [src]="contrato.proximaFatura.boleto.urlCodigoBarras" >

            <span class="font-12 text-center d-block">
              {{contrato.proximaFatura.boleto.codigoBarras}}
            </span>

            <a class="btn btn-info mt-2" target="_blank" href="{{contrato.proximaFatura.linkPagamento}}"   >
               Imprimir boleto
            </a>

          </div>

          <span [hidden]="pago">
              <cad-cartaocredito #cccompoment (onCriou)="ativeViaCartao($event)"
                                 [parcelamento]="{numeroParcelas: contrato.numeroParcelas, total: contrato.proximaFatura.total}"
                                 *ngIf="contrato.proximaFatura && contrato.pagaNoCartao">

              </cad-cartaocredito>
          </span>

          <div  *ngIf="contrato.proximaFatura?.pix" [hidden]="pago">

             <h5 class="text-center mt-2">Pix</h5>

             <img class="pix" [src]="contrato.proximaFatura.pix.qrcode" >

             <button *ngIf="!mensagemCopiada" class="btn btn-outline-secondary btn-copia" (click)="copyMessage(contrato.proximaFatura.pix.qrcodeText)"
                      >Pix Copia e Cola</button>

            <button *ngIf="mensagemCopiada" class="btn btn-success btn-copia"
                  >Pix Copiado</button>


             <h6> O que é Pix?</h6>
             <p> O Pix é um novo meio de pagamento instantâneo criado pelo Banco Central, que permite realizar pagamentos 24h por dia.
               </p>
             <p>Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneio QR Code acima  ou copie e código
             usando o Pix Copia e Cola para efetuar o um pagamento.</p>

          </div>

          <span [hidden]="!pago">

             <h4 class="text-success text-center">Seu plano foi ativado com sucesso!</h4>

          </span>


        </div>

        <div class="col-lg-8 col-0 quadro">

          <div class="bg-cartao" *ngIf="mensagemAtivacao === 'cartao'">

            <div class="promo-info ">
              <h3>Ative seu plano com seu cartão de crédito e garanta seu desconto.</h3>
              <p>E começe a melhorar o ciclo de retorno diminuindo o tempo de recorrência
              dos seus clientes.</p>
            </div>

          </div>

          <div class="bg-ativacao" *ngIf="mensagemAtivacao === 'boleto' ">
            <div class="promo-info ">
              <h3>Ative seu plano pagando o boleto e começe a faturar mais.</h3>
              <p>E começe a melhorar o ciclo de retorno diminuindo o tempo de recorrência
                dos seus clientes.</p>
            </div>
          </div>

          <div class="bg-ativacao" *ngIf=" mensagemAtivacao === 'pix'">
            <div class="promo-info ">
              <h3>Ative seu plano fazendo transferência via Pix e começe a faturar mais.</h3>
              <p>E começe a melhorar o ciclo de retorno diminuindo o tempo de recorrência
                dos seus clientes.</p>
            </div>
          </div>

        </div>
     </div>


    </div>

</div>
