import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TelaExpandidaComponent} from "../TelaExpandida";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {NotificationService} from "@progress/kendo-angular-notification";
import {EmpresaService} from "../../services/empresa.service";


@Component({
  selector: 'app-tela-pedidos',
  templateUrl: './tela-pedidos.component.html',
  styleUrls: ['./tela-pedidos.component.scss']
})
export class TelaPedidosComponent extends TelaExpandidaComponent implements OnInit {
  empresa: any = {};
  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;
  constructor(private  constantsService: ConstantsService,  private notificationService: NotificationService,
              private empresasService: EmpresaService) {
    super();
  }

  ngOnInit() {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) return;

      this.empresa = empresa;
    });
  }

  alterouAceitarAutomatico(aceitarPedidoAutomatico ) {

   this.empresasService.altereAceitarPedido(aceitarPedidoAutomatico).then( (resp) => {
      this.notificationService.show({
        content: aceitarPedidoAutomatico ? 'Alterado para aceitar pedidos automaticamente!' : "Alterado para não aceitar pedidos automaticamente",
        appendTo: this.appendTo,
        hideAfter: 2000,
        position: { horizontal: "center", vertical: "top" },
        animation: { type: "fade", duration: 800 },
        type: { style: "success", icon: true },
      });
    }).catch((err) => {
     setTimeout(() => {
       this.empresa.aceitarPedidoAutomatico = !aceitarPedidoAutomatico
     }, 500)
     alert(err)
   })
  }
}
