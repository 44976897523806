<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">
  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group mb-2">
        <label for="estado">Estado:</label>
        <kendo-combobox id="estado" name="estado" [(ngModel)]="estadoEscolhido" [data]="estados"  [filterable]="false"
                        placeholder="Selecione um Estado" class="form-control"   [textField]="'nome'" [valueField]="'id'"
                        required   #estado="ngModel" #cboEstado
                        autocomplete="disabled"
                        (valueChange)="mudouEstado($event)">
        </kendo-combobox>
        <div class="invalid-feedback">
          <p *ngIf="estado.errors?.required">Estado é obrigatório</p>
          <p *ngIf="estado.errors?.nomeCompleto">Informe o Estado</p>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group mb-2">
        <label for="estado">Cidade:</label>
        <kendo-combobox id="cidade" name="cidade" [(ngModel)]="cidadeEscolhida" [data]="cidades" [filterable]="false"
                        placeholder="Selecione uma Cidade" class="form-control"  [textField]="'nome'" [valueField]="'id'"
                        #cboCidade
                        required #cidade="ngModel">
        </kendo-combobox>
        <div class="invalid-feedback">
          <p *ngIf="cidade.errors?.required">Cidade é obrigatória</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="enviando" >Salvar</button>
    <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
    <button type="button" (click)="fechar()" primary="true"  class="btn btn-light waves-effect">Fechar</button>
  </div>
</form>
