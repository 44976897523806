import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TemplatePromptsService extends ServerService {

  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  liste() {
    return this.http.get('/prompt-templates/liste')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  carrgueTemplatePrompt(id: number) {
    return this.obtenha('/prompt-templates/' + id, {});
  }

  salveTemplatePrompt(templatePrompt: any) {
    return this.salve('/prompt-templates', templatePrompt);
  }
}
