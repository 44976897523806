import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DominiosService} from "../../services/dominios.service";
import {ClienteService} from "../../services/cliente.service";

@Component({
  selector: 'app-admin-autenticar',
  templateUrl: './admin-autenticar.component.html',
  styleUrls: ['./admin-autenticar.component.scss', '../login.component.css']
})
export class AdminAutenticarComponent implements OnInit , OnDestroy {
  @ViewChild('frm')  frm: NgForm;
  email: string;
  codigo: string;
  erro: string;
  urlDestino: string;
  validando: boolean;
  reenviando: boolean;
  token: string;
  tempoRestante = 0;
  tempoRestanteTexto = '00:00'
  tarefa: any;
  constructor(private autorizacao: AutorizacaoService, private router: Router,
              private route: ActivatedRoute,
              private dominiosService: DominiosService, private clienteService: ClienteService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params.token;

    this.route.queryParams.subscribe(params => {
      this.urlDestino = params['t'] || '/admin/index';
    });

    this.valideTokenNaSessao();
  }

  valideTokenNaSessao(){
    if(  this.tarefa) clearInterval(this.tarefa)

    this.autorizacao.tokenSessaoAutenticar(this.token).then((resposta: any) => {
      this.email  = resposta.email;
      this.tempoRestante = resposta.tempoRestante;
      this.tarefa =   setInterval(() => this.updateCountdown(), 1000);
    }).catch((err) => {
      alert(err)
      this.router.navigateByUrl('/admin/login')
    })
  }

  updateCountdown( ){

    let minutes = Math.floor( this.tempoRestante  / 60000);
    let seconds = Math.floor(( this.tempoRestante  % 60000) / 1000);
    if(   this.tempoRestante < 0){
      minutes = 0;
      seconds = 0;
    }

    this.tempoRestanteTexto = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    this.tempoRestante = this.tempoRestante - 1000 ;
  }

  alterouCodigo(codigo: string) {
    if (this.frm.controls.codigo.valid) {
      this.validando = true;
      this.autorizacao.autentiqueUsuarioPeloCodigo(this.token, codigo).then((resposta) => {
        this.router.navigateByUrl( this.urlDestino)
      }).catch((err) => {
        this.validando = false;
        this.erro =  err || 'Codigo inválido'
      })

    }
  }

  enviarNovoCodigo(){
    this.codigo = null
    this.reenviando = true;
    this.autorizacao.reenvieCodigoAutenticacao(this.token).then((resposta: any) => {
      this.reenviando = false;
      this.token = resposta;
      const novaUrl = `/admin/autenticar/${this.token}`;
      window.history.pushState({path: novaUrl}, '', novaUrl);
      this.valideTokenNaSessao();

    }).catch((err) => {
      this.reenviando = false;
      this.erro =  err || 'Falha ao reenviar codigo'
    })

  }

  ngOnDestroy(): void {
    if(this.tarefa) clearInterval(this.tarefa)
  }

}
