<h4 class="page-title">
  <i class="fas fa-box-open"></i>
  Produtos
  <span *ngIf="empresa?.catalogo?.compartilhado" class="badge badge-danger badge-sm ml-1" >catálogo compartilhado</span>


  <button class="btn btn-danger ml-4" (click)="removerCatalogo()" *ngIf="usuario.admin" [hidden]="confirmeRemover">
    Limpar catalogo</button>

</h4>



<h4 *ngIf="removendo" class="mt-1"><i class="k-icon k-i-loading"></i> removendo aguarde...</h4>


<div class="row" *ngIf="empresa?.catalogo">
 <div class="card-box" style="width: 100%;">
   <app-produtos2 [empresa]="empresa" [catalogo]="empresa.catalogo"></app-produtos2>
 </div>
</div>
