import {Component, Input, OnInit} from '@angular/core';
import {ProdutoService} from "../../../services/produto.service";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import {OpcaoDeAdicionalDeProduto} from "../../../../../server/domain/delivery/OpcaoDeAdicionalDeProduto";

@Component({
  selector: 'app-adicionais-produto',
  templateUrl: './adicionais-produto.component.html',
  styleUrls: ['./adicionais-produto.component.scss']
})
export class AdicionaisProdutoComponent implements OnInit {
  carregando: any;

  private _produto: any;

  opcoes = [];
  empresa: any;
  processando = false;

  constructor(private produtoService: ProdutoService, private constantsService: ConstantsService) {
  }

  @Input() set produto(value: any) {
    this._produto = value;

    this.opcoes = [];

    for( let campo of this._produto.camposAdicionais ) {
      if( campo.opcoesDisponiveis ) {
        for (let opcao of campo.opcoesDisponiveis) {
          this.opcoes.push(opcao);
        }
      }
    }
  }

  get produto(): any {
    return this._produto;
  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) {
        return;
      }

      this.empresa = empresa;
    });
  }

  pausarAdicional(opcao: OpcaoDeAdicionalDeProduto) {
    this.processando = true;

    this.produtoService.pausarAtivarOpcional(this.empresa, opcao, false).then( (resposta: any) => {
      if(this.produto.catalogo.disponibilidadePorEmpresa && opcao.opcaoNaEmpresa && opcao.opcaoNaEmpresa.id)
        opcao.opcaoNaEmpresa.disponivel = false
      else
        opcao.disponivel = false;

      this.processando = false;
    }).catch( (erro: any) => {
      this.processando = false;
    });
  }

  ativarAdicional(opcao: any) {
    this.processando = true;

    this.produtoService.pausarAtivarOpcional(this.empresa, opcao, true).then( (resposta: any) => {
      if(this.produto.catalogo.disponibilidadePorEmpresa && opcao.opcaoNaEmpresa && opcao.opcaoNaEmpresa.id)
        opcao.opcaoNaEmpresa.disponivel = true
      else
        opcao.disponivel = true;

      this.processando = false;
    }).catch( (erro: any) => {
      this.processando = false;
    });
  }
}
