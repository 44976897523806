import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import {NovoCartaoComponent} from "../novo-cartao/novo-cartao.component";

import {ActivatedRoute, Router} from "@angular/router";
import {ContatosService} from "../services/contatos.service";
import {AutorizacaoService} from "../services/autorizacao.service";
import {ChartComponent} from "@progress/kendo-angular-charts";
import {VendasFidelidadeService} from "../services/vendas-fidelidade.service";
import {EnumTipoDeAcao} from "../../../server/lib/emun/EnumTipoDeAcao";
import {Observable, timer} from "rxjs";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {ContratoService} from "../superadmin/services/contrato.service";
import { EmpresasService } from '../superadmin/services/empresas.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { BotoesHomeComponent } from '../componentes/botoes-home/botoes-home.component';
import {CampanhaService} from "../services/campanha.service";
import {RotaGuardClient} from "../guards/auth-loja.guard";
import {DialogCloseResult, DialogService} from "@progress/kendo-angular-dialog";
import {FidelidadeComponent} from "../fidelidade/fidelidade.component";
import {DeviceDetectorService} from "ngx-device-detector";
import {SocketService} from "../services/socket.service";

declare const _;

@Component({
  providers: [ ],
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild(NovoCartaoComponent) dialogNovoCartao;
  @ViewChild(ChartComponent, {static: true})   chart;
  @ViewChild('botoesHome', {static: false}) botoesHome: BotoesHomeComponent;
  resumo = {total: 0, qtdeAtivos: 0 , qtdeInativos: 0 };
  series: Observable<any>;
  seriesLabels: [] = [];
  graficos = ['dia' , 'mes' , 'ano' ];
  tipoGrafico ;
  ultimasAcoes: any = [];
  tipoDeAcaoEnum = EnumTipoDeAcao;
  resumoVenda: any = {
    total: 0, pontos: 0,  mesFormatado: 'Sem Vendas'
  };
  exibirFinanceiro = false;
  usuario: any;
  empresa: any;
  msg: any;
  novoPedido: any = {};
  carregou = false;
  fatura: any;
  fechouAlerta: Boolean;
  exibirModal: Boolean;
  exibirPedidos = false;
  marcando = false;
  msgStatus;
  total = 0;
  indice = 0;
  campanhasPendentes = [];
  timerAberto: any;
  isMobile: boolean;
  iframe: boolean;
  constructor(private router: Router, private  contratoService: ContratoService,
              private autorizacaoService: AutorizacaoService , private vendasService: VendasFidelidadeService,
              private contatoService: ContatosService, private activatedRoute: ActivatedRoute,
              private dialogService: DialogService, private deviceService: DeviceDetectorService,
              private constantsService: ConstantsService, private empresasService: EmpresasService,
              private sanitizer: DomSanitizer, private campanhaService: CampanhaService,
              private socketService: SocketService) {
    this.isMobile = this.deviceService.isMobile()
    this.series = vendasService;
    this.tipoGrafico = this.graficos[0];
    let usuarioLogado = this.autorizacaoService.getUser();

    this.iframe = this.inIframe();

    if (usuarioLogado.garcom) {
      this.router.navigate(['/admin/pedidos-mesas'], {}).then(() => {
      });
      return
    }

    this.exibirFinanceiro = RotaGuardClient.visualizaRelatorios(usuarioLogado);
    this.constantsService.moduloPedido$.subscribe(data => this.exibirPedidos = data);

    if (!this.exibirFinanceiro) return;

    this.contatoService.obtenhaResumo({}).then(resumo => {
      this.resumo = resumo;
    });

    this.exibaGraficoDia();

    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      this.msg = queryParams.msg;
      this.novoPedido = {codigo: queryParams.codigo};
    });
    this.constantsService.moduloFidelidade$.subscribe(data => this.carregou = data != null);
    this.campanhaService.obtenhaCampanhas('', '', true).then( (respCampanhas: any) => {
      this.campanhasPendentes = respCampanhas.campanhas;
    });
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }


  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e.data.tipo === 'MENSAGEM_ADICIONAR_TAG') {
      if( e.data.fim ) {
        this.marcando = false;
      }

      this.total = e.data.total;
      this.indice = e.data.indice;
      this.msgStatus = this.sanitizer.bypassSecurityTrustHtml(e.data.msg);
    }
    else if( e.data.tipo === 'PKT_IMPORTE_CONTATOS' ) {
      this.msgStatus = this.sanitizer.bypassSecurityTrustHtml('Importando contatos...');

      const contatos = e.data.contatos;

      this.empresasService.importeContatos(this.empresa, contatos).then( (resp: any) => {
        this.botoesHome.terminouEnviarContatos();

        this.msgStatus = this.sanitizer.bypassSecurityTrustHtml(`<strong>Contatos Importados</strong>
<p class="mb-0">Novos: <strong>${resp.importados} contatos</strong></p>
<p>Existentes: <strong>${resp.existentes} contatos</strong></p>`);
      this.total = 100;
      this.indice = 100;
      }).catch( erro => {
        this.msgStatus = erro;
        this.botoesHome.terminouEnviarContatos();
      });
    }
  }

  ngOnInit() {
    this.atualizeTela();
    this.usuario = this.autorizacaoService.getUser() || {};
    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if (!empresa) return;

      this.empresa = empresa;
      if(this.isMobile) this.monitoreLojaFechada();
    });
    this.contratoService.obtenhaFaturaAberta().then( fatura => {
      this.fatura  = fatura;
    })
  }

  ngAfterViewInit() {  }

  atualizeTela() {
    this.contatoService.obtenhaUltimasAcoes().then( ultimasAcoes => {
      this.ultimasAcoes = ultimasAcoes;
    });
  }


  exibaGraficoDia() {
    this.tipoGrafico = 'dia';
    this.vendasService.queryDia(this.seriesLabels, this.resumoVenda) ;
  }

  exibaGraficoMes() {
    this.tipoGrafico = 'mes';
    this.vendasService.queryMes(this.seriesLabels, this.resumoVenda) ;
  }

  exibaGraficoAno() {
    this.tipoGrafico = 'ano';
    this.vendasService.queryAno(this.seriesLabels, this.resumoVenda) ;
  }

  imprimaPedido(pedido: any) {
    window.open('/imprimir/pedido/' + pedido.guid);
  }

  fecheAterta() {
    this.fechouAlerta = true;
  }

  fecheModal() {
    this.exibirModal = true;
  }


  recarregue() {
    this.socketService.atualizeAbas();
  }

  sequence_operate(arr: any, index, fn){
    setTimeout(async () => {
      const objeto = arr[index];

      if( !objeto ) {
        return;
      }

      fn(objeto);
      this.sequence_operate(arr, index + 1, fn);
    }, 300);
  }

  adicionarTags() {
    this.marcando = true;
    this.contatoService.obtenhaContatosStatus().then( (contatos) => {
      window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {
          tipo: 'ADICIONE_TAG',
          contatos: contatos
        }}, "*");
    }).catch( (erro) => {});
  }

  fecharAlertaStatus() {
    this.msgStatus = null;
  }

  notifique(msg: string) {
    this.msgStatus = this.sanitizer.bypassSecurityTrustHtml(msg);
  }


  cliqueAcessarCampanha(campanhaPendente: any) {
    this.router.navigate(['/admin/marketing'], {}).then(() => {
    });
  }

  fecharAgora() {
    const windowRef = FidelidadeComponent.abraComoPopup(this.dialogService, false);

    windowRef.result.subscribe((result: any) => {
      if( result instanceof DialogCloseResult) {

      } else if (result) {
        this.empresa.estaRecebendoPedidos = result.fechou;
      }
      this.monitoreLojaFechada();
    });
  }

  private monitoreLojaFechada() {
    if(!this.empresa.estaAberta || !this.empresa.estaRecebendoPedidos){
      if(this.timerAberto) this.timerAberto.unsubscribe();

      const segundosTimer = 60;

      this.timerAberto =  timer(1000 * segundosTimer , 1000 * segundosTimer  ).subscribe( () => {

        this.empresasService.logada().then( (_empresa: any) => {
          this.empresa.estaAberta = _empresa.estaAberta;
          this.empresa.estaRecebendoPedidos = _empresa.estaRecebendoPedidos;

          if(  this.empresa.estaAberta &&   this.empresa.estaRecebendoPedidos )
            this.timerAberto.unsubscribe();

        });

      })
    }
  }

  reabrir() {
    this.empresasService.abra().then( (resposta) => {
      this.empresa.estaRecebendoPedidos = true;
    });
  }

}
