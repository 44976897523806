import {Component, Input, OnInit} from '@angular/core';
import {EnumStatusContato} from "../../../../server/lib/emun/EnumStatusContato";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'contato-status',
  templateUrl: './contato-status.component.html',
  styleUrls: ['./contato-status.component.scss']
})
export class ContatoStatusComponent implements OnInit {
  @Input() contato: any;
  constructor() { }

  ngOnInit() {
  }

  ativo() {
    return  this.contato && this.contato.status === EnumStatusContato.Ativo;
  }

  inativo() {
    return  this.contato && this.contato.status === EnumStatusContato.Inativo;
  }

  perdido() {
    return  this.contato && this.contato.status === EnumStatusContato.Perdido;
  }

  novo() {
    return  this.contato && this.contato.status === EnumStatusContato.Novo;
  }

  emPerigo() {
    return  this.contato && this.contato.status === EnumStatusContato.EmPerigo;
  }

  importado() {
    return  this.contato && this.contato.status === EnumStatusContato.Importado;
  }
}
