<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel">
      <i class=" fe-shopping-cart fa-lg "></i> Detalhes do Pedido
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal(detalhesPedido._pedido)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">

    <app-pedido-detalhes #detalhesPedido [modal] = true></app-pedido-detalhes>
  </div>

</div>
