import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ContatosService} from '../../services/contatos.service';
import {BuscarContatoComponent} from '../../componentes/buscar-contato/buscar-contato.component';
import {InserirPontosComponent} from "../../inserir-pontos/inserir-pontos.component";
import {NgForm} from "@angular/forms";
import {Location} from "@angular/common";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TelaExpandidaComponent} from "../../pedidos/TelaExpandida";

@Component({
  selector: 'app-adicionar-pontos',
  templateUrl: './adicionar-pontos.component.html',
  styleUrls: ['./adicionar-pontos.component.scss']
})
export class AdicionarPontosComponent extends TelaExpandidaComponent implements OnInit {
  @ViewChild('buscarContato', {static: true}) public buscarContato: BuscarContatoComponent;
  @ViewChild('appInserirPts') public appInserirPts: InserirPontosComponent;
  @ViewChild('frm')  frm: NgForm;

  contato: any = null;
  cartao: any = null;
  carregandoContato: boolean;
  mensagemErro: any = null;
  mensagemSucesso: any = null;
  enviando = false;
  bloqueado = false;
  codigoPontuacao: any;
  codigoResgate: string;
  urlRetorno: any;

  constructor(private contatosService: ContatosService, private  autorizacaoService: AutorizacaoService,
              private _location: Location,  private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.buscarContato.onContatoBuscado = (contatoBuscado) => {
      this.limpeContato();
      if ( contatoBuscado && contatoBuscado.id ) {
        this.contato = contatoBuscado;
        if(this.contato.cartoes.length === 1)
          this.cartao = this.contato.cartoes[0];
        this.carregandoContato = false;
        this.appInserirPts.setCartao(this.contato, this.cartao, null);
      } else {
        this.appInserirPts.reset();
      }
    };

    const { contato, cartao, valor } = window.history.state;

    this.urlRetorno = this.activatedRoute.snapshot.queryParams.urlRetorno;

    if(contato) this.exibaContato(contato, cartao, valor);

    this.autorizacaoService.estaLogado().then( (resposta: any) => {
      this.bloqueado = resposta.bloqueado;
      if (!resposta.logado)
        this.redirecioneTelaLogin();

    });
  }

  redirecioneTelaLogin(){
    this.router.navigateByUrl('/admin/login?t=' + window.location.pathname);
  }

  limpeContato(){
    this.contato = null;
    this.cartao = null;
    this.mensagemErro = null;
    this.mensagemSucesso = null;
  }

  adicionePontosSubmit() {
    if( this.enviando) return;

    this.fecheMensagens();
    this.buscarContato.valide();

    if ( this.contato == null || this.frm.invalid) {
      return;
    }
    this.enviando = true;

    this.appInserirPts.registrePontuacao().then( (resposta: any) => {
      this.limpeContato();
      this.enviando = false;
      this.mensagemSucesso = resposta;
      this.codigoResgate = resposta.codigoResgate
      this.codigoPontuacao = resposta.codigo ;

      this.buscarContato.reset();
      this.buscarContato.foque();

      if(this.codigoResgate != null)
        window.open('/imprimir/troca/' + this.codigoResgate)

      if(this.urlRetorno) {
        let dados = {mensagemPontuacao: this.mensagemSucesso, codigoPontuacao: this.codigoPontuacao};
        this.router.navigateByUrl(this.urlRetorno, { state: dados});
      }

    }).catch( (erro) => {
      this.enviando = false;
      this.mensagemErro = erro;
    });
  }


  exibaContato(contato: any, cartao: any, valor: any ) {
    this.contato = contato;
    this.cartao = cartao;
    this.buscarContato.exibaContato(contato);

    setTimeout(() => {
      this.appInserirPts.setCartao(contato, cartao, valor);
      this.appInserirPts.foqueElementosTela();
    }, 0);
  }

  fecheMensagens(){
    this.fecheMensagemErro();
    this.fecheMensagemSucesso();
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = null;
    this.codigoResgate = null;
  }

  fecheMensagemErro() {
    this.mensagemErro = null;
  }

  fecheMensagemResgate(){
    this.codigoResgate = null;
  }

  voltar() {
    if(!this.urlRetorno)
      this._location.back();
    else
      this.router.navigateByUrl(this.urlRetorno);
  }

}
