import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[nomeCompleto][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NomeConmpletoValidator), multi: true }
  ]
})
export class NomeConmpletoValidator  implements  Validator {
  constructor() {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    let valido = this.nomeValido(c.value)

    if(valido) return null;

    return { nomeCompleto: true }

  }

  nomeValido(nome){
    return nome && (nome.split(' ').length > 1);
  }
}


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[nomeValido][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NomeValidoValidator), multi: true }
  ]
})
export class NomeValidoValidator  implements  Validator {
  @Input("completo") completo = true;
  constructor() {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if(!c.value) return null;

    if(!this.nomeValido(c.value))
      return { nomeValido: true }

    if(this.completo && !this.ehNomeCompleto(c.value))
      return { nomeCompleto: true }

    return null;
  }

  ehNomeCompleto(nome){
    return nome && (nome.trim().split(' ').length > 1);
  }

  nomeValido(nome){
    if(nome && !nome.toLowerCase().match(/^[a-záàâãéèêíïóôõöúçñ ]+$/)) return false;

    if(nome && nome.trim().length <= 2) return false;

    const possuiCaracteresInvalidos = (/[0-9@]/g.test(nome));

    return !possuiCaracteresInvalidos;
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[cnpjValido][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CNPJValidador), multi: true }
  ]
})
// tslint:disable-next-line:directive-class-suffix
export class CNPJValidador implements Validator {
  @Input("required") required: any;
  constructor() {   }

  validate(c: AbstractControl) {
    if (!this.required && !c.value)
      return null;

    let valido = this.cnpjValido(c.value)
    if(valido) return null;

    return { cnpjInvalido: true }
  }

  cnpjValido(cnpj) {
    if (!cnpj) return false;

    cnpj = cnpj.replace(/\D/g, "");

    let digitos_iguais, i, tamanho, numeros, digitos, soma, pos;

    digitos_iguais = 1;

    if (cnpj.length < 14)
      return false;
    for (i = 0; i < cnpj.length - 1; i++)
      if (cnpj.charAt(i) !== cnpj.charAt(i + 1))
      {
        digitos_iguais = 0;
        break;
      }
    if (!digitos_iguais)
    {
      // Valida DVs
      tamanho = cnpj.length - 2
      numeros = cnpj.substring(0, tamanho);
      digitos = cnpj.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(0)))
        return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !==  Number(digitos.charAt(1)))
        return false;

      return true;

    } else {
      return false;
    }
  }

}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[cpfValido][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CPFValidator), multi: true }
  ]
})
export class CPFValidator  implements  Validator {
  @Input("required") required: any;
  constructor() {   }
  validate(c: AbstractControl) {
    if (!this.required && !c.value)
      return null;

    let valido = this.cpfValido(c.value)
    if(valido) return null;

    return { cpfInvalido: true }
  }

  cpfValido(cpf){
    if(!cpf) return false;

    let numeros, digitos, soma, i, resultado, digitos_iguais;

    cpf = cpf.replace(/\D/g, "");

    digitos_iguais = 1;
    if (!cpf || cpf.length < 11)
      return false;
    for (i = 0; i < cpf.length - 1; i++)
      if (cpf.charAt(i) !== cpf.charAt(i + 1))
      {
        digitos_iguais = 0;
        break;
      }
    if (!digitos_iguais)
    {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--)
        soma += numeros.charAt(10 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(0)))
        return false;
      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--)
        soma += numeros.charAt(11 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number( digitos.charAt(1)))
        return false;
      return true;
    }
    else
      return false;
  }
}
