
<div class="row mb-2">
  <div class="col">
    <div class="float-right">
      <button class="btn btn-blue mr-2 waves-effect waves-light   btn-novo " (click)="vaParaNovoPedido(false)">
        Novo Pedido</button>

      <button class="btn btn-warning mr-2 waves-effect waves-light   btn-novo " *ngIf="!empresa.integracaoDelivery" (click)="vaParaNovoPedido(true)">
        Novo Pedido Balcão</button>

      <button class="btn btn-danger waves-effect waves-light   btn-novo " (click)="abraModalFinalizar()">
        Finalizar Pedidos</button>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box  com-borda">
      <div class=" ribbon ribbon-dark float-left">
        <span>Total</span>
      </div>
       <h5 class="text-info float-right mt-0 total">
         {{resumoPedidos.total | number}}
       </h5>

      <div class="ribbon-content">
         <p></p>
      </div>

    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box ">
      <div class=" ribbon ribbon-warning float-left">
        <span>Em Preparação</span>
      </div>
      <div class="text-info float-right mt-0 total">
        {{resumoPedidos.emPreparacao | number}}
      </div>

      <div class="ribbon-content">
        <p></p>
      </div>

    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box  ">
      <div class=" ribbon ribbon-success float-left">
        <span>Em Entrega</span>
      </div>

      <div class="text-info float-right mt-0 total">
        {{resumoPedidos.emEntrega | number}}
      </div>

      <div class="ribbon-content">
        <p></p>
      </div>

    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="card-box ribbon-box  ">
      <div class=" ribbon ribbon-info float-left">
        <span>Novos</span>
      </div>
      <div class="text-info float-right mt-0 total">
        {{resumoPedidos.novos | number}}
      </div>


      <div class="ribbon-content">
        <p></p>
      </div>

    </div>
  </div>
</div>

<div class="alert alert-success mb-2" role="alert" *ngIf="mensagemSucesso" (click)="fecheMensagemSucesso()">
  <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}} <i class="fa fa-print fa-lg ml-1 cpointer " (click)="imprimaPedido(this.novoPedido)" ></i>
</div>


<div class="filtro-info alert alert-info"  >
  <div class="row">
    <div class="col-auto">
      <kendo-formfield>
        <kendo-textbox placeholder="Busque por nome ou telefone do cliente ou por código"
                       name="txtFiltro" [(ngModel)]="objFiltro.q"
                       [clearButton]="true" style="width: 500px"
                       appAutoFocus (afterValueChanged)="onFilter($event)">
          <ng-template kendoTextBoxSuffixTemplate>
            <button kendoButton [look]="'clear'" [icon]="'search'" (click)="onFilter($event)"></button>
          </ng-template>
        </kendo-textbox>
      </kendo-formfield>
    </div>

    <div class="col-auto" style="    max-width: 200px !important;" >

      <div class="form-group ">

        <kendo-datepicker name="dia" id="dia" format="dd/MM/yyyy" class="form-control" placeholder="busque pelo dia"
                          #dia="ngModel"  [(ngModel)]="objFiltro.dia"  (valueChange)="alterouDia()" >

          <kendo-datepicker-messages
            today="Hoje"
            toggle="Trocar calendário"
          ></kendo-datepicker-messages>
        </kendo-datepicker>

      </div>

    </div>

    <div class="col-auto row" style="    max-width: 360px !important;"   >
      <div class="form-group  col" >
        <kendo-timepicker #trInicio="ngModel" [(ngModel)]="objFiltro.horaInicio" name="horaInicio" placeholder="hora inicio"
                          [disabled]="!this.objFiltro.dia"   class="form-control" (valueChange)="alterouHorario()" >

        </kendo-timepicker>


      </div>

      <div class="form-group  col"  >
        <kendo-timepicker #trFim="ngModel" [(ngModel)]="objFiltro.horaFim" name="horaFim"  placeholder="hora fim"
             [min]="objFiltro.horaInicio"             [disabled]="!this.objFiltro.dia"     class="form-control" (valueChange)="alterouHorario()">

        </kendo-timepicker >
      </div>
    </div>

  </div>

   <p *ngIf="this.objFiltro.filtroData ">
     Pedidos realizados/agendados no dia  <b>{{this.objFiltro.dia | date: 'dd/MMM/yyyy'}}</b>

     <span *ngIf="objFiltro.horaInicio && objFiltro.horaFim">
        entre <b>{{objFiltro.horaInicio | date: 'HH:mm'}}</b> e <b>{{objFiltro.horaFim | date: 'HH:mm'}}</b></span>

     <button class="btn btn-outline-secondary d-inline-block ml-2" (click)="limpeFiltrosData()">limpar filtro</button>
   </p>

</div>


<div class="clearfix mt-2"> </div>


<app-grid-pedidos #gridPedidos   [carregando]="carregando" [mesa]="mesa"></app-grid-pedidos>
<div class="clearfix mt-4"> </div>

