import { Component, OnInit } from '@angular/core';
import {State} from "@progress/kendo-data-query";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {CatalogosService} from "../../superadmin/services/catalogos.service";

@Component({
  selector: 'app-lojas-rede',
  templateUrl: './lojas-rede.component.html',
  styleUrls: ['./lojas-rede.component.scss']
})
export class LojasRedeComponent implements OnInit {
  empresasResultSet: any ;
  tamanhoPagina = 20;
  loading = false;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 20
  };
  filtro: any = {
    nome: ''
  };
  page = 0;
  catalogos: any[] = [];
  modelos: any[] = [];
  carregando = true;
  modalAlterarCatalogo: boolean;
  empresaCatalogo: any;
  novoCatalogo: any;
  catalogoAnterior: any;
  alterandoCatalogo: any;

  constructor(private empresasService: EmpresasService,
              private catalogosService: CatalogosService) { }

  ngOnInit(): void {
    this.carregueEmpresas();
    this.carregueCatalogos()
  }

  public onPageChange(e: PageChangeEvent): void {
    this.gridState.skip = e.skip;
    this.carregueEmpresas();
  }

  private carregueEmpresas() {
    this.loading = true
    this.empresasService.listeEmpresasRede(this.gridState.skip, this.gridState.take, this.filtro.nome).then( (resposta) => {
      this.loading = false;
      this.empresasResultSet = {
        data: resposta.empresas,
        total: resposta.total
      };
      console.log(resposta)
      this.empresasService.verifiqueEmpresasOnline().then( (respLojas) => {

        for( let empresa of resposta.empresas ) {
          empresa.online = false;
        }

        for( let assinante of respLojas.assinantes ) {
          const empresa = resposta.empresas.find( (objEmpresa) => {
            return objEmpresa.id === assinante.empresa.id;
          });

          if( empresa ) {
            empresa.online = true;
          }

        }
      });
    });
  }

  onFilter(event) {
    this.carregueEmpresas();
  }

  atualize() {
    this.carregueEmpresas();
  }

  alterouCatalogo(empresaCatalogo: any, novoValor: any) {
    this.empresaCatalogo = empresaCatalogo
    this.novoCatalogo = novoValor
    this.modalAlterarCatalogo = true

    return false;
  }

  alterouModeloCatalogo(empresa: any, catalogo: any){
    delete empresa.catalogo;
    this.empresaCatalogo = empresa;
    this.confirmeAlteracaoModeloCatalogo()

    return false;

  }

  private carregueCatalogos() {
    this.carregando = true;
    this.catalogosService.listeCatalogos({}).then( (resposta) => {
      this.carregando = false;
      this.modelos = resposta;
      this.catalogos = resposta.map((catalogoRede) => { return {
        id: catalogoRede.idCatalogo,
        nome: catalogoRede.nome
      } })
      console.log(this.catalogos)
    })
  }

  modelosDaRede(empresa){
    if(empresa.idRede === 1) return this.modelos.filter((item: any) => item.nome.startsWith('CIB'))
    if(empresa.idRede === 2) return this.modelos.filter((item: any) => item.nome.startsWith('GED'))

    return []

  }

  close(cancel: string) {
    this.empresaCatalogo = null
    this.novoCatalogo = null
    this.catalogoAnterior = null
    this.modalAlterarCatalogo = false;
  }

  confirmeAlteracaoModeloCatalogo(){
    this.catalogosService.altereModeloCatalogoDaEmpresa(this.empresaCatalogo).then((resultado) => {
      this.alterandoCatalogo = false;
      this.close('alterou')
    }).catch(() => {
      this.alterandoCatalogo = false;
      delete this.empresaCatalogo.modeloCatalogoDaRede
    })
  }

  confirmeAlteracaoCatalogo() {
    this.empresaCatalogo.catalogo = this.novoCatalogo
    this.alterandoCatalogo = true
    this.catalogosService.altereCatalogoDaEmpresa(this.empresaCatalogo).then((resultado) => {
      alert(resultado);
      this.alterandoCatalogo = false;
      this.close('alterou')
    })


  }

  canceleAlteracaoCatalogo() {
    this.empresaCatalogo.catalogo = this.catalogoAnterior
    this.close('cancel')
  }

  salveCatalogoAnterior(dataItem: any) {
    this.catalogoAnterior = dataItem.catalogo

  }
}
