import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AvaliacaoService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  liste(inicio: number, total: number) {
    return this.obtenha('/avaliacoes/liste', {i: inicio, t: total})
  }
}
