import {Component, Inject, Input, OnInit} from '@angular/core';
import {GroupDescriptor, process, State} from "@progress/kendo-data-query";
import {GridHorariosEditService} from "../../superadmin/cad-empresas/grid-horarios-edit-service";
import {Observable} from "rxjs";
import {AddEvent, GridDataResult} from "@progress/kendo-angular-grid";
import {map} from "rxjs/operators";
import {EmpresasService} from "../../superadmin/services/empresas.service";

declare var _;
declare var moment;
@Component({
  selector: 'app-cad-horariosfuncionamento',
  templateUrl: './cad-horariosfuncionamento.component.html',
  styleUrls: ['./cad-horariosfuncionamento.component.scss']
})
export class CadHorariosfuncionamentoComponent implements OnInit {
  @Input() empresa: any = {};
  private indiceLinhaEditada: number;
  private horarioEditado: any;
  private  editService: GridHorariosEditService;
  public gridState: State = {
    sort: [],
    skip: 0
  };

  public groups: GroupDescriptor[] = []
  public viewHorarios: Observable<GridDataResult>;
  diasDaSemana = [{dia: 0 , descricao: 'Domingo'}, {dia: 1 , descricao: 'Segunda'},
    {dia: 2 , descricao: 'Terça'},   {dia: 3 , descricao: 'Quarta'},
    {dia: 4 , descricao: 'Quinta'},  {dia: 5 , descricao: 'Sexta'},
    {dia: 6 , descricao: 'Sabado'} ];

  groupable: any;
  servicos = [ {id: 'site', descricao: 'Pedidos delivery (site)'} ,
               {id: 'presencial', descricao: 'Pedidos mesas (presencial)'}
  ]
  servico: any;
  horariosFuncionamento = []
  horariosDelivery  = []
  constructor(  private empresaService: EmpresasService, @Inject(GridHorariosEditService) gridHorariosEditService: any) {
    this.editService = gridHorariosEditService();
  }

  ngOnInit() {
    this.groupable = true
    this.groups = [{ field: 'diaDaSemana'}  ];
    this.gridState.group = this.groups;
    this.servico = this.servicos[0];


    this.diasDaSemana.forEach( (diaDaSemana) => {
      this.servicos.forEach((servico) => {
        if(!this.empresa.horariosFuncionamento.find((item: any) => item.diaDaSemana ===  diaDaSemana.dia && item.servico === servico.id))
          this.empresa.horariosFuncionamento.push( {diaDaSemana: diaDaSemana.dia, funciona: false, servico: servico.id})
      })
    })

    this.viewHorarios = this.editService.pipe(map((data: any[]) => {
      return process(data, this.gridState);
    }));


    if(!this.fazDelivery(this.empresa))
      this.servicos[0].descricao = String(`Pedidos retirada (site)`)

    this.editService.setEmpresa( this.empresa, this.servico.id );

  }

  fazDelivery(empresa: any){
    let formaDelivery = empresa.formasDeEntrega.find( formaDeEntregaEmpresa => {
      return formaDeEntregaEmpresa.formaDeEntrega.nome === 'Receber em casa'
    });

    return formaDelivery && formaDelivery.ativa
  }


  public onStateChange(state: State) {
    this.gridState = state;
    this.editService.read();
  }

  public editarHorario({sender, rowIndex, dataItem}) {
    this.closeEditor(sender);

    if(  !dataItem.id)  dataItem.funciona = true

    this.indiceLinhaEditada = rowIndex;
    this.horarioEditado = Object.assign({}, dataItem);

    sender.editRow(rowIndex);
  }

  public removerHorario(event: any) {
    let dataItemRemovido = event.dataItem

    let qtdHorariosDia = 0
    for(let dataItem of this.editService.data)
      if (dataItem.diaDaSemana === dataItemRemovido.diaDaSemana)
        qtdHorariosDia++

    if(qtdHorariosDia === 1) return alert('Só é possível remover turnos adicionais')

    if(!dataItemRemovido.id){
      this.editService.removeu(event.rowIndex);
      return;
    }

    this.editService.removaHorario(dataItemRemovido, event.isNew).then( (data) => {
      console.log(data)
      if(data.sucesso) {
        this.editService.removeu(event.rowIndex);
      }
      else
        alert(data.mensagem)
    }).catch( erro => {
      alert(erro)
    })
  }

  public cancelarEdicaoHorario({sender, rowIndex}) {
    if(  !this.horarioEditado.id)
      this.horarioEditado.funciona = false

    this.closeEditor(sender, rowIndex);
  }

  ajusteHorarios(horarioTela: any){
    let dados: any =  Object.assign({}, horarioTela);

    let horaAbertura  = horarioTela.horarioAbertura.getHours().toString().padStart(2, '0');
    let minutosAbertura = horarioTela.horarioAbertura.getMinutes().toString().padStart(2, '0');

    dados.horarioAbertura =  String(`${horaAbertura}:${minutosAbertura}:00`)

    let horaFechamento  = horarioTela.horarioFechamento.getHours().toString().padStart(2, '0');
    let minutosFechamento = horarioTela.horarioFechamento.getMinutes().toString().padStart(2, '0');

    dados.horarioFechamento = String(`${horaFechamento}:${minutosFechamento}:00`)

    return dados;

  }

  public salvarHorario({sender, rowIndex, dataItem, isNew}) {
    let dadosHorario = this.ajusteHorarios(dataItem);

    this.editService.salveHorario(dadosHorario, isNew).then( (data) => {
      console.log(data)
      if(data.sucesso) {
        sender.closeRow(rowIndex);
        this.indiceLinhaEditada = undefined;
        this.horarioEditado = undefined;
      }
      else
        alert(data.mensagem)
    }).catch( erro => {
      alert(erro)
    })
  }

  private closeEditor(grid, rowIndex = this.indiceLinhaEditada) {
    grid.closeRow(rowIndex);
    this.editService.resetItem(this.horarioEditado);
    this.indiceLinhaEditada = undefined;
    this.horarioEditado = undefined;
  }

  getDiaSemana(dia: number) {
    let descricao = '';

    this.diasDaSemana.forEach( (diaDaSemana: any) => {
      if(diaDaSemana.dia === dia) descricao = diaDaSemana.descricao;
    })
    return descricao;
  }

  alterouSempreReceberPedidos() {
    this.empresaService.atualiseSempreReceberPedidos(this.empresa).then( () => {
    }).catch(() => {
      this.empresa.sempreReceberPedidos = !this.empresa.sempreReceberPedidos;
    })
  }

  adicionarTurno(dataItem: any) {
    this.empresa.horariosFuncionamento.push( {diaDaSemana: dataItem.diaDaSemana, funciona: false,
      servico: this.servico.id, $id:  new Date().getTime()})
    this.editService.recarregueDados();
  }


  selecionouServico(servico: any){
    this.editService.alterouServico(servico.id)
  }
}
