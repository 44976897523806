import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {orderBy} from "@progress/kendo-data-query";

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

@Injectable()
export class GridHorariosEditService extends BehaviorSubject<any[]> {
  data: any[] = [];
  empresa: any;
  filtroServico: string;
  constructor(private http: HttpClient) {
    super([]);
  }

  setEmpresa(empresa: any, servico: string) {
    this.empresa = empresa;
    this.filtroServico = servico;
    this.empresa.horariosFuncionamento.forEach( (horarioFuncionamento) => {
      if(horarioFuncionamento.horarioAbertura)
        horarioFuncionamento.horarioAbertura = this.horarioToDate( horarioFuncionamento.horarioAbertura)

      if(horarioFuncionamento.horarioFechamento)
        horarioFuncionamento.horarioFechamento = this.horarioToDate(  horarioFuncionamento.horarioFechamento )
    })

    this.data = orderBy(this.obtenhaDados(), [{
      field: 'diaDaSemana',
      dir: 'asc'
    }]);
    console.log(this.data)
    this.read();
  }

  obtenhaDados(){
    return this.empresa.horariosFuncionamento.filter((item: any) => item.servico === this.filtroServico)
  }

  removeu(rowIndex){
    let itemRemovido = this.data.slice(rowIndex)[0]

    let ihorarios = 0;

    for(let i = 0; i <  this.empresa.horariosFuncionamento.length ; i ++){
      if(itemRemovido.id && this.empresa.horariosFuncionamento[i].id === itemRemovido.id){
        ihorarios = i;
      } else if(itemRemovido.$id && this.empresa.horariosFuncionamento[i].$id === itemRemovido.$id){
        ihorarios = i;
      }
    }


    this.empresa.horariosFuncionamento.splice(ihorarios, 1);
    this.data.splice(rowIndex, 1);

    this.read();
  }

  private horarioToDate(horario: any): Date{
    if(!horario || typeof horario === 'object')
      return horario;

    let horarios: any =    horario.split(':');
    let date = new Date();
    date.setHours( horarios[0] );
    date.setMinutes( horarios[1] );

    return date;
  }

  public read() {
    super.next(this.data);
  }

  public salveHorario(horario: any, isNew?: boolean): Promise<any> {
     return new Promise((resolve) => {
       this.http
         .put('/empresas/' + this.empresa.id + '/horario', horario).toPromise().then( (response: any) => {
         console.log(response)
         if(response.sucesso){
           if(!horario.id) horario.id = response.data.id;
           this.read();
           resolve({sucesso: true, resposta: response.data} );
         } else {
           resolve( {sucesso: false, mensagem: response.erro}  );
         }
       })
     })
  }

  public removaHorario(horario: any, isNew: boolean): Promise<any> {
    return new Promise((resolve) => {
      console.log(horario)
      if(isNew)
        return resolve({sucesso: true, resposta: "Horário removido com sucesso"})
      this.http
        .delete('/empresas/' + this.empresa.id + '/horario/' + horario.id).toPromise().then( (response: any) => {
        console.log(response)
        if(response.sucesso){
          this.read();
          resolve({sucesso: true, resposta: response.data} );
        } else {
          resolve( {sucesso: false, mensagem: response.erro}  );
        }
      })
    })
  }

  public remove(data: any) {
    this.reset();
  }

  public resetItem(dataItem: any) {
    if (!dataItem) { return; }
    let originalDataItem = {}

    if(dataItem.id >= 0)
      originalDataItem = this.data.find(item => item.id === dataItem.id);

    Object.assign(originalDataItem, dataItem);
    super.next(this.data);
  }

  private reset() {
    this.data = [];
  }

  public alterouServico(servico){
    this.filtroServico = servico;
    this.recarregueDados();
  }

  public recarregueDados() {
    this.data = orderBy(this.obtenhaDados(), [{
      field: 'diaDaSemana',
      dir: 'asc'
    }]);
    this.read();
  }



}
