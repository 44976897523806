import { Injectable } from '@angular/core';
import {ActivatedRoute, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from "@angular/router";
import {AutorizacaoService} from "../services/autorizacao.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(location.pathname.indexOf('/admin/assinatura/pagamento') === 0 ||
       location.pathname.indexOf('/admin/assinatura/trocarcartao') === 0 ) return  true;

    return this.autorizacaoService.estaLogado().then((resposta: any) => {

      if(!resposta.logado && !resposta.modoGarcom){
        this.router.navigate(['/admin/login'], { queryParams: { t: state.url }});
      }


      if(resposta.modoGarcom && location.pathname.indexOf('/admin/index') === 0) {
        this.router.navigate(['/admin/pedidos-mesas'], {})
      }


      console.log('AuthGuard')
      console.log(resposta)

      return  true;
    });
  }
}
