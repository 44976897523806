import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from "@progress/kendo-angular-dialog";
import {EntregadorService} from "../../services/entregador.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-cad-entregador',
  templateUrl: './cad-entregador.component.html',
  styleUrls: ['./cad-entregador.component.scss']
})
export class CadEntregadorComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;
  entregador: any = {ativo: true};
  windowRef: DialogRef;
  salvando: any;
  mensagemErro: any;

  constructor(private entregadorService: EntregadorService) { }

  ngOnInit(): void {
  }

  onSalvar() {
    if(this.frm.valid){
      this.salvando = true;
      this.entregadorService.salveEntregador(this.entregador).then( (resp) => {
        if(!this.entregador.id)
          this.entregador.id = resp.id;
        this.windowRef.close(this.entregador);
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro = erro;
      })
    }
  }

  fechar() {
    this.windowRef.close();
  }
}
