<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit(frm, notificacao)">
  <div #pnl class="modal-body" style="max-width: 1024px;">
    <div class="alert alert-success alert-dismissible fade show" *ngIf="notificacao.mensagemSucesso" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{notificacao.mensagemSucesso}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="form-group mb-3">
      <div class="row">
        <div class="col">
          <label>Notificação Ativada</label>
          <div class="ui-g" style="width:250px;margin-bottom:10px">
            <kendo-switch [(ngModel)]="notificacao.ativada" name="ativada" id="ativada" [disabled]="!notificacao.podeDesativar"></kendo-switch>
          </div>

        </div>
        <div class="col">
          <label>Habilitar Preview dos Links</label>
          <div class="ui-g" style="width:250px;margin-bottom:10px">
            <kendo-switch [(ngModel)]="notificacao.fazerPreview" name="fazerPreview" id="fazerPreview"></kendo-switch>
          </div>
        </div>

        <ng-container *ngIf="notificacao.tipoDeNotificacao === NOTIFICACAO_MENSAGEM_SAUDACAO">
          <div class="col  mt-2">
            <label>Marcar mensagem como lida</label>
            <div class="ui-g" style="width:250px;margin-bottom:10px">
              <kendo-switch [(ngModel)]="notificacao.marcarComoLida" name="marcarComoLida" id="marcarComoLida"></kendo-switch>
            </div>
          </div>

          <div class="col mt-2" >
            <label>Tempo para enviar Nova Mensagem (em horas)</label>
            <div class="ui-g" style="width:250px;margin-bottom:10px">
              <input type="text" class="form-control" currencyMask
                     [options]="{ prefix: '', suffix: ' Hora(s)', precision: 0, thousands: '',
                   decimal: ',', align: 'left', allowNegative: false }"
                     [(ngModel)]="tempoMensagemWhatsapp"/> <span>horas</span>
            </div>
          </div>
        </ng-container>

      </div>
    </div>

    <div class="form-group mb-3" style="position: relative;">
      <label for="txtMensagem">Mensagem</label>
      <div>
        <div style="position: absolute;right: 0px;top: -13px;">
          <kendo-dropdownbutton *ngIf="templates && templates.length > 0" [data]="templates" textField="nome" valueField="nome" (itemClick)="adicioneCampo(textArea, notificacao, $event)">
            Adicionar Template <i class="mdi mdi-chevron-down"></i>
          </kendo-dropdownbutton>

          <kendo-dropdownbutton [data]="campos" textField="nome" valueField="nome" (itemClick)="adicioneCampo(textArea, notificacao, $event)">
            Adicionar Campo <i class="mdi mdi-chevron-down"></i>
          </kendo-dropdownbutton>
        </div>

        <textarea class="form-control" id="txtMensagem" rows="10" name="txtMensagem" [(ngModel)]="notificacao.mensagem" #txtMensagem="ngModel" #textArea
                  required appAutoFocus (ngModelChange)="alterouMensagem(notificacao)" [maxlength]="QTDE_MAXIMA_CARACTERES"></textarea>
        <div>
          <span [ngClass]="{'text-warning': notificacao.qtdeCaracteresRestantes < 50, 'text-success': notificacao.qtdeCaracteresRestantes >= 50,
           'text-danger': notificacao.qtdeCaracteresRestantes < 20}">
            {{notificacao.qtdeCaracteresRestantes}} caracteres restantes</span>
        </div>
        <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched || frm.submitted)" class="invalid-feedback">
          <div *ngIf="txtMensagem.errors.required">
            Informe uma mensagem que será enviada ao cliente.
          </div>
        </div>
      </div>
    </div>

    <span *ngIf="informarQtdeDiasAtiva(notificacao)">
       <div class="form-group mb-3" >
        <label>Quantidade de dias  </label>
        <div class="ui-g"  >
          <input type="number" [required]="informarQtdeDiasAtiva(notificacao)" pInputText pKeyFilter="int" class="form-control col-lg-2 col-6"
                 name="qtdeDiasAtiva"    #qtdeDiasAtiva="ngModel" [(ngModel)]="notificacao.qtdeDiasAtiva"   autocomplete="off">
          <h5 class="ml-1"><strong> dia<span *ngIf="notificacao.qtdeDiasAtiva > 1">s</span></strong></h5>

          <div *ngIf="qtdeDiasAtiva.invalid && (qtdeDiasAtiva.dirty || qtdeDiasAtiva.touched || frm.submitted)" class="invalid-feedback">
            <div *ngIf="qtdeDiasAtiva.errors.required">
              Informe a quantidade de dias que irá disparar o envio da notificação
            </div>
          </div>
        </div>
       </div>
    </span>


    <div class="form-group mb-3" *ngIf="!informarQtdeDiasAtiva(notificacao)">
      <label>Quantidade Dias para permitir enviar nova notificação</label>
      <div class="ui-g" style="margin-bottom:10px">
        <input type="number" required pInputText pKeyFilter="int" class="form-control col-lg-2 col-6"
               name="qtdeDias"    #txtQtdeDeDias="ngModel" [(ngModel)]="notificacao.qtdeDeDiasNovaNotificacao"
               minimo="-1" maximo="180" autocomplete="off">
        <h5 class="ml-1"><strong> dia<span *ngIf="notificacao.qtdeDeDiasNovaNotificacao > 1">s</span></strong></h5>

        <div *ngIf="txtQtdeDeDias.invalid && (txtQtdeDeDias.dirty || txtQtdeDeDias.touched || frm.submitted)" class="invalid-feedback">
          <div *ngIf="txtQtdeDeDias.errors.required">
            Informe a quantidade de dias mínimo para enviar nova notificação.
          </div>
          <div *ngIf="txtQtdeDeDias.errors.minimo">
            A quantidade de dias para enviar outra mensagem deve estar entre menor que 365 dias.
          </div>
        </div>
      </div>
    </div>e

    <app-controle-menu-whatsapp [objeto]="notificacao"></app-controle-menu-whatsapp>

    <hr>

    <p class="text-danger mt-2 mb-2" *ngIf="erro">{{erro}}</p>

    <div class="alert alert-success mt-2" *ngIf="notificacao.mensagemSucesso" (close)="fecheMensagemSucesso()">
      <i class="mdi mdi-check-all mr-2"></i> {{notificacao.mensagemSucesso}}
    </div>

    <div class="text-center mb-1">
      <button type="submit" class="btn btn-primary width-lg" >Salvar</button>
    </div>
  </div>
</form>
