import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CampanhaService} from "../../services/campanha.service";
import {CampanhaRedeService} from "../../services/campanha-rede.service";
declare let moment;

@Component({
  selector: 'app-marketing-rede',
  templateUrl: './marketing-rede.component.html',
  styleUrls: ['./marketing-rede.component.scss']
})
export class MarketingRedeComponent implements OnInit {
  campanhas = [];
  total = 0;
  tiposDeEnvio = [
    {nome: 'Todas Campanhas', id: 'todos'},
    {nome: 'Desativadas', id: 'desativadas'}
  ];
  opcoesDeFiltro = [
    'todos',
    'recorrentes',
    'envio-unico',
    'desativadas'
  ];
  filtroSelecionado: any = 'todos';
  carregando = false;

  constructor(private router: Router, private campanhaRedeService: CampanhaRedeService,
              private _ngZone: NgZone) {
  }
  ngOnInit() {
    this.obtenhaCampanhas();
  }

  obtenhaCampanhas() {
    this.carregando = true;
    this.campanhas = [];

    this.campanhaRedeService.obtenhaCampanhas(this.filtroSelecionado).then( (resposta: any) => {
      this.carregando = false;
      this.campanhas = resposta.campanhas;
      this.total = resposta.total;

      this.campanhas.forEach( ( async (campanha: any) => {
        const diferenca = moment(campanha.horarioEnvio).diff(moment());

        if( diferenca < 0 ) {
          campanha.horario = 'Deveria ter sido enviada';
        } else {
          campanha.horario = 'Será enviada ' + moment.duration(diferenca).humanize(true);
        }
        let qtdeLidas =  0;
        this._ngZone.run(() => {
          campanha.qtdeLidas = qtdeLidas;
        });
      }));
    }).catch( (erro: any) => {
      this.carregando = false;
    });
  }

  novaCampanha() {
    this.router.navigateByUrl( '/admin-rede/marketing/nova-campanha').then( () => {});
  }

  selecionouTipoDeCampanha($event: any) {
    if( $event.nextId === 'todos' ) {
      this.filtroSelecionado = this.opcoesDeFiltro[0];
    } else if( $event.nextId === 'recorrentes' ) {
      this.filtroSelecionado = this.opcoesDeFiltro[1];
    } else if( $event.nextId === 'envio-unico') {
      this.filtroSelecionado = this.opcoesDeFiltro[2];
    }

    this.obtenhaCampanhas();
  }
}
