
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {VendasPedidoService} from "../../services/vendas-pedido.service";
import {ActivatedRoute} from "@angular/router";
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impressao-relatorio-vendas',
  templateUrl: './impressao-relatorio-vendas.component.html',
  styleUrls: ['./impressao-relatorio-vendas.component.scss',  '../../componentes/imprimir.component.scss']
})
export class ImpressaoRelatorioVendasComponent implements OnInit {
  carregando: any;
  resumoVendas: any;
   empresa: any;

  constructor(private constantsService: ConstantsService, private vendasService: VendasPedidoService,
              private activatedRoute: ActivatedRoute) {
    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if(empresa) {
        this.empresa = empresa
      }
    });

    this.carregueResumoVendas();
  }

  ngOnInit(): void {

  }

  carregueResumoVendas() {
    if(this.carregando) return;

    this.carregando = true;

    this.vendasService.obtenhaResumo(this.obtenhaFiltro()).then( (resposta) => {
      this.carregando = false;
      this.resumoVendas = resposta;
      let dataInicial: string = this.activatedRoute.snapshot.queryParams['inicio']
      let dataFinal: string = this.activatedRoute.snapshot.queryParams['fim']
      this.resumoVendas.dataInicial =  dataInicial ? dataInicial.substr(6, 2) + '/'
        + dataInicial.substr(4, 2)  + "/" + dataInicial.substr(0, 4) : null
      //moment(this.activatedRoute.snapshot.queryParams['inicio'], 'YYYYMMDD')
      this.resumoVendas.dataFinal =  dataFinal ? dataFinal.substr(6, 2) + '/'
        + dataFinal.substr(4, 2)  + "/" + dataFinal.substr(0, 4) : null
      //moment(this.activatedRoute.snapshot.queryParams['fim'], 'YYYYMMDD')
      this.resumoVendas.empresa = this.empresa
    })
  }

  obtenhaFiltro( ){
    let dados: any = { delivery: true }

    let dtInicio = this.activatedRoute.snapshot.queryParams['inicio'];
    let dtFim = this.activatedRoute.snapshot.queryParams['fim'];

    let formaDePagamento = this.activatedRoute.snapshot.queryParams['fp']
    let cupons = this.activatedRoute.snapshot.queryParams['cps']
    let fe = this.activatedRoute.snapshot.queryParams['fe']
    let np = this.activatedRoute.snapshot.queryParams['np']
    let dl = this.activatedRoute.snapshot.queryParams['dl']
    let ms = this.activatedRoute.snapshot.queryParams['ms']

    if(dtInicio) dados.di = dtInicio;
    if(dtFim) dados.df = dtFim;

    if(formaDePagamento) dados.fp = formaDePagamento;
    if(cupons) dados.cps = cupons;
    if(fe) dados.fe = fe;
    if(np) dados.np = np;
    if(dl) dados.dl = dl;
    if(ms) dados.ms = ms;

    dados.agfp = true

    return dados;
  }
}
