import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotificacaoAppService} from "../../../services/notificacao-app.service";

@Component({
  selector: 'app-exibir-notificacaoapp',
  templateUrl: './exibir-notificacaoapp.component.html',
  styleUrls: ['./exibir-notificacaoapp.component.scss']
})
export class ExibirNotificacaoappComponent implements OnInit {
  @Input() notificacaoApp: any = {};
  @Input() modoAprovacao: boolean;

  mensagem = '';
  mensagemWarning = '';
  confirmarEnvioCampanha = false;

  constructor(private router: Router, private notificacaoAppService: NotificacaoAppService) { }

  ngOnInit(): void {
  }

  editarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/nova-campanha-app/' + campanha.id, {}).then( () => {});
  }

  irParaTelaTestarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/tela-enviar-mensagens/' + campanha.id, {state: {data: campanha, testar: true }}).
    then( () => {});
  }

  irParaTelaEnviarCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/tela-enviar-mensagens/' + campanha.id, {state: {data: campanha }}).
    then( () => {});
  }

  verDetalhesCampanha(campanha: any) {
    this.router.navigateByUrl( '/admin/campanha/' + campanha.id + '/detalhes' , {state: {data: campanha }}).
    then( () => {});
  }

  enviarCampanha(campanha: any) {
    this.confirmarEnvioCampanha = true;
  }

  aprovarCampanha(campanha: any) {
    this.mensagem = '';
    this.mensagemWarning = '';

    this.notificacaoAppService.aprove(campanha).then( (resposta) => {
      this.mensagem = resposta.msg;
      campanha.statusAprovacao = resposta.status;
    });
  }

  reprovarCampanha(campanha: any) {
    this.mensagem = '';
    this.mensagemWarning = '';

    this.notificacaoAppService.reprove(campanha).then( (resposta) => {
      this.mensagemWarning = resposta.msg;
      campanha.statusAprovacao = resposta.status;
    });
  }

  close(comando: string) {
    if( comando === 'sim' ) {
      this.notificacaoAppService.envieNotificacaoApp(this.notificacaoApp).then( (resposta) => {
        this.notificacaoApp.status = 'Enviada';
      });
    }

    this.confirmarEnvioCampanha = false;
  }
}
