import { Component, OnInit } from '@angular/core';
import {Mesa} from "../../objetos/Mesa";
import {MesaService} from "../../../services/mesa.service";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import moment from "moment";
import {ComandaService} from "../../../services/comanda.service";
import {AutorizacaoService} from "../../../services/autorizacao.service";
import {ActivatedRoute} from "@angular/router";
import {PedidosService} from "../../../services/pedidos.service";
import {MonitoradorPedidos} from "../../../fidelidade/MonitoradorPedidos";

@Component({
  selector: 'app-tela-pedidos-mesas',
  templateUrl: './tela-pedidos-mesas.component.html',
  styleUrls: ['./tela-pedidos-mesas.component.scss']
})
export class TelaPedidosMesasComponent implements OnInit {
  objetos: Array<Mesa> = [];
  empresa: any;
  carregando = false;
  modoGarcom: boolean;
  possuiModuloGarcom = false;
  mesasBloqueadas = false;
  comErros = false;
  constructor(private constantsService: ConstantsService, private mesaService: MesaService,
              private route: ActivatedRoute, private pedidosService: PedidosService,    public monitoradorPedidos: MonitoradorPedidos,
              private comandaService: ComandaService, private autorizacaoService: AutorizacaoService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) return;

      if(this.route.snapshot.routeConfig.path.endsWith('erros'))
        this.comErros = true;


      this.constantsService.moduloGarcom$.subscribe( (data) => {
        this.possuiModuloGarcom = data;
      });

      if(this.autorizacaoService.getUser().garcom) this.modoGarcom = true;
      this.empresa = empresa;


      if(empresa.integracaoDelivery){
        let sistema = empresa.integracaoDelivery.sistema;
        this.mesasBloqueadas = sistema === 'gcom';
      }

      if(!this.comErros){
        this.pedidosService.listePedidosMesaComErro().then( (resposta) => {
          if(resposta.pedidos)
            this.monitoradorPedidos.notifiqueMesasComErro(resposta.pedidos.length)
        });
      }
    });
  }

  novo() {}
}
