import {TamanhoPapel} from "./TamanhoPapel";

export class ProcessadorTexto {
  public static readonly _MARCADOR_FIM_PAPEL = "-*-*-FIM PAPEL-*-*-";

  public impressora: any;
  protected papel: TamanhoPapel;

  constructor(impressora) {
    this.impressora = impressora;

    switch (this.impressora.tamanhoPapel) {
      case 'Layout58mm':
        this.papel = TamanhoPapel.Papel58mm
        break;
      case 'Layout58mm32car':
        this.papel = TamanhoPapel.Papel58mm32car
        break;
      case 'Layout80mm42car':
        this.papel = TamanhoPapel.Papel80mm42car
        break;
      case 'Layout80mm':
      default:
        this.papel = TamanhoPapel.Papel80mm
    }
  }


  centralizarTexto(texto: string, tamanho: number) {
    let tamanhoRestante = tamanho - texto.length

    if (tamanhoRestante < 0) tamanhoRestante = 0

    return this.espaco(Math.floor(tamanhoRestante / 2)) + texto.substr(0, tamanho) + this.espaco(Math.ceil(tamanhoRestante / 2))
  }

  linha(texto: string = "") {
    return texto + this.papel.quebraLinha
  }

  centralizarLinha(texto: string) {
    return this.linha(this.centralizarTexto(texto, this.papel.tamanhoLinha))
  }

  alinhadoEsquerda(texto: string, tamanho: number = this.papel.tamanhoLinha) {
    let tamanhoRestante = tamanho - texto.length

    if (tamanhoRestante < 0) tamanhoRestante = 0

    return texto.substr(0, tamanho) + this.espaco(tamanhoRestante)
  }

  alinhadoDireita(texto: string, tamanho: number = this.papel.tamanhoLinha) {
    let tamanhoRestante = tamanho

    if (!texto) return this.espaco(tamanhoRestante)

    tamanhoRestante -= texto.length

    if (tamanhoRestante < 0) tamanhoRestante = 0

    return this.espaco(tamanhoRestante) + texto.substr(0, tamanho)

  }

  espaco(qtd: number) {
    return " ".repeat(qtd)
  }

  multiplasLinhas(texto: string, tamanho: number) {
    //.{0,150}(\s+|$)
    let resposta = texto.match(new RegExp(".{1," + tamanho + "}", 'g'))

    if (!resposta) return []

    return resposta
  }
}
