import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ImpressaoService} from "../../services/impressao.service";
import {NgForm} from "@angular/forms";
import {EnumTamanhoPapel} from "../../../../server/lib/emun/EnumTamanhoPapel";
import {EnumMomentoImprimirAuto} from "../../../../server/lib/emun/EnumMomentoImprimirAuto";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-config-impressao',
  templateUrl: './config-impressao.component.html',
  styleUrls: ['./config-impressao.component.scss']
})
export class ConfigImpressaoComponent implements OnInit {
  private _empresa: any;
  impressoras: any[] = [];
  conectando = true;
  qzInstalado: boolean;
  salvando: boolean;
  layouts = ['PedidoCompleto', 'PedidoResumido', 'UmPorProduto'];
  @ViewChild('frmConfigImpressao')   frmConfigImpressao: NgForm;
  mensagemSucesso: string;
  erro: string;
  listaTamanhosPapeis = [
    EnumTamanhoPapel.Layout80mm,
    EnumTamanhoPapel.Layout80mm42car,
    EnumTamanhoPapel.Layout58mm,
    EnumTamanhoPapel.Layout58mm32car,
    EnumTamanhoPapel.LayoutA4
  ];
  recarregar: boolean
  isMobile: boolean;
  @Input()
  set empresa(empresa: any) {
    if(!empresa.configImpressao)
      empresa.configImpressao = {}

    if(!empresa.configImpressao.momentoImprimirAuto)
      empresa.configImpressao.momentoImprimirAuto =  EnumMomentoImprimirAuto.StatusPedido

    if(empresa.configImpressao.imprimirOnlineNaoPago === undefined)
      empresa.configImpressao.imprimirOnlineNaoPago = false

    this._empresa = empresa;

  }

  get empresa() {
    return this._empresa;
  }

  constructor(private empresaService: EmpresasService, private impressao: ImpressaoService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile()
    if(this.isMobile) this.conectando = false;

  }

  ngOnInit(): void {
    if(this.impressao.conectando && !this.isMobile) {
      this.impressao.conectou.subscribe((conectou: boolean) => {
        this.conectando = false
        this.qzInstalado = conectou;
        if(conectou)
          this.carregueImpressoras()
      })
    } else {
      this.conectando = false;
      this.qzInstalado = this.impressao.qzInstalado
      if(this.qzInstalado) this.carregueImpressoras()
    }

  }

  tenteConectarQZ() {
    this.conectando = true;
    this.impressao.possuiQZ().then((possui: boolean) => {
      this.conectando = false;
      this.qzInstalado = possui;

      if(possui)
        this.carregueImpressoras()

    })
  }

  alterouTipoDeImpressao() {
    if(this.empresa.configImpressao.imprimirTXT) {
      if(!this.empresa.configImpressao.impressoras)
        this.empresa.configImpressao.impressoras = []

      if(!this.empresa.configImpressao.impressoras.find( impressora => !impressora.removida))
        this.empresa.configImpressao.impressoras.push({})

      this.carregueImpressoras()
    }
  }

  carregueImpressoras(){
    if(this.impressoras.length === 0){
      this.impressao.listeImpressoras().then((resultado: any) => {
        if(resultado.sucesso)
          this.impressoras = resultado.data;
        else
          alert('Houve um erro ao carregar a lista de impressoras: ' + resultado.erro)
      })
    }
  }

  onSubmitConfigImpressao() {
    this.mensagemSucesso = '';
    delete this.erro;
    if( !this.frmConfigImpressao.valid || this.salvando)   return;

    this.salvando = true;
    this.empresaService.salveConfigImpressao(this.empresa).then( (configImpressao) => {
      this.empresa.configImpressao = configImpressao;
      if(!this.recarregar){
        this.salvando = false;
        this.mensagemSucesso = "As configurações de impressão foram atualizadas";
      } else {
        location.reload();
      }
    }).catch( (erro) => {
      this.salvando = false;
      this.erro = erro;
    });
  }

  adicionarNovaImpressa() {
      this.empresa.configImpressao.impressoras.push({ novo: true })
  }


  alterouLeiaute() {
    if(this.empresa.configImpressao.layoutPedido !== 'Ambos'){
      this.layouts = [this.empresa.configImpressao.layoutPedido]

      if(this.empresa.configImpressao.impressoras)
        this.empresa.configImpressao.impressoras.forEach( (impressora: any) => {
          if(impressora.layout !== this.empresa.configImpressao.layoutPedido )
            delete impressora.layout
        })
    } else {
      this.layouts  = ['PedidoCompleto', 'PedidoResumido', 'UmPorProduto'];
    }
  }

  excluirImpressora(impressora){
    if(impressora.id){
      impressora.removida = true;
    } else {
      this.empresa.configImpressao.impressoras.splice(  this.empresa.configImpressao.impressoras.indexOf(impressora), 1)
    }
  }

  marqueRecarregar() {
    this.recarregar = true;
  }
}
