import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RedesService extends ServerService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public liste(): Promise<any> {
    return this.obtenha('/rede/liste', {});
  }

  public redesEmpresa(): Promise<any> {
    return this.obtenha('/rede/redes-empresa', {});
  }
}
