import { Component, OnInit } from '@angular/core';
import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { FieldMeuCampo } from '../FieldMeuCampo';
import Teste from 'blockly/msg/pt';


@Component({
  templateUrl: './editor-template-notificacoes.component.html',
  styleUrls: ['./editor-template-notificacoes.component.scss']
})
export class EditorTemplateNotificacoesComponent implements OnInit {
  workspace: Blockly.WorkspaceSvg;
  javascript: any;

  constructor() { }

  ngOnInit(): void {
    Blockly.setLocale(Teste);
    console.log(Blockly.Blocks);
    this.workspace = Blockly.inject('blocklyDiv', {
      toolbox: document.getElementById('toolbox')
    });

    Blockly.Blocks['whatsapp_template'] = {
      init: function () {
        this.appendDummyInput()
          .appendField('Mensagem')
          .appendField(new FieldMeuCampo('Valor padrão'), 'Mensagem')
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(120);
        this.setTooltip("Ao ser chamado o texto entrará na mensagem do Whatsapp do cliente");
        this.setHelpUrl('');
        this.setInputsInline(true);
      }
    };

    Blockly.JavaScript['whatsapp_template'] = function (block) {
      var text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC);
      var code = 'console.log(' + text + ');\n';
      return code;
    };

  }

  runCode() {
    console.log('bloco: ', javascriptGenerator, 'workspace: ', this.workspace);
    const code = javascriptGenerator.workspaceToCode(this.workspace);
    eval(code);
  }
}
