import { Injectable } from '@angular/core';
import { ServerService } from './ServerService';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TaxasDeEntregaCalculadasService extends ServerService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  liste(inicio: number, total: number) {
    return this.obtenha(`/taxasDeEntregaCalculadas/liste?i=${inicio}&t=${total}`, {});
  }
}
