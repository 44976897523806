<div class="page-title-right">
  <ol class="breadcrumb m-0">
    <li class="breadcrumb-item active">Relatórios</li>
  </ol>
</div>

<ng-template #templateNovo>
  <div class="mt-2 mb-2">
    <p>Clientes que compraram pela primeira vez com você neste mês.</p>
  </div>
</ng-template>

<ng-template #templateRegular>
  <div class="mt-2 mb-2">
    <p>Clientes que compraram em meses anteriores, mas ainda não compraram o suficiente entre {{dataInicioVendas}} e {{dataFimVendas}}
      para serem <strong>recorrentes.</strong></p>
  </div>
</ng-template>

<ng-template #templateRecorrente>
  <div class="mt-2 mb-2">
    <p>Clientes que compraram de você pelo menos <strong>{{empresa.qtdeVendasRecorrente}} vezes</strong> no período.</p>
  </div>
</ng-template>

<ng-template #templatePerdido>
  <div class="mt-2 mb-2" style="color: #fff;">
    <p>Em <strong>{{empresa.qtdeDiasPerdido}} dias</strong>, o seu cliente que não compra de você passa a ser considerado <strong>Perdido</strong>.</p>
  </div>
</ng-template>

<ng-template #templateEmRisco>
  <div class="mt-2 mb-2" style="color: #000;">
    <p>Em <strong>{{empresa.qtdeDiasEmRisco}} dias</strong>, o seu cliente que não compra de você passa a ser considerado <strong>Em Risco</strong>.</p>
  </div>
</ng-template>

<ng-template #templateVIP>
  <div class="mt-2 mb-2" style="color: #fff;">
    <p>Os clientes que compram pelo menos <strong>{{empresa.qtdeVendasVIP}} vezes </strong> e que tenham ticket médio superior a <strong>{{empresa.ticketMedioVIP | currency: 'BRL'}}</strong> são considerados <strong>VIP</strong>.</p>
  </div>
</ng-template>

<div>
  <h4 class="page-title text-left">Dashboard da Empresa</h4>

  <div class="row filtro">
    <div class="col-12 col-sm-3" *ngIf="redes.length">
      <div class="form-group mb-3  "   >
        <label >Rede</label>
        <kendo-combobox  name="rede" [(ngModel)]="filtro.rede" [data]="redes"
                         placeholder="Filtre pela rede" class="form-control" [textField]="'descricao'"
                         (ngModelChange)="carregueResumoVendas()">
        </kendo-combobox>


      </div>
    </div>
  </div>

  <div class="row" *ngIf="resumo.total > 0">
    <div class="col">
      <div class="card">
        <div class="card-body" style="padding-top: 0.5rem;">
          <div class="row mb-2" style="cursor: pointer;" (click)="expanda()">
            <div class="col-12">
              <h4 style="padding-top: 0px;">
                Classificação dos Clientes
              </h4>
              <i class="mdi mdi-plus" *ngIf="!expandiuConfiguracoes" style="cursor: pointer;font-size: 18px;position: absolute;right: 0px;top: 0px;color: #999;padding: 10px;"></i>
              <i class="mdi mdi-minus" *ngIf="expandiuConfiguracoes" style="cursor: pointer;font-size: 18px;position: absolute;right: 0px;top: 0px;color: #999;padding: 10px;"></i>
            </div>
          </div>
          <div class="row mb-3" *ngIf="expandiuConfiguracoes">
            <div class="col card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <p class="card-subtitle mb-2">Tempo do período de vendas</p>
                    <div>
                      <kendo-numerictextbox
                        [value]="empresa.qtdeDiasPeriodo" [(ngModel)]="empresa.qtdeDiasPeriodo" [format]="'##### dias'"
                        [min]="0" [max]="366" class="form-control" (afterValueChanged)="mudouValorCompararMeses()">
                      </kendo-numerictextbox>
                    </div>
                  </div>
                  <div class="col espaco_mobile">
                    <p class="card-subtitle mb-2">Data que as vendas serão consideradas para <strong>classificar os clientes</strong></p>
                    <div>
                      <h3 style="margin-top: 15px;">{{dataInicioVendas}} <span class="text-muted" style="font-size: 16px;">a</span> {{dataFimVendas}}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md">
              <h4 kendoTooltip [showAfter]="200" [tooltipTemplate]="templateNovo"  tooltipClass="tooltip-novo" title=""
                  class="card-title cores_novo titulo" [style.backgroundColor]="obtenhaCor({rotulo: 'Novo'})">Novo</h4>
              <div class="expandido" *ngIf="expandiuConfiguracoes">
                <p class="card-subtitle mb-2">Clientes que compraram pela primeira vez com você neste mês.</p>
                <div>
                  <kendo-numerictextbox style="opacity: 0;"
                    [value]="empresa.qtdeDiasEmRisco" format="n0" [min]="0" [max]="1000" class="form-control" (afterValueChanged)="mudouValorCompararMeses()">
                  </kendo-numerictextbox>
                </div>
              </div>
            </div>
            <div class="col-12 col-md">
              <h4 kendoTooltip [showAfter]="200" [tooltipTemplate]="templateRegular"  tooltipClass="tooltip-regular" title=""
                  class="card-title cores_regular titulo" [style.backgroundColor]="obtenhaCor({rotulo: 'Regular'})" style="padding: 5px;color: #fff;" (click)="expanda()">Regular</h4>
              <div class="expandido" *ngIf="expandiuConfiguracoes">
                <p class="card-subtitle mb-2">Clientes que compraram em meses anteriores, mas ainda não compraram o suficiente
                  para serem <strong>recorrentes.</strong></p>
                <div style="height: 15px;">

                </div>
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="">
                <div class="">
                  <h4 kendoTooltip [showAfter]="200" [tooltipTemplate]="templateRecorrente"  tooltipClass="tooltip-recorrente" title=""
                      class="card-title cores_recorrente titulo" [style.backgroundColor]="obtenhaCor({rotulo: 'Recorrente'})" style="padding: 5px;color: #fff;" (click)="expanda()">Recorrente</h4>
                  <div class="expandido" *ngIf="expandiuConfiguracoes">
                    <p class="card-subtitle mb-2">Clientes que compraram de você pelo menos <strong>{{empresa.qtdeVisitasRecorrente}} vezes</strong> entre {{dataInicioVendas}} e {{dataFimVendas}}.</p>
                    <div>
                      <kendo-numerictextbox
                        [value]="empresa.qtdeVisitasRecorrente" [(ngModel)]="empresa.qtdeVisitasRecorrente" format="n0" [min]="0" [max]="360" (afterValueChanged)="mudouValorCompararMeses()">
                      </kendo-numerictextbox> visitas
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md">
              <h4 kendoTooltip [showAfter]="200"  [tooltipTemplate]="templateEmRisco"  tooltipClass="tooltip-em-risco" title=""
                  class="card-title cores_em_risco titulo" [style.backgroundColor]="obtenhaCor({rotulo: 'Em Risco'})" style="padding: 5px;" (click)="expanda()">Em Risco</h4>
              <div class="expandido" *ngIf="expandiuConfiguracoes">
                <p class="card-subtitle mb-2">Em <strong>{{empresa.qtdeDiasEmRisco}} dias</strong>, o seu cliente que não compra de você passa a ser considerado <strong [style.color]="obtenhaCor({rotulo: 'Em Risco'})">Em Risco</strong>.</p>
                <div>
                  <kendo-numerictextbox
                    [value]="empresa.qtdeDiasEmRisco" [(ngModel)]="empresa.qtdeDiasEmRisco" format="n0" [min]="0" [max]="360" (afterValueChanged)="mudouValorCompararMeses()">
                  </kendo-numerictextbox>
                  &nbsp;<span>dias</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md">
              <h4 kendoTooltip [showAfter]="200" [tooltipTemplate]="templatePerdido"  tooltipClass="tooltip-perdido" title=""
                  class="card-title cores_perdido titulo" [style.backgroundColor]="obtenhaCor({rotulo: 'Perdido'})" style="padding: 5px;color: #fff;" (click)="expanda()">Perdido</h4>
              <div class="expandido" *ngIf="expandiuConfiguracoes">
                <p class="card-subtitle mb-2">Em <strong>{{empresa.qtdeDiasPerdido}} dias</strong>, o seu cliente que não compra de você passa a ser considerado <strong [style.color]="obtenhaCor({rotulo: 'Perdido'})">Perdido</strong>.</p>
                <div>
                  <kendo-numerictextbox
                    [value]="empresa.qtdeDiasPerdido" [(ngModel)]="empresa.qtdeDiasPerdido" format="n0" [min]="0" [max]="360" (afterValueChanged)="mudouValorCompararMeses()">
                  </kendo-numerictextbox>
                  &nbsp;<span>dias</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md">
              <div>
                <h4 kendoTooltip [showAfter]="200" [tooltipTemplate]="templateVIP"  tooltipClass="tooltip-vip" title=""
                    class="card-title cores_vip titulo" [style.backgroundColor]="obtenhaCor({rotulo: 'VIP'})" style="padding: 5px;color: #fff;" (click)="expanda()">
                  VIP
                </h4>
                <div class="expandido" *ngIf="expandiuConfiguracoes">
                  <p class="card-subtitle mb-2">Os clientes que compraram, entre {{dataInicioVendas}} e {{dataFimVendas}}, pelo menos <strong>{{empresa.qtdeComprasVIP}} vezes </strong>
                    de você e que tenham ticket médio superior a <strong>{{empresa.ticketMedioVIP | currency: 'BRL'}}</strong> são considerados <strong [style.color]="obtenhaCor({rotulo: 'VIP'})">VIP</strong>.</p>
                    <div>
                      <p>
                        <span>Qtde. Compras: </span>
                      <kendo-numerictextbox
                        [value]="empresa.qtdeComprasVIP" [(ngModel)]="empresa.qtdeComprasVIP" format="n0" [min]="0" [max]="360" (afterValueChanged)="mudouValorCompararMeses()">
                      </kendo-numerictextbox>
                      </p>
                      <p>
                        Ticket Médio:
                        <kendo-numerictextbox
                          [value]="empresa.ticketMedioVIP" [(ngModel)]="empresa.ticketMedioVIP" [format]="'n2'" [min]="0" [max]="1000" (afterValueChanged)="mudouValorCompararMeses()">
                        </kendo-numerictextbox>
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        </div>
      </div>
</div>

<kendo-tabstrip class="nav-bordered" (tabSelect)="selecionouTab($event)">
  <kendo-tabstrip-tab [title]="'Raio-X dos Clientes'" [selected]="true"  >
    <ng-template kendoTabContent>
      <div class="row" *ngIf="resumo.total > 0">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-8">
                  <h4 style="padding-top: 0px;">
                    Raio-X dos Clientes Mês de {{mesAtual}}
                  </h4>
                </div>
                <div class="col-12 col-md-4">
                  <kendo-datepicker #dtMesAtual style="border: none;" class="form-control"
                                    [bottomView]="'year'"
                                    [topView]="'year'"
                                    [format]="'MMMM yyyy'"
                                    [activeView]="'year'"
                                    [max]="hoje"
                                    (valueChange)="mudouValor(dtMesAtual, $event)"
                                    [(value)]="value">
                  </kendo-datepicker>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12 col-lg-6" style="text-align: center;">
                  <p class="header-title mb-1 titulo_grafico">Quantidade de Clientes por Categoria</p>

                  <div class="grafico">
                    <i class="k-i-loading k-icon mt-2 fa-4x" *ngIf="carregando"></i>

                    <app-tela-grafico-fidelidade [relatorios]="this" #telaGraficoPorQtde [campo]="'qtdeClientes'"
                                                 [dtInicio]="'2019-11-01'" [dtFim]="'2019-11-30'"></app-tela-grafico-fidelidade>
                  </div>
                </div>
                <hr>
                <div class="col-12 col-lg-6" style="text-align: center;">
                  <p class="header-title mb-1 titulo_grafico">Receita por cada Categoria</p>

                  <div class="grafico">
                    <i class="k-i-loading k-icon mt-2 fa-4x" *ngIf="carregando"></i>
                    <app-tela-grafico-fidelidade [relatorios]="this" #telaGraficoPorValor
                                                 [dtInicio]="'2019-11-01'" [dtInicio]="'2019-11-01'"  [dtFim]="'2019-11-30'"></app-tela-grafico-fidelidade>
                  </div>
                </div>
              </div>

              <h3>{{categoriaSelecionada}}</h3>
              <div class="row resumo" *ngIf="categoriaSelecionada">
                <div class="col-6 col-sm">
                  <div class="  card-box com-borda" [style.border-color]="obtenhaCor({'rotulo': categoriaSelecionada})">
                    <div>
                      <span>{{contatos.length}}</span>
                    </div>
                    <label>Total de contatos {{categoriaSelecionada}}</label>
                  </div>
                </div>
                <div class="col-6 col-sm">
                  <div class="  card-box">
                    <div>
                      <span>  {{grupoSelecionado.totalGasto | currency: 'BRL'}}</span>
                    </div>
                    <label class="d-none d-sm-block">Total de Vendas </label>
                  </div>
                </div>
                <div class="col-6 col-sm">
                  <div class="  card-box">
                    <div>
                      <span>  {{grupoSelecionado.ticketMedio | currency: 'BRL'}}</span>
                    </div>
                    <label class="d-none d-sm-block">Ticket Médio</label>
                  </div>
                </div>
                <div class="col-6 col-sm">
                  <div class="  card-box">
                    <div>
                      <span>  {{grupoSelecionado.qtdeVisitas}}</span>
                    </div>
                    <label class="d-none d-sm-block">Qtde. Vendas </label>
                    <label class="d-sm-none">Contatos novos</label>
                  </div>
                </div>
                <div class="col" kendoTooltip title="Tempo médio em dias para um cliente retornar ao seu estabelecimento.">
                  <div class=" card-box">
                    <div>
                      <span>{{grupoSelecionado.tempoRetorno.toFixed(2).replace(".", ",")}}</span>
                      <span class="text-muted" style="font-size: 13px;"> dias</span>
                    </div>
                    <label class="d-none d-sm-block">Ciclo de Recorrência</label>
                    <label class="d-sm-none">Ciclo de Recorrência  </label>
                  </div>
                </div>
              </div>

              <div id="classificacao" class="row">
                <div class="col">
                  <app-tela-contatos-por-categoria #telaContatosPorCategoriaComponent [rede]="rede"
                                                   [categoria]="categoriaSelecionada"></app-tela-contatos-por-categoria>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="'Comparar Meses'" id="comparar">
    <ng-template kendoTabContent>
      <div class="row" *ngIf="resumo.total > 0">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 style="padding-top: 0px;" class="mb-2">
                Comparar Meses
              </h4>
              <div class="row">
                <div class="col">
                  <kendo-datepicker style="border: none;" class="form-control"
                                    [bottomView]="'year'"
                                    [topView]="'year'"
                                    [format]="'MMMM yyyy'"
                                    [activeView]="'year'"
                                    [max]="hoje"
                                    (valueChange)="mudouValorCompararMes1($event)"
                                    [(value)]="valorMes1">
                  </kendo-datepicker>
                </div>
                <div class="col">
                  <kendo-datepicker style="border: none;" class="form-control"
                                    [bottomView]="'year'"
                                    [topView]="'year'"
                                    [format]="'MMMM yyyy'"
                                    [activeView]="'year'"
                                    [max]="hoje"
                                    (valueChange)="mudouValorCompararMes2($event)"
                                    [(value)]="valorMes2">
                  </kendo-datepicker>
                </div>
              </div>

              <div class="mt-3">
                <div style="text-align: center;">
                  <h3 class="mb-2 mt-2 titulo_grafico">Receita por cada Categoria</h3>

                  <div class="grafico">
                    <app-tela-grafico-comparacao #telaGraficoComparacaoPorValor [tipoGrafico]="ehCelular ? 'bar' : 'column'"></app-tela-grafico-comparacao>
                  </div>
                </div>
                <hr>
              </div>

              <div class="row">
                <div class="col mt-2" style="text-align: center;">
                  <h3 class="mb-2 mt-2 titulo_grafico">Quantidade de Clientes por Categoria</h3>

                  <div class="grafico">
                    <app-tela-grafico-comparacao #telaGraficoComparacaoPorQtde [tipoGrafico]="ehCelular ? 'bar' : 'column'" [campo]="'qtdeClientes'"></app-tela-grafico-comparacao>
                  </div>
                </div>
              </div>

              <div class="grafico mt-3">
                <div style="text-align: center;">
                  <h3 class="mb-2 mt-2 titulo_grafico">Receita por cada Categoria</h3>

                  <div>
                    <app-tela-grafico-comparacao #telaGraficoComparacaoPorValorDonut></app-tela-grafico-comparacao>
                  </div>
                </div>
              </div>

              <div class="grafico mt-3">
                <div style="text-align: center;">
                  <h3 class="mb-2 mt-2 titulo_grafico">Receita por cada Categoria</h3>

                  <div>
                    <app-tela-grafico-comparacao #telaGraficoComparacaoPorQtdeDonut [campo]="'qtdeClientes'"></app-tela-grafico-comparacao>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="resumo.total > 0">
        <div class="col">
          <h3 class="mt-3 titulo_grafico">Receita de Fidelidade</h3>

          <div class="card">
            <div class="card-body grafico">
              <div class="controle-grafico">
                <div class="btn-group btn-group-toggle " data-toggle="buttons">
                  <button class="btn btn-light " [ngClass]="{'active':tipoGrafico === 'dia'}"  (click)="exibaGraficoDia()">Dia</button>
                  <button class="btn btn-light"  [ngClass]="{'active':tipoGrafico === 'mes'}"  (click)="exibaGraficoMes()">Mês</button>
                  <button class="btn btn-light"  [ngClass]="{'active':tipoGrafico === 'ano'}"  (click)="exibaGraficoAno()">Ano</button>
                </div>
              </div>

              <div class="mt-2"></div>

              <kendo-chart  [categoryAxis]="{ categories: seriesLabels}" >
                <kendo-chart-title align="left">
                </kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="seriesLabels">

                    <kendo-chart-category-axis-item-labels [rotation]="90">
                    </kendo-chart-category-axis-item-labels>

                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>

                <kendo-chart-axis-defaults>
                  <kendo-chart-axis-defaults-labels format="c0" [rotation]="0">
                  </kendo-chart-axis-defaults-labels>
                </kendo-chart-axis-defaults>

                <kendo-chart-area  [margin]="10" > </kendo-chart-area>
                <kendo-chart-series>
                  <kendo-chart-series-item [data]="series | async" [type]="'line'" style="smooth"
                                           [overlay]="{gradient: 'roundedBevel'}" color="#48d746">
                    <kendo-chart-series-item-tooltip>
                      <ng-template let-value="value" let-category="category">
                        <p style="text-align: center;padding-bottom: 5px;width: 200px;">
                          <span style="font-size: 15px;font-weight: bold;">{{category}}</span>
                        </p>
                        <h4 style="text-align: center;color: #e3fcdc;font-weight: bold;">{{value | currency: 'BRL'}}</h4>
                      </ng-template>
                    </kendo-chart-series-item-tooltip>
                  </kendo-chart-series-item>

                  <kendo-chart-series-item [data]="series | async" [type]="'column'"
                                           [overlay]="{gradient: 'roundedBevel'}">
                    <kendo-chart-series-item-tooltip>
                      <ng-template let-value="value" let-category="category">
                        <p style="text-align: center;padding-bottom: 5px;width: 200px;">
                          <span style="font-size: 15px;font-weight: bold;">Receita {{category}}</span><br>
                        </p>
                        <h3 style="text-align: center;color: #f3f3f3;font-weight: bold;">{{value | currency: 'BRL'}}</h3>
                      </ng-template>
                    </kendo-chart-series-item-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>

            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
