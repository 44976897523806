import { Component, OnInit } from '@angular/core';
import {EmpresasService} from "../../../superadmin/services/empresas.service";
import {CampanhaRedeService} from "../../../services/campanha-rede.service";
import {State} from "@progress/kendo-data-query";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas-campanha-rede.component.html',
  styleUrls: ['./lista-empresas-campanha-rede.component.scss']
})
export class ListaEmpresasCampanhaRedeComponent implements OnInit {
  empresasResultSet: any ;
  tamanhoPagina = 20;
  loading = false;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 20
  };
  filtro: any = {
    nome: ''
  };
  page = 0;
  campanha: any;
  apenasPendentes = true;

  constructor(private activatedRoute: ActivatedRoute, private empresasService: EmpresasService,
              private campanhaRedeService: CampanhaRedeService, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let id = +params['idc']; // (+) converts string 'id' to a number

      if(id){
        this.campanhaRedeService.obtenhaCampanha(id).then((resposta: any) => {
          this.campanha = resposta;
          this.carregueEmpresas();
        });
      }
    });

  }

  public onPageChange(e: PageChangeEvent): void {
    this.gridState.skip = e.skip;
    this.carregueEmpresas();
  }

  private carregueEmpresas() {
    this.empresasResultSet = {
      data: [],
      total: 0
    };

    this.campanhaRedeService.empresasQueAceitaram(this.campanha, this.apenasPendentes, this.gridState.skip,
      this.gridState.take, this.filtro.nome).then( (resposta) => {
      this.empresasResultSet = {
        data: resposta.empresas,
        total: resposta.total
      };

      this.empresasService.verifiqueEmpresasOnline().then( (respLojas) => {
        for( let empresa of resposta.empresas ) {
          empresa.online = false;
        }

        for( let assinante of respLojas.assinantes ) {
          const empresa = resposta.empresas.find( (objEmpresa) => {
            return objEmpresa.id === assinante.empresa.id;
          });

          if( empresa ) {
            empresa.online = true;
          }
        }
      });
    });
  }

  onFilter(event) {
    this.carregueEmpresas();
  }

  atualize() {
    this.carregueEmpresas();
  }

  voltar() {
    this.router.navigate(['/admin-rede/marketing']).then( () => {
    });
  }
}
