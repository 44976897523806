import { Component, OnInit } from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {PedidosService} from "../../services/pedidos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-modal-vincular-garcom',
  templateUrl: './modal-vincular-garcom.component.html',
  styleUrls: ['./modal-vincular-garcom.component.scss']
})
export class ModalVincularGarcomComponent extends ModalKendo implements OnInit {
  empresa: any = {}
  mesa: any = {}
  garcons = [];
  comanda: any   = {};
  erro: string;
  vinculando: boolean;
  jaTemGarcom: boolean;
  constructor(  private  pedidosService: PedidosService, private empresasService: EmpresasService) {
    super()
  }

  ngOnInit(): void {
    if(this.informarComanda())
     this.comanda.codigoPdv =  this.mesa ? this.mesa.codigoPdv : null;

    this.empresasService.listeUsuarios(this.empresa).then( (usuarios: any) => {
       if(!usuarios.length)
         this.erro = 'Nenhum garçom cadastrado'
       else  this.garcons = usuarios;

    }).catch(erro => {
      this.erro = erro;
    })
  }

  setGarcom(garcom){
    this.comanda.garcom = garcom;
    this.jaTemGarcom =  garcom.id != null;
  }

  onSubmit(frm: any) {
    delete  this.erro;

    if(!frm.valid){
      this.erro = 'Verifique os campos obrigatórios'
      return;
    }

    if(this.vinculando) return;

    if(this.comanda.id){
      this.vinculando = true;

      this.pedidosService.vinculeGarcomOuCodigoComanda(this.comanda).then( (comanda) => {
        this.vinculando = false;
        this.fecheModal(  comanda );
      }).catch( erro => {
        this.vinculando = false
        this.erro = erro;
      })
    } else {
      this.fecheModal(this.comanda )
    }
  }

  informarComanda() {
    if(!this.empresa || !this.empresa.integracaoDelivery) return false;

    let config: any = this.empresa.integracaoDelivery.configuracoesEspecificas;

    return config ? config.integrarComComandas : false;
  }
}
