import { Component, OnInit } from '@angular/core';
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {TemplatesService} from "../../services/templates.service";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {State} from "@progress/kendo-data-query";
import {DialogRef} from "@progress/kendo-angular-dialog";

@Component({
  selector: 'app-empresas-usando-template',
  templateUrl: './empresas-usando-template.component.html',
  styleUrls: ['./empresas-usando-template.component.scss']
})
export class EmpresasUsandoTemplateComponent implements OnInit {
  templateDeMensagem: any = {};
  empresasResultSet: any;
  tamanhoPagina = 20;
  page = 0;
  loading = false;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 20
  };
  filtro: any = {};
  windowRef: DialogRef;
  private timerBusca: any;

  constructor( private empresasService: EmpresasService,
               private templatesService: TemplatesService) { }

  ngOnInit(): void {
    this.carregueEmpresas()
  }

  private carregueEmpresas() {
    this.empresasResultSet = {
      data: [],
      total: 0
    };

    this.templatesService.empresasQueUsam(this.templateDeMensagem, this.filtro.nome).then( (resposta) => {
      this.empresasResultSet = {
        data: resposta.empresas,
        total: resposta.total
      };

      this.empresasService.verifiqueEmpresasOnline().then( (respLojas) => {
        for( let empresa of resposta.empresas ) {
          empresa.online = false;
        }

        for( let assinante of respLojas.assinantes ) {
          const empresa = resposta.empresas.find( (objEmpresa) => {
            return objEmpresa.id === assinante.empresa.id;
          });

          if( empresa ) {
            empresa.online = true;
          }
        }
      });
    });
  }

  public onPageChange(e: PageChangeEvent): void {
    this.gridState.skip = e.skip;
    this.carregueEmpresas();
  }


  onFilter(event: any) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueEmpresas();
    }, 1000);
  }
}
