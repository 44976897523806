import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { map, tap } from 'rxjs/operators';
import { State, process, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {FormasDeEntregaService} from "../../services/formas-de-entrega.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'app-cad-alcances',
  templateUrl: './cad-alcances.component.html',
  styleUrls: ['./cad-alcances.component.scss']
})
export class CadAlcancesComponent implements OnInit {
  view: any;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 5
  };
  editedAlcance: any;
  msgErroAlcance: any = '';
  public sort: SortDescriptor[] = [
    {
        field: 'alcance',
        dir: 'asc'
    }
  ];
  carregando = false;
  modalDeletar: boolean;
  alcanceApagar: any;
  alcances: any;
  @ViewChild(GridComponent) grid: GridComponent;
  editedRowIndex: number;
  isNew: boolean;
  formGroup: UntypedFormGroup;

  @Output() atualizouAlcances = new EventEmitter();

  constructor(private formasDeEntregaService: FormasDeEntregaService, private constantsService: ConstantsService,
    private formBuilder: UntypedFormBuilder) {
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  ngOnInit(): void {
    this.carregueAlcances();
  }

  private carregueAlcances() {
    this.carregando = true;
    this.formasDeEntregaService.carregue().pipe(
      map( (resp: any) => resp.data),
      tap(alcances => {
        this.carregando = false;

        alcances.forEach((a) => a.ativo = !a.destivado)

        return alcances.sort((a, b) => (a.alcance > b.alcance) ? 1 : -1);
      }),
    ).subscribe( (objetos) => {
      this.alcances = objetos;
      this.view = process(objetos, this.gridState);
      this.atualizouAlcances.emit(objetos);
    });
  }

  public onPageChange(e: PageChangeEvent): void {
    this.view = process(this.alcances, this.gridState);
  }

  public onStateChange(state: State) {
    this.gridState = state;
  }

  private closeEditor(sender): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
        'alcance': [{value: dataItem.alcance, disabled: true}],
        'taxa': [dataItem.taxa, Validators.required]
    });
  }

  public addHandler({sender}) {
    //formInstance.reset();
    this.closeEditor(sender);

    sender.addRow({ ativo: true});
  }

  public removeHandler($event) {
    this.alcanceApagar = $event.dataItem;
    this.modalDeletar = true;
  }

  public cancelHandler({sender}) {
    this.msgErroAlcance = '';
    this.closeEditor(sender);
    this.carregueAlcances();
  }

  public editHandler({sender, rowIndex, dataItem}) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.editedAlcance = Object.assign({}, dataItem);

    sender.editRow(rowIndex);
  }

  salvarRaio({sender, rowIndex, dataItem, isNew}) {
    this.msgErroAlcance = '';
    dataItem.desativado = !dataItem.ativo;
    this.formasDeEntregaService.salveAlcance(dataItem).then( (resposta) => {
      this.closeEditor(sender);

      this.carregueAlcances();
    }).catch( (erro) => {
      this.msgErroAlcance = erro;
    });

    return false;
  }

  close(status: string) {
    if( status === 'sim') {
      this.msgErroAlcance = '';
      this.formasDeEntregaService.removaAlcance(this.alcanceApagar).then( () => {
        this.constantsService.recarregueEmpresa();

        this.carregueAlcances();

        this.alcanceApagar = null;
        this.modalDeletar = false;
      }).catch( () => {

      });
    } else {
      this.alcanceApagar = null;
      this.modalDeletar = false;
    }
  }
}
