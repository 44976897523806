<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()" style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fe-user "></i> Status do Pedido</h4>

<div class="row"  [hidden]="!pedido.id" >

  <div class="col-12">
    <info-contato [contato]="contato" [exibirUltimaVisita]="false" [empresa]="empresa"></info-contato>
  </div>
  <div class="col-12 mt-2">
    <h4>Mudar Status</h4>
    <div class="card contato">
      <div class="card-body">
        <ng-container *ngIf="pedido.id">
          <info-pedido [contato]="contato"  [pedido]="pedido" [permiteAlterarStatus]="false"></info-pedido>
        </ng-container>

        <hr />
        <mudar-status #mudarStatusComponent [pedido]="pedido"   [contato]="contato"   ></mudar-status>
      </div>
    </div>

  </div>

</div>

<div class="alert alert-danger alert-dismissible fade show" *ngIf="carregou && !pedido.id" role="alert">
  <p>Pedido não encontrado</p>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
