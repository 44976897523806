
export class TaxaCobranca{
  id: number;
  percentual: number;
  valor: number;
  ativa: boolean
  static calcule(taxa: any, valorTotalPedido: number){
    if(!taxa.ativa) return  0;

    if(taxa.percentual)
      return  Number(( (taxa.percentual / 100) * valorTotalPedido).toFixed(2))

    return taxa.valor;
  }
}
