import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {NgForm} from "@angular/forms";
import {EmpresasService} from "../services/empresas.service";
import {CatalogosService} from "../services/catalogos.service";
import {FotoService} from "../../services/foto.service";

@Component({
  selector: 'app-cad-categoria',
  templateUrl: './cad-categoria.component.html',
  styleUrls: ['./cad-categoria.component.scss']
})
export class CadCategoriaComponent implements OnInit {
  @ViewChild('frm', {static: true}) frm: NgForm;
  @Input()   catalogo: any = {};
  @Input()   atualizarNomeDestaque = false;
  @Output() finalizou = new  EventEmitter();
  mensagemSucesso: any;
  mensagemErro: any;
  categoria: any = {};
  impressoras: any = [];
  salvando: boolean;
  @Input()
  empresa: any;

  constructor( private empresasService: EmpresasService, private fotoService: FotoService,
               private catalogosService: CatalogosService) {}

  ngOnInit() {
  }

  setModoAtualizarNomeDestaque(valor: boolean){
    this.atualizarNomeDestaque = valor
  }

  setCategoria(categoria: any){
    this.categoria = categoria;
    this.impressoras = [];

    if(this.empresa && !this.atualizarNomeDestaque) {
      this.empresa.configImpressao.impressoras.forEach((impressora: any) => {
        let disponivel = this.categoria.impressoras ?
          this.categoria.impressoras.find(impressoraDisponivel => impressoraDisponivel.id === impressora.id) != null : false;

        let _impressora = Object.assign({}, impressora);

        _impressora.disponivel = disponivel || impressora.imprimirAutomatico;

        this.impressoras.push(_impressora)
      })
    }

  }

  salveImagemCategoria($event){
    $event.salvou();
  }

  onSubmit() {
    delete this.mensagemErro;
    if(this.frm.valid){
      delete this.mensagemErro;

      if(this.atualizarNomeDestaque) {
        this.empresa.nomeCategoriaDestaques = this.categoria.nome
        this.empresa.imagemCategoriaDestaque = this.categoria.imagem
        this.salvando = true;
        this.empresasService.atualizeNomeCategoriaDestaques(this.empresa).then((resposta) => {
          this.salvando = false;
          this.feche(this.categoria);
        })
      } else {
        this.categoria.impressoras = this.impressoras.filter( impressora =>  impressora.disponivel);

        this.salvando = true;

        this.catalogosService.salveCategoria( this.catalogo, this.categoria, !this.empresa ||
          !this.empresa.id).then(resposta => {
          this.salvando = false;
          if(!this.categoria.id){
            this.categoria.id = resposta.id;
            this.categoria.posicao = resposta.posicao;
            this.categoria.nivel = 1;
            this.feche(this.categoria)
          } else {
            this.feche(null)
          }


        }).catch( erro => {
          this.salvando = false;
          this.mensagemErro   = erro;
        })
      }
    }
  }

  feche(categoria: any) {
    this.finalizou.emit(categoria)
    this.setCategoria({})
  }


  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

}
