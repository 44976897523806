<ng-container *ngIf="saldo">
  <p class="mb-1">
    <kendo-progressbar [value]="saldo.qtde" [max]="empresa.qtdeDeMensagens" style="width: 100px;"></kendo-progressbar>
  </p>
  <p class="mt-0" *ngIf="saldo.qtde > 0">
    Restam {{saldo.qtde}} msgs
  </p>
  <p class="mt-0" *ngIf="saldo.qtde === 0">
    Sem saldo
  </p>
</ng-container>

