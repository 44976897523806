import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-controle-menu-whatsapp',
  templateUrl: './controle-menu-whatsapp.component.html',
  styleUrls: ['./controle-menu-whatsapp.component.scss']
})
export class ControleMenuWhatsappComponent implements OnInit {
  @Input() objeto: any = {
    menu: {
      opcoes: []
    },
    novaOpcao: {
      texto: '',
      mensagem: ''
    }
  };
  erroOpcao = '';
  msgOpcao = '';

  constructor() { }

  ngOnInit(): void {
  }

  adicionarOpcao($event: MouseEvent) {
    this.objeto.menu.opcoes.push({
      texto: '',
      mensagem: ''
    });
  }

  salvarOpcao($event: any) {
    this.erroOpcao = '';

    if (!this.objeto.novaOpcao.texto) {
      return;
    }

    //valida se não tem uma opção com o mesmo texto já
    const opcaoEncontrada = this.objeto.menu.opcoes.find((opcao: any) => opcao.texto === this.objeto.novaOpcao.texto);

    if (opcaoEncontrada) {
      this.erroOpcao = 'Já existe uma opção com esse texto';
      return;
    }

    this.msgOpcao = 'Não esqueça de salvar a notificação para salvar as opções de menu.';

    this.objeto.menu.opcoes.push(this.objeto.novaOpcao);
    this.objeto.novaOpcao = {
      texto: '',
      mensagem: ''
    };
  }

  ativouDesativouOMenu($event: any) {
    if (!this.objeto.menu) {
      this.objeto.menu = {
        textoBotao: '',
        opcoes: []
      }
    }
  }

}
