import {ElementRef, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CadProdutoComponent} from "../superadmin/cad-produto/cad-produto.component";
import {DialogModule, WindowModule} from "@progress/kendo-angular-dialog";
import {PopoverModule, TooltipModule} from "@progress/kendo-angular-tooltip";
import {DateInputsModule, DatePickerModule, TimePickerModule} from "@progress/kendo-angular-dateinputs";
import {FileSelectModule, UploadModule} from "@progress/kendo-angular-upload";
import {FormsModule} from "@angular/forms";
import {ExcelModule, GridModule} from "@progress/kendo-angular-grid";
import {InputsModule, MaskedTextBoxModule, NumericTextBoxModule} from "@progress/kendo-angular-inputs";
import {DropDownListModule} from "@progress/kendo-angular-dropdowns";
import {ImageCropperModule} from "ngx-image-cropper";
import {HttpClientJsonpModule} from "@angular/common/http";
import {ButtonModule, ChipModule, DropDownButtonModule} from "@progress/kendo-angular-buttons";
import {AutofocusDirective} from "../directives/autofocus.directive";
import {UploadImagemComponent} from "../superadmin/upload-imagem/upload-imagem.component";
import {CPFValidator} from "../directives/form-validadores.directive";
import {CadCategoriaComponent} from "../superadmin/cad-categoria/cad-categoria.component";
import {OrdenarCategoriasComponent} from "../superadmin/ordenar-categorias/ordenar-categorias.component";
import { SortableModule} from "@progress/kendo-angular-sortable";
import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {RemovaWheelDirective} from "../directives/remova-wheel.directive";
import {SelecionarNoFocoDirective} from "../directives/selecionar-no-foco.directive";
import {FormFormasEntregaComponent} from "../superadmin/form-formasdeentrega/form-formasdeentrega.component";
import {ValorMinimoValidator} from "../directives/valor-minimo.directive";
import {ValorMaximoValidator} from "../directives/valor-maximo.directive";
import {FotosComponent} from "../superadmin/fotos/fotos.component";
import {UploadCropImagemComponent} from "../superadmin/upload-crop-imagem/upload-crop-imagem.component";
import {CadAdicionalProdutoComponent} from "../superadmin/cad-adicional-produto/cad-adicional-produto.component";
import { CadHorariosfuncionamentoComponent } from './cad-horariosfuncionamento/cad-horariosfuncionamento.component';
import {SiteCampoAdicionalEdicaoComponent} from "./site-campo-adicional-edicao/site-campo-adicional-edicao.component";
import {FormCopiarAdicionalComponent} from "../superadmin/form-copiar-adicional/form-copiar-adicional.component";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {ImportadorIfoodComponent} from "../importador-food/importador-ifood.component";
import {ImportadorCsvComponent} from "../importador-csv/importador-csv.component";
import { UploadCardapioComponent } from './upload-cardapio/upload-cardapio.component';
import { ConfigImpressaoComponent } from './config-impressao/config-impressao.component';
import { TelaCuponsComponent } from './tela-cupons/tela-cupons.component';
import { CadCupomComponent } from './cad-cupom/cad-cupom.component';
import { TelaAssociarCupomContatosComponent } from './tela-associar-cupom-contatos/tela-associar-cupom-contatos.component';
import {ApplicationPipesModule} from "../pipes/application-pipes.module";
import {CadTemplatepizzaComponent} from "./cad-templatepizza/cad-templatepizza.component";
import {Produtos2Component} from "../superadmin/produtos2/produtos2.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {AdicionaisProdutoComponent} from "../superadmin/produtos2/adicionais-produto/adicionais-produto.component";
import {UploadMultiplasImagensComponent} from "../superadmin/upload-multiplas-imagens/upload-multiplas-imagens.component";
import {PagerModule} from "@progress/kendo-angular-pager";
import {ListaAdicionaisComponent} from "../superadmin/adicionais/lista-adicionais/lista-adicionais.component";
import { FormIntegracaodeliveryComponent } from './form-integracaodelivery/form-integracaodelivery.component';
import {TelaSincronizarPrecosComponent} from "./tela-sincronizar-precos/tela-sincronizar-precos.component";
import {ProgressBarModule} from "@progress/kendo-angular-progressbar";
import { CadPausasProgramadasComponent } from './cad-pausas-programadas/cad-pausas-programadas.component';
import { TelaPausasProgramadasComponent } from './tela-pausas-programadas/tela-pausas-programadas.component';
import {TelaSincronizarEstoqueComponent} from "./tela-sincronizar-estoque/tela-sincronizar-estoque.component";
import {HistoricoProdutoComponent} from "../historico-produto/historico-produto.component";
import {ExibirCampanhaComponent} from "../marketing/exibir-campanha/exibir-campanha.component";
import {UsuariosEmpresaComponent} from "../superadmin/usuarios-empresa/usuarios-empresa.component";
import {CadUsuarioComponent} from "../superadmin/cad-usuario/cad-usuario.component";
import {TelefonePipe} from "../telefone.pipe";
import {FormRestringirCidadesComponent} from "../superadmin/form-formasdeentrega/form-restringir-cidades/form-restringir-cidades.component";
import {NovaRestricaoCidadeComponent} from "../superadmin/form-formasdeentrega/nova-restricao-cidade/nova-restricao-cidade.component";
import {ExibirNotificacaoappComponent} from "../marketing/app/exibir-notificacaoapp/exibir-notificacaoapp.component";
import { TelaImportarProdutosERPComponent } from './tela-importarerp/tela-importar-produtos-erp.component';
import { TelaSincronizarIfoodComponent } from './tela-sincronizar-ifood/tela-sincronizar-ifood.component';
import { PainelImportacaoProdutosComponent } from './painel-importacao-produtos/painel-importacao-produtos.component';
import { CadAlcancesComponent } from '../tela-areas-de-entrega/cad-alcances/cad-alcances.component';
import { TelaSincronizarChinaComponent } from './tela-sincronizar-china/tela-sincronizar-china.component';
import {FinanceiroDeliveryComponent} from "../fidelidade/financeiro-delivery/financeiro-delivery.component";
import {GridPedidosComponent} from "../pedidos/grid-pedidos/grid-pedidos.component";
import {FinanceiroMesasComponent} from "../fidelidade/financeiro-mesas/financeiro-mesas.component";
import {ExpansionPanelModule, LayoutModule, PanelBarModule, TabStripModule} from "@progress/kendo-angular-layout";
import {PedidoAcoesComponent} from "../fidelidade/pedido-acoes/pedido-acoes.component";
import {PedidoStatusComponent} from "../fidelidade/pedido-status/pedido-status.component";
import {RouterModule} from "@angular/router";
import { CadPromocaoComponent } from './cad-promocao/cad-promocao.component';
import { TelaPromocoesComponent } from './tela-promocoes/tela-promocoes.component';
import {PedidoDetalhesComponent} from "../fidelidade/pedido-detalhes/pedido-detalhes.component";
import {CadEnderecoContatoComponent} from "../fidelidade/cad-endereco-contato/cad-endereco-contato.component";
import {FormEnderecoContatoComponent} from "../fidelidade/form-endereco-contato/form-endereco-contato.component";
import {ModalPedidoStatusComponent} from "../fidelidade/modal-pedido-status/modal-pedido-status.component";
import {MudarStatusComponent} from "../componentes/mudar-status/mudar-status.component";
import {InfoPedidoComponent} from "../componentes/info-pedido/info-pedido.component";
import {InfoContatoComponent} from "../componentes/info-contato/info-contato.component";
import {PedidoRibbonComponent} from "../fidelidade/pedido-ribbon/pedido-ribbon.component";
import {NgxMaskModule} from "ngx-mask";
import {ContatoStatusComponent} from "../componentes/contato-status/contato-status.component";
import {ResumoEnderecoComponent} from "../fidelidade/resumo-endereco/resumo-endereco.component";
import {FooterComponent} from "../componentes/footer/footer.component";
import {ComandosImprimirComanda} from "../pedidos/ComandosImprimirComanda";
import {RelatorioRecebimentosComponent} from "../componentes/relatorio-recebimentos/relatorio-recebimentos.component";
import {TabelaRecebimentoComponent} from "../componentes/relatorio-recebimentos/tabela-recebimento/tabela-recebimento.component";
import {FormIntegracaofidelidadeComponent} from "./form-integracaofidelidade/form-integracaofidelidade.component";
import { TelaUsuarioPermissoesComponent } from './tela-usuario-permissoes/tela-usuario-permissoes.component';
import { TelaGerenciarPapeisComponent } from './tela-gerenciar-papeis/tela-gerenciar-papeis.component';
import { GridCuponsComponent } from './tela-cupons/grid-cupons/grid-cupons.component';
import {ProdutoPrecoComponent} from "../superadmin/produtos2/produto-preco/produto-preco.component";
import {TelaAreasDeEntregaComponent} from "../tela-areas-de-entrega/tela-areas-de-entrega.component";
import {FormTemplateComponent} from "./form-template/form-template.component";
import {FormUploadKmlComponent} from "../tela-areas-de-entrega/form-upload-kml/form-upload-kml.component";
import {
  FormCadastroOpcaoadicionalComponent
} from "../superadmin/cad-adicional-produto/form-cadastro-opcaoadicional/form-cadastro-opcaoadicional.component";
import {TelaUploadFaviconComponent} from "../tela-upload-favicon/tela-upload-favicon.component";
import { GridChavesIntegracaoComponent } from './grid-chaves-integracao/grid-chaves-integracao.component';
import {BrindesComponent} from "./fidelidade/brindes/brindes.component";
import {CadBrindeComponent} from "./fidelidade/cad-brinde/cad-brinde.component";
import {PlanosComponent} from "./fidelidade/planos.component";
import {CadPlanosComponent} from "./fidelidade/cad-planos/cad-planos.component";
import {AtividadesComponent} from "./fidelidade/atividades/atividades.component";
import {CadAtividadeComponent} from "./fidelidade/cad-atividade/cad-atividade.component";
import { CadIntegracaoOpendeliveryComponent } from './cad-integracao-opendelivery/cad-integracao-opendelivery.component';
import { ViewDisponibilidadeComponent } from './view-disponibilidade/view-disponibilidade.component';
import { CadDisponibilidadeComponent } from './cad-disponibilidade/cad-disponibilidade.component';
import { TelaSincronizarCatalogomodeloComponent } from './tela-sincronizar-catalogomodelo/tela-sincronizar-catalogomodelo.component';
import { CadIntegracaoOpendeliveryLogisticaComponent } from './cad-integracao-opendelivery-logistica/cad-integracao-opendelivery-logistica.component';
import {AcoesDeliveryComponent} from "../fidelidade/pedido-acoes-delivery/acoes-delivery.component";
import { PedidoProximasAcoesComponent } from '../fidelidade/pedido-proximas-acoes/pedido-proximas-acoes.component';
import { FormIntegracaopedidosComponent } from './form-integracaopedidos/form-integracaopedidos.component';
import { CadIntegracaoUberdirectComponent } from './cad-integracao-uberdirect/cad-integracao-uberdirect.component';
import { FormIntegracaoFoodydeliveryComponent } from './form-integracao-foodydelivery/form-integracao-foodydelivery.component';
import { FormIntegracaoIfoodComponent } from './form-integracao-ifood/form-integracao-ifood.component';
import { BandeiraLogoComponent } from './bandeira-logo/bandeira-logo.component';
import {ControleKanbamComponent} from "../controle-kanbam/controle-kanbam.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatCardModule} from "@angular/material/card";
import {LabelModule} from "@progress/kendo-angular-label";
import {
  RazaoCancelamentoIfoodComponent
} from "../componentes/razao-cancelamento-ifood/razao-cancelamento-ifood.component";
import {AcaoPedidoDisputaComponent} from "../fidelidade/acao-pedido-disputa/acao-pedido-disputa.component";
import {ImageIfoodComponent} from "../fidelidade/acao-pedido-disputa/imagem-ifood";
import {
  GridCadastroRegioesComponent
} from "../superadmin/form-formasdeentrega/grid-cadastro-regioes/grid-cadastro-regioes.component";
import {
  FormZonaDeEntregaComponent
} from "../superadmin/form-formasdeentrega/form-zona-de-entrega/form-zona-de-entrega.component";
import {
  TelaListaSessoesUsuarioComponent
} from "../superadmin/tela-lista-sessoes-usuario/tela-lista-sessoes-usuario.component";
import {ListViewModule} from "@progress/kendo-angular-listview";
import { TelaIntegracaoIfoodComponent } from './tela-integracao-ifood/tela-integracao-ifood.component';
// tslint:disable-next-line:max-line-length
import { FormIntegracaoIfoodCentralizadoComponent } from './form-integracao-ifood-centralizado/form-integracao-ifood-centralizado.component';
import {ModalExportarProdutosComponent} from "./modal-exportar-produtos/modal-exportar-produtos.component";
import {ListaTemplatesPromptsDbComponent} from "../ia/lista-templates-prompts-db/lista-templates-prompts-db.component";
import {
  ControleListagemTrechoPromptComponent
} from "../ia/controle-listagem-trecho-prompt/controle-listagem-trecho-prompt.component";
import {TelaTemplatesPromptsDbComponent} from "../ia/tela-templates-prompts-db/tela-templates-prompts-db.component";
import {TelaTemplatesPromptsComponent} from "../ia/tela-templates-prompts/tela-templates-prompts.component";
import {PedidoOrigemComponent} from "../fidelidade/pedido-origem/pedido-origem.component";
import {ControleEditorMensagemComponent} from "../controle-editor-mensagem/controle-editor-mensagem.component";

import {
  AcaoPedidoAlteracaoEnderecoComponent
} from "../fidelidade/acao-pedido-alteracao-endereco/acao-pedido-alteracao-endereco.component";
import {
  SincronizarPagamentoOnlineComponent
} from "../fidelidade/sincronizar-pagamento-online/sincronizar-pagamento-online.component";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[trocoMinimo][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValorTrocoPedido, multi: true }
  ]
})
// tslint:disable-next-line:directive-class-suffix
export class ValorTrocoPedido  implements  Validator {
  @Input() trocoMinimo: number;

  constructor(private el: ElementRef) {
  }

  validate(c: AbstractControl): { [key: string]: any } {

    const valor = c.value;

    const erros = (!valor || Number(valor) < Number(this.trocoMinimo)) ? {"trocoMinimo": true} : null;

    if (erros) {
      this.el.nativeElement.setCustomValidity('erro de mínimo');
    } else {
      this.el.nativeElement.setCustomValidity('');
    }

    return erros;
  }
}


@NgModule({
    declarations: [
        UploadImagemComponent, CPFValidator,
        ValorTrocoPedido, Produtos2Component, ProdutoPrecoComponent, AdicionaisProdutoComponent,
        CadProdutoComponent, CadCategoriaComponent, OrdenarCategoriasComponent,
        TelaSincronizarPrecosComponent, TelaSincronizarEstoqueComponent,
        AutofocusDirective, RemovaWheelDirective,
        SelecionarNoFocoDirective,
        FotosComponent, UploadCropImagemComponent,
        FormFormasEntregaComponent,
        ValorMinimoValidator,
        ValorMaximoValidator,
        CadHorariosfuncionamentoComponent,
        CadAdicionalProdutoComponent, FormCopiarAdicionalComponent, FormCadastroOpcaoadicionalComponent,
        SiteCampoAdicionalEdicaoComponent,
        ImportadorIfoodComponent,
        ImportadorCsvComponent,
        UploadCardapioComponent,
        ConfigImpressaoComponent,
        TelaCuponsComponent,
        CadCupomComponent, CadTemplatepizzaComponent,
        TelaAssociarCupomContatosComponent, ListaAdicionaisComponent,
        UploadMultiplasImagensComponent,
        CadUsuarioComponent,
        UsuariosEmpresaComponent,
        FormIntegracaodeliveryComponent, FormIntegracaopedidosComponent, FormIntegracaofidelidadeComponent,
        CadPausasProgramadasComponent,
        TelaPausasProgramadasComponent, HistoricoProdutoComponent, TelefonePipe,
        ExibirCampanhaComponent,
        ExibirNotificacaoappComponent,
        FormRestringirCidadesComponent, GridCadastroRegioesComponent,
        NovaRestricaoCidadeComponent, FormZonaDeEntregaComponent,
        TelaImportarProdutosERPComponent,
        TelaSincronizarIfoodComponent, ModalExportarProdutosComponent,
        PainelImportacaoProdutosComponent,
        CadAlcancesComponent,
        TelaSincronizarChinaComponent,
        FinanceiroDeliveryComponent,
        FinanceiroMesasComponent,
        PedidoAcoesComponent, AcoesDeliveryComponent, PedidoProximasAcoesComponent, SincronizarPagamentoOnlineComponent,
        GridPedidosComponent, PedidoOrigemComponent,
        PedidoDetalhesComponent, AcaoPedidoDisputaComponent, ImageIfoodComponent, CadEnderecoContatoComponent, FormEnderecoContatoComponent,
        ModalPedidoStatusComponent, MudarStatusComponent, InfoPedidoComponent, InfoContatoComponent,
        PedidoStatusComponent, PedidoRibbonComponent, ContatoStatusComponent, AcaoPedidoAlteracaoEnderecoComponent,
        CadPromocaoComponent, ResumoEnderecoComponent,
        TelaPromocoesComponent, FooterComponent, ComandosImprimirComanda,
        RelatorioRecebimentosComponent, TabelaRecebimentoComponent, TelaUsuarioPermissoesComponent,
        TelaGerenciarPapeisComponent, GridCuponsComponent, TelaAreasDeEntregaComponent, FormTemplateComponent,
        FormUploadKmlComponent, TelaUploadFaviconComponent, GridChavesIntegracaoComponent,
        PlanosComponent, CadPlanosComponent, BrindesComponent, CadBrindeComponent, AtividadesComponent, CadAtividadeComponent,
        CadIntegracaoOpendeliveryComponent, ViewDisponibilidadeComponent, CadDisponibilidadeComponent,
        TelaSincronizarCatalogomodeloComponent,
        CadIntegracaoOpendeliveryLogisticaComponent,
        FormIntegracaopedidosComponent,
        CadIntegracaoUberdirectComponent,
        FormIntegracaoFoodydeliveryComponent,
        FormIntegracaoIfoodComponent, TelaIntegracaoIfoodComponent, FormIntegracaoIfoodCentralizadoComponent,
        ControleKanbamComponent, BandeiraLogoComponent, RazaoCancelamentoIfoodComponent,
        TelaListaSessoesUsuarioComponent,
        ListaTemplatesPromptsDbComponent,
        TelaTemplatesPromptsDbComponent,
        TelaTemplatesPromptsComponent,
        ControleEditorMensagemComponent,
        ControleListagemTrechoPromptComponent
    ],
    imports: [
        CommonModule,
        CommonModule, DialogModule, TooltipModule, DateInputsModule,
        UploadModule,
        FormsModule,
        GridModule,
        NgxMaskModule,
        ListViewModule,
        PopoverModule,
        MaskedTextBoxModule,
        CurrencyMaskModule,
        DatePickerModule, TimePickerModule, SortableModule,
        DropDownListModule, DropDownsModule,
        NumericTextBoxModule, ProgressBarModule,
        ImageCropperModule, InputsModule,
        HttpClientJsonpModule,
        WindowModule, ButtonModule, DialogModule, FileSelectModule, ApplicationPipesModule,
        InfiniteScrollModule, DropDownButtonModule, PagerModule, ExpansionPanelModule, PanelBarModule,
        RouterModule, ExcelModule, ChipModule, DragDropModule, MatCardModule, TabStripModule, LabelModule, LayoutModule
    ],
    exports: [
        UploadImagemComponent, CPFValidator, ValorTrocoPedido,
        CadProdutoComponent, CadCategoriaComponent, OrdenarCategoriasComponent,
        TelaSincronizarPrecosComponent, TelaSincronizarEstoqueComponent,
        AutofocusDirective, CadAdicionalProdutoComponent, FormCopiarAdicionalComponent, FormCadastroOpcaoadicionalComponent,
        RemovaWheelDirective, SelecionarNoFocoDirective, CadTemplatepizzaComponent,
        FormFormasEntregaComponent, ValorMinimoValidator, ValorMaximoValidator,
        FotosComponent, UploadCropImagemComponent, CadHorariosfuncionamentoComponent,
        SiteCampoAdicionalEdicaoComponent, ImportadorIfoodComponent, TelaCuponsComponent, TelaPromocoesComponent,
        ListaAdicionaisComponent, ImportadorCsvComponent, UploadCardapioComponent, ConfigImpressaoComponent,
        Produtos2Component, ProdutoPrecoComponent, UploadMultiplasImagensComponent, FormIntegracaodeliveryComponent,
        FormIntegracaoIfoodComponent, TelaIntegracaoIfoodComponent, FormIntegracaoIfoodCentralizadoComponent,
        FormIntegracaopedidosComponent, FormIntegracaofidelidadeComponent,
        TelaPausasProgramadasComponent, CadPausasProgramadasComponent, UsuariosEmpresaComponent,
        CadUsuarioComponent, TelaSincronizarIfoodComponent,
        ExibirCampanhaComponent, ExibirNotificacaoappComponent, TelefonePipe,
        CadAlcancesComponent, GridPedidosComponent, PedidoDetalhesComponent, AcaoPedidoDisputaComponent, ImageIfoodComponent,
        CadEnderecoContatoComponent, PedidoRibbonComponent, AcaoPedidoAlteracaoEnderecoComponent,
        FinanceiroDeliveryComponent, FinanceiroMesasComponent, FormEnderecoContatoComponent, ModalPedidoStatusComponent,
        ModalPedidoStatusComponent, MudarStatusComponent, InfoPedidoComponent, InfoContatoComponent,
        PedidoAcoesComponent, PedidoOrigemComponent, AcoesDeliveryComponent, PedidoProximasAcoesComponent, ContatoStatusComponent,
        ResumoEnderecoComponent, SincronizarPagamentoOnlineComponent,
        PedidoStatusComponent, PainelImportacaoProdutosComponent, FooterComponent, ComandosImprimirComanda,
        RelatorioRecebimentosComponent, TabelaRecebimentoComponent, TelaAreasDeEntregaComponent,
        FormUploadKmlComponent, TelaUploadFaviconComponent, TooltipModule, ControleKanbamComponent,
        PlanosComponent, CadPlanosComponent, BrindesComponent, CadBrindeComponent, AtividadesComponent, CadAtividadeComponent,
        BandeiraLogoComponent, RazaoCancelamentoIfoodComponent,
        TelaListaSessoesUsuarioComponent
    ],
    providers: [
        TelefonePipe
    ]
})
export class CompartilhadoModule { }
