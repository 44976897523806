<h3>
  Recebimentos Promokit
  <i class="k-i-loading k-icon ml-2" *ngIf="carregando"></i>
</h3>

<div class="card-box mt-2"   *ngIf="!carregando">
  <div class="row">
     <div class="col">
      <h3>
        Total Bruto: <b>{{resumo.totalBruto | number: "1.2-2" }}</b>
      </h3>
     </div>
     <div class="col">
       <h3>
         Total Taxas: <b>{{resumo.totalAdesao | number: "1.2-2"}}</b>
       </h3>

     </div>
     <div class="col">
        <h3> Total: <b>{{resumo.total| number: "1.2-2" }}</b></h3>
     </div>
  </div>

</div>

<div class="card-box mt-2"   *ngIf="!carregando">
  <h4>Recebimentos Eclética</h4>

  <app-tabela-recebimento [resumo]="resumo.ecletica"></app-tabela-recebimento>

  <h4>Recebimentos Eclética TrendFoods</h4>

  <app-tabela-recebimento [resumo]="resumo.trendfoods"></app-tabela-recebimento>

  <h4>Recebimentos   Mensalidades</h4>

  <app-tabela-recebimento [resumo]="resumo.mensalidades"></app-tabela-recebimento>

  <h4>Outros Recebimentos</h4>
  <table class="table table-striped">
    <thead>
    <tr>
      <th></th>
      <th>Empresa</th>
      <th>Descriçao</th>
      <th>Compensação</th>
      <th>Referência</th>
      <th>Sub-total</th>
      <th>Tarifas</th>
      <th>Total</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let recebimento of resumo.outros.recebimentos; let i = index;">
      <td>{{i + 1}}</td>
      <td class="text-primary"><b>{{recebimento.empresa.nome}}</b></td>
      <td>{{recebimento.descricao}}</td>
      <td>{{recebimento.dataCompensacao | date :"dd/MM/yy"}}</td>
      <td>{{recebimento.referencia }}</td>
      <td class="text-muted"> {{recebimento.valorBruto  | number: "1.2-2"}} </td>
      <td class="text-danger"> {{recebimento.valorTarifas  | number: "1.2-2"}} </td>
      <td><b>{{recebimento.total  | number: "1.2-2"}}</b></td>
    </tr>
    </tbody>
    <tbody>
    <tr>
      <td colspan="5"><p class="float-right">Totais</p></td>
      <td colspan="1">
        <p><b>{{resumo.outros.totalBruto  | number: "1.2-2"}}</b></p>
      </td>

      <td colspan="1">
        <p><b>{{resumo.outros.totalTarifas | number: "1.2-2"}}</b></p>
      </td>
      <td colspan="1">
        <p><b>{{resumo.outros.total | number: "1.2-2"}}</b></p>
      </td>
    </tr>
    </tbody>

  </table>
</div>


