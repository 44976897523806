<div class="" style="max-width: 900px">
  <h4 class="mt-2"><b>Minhas chaves</b></h4>

  <span *ngIf="integracaoOpendelivery?.appId" class="mb-2">
    AppId: <b>{{integracaoOpendelivery?.appId}}</b>
    <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(integracaoOpendelivery?.appId)"
       [ngClass]="{'text-success': textoCopiado === integracaoOpendelivery?.appId}"
       title="copiar appId" kendoTooltip></i>


  </span>

  <div   class="row">
    <div class="col">
      <div class="form-group">
        <label>Base Url (autenticação)</label>

        <input kendoTextBox   name="urlAuth"    class="form-control"
               [value]="obtenhaBaseUrlAuth()" readonly/>

        <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(obtenhaBaseUrlAuth())"
           [ngClass]="{'text-success': textoCopiado === obtenhaBaseUrlAuth()}"
           title="copiar url autenticação" kendoTooltip></i>
      </div>

    </div>

    <div class="col">
      <div class="form-group  ">
        <label>Base Url (demais rotas)</label>

        <input kendoTextBox   name="urlOrders"    class="form-control"
               [value]="obtenhaBaseUrlOrders()" readonly/>

        <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(obtenhaBaseUrlOrders())"
           [ngClass]="{'text-success': textoCopiado === obtenhaBaseUrlOrders()}"
           title="copiar url para demais rotas" kendoTooltip></i>
      </div>

    </div>

  </div>

  <div class="row" *ngIf="gerouChaves()">
    <div class="col">
      <div class="form-group ">
        <label>Client Id</label>
        <input kendoTextBox name="clientId" class="form-control" placeholder="nao gerado ainda"
               [(ngModel)]="integracaoOpendelivery.cliente.id" readonly/>

        <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(integracaoOpendelivery.cliente.id)" *ngIf="integracaoOpendelivery.id"
           [ngClass]="{'text-success': textoCopiado === integracaoOpendelivery.cliente.id}"
           title="copiar clientId" kendoTooltip></i>

      </div>
    </div>
    <div class="col">
      <div class="form-group ">
        <label>Client Secret</label>
        <input kendoTextBox name="clientSecret" class="form-control" placeholder="nao gerado ainda"
               [(ngModel)]="integracaoOpendelivery.cliente.segredo" readonly/>

        <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(integracaoOpendelivery.cliente.segredo)" *ngIf="integracaoOpendelivery.id"
           [ngClass]="{'text-success': textoCopiado === integracaoOpendelivery.cliente.segredo}"
           title="copiar client secret" kendoTooltip></i>

      </div>
    </div>
  </div>

  <button class="btn btn-blue mt-2" (click)="gereChaves()" [disabled]="gerandoChaves" [hidden]="gerouChaves()">
    Gerar chaves <i class="k-i-loading k-icon" *ngIf="gerandoChaves" ></i>
  </button>


  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >

    <h4><b>Credenciais do Comerciante </b>   </h4>

    <div class="row">
      <div class="col">
        <div class="form-group mt-2">
          <label>URL Base ( Para obter /merchant )</label>
          <input kendoTextBox name="urlBase" placeholder="Url base onde as rotas serão chamadas: ex: https://meunegocio.com.br/api"
                 class="form-control" #urlBase="ngModel"
                 [(ngModel)]="integracaoOpendelivery.merchantBaseUrl" required/>

          <div class="invalid-feedback">
            <p  >URL Base é obrigatório</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group mt-2">
          <label>Api Key</label>
          <input kendoTextBox name="apiKey" placeholder="Api Key para autenticação"
                 class="form-control" #apiKey="ngModel"
                 [(ngModel)]="integracaoOpendelivery.merchantApiKey" required/>

          <div class="invalid-feedback">
            <p  >API Key é obrigatório</p>
          </div>
        </div>
      </div>

    </div>

    <div class="row" *ngIf="false">
      <div class="col">
        <div class="form-group mt-2">
          <label>Webhook atualizaçao de pedidos ( POST v1/newEvent)</label>
          <input kendoTextBox   name="webhook"  placeholder="Url do webhook atualizaçao pedidos  (Opcional) "
                 class="form-control"
                 [(ngModel)]="integracaoOpendelivery.merchantWebhook"  />
        </div>
      </div>
    </div>

    <div *ngIf="integracaoOpendelivery.merchantId" class="row">
      <div class="col">
        <div class="form-group mt-2">
          <label>Merchant Id</label>
          <input kendoTextBox name="merchantId" class="form-control"
                 [(ngModel)]="integracaoOpendelivery.merchantId" readonly/>
        </div>
      </div>
      <div class="col">
        <div class="form-group mt-2">
          <label>Merchant Name</label>
          <input kendoTextBox name="merchantName" class="form-control"
                 [(ngModel)]="integracaoOpendelivery.merchantName" readonly/>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col">

        <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2 mb-2" role="alert">
          {{mensagemErro}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <button class="btn btn-warning waves-effect  " type="button"   [disabled]="processando"
                (click)="ativeIntegracao()"   *ngIf="this.integracaoOpendelivery && !this.integracaoOpendelivery.ativa">
          Ativar <i class="k-i-loading k-icon" *ngIf="processando" ></i>
        </button>

        <ng-container *ngIf="!this.integracaoOpendelivery || this.integracaoOpendelivery.ativa">
          <button class="btn btn-success waves-effect" type="submit"   [disabled]="salvando || !frm.dirty"  >
            Salvar <i class="k-i-loading k-icon" *ngIf="salvando" ></i>
          </button>

          <button class="btn btn-danger waves-effect ml-2" type="button"   [disabled]="salvando || processando"
                  (click)="desativeIntegracao()" >
            Desativar <i class="k-i-loading k-icon" *ngIf="processando" ></i>
          </button>
        </ng-container>


      </div>
    </div>

  </form>

</div>

