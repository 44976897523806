import {EnumTipoDePontuacao} from "../../../server/lib/emun/EnumTipoDePontuacao";
import {UITipoDePontuacaoPorValor} from "./UITipoDePontuacaoPorValor";
import {TipoDePontuacaoPorValor} from "../../../server/domain/TipoDePontuacaoPorValor";
import {UITipoDePontuacaoQtdSelos} from "./UITipoDePontuacaoQtdSelos";
import {TipoDePontuacaoQtdFixa} from "../../../server/domain/TipoDePontuacaoQtdFixa";
import {UITipoDePontuacaoPorPontos} from "./UITipoDePontuacaoPorPontos";
import {TipoDePontuacaoPorPontos} from "../../../server/domain/TipoDePontuacaoPorPontos";
import {UITipoDePontuacaoQtdeFixaAtividade} from "./UITipoDePontuacaoQtdeFixaAtividade";
import {UITipoDePontuacaoQtdeVariavelAtividade} from "./UITipoDePontuacaoQtdeVariavelAtividade";
import {UITipoDePontuacaoCashback} from "./UITipoDePontuacaoCashback";
import {IUITipoDePontuacao} from "./IUITipoDePontuacao";

export class IUITipoDePontuacaoFactory{

  static obtenhaTipodePontuacao(dtoEmpresa: any, tipoDePontuacao: any): IUITipoDePontuacao {

    switch (tipoDePontuacao.tipo) {
      case EnumTipoDePontuacao.PorValor:
        let tipoDePontuacaoPorValor = new UITipoDePontuacaoPorValor(dtoEmpresa);
        tipoDePontuacaoPorValor.valorPorPonto = (tipoDePontuacao as TipoDePontuacaoPorValor).valorPorPonto;
        return tipoDePontuacaoPorValor;

      case EnumTipoDePontuacao.QtdeFixa:
        let tipoDePontuacaoQtdSelos = new UITipoDePontuacaoQtdSelos(dtoEmpresa);
        tipoDePontuacaoQtdSelos.selosPorAtividade = (tipoDePontuacao as TipoDePontuacaoQtdFixa).selosPorAtividade;
        return tipoDePontuacaoQtdSelos;

      case EnumTipoDePontuacao.PorPontos:
        let tipoDePontuacaoEmPontos = new UITipoDePontuacaoPorPontos(dtoEmpresa);
        tipoDePontuacaoEmPontos.pontosPorValor = (tipoDePontuacao as TipoDePontuacaoPorPontos).pontosPorValor;
        return tipoDePontuacaoEmPontos;

      case EnumTipoDePontuacao.QtdeFixaPorAtividade:
        return new UITipoDePontuacaoQtdeFixaAtividade(dtoEmpresa);

      case EnumTipoDePontuacao.QtdeVariavelPorAtividade:
        return new UITipoDePontuacaoQtdeVariavelAtividade(dtoEmpresa);

      case EnumTipoDePontuacao.Cashback:
        return new UITipoDePontuacaoCashback(dtoEmpresa);

      default:
        return null;
    }
  }
}
