import { Component, OnInit } from '@angular/core';
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {CadCupomComponent} from "../../compartilhado/cad-cupom/cad-cupom.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {EntregadorService} from "../../services/entregador.service";
import {process} from "@progress/kendo-data-query";
import {CadEntregadorComponent} from "../cad-entregador/cad-entregador.component";

@Component({
  selector: 'app-grid-entregador',
  templateUrl: './grid-entregador.component.html',
  styleUrls: ['./grid-entregador.component.scss']
})
export class GridEntregadorComponent implements OnInit {
  gridDataResult: any;
  groups: any;
  carregando: any;
  modalDeletar: any;
  entregadorApagar: any;
  entregadores: any[];

  constructor(private dialogService: DialogService,
              private entregadorService: EntregadorService) {
    this.carregueEntregadores();
  }

  ngOnInit(): void {

  }

  editarEntregador(dataItem: any) {
    this.abraModalEntregador(dataItem)
  }

  abraDialogRemover($event: RemoveEvent) {
    this.entregadorApagar = $event.dataItem;

    this.modalDeletar = true;

  }

  criarEntregador() {
    this.abraModalEntregador()
  }

  canceleRemocao() {
    this.modalDeletar = false;
    delete    this.entregadorApagar;
  }

  confirmeRemocao() {
    let i = this.entregadores.indexOf(this.entregadorApagar);

    this.entregadorService.removaEntregador(this.entregadorApagar).then( () => {
      this.entregadores.splice(i, 1);
    }).catch( erro => {
      alert(erro)
    })

    this.canceleRemocao();
  }

  carregueEntregadores() {
    this.carregando = true
    this.entregadorService.liste().then( (resposta) => {
      this.setEntregadores(resposta)
    })

  }

  private abraModalEntregador(entregador: any = null) {
    let altura = window.innerHeight;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Entregador ',
      content: CadEntregadorComponent,
      width: 500,
      maxHeight: altura
    });

    const telaEntregador: CadEntregadorComponent = windowRef.content.instance;

    telaEntregador.windowRef = windowRef;

    if(entregador && entregador.id)
      telaEntregador.entregador = entregador;

    windowRef.result.subscribe((result: any) => {
      if(result && result.id){
        this.carregando = true;
        this.entregadores = [];
        this.carregueEntregadores();
      }

    });
  }


  private setEntregadores(entregadores: any) {
    this.entregadores = entregadores;
    this.gridDataResult = process(this.entregadores, { group: this.groups });
    this.carregando = false;
  }
}
