
<kendo-grid
  [data]="chavesIntegracao" [group]="groups" [groupable]="true"
  [loading]="carregando" style="min-height: 300px;"

  footerStyle="font-size: 11px;"
>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum aplicativo externo integrado "
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="Nome">
    <ng-template kendoGridCellTemplate let-chaveIntegracao >
      <span><b>{{chaveIntegracao.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Identificador" field="identificador"   >
    <ng-template kendoGridCellTemplate let-chaveIntegracao >
      <b> {{chaveIntegracao.identificador}}</b>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Client ID"   width="310px" >
    <ng-template kendoGridCellTemplate let-chaveIntegracao  >
      <span class="text-blue"><b>{{chaveIntegracao.id}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Client Secret" field="segredo" width="330px"  >
    <ng-template kendoGridCellTemplate let-chaveIntegracao>
      <b> ************************************</b>

      <i class="fe-copy fa-2x ml-1 cpointer" (click)="copiarSegredo(chaveIntegracao.segredo)"
         [ngClass]="{'text-success': mensagemCopiada === chaveIntegracao.segredo}"
         title="copiar código" kendoTooltip></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Sistema Integrado"   >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-combobox   name="tipo{{dataItem.id}}" [(ngModel)]="dataItem.sistemaIntegrado" [data]="sistemasIntegrados"
                        [allowCustom]="false" placeholder="Selecione um Sistema" class="form-control" [clearButton]="false"
                        [textField]="'nome'"     style="border: none;" required
                        [valueField]="'id'"
                        (valueChange)="alterouSistema(dataItem, $event)">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-command-column width="100px" title="Ativo">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-chaveIntegracao>
      <button class="btn btn-xs btn-danger ml-1" (click)="desativarIntegracao(chaveIntegracao)"
              kendoTooltip title="Desativar Integração" *ngIf="chaveIntegracao.ativo" [disabled]="chaveIntegracao.processando">
        <i class="far fa-pause-circle fa-lg">  </i>
      </button>
      <button class="btn btn-xs btn-primary ml-1" (click)="ativarIntegracao(chaveIntegracao)"
              kendoTooltip title="Ativar Integração" *ngIf="!chaveIntegracao.ativo" [disabled]="chaveIntegracao.processando">
        <i class="far fa-play-circle fa-lg"  ></i>&nbsp;
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
</kendo-grid>
