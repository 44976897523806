import {Component, Input, OnInit} from '@angular/core';
import {CurrencyPipe, formatCurrency, formatNumber} from "@angular/common";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'saldo-cartao',
  templateUrl: './saldo-cartao.component.html',
  styleUrls: ['./saldo-cartao.component.scss']
})
export class SaldoCartaoComponent implements OnInit {
  @Input() cartao: any = {};
  @Input() tipoDeAcumulo: any;
  @Input() inline: boolean;
  cashback: boolean;
  constructor() { }

  ngOnInit() {
    this.cashback = this.cartao && this.cartao.plano && this.cartao.plano.tipoDeAcumulo === 'Reais';
  }

  getPontos(){
    let pontos: any = 0;

    if(this.cartao && this.cartao.pontos >= 0)  {
      if(this.cashback){
         // @ts-ignore
        pontos = formatCurrency(this.cartao.pontos, 'pt-BR', 'R$');
      } else {
        pontos =   formatNumber(Math.floor(this.cartao.pontos), 'pt-BR');
      }
    }

    return pontos;

  }
}
