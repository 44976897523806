<div class="mt-1" *ngIf="pedido.aceito && !pedido.aguardandoPagamentoOnline">
  <button class="btn btn-block btn-warning btn-sm" *ngIf="pedido.status === 'Novo'"
          (click)="mudarStatusPedidoSemTela($event, pedido, 'Em preparação')"
          kendoTooltip title="Notifica o cliente que o pedido está em Preparação.">
    Em Preparação
  </button>

  <button class="btn btn-block btn-primary btn-sm" *ngIf="pedido.status === 'Em preparação' && pedido.despachar"
          (click)="mudarStatusPedidoSemTela($event, pedido, 'Saiu para entrega', true)"
          kendoTooltip title="Notifica o cliente que o pedido saiu para Entrega.">
    <i class="fas fa-motorcycle" [hidden]="integracaoDeliveryAtiva()"></i> Despachar
  </button>

  <button class="btn btn-block btn-primary btn-sm" *ngIf="pedido.status === 'Em preparação' && (pedido.retirar || pedido.balcao)"
          (click)="mudarStatusPedidoSemTela($event, pedido, 'Pronto')" kendoTooltip title="Marca como Pronto e notifica o cliente.">
    <i class="fas fa-check"></i> Pronto
  </button>

  <button class="btn btn-block btn-primary btn-sm" *ngIf="pedido.status === 'Saiu para entrega'" [hidden]="pedido.naoAlterarStatus"
          (click)="mudarStatusPedidoSemTela($event, pedido, 'Entregue')" kendoTooltip title="Marca como Entregue e notifica o cliente.">
    Entregue
  </button>

  <button class="btn btn-block btn-primary btn-sm" *ngIf="pedido.status === 'Entregue' && !pedido.pago"
          (click)="mudarStatusPedidoSemTela($event, pedido, 'Finalizar')" kendoTooltip title="Muda o pedido para pago.">
    Finalizar
  </button>
</div>
