import {EnumMeioDePagamento} from "./EnumMeioDePagamento";

export class ConfigFormaDePagamento {
  clientID: string;
  clientSecret: string;
  merchantKey: string;
  merchantId: string;
  nomeFaturaCartao: string;
  token: string;
  publicKey: string;
  email: string;
  meioDePagamento: EnumMeioDePagamento;
  establishmentCode: string ;
  merchantName: string ;
  mcc: string ;
  antifraudeDesabilitado: boolean;
  tokenizar: boolean;
}
