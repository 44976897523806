import { Component, OnInit } from '@angular/core';
import {AutorizacaoService} from "../services/autorizacao.service";
import {ConstantsService} from "../fidelidade/ConstantsService";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {DialogService} from "@progress/kendo-angular-dialog";

@Component({
  selector: 'app-tela-cadastro-produtos',
  templateUrl: './tela-cadastro-produtos.component.html',
  styleUrls: ['./tela-cadastro-produtos.component.scss']
})
export class TelaCadastroProdutosComponent implements OnInit {
  empresa;
  usuario: any = {};
  confirmeRemover = false;
  removendo = false;
  constructor(private constantsService: ConstantsService, private autorizacaoService: AutorizacaoService,
              private empresaService: EmpresasService, private dialogService: DialogService) {
    this.constantsService.empresa$.subscribe( data => this.empresa  = data  )

  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser() || {};
  }

  removerCatalogo(){
  //  this.confirmeRemover = true;
    this.openConfirm();
  }

  removaCatalogo(){
    this.removendo = true;

    this.empresaService.limpeCatalogoDaLoja( ).then((resposta: any) => {
      window.location.reload();
    }).catch((err) => {
      this.removendo = false;
      alert(err);

    })
  }

  public openConfirm(): void {
    const dialog: any = this.dialogService.open({
      title: 'Confirmação',
      content: 'Você tem certeza quer remover? serão removidos todas as categorias e produtos da loja',
      actions: [
        {text: 'Confirmar', primary: true},
        {text: 'Cancelar'}
      ]
    });

    dialog.result.subscribe((result) => {
      if (result.text === 'Confirmar')
        this.removaCatalogo();
    });
  }

}
