import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileInfo, FileRestrictions, RemoveEvent, SelectEvent} from "@progress/kendo-angular-upload";
import {DomSanitizer} from "@angular/platform-browser";
import {ImageCroppedEvent, ImageTransform} from "ngx-image-cropper";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-upload-multiplas-imagens',
  templateUrl: './upload-multiplas-imagens.component.html',
  styleUrls: ['./upload-multiplas-imagens.component.scss'],
})
export class UploadMultiplasImagensComponent implements OnInit {
  @Input() objeto: any = {} ;
  @Input() label: any =  'Imagem' ;
  @Input() prop = 'imagens';
  @Input() lg ;
  @Input() sm ;
  @Output() onEnviou = new EventEmitter();
  @Output() onSalvar = new EventEmitter();
  @Input() maxsize ;
  uploadUrl: any = '/upload/imagens/crop';
  uploadRemoveUrl: any;
  public imagePreview: any;
  imagensCortar: any = [];
  imagensCortadas: any = [];
  currentImageIndex: any = 0;
  showUploader: any = true;
  croppedImage: any = '';
  @Input() restricoes:  FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png']
  };
  @ViewChild('imageCropper', {static: false})  imageCropper: any;
  files: Array<FileInfo> = [];
  editarImagem = false;
  backupImagem: any[];
  fezAlteracoes: boolean;
  erroSalvar: string;
  erroUpload: string;
  salvando: boolean;
  cortando: any;
  erroLoadImage: string;
  showCropper = false;
  isCropping = false;
  enviando: any = false;
  transform: ImageTransform = {};
  get listaImagens(): any[] {
    return this.objeto[this.prop]
  }

  get qtdRestantes(): number {
    return this.listaImagens ? 6 - this.listaImagens.length : 6
  }

  constructor(private sanitizer: DomSanitizer, private httpClient: HttpClient) {

  }

  ngOnInit() {
    this.fezAlteracoes = false;
    if(this.maxsize)
      this.uploadUrl = String(`${this.uploadUrl}/${this.maxsize}`)

  }

  selecionouArquivo(e: SelectEvent){
    if (e.files.length > this.qtdRestantes) {
      alert("Atenção, selecione até " + this.qtdRestantes + " arquivos.");
      e.preventDefault();
    }
    e.files.forEach((file) => {
      console.log(`File selected: ${file.name}`);
      if (!file.validationErrors)
        this.imagensCortar.push( file)
    });

    this.loadAndCropImage();

  }

  confirmeCrop() {
    this.isCropping = false;
    this.uploadCroppedImage();
  }

  loadAndCropImage() {
    if (this.currentImageIndex < this.imagensCortar.length) {
      this.showUploader = false;
      this.isCropping = false;
    } else {
      this.showUploader = true;
    }
  }



  salveFotoProduto($event: any) {
    $event.salvou();
    this.imagensCortar  = [];

  }

  canceleCortarImagem(){
    //this.objeto[this.prop] = [...this.backupImagem];
    this.imagensCortar  = [];
    this.imagePreview = null;
    this.showUploader = true;
  }



  canceleSalvar(){
    this.canceleCortarImagem();
    this.finalizeSalvar();
  }

  finalizeSalvar(){
    this.fezAlteracoes = false;
    this.editarImagem = false;
    delete this.erroSalvar;
  }


  public removeEventHandler(e: RemoveEvent): void {
    delete  this.imagePreview;
  }

  getLinkPrimeiraImagem() {
    let linkPrimeiraImagem = this.objeto[this.prop] && this.objeto[this.prop].length > 0 ? this.objeto[this.prop][0].linkImagem : null
    return linkPrimeiraImagem
  }


  exibirSalvar(){
    return this.objeto.id && this.fezAlteracoes && this.temAcaoSalvar()
  }

  temAcaoSalvar() {
    return this.onSalvar.observers.length
  }

  salveImagem() {
    this.salvando = true;
    this.onSalvar.emit( (erro) => {
      this.salvando = false;
      if(!erro){
        this.backupImagem = null
        this.finalizeSalvar();
      } else {
        this.erroSalvar = erro;
      }

    })
  }

  onErroUpload($event: any) {
    console.log($event)
    if($event.response && $event.response.error)
      alert($event.response.error)

  }


  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  uploadCroppedImage() {
    if (this.currentImageIndex < this.imagensCortar.length) {
      this.enviando = true;

      const formData = new FormData();
      formData.append('image', this.dataURItoBlob(this.imagensCortadas[this.currentImageIndex]));

      this.httpClient.post(this.uploadUrl, formData )
        .subscribe((response: any) => {
          console.log(`Upload da imagem ${this.currentImageIndex + 1} bem-sucedido:`, response);

          this.enviando = false;
          this.currentImageIndex++;
          this.loadAndCropImage();
          this.adicioneArquivoDoUpload(response.data);
        }, error => {
          console.error(`Erro ao fazer upload da imagem ${this.currentImageIndex + 1}:`, error);

          this.enviando = false;
          // Você pode decidir o que fazer em caso de erro, como pular para a próxima imagem
          // this.currentImageIndex++;
          // this.loadAndCropImage();
        });
    }
  }

  adicioneArquivoDoUpload(data: any){
    if(!this.objeto[this.prop])
      this.objeto[this.prop] = []

    if(!this.backupImagem)
      this.backupImagem =  [...this.objeto[this.prop]];

    this.objeto[this.prop].push(data);

    console.log( this.objeto[this.prop])


    this.reorganizeImagens();
    this.editarImagem = false;
    this.fezAlteracoes = true;
    this.onEnviou.emit();
    this.fezAlteracoes = true;

  }

  onErroUploadMaisArquivos($event: any) {
    console.log($event)
    if($event.response && $event.response.error)
      alert($event.response.error)
  }

  possuiMaisDeUmaImagem() {
    return this.objeto[this.prop] && this.objeto[this.prop].length > 1
  }

  getLinkImagem(i: number) {
    return this.listaImagens[i].linkImagem
  }

  removerFoto(i: number) {
    if(!this.backupImagem)
      this.backupImagem =  [...this.objeto[this.prop]];
    this.objeto[this.prop].splice(i, 1);
    this.reorganizeImagens();
    this.fezAlteracoes = true;
  }

  private reorganizeImagens() {
    for (let i = 0; i < this.listaImagens.length; i++)
      this.listaImagens[i].ordem = i
  }

  terminouUpload() {
    this.files = []
    this.imagensCortadas = [];
  }

  loadImageFailed () {
    this.erroLoadImage = 'Imagem não é do tipo suportado: png, jpeg ou jpg';
  }


  imageCropped(event: ImageCroppedEvent) {
    this.imagensCortadas[this.currentImageIndex] = event.base64;
    this.isCropping = true;

  }

  zoomIn() {
    this.transform = {
      ...this.transform,
      scale: (this.transform.scale || 1) * 1.1
    };
  }

  zoomOut() {
    this.transform = {
      ...this.transform,
      scale: (this.transform.scale || 1) * 0.9
    };
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded')
  }
  cropperReady() {
    console.log('Cropper ready')

  }
}
