<div class="paper" [hidden]="carregando">

  <div class="titulo" *ngIf="resumoVendas">
    <img class="imagem_empresa" src="https://promokit.com.br/images/empresa/{{resumoVendas.empresa.logo}}" style="    width: 65px;"/>
    <h3 class="empresa mt-3"><b>{{resumoVendas.empresa.nome}}</b></h3>
    <h4>Relatorio Financeiro</h4>

  </div>

  <div class="dados mt-3 ">
    <div class="row">
      <div class="col-12" *ngIf="resumoVendas.dataInicial">
        <span>Data Inicial: {{resumoVendas.dataInicial }}</span><br>
        <span>Data Final: {{resumoVendas.dataFinal }}</span><br>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="text-center font-weight-bold" style="display: block" >
          Resumo Geral

        </label>
      </div>
      <div class="col-12">
        <span>Qtde de Pedidos: {{resumoVendas.qtde}}</span><br>
        <span>Total de Vendas: {{resumoVendas.total | currency: "BRL"}}</span><br>
        <span>Total Taxa de Entrega: {{resumoVendas.taxaDeEntrega | currency: "BRL"}}</span><br>
        <span>Total Produtos: {{resumoVendas.total -  resumoVendas.taxaDeEntrega | currency: "BRL"}}</span><br>
      </div>
    </div>


    <div class="row pt-2 itens">
      <div class="col-12">
        <label class="text-center font-weight-bold" style="display: block" >
          Resumo por Forma de Pagamento

        </label>

        <div class="row">
          <div class="font-italic col-6"><label><b>Nome</b></label></div>
          <div class="col-3 font-italic" ><label><b>Qtde.</b></label></div>
          <div class="col-3 font-italic" ><label><b>Total</b></label></div>
        </div>

        <div class="row item" *ngFor="let forma of resumoVendas.formasDePagamento">
          <div class="col-7">{{forma.nome}}
          </div>
          <div class="col-2  ">{{forma.qtde}}</div>
          <ng-container>
            <div class="col-3 " >
              <span class="float-right">{{forma.total | currency: "BRL"}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row pt-2 itens">
      <div class="col-12">
        <label class="text-center font-weight-bold" style="display: block" >
          Resumo por Forma de Entrega

        </label>

        <div class="row">
          <div class="font-italic col-6"><label><b>Nome</b></label></div>
          <div class="col-3 font-italic" ><label><b>Qtde.</b></label></div>
          <div class="col-3 font-italic" ><label><b>Total</b></label></div>
        </div>

        <div class="row item" *ngFor="let forma of resumoVendas.formasDeEntrega">
          <div class="col-7">{{forma.nome === 'undefined' ? 'Outros' : forma.nome}}
          </div>
          <div class="col-2  ">{{forma.qtde}}</div>
          <ng-container>
            <div class="col-3 " >
              <span class="float-right">{{forma.total | currency: "BRL"}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row pt-2 itens" *ngIf="resumoVendas.cupons && resumoVendas.cupons.length > 0">
      <div class="col-12">
        <label class="text-center font-weight-bold" style="display: block" >
          Resumo por Cupom

        </label>

        <div class="row">
          <div class="font-italic col-6"><label><b>Nome</b></label></div>
          <div class="col-3 font-italic" ><label><b>Qtde.</b></label></div>
          <div class="col-3 font-italic" ><label><b>Total</b></label></div>
        </div>

        <div class="row item" *ngFor="let cupom of resumoVendas.cupons">
          <div class="col-7">{{cupom.nome}}
          </div>
          <div class="col-2  ">{{cupom.qtde}}</div>
          <ng-container>
            <div class="col-3 " >
              <span class="float-right">{{cupom.total | currency: "BRL"}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
