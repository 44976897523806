<kendo-chart #chartComponent
  (seriesClick)="onSeriesClick($event)" [style.height]="altura">
  <ng-template kendoChartDonutCenterTemplate *ngIf="campo === 'qtdeClientes'">
    <h4>{{relatorio.qtdeClientes}}</h4>
    clientes
  </ng-template>
  <ng-template kendoChartDonutCenterTemplate *ngIf="campo === 'totalGasto'">
    <h4>{{relatorio.totalGasto | currency: 'BRL' }}</h4>
  </ng-template>
  <kendo-chart-series>
    <kendo-chart-legend [visible]="ehCelular" position="bottom" [labels]="{content: labelContentLabels}">
    </kendo-chart-legend>
    <kendo-chart-series-item [autoFit]="true" name="Fibonacci" [data]="data" [field]="campo"
                             categoryField="rotulo" type="donut" colorField="cor" [explodeField]="'selecionado'"
                             [overlay]="{gradient: 'roundedBevel'}">
      <kendo-chart-series-item-tooltip [ngClass]="{'teste': true}" [color]="'#333'" [background]="'#fafafa'" [class]="'teste'" style="width: 600px;">
        <ng-template let-value="value" let-category="category">
          <div style="height: 100%;position: absolute;left: 0px;right: 0px;top: 0px;width: 4px;" [style.background]="obtenhaCor({rotulo:category})" class="mb-2"></div>
          <div style="padding-left: 5px;" class="popup">
            <div class="mt-1">
              <p class="mt-0 mb-2">
                <span style="font-size: 15px;" [style.color]="obtenhaCor({rotulo:category})"><strong>{{ category }}</strong></span>
                <br>
                {{ obtenhaRegistro(category).qtdeClientes }} clientes
              </p>

              <div style="height: 1px;width: 100%;opacity: .3;" [style.background]="obtenhaCor({rotulo:category})" class="mb-2"></div>
            </div>
            <div class="row">
              <div class="col-6 mb-1">
                <p class="mb-1 rotulo">Valor Gasto</p>
                <p class="valor">
                  {{ obtenhaRegistro(category).totalGasto | currency: 'BRL' }}
                </p>
              </div>
              <div class="col-6 mb-1">
                <p class="mb-1 rotulo">Qtde. Vendas</p>
                <p class="valor">
                  {{ obtenhaRegistro(category).qtdeVisitas }}
                </p>
              </div>
              <div class="col-6 mb-1">
                <p class="mb-1 rotulo">Ticket Médio</p>
                <p class="valor">
                  {{ obtenhaRegistro(category).ticketMedio | currency: 'BRL' }}
                </p>
              </div>
              <div class="col-6 mb-1">
                <p class="mb-1 rotulo">Retorna em</p>
                <p class="valor">
                  {{ obtenhaTempoRetornar(category) }} dias
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-chart-series-item-tooltip>
      <kendo-chart-series-item-labels [visible]="!ehCelular" [distance]="20" position="outsideEnd" background="none" color="#000"
                                      [content]="labelContent">
      </kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
