import {Component, Input, OnInit} from '@angular/core';
import {ContatosService} from "../services/contatos.service";

@Component({
  selector: 'app-dialog-remover-contato',
  templateUrl: './dialog-remover-contato.component.html',
  styleUrls: ['./dialog-remover-contato.component.scss']
})
export class DialogRemoverContatoComponent implements OnInit {
  @Input() abraDialogo: boolean;
  @Input() contatoSelecionado: any;
  @Input() tela: any;
  excluindoContato: boolean;
  removeu = false;


  constructor(private contatoService: ContatosService) { }

  ngOnInit() {
  }

  close(remover: string) {
    if( remover === 'nao' ) {
      this.excluindoContato = false;
      this.removeu = false;
      this.abraDialogo = false;
      this.tela.abraDialogo = false;
      return;
    }

    this.excluindoContato = true;


    this.contatoService.removaContato(this.contatoSelecionado).then((resposta) => {
      this.excluindoContato = false;
      this.contatoSelecionado = null;
      this.removeu = true;
    }).catch((reason) => {
      alert(reason);
      this.contatoSelecionado = null;
      this.excluindoContato = false;
      this.abraDialogo = false;
    })
  }

  removeuContato() {
    this.abraDialogo = false;
    this.removeu = false;
    this.tela.apagueiContato();
    this.tela.abraDialogo = false;
  }
}
