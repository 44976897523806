import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {IntegracaoERPService} from "../../services/integracaoerp.service";


@Component({
  selector: 'app-form-integracaodelivery',
  templateUrl: './form-integracaodelivery.component.html',
  styleUrls: ['./form-integracaodelivery.component.scss']
})
export class FormIntegracaodeliveryComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('frm', {}) frm:  any;
  constructor(private  empresaService: EmpresasService, private integracaoERPService: IntegracaoERPService) { }

  ngOnInit(): void {
  }

}
