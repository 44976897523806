<div>
  <h4 class="page-title"> <i class="k-icon k-i-track-changes"></i>
    Meu Plano</h4>

 <div class="row">

   <div class="col-12 col-lg-6 ">

     <div class="card com-borda text-left"  >
       <div class="card-header">
         Detalhes do Plano
       </div>
       <div class="card-body">
         <h5 class="d-inline-block">{{contrato.plano?.nome}}</h5>
         <span class="d-inline-block ml-2">por <b>{{contrato.valorMensalidade | currency: "BRL"}}</b> / mês</span>

         <ul>
           <li>{{contrato.limiteContatos}} contatos ativos</li>
           <li>R$ 0,25 por mensagem de campanha.</li>
           <li>Mensagens automatizadas.</li>
         </ul>

         <div *ngIf="contrato.percentualAtivos >= 75" >
           <p> {{contrato.percentualAtivos }}% contato ativos no plano</p>
           <kendo-progressbar
             [style.width.%]="100"
             [emptyCssStyle]="emptyStyles"
             [progressCssStyle]="progressStyles"
             [label]="labelSettings"
             [max]="100"
             [value]="contrato.percentualAtivos"
           ></kendo-progressbar>

         </div>

         <button class="btn btn-info mt-2" (click)="atualizarPlano()">Atualizar Plano</button>
       </div>
     </div>

   </div>

   <div class="col-12 col-lg-6">

     <div class="card com-borda text-left" *ngIf="contrato.pagaNoBoleto">

       <div class="card-header">
         Informações e  avisos
       </div>

       <div class="card-body">
         <div  *ngIf="contrato.proximaFatura && !contrato.proximaFatura.paga">

           <p>
             Informamos que o boleto com vencimento <b>{{contrato.proximaFatura.dataVencimento}}</b>
             e valor <b> {{contrato.proximaFatura.total | currency: 'BRL'}}  </b> já se encontra disponível para pagamento.

           </p>

           <button class="btn btn-success">
             <i class="fa fa-barcode fa-lg cursor-pointer"    (click)="verFatura(contrato.proximaFatura)"> Pagar boleto</i>
           </button>

           <div class="alert  alert-light mt-2">

             <p>
               <b>  Quer desconto? </b> cadastre a cobrança automatica
               no cartão de crédito    é tenha
               <b>{{contrato.plano.descontoCartao | currency: "BRL"}} </b> de desconto.

             </p>
             <button class="btn btn-info" (click)="mudarParaCartao()">
               <i class="fa fa-credit-card fa-"> </i> Pagar no  cartão</button>

           </div>

         </div>

         <div  *ngIf="contrato.proximaFatura && contrato.proximaFatura.paga" >
           <p> Tudo certo por aqui, sua fatura de <b>{{contrato.proximaFatura.referencia}}</b>  está paga!</p>
           <div class="row">
             <div class="col-6">
               <label>Forma de pagamento</label>
               <h5>{{contrato.proximaFatura?.formaDePagamento || '-'}}</h5>

             </div>

             <div class="col-6">
               <label>Data Pagamento</label>
               <h5>{{contrato.proximaFatura.dataPagamento || '-'}}</h5>
             </div>
           </div>

         </div>

       </div>


     </div>
     <div class="card-box com-borda text-left" *ngIf="contrato.pagaNoCartao">
       <div class="row">
         <div class="col-8">
           <label>Forma de pagamento</label>
           <h5><i class="fa fa-credit-card fa-2x"></i></h5>

           <label class="text-muted">  {{contrato.assinatura.cartao.bandeira}} - {{contrato.assinatura.cartao.numero}} </label>
         </div>
         <div class="col-4"  >
           <span  *ngIf ="!contrato.assinatura.cartao">
              <label>Cartão ainda não cadastrado</label><br>
              <button class="btn btn-primary" (click)="informarCartao()">Pagar com cartão</button>
           </span>
           <span   >
             <label>validade</label><br>
             <h5>{{contrato.assinatura.cartao.validade}}</h5>
           </span>

         </div>
       </div>
     </div>
   </div>

 </div>

  <div class="row mt-3">
    <div class="col">

      <div class="card com-borda text-left"  >
        <div class="card-header">
          Faturas anteriores
        </div>

        <div class="card-body">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Referência</th>
              <th>Vencimento</th>
              <th>Total</th>
              <th>Pagamento</th>
              <th>Forma de pagamento</th>
              <th></th>
            </tr>
            </thead>

            <tbody>
            <tr *ngIf="contrato.faturas?.length===0">
              <td colspan="7">
                <label class="text-muted "><i>Nenhuma fatura gerada</i></label>
              </td>
            </tr>

            <tr *ngFor="let fatura of contrato.faturas">
              <td>{{fatura.referencia}}</td>
              <td>{{fatura.dataVencimento}}</td>
              <td>{{fatura.total | currency: "BRL"}}</td>
              <td>{{fatura.dataPagamento}}</td>
              <td>{{fatura.formaDePagamento}}</td>
              <td>

                <i class="fa fa-barcode fa-2x cursor-pointer"  *ngIf="fatura.boleto" (click)="verFatura(fatura)"></i>


              </td>
            </tr>
            </tbody>

          </table>

          <label class="text-muted">NOTA: Exibimos apenas os últimos 12 meses pagos.      </label>
        </div>
      </div>

    </div>
  </div>

</div>
