import { Component, OnInit } from '@angular/core';
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {CadCupomComponent} from "../cad-cupom/cad-cupom.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {CadPromocaoComponent} from "../cad-promocao/cad-promocao.component";
import {PromocoesService} from "../services/promocoes.service";

@Component({
  selector: 'app-tela-promocoes',
  templateUrl: './tela-promocoes.component.html',
  styleUrls: ['./tela-promocoes.component.scss']
})
export class TelaPromocoesComponent implements OnInit {
  promocoes:  any = []
  carregando = false;
  modalDeletar: boolean;
  promocaoApagar: any;

  constructor(private dialogService: DialogService,
              private promocoesService: PromocoesService) { }

  ngOnInit(): void {
    this.carreguePromocoes()
  }

  editarPromocao(dataItem: any) {
    let copiaPromocao = Object.assign({}, dataItem)
    this.abraModalPromocao(copiaPromocao)
  }

  abraDialogRemover($event: RemoveEvent) {
    this.promocaoApagar = $event.dataItem;

    this.modalDeletar = true;
  }

  criarPromocao() {
    this.abraModalPromocao()
  }

  private abraModalPromocao(promocao: any = null) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Promoção ',
      content: CadPromocaoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaPromocao: CadPromocaoComponent = windowRef.content.instance;

    telaPromocao.windowRef = windowRef;

    if(promocao && promocao.id)
      telaPromocao.promocao = promocao;

    windowRef.result.subscribe((result: any) => {
      if(result && result.id)
        this.carreguePromocoes();
    });
  }

  desativarPromocao(promocao: any) {
    promocao.ativa = false;
    this.promocoesService.desativePromocao(promocao).then( () => {

    }).catch( erro => {
      promocao.ativo = true;
      alert(erro)
    })



  }

  ativarPromocao(promocao: any) {
    promocao.ativa = true;
    this.promocoesService.ativePromocao(promocao).then( () => {

    }).catch( erro => {
      promocao.ativa = false;
      alert(erro)
    })

  }
  close(cancel: string) {
    this.canceleRemocao()

  }

  confirmeRemocao() {
    let i = this.promocoes.indexOf(this.promocaoApagar);

    this.promocoesService.removaPromocao(this.promocaoApagar).then( () => {
      this.promocoes.splice(i, 1);
    }).catch( erro => {
      alert(erro)
    })

    this.canceleRemocao();

  }

  canceleRemocao() {
    this.modalDeletar = false;
    delete    this.promocaoApagar;

  }

  private carreguePromocoes() {
    this.carregando = true;
    this.promocoesService.listePromocoes({}).then( (resposta) => {
      this.carregando = false;
      this.promocoes = resposta;
    })
  }
}
