import {Component, Input, OnInit} from '@angular/core';
import {MensagemTextAreaUtils} from "../../lib/MensagemTextAreaUtils";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NgForm} from "@angular/forms";
import {TemplatesService} from "../../services/templates.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss']
})
export class FormTemplateComponent implements OnInit {
  @Input()
  template: any = {};
  campos = MensagemTextAreaUtils.campos

  windowRef: DialogRef;
  empresa: any;
  erro: any;
  public QTDE_MAXIMA_CARACTERES = 1000;
  constructor(private dialogService: DialogService, private constantsService: ConstantsService,
              private templatesService: TemplatesService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) { return; }

      this.empresa = empresa;
      this.alterouMensagem(this.template)
    });
  }

  fecheMensagemSucesso() {

  }

  adicioneCampo(textArea, template: any, campo: any) {
    MensagemTextAreaUtils.adicioneCampo(textArea, template, campo, this.dialogService, this.empresa)
    this.alterouMensagem(this.template)
  }

  alterouMensagem(template: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = template.mensagem.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(template.mensagem)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[LinkCartao]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if ( campo === '[LinkPedido]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if ( campo === '[Empresa]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[CODIGO]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[SALDO]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 10;
      } else if ( campo === '[LinkAtivarCartao]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      }
    }

    template.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - template.mensagem.length + qtdARemover - qtdAAdicionar;
  }

  onSubmit(frm: NgForm, template: any) {
    if ( !frm.valid ) {
      return;
    }

    this.templatesService.salveTemplate(template).then( resp =>   {
      if(resp.id) this.template.id = resp.id
      this.windowRef.close(this.template);

    }).catch((erro) => {
      this.erro = erro;
    })

  }

  impedirEspacos($event: KeyboardEvent) {
    let key = $event.key;
    if (key === ' ') {
      $event.preventDefault();
    }
  }
}
