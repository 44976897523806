import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {DisponibilidadeService} from "../services/disponibilidade.service";
import {ModalKendo} from "../../lib/ModalKendo";
declare var moment;

@Component({
  selector: 'app-cad-disponibilidade',
  templateUrl: './cad-disponibilidade.component.html',
  styleUrls: ['./cad-disponibilidade.component.scss']
})
export class CadDisponibilidadeComponent extends ModalKendo implements OnInit {
  @Input() catalogo: any;
  mensagemErro: string;
  mensagemSucesso: string;
  salvando: boolean;
  disponibilidade: any = {}
  disponibilidadesSemana  = [
    { dia: 0 , label: 'Dom', disponivel: false },
    { dia: 1 , label: 'Seg', disponivel: false },
    { dia: 2 , label: 'Ter', disponivel: false },
    { dia: 3 , label: 'Qua', disponivel: false },
    { dia: 4 , label: 'Qui', disponivel: false },
    { dia: 5 , label: 'Sex', disponivel: false },
    { dia: 6 , label: 'Sab', disponivel: false }
  ]


  constructor( private disponibilidadeService: DisponibilidadeService ) {
    super()
  }

  ngOnInit(): void {
    this.disponibilidade = { periodos: [  ]};
    this.novoPeriodo();
  }

  salveDisponibilidade(){
    let temErro = false;
    for(let periodo of this.disponibilidade.periodos){
      delete periodo.selecionarDiasSemana;

      let diaDisponivel: any = periodo.dias.find((item: any) => item.disponivel);

      if(!diaDisponivel){
        periodo.selecionarDiasSemana = true;
        temErro = true;
      }
    }

    if(temErro) return  ;

    this.salvando = true;
    this.disponibilidadeService.salveDisponibilidade(this.disponibilidade).then( (resp: any) => {
      this.salvando = false;
      this.fecheModal(resp)
    }).catch((erro) => {
      this.salvando = false;
      this.mensagemErro = erro
    })
  }

  setHorariosTurnos(){
   /* this.disponibilidades.forEach( (turno: any) => {
      turno.horaInicio = this.horarioToDate( turno.horaInicio)
      turno.horaFim = this.horarioToDate( turno.horaFim)
    }) */
  }

  private horarioToDate(horario: any): Date{
    if(!horario || typeof horario === 'object')
      return horario;

    let horarios: any =    horario.split(':');
    let date = new Date();
    date.setHours( horarios[0] );
    date.setMinutes( horarios[1] );

    return date;
  }

  horarioMinimoInicio() {
    return moment().startOf('day').toDate();
  }

  onSelectedChange(ds, selecionou) {
    ds.disponivel = selecionou;

  }

  novoPeriodo( ) {
    let novosDias  = this.disponibilidadesSemana.map(objeto => Object.assign({}, objeto));

    this.disponibilidade.periodos.push({ dias: novosDias})
    return false;
  }

  removaPeriodo(periodo: any, i: number){
    if(periodo.id){
      periodo.removido = true;
    } else {
      this.disponibilidade.periodos.splice(i, 1);
    }
    return false;
  }


}
