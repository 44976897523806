import { Component, OnInit } from '@angular/core';
import {CampanhaService} from "../../services/campanha.service";
import {ConstantsService} from "../ConstantsService";

@Component({
  selector: 'app-saldo-mensagens',
  templateUrl: './saldo-mensagens.component.html',
  styleUrls: ['./saldo-mensagens.component.scss']
})
export class SaldoMensagensComponent implements OnInit {
  saldo: any;
  empresa: any;

  constructor(private constantsService: ConstantsService, private campanhaService: CampanhaService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) { return; }

      this.empresa = empresa;

      this.campanhaService.obtenhaSaldo().then( (resposta) => {
        this.saldo = resposta;
      });
    });

    this.campanhaService.novoSaldoMensagens$.subscribe( (alterou) => {
      if( !alterou ) {
        return;
      }

      this.campanhaService.obtenhaSaldo().then( (resposta) => {
        this.saldo = resposta;
      });
    })
  }
}
