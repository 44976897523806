<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSubmit()" style="padding: 0px 15px;">

  <h4 class="text-center mb-2">
    <span *ngIf="clonarAdicional"><i class="far fa-clone"></i>&nbsp; Duplicar</span>
    <span *ngIf="!clonarAdicional"><i class="fas fa-link"></i>&nbsp; Usar</span>
    adicional de outro produto</h4>
  <label>Selecione o produto</label>

  <kendo-autocomplete   name="produtoCopiar" id="produtoCopiar"   class="form-control"
                        [data]="produtos" required
                        [filterable]="true"   [highlightFirst]="true" [popupSettings]="configuracoes" [(ngModel)]="produtoCopiar"
                        (filterChange)="search($event)"     [value]="produtoCopiar?.nome"     [loading]="buscando">
    <ng-template kendoAutoCompleteItemTemplate let-produto>
      <div class="row mt-1" style="width: 100%">
        <div class="col" style="max-width: 100px; min-height: 100px; ">
          <img [src]="'/images/empresa/' + produto.linkImagem" alt="Imagem"  style="width: 80px; min-height: 50px;" *ngIf="produto.linkImagem"  >
          <i *ngIf="!produto.linkImagem" class="fe-image fa-4x text-muted" ></i>
        </div>
        <div class="col">
          <b> {{produto.nome}} </b><br>
          <span>{{produto.descricao  }}</span> <br>
        </div>
      </div>
    </ng-template>
    <ng-template kendoAutoCompleteNoDataTemplate>
      <h5 class="mt-4">Nenhum produto encontrado<br />
      </h5>
    </ng-template>
  </kendo-autocomplete>

  <div class="mt-2">
    <label>Escolha quais adicionais copiar</label>

    <kendo-grid
      [kendoGridBinding]="produtoCopiar?.camposAdicionais"

      editable="incell"
      [loading]="buscando" style="min-height: 250px;"   >

      <kendo-grid-messages
        loading="Carregando"
        noRecords="Sem opções extras/adicionais"
      >
      </kendo-grid-messages>
      <kendo-grid-column title=" " width="70px">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <input type="checkbox" name="selecionada"  class="k-checkbox  "
                 kendoGridFocusable  [(ngModel)]="dataItem.selecionada" />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Nome'">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span>{{dataItem.nome}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Opçoes">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let opcao of dataItem.opcoesDisponiveis; let i = index;" class="mr-1">
          {{opcao.nome}}
        </span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>

    <p *ngIf="frm.submitted && !temAdicionaisSelecionados()" class="text-danger mt-2">
      <b>Selecione pelo menos 1 adicional</b>
    </p>
  </div>

  <div class="modal-footer mt-2">

    <p *ngIf="erro" class="text-danger mt-2 mb-2">
      <b> {{erro}}</b>
    </p>

    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="copiando"  >
      <i class="k-i-loading k-icon " *ngIf="copiando"> </i>
      Salvar</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="cancelar()"
            [disabled]="copiando" >Cancelar</button>
  </div>
</form>
