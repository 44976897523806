import {ApplicationRef, Component, OnInit} from '@angular/core';
import {ClienteService} from "../../../services/cliente.service";
import {ModalKendo} from "../../../lib/ModalKendo";


@Component({
  selector: 'app-modal-trocarcartao',
  templateUrl: './modal-trocarcartao.component.html',
  styleUrls: ['./modal-trocarcartao.component.scss']
})
export class ModalTrocarcartaoComponent extends ModalKendo implements OnInit {
  empresa: any = {}
  contrato: any = {}
  fatura: any;
  mensagemCopiada: string;
  mensagemAtivacao: string;
  exibirFechar: boolean;
  constructor(private clienteService: ClienteService,
              private app: ApplicationRef ) {
    super()
  }

  ngOnInit(): void {
    if(!this.fatura)
      this.fatura = this.contrato.proximaFatura || {}

    this.clienteService.obtenhaEmpresa().then( resposta => {
      this.empresa = resposta.empresa;
    });
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.mensagemCopiada = val;

    setTimeout( () => {
      delete this.mensagemCopiada
    }, 5000);
  }

}
