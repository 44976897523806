import {Component, Input, OnInit} from '@angular/core';
import {ComandaService} from "../../services/comanda.service";
import {ActivatedRoute} from "@angular/router";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {UsuarioService} from "../../services/usuario.service";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-tela-mesa-escolhida',
  templateUrl: './tela-mesa-escolhida.component.html',
  styleUrls: ['./tela-mesa-escolhida.component.scss']
})
export class TelaMesaEscolhidaComponent implements OnInit {
  @Input() mesa: any;
  @Input() garcom: any;
  empresa: any;
  idMesa: any;
  idGarcom: any;
  modoGarcom: boolean;
  codigoComanda: any;
  @Input() carregarMesa = true;
  carregou = false;

  constructor(private activatedRoute: ActivatedRoute, private constantsService: ConstantsService,
              private usuarioService: UsuarioService,  private autorizacaoService: AutorizacaoService,
              private comandasService: ComandaService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.idMesa = queryParams.idm;
      this.idGarcom = queryParams.idg;
      this.modoGarcom =  queryParams.mg;

      if(queryParams.cc)
        this.codigoComanda = queryParams.cc;

      this.constantsService.empresa$.subscribe( async (empresa) => {
        if(!empresa)     return;

        this.empresa = empresa;

        if(this.carregarMesa) {
          if (this.idMesa )
            this.mesa = await this.comandasService.obtenhaMesa(this.idMesa);

          if (this.modoGarcom){
            let operador =  this.autorizacaoService.getUser();
            this.garcom =   {id: operador.id, nome: operador.nome, codigoPdv: operador.codigoPdv};
            this.idGarcom = this.garcom.id
          }   else if(this.idGarcom) {
            this.garcom = await this.usuarioService.obtenhaGarcom(this.idGarcom) ;
          }
        }

        this.carregou = true;

      });
    });
  }

  carregueMesa(mesa: any) {
    this.mesa = mesa;
  }

  public obtenhaIdMesa() {
    return this.idMesa;
  }

  public obtenhaCodigoComanda(){
    return this.codigoComanda;
  }
  public obtenhaIdGarcom() {
    return this.idGarcom;
  }

  obtenhaMesa() {
    return this.mesa;
  }

  obtenhaGarcom(){
     return this.garcom
  }

  modoGarcomAtivo() {
    return this.modoGarcom
  }
}
