import {AfterViewInit, Component} from "@angular/core";
import {ModalKendo} from "../lib/ModalKendo";

@Component({
  template: ''
})
export class TelaExpandidaComponent extends ModalKendo implements AfterViewInit {
  ngAfterViewInit(): void {
    window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {tipo: 'FOCOU_INPUT'}}, "*");
  }
}
