import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {PedidosService} from "../../services/pedidos.service";
import {ModulosService} from "../../services/modulos.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {NotificacoesService} from "../../services/notificacoes.service";
import {WhatsappService} from "../../services/whatsapp.service";
import {ContatosService} from "../../services/contatos.service";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {AutorizacaoService} from "../../services/autorizacao.service";


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'acoes-modulos',
  templateUrl: './acoes-modulos.component.html',
  styleUrls: ['./acoes-modulos.component.scss']
})
export class AcoesModulosComponent implements OnInit {
  _contato: any;
  @Input() public novoContato: boolean;
  @Input() public cartao: any;
  @Output() public salvouContato = new EventEmitter<any>();
  @Input()
  set contato(value: any) {
    this._contato = value;
    if(value) {
      this.possuiPedido = value.telefone ? this.pedidoLocalService.possuiPedidoEmAndamento(value.codigoPais + value.telefone) : false
      this.temCartao =   value.cartoes && value.cartoes.length;
    }
  }

  get contato() {
    return this._contato || {};
  }

  msg = '';
  msgErro = '';
  temCartao = false;
  enviandoCardapio = false;
  mensagemPontuacao: any;
  codigoPontuacao: any;
  estaNoIframe: any;
  temModuloFidelidade: boolean;
  temModuloPedidos: boolean;
  possuiPedido: boolean;
  enviandoExtratoPontos: any;
  empresa: any = {};
  enviandoMensagemSaudacao: boolean;
  enviandoCodigoValidacao: boolean;
  constructor(private router: Router, private pedidosService: PedidosService, private modulosService: ModulosService,
              private constantsService: ConstantsService, private pedidoLocalService: PedidoLocalService,
              private autorizacaoService: AutorizacaoService,
              private notificacoesService: NotificacoesService, private whatsappService: WhatsappService,
              private contatosService: ContatosService) {
    this.constantsService.moduloFidelidade$.subscribe( data => this.temModuloFidelidade  = data  )
    this.constantsService.moduloPedido$.subscribe( data => this.temModuloPedidos  = data);

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;
    });
  }

  ngOnInit() {
    let state = window.history.state;
    if(state.mensagemPontuacao) this.mensagemPontuacao = state.mensagemPontuacao
    if(state.codigoPontuacao) this.codigoPontuacao = state.codigoPontuacao

    this.estaNoIframe = this.inIframe();

    if(this.contato && this.contato.telefone)
      this.possuiPedido = this.pedidoLocalService.possuiPedidoEmAndamento(this.contato.codigoPais + this.contato.telefone)

    this.temCartao =  this.contato && this.contato.cartoes && this.contato.cartoes.length;

  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  adcionarPontos(contato: any) {
    let state: any = {contato: contato};

    if(contato.cartoes && contato.cartoes.length === 1) state.cartao = contato.cartoes[0];

    this.router.navigateByUrl('/admin/contatos/pontos/adicionar' ,
      { state: state });
  }

  criarCartao(contato: any){
    this.router.navigateByUrl('/admin/contatos/novo',
      { state: { contato: contato }});
  }


  trocarPontos(contato: any, cartao: any) {
    let state: any = { contato: contato };

    if(contato.cartoes && contato.cartoes.length === 1) state.cartao = contato.cartoes[0];

    this.router.navigateByUrl('/admin/contatos/pontos/trocar' ,
      { state: state });
  }

  novoPedido(contato: any) {
    this.pedidoLocalService.remova(contato.codigoPais + contato.telefone);

    this.router.navigateByUrl('/admin/pedidos/novo?nome=' + this.contato.nome + '&tel=' + encodeURIComponent(this.contato.codigoPais
      + this.contato.telefone) +
      '&urlRetorno=' + encodeURIComponent(location.pathname + location.search),
      {state: {contato: this.contato}}).then( () => {});
  }

  continuarPedido(contato: any) {
    this.router.navigateByUrl('/admin/pedidos/novo?nome=' + this.contato.nome + '&tel=' +
      encodeURIComponent(this.contato.codigoPais + this.contato.telefone) +
      '&urlRetorno=' + encodeURIComponent(location.pathname + location.search),
      {state: {contato: this.contato}}).then( () => {});
  }

  enviarExtrato(contato: any) {
    this.enviandoExtratoPontos = true;

    this.notificacoesService.envieNotificacaoLinkExtrato(contato).then( resposta => {
      this.msg = "Link com extrato de pontos do cartão será enviado!";
    }).catch( (erro) => {
      this.msgErro = erro;
    });

    setTimeout( () => {
      this.enviandoExtratoPontos = false;
    }, 10000);
  }

  enviarLinkCardapio(contato: any) {
    this.enviandoMensagemSaudacao = true;

    window['root'].envieMensagemSaudacao(this.empresa, contato, true);

    setTimeout( () => {
      this.enviandoMensagemSaudacao = false;
    }, 5000);
  }

  enviarCodigoValidacao(contato: any){
    this.enviandoCodigoValidacao = true;

    window['root'].envieMensagemCodigoValidacao(this.empresa, contato, true);

    setTimeout( () => {
      this.enviandoCodigoValidacao = false;
    }, 5000);
  }

  enviarCardapio(contato: any) {
    this.enviandoCardapio = true;
    this.pedidosService.envieCardapio(contato).then( (resposta) => {
      this.msg = "Cardápio será enviado!";
      this.contato.id = resposta.id;

      this.salvouContato.emit(this.contato);
    }).catch( (erro) => {
      this.msgErro = erro;
    });

    setTimeout( () => {
      this.enviandoCardapio = false;
    }, 10000);
  }

  abraTelaNovoContato() {
    this.router.navigateByUrl('/admin/contatos/novo?nome=' + this.contato.nome + '&tel=' + this.contato.telefone);
  }

  verCartao(contato: any) {
    if(!this.cartao) alert('É necessário selecionar um cartão primeiro');
    this.router.navigateByUrl('/cliente/' + contato.id + "/" + this.cartao.id);
  }


  fecheMensagemSucesso() {
    this.mensagemPontuacao = null;
    this.codigoPontuacao = null;
  }

  bloquearContato(contato: any, bloquear: boolean) {
    this.contatosService.bloqueieContato(contato, bloquear).then( () => {
      contato.bloqueado = true;
    });
  }

  desbloquearContato(contato: any, bloquear: boolean) {
    this.contatosService.desbloqueieContato(contato, bloquear).then( () => {
      contato.bloqueado = false;
    });
  }

  temPermisaoAdicionarPontos(){
    if(!this.temModuloFidelidade) return false;

    return RotaGuardClient.adicionarPontosFidelidade( this.autorizacaoService.getUser())
  }

  temPermisaoResgatarBrindes(){
    if(!this.temModuloFidelidade) return false;

    return RotaGuardClient.resgatarBrindesFidelidade( this.autorizacaoService.getUser())
  }

}
