import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SelosComponent} from "../componentes/selo/selo.component";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {ContatosService} from "../services/contatos.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AutorizacaoService} from "../services/autorizacao.service";

import {ConstantsService} from "../fidelidade/ConstantsService";
import {ArmazenamentoService} from "../services/armazenamento.service";
import {BotsService} from "../services/bots.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-ver-contato-iframe',
  templateUrl: './ver-contato-iframe.component.html',
  styleUrls: ['./ver-contato-iframe.component.scss']
})
export class VerContatoIframeComponent implements OnInit, OnDestroy {
  acoes: any = [];
  cartoes: any = [];
  filtro: any = {cartao: null};
  contato: any = null;
  @ViewChild('selosCartao', { static: true } ) selosCartao: SelosComponent;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  abraDialogo: boolean;
  telefone: any;
  nomeContato: any;
  widget = false;
  usuario: any;
  empresa: any;
  pedidosEmAberto: any[];
  pedidosAnteriores: any[];
  carregou: boolean;
  novoPedido: any = {}
  msg: any = '';
  dev = false;
  assumirComandoBot: any;
  atendente = false;
  desativarParaSempre = false;
  statusMia = false;
  contatoPorDados: any = {};
  assinante: Subscription;

  constructor(  private  contatosService: ContatosService, private router: Router,
               private activatedRoute: ActivatedRoute, private _location: Location,
               private autorizacaoService: AutorizacaoService, private constantsService: ConstantsService,
               private armazenamentoService: ArmazenamentoService, private botsService: BotsService) {
    this.dev = (window.location.href.indexOf('localhost') !== -1);
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  calculeSeBotEstahAtivo(telefone: string) {
    if( !this.usuario || !telefone ) {
      return;
    }

    this.botsService.estahComAtedente(this.empresa, telefone).then( (respSessao: any) => {
      this.atendente = respSessao.data.atendente;
      this.desativarParaSempre = respSessao.data.desativarParaSempre;

      const configuracoesMia = respSessao.data.mia;

      if( configuracoesMia ) {
        this.statusMia = (configuracoesMia.status !== 'DESATIVADA');
      }
    });
  }

  ngOnInit() {
    this.msg = this.activatedRoute.snapshot.queryParams.msg;
    this.novoPedido = { guid: this.activatedRoute.snapshot.queryParams.codigo};

    let usuarioLogado = this.autorizacaoService.getUser();

    if( usuarioLogado != null )
      this.usuario = usuarioLogado;
    else {
      this.usuario = {};
    }

    this.autorizacaoService.usuarioLogado$.subscribe( (usuario) => {
      if( !usuario ) return;

      this.usuario = usuario;
    });

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) { return; }

      this.empresa = empresa;

      this.assinante = this.botsService.eventoEstadoMia$.subscribe( (dados: any) => {
        if( this.contato ) {
          this.calculeSeBotEstahAtivo(this.contato.codigoPais + this.contato.telefone);
        }
      });

      this.activatedRoute.queryParams.subscribe(queryParams => {
        this.nomeContato = this.activatedRoute.snapshot.queryParams.nome;


        if( this.nomeContato === 'undefined' || this.nomeContato === undefined ) {
          this.nomeContato = '';
        }

        this.widget = this.inIframe();

        const { contato } = window.history.state;

        if (contato) {
          this.setContato(contato);
        }
        else {
          this.telefone = decodeURIComponent(this.activatedRoute.snapshot.params['zap']);
          this.contatoPorDados = this.gereNovoContato(this.nomeContato, this.telefone)
          this.contatosService.obtenhaContato(this.telefone.toString()).then( (objContato: any) => {
            this.setContato(objContato);
          });
        }

        this.calculeSeBotEstahAtivo(this.activatedRoute.snapshot.params['zap']);
      });
    });
  }

  salveContato(contato: any) {
    this.contato = contato;
  }

  gereNovoContato(nomeContato: string, telefoneCompleto: string) {

    const { codigoPais, telefone } = this.contatosService.extraiCodigoPaisETelefone(telefoneCompleto)

    return { nome: nomeContato,
      codigoPais: codigoPais,
      telefone: telefone
    }
  }


  setContato(contato: any) {
    this.contato = contato;

    let estadoAnterior: any = JSON.parse(this.armazenamentoService.carregue('url_' + this.telefone))  ;

    if(estadoAnterior) {
      this.router.navigateByUrl(estadoAnterior.url, {state: estadoAnterior.state});
      return;
    }

    this.carregou = true;
    if( !contato ) {
      return;
    }

    this.cartoes = contato.cartoes;
    this.cartoes.forEach( cartao => cartao.descricao = cartao.plano.nome)
  }

  voltar() {
    this._location.back();
  }

  recarregue() {
    window.location.reload();
  }

  imprimaPedido(pedido: any) {
    window.open('/imprimir/pedido/' + pedido.guid);
  }

  digitouMensagemBot($event: any) {
    const mensagem = $event.target.value;

    window['root'].novasMensagens([mensagem]);

    $event.target.value = '';
  }

  alterouStatusBot() {

  }

  ngOnDestroy(): void {
    this.assinante.unsubscribe();
  }
}
