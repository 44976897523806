import {TipoDePagamentoEnum} from "./TipoDePagamentoEnum";
import {ConfigFormaDePagamento} from "./ConfigFormaDePagamento";
import {TaxaCobranca} from "./TaxaCobranca";
import {FormaDePagamentoIntegrada} from "../../../../server/domain/integracoes/FormaDePagamentoIntegrada";

export class FormaDePagamento {
  id: number;
  nome: string;
  descricao: string;
  exibirCardapio = false;
  empresa: any;
  online = false;
  configMeioDePagamento: ConfigFormaDePagamento;
  tipoDePagamento: string;
  referenciaExterna: string;
  formaIntegrada: FormaDePagamentoIntegrada;
  bandeirasCartaoIntegrada: any = [];
  opendeliveryMethod: string;
  opendeliveryBrand: string;
  taxaCobranca: TaxaCobranca;
  possuiDesconto: boolean;
  cobrarTaxaRetorno: boolean;
  desconto: number
  numeroParcelasFixas: number
  pix: boolean;
  voucher: boolean;
  enviarComoDesconto: boolean
  notificarConfirmacaoPagamento: boolean;
  notificarNovoPedido: boolean;
  habilitarRetirada: boolean;
  habilitarEntrega: boolean;
  formaDePagamentoPdv: any;
  constructor() {
    this.configMeioDePagamento = new ConfigFormaDePagamento();
    this.notificarConfirmacaoPagamento =  true;
    this.notificarNovoPedido = true;
  }

  ehPagamentoEmDinheiro() {
    return this.nome === TipoDePagamentoEnum.Dinheiro;
  }
}
