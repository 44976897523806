import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PerformanceWhatsappService extends ServerService {

  constructor(http: HttpClient) {
    super(http);
  }

  busqueRelatorio(inicio: any, fim: any) {
    return this.obtenha('/notificacoes/performance-whatsapp?di=' + inicio + '&df=' + fim, {});
  }
}
