import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AutorizacaoService} from "../services/autorizacao.service";

@Injectable({
  providedIn: 'root'
})
export class AuthRedeGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (location.pathname.indexOf('/admin/assinatura/pagamento') === 0 ||
      location.pathname.indexOf('/admin/assinatura/trocarcartao') === 0) return true;

    return this.autorizacaoService.estaLogado().then((resposta: any) => {

      if (!resposta.logado) {
        this.router.navigate(['/admin-rede/login'], {queryParams: {t: state.url}}).then(() => {
        });
      }

      console.log('AuthGuard')
      console.log(resposta)

      return true;
    });
  }
}
