<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>

  <div class="row">
    <div class="form-group mb-3 col" style="max-width: 250px">
      <label for="nome">Nome</label>
      <input type="text" class="form-control" autocomplete="off" appAutoFocus [autoFocus]="true"
             id="nome" name="nome" [(ngModel)]="mesa.nome" #nome="ngModel"
             placeholder="Nome da Mesa" value="" required/>
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome da Mesa é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3 col-4"  *ngIf="empresa?.integracaoDelivery && !this.escolherMesaIntegrada()"  >
      <label  >  {{ empresa.integracaoDelivery.configuracoesEspecificas &&
                    empresa.integracaoDelivery.configuracoesEspecificas.integrarComComandas ? ' Codigo Comanda' :" Codigo PDV" }}

            <b>  {{ empresa?.integracaoDelivery?.sistema }}</b>
      </label>
      <input type="text" class="form-control" autocomplete="off"
             name="codigoPdv" [(ngModel)]="mesa.codigoPdv" #codigoPdv="ngModel"
             placeholder="Cod. PDV"  >
      <div class="invalid-feedback">
        <p *ngIf="codigoPdv.errors?.required">Código é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3 col-4"  *ngIf="escolherMesaIntegrada()" style="min-width: 240px" >
      <label  >  {{ empresa.integracaoDelivery.configuracoesEspecificas &&
      empresa.integracaoDelivery.configuracoesEspecificas.integrarComComandas ? ' Codigo Comanda' :" Codigo Mesa" }} no Ecletica
      </label>

      <kendo-combobox name="codigoMesa" #codigoMesa="ngModel" [data]="mesasIntegracao" style="width: 140px; display: inline-block;"
                      class="form-control"     [allowCustom]="false"  [clearButton]="false" [required]="true"
                      [(ngModel)]="mesa.mesaIntegrada" [valueField]="'num'"   [textField]="'num'">
      </kendo-combobox>

      <div style="display: inline-block;" >
        <i class="k-icon k-i-loading ml-2  " [hidden]="!carregandoMesas" ></i>

        <i class="k-icon k-i-search ml-2 cpointer" (click)="selecioneMesaDoNumero()"
           kendoTooltip title="encontrar mesa com mesmo codigo" [hidden]="carregandoMesas"></i>
      </div>

      <div class="invalid-feedback">
        <p *ngIf="codigoMesa.errors?.required"><Campo></Campo> é obrigatório</p>
      </div>


    </div>

  </div>

  <div class="row">
    <div class="form-group mb-3 col">
      <input id="naoGerarComanda" name="naoGerarComanda" type="checkbox"    kendoCheckBox
             [(ngModel)]="mesa.naoGerarComanda"      class="k-checkbox"/>
      <label for="naoGerarComanda" class="ml-1"  >
        Não gerar comanda
      </label>
    </div>
  </div>

  <div class="row">
    <div class="form-group mb-3 col">
      <input id="somenteLeitura" name="somenteLeitura" type="checkbox"    kendoCheckBox
             [(ngModel)]="mesa.somenteLeitura"      class="k-checkbox"/>
      <label for="naoGerarComanda" class="ml-1"  >
        Somente leitura
      </label>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="enviando" >Salvar</button>
    <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
    <button type="button" (click)="fechar()" primary="true"  class="btn btn-light waves-effect">Fechar</button>
  </div>
</form>
