import {Component, Input, OnInit} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";

@Component({
  selector: 'app-sincronizar-pagamento-online',
  templateUrl: './sincronizar-pagamento-online.component.html',
  styleUrls: ['./sincronizar-pagamento-online.component.scss']
})
export class SincronizarPagamentoOnlineComponent implements OnInit {
  @Input() pedido: any = {}
  constructor(private  pedidosService: PedidosService) {

  }

  ngOnInit(): void {
  }


  async sincronizarPagamentoOnline(pedido: any, $event: any) {

    pedido.sincronizandoPagamento = true;

    this.pedidosService.sincronizePagamentoOnline(pedido).then((resposta: any) => {
      pedido.sincronizandoPagamento = false;
      if(resposta && resposta.id)
        Object.assign(pedido, resposta);
    }).catch((err) => {
      pedido.sincronizandoPagamento = false;
    });

    $event.stopPropagation();
    $event.preventDefault();

    return false;
  }

  getStatusPagamentoOnline(pedido: any){
    if(pedido.pagamentos){
      let pagamentoOnline = pedido.pagamentos.find((item: any) => item.formaDePagamento.online);

      return pagamentoOnline ? pagamentoOnline.status : ''
    }

  }

}
