<div *ngIf="!empresa.dark && empresa.modulos && empresa.modulos.length > 0 && possuiFidelidade">
  <h4>Cartão de Fidelidade</h4>
  <div class="card contato">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="row" *ngIf="cartoes.length > 0">
            <div *ngFor="let cartao of cartoes; let ultimo = last;" [class.col-6]="!ultimo || (cartoes.length % 2 == 0)" class="mt-2"
            [class.col-12]="ultimo && (cartoes.length % 2 != 0)">
              <div class="pontos" style="text-align: center" [class.acumulo_pontos]="(cartao.plano.tipoDeAcumulo === 'Pontos' || cartao.plano.tipoDeAcumulo === 'Reais')">
                <div>
                  <!--<h2>{{cartao.pontos | number}}</h2>-->
                  <label>{{cartao.plano.tipoDeAcumulo}}</label>

                  <app-selo [selo]="{n: cartao.pontos, on: true}" *ngIf="cartao.plano.tipoDeAcumulo === 'Selos'"></app-selo>

                  <div class="pontos-interno" *ngIf="cartao.plano.tipoDeAcumulo === 'Pontos'">
                    {{cartao.pontos | number}}
                  </div>

                  <div class="pontos-interno" *ngIf="cartao.plano.tipoDeAcumulo === 'Reais'">
                    {{cartao.pontos | currency: 'BRL'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
