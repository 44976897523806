
import {Component, OnInit, ViewChild} from '@angular/core';
import {BrindeService} from '../services/brinde.service';
import {BuscarContatoComponent} from '../componentes/buscar-contato/buscar-contato.component';
import {Form, NgForm} from "@angular/forms";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {ClienteService} from "../services/cliente.service";
import {TipoDeNotificacaoEnum} from "../../../server/domain/TipoDeNotificacaoEnum";
import {NotificacoesService} from "../services/notificacoes.service";
import {Location} from "@angular/common";
import {AutorizacaoService} from "../services/autorizacao.service";
import {Router} from "@angular/router";
import {TelaExpandidaComponent} from "../pedidos/TelaExpandida";


@Component({
  selector: 'app-trocar-pontos',
  templateUrl: './trocar-pontos.component.html',
  styleUrls: ['./trocar-pontos.component.scss']
})
export class TrocarPontosComponent extends TelaExpandidaComponent implements OnInit {
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  contato: any = {};
  cartao: any = {};
  enviando = false;
  mensagemSucesso = '';
  brindeEscolhido: any = null;
  mensagemErro = '';
  idEmpresa: string;
  mensagem = '';
  saldoUtilizar = 0;
  codigoTroca: string;
  bloqueado: string;
  @ViewChild('buscarContato', { static: true } ) public buscarContatoComponent: BuscarContatoComponent;
  @ViewChild('f', {static: true}) public frmTrocarPontos: NgForm;
  private enviado: boolean;

  constructor(private brindeService: BrindeService, private _location: Location,
              private  autorizacaoService: AutorizacaoService,  private router: Router,
              private  notificacoesService: NotificacoesService, private clienteService: ClienteService) {
    super();
  }

  ngOnInit() {
    this.buscarContatoComponent.onContatoBuscado = (contatoBuscado: any) => {
       if(contatoBuscado && contatoBuscado.id){
         this.exibaContato(contatoBuscado, contatoBuscado.cartoes[0]);
       } else {
         this.exibaContato({}, null)
       }

    };
    this.idEmpresa = this.clienteService.obtenhaIdEmpresa();

    const { contato, cartao } = window.history.state;

    if(contato) this.exibaContato(contato, cartao);

    this.autorizacaoService.estaLogado().then( (resposta: any) => {
      this.bloqueado = resposta.bloqueado;
      if (!resposta.logado)
        this.redirecioneTelaLogin();
    });
  }

  redirecioneTelaLogin(){
    this.router.navigateByUrl('/admin/login?t=' + window.location.pathname);
  }

  trocarPontosSubmit() {
    this.enviado = true;
    this.buscarContatoComponent.valide();
    if ( !this.contato || this.frmTrocarPontos.invalid ) {
      return;
    }
    const parametros = {
      ict: this.cartao.id,
      idb: this.brindeEscolhido.id,
      msg: this.mensagem,
      sau: this.saldoUtilizar
    };

    this.enviando = true;
    this.mensagemErro = null;
    this.mensagemSucesso = null;

    this.brindeService.trocarPontos(parametros).then( (resposta) => {
      this.enviando = false;
      this.cartao.pontos = resposta.novaPontuacao;
      this.codigoTroca = resposta.codigoTroca;
      this.mensagemSucesso = 'Troca realizada, saldo restante: ' + resposta.novaPontuacao +  ' ' + this.cartao.plano.tipoDeAcumulo;
      this.limpeFormulario();
    }).catch( (erro) => {
      this.enviando = false;
      this.mensagemErro = erro;
    });
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = null;
    this.frmTrocarPontos.resetForm();
  }

  private limpeFormulario() {
    this.brindeEscolhido = null;
    this.saldoUtilizar = 0;
    let mensagemFinal = this.mensagem;
    let cartaoAtual = this.cartao;

    this.frmTrocarPontos.resetForm();

    setTimeout( () => {
      this.mensagem = mensagemFinal;
      this.cartao = cartaoAtual;
    })
  }

  fecheMensagemErro() {
    this.mensagemErro = null;
  }

  exibaContato(contato: any, cartao: any) {
    this.contato = contato;
    this.cartao = cartao || {};
    this.buscarContatoComponent.exibaContato(contato);

    this.notificacoesService.obtenhaMesagemProcessada(this.contato, TipoDeNotificacaoEnum.ResgatouCartao).then( resposta => {
      this.mensagem = resposta.mensagemFinal;
    })

    if(this.contato.cartoes && this.contato.cartoes.length){
      this.contato.cartoes.forEach( ( cc: any ) => {
        cc.nomePlano = cc.plano.nome;
      })
      this.setBrindes();
    }
  }

  setBrindes() {
    if( !this.cartao.plano ) {
      return;
    }

    this.brindeService.listeDoPlano(this.cartao.plano).then( (brindes) => {
      this.cartao.brindes = brindes;
      if(this.ehCashback() || brindes.length === 1) this.brindeEscolhido = brindes[0];
    })
  }

  alterouPlano() {
    if(this.cartao && !this.cartao.brindes)
      this.setBrindes();
  }

  ehCashback() {
    return this.cartao && this.cartao.plano && this.cartao.plano.tipoDeAcumulo === 'Reais';
  }

  voltar() {
    this._location.back();
  }

  fechar() {
    this._location.back();
  }
}
