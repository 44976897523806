import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {IntegracaoERPService} from "../../services/integracaoerp.service";

@Component({
  selector: 'app-form-integracaopedidos',
  templateUrl: './form-integracaopedidos.component.html',
  styleUrls: ['./form-integracaopedidos.component.scss']
})
export class FormIntegracaopedidosComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('frm', {}) frm:  any;
  lojas  = []
  mensagemErro: string;
  integracoes = [
    { id: 2, descricao: 'Ecletica' , sistema: 'ecletica', tipo: 'ERP' },
    { id: 7, descricao: 'Saipos', sistema: 'saipos', tipo: 'ERP'},
    { id: 9, descricao: 'GCOM ERP', sistema: 'gcom', tipo: 'ERP'},
    { id: 5, descricao: 'Menew', sistema: 'menew', tipo: 'ERP'},
    { id: 3, descricao: 'Tiny' , sistema: 'tiny', tipo: 'ERP' },
    { id: 8, descricao: 'Teknisa', sistema: 'teknisa', tipo: 'ERP'},
    { id: 10, descricao: 'TOTVS Chef', sistema: 'totvs', tipo: 'ERP'},
    { id: 6, descricao: 'Bluesoft', sistema: 'bluesoft', tipo: 'ERP'},
    { id: 4, descricao: 'RP Info', sistema: 'RPInfo', tipo: 'ERP'},
    //// { id: 10, descricao: 'Click Entregas' , sistema: 'clickentregas', tipo: 'Delivery' }
  ]
  integracaoDelivery: any = {
    configuracoesEspecificas: {}
  };
  salvando = false;
  removendo = false;
  carregandoLojas = false;
  confirmarRemover: any;
  chinas = []
  gendais = []
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  constructor(private  empresaService: EmpresasService, private integracaoERPService: IntegracaoERPService) { }

  ngOnInit(): void {
    if(this.empresa.integracaoDelivery){
      this.integracaoDelivery = Object.assign({}, this.empresa.integracaoDelivery )
      this.integracaoDelivery.integracao = this.integracoes.find( (integracao) => integracao.sistema ===  this.integracaoDelivery.sistema)

      if(this.integracaoDelivery.configuracoesEspecificas){
        Object.keys(this.integracaoDelivery.configuracoesEspecificas).forEach(key => {
          this.integracaoDelivery[key] = this.integracaoDelivery.configuracoesEspecificas[key]})
      }
    }

    this.empresaService.listeLojasChinnas().then( (resp) => {
      this.chinas = resp;
    })

    this.empresaService.listeLojasGendais().then( (resp) => {
      this.gendais = resp;
    })
  }

  onSubmit() {
    delete this.mensagemErro;

    if (this.frm.valid) {
      this.salvando = true;
      this.integracaoDelivery.sistema = this.integracaoDelivery.integracao.sistema;
      this.integracaoDelivery.tipo = this.integracaoDelivery.integracao.tipo;
      this.empresaService.salveIntegracaoDelivery(this.empresa, this.integracaoDelivery).then( (integracao: any) => {
        this.integracaoDelivery.id = integracao.id;
        this.integracaoDelivery.data = integracao.data;
        this.empresa.integracaoDelivery = this.integracaoDelivery;
        this.integracaoDelivery.editar = false;
        this.salvando = false;
      }).catch( (erro) => {
        this.mensagemErro = erro;
        this.salvando = false
      })
    }
  }

  confirmeRemover() {
    this.confirmarRemover = true;
  }

  editarIntegracao(){
    this.integracaoDelivery.editar = true;
    this.carregueLojas();
  }

  canceleRemover() {
    this.confirmarRemover = false;
  }

  removerIntegracao() {

    this.removendo = true;

    this.empresaService.removaIntegracaoDelivery(this.empresa).then(  () => {
      this.removendo = false;
      delete  this.empresa.integracaoDelivery;
      this.integracaoDelivery = {}
    }).catch((erro) => {
      this.removendo = false;
      alert(erro)

    })

  }

  selecionouLojaGendai() {
    delete this.integracaoDelivery.unidadeChina;
  }

  selecionouLojaChina() {
    delete this.integracaoDelivery.unidadeGendai;
  }

  temToken(){
    if(!this.integracaoDelivery.integracao) return false;

    let sistema = this.integracaoDelivery.integracao.sistema;

    return sistema   === 'foodydelivery'  ||  sistema === 'tiny' ||  sistema === 'bluesoft' || sistema === 'teknisa';
  }

  carregueLojas(){
    if( this.carregandoLojas || !this.integracaoDelivery.token ) return;

    if(!this.integracaoDelivery.configuracoesEspecificas || !this.integracaoDelivery.configuracoesEspecificas.grupo ) return;

    if(this.selecionarLoja() && !this.lojas.length){
      this.carregandoLojas = true;
      this.integracaoERPService.listeLojas(this.integracaoDelivery.token,
        this.integracaoDelivery.configuracoesEspecificas.grupo).then( (lojas) => {
        this.lojas = lojas;
        this.carregandoLojas = false;

        if( this.integracaoDelivery.loja)
          this.integracaoDelivery.loja = this.lojas.find((item: any) => item.lojaKey ===    this.integracaoDelivery.loja.id )

      }).catch((erro) => {
        this.carregandoLojas = false;
        alert('Nao foi possivel carregar lojas: ' + erro)
      })
    }
  }

  selecionarLoja(){
    if(!this.integracaoDelivery.integracao) return false;

    let sistema = this.integracaoDelivery.integracao.sistema;

    return  sistema === 'bluesoft';
  }

  selecionouLoja(){

  }

}
