<h4   class="page-title"><i class="fa fa-table"></i> Mesas</h4>


<div *ngIf="this.mesasBloqueadas" class="card mt-4">
  <div class="card-body">
    <div class="alert alert-warning">
      <h4>
        <i class="fas fa-exclamation-triangle"></i>
        Sistema parceiro "<b>{{empresa.integracaoDelivery.sistema}}</b>" não tem suporte a pedidos mesa
      </h4>
    </div>
  </div>

</div>


<div class="card" [hidden]="mesasBloqueadas">

  <div class="card-body">
    <ng-container *ngIf="modoGarcom">
      <app-pedidos-mesa></app-pedidos-mesa>
    </ng-container>

    <kendo-tabstrip   class="nav-bordered mt-3" *ngIf="!modoGarcom" >
      <kendo-tabstrip-tab [title]="'Com erro'" [selected]="true" *ngIf="comErros">
        <ng-template kendoTabContent>
           <app-pedidos-mesas-com-erro></app-pedidos-mesas-com-erro>
        </ng-template>
      </kendo-tabstrip-tab>

      <ng-container *ngIf="!comErros">
        <kendo-tabstrip-tab [title]="'Em aberto'" [selected]="true">
          <ng-template kendoTabContent>
            <app-pedidos-mesa></app-pedidos-mesa>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Finalizados'" >
          <ng-template kendoTabContent>
            <app-tela-pedidos-pagos [mesa]="true"></app-tela-pedidos-pagos>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Cancelados'" >
          <ng-template kendoTabContent>
            <app-tela-pedidos-cancelados [mesa]="true"></app-tela-pedidos-cancelados>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Configuracoes'" >
          <ng-template kendoTabContent>
            <app-cadastro-mesa></app-cadastro-mesa>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Garçons'" *ngIf="possuiModuloGarcom || empresa?.integracaoDelivery?.sistema ==='ecletica' ">
          <ng-template kendoTabContent>
            <app-garcons-empresa   [empresa]="empresa" [moduloGarcom]="possuiModuloGarcom"  ></app-garcons-empresa>
          </ng-template>
        </kendo-tabstrip-tab>
      </ng-container>

    </kendo-tabstrip>

  </div>


</div>



