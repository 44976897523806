<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4> {{titulo}}  </h4>

</kendo-dialog-titlebar>

<div #pnl class="modal-body">
  <button class="btn btn-blue btn-sm float-right" (click)="copieJson()">copiar</button>
  <div class="clearfix"> </div>
  <div class="json">
    <app-json-display [data]="payload"></app-json-display>
  </div>
</div>
