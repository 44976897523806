import { Component, OnInit } from '@angular/core';
import {Mesa} from "../objetos/Mesa";
import {ActivatedRoute, Router} from "@angular/router";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {MesaService} from "../../services/mesa.service";
import {ComandaService} from "../../services/comanda.service";
import {PedidosService} from "../../services/pedidos.service";

@Component({
  selector: 'app-tela-trocar-mesa',
  templateUrl: './tela-trocar-mesa.component.html',
  styleUrls: ['./tela-trocar-mesa.component.scss']
})
export class TelaTrocarMesaComponent implements OnInit {
  mesas: Array<Mesa>;
  empresa: any;
  nomeMesa: any;
  mesaEscolhida: any = {};
  idComanda: any;
  comanda: any = {};
  msgErro = '';

  constructor(private router: Router, private constantsService: ConstantsService,
              private mesaService: MesaService, private activatedRoute: ActivatedRoute,
              private comandaService: ComandaService, private pedidosService: PedidosService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( (params) => {
      this.nomeMesa = params.mesa;
      this.idComanda = params.idc;

      this.constantsService.empresa$.subscribe( (empresa) => {
        if( !empresa ) { return; }

        this.empresa = empresa;

        this.mesaService.obtenhaMesas(this.empresa).then( (mesas) => {
          this.mesas = mesas;

          this.pedidosService.obtenhaComandaComoPedido(this.idComanda).then( (comanda: any) => {
            this.comanda = comanda;
          });
        });
      });
    });
  }

  voltar() {

  }

  mesaFoiEscolhida(mesa: Mesa) {
    this.mesaEscolhida = mesa;
    this.msgErro = '';
  }

  trocaMesa() {
    this.msgErro = '';

    if( !this.mesaEscolhida.id ) {
      this.msgErro = 'Mesa deve ser escolhida';
      return;
    }

    this.comandaService.trocarMesaComanda(this.idComanda, this.mesaEscolhida).then( (resposta) => {
      this.router.navigateByUrl('/admin/pedidos-mesas', {}).then( () => {});
    });
  }
}
