<div class="modal-content">
  <div class="modal-header" >
    <h4 class="modal-title" id="myModalLabel">
       Confirmação cancelamento entregador
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">

    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}" novalidate #frm="ngForm"
          (ngSubmit)="submitForm(frm)">
      <div class="row">
        <div class="col">
            <p class="mb-1 ">
                     Entrega codigo:
                        <span class="text-primary mr-2 cpointer" (click)="verDelivery()">
                          {{pedido.delivery.codigo}}
                        </span>
                        <b>  feita ás {{pedido.delivery.horario | date: 'HH:mm'}}</b>
           </p>

             <p  class="">  status:  <span class="badge badge-primary">{{pedido.delivery.descricaoStatus}}</span></p>
        </div>
      </div>
      <div class="row mb-2" *ngIf="pedido.delivery.entregador">
        <div class="col">
          Entregador: <b>{{pedido.delivery.entregador.name}}</b>
        </div>
      </div>
      <ng-container *ngIf="exigeMotivo()">
        <div class="row" >
          <div class="col-7">
            <div class="form-group    "   >
              <label >Razão</label>
              <kendo-combobox  name="razao" [(ngModel)]="razao" [data]="razoes" required
                               placeholder="Selecione um razão" class="form-control" [textField]="'descricao'" >

              </kendo-combobox>
              <div class="invalid-feedback" *ngIf="!razao">
                Campo obrigatório
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="form-group   "   >
              <label >Açao</label>
              <kendo-combobox  name="acao" [(ngModel)]="acao" [data]="acoes" required  [textField]="'descricao'"
                               placeholder="Selecione um açao" class="form-control" >

              </kendo-combobox>
              <div class="invalid-feedback" *ngIf="!acao">
                Campo obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col">
            <div class="form-group  ">
              <label for="motivo"><i class="fas fa-comment-dots"></i>Aglum outro detalhe?</label>

              <textarea type="text" class="form-control" autocomplete="off"  minlength="5"
                        id="motivo" name="motivo" [(ngModel)]="motivo"
                        placeholder="Descreva algo que queira informar ao parceiro" value=""  >
             </textarea>


            </div>
          </div>
        </div>

      </ng-container>


      <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="erro">
        <i class="mdi mdi-check-all mr-2"></i> {{erro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <p class="text-black-50 mt-2 " *ngIf="aguardandoParceiro">
        <b> Enviando solicitação cancelamento para {{pedido.doIfood || pedido.delivery.origem ==='ifood' ? 'Ifood' : 'PDV'}}, aguarde!</b>
      </p>

      <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="motivoRejeicao">
        <i class="mdi mdi-check-all mr-2"></i>
        Solicitação cancelamento não foi aceita: {{motivoRejeicao}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <div *ngIf="ignorarParceiro" class="alert alert-warning"  >
         <b> Se a entrega já estiver cancelada no parceiro você pode cancelar somente aqui.</b>
        <br>
        <button  class="btn btn-danger   waves-effect waves-light btn-atualizar mt-2"   type="button"
                 [disabled]="cancelando" (click)="cancelarSomenteMeucarpadio()" >

          <i class="k-i-loading k-icon" *ngIf="cancelando"></i>
          Quero cancelar somente no Meucardapio
        </button>

      </div>

      <button  class="btn btn-blue   waves-effect waves-light btn-atualizar mt-2"   type="submit"
               [disabled]="cancelando || aguardandoParceiro" >
        <i class="fas fa-sync" *ngIf="!cancelando && !aguardandoParceiro"></i>
        <i class="k-i-loading k-icon" *ngIf="cancelando || aguardandoParceiro"></i>
        Cancelar Entregador
      </button>




    </form>


  </div>

</div>
