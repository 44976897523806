import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MensagemService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  obtenhaMensagensObservable(pagina: number, filtro: any): Observable<any> {
    const params: any = {p: pagina};

    for( let i = 0 ; i < filtro.filters.length; i ++ ) {
      var filtroCorrente = filtro.filters[i];

      params[filtroCorrente.field] = filtroCorrente.value
    }

    return this.http.get('/mensagem/liste', {params: params});
  }

  obtenhaResumo(filtro: any) {
    const params: any = {};

    for( let i = 0 ; i < filtro.filters.length; i ++ ) {
      const filtroCorrente = filtro.filters[i];

      params[filtroCorrente.field] = filtroCorrente.value
    }

    return this.obtenha('/mensagem/resumo', params);
  }

  obtenhaMensagens(pagina: number) {
    return this.obtenha('/mensagem/liste', {p: pagina});
  }

  envieNovamente(mensagem: any) {
    return this.salve('/mensagem/envieNovamente', {idm: mensagem.id});
  }
}
