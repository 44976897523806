import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class IntegracaoOpendeliveryService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  salveParametrizacaoMerchant(integracao: any){
    return this.facaPost('/opendelivery/merchant/params', integracao)
  }

  ativeIntegracaoComerciante(integracao){
    return this.facaPost('/opendelivery/merchant/ative', integracao)
  }
  desativeIntegracaoComerciante(integracao){
    return this.facaPost('/opendelivery/merchant/desative', integracao)
  }

  salveParametrizacaoLogistica(integracao: any){
    return this.facaPost('/opendelivery/logistica/params', integracao)
  }


  ativeIntegracaoLogistica(integracao: any){
    return this.facaPost('/opendelivery/logistica/ative', integracao)
  }

  desativeIntegracaoLogisca(integracao: any){
    return this.facaPost('/opendelivery/logistica/desative', integracao)
  }


  gereChaves(){
    return this.facaPost('/opendelivery/orderapp/keys', {})
  }
}
