<h4   class="page-title">
  <i class="fe-bar-chart-2"></i>
  Templates de Prompt</h4>

<div class="row mt-4" *ngIf="carregando">
  <div class="col text-center">
    <i class="k-icon k-i-loading mr-1 h1"></i>
  </div>
</div>

<ng-container *ngIf="templatePrompts.length > 0">
  Prompts: <b>{{templatePrompts.length}}</b> templates
  <kendo-listview [data]="templatePrompts">
    <ng-template kendoListViewItemTemplate let-dataItem>
      <div class="p-2">
        <h4 class="mt-0"><i class="far fa-file-alt"></i>

          {{ dataItem.nome }}</h4>
        <p>{{ dataItem.descricao }}</p>
        <div style="background: #f7f8f8" class="p-2 border-2">
          <app-controle-listagem-trecho-prompt [lista]="dataItem.trechosDePrompt"></app-controle-listagem-trecho-prompt>
        </div>
        <div class="mt-2">
          <button class="btn btn-sm btn-primary" (click)="editarTemplatePrompt(dataItem)">Editar</button>
        </div>
      </div>
      <hr>
    </ng-template>
  </kendo-listview>
</ng-container>
