import {AfterViewInit, ApplicationRef, Component, OnInit, ViewChild} from '@angular/core';
import {ListaDePedidosComponent} from "../lista-de-pedidos/lista-de-pedidos.component";
import {Location} from "@angular/common";
import {PedidosService} from "../../services/pedidos.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {PedidoLoja} from "../objetos/PedidoLoja";
import {TipoDePagamentoEnum} from "../objetos/TipoDePagamentoEnum";
import {FormaDeEntrega} from "../objetos/FormaDeEntrega";
import {TelaMesaEscolhidaComponent} from "../tela-mesa-escolhida/tela-mesa-escolhida.component";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {TelaExpandidaComponent} from "../TelaExpandida";

@Component({
  selector: 'app-tela-confirmar-pedido',
  templateUrl: './tela-confirmar-pedido.component.html',
  styleUrls: ['./tela-confirmar-pedido.component.scss']
})
export class TelaConfirmarPedidoComponent extends TelaExpandidaComponent implements OnInit, AfterViewInit {
  @ViewChild('telaMesaEscolhida') telaMesaEscolhida: TelaMesaEscolhidaComponent;

  pedido: PedidoLoja;

  @ViewChild('listaDePedidos') listaDePedidos: ListaDePedidosComponent;
  msgErro: any = '';
  telefone: any = '';
  msg: any = '';
  enviandoConfirmacao = false;
  urlRetorno: any;
  tituloPagina: string;
  tipoDePagamento = TipoDePagamentoEnum;
  criandoPedido: any;

  constructor(private app: ApplicationRef, private router: Router, private activatedRoute: ActivatedRoute,
              private _location: Location, private pedidosService: PedidosService,
              private pedidoLocalService: PedidoLocalService) {
    super();

    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      this.telefone = queryParams.telefone;
      this.urlRetorno = queryParams.urlRetorno;
    });
  }

  ngOnInit() {
    this.pedido = this.pedidoLocalService.obtenhaPedido(this.telefone);
    this.tituloPagina = 'Novo Pedido'

    if(this.pedido){
      if(this.pedido.codigo)
        this.tituloPagina =  'Editar Pedido #' + this.pedido.codigo

      this.pedidoLocalService.salveUrlAtual(this.telefone, this.router.url, window.history.state);
    }
  }

  onSubmit() {
    if( this.criandoPedido ) return;
    this.msgErro = '';

    const objPedido = this.obtenhaObjetoPedido();

    this.criandoPedido = true;

    this.pedidosService.salvePedido(objPedido).then( (resposta) => {
      let editando = !!this.pedido.id;
      this.pedido.id = resposta.id;
      this.pedido.codigo = resposta.codigo;
      this.pedido.guid = resposta.guid;

      this.pedidoLocalService.remova(this.telefone);

      let urlDestino = this.urlRetorno ;

      if(!urlDestino){
        if( !this.pedido.mesa ){
          if(this.pedido.contato.telefone){
            urlDestino = '/admin/contatos/iframe/' +
              encodeURIComponent(this.pedido.contato.codigoPais + this.pedido.contato.telefone)
          } else {
            urlDestino = '/admin/pedidos'
          }
        }  else {
          urlDestino = '/admin/pedidos-mesas';
        }
      }

      let msg = editando ? 'Pedido atualizado com sucesso!' : 'Pedido criado com sucesso!';

      let parametros = [
        'msg=' + encodeURIComponent(msg),
        'codigo=' + this.pedido.guid
      ];

      const params = parametros.join('&');

      if( urlDestino.indexOf('?') !== -1 ) {
        urlDestino += '&' + params;
      } else {
        urlDestino += '?' + params;
      }

      this.router.navigateByUrl(urlDestino);
    }).catch( (erro) => {
      this.criandoPedido = false;
      window.scrollTo(0, 0);
      this.msgErro = erro;
    });
  }

  voltar() {
    this.pedidoLocalService.removaUrlSalva(this.telefone);
    this._location.back();
    this.app.tick();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      //this.pedido.total = this.pedido.subTotal + this.pedido.taxaDeEntrega - this.pedido.desconto;
      //this.pedido.troco = this.pedido.formaPagamento.trocoPara - this.pedido.total;

      if( this.pedido.mesa ) {
        this.telaMesaEscolhida.carregueMesa(this.pedido.mesa);
      }

      if( this.pedido.troco < 0 )
        this.pedido.troco = 0;

      this.listaDePedidos.exibaPedido(this.pedido);

      this.app.tick();
    }, 0);
  }

  abrirItensPedido() {
    this.router.navigate(['/admin/pedidos/novo'],
      {queryParams: {tel: this.pedido.contato.codigoPais + this.pedido.contato.telefone,
          idm: this.telaMesaEscolhida.obtenhaIdMesa(),
          idg: this.telaMesaEscolhida.obtenhaIdGarcom(),
          nome: this.pedido.contato.nome,
          urlRetorno: this.urlRetorno}}).then( () => {});

    return false;
  }

  envieConfirmacaoPedido() {
    const objPedido = this.obtenhaObjetoPedido();

    this.enviandoConfirmacao = true;

    delete this.msgErro;
    delete this.msg;

    this.pedidosService.envieConfirmacaoPedido(objPedido).then( (resposta) => {
      this.msg = resposta.mensagem;

      if(!this.pedido.contato.id)
        this.pedido.contato.id = resposta.contato.id;

      if(this.pedido.entrega.endereco && !this.pedido.entrega.endereco.id)
        this.pedido.entrega.endereco.id = resposta.endereco.id;

      this.pedidoLocalService.salvePedido(this.pedido);

      setTimeout( () => {
        this.enviandoConfirmacao = false;
      }, 10000);
    }).catch( (erro) => {
         this.msgErro = erro;
    })
  }

  private obtenhaIdFormaEntrega(formaDeEntrega: FormaDeEntrega) {
    switch (formaDeEntrega) {
      case FormaDeEntrega.RETIRAR:
        return {id: 1, nome: formaDeEntrega};
      case FormaDeEntrega.COMERNOLOCAL:
        return {id: 1, nome: formaDeEntrega, comerNoLocal: true};
      case FormaDeEntrega.RECEBER_EM_CASA:
        return {id: 2, nome: formaDeEntrega};
      default:
        return null;
    }
  }

  private obtenhaObjetoPedido() {

    const objPedido: any = {
      id: this.pedido.id,
      codigo: this.pedido.codigo,
      contato: this.pedido.contato,
      formaDeEntrega: this.obtenhaIdFormaEntrega(this.pedido.entrega.formaDeEntrega),
      pagamentos: [],
      observacoes: this.pedido.observacoes,
      cupom: this.pedido.cupom,
      endereco: this.pedido.entrega.endereco,
      taxaEntrega: this.pedido.obtenhaValorTaxaEntrega(),
      taxaCalculadaId: this.pedido.entrega.taxaCalculadaId,
      itens: this.pedido.itens,
      desconto: this.pedido.desconto,
      descontoFormaDePagamento: this.pedido.descontoFormaDePagamento,
      mesa: this.pedido.mesa,
      removeuExtras: this.pedido.removeuExtras
    };

    let itens = []
    let extraDoPedido

    for(let item of this.pedido.itens)
      if(item.produto.nome !== 'Extras do Pedido')
        itens.push(item)
      else
        extraDoPedido = item

    objPedido.itens = itens

    if(extraDoPedido)
      objPedido.adicionais = extraDoPedido.adicionais

    objPedido.mesa = this.telaMesaEscolhida.obtenhaMesa();
    objPedido.garcom = this.telaMesaEscolhida.obtenhaGarcom();

    if(objPedido.garcom)
      objPedido.origem = 'garcom'

    if(this.telaMesaEscolhida.obtenhaCodigoComanda())
      objPedido.codigoComanda = this.telaMesaEscolhida.obtenhaCodigoComanda()

    if(this.pedido.dataEntrega) {
      let dataEntrega = new Date(this.pedido.dataEntrega)
      let horarioEntrega = new Date(this.pedido.horarioEntrega)
      let horarioEntregaAgendadaCompleto = dataEntrega

      horarioEntregaAgendadaCompleto = dataEntrega
      horarioEntregaAgendadaCompleto.setHours(horarioEntrega.getHours())
      horarioEntregaAgendadaCompleto.setMinutes(horarioEntrega.getMinutes())
      objPedido.horarioEntregaAgendada = horarioEntregaAgendadaCompleto
      objPedido.textoHorarioEntregaAgendada = horarioEntregaAgendadaCompleto.toLocaleString("pt-BR")
    }

    if(this.pedido.cashback && this.pedido.cashback.valor){
      objPedido.pagamentos.push({
        id: this.pedido.cashback.id,
        valor: this.pedido.cashback.valor,
        cartao: this.pedido.cashback.cartao,
        formaDePagamento:  this.pedido.cashback.formaDePagamento
      });
    }

    if( !objPedido.mesa ) {
      for (let pagamento of this.pedido.pagamentos) {
        objPedido.pagamentos.push({
          id: pagamento.id,
          trocoPara: pagamento.trocoPara,
          valor: pagamento.valor,
          formaDePagamento: pagamento.formaDePagamento,
          taxa: pagamento.taxa,
        });
      }
    }

    if(this.pedido.balcao)  objPedido.origem = 'balcao';

    return objPedido;
  }
}
