import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from "@progress/kendo-angular-dialog";
import {NgForm} from "@angular/forms";
import {PromocoesService} from "../services/promocoes.service";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ProdutoService} from "../../services/produto.service";
declare let moment;

@Component({
  selector: 'app-cad-promocao',
  templateUrl: './cad-promocao.component.html',
  styleUrls: ['./cad-promocao.component.scss']
})
export class CadPromocaoComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;
  promocao: any = {
    regras: [],
    ativa: true
  }
  windowRef: DialogRef;
  novaRegra: boolean;
  private buscando: any;
  private timerBusca: any;
  disponibilidadesSemana  = [
    { dia: 0 , label: 'Dom', disponivel: false },
    { dia: 1 , label: 'Seg', disponivel: false },
    { dia: 2 , label: 'Ter', disponivel: false },
    { dia: 3 , label: 'Qua', disponivel: false },
    { dia: 4 , label: 'Qui', disponivel: false },
    { dia: 5 , label: 'Sex', disponivel: false },
    { dia: 6 , label: 'Sab', disponivel: false }
  ]
  constructor(private promocoesService: PromocoesService,
              private produtoService: ProdutoService,
              private empresasService: EmpresasService) {
    this.hoje = moment().toDate();

    this.empresasService.obtenhaTemplatesEmpresaLogada( ).then( (templates) => {
      if(templates && templates.length){
        templates.forEach( template => {
          template.tamanhos.forEach( tamanho => {
            this.tamanhos.push( { id: tamanho.id, descricao: String(`${template.nome} - ${tamanho.descricao}`)});
          })
        })

        if(this.regra) {
          if(this.regra.tamanhoPizzaComprar){
            this.regra.tamanhoPizzaComprar =
              this.tamanhos.find( tamanhoPizza => tamanhoPizza.id ===  this.regra.tamanhoPizzaComprar.id)
          }
          if(this.regra.tamanhoPizzaGanhar){
            this.regra.tamanhoPizzaGanhar =
              this.tamanhos.find( tamanhoPizza => tamanhoPizza.id ===  this.regra.tamanhoPizzaGanhar.id)
          }
        }

        console.log(this.tamanhos)

      }
    });
  }
  hoje: any;
  mensagemErro: string;
  salvando = false;
  carregando = false;
  abraDialogoRegra = false;
  regra: any = {};
  regraEditada: any;
  produtos: any = [   { descricao: ''}];
  tamanhos: any = [ {descricao: ''}];
  abraDialogoRemover = false;
  regraRemover: any;
  private texto: string;
  tiposDeRegra: any[] = [ {
    descricao: "Desconto no Produto",
    id: 'percentual'
  }, {
    descricao: "Desconto no Segundo Produto",
    id: 'percentual-no-segundo'
  }, {
    descricao: "Desconto em qualquer produto",
    id: 'percentual-todos'
  }, {
    descricao: "Adicionar produto com desconto ao carrinho",
    id: 'adicionar-produto'
  }, {
    descricao: "Ao comprar unidades de um tamanho de pizza, ganhe uma pizza de outro tamanho",
    id: 'compre-pizza-ganhe-outra'
  }

  ];


  ngOnInit(): void {
    if(this.promocao.dataInicio) this.promocao.dataInicio = new Date(this.promocao.dataInicio)
    if(this.promocao.dataFim) this.promocao.dataFim = new Date(this.promocao.dataFim)

    this.disponibilidadesSemana.forEach( (ds: any) => {
      ds.disponivel = this.promocao.horarios.find( horario => horario.dia === ds.dia) != null
    })

    this.busqueProdutos('')

  }

  onSalvar() {
    if(this.frm.valid){
      this.salvando = true;
      this.promocao.regras = this.promocao.regras.filter(regra => !regra.removida )
      this.promocao.horarios = this.disponibilidadesSemana.filter( (ds: any) => ds.disponivel)

      this.promocoesService.salvePromocao(this.promocao).then( (resp) => {
        if(!this.promocao.id)
          this.promocao.id = resp.id;
        this.windowRef.close(this.promocao);
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro = erro;
      })
    } else {
      this.mensagemErro = 'O formulário não é válido'
    }


    console.log(this.frm)
  }

  fechar() {  }

  onSelectedChange(ds, selecionou) {
    ds.disponivel = selecionou;
  }

  editarRegra($event: any) {
    this.regra = {    }
    this.regraEditada = $event.dataItem

    Object.assign(this.regra, this.regraEditada)
    this.novaRegra = false;

    if(this.regra.produto)
      this.busqueProdutos(this.regra.produto.nome, () => {
        let produtoBuscado = this.produtos.find(produto => produto.id === this.regra.produto.id)

        if(produtoBuscado) this.regra.produto = produtoBuscado
      })

    this.abraDialogoRegra = true;
  }


  removerRegra($event: any) {
    $event.dataItem.removida = true;
  }

  carregue($event: any) {

  }

  adicionarRegra() {
    this.novaRegra = true;
    this.regra = {}
    this.abraDialogoRegra = true;


    return false;
  }

  cancelarRemocao($event: MouseEvent, dataItem: any) {
    dataItem.removida = false;
  }

  fecharDialogRegra() {
    this.regra = null;
    this.abraDialogoRegra = false;
  }

  salvarRegra(frmRegra: NgForm) {
    if(this.novaRegra) {
      this.promocao.regras.push(this.regra)
    } else {
      Object.assign(this.regraEditada, this.regra)
    }

    this.fecharDialogRegra()
  }

  search($event: any, esconderZerado: boolean = false) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.busqueProdutos($event, null, esconderZerado)
    }, 1000)
  }

  busquePrimeiraVez(esconderZerado: boolean = false) {
    if(!this.produtos.length){
      this.busqueProdutos('', null, esconderZerado);
    }
  }

  busqueProdutos(texto: string = '', cb = null, esconderZerado: boolean = false) {
    if(this.buscando) return;

    this.buscando = true;

    this.produtoService.autoComplete( texto, true, false, esconderZerado).then (  (produtos) => {
      this.produtos = produtos;
      this.buscando = false;

      if(cb) cb();


    }).catch( () => {
      console.log('Aconteceu erro ao buscar')
      this.buscando = false;
    })

  }

  fecharDialogRemover() {
    this.abraDialogoRemover = false
  }

  confirmouRemoverOpcao() {

  }

  obtenhaFormatoPercentual() {
    return String(`0.00"%"`);
  }

  busqueTamanhos() {

    return this.tamanhos
  }
}
