import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DragEndEvent, SortableComponent} from "@progress/kendo-angular-sortable";
import {CadCategoriaComponent} from "../cad-categoria/cad-categoria.component";
import {CatalogosService} from "../services/catalogos.service";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-ordenar-categorias',
  templateUrl: './ordenar-categorias.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./ordenar-categorias.component.scss']
})
export class OrdenarCategoriasComponent extends ModalKendo implements OnInit {
  @ViewChild('cadCategoria')   cadCategoria:  CadCategoriaComponent;
  @ViewChild('sortableNivel1')   sortable1:  any;
  editarCategoria: any =  false;
  excluindoCategoria = false;
  categoriaExcluir: any;
  mensagemErro: any;
  mensagemSucesso: any;
  chamandoServidor: boolean;
  niveis: any  =  {}
  nivelMaximo: any = 1;
  catalogo: any = {};
  empresa: any = {};
  reordenando = false;
  constructor( private catalogosService: CatalogosService) {
    super()
  }

  ngOnInit() {
    this.setPosicoes();
  }


  private setPosicoes() {
    this.niveis = {};
    let categorias = this.catalogo.categorias;
    for(let i = 0; i < categorias.length; i++){
      categorias[i].exibirFilhos = false;
      if(categorias[i].posicao != null) continue;

      categorias[i].posicao = i + 1  ;
      categorias[i].atualizado = true;
    }

    for(let nivel = 1; nivel <= 10 ; nivel++){
      let categoriasNivel =  categorias.filter((cat: any) => cat.nivel === nivel);

      if(categoriasNivel.length){
        this.niveis[nivel] = categoriasNivel.sort( (a: any, b: any) =>  a.posicao - b.posicao);
        this.nivelMaximo = nivel;
      }
    }

    this.atualizePosicoesNoServidor();
  }



  private reconfigurePosicoes(indice: number = 0, nivel: number) {

    let categorias = this.niveis[nivel];

    for(let i = indice; i < categorias.length; i++) {
      categorias[i].posicao = i + 1;
      categorias[i].atualizado = true;
    }

    this.atualizePosicoesNoServidor();
  }

  atualizePosicoesNoServidor() {
   let categoriasAtualizadas  = [];
   let niveis = Object.keys(this.niveis);

   for(let i = 0; i < niveis.length; i++){
     let nivel = niveis[i];
     categoriasAtualizadas = [...this.niveis[nivel].filter( categoria => categoria.atualizado)];
   }

   if(categoriasAtualizadas.length)
     this.catalogosService.atualizeCategorias(this.catalogo, categoriasAtualizadas).then( () => {
       categoriasAtualizadas.forEach( categoria => delete categoria.atualizado)
     })
  }

  public onDragOver(event: any, nivel: number, sortable: any ) {
    event.preventDefault();
    if((event.oldIndex !== event.index) && event.index >= 0 && event.index < this.niveis[nivel].length) {
      sortable.moveItem(event.oldIndex, event.index);
      this.atualizePosicoesNaTela(event, nivel);
    }

  }

  public onNavigate(event: any, nivel: number, sortable: any) {
    if((event.oldIndex !== event.index) && event.index >= 0) {
      sortable.moveItem(event.oldIndex, event.index);
      this.atualizePosicoesNaTela(event, nivel);
    }
  }

  onDragEnd($event: DragEndEvent) {
    console.log($event.index)
    console.log($event)

    this.atualizePosicoesNoServidor();
  }

  private atualizePosicoesNaTela($event: DragEndEvent, nivel: number) {
    if($event.index < 0) return;
    console.log('atualizando posições na tela')
    let categorias = this.niveis[nivel];

    categorias[$event.index].posicao = $event.index + 1;
    categorias[$event.index].atualizado = true;

    if ($event.index > $event.oldIndex) { // desceu
      for (let i = $event.oldIndex; i < $event.index; i++) {
        categorias[i].posicao--;
        categorias[i].atualizado = true;
      }

    } else if ($event.index < $event.oldIndex) { // subiu
      for (let i = $event.oldIndex; i > $event.index; i--) {
        categorias[i].posicao++;
        categorias[i].atualizado = true;
      }
    }
  }

  novaCategoria() {
    this.editarCategoria = true;
  }

  reordeneCategorias(){
    this.reordenando = true;
    this.catalogosService.reordenarCategorias( this.catalogo ).then( categorias => {
      this.reordenando = false;
      this.catalogo.categorias = categorias;
      this.setPosicoes()
    }).catch((erro) => {
      this.reordenando = false;
      alert(erro)
    })
  }

  finalizouCadastro(categoria, sortable) {
    if(categoria && categoria.id){
      sortable.addDataItem(categoria, categoria.posicao)
      this.catalogo.categorias.push(categoria)
    }

    this.editarCategoria = false;
  }

  editeCategoria(item) {
    this.editarCategoria = true;
    setTimeout( () => {
      this.cadCategoria.setModoAtualizarNomeDestaque(false)
      this.cadCategoria.setCategoria(item);
    }, 0)

  }

  excluaCategoria(item: any) {
    this.categoriaExcluir = item
    this.excluindoCategoria = true;
  }

  onSubmitExcluir(nivel = 1, sortable: any) {
    delete this.mensagemErro;
    this.chamandoServidor = true
    this.catalogosService.removaCategoria( this.catalogo, this.categoriaExcluir).then( resposta => {
      this.chamandoServidor = false;
      let indice = this.catalogo.categorias.indexOf(this.categoriaExcluir);
      this.catalogo.categorias.splice(indice, 1);
      sortable.removeDataItem(indice)
      setTimeout(() => {
        this.reconfigurePosicoes(indice, nivel);
      }, 0);

      this.feche(null)

    }).catch( erro => {
      this.chamandoServidor = false;
      alert('Houve um erro: ' + erro)
      this.mensagemErro   = erro;
    })

  }

  fecheMensagemSucesso() {

  }

  fecheMensagemErro() {

  }

  feche(param) {
    this.categoriaExcluir = null;
    this.excluindoCategoria = false;
  }

  getNiveis(){
    return Object.keys(this.niveis)
  }

  getCategoriasFilhas(nivel, categoriaPai: any){
    let categorias: any = this.niveis[nivel];

    if(nivel === 1)  return categorias || []

    if(!this.niveis[nivel]) return [];

    categorias = this.niveis[nivel].filter( (cat: any) => cat.categoriaPai && cat.categoriaPai.id === categoriaPai.id )

    return  categorias || []
  }

  temCategorias(nivel: number,  categoriaPai: any) {
    return this.getCategoriasFilhas(nivel, categoriaPai).length
  }

  editarNomeCategoriaDestaque() {
    this.editarCategoria = true;
    setTimeout( () => {
      this.cadCategoria.setModoAtualizarNomeDestaque(true)
      this.cadCategoria.setCategoria({nome: this.empresa.nomeCategoriaDestaques, imagem: this.empresa.imagemCategoriaDestaque});
    }, 0)
  }
}
