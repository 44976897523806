import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileRestrictions, SuccessEvent} from "@progress/kendo-angular-upload";

@Component({
  selector: 'app-upload-contatos-campanha',
  templateUrl: './upload-contatos-campanha.component.html',
  styleUrls: ['./upload-contatos-campanha.component.scss']
})
export class UploadContatosCampanhaComponent implements OnInit {
  importou: boolean;
  restricoes: FileRestrictions = {
    allowedExtensions: ['.csv']
  };
  msgErroUpload = '';
  contatos: Array<any> = [];
  @Input() campanha: any;
  @Output() onNovosContatos: any  = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  selecionouArquivo($event: any) {
    this.msgErroUpload = '';
  }

  uploadFalhou($event: any) {
    this.msgErroUpload = $event.response.error.erro;
  }

  enviouArquivoDeContatos($event: SuccessEvent) {
    const respUpload = $event.response.body;

    if( respUpload.sucesso ) {
      this.contatos = respUpload.data;
      this.campanha.contatos = this.contatos;

      this.onNovosContatos.emit(this.contatos);
    }
  }

  ajusteContatos(contatos) {
    this.contatos = [].concat(contatos);
  }
}
