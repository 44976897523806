import {AdicionalDeProduto} from "./AdicionalDeProduto";

export class AdicionalDeProdutoEscolhaSimples extends AdicionalDeProduto {

  constructor(nome: string,   obrigatorio: boolean, opcoesDisponiveis: any = [], entidade: string = 'produto') {
    super(nome, obrigatorio, 'escolha-simples', opcoesDisponiveis, entidade);

    this.classe = this.tipo + '-' + this.entidade;
  }

  permiteMultiplaEscolha(): boolean {
    return false;
  }

  clone(): AdicionalDeProduto {
    let novo = new AdicionalDeProdutoEscolhaSimples(this.nome, this.obrigatorio, [], this.entidade);

    novo.classe = this.classe;
    this.opcoesDisponiveis.forEach( opcao => novo.opcoesDisponiveis.push(opcao.clone()))

    return  novo;
  }

  obtenhaDescricao(): string {
    return 'Escolha 1 opção';
  }

  podeIrParaProximo(qtdeEscolhidos: number): boolean {
    return !this.obrigatorio;
  }
}
