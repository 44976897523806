import {Component, Input, OnInit} from '@angular/core';
import {MonitoradorPedidos} from "../MonitoradorPedidos";
import {Router} from "@angular/router";

@Component({
  selector: 'app-alerta-pedidos',
  templateUrl: './alerta-pedidos.component.html',
  styleUrls: ['./alerta-pedidos.component.scss']
})
export class AlertaPedidosComponent implements OnInit {
  @Input() monitoradorPedidos: MonitoradorPedidos;
  constructor(   private router: Router) { }

  ngOnInit(): void {
  }


  umPedidoNovo(): any{
    return this.monitoradorPedidos.novosPedidos[0];
  }

  verPedidos(){
    this.monitoradorPedidos.fecheEZerePedidosNovos();
    this.router.navigateByUrl(  "/admin/pedidos");
  }

  verPedidosEmDisputa(){
    if(this.monitoradorPedidos.pedidosContestados.length === 1){
      this.monitoradorPedidos.verPedido(this.monitoradorPedidos.pedidosContestados[0])
    } else {
      this.router.navigateByUrl(  "/admin/pedidos/contestados");
    }
    this.monitoradorPedidos.fecheEZerePedidosNovos();

  }

  verPedidosAlteracaoEndereco(){
    if(this.monitoradorPedidos.pedidosEnderecoAlterado.length === 1){
      this.monitoradorPedidos.verPedido(this.monitoradorPedidos.pedidosEnderecoAlterado[0])
    } else {
      this.router.navigateByUrl(  "/admin/pedidos/alteracaoendereco");
    }
    this.monitoradorPedidos.fecheEZerePedidosNovos();

  }

  verPedidosdErroImportar(){
    this.monitoradorPedidos.fecheModalAlerta();
    this.router.navigateByUrl(  "/admin/pedidos/errosimportar");
  }
}
