import {ApplicationRef, Component, OnInit} from '@angular/core';
import {CatalogosService} from "../services/catalogos.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-tela-produtos-catalogo',
  templateUrl: './tela-produtos-catalogo.component.html',
  styleUrls: ['./tela-produtos-catalogo.component.scss']
})
export class TelaProdutosCatalogoComponent implements OnInit {
  catalogo: any;

  constructor(private catalogosService: CatalogosService,
              private activatedRoute: ActivatedRoute,
              private app: ApplicationRef) {
    let idCatalogo = this.activatedRoute.snapshot.queryParams.cid;
    this.catalogosService.obtenhaCatalogo(idCatalogo).then((catalogo: any) => {
      console.log(catalogo);
      this.catalogo = catalogo;
    })

  }

  ngOnInit(): void {
  }

}
