
<div class="modal-content">

    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel" >
        <ng-containger *ngIf="!enderecoDeEntrega">
          <i class="fe-user"></i> Endereço do Contato
        </ng-containger>

        <ng-containger *ngIf="enderecoDeEntrega">
          <i class="fas fa-map-marker-alt mr-1"></i> Endereço de Entrega
        </ng-containger>

      </h4>
      <button type="button" class="close" aria-label="Close" (click)="this.fecheModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div  class="modal-body">
      <app-form-endereco-contato (onSalvou)="salvarEndereco($event)" #formEndereco   >
      </app-form-endereco-contato>

      <div class="mt-1" *ngIf="!enderecoDeEntrega">
        <button class="btn btn-outline-danger btn-block" type="button" (click)="removerEndereco()" [disabled]="removendo" >
          <i class="k-icon k-i-loading mr-1" *ngIf="removendo" ></i> Remover Endereço</button>
      </div>

      <div class="alert alert-danger alert-dismissible fade show mt-1 mb-1" role="alert" *ngIf="mensagemErro">
        {{mensagemErro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    </div>

</div>
