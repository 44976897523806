


<div class="form-group  col-12 ">
  <label   >Disponibilidades</label>

  <button class=" float-right btn btn-sm mb-1 btn-blue" (click)="abraModalNovaDisponibilidade()">
    <i class="fe-plus "></i>
    Nova disponiblidade
  </button>
  <kendo-multiselect  id="disponibilidadesSelecionadas" name="disponibilidadesSelecionadas"
    [data]="disponibilidadesOrdenadas"   placeholder="Marque um ou mais disponibilidade"
    textField="descricao" valueField="id"
    [(ngModel)]="disponibilidades"  (valueChange)="setDisponibilidadesSemana()">

    <!--<ng-template kendoMultiSelectItemTemplate let-disponibilidade>
      <div class="row"  style="width: 100%; ">
        <div class="col-4" *ngIf="disponibilidade.dataInicio">
           {{disponibilidade.dataInicio | date: 'dd/MM/yyyy'}}
            <span *ngIf="disponibilidade.dataFim">até {{disponibilidade.dataFim | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="col-8"  >
          <span class="mr-2 d-inline-block" *ngFor="let periodo of disponibilidade.periodos">
              {{periodo.diasDescricao}}
              <span *ngIf="periodo.turnoDescricao">
                {{periodo.turnoDescricao}}
            </span>
          </span>

        </div>
      </div>
    </ng-template>

    <ng-template kendoMultiSelectNoDataTemplate>
      <div class="k-multiselect-no-data">Nenuma disponibilidade cadastrada.</div>
    </ng-template> -->


    <ng-template kendoMultiSelectItemTemplate let-dataItem>
      <span>{{ dataItem.descricao }}</span>
    </ng-template>


  </kendo-multiselect>

</div>

<div *ngFor="let disponibilidadeExibir of disponibilidadesExibir" class="mb-2">
  <p *ngIf="disponibilidadeExibir.descricaoInicioFim">
    Disponibilidade entre <b>{{disponibilidadeExibir.descricaoInicioFim}}</b>
  </p>
  <div class="d-flex flex-column mb-2" >
    <div class="d-flex flex-wrap">
      <div  class="flex-grow-1 " *ngFor="let diaSemana of disponibilidadeExibir.dias">
        <div class="box-semana">
          <p class="semana">
            <b> {{diaSemana.label}}</b>
          </p>
          <span class="badge badge-danger" *ngIf="!diaSemana.disponivel">indisponível</span>
          <span class="badge badge-success" *ngIf="diaSemana.disponivel">disponível</span>

          <span [hidden]="diaSemana.fullTime">
          <p *ngIf=" diaSemana.turnos.length" class="text-muted horario-info " >
           <i class=" fe-clock"></i> horários</p>

           <p  *ngFor="let turno of diaSemana.turnos" class="text-primary turnos" >
            <ng-container *ngIf="turno.horaInicio || turno.horaFim">
              {{turno.horaInicio | date: "HH:mm"}} as {{turno.horaFim  | date: "HH:mm"}}
            </ng-container>
          </p>
        </span>

        </div>
      </div>
    </div>

  </div>
</div>



