import {PontuacaoRegistrada} from "./PontuacaoRegistrada";

import {EnumTipoDePontuacao} from "../lib/emun/EnumTipoDePontuacao";
import {Empresa} from "./Empresa";
import {IVenda} from "./interfaces/venda/ivenda";

export abstract class TipoDePontuacao {

  constructor(tipo: any) {
    this.tipo = tipo
  }

  static  tipos = [
    { tipo: EnumTipoDePontuacao.Cashback, nome: 'Cashback', descricao: 'Valor da compra é retornado em crédito.' },
    { tipo: EnumTipoDePontuacao.PorValor, nome: 'Por valor', descricao: ' 1 ponto a cada R$X comprados' },
    { tipo: EnumTipoDePontuacao.PorPontos, nome: 'Por pontos', descricao: 'X pontos a cada R$1,00 em compras' },
    { tipo: EnumTipoDePontuacao.QtdeFixa, nome: 'Quantidade fixa', descricao: 'Pontuação fixada para todas atividade' },
    { tipo: EnumTipoDePontuacao.QtdeFixaPorAtividade, nome: 'Quantidade fixa na atividade',
      descricao: 'Pontuação será definida por atividade' },
    { tipo: EnumTipoDePontuacao.QtdeVariavelPorAtividade, nome: 'Quantidade variável',
      descricao: 'Pontuação será informada no ato do registro da atividade.' }
  ]

  static tiposIntegraAhPedidos: any = [
    EnumTipoDePontuacao.Cashback,  EnumTipoDePontuacao.PorValor, EnumTipoDePontuacao.PorPontos
  ]
  id: number;
  nome: string;
  tipo: string;
  descricao: string;

  abstract calculePontos(empresa: Empresa, pontuacaoRegistrada: PontuacaoRegistrada, venda: IVenda ): number

  abstract getDescricao(plano: any): string
  abstract getDescricaoAtividades(plano: any): string
  abstract clone(): TipoDePontuacao;
}
