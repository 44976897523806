import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CloudWhatsappService extends ServerService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  obtenhaTelefones(accessToken: string) {
    let dados = {
      at: accessToken,
    };

    return this.obtenha('/whatsapp/telefones', dados);
  }

  associe(accessToken: string, waba: any) {
    let dados = {
      at: accessToken,
      waba: waba
    };

    return this.facaPost('/whatsapp/associe', dados);
  }

  desassocie() {
    let dados = {
    };

    return this.facaPost('/whatsapp/desassocie', dados);
  }

  carregueDados() {
    let dados = {
    };
    return this.obtenha('/whatsapp/integracao', dados);
  }
}
