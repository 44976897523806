import { Pipe, PipeTransform } from '@angular/core';
import * as libphonenumber from 'google-libphonenumber';
import {CodigoPais} from "../../../server/domain/CodigoPais";

@Pipe({name: 'tel'})
export class TelPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if(!value) return value;

    value = '' + value;

    if(!value.startsWith('+')) {
      let codigoPais = '+55'

      if(args && args.length > 0)
        codigoPais = args[0]

      if(!codigoPais) return value;

      value = codigoPais + value
    }



    const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parse(value, 'ZZ');
    let format = value.startsWith('+55') ? libphonenumber.PhoneNumberFormat.NATIONAL : libphonenumber.PhoneNumberFormat.INTERNATIONAL
    return phoneNumberUtil.format(phoneNumber, format);


/*
    if(!value) return value;

    if( value.length === 10 ) {
      return '(' + value.substr(0, 2) + ') ' +
        value.substr(2, 4) + '-' + value.substr(6, value.length - 1);
    }

    return '(' + value.substr(0,2) + ') ' +
                 value.substr(2,1) + '-'  +
                 value.substr(3,4) + '-'  + value.substr(7, value.length - 1);
                 */
  }


}
