import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";

import {ActivatedRoute, Router} from "@angular/router";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {DominiosService} from "../../services/dominios.service";
import {ClienteService} from "../../services/cliente.service";


@Component({
  selector: 'app-admin-recuperar',
  templateUrl: './admin-recuperar.component.html',
  styleUrls: ['./admin-recuperar.component.scss', '../login.component.css']
})
export class AdminRecuperarComponent implements OnInit {
  @ViewChild('frm')  frm: NgForm;
  @ViewChild('frmv')  frmv: NgForm;
  @ViewChild('frms')  frms: NgForm;
  @ViewChild('codigoEle', {static: false})  codigoEle: ElementRef;
  @ViewChild('senhaEle', {static: false})  senhaEle: ElementRef;
  model: any = {};
  aguardeProcessar: any;
  erro: string;
  exibirSenha: any;
  empresa: any = {};
  constructor(private autorizacao: AutorizacaoService, private router: Router,
              private dominiosService: DominiosService, private clienteService: ClienteService,
              private activatedRoute: ActivatedRoute) {

    if(window.history.state.email)
      this.model.email = window.history.state.email

    this.clienteService.obtenhaEmpresa().then( resposta => {
      this.empresa = resposta.empresa;
    });
  }

  ngOnInit(): void {
    let token = this.activatedRoute.snapshot.params.token;

    if(!token){
      this.informarEmail();
    } else {
      this.autorizacao.obtenhaToken(token).then( resp => {
        this.model.email = resp.email;
        this.informarCodigo(token)
      }).catch(erro => {   this.informarEmail();  this.erro = erro})
    }
  }
  informarEmail(){
    this.model.passo = 'email';
  }

  informarCodigo(token){
    this.model.passo = 'codigo';
    this.model.token = token;
    setTimeout(() => {
      this.codigoEle.nativeElement.focus();
    }, 500);
  }

  redefinirSenha(){
    this.model.passo = 'senha';
    setTimeout(() => {
      this.senhaEle.nativeElement.focus();
    }, 500);
  }

  onEnviarVerificacao() {
    delete this.erro;
    if(this.frm.valid){
      this.aguardeProcessar = true;
      this.autorizacao.envieEmailRecuperacao(this.model.email).then( (token) => {
        this.aguardeProcessar = false;
        this.informarCodigo(token);
      }).catch(erro => {      this.aguardeProcessar = false; this.erro = erro})

    }
  }

  alterouCodigo(codigo) {
    if(this.frmv.controls.codigo.valid){
      delete this.erro;
      this.aguardeProcessar  = true;
      this.autorizacao.verifiqueCodigoConfirmacao(this.model.token, codigo).then( resp => {
        this.aguardeProcessar = false;
        this.redefinirSenha();
      }).catch(erro => {
        this.aguardeProcessar = false;
        this.erro = erro;
      })
    }
  }

  onRedefinir() {
    delete this.erro;

    if(this.frms.valid){
      this.aguardeProcessar = true;
      this.autorizacao.troqueSenha(this.model.token, this.model.senha).then( resp => {
        this.aguardeProcessar = false;
        this.router.navigateByUrl('/admin/login', { state: {email: this.model.email }});
      }).catch(erro => {
        this.aguardeProcessar = false;
        this.erro = erro;
      })
    }
  }

  exibirSenhaTela() {
    this.exibirSenha = ! this.exibirSenha ;
  }

  deveExibirTopo() {
    return false;
  }

  deveExibirMenu(){
    return true;
  }
}
