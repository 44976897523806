import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EnderecoService  extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  obtenhaEstados(): Promise<any> {
    return this.obtenha('/api/estados', { });
  }

  obtenhaCidades(estado): Promise<any> {
    return this.obtenha('/api/cidades/' + estado.id, { });
  }

  busquePorCEP(cep) {
    return this.obtenha('/api/endereco/' + cep, { });
  }

  obtenhaEnderecos(contato: any) {
    return this.obtenha('/contatos/' + contato.id + '/enderecos', { });
  }

  salveEnderecoLocal(pedido: any, endereco: any) {

    if(endereco.id) return;

    if( !pedido.novosEnderecos ) {
      pedido.novosEnderecos = [];
    }

    pedido.novosEnderecos.push(endereco);
  }

  calculeTaxaDeEntrega(formaDeEntrega: any, endereco: any, valorCompra: number) {
    return this.http.post('/api/taxaDeEntrega', {
      forma: formaDeEntrega,
      zona: endereco.zonaDeEntrega,
      endereco: endereco,
      valor: valorCompra
    });
  }
}
