import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {NumeroWhatsapp} from "../pedidos/objetos/NumeroWhatsapp";
import {FormaDePagamento} from "../pedidos/objetos/FormaDePagamento";

@Injectable({
  providedIn: 'root'
})
export class NumerosWhatsappService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  obtenhaNumeros(empresa: any) {
    return this.obtenha('/numerosWhatsapp/' + empresa.id, {});
  }

  removaWhatsapp(objetoApagar: NumeroWhatsapp) {
    return this.remova( "/numerosWhatsapp/" + objetoApagar.id, {})
  }

  salveNumeroWhatsapp(numeroWhatsapp: NumeroWhatsapp, empresa: any) {
    return this.salve('/numerosWhatsapp', {
      numero: numeroWhatsapp,
      empresa: {id: empresa.id }
    });
  }

  marqueNumeroComoPrincipal(numeroPrincipal: NumeroWhatsapp, empresa: any) {
    return this.facaPost('/numerosWhatsapp/marquePrincipal', {
      numero: numeroPrincipal,
      empresa: {id: empresa.id }
    });
  }
}
