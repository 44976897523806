import { Component, OnInit } from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {ActivatedRoute} from "@angular/router";
import {ModalTrocarcartaoComponent} from "../assinatura-trocarcartao/modal-trocarcartao/modal-trocarcartao.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NovoContatoComponent} from "../../novo-contato/novo-contato.component";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-assinatura-blackfriday',
  templateUrl: './assinatura-blackfriday.component.html',
  styleUrls: ['./assinatura-blackfriday.component.scss']
})
export class AssinaturaBlackfridayComponent implements OnInit {

  constructor( private empresasService: EmpresasService, private dialogService: DialogService,
               private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.empresasService.obtennhaFaturaBlackfriday().then((fatura: any) => {
        this.abraModalPagarCartao(fatura);
    }).catch((erro) => {
      alert(erro)
    })
  }

  abraModalPagarCartao(fatura: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalTrocarcartaoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    fatura.avulsa  = true;
    windowRef.content.instance.contrato = { id: -1, numeroParcelas: fatura.maxParcelas || 1, proximaFatura: fatura};
    windowRef.content.instance.reload =  false;
  }

}
