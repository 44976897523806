import {IUITipoDePontuacao} from "./IUITipoDePontuacao";

export class UITipoDePontuacaoCashback implements IUITipoDePontuacao {
  private empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any) {

    if (!pontuacaoRegistrada || !pontuacaoRegistrada.atividades || !pontuacaoRegistrada.atividades.length) return 0;

    if (pontuacaoRegistrada.valor < pontuacaoRegistrada.cartao.plano.valorMinimoPontuar)
      return 0;

    let valorCashback = pontuacaoRegistrada.valor * pontuacaoRegistrada.atividades[0].cashback;

    return Number((Math.round(valorCashback * 100) / 100).toFixed(2));
  }
}
