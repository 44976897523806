<ng-container *ngIf="usuario != null && usuario.empresa != null">
  <!-- Begin page -->
  <div id="wrapper">
    <!-- Topbar Start -->
    <div class="navbar-custom" >
      <ul class="list-unstyled topnav-menu float-right mb-0">
        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <img style="width:32px; height: 32px;" src="/assets/fidelidade/icones/user-icon.png" alt="user-image">
            <span class="pro-user-name ml-1">
                                {{usuario.nome}} <i class="mdi mdi-chevron-down"></i>
                            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
            <!-- item-->
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Bem vindo!</h6>
            </div>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="fe-user"></i>
              <span>Minha Conta</span>
            </a>
            <div class="dropdown-divider"></div>

            <!-- item-->
            <a href="javascript:void(0);"  (click)="logout()" class="dropdown-item notify-item">
              <i class="fe-log-out"></i>
              <span>Logout</span>
            </a>

          </div>
        </li>

      </ul>

      <!-- LOGO -->
      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li class="d-none">
          <form class="app-search">
            <div class="app-search-box">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Buscar...">
                <div class="input-group-append">
                  <button class="btn" type="submit">
                    <i class="fe-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </li>
      </ul>
      <div class="logo-box">
        <a href="index.html" class="logo text-center">
                        <span class="logo-lg">
                            <img src="/assets/fidelidade/promokit-horizontal-branco.png" alt="" height="36">
                          <!-- <span class="logo-lg-text-light">UBold</span> -->
                        </span>
          <span class="logo-sm">
                            <!-- <span class="logo-sm-text-dark">U</span> -->
                            <img src="/assets/fidelidade/zapkit-logo.png" alt="" height="36">
                        </span>
        </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <!--
        <li>
          <button class="button-menu-mobile waves-effect waves-light">
            <i class="fe-menu"></i>
          </button>
        </li>
        -->

      </ul>
    </div>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-menu-rede></app-menu-rede>


    <!-- Left Sidebar End -->
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row" #appendTo>
            <div class="col-12 conteudo-componente">
              <div class="page-title-box">



                <ng-template #template>
                  <i class="fe-printer fa-lg mr-1"></i>
                  Conectando com a impressora... <i class="k-icon k-i-loading"></i>
                </ng-template>

                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
          <!-- end page title -->

        </div> <!-- container -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

  </div>
  <!-- END wrapper -->

  <!-- Right Sidebar -->
  <div class="right-bar">
    <div class="rightbar-title">
      <a href="javascript:void(0);" class="right-bar-toggle float-right">
        <i class="dripicons-cross noti-icon"></i>
      </a>
      <h5 class="m-0 text-white">Settings</h5>
    </div>
    <div class="slimscroll-menu">
      <!-- User box -->
      <div class="user-box">
        <div class="user-img">
          <img src="/assets/template/images/users/user-1.jpg" alt="user-img" title="Mat Helme" class="rounded-circle img-fluid">
          <a href="javascript:void(0);" class="user-edit"><i class="mdi mdi-pencil"></i></a>
        </div>

        <h5><a href="javascript: void(0);">Geneva Kennedy</a> </h5>
        <p class="text-muted mb-0"><small>Admin Head</small></p>
      </div>

      <!-- Settings -->
      <hr class="mt-0" />
      <h5 class="pl-3">Basic Settings</h5>
      <hr class="mb-0" />

      <div class="p-3">
        <div class="checkbox checkbox-primary mb-2">
          <input id="Rcheckbox1" type="checkbox" checked>
          <label for="Rcheckbox1">
            Notifications
          </label>
        </div>
        <div class="checkbox checkbox-primary mb-2">
          <input id="Rcheckbox2" type="checkbox" checked>
          <label for="Rcheckbox2">
            API Access
          </label>
        </div>
        <div class="checkbox checkbox-primary mb-2">
          <input id="Rcheckbox3" type="checkbox">
          <label for="Rcheckbox3">
            Auto Updates
          </label>
        </div>
        <div class="checkbox checkbox-primary mb-2">
          <input id="Rcheckbox4" type="checkbox" checked>
          <label for="Rcheckbox4">
            Online Status
          </label>
        </div>
        <div class="checkbox checkbox-primary mb-0">
          <input id="Rcheckbox5" type="checkbox" checked>
          <label for="Rcheckbox5">
            Auto Payout
          </label>
        </div>
      </div>

      <!-- Timeline -->
      <hr class="mt-0" />
      <h5 class="pl-3 pr-3">Messages <span class="float-right badge badge-pill badge-danger">25</span></h5>
      <hr class="mb-0" />
      <div class="p-3">
        <div class="inbox-widget">
          <div class="inbox-item">
            <div class="inbox-item-img"><img src="/assets/template/images/users/user-2.jpg" class="rounded-circle" alt=""></div>
            <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Tomaslau</a></p>
            <p class="inbox-item-text">I've finished it! See you so...</p>
          </div>
          <div class="inbox-item">
            <div class="inbox-item-img"><img src="/assets/template/images/users/user-3.jpg" class="rounded-circle" alt=""></div>
            <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Stillnotdavid</a></p>
            <p class="inbox-item-text">This theme is awesome!</p>
          </div>
          <div class="inbox-item">
            <div class="inbox-item-img"><img src="/assets/template/images/users/user-4.jpg" class="rounded-circle" alt=""></div>
            <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Kurafire</a></p>
            <p class="inbox-item-text">Nice to meet you</p>
          </div>

          <div class="inbox-item">
            <div class="inbox-item-img"><img src="/assets/template/images/users/user-5.jpg" class="rounded-circle" alt=""></div>
            <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Shahedk</a></p>
            <p class="inbox-item-text">Hey! there I'm available...</p>
          </div>
          <div class="inbox-item">
            <div class="inbox-item-img"><img src="/assets/template/images/users/user-6.jpg" class="rounded-circle" alt=""></div>
            <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Adhamdannaway</a></p>
            <p class="inbox-item-text">This theme is awesome!</p>
          </div>
        </div> <!-- end inbox-widget -->
      </div> <!-- end .p-3-->

    </div> <!-- end slimscroll-menu-->
  </div>
  <!-- /Right-bar -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay"></div>

  <div class="bloqueio" *ngIf="bloqueado"> </div>

  <audio id="beep"  >   <source src="/assets/audio/beet-pedido.mp3" /> </audio>

  <div kendoWindowContainer></div>
</ng-container>
