<h4 _ngcontent-mki-c5="" class="page-title"><i class="fas fa-mail-bulk"></i> Notificaões para Usuários do App </h4>

<div class="card">
  <div class="card-body">
    <button type="button" class="btn btn-blue   btn-lg  waves-effect waves-light" (click)="novaCampanha()">
      <i class="fas fa-mail-bulk"></i> Nova Notificação
    </button>

    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>

    <div class="mt-3" *ngIf="!carregando">
      <div *ngIf="notificacoes.length == 0">
        <div class="justify-content-center text-center">
          <p><i class="fas fa-exclamation-triangle" style="font-size: 100px;color: #faa597;"></i>
          </p>
          <span style="font-size: 26px;">Você não tem campanhas ainda!</span>
        </div>
      </div>

      <div class="mt-3" *ngFor="let campanha of notificacoes">
        <app-exibir-notificacaoapp [notificacaoApp]="campanha"></app-exibir-notificacaoapp>
      </div>
    </div>
  </div>
</div>
