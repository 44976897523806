import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {AtendimentosPendentes, IndexedDbService} from "./indexed-db.service";

@Injectable({
  providedIn: 'root'
})
export class GerenciadorDeAtendimentosService {
  private atendimentosPendentesSubject = new BehaviorSubject<any[]>([]);

  constructor(private indexDbService: IndexedDbService) {
  }

  public get atendimentosPendentes$() {
    return this.atendimentosPendentesSubject.asObservable();
  }

  async adicioneAtendimentoPendente(atendimento: any) {
    await this.indexDbService.addData(atendimento);

    await this.notifiqueAssinantes();
  }

  private async notifiqueAssinantes() {
    const atendimentos = await this.indexDbService.obtenhaTodosAtendimentos();

    this.atendimentosPendentesSubject.next(atendimentos);
  }

  async finalizePendencia(atendimento: any) {
    this.indexDbService.removaAtendimento(atendimento);

    await this.notifiqueAssinantes();
  }

  async obtenhaTodosAtendimentosPendentes(): Promise<Array<any>> {
    return this.indexDbService.obtenhaTodosAtendimentos();
  }

  async obtenhaPorTelefone(telefone: string): Promise<AtendimentosPendentes> {
    return this.indexDbService.getAtendimentosPorTelefone(telefone);
  }

  async apagueAtendimentoPendente(telefone: string) {
    const atendimento = await this.indexDbService.getAtendimentosPorTelefone(telefone);

    this.finalizePendencia(atendimento);
  }
}
