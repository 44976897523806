<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-pattern">

          <div class="card-body p-4">

            <div class="text-center w-75 m-auto">
              <a href="index.html">
                <span><img src="assets/images/logo-dark.png" alt="" height="22"></span>
              </a>
              <p class="text-muted mb-4 mt-3">Não tem uma conta? Crie sua conta, leva menos de um minuto</p>
            </div>


            <form (ngSubmit)="onSubmit()" [formGroup]="frmCadastrar">

              <div class="form-group mb-3" [ngClass]="{'has-error': nome.invalid && (nome.dirty || nome.touched)}">
                <label for="email">Nome Completo</label>
                <input class="form-control" type="text" id="nome" required="" formControlName="nome" placeholder="Informe seu nome">
                <div *ngIf="nome.invalid && enviado" class="erro col-lg-9">
                  <div *ngIf="nome.errors.required">
                    Nome é obrigatório
                  </div>
                  <div *ngIf="nome.errors.minlength">
                    Nome deve ter pelo menos 5 caracteres
                  </div>
                </div>
              </div>
              <div class="form-group mb-3" [ngClass]="{'has-error': email.invalid && (email.dirty || email.touched)}">
                <label for="email">E-mail</label>
                <input class="form-control" type="email" id="email" required="" formControlName="email" placeholder="Informe seu e-mail">
                <div *ngIf="email.invalid && enviado" class="erro col-lg-9">
                  <div *ngIf="email.errors.required">
                    Email é obrigatório
                  </div>
                  <div *ngIf="email.errors.minlength">
                    Email deve ter pelo menos 5 caracteres
                  </div>
                  <div *ngIf="email.errors.email">
                    Você deve informar um email válido
                  </div>
                </div>

              </div>
              <div class="form-group mb-3" [ngClass]="{'has-error': senha.invalid && (senha.dirty || senha.touched)}">
                <label for="senha">Senha</label>
                <input class="form-control" type="password" required="" id="senha" formControlName="senha" placeholder="Informe sua senha">
                <div *ngIf="senha.invalid && enviado" class="erro  col-lg-9">
                  <div *ngIf="senha.errors.required">
                    Senha é obrigatória
                  </div>
                </div>
              </div>
              <div class="form-group mb-3" [ngClass]="{'has-error': confirmacaoSenha.invalid && (confirmacaoSenha.dirty || confirmacaoSenha.touched)}">
                <label for="senha">Confirmação de Senha</label>
                <input class="form-control" type="password" required="" id="confirmacaoSenha" formControlName="confirmacaoSenha" placeholder="Repita sua senha">
                <div *ngIf="confirmacaoSenha.invalid && enviado" class="erro  col-lg-9">
                  <div *ngIf="confirmacaoSenha.errors.required">
                    Confirmação de Senha é obrigatória
                  </div>
                </div>
              </div>

              <div class="form-group mb-3" [ngClass]="{'has-error': aceitouTermos.invalid && (aceitouTermos.dirty || aceitouTermos.touched)}">
                <div class="custom-checkbox ">
                  <input type="checkbox" class="k-checkbox" id="aceitouTermos" formControlName="aceitouTermos">
                  <label for="aceitouTermos">&nbsp;Eu aceito os <a href="javascript: void(0);" class="text-dark">Termos e Condições</a></label>
                </div>
                <div *ngIf="aceitouTermos.invalid && enviado" class="erro  col-lg-9">
                  <div *ngIf="aceitouTermos.errors.required">
                    É necessário aceitar os termos
                  </div>
                </div>

              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-info btn-block" type="submit" [disabled]="enviando" [ngClass]="{disabled: enviando}"> Cadastrar </button>
                <div class="erro" role="alert" *ngIf="mensagemErro" [innerHTML]="mensagemErro">
                  {{mensagemErro}}
                </div>

              </div>
            </form>

          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="">Já tem uma conta?  <a routerLink="/admin/login" class="ml-1"><b>Entre</b></a></p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
