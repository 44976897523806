import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver, ComponentRef, EventEmitter, Injector,
  Input,
  OnInit, Output, ReflectiveInjector,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {ContatosService} from '../../services/contatos.service';
import {NgForm} from "@angular/forms";
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import {AutoCompleteComponent, DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {debounceTime, map, switchMap} from "rxjs/operators";
import {ICriarContato} from "../../ui/ICriarContato";
import * as libphonenumber from 'google-libphonenumber';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-buscar-contato',
  templateUrl: './buscar-contato.component.html',
  styleUrls: ['./buscar-contato.component.scss'],
})
export class BuscarContatoComponent implements OnInit, AfterViewInit {
  @Output() onBuscouContato = new EventEmitter();

  @Input() criarNovo = false;
  @Input() rotulo = 'Whatsapp ou Nome';
  @Input() validarContato = true;
  @ViewChild('f', {static: true}) private frmBuscarContato: NgForm;
  @ViewChild('ipmWhatsapp', {static: true}) private ipmWhatsapp: AutoCompleteComponent;
  @Input() mensagem: any = 'Escolha o contato';
  @Input() private mensagemObrigatorio: any = 'Contato não encontrado';
  @Input() private parent: ICriarContato;
  @Input() editavel = true;
  @Input() borda = true;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  carregandoContato = false;
  mensagemErro = '';
  contato: any;
  buscandoOpcoes = false;
  onContatoBuscado: Function;
  results;
  contatoNgModel: any;
  contatoCodigoPais: any = '+55';
  validado = false;
  maskedInputController;
  public mask: Array<string | RegExp>;
  public maskFixo: Array<string | RegExp>;
  primeiraVez = true;
  configuracoes: any = {};
  constructor(public contatosService: ContatosService,
              private dialogService: DialogService,
              private vcRef: ViewContainerRef,
              private resolver: ComponentFactoryResolver) {
    this.mask = ['(', /\d/, /\d/, ')', ' ', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.maskFixo = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  ngOnInit() {
    if( !this.criarNovo ) {
      this.configuracoes = {
        popupClass: 'autocompleteContatos'
      }
    }
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngAfterViewInit(): void {
    if(!this.inIframe()) this.foque();

    this.ipmWhatsapp.filterChange.asObservable().pipe(
      map((valor: any) => {
        return valor;
      }),
      debounceTime(300)
    )
      .subscribe( (valor: any) => {
        this.search(valor);
      });

    let input = this.ipmWhatsapp.searchbar.input.nativeElement;

    input.addEventListener('keydown', (e) => {
      if(e.which === 13){
        if( this.contato == null || this.contato.telefone !==  this.unmask(input.value) ) {
          this.criarContatoIndicacao();
          this.ipmWhatsapp.toggle(false);
        }
      }
    });
  }

  search(q) {
   if(this.maskedInputController)
      q = this.unmask(q)

    if(q.length <=  2 || this.buscandoOpcoes) return;
    this.buscandoOpcoes = true;
    this.contatosService.obtenhaContatosAC(q).then((dados) => {
      this.buscandoOpcoes = false;
      for (let i = 0; i < dados.length; i++) {
        let result = dados[i];
        result.telefoneMascarado = this.apliqueMascara(result.codigoPais, result.telefone);
      }
      this.results = dados;
    }).catch((erro) => {
      this.buscandoOpcoes = false;
    });


  }

  mudouValorCampoBusca(valor: string) {
    this.alterouCampoBusca(valor);
  }

  alterouCampoBusca(valor: string) {
    if( valor !== null && valor.trim() !== ''){
      let telefone = this.unmask(valor);

      if(Number.isInteger(Number(telefone[0]))){
        this.mascareCampo()
      } else if(/^[a-zA-Z]+/.test(valor)){
        this.removaMascaraCampo()
      }
      if(telefone.length <  5) return;

      this.buscarContato(this.contatoCodigoPais, telefone)
    } else if (this.contato) {
      this.contato = null;
      this.dispareEvento(null, null, null);
    }
  }

  dispareEvento(contato: any, codigoPais: string, telefone: string) {
    if( this.onContatoBuscado ) this.onContatoBuscado(contato, codigoPais, telefone);

    this.onBuscouContato.emit({contato: this.contato, codigoPais: codigoPais, telefone: telefone});
  }

  buscarContato(codigoPais, telefone) {
    this.carregandoContato = true;
    this.contato = null;
    this.contatosService.obtenhaContato(codigoPais + telefone).then( (contato: any) => {
      this.carregandoContato = false;
      if(contato && contato.id)  {
        this.contato = contato;
        this.contatoCodigoPais = contato.codigoPais;
      }


      this.dispareEvento(this.contato, this.contatoCodigoPais, telefone);
    }).catch( (erro) => {
      this.carregandoContato = false;
      if ( this.validarContato ) {
        this.mensagemErro = this.mensagemObrigatorio;
      } else {
        this.mensagemErro = null;
      }
      this.dispareEvento(null, codigoPais, telefone);
    });
  }

  valide($event = null) {
    this.mensagemErro = null;
    if(!this.frmBuscarContato.dirty || !this.contatoNgModel) return;

    this.validado = true;
    if ( this.contato == null && this.validarContato )
      this.mensagemErro = this.mensagemObrigatorio;
  }

  exibaContato(contato: any) {
    this.results = [contato];
    this.contato = contato;
    this.contato.telefoneMascarado = this.apliqueMascara(this.contato.codigoPais, this.contato.telefone ? this.contato.telefone.toString() : '');
    this.contatoNgModel = this.contato.telefoneMascarado;

    if(this.contato.codigoPais)
      this.contatoCodigoPais = this.contato.codigoPais
  }

  obtenhaTelefone() {
    let telefoneComMascara = typeof( this.contatoNgModel) === 'object' ? this.contatoNgModel.telefone : this.contatoNgModel; ;
    return this.unmask(telefoneComMascara)
  }

  obtenhaCodigoPais() {
    return this.contatoCodigoPais;
  }

  apliqueMascara(codigoPais: any, telefone: any) {
    if(!codigoPais) return telefone;

    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

    // Cria um objeto PhoneNumber a partir do código do país e do número de telefone
    const phoneNumber = phoneUtil.parse(codigoPais + telefone, 'ZZ');

    // Verifica se o número é válido
    if (!phoneUtil.isValidNumber(phoneNumber)) {
      return telefone;
    }

    // Aplica a máscara no número de telefone
    const formattedNumber = phoneUtil.format(phoneNumber, libphonenumber.PhoneNumberFormat.NATIONAL);

    return formattedNumber;
  }

  criarContatoIndicacao() {
    if( !this.parent ) {
      return;
    }
    const factoryClass = this.parent.obtenhaComponenteCriarContato();
    //console.log(cr.instance.config);

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: factoryClass,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });


    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe((result: any) => {
      this.contato = result;
      if(result && result.id) {
        this.dispareEvento(this.contato, null, null);
      }
    }, (reason) => {

    });

    const contato: any = {};
    contato.telefone = this.ipmWhatsapp.searchbar.input.nativeElement.value;
    windowRef.content.instance.deveInserirPontos = false;
    windowRef.content.instance.exibaContato(contato);
  }

  mascareCampo(){
    if(!this.maskedInputController){
      this.maskedInputController = textMask.maskInput({
        inputElement: document.getElementById(this.ipmWhatsapp.focusableId),
        mask: (rawValue, valor2) => {
          let numbers =  rawValue.match(/\d/g);
          let numberLength = 0;
          if (numbers) {
            numberLength = numbers.join("").length;
          }

          if( numberLength >= 3 ) {
            if( numbers[2] === '9' ) {
              return this.mask;
            }
          }

          if (numberLength > 10) {
            return this.mask;
          } else {
            if(this.contatoCodigoPais === '+55')
              return this.maskFixo;
            console.log("Alterou a máscara")
            return this.mask;
          }
        },
      });
    }
  }

  removaMascaraCampo(){
    if(this.maskedInputController){
      this.maskedInputController.destroy()
      delete this.maskedInputController;
      this.reset();
    }

  }
  unmask(val) {
    return val ? val.replace(/\D+/g, '') : val;
  }

  reset() {
    this.contato = null;
    this.contatoNgModel = null;
    this.mensagemErro = null;
    this.validado = false;
  }

  foque() {
    if ( this.contato ) {
      return;
    }
    setTimeout( () => {
      this.ipmWhatsapp.focus();
    }, 50);
  }

  fecheMensagemAlerta() {
    this.mensagemErro = null;
  }

  onPhoneMaskChange($event: any) {
    this.mask = $event;
    this.mascareCampo();
  }

  onCountrySelected($event: any) {
    this.contatoCodigoPais = $event
  }
}
