import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title = 'Confirmação';
  @Input() message = 'Deseja remover este item?';
  @Output() onClose = new EventEmitter<boolean>();

  constructor() { }
  ngOnInit(): void {
  }

  confirm(confirmed: boolean) {
    this.onClose.emit(confirmed);
  }
}
