
<h4 class="float-left">
  <br>
  Selecione o Brinde que deseja adicionar
</h4>

<h4 class="text-muted  float-right">Saldo cashback disponível:
  <saldo-cartao [cartao]="resgate.cartao" [inline]="true"  ></saldo-cartao>
</h4>

<div class="clearfix"></div>
<div class="form-group   ">

  <kendo-grid [data]="gridDataResult"
                [style]=""
                [loading]="loading"
                [height]="500"
                [scrollable]="true"
                [selectable]="configuracoesDeSelecao"
                footerStyle="font-size: 11px;"
                [filter]="state.filter"
                (selectionChange)="selecionouBrinde($event)"
                (dataStateChange)="dataStateChange($event)">
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Nenhum brinde disponível"
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>
      <ng-template kendoGridToolbarTemplate>
        <input kendoTextBox placeholder="Busque por nome" class="form-control" appAutoFocus kendoTextBox (input)="onFilter($event.target.value)"/>
      </ng-template>

      <kendo-grid-column title="Brinde"   width="60px;"  [style]="{padding: '8px 6px'}" [headerStyle]="{verticalAlign: 'middle', padding: '8px 6px'}"  [headerClass]="'font-12'">
        <ng-template kendoGridCellTemplate let-brinde let-rowIndex="rowIndex">
          <img src="//promokit.com.br/images/empresa/{{brinde.linkImagem}}" style="width: 50px;  "/>

        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Descrição"     [headerClass]="'font-12'"    >
        <ng-template kendoGridCellTemplate let-brinde let-rowIndex="rowIndex">
          <span class="font-12"> {{brinde.nome}} </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Pontos"   width="70px;" [headerClass]="'font-12'"  >
        <ng-template kendoGridCellTemplate let-brinde let-rowIndex="rowIndex">
          <span class="font-12"><b>{{brinde.valorEmPontos}}</b></span>
        </ng-template>
      </kendo-grid-column>

  </kendo-grid>

</div>

<div class="row" *ngIf="brindeSelecionado">
  <div class="col-12 col-sm-6">
    <div class="form-group mb-12">
      <label for="qtdeDeItens">Qtde. Itens</label>
      <kendo-numerictextbox id="qtdeDeItens" name="qtdeDeItens" appRemovaWheel class="form-control"
                            [(ngModel)]="qtdeItens" [value]="1" [min]="0" [max]="100" [autoCorrect]="true">
      </kendo-numerictextbox>
    </div>
  </div>
</div>

<p class="alert alert-danger mt-2 mb-2" *ngIf="msgErro">
 {{msgErro}}
</p>

<div class="mt-3">
  <button class="btn btn-blue btn-block" type="button" (click)="adicioneBrindes()" [disabled]="!brindeSelecionado">Adicionar</button>
  <button class="btn btn-secondary btn-block" (click)="cancelar()">Cancelar</button>
</div>

