<div class="form-group mb-12" [ngClass]="{'lg': lg, 'sm': sm}">
  <label for="foto"  >{{label}}:
    <i class="fa fa-edit fa-lg"  *ngIf="getLinkImagem()" (click)="editarImagem=!editarImagem" ></i>
  </label>
  <div *ngIf="getLinkImagem() && !editarImagem && exibirImagem" class="container-imagem">
    <img [class.menor]="menor" src="/images/empresa/{{getLinkImagem()}}" alt="foto" *ngIf="ehImagem()">

    <iframe [src]="obtenhaUrlPdf()" alt="foto" *ngIf="!ehImagem()" width="100%" height="350px" frameborder="0"></iframe>

    <div class="acoes-salvar" *ngIf="exibirSalvar()" >
      <p class="mt-2">
        Confirma a foto, podemos salvar?
      </p>
      <div>
        <button class="btn btn-success"  (click)="salveImagem()" [disabled]="salvando">
          <i class="fe-save fa-lg"></i>
          Confirmar
        </button>
        <button class="btn btn-light ml-2"  (click)="canceleSalvar()" [disabled]="salvando">
          <i class="fa fa-times fa-la"></i>
          Cancelar
        </button>
      </div>
      <span *ngIf="erroSalvar"  class="text-danger mt-1 d-block"><b>{{erroSalvar}}</b></span>
    </div>

  </div>

  <div *ngIf="(!objeto.id || !getLinkImagem() || editarImagem || !exibirImagem)">
    <kendo-upload id="foto" name="foto"  #foto="ngModel" [disabled]="desabilitado" (select)="selecionouArquivo($event)"   (success)="successUpload($event)" (error)="onErroUpload($event)"
                  [(ngModel)]="files" [required]="true"
                  [multiple]="false" [autoUpload]="true"   [saveUrl]="uploadUrl"  [removeUrl]="uploadRemoveUrl"  [restrictions]="restricoes" >
      <kendo-upload-messages
        select="Selecione..."
        uploadSelectedFiles="Enviar arquivos"
        clearSelectedFiles="Limpar">
      </kendo-upload-messages>
      <div class="invalid-feedback">
        <p *ngIf="foto.errors?.required">Imagem é obrigatório</p>
      </div>
    </kendo-upload>
  </div>
</div>
