import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class IntegracaouberdirectService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  salveParametrizacao(integracao: any){
    return this.facaPost('/uberdirect/params', integracao)
  }

  ativeIntegracao(integracao: any){
    return this.facaPost('/uberdirect/ative', integracao)
  }

  desativeIntegracao(integracao: any){
    return this.facaPost('/uberdirect/desative', integracao)
  }
}
