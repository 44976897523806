import { Component, OnInit } from '@angular/core';
import {QrCodeService} from "../../services/qrcode.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
@Component({
  selector: 'app-cad-qrcode',
  templateUrl: './cad-qrcode.component.html',
  styleUrls: ['./cad-qrcode.component.scss']
})
export class CadQrcodeComponent implements OnInit {
  urlAnterior: string;
  qrCode: any = {
    url: ''
  };
  processando = false;
  mensagemSucesso: string | null = null;
  mensagemErro: string | null = null;

  linkTestado = false;
  shake: boolean;
  editar: boolean;
  carregando: boolean;

  constructor(private _location: Location, private qrCodeService: QrCodeService, private router: Router,
              private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.editar = true;
      this.carregando = true;
      try {
        const qrCode = await this.qrCodeService.buscarPorId(+id);
        if (qrCode) {
          this.qrCode = qrCode;
          this.linkTestado = true;
        }
      } catch (error) {
        console.error('Erro ao carregar QR Code:', error);
        this.mensagemErro = 'Erro ao carregar QR Code. Por favor, tente novamente.';
      } finally {
        this.carregando = false;
      }
    }
  }


  async adicionarQrCode() {
    this.mensagemErro = '';
    this.mensagemSucesso = null;
    if( !this.linkTestado ) {
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 800);
      return;
    }
    this.processando = true;
    try {
      const qrCodeCriado = await this.qrCodeService.salveQrCode(this.qrCode);
      this.mensagemSucesso = this.qrCode.id ? 'QrCode atualizado com sucesso!' : 'QrCode adicionado com sucesso!';

      setTimeout(() => {
        this.router.navigate(['/admin/qrcodes']);
      }, 2000);
    } catch (error) {;
      this.mensagemErro = error;
    } finally {
      this.processando = false;
    }
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = null;
  }

  fecheMensagemErro() {
    this.mensagemErro = null;
  }

  voltar() {
    this._location.back();
  }

  testeLink() {
    this.linkTestado = true;
  }

  verificarUrl() {
    if (this.qrCode.url !== this.urlAnterior) {
      this.linkTestado = false;
    }
  }
}
