<h4 class="page-title text-left">
  <i class="fab fa-whatsapp fa-lg" style="color: green;font-weight: bold;"></i> Configurar Integração com Whatsapp
</h4>

<ng-container *ngIf="!dadosIntegracao.associado">
  <div class="mb25 fb-login-text">Faça Login com sua conta do Facebook</div>

  <div class="alert alert-blue mt-2" *ngIf="!conectado">
    <strong>Faça login com a conta do Facebook, com a qual você configurou no Gerenciador de Negócios.</strong>
  </div>

  <button (click)="launchWhatsAppSignup()" class="botao-facebook">Logar com Facebook</button>
</ng-container>

<div *ngIf="dadosIntegracao.associado">
  <button class="btn btn-danger" (click)="desconectar()">
    Desconectar Whatsapp
  </button>
</div>

<div class="mt-3">
  <kendo-grid
    [data]="telefones"
    [loading]="carregando"
    footerStyle="font-size: 11px;">
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem Whatsapps associados"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>
    <kendo-grid-column title="ID"    width="150">
      <ng-template kendoGridCellTemplate let-dataItem  >
        <span class="text-black-50">{{dataItem.whatsapp_id}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="WABA ID"    width="150">
      <ng-template kendoGridCellTemplate let-dataItem  >
        <span class="text-black-50">{{dataItem.waba_id}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Número de Telefone"    width="200">
      <ng-template kendoGridCellTemplate let-dataItem  >
        <span class="text-black-50">{{dataItem.telefone}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Status"    width="200">
      <ng-template kendoGridCellTemplate let-dataItem  >
        <span class="text-muted" *ngIf="dataItem.status === 'NOT_VERIFIED'"><i class="fa fa-clock fa-lg"></i> Pendente</span>
        <span *ngIf="dataItem.status === 'VERIFIED'"><i class="fas fa-circle" style="color: #0ca678;"></i> Conectado</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Qualidade"    width="200">
      <ng-template kendoGridCellTemplate let-dataItem  >
        <span *ngIf="dataItem.rating === 'UNKNOWN'"><i class="fas fa-circle" style="color: #999;"></i> Indisponível</span>
        <span *ngIf="dataItem.rating === 'GREEN'"><i class="fas fa-circle" style="color: #0ca678;"></i> Alta</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Nome"    width="200">
      <ng-template kendoGridCellTemplate let-dataItem  >
        {{dataItem.nome}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" width="150px">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-telefone>

        <button kendoButton (click)="associarWhatsapp(telefone)" *ngIf="!telefone.associado"
                [title]="'Associar Whatsapp ' + telefone.telefone + ' ao sistema.'">
          <i class="k-icon k-i-loading mr-1" *ngIf="telefone.associando"></i>
          Associar
        </button>

        <span *ngIf="telefone.associado"><i class='fa fa-check font-20' style="color: #0ca678;"></i> Conectado</span>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<div class="mt-5">
  <img src="/assets/fidelidade/ilustra-whatsapp.svg" style="max-width: 500px"/>
</div>

