import { Injectable } from '@angular/core';
import {v4 as uuid} from "uuid";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {PedidoLocalService} from "./pedido-local.service";
import {MessageHandler} from "./MessageHandler";

@Injectable({
  providedIn: 'root'
})
export class BotsService {
  public eventoEstadoMia = new BehaviorSubject<any>(null);

  eventoEstadoMia$ = this.eventoEstadoMia.asObservable();

  constructor(protected http: HttpClient, private pedidoLocalService: PedidoLocalService) {
    this.carregueConversas();
  }

  estahComAtedente(empresa: any, telefone: string) {
    return this.http.get('/chatbot/estahComAtedente?tel=' + encodeURIComponent(telefone)).toPromise();
  }

  desativeComunicacao(empresa: any, contato: any, desativarParaSempre: boolean = false): Promise<any> {
    //chama o servidor para fazer reset da sessão
    const dados = {
      telefone: contato.codigoPais + contato.telefone,
      desativarParaSempre: desativarParaSempre
    };

    return this.http.post('/chatbot/desative', dados).toPromise().then( (resposta: any) => {
      if( resposta.sucesso ) {
        this.eventoEstadoMia.next(null);
        return Promise.resolve(resposta);
      } else {
        return Promise.reject(resposta.erro);
      }
    });
  }

  ativarComunicacao(telefone: string, desativarParaSempre: boolean = false): Promise<any> {
    //chama o servidor para fazer reset da sessão
    const dados = {
      telefone: telefone,
      desativarParaSempre: desativarParaSempre
    };

    return this.http.post('/chatbot/ative', dados).toPromise().then( (resposta: any) => {
      if( resposta.sucesso ) {
        this.eventoEstadoMia.next(null);
        return Promise.resolve(resposta);
      } else {
        return Promise.reject(resposta.erro);
      }
    });
  }

  obtenhaPedido(telefone: string) {
    return this.http.get('/bots/obtenhaPedido?tel=' + telefone).toPromise();
  }

  obtenhaConversas() {
    return this.http.get('/bots2/ultimasConversas').toPromise();
  }

  obtenhaMensagens(telefone: string) {
    return this.http.get('/bots2/ultimasMensagens?tel=' + encodeURIComponent(telefone)).toPromise();
  }

  salveMensagem(msgWhatsapp: any): Promise<any> {
    return new Promise( (resolve, reject) => {
      /*
      id: number;
      idWhatsapp: string;
      idConversa: string;
      idRemetente: string;
      idChat: string;
      nome_contato: string;
      conteudo: string;
      timestamp: number;
      tipo: string;
       */

      const nomeContato = new MessageHandler().obtenhaNomeContato(msgWhatsapp);

      if( msgWhatsapp.chatId._serialized.indexOf('g.us') > -1 || msgWhatsapp.chatId._serialized.isGroupMsg ) {
        return;
      }

      if( msgWhatsapp.chatId._serialized === 'status@broadcast' ) {
        return;
      }

      const dados = {
        idWhatsapp: msgWhatsapp.id,
        idDestinatario: msgWhatsapp.to,
        idRemetente: msgWhatsapp.sender ? msgWhatsapp.sender.id : msgWhatsapp.author,
        idChat: msgWhatsapp.chatId._serialized,
        nomeChat: msgWhatsapp.nomeChat,
        nomeContato: nomeContato,
        conteudo: msgWhatsapp.body,
        timestamp: msgWhatsapp.timestamp,
        tipo: msgWhatsapp.type
      };

      if( msgWhatsapp.type === 'ptt' ) {
        dados.conteudo = '🎙️ Audio';
      }

      if( !dados.conteudo ) {
        return;
      }

      if( msgWhatsapp.chatId._serialized === 'status@broadcast' ) {
        return;
      }

      msgWhatsapp.horario = new Date(msgWhatsapp.timestamp * 1000);

      const diferencaEmMinutos = Math.round((Date.now() - msgWhatsapp.horario) / 1000 / 60);

      if( diferencaEmMinutos > 5 ) {
        return;
      }

      /*
      if( true ) {
        console.log(`[dados_mensagem]: tempo: ${diferencaEmMinutos} min.`, dados);
        console.log(dados);
        return;
      }
       */

      this.http.post('/bots2/salve-mensagem', dados).toPromise().then((resposta: any) => {
        console.log('retornou aqui');

        resolve(resposta);
      }).catch( erro => {
        const resposta = {
          sucesso: false,
          erro: 'Erro na chamada'
        };
        resolve(resposta);
      });
    });
  }

  removeBotName(message) {
    console.log(message);
    const botName = " 🤖:";
    const index = message.indexOf(botName);

    console.log(index);
    if (index !== -1) {
      return message.slice(index + 1 + botName.length).trim();
    } else {
      return message.trim();
    }
  }

  executeComando(nome, telefone: string, mensagem: string, tipo: string, chatId: string, waId: string = '',
                 apenasSalvar: boolean = false, tentativa: number = 1, ultimaMsg: any = null): Promise<any> {
    return new Promise( (resolve, reject) => {
      if( telefone.length < 11 ) {
        telefone = telefone.substr(0, 2) + '9' + telefone.substr(2);
      }


      const dados = {
        n: nome,
        m: mensagem.trim(),
        tel: telefone,
        chatId: chatId,
        waId: waId,
        tipo: tipo,
        nt: tentativa,
        as: apenasSalvar,
        um: ultimaMsg ? this.removeBotName(ultimaMsg.trim()) : ''
      };

      this.http.post('/chatbot/conversa', dados).toPromise().then((resposta: any) => {
        console.log('retornou aqui');

        resolve(resposta);
      }).catch( erro => {
        const resposta = {
          sucesso: false,
          erro: 'Erro na chamada'
        };
        resolve(resposta);
      });
    });
  }

  private carregueConversas() {
    /*
    this.http.get('/bots/conversas').toPromise().then( (resposta: any) => {
      for( const conversa of resposta.data ) {
        //TODO: não vai funcionar pelo gmt
        conversa.horario = new Date(conversa.horarioTimestamp);
        conversa.horarioStatus = new Date(conversa.horarioStatusTimestamp);

        this.mapTelefoneSessao[conversa.telefone] = conversa;
      }
    });
     */
  }

  public reset(telefone: string) {
    //chama o servidor para fazer reset da sessão
    const dados = {
      telefone: telefone
    };

    let promise = this.http.post('/chatbot/reset', dados).toPromise();

    promise.then( (resposta: any) => {
      this.eventoEstadoMia.next(null);
    } ).catch( erro => {
      this.eventoEstadoMia.next(null);
    });

    return promise;
  }

  public obtenhaMensagensIntentsFrequencia() {
    return this.http.get('/chatbot/mensagens-intents-frequencia');
  }

  public busqueRespostasMia(intent: string) {
    return this.http.get('/chatbot/busque-respostas-mia?intent=' + intent);
  }
}
