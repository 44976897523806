<div *ngIf="!empresa.exibirBandeiras">
   <p>Para integrar com <b>ifood</b> é necessário ativar formas de pagamentos com bandeiras</p>
</div>


<div class="row mt-2" *ngIf="empresa.exibirBandeiras">
  <div  *ngIf="novaIntegracao"   class="col">
    <app-form-integracao-ifood [empresa]="empresa" [integracaoIfood]="novaIntegracao">

    </app-form-integracao-ifood>
  </div>

  <div  *ngIf="!novaIntegracao"  class="col">
    <button class="btn btn-blue" (click)="novaIntegracaoIfood()">
      <i class="k-i-plus k-icon"></i> Adicionar nova loja</button>


    <button class="btn btn-warning ml-2" *ngIf="empresa.integracoesIfood?.length"
            (click)="sincronizarFormasPagamentos()" [disabled]="sincronizando"  >
      Sincronizar Formas Pagamentos</button>

  </div>
</div>

<div class="row mt-2"  >
  <div *ngFor="let integracao of empresa.integracoesIfood" [hidden]="integracao.removida" class="col">
    <div  *ngIf="integracao.id && !integracao.codigoAutorizacao" >
      <app-form-integracao-ifood-centralizado [empresa]="empresa" [integracaoIfood]="integracao">

      </app-form-integracao-ifood-centralizado>
    </div>
    <div  *ngIf="integracao.codigoAutorizacao" >
      <app-form-integracao-ifood [empresa]="empresa" [integracaoIfood]="integracao"></app-form-integracao-ifood>
    </div>

  </div>
</div>

