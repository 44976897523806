<div class="vh-100">
  <div class="linha-caixa justify-content-center align-items-center h-100">
    <div class="caixa_login">

      <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
            novalidate #frm="ngForm"       >

        <h5 class="mb-4 text-center">Insira o código de 6 digitos que enviamos para o email: <b>{{email}}</b></h5>
        <div class="form-group mb-2 col"  >
          <label for="codigo">Código de confirmação </label>

          <div class="input-group mb-2  ">

            <input class="form-control codigo-validacao text-center" type="text" minlength="6" mask="0-0-0-0-0-0"
                   id="codigo" name="codigo"    (ngModelChange)="alterouCodigo($event)" #codigoEle
                   [(ngModel)]="codigo" placeholder="0-0-0-0-0-0" required  appAutoFocus>


            <div class="invalid-feedback">
              <p>Informe o código de validação de 6 digitos</p>
            </div>
          </div>
        </div>

        <div class="timer col mb-2">
          <div class="display">
             {{tempoRestanteTexto}}
          </div>
          <div class="text" *ngIf=" this.tempoRestante > 0">
            O Código de autenticação expira em breve
          </div>

          <div class="text text-danger" *ngIf=" this.tempoRestante <= 0">
            <b> O Código de autenticação expirado</b>
          </div>

        </div>


        <div class="mb-3 col mt-3"   >
          <button class="btn btn-blue mr-2 " type="submit" [disabled]="validando || frm.invalid"  >
            <i class="k-icon k-i-loading mr-1" *ngIf="validando"></i>
            Verificar Código </button>


          <button (click)="enviarNovoCodigo()" type="button"  class="btn btn-outline-secondary"
                  [disabled]="validando || reenviando">
            <i class="k-icon k-i-loading mr-1" *ngIf="reenviando"></i>
                 Enviar Novo Código </button>

        </div>

        <div class="text-danger mt-2 mb-2  text-center" role="alert" *ngIf="erro"  >
          <b> {{erro}}</b>
        </div>

      </form>
    </div>

  </div>
</div>


