import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "../../services/ServerService";


@Injectable({
  providedIn: 'root'
})
export class EmpresasService extends  ServerService{
  constructor(http: HttpClient) {
      super(http)
  }

  logada(){
    return this.http.get('/api/empresas/me' , {params: {}})
      .toPromise();
  }

  busqueEmpresas(filtro: any = {}){
    return this.http.get('/empresas' , {params: filtro})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeRedes() {
    return this.http.get('/empresas/redes', {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeRedesDoGrupoDaEmpresa(empresa: any) {
    if(!empresa.rede) return Promise.resolve([])

    return this.http.get('/empresas/redes/grupo/' + empresa.rede, {})
      .toPromise().then(this.retorno).catch(this.handleError);

  }



  listeComResumo(filtro: any = {}) {
    return this.http.get('/empresas/resumo' , {params: filtro})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

   listeCanceladas(params): Promise<any>{
     return this.http.get('/empresas/canceladas' , {params: params})
       .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeProximoBloqueios(){
   return this.busqueEmpresas({pb: 1})
  }

  listeBloqueadas(){
   return this.busqueEmpresas({b: 1})
  }

  obtenhaTemplates(catalogo: any) {
    return this.http.get(String(`/empresas/${catalogo.id}/templates`) , {params: {}})
      .toPromise().then(this.retorno).catch(this.handleError);
  }
  obtenhaTemplatesEmpresaLogada() {
    return this.http.get(String(`/empresas/me/templates`) , {params: {}})
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  obtenhaContrato(){
    return this.http.get('/contratos' , { })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtenhaContratoAssinatura(token: string){
    return this.http.get('/api/contratos/' + token , { })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtennhaFaturaBlackfriday(){
    return this.http.get('/api/contratos/fatura/blackfriday' , { })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtenha(idEmpresa: string) {
    return this.http.get('/empresas/' + idEmpresa , {params: {}})
      .toPromise();
  }

  obtenhaEmpresaCnpj(cnpj: string) {
    return super.obtenha('/empresas/cnpj/' + cnpj , { });
  }

  vinculeDominio(empresa: any, hostname: string, ehCardapio: boolean, ehCatalogo: boolean) {
    return super.obtenha('/empresas/link/' + empresa.id + '?hostname=' + hostname +
      (ehCardapio ? '&cardapio=true' : ehCatalogo ? '&catalogo=true' : '') , { });
  }

  desvinculeDominio(empresa: any) {
    return super.obtenha('/empresas/link/' + empresa.id + '/desvincule' , { });
  }

  salve(empresa: any) {
    return super.salve('/empresas', empresa);
  }

  salveModulos(empresa: any, modulos: any) {
    let dados = {id: empresa.id,
                tipoDeLoja: empresa.tipoDeLoja,
                modulos: modulos,
                aceitarPedidoAutomatico: empresa.aceitarPedidoAutomatico,
                statusPedidoAoAceitar: empresa.statusPedidoAoAceitar,
                googleMapsKey: empresa.googleMapsKey,
                appIos: empresa.appIos
    };

    return super.salve('/empresas/modulos', dados);
  }

  salveFavicon(empresa: any, cardapio: any) {
    return super.salve('/fotos/empresa/favicon', {id: empresa.id, favicon: empresa.favicon});
  }

  salveCardapio(empresa: any, cardapio: any) {
    return super.salve('/empresas/cardapios', {id: empresa.id, cardapio: cardapio});
  }

  atualizeSegmento(empresa: any ) {
    return super.salve('/empresas/segmentos', {id: empresa.id, segmento: empresa.segmento, agruparCategoriasPizza: empresa.agruparCategoriasPizza});
  }

  atualizeNomeCategoriaDestaques(empresa: any) {
    return this.facaPut('/empresas/nomedestaques', empresa) ;
  }

  salveUsuario(empresa: any, usuario: any) {
    return this.facaPut('/empresas/' + empresa.id + '/usuario', usuario);
  }

  salveGarcom(empresa: any, usuario: any) {
    return this.facaPut('/empresas/' + empresa.id + '/garcom', usuario) ;
  }


  atualizeEnvio(empresa: {}) {
    return this.http.put('/empresas/meioenvio', empresa)
      .toPromise();
  }

  atualizeBloqueio(empresa: any) {
    return super.salve('/empresas/bloqueada', empresa);
  }

  listeUsuarios(empresa: any) {
    return super.obtenha('/empresas/' + empresa.id + "/usuarios", {});
  }

  listeGarcons(empresa: any) {
    return this.http.get('/empresas/' + empresa.id + "/garcons", {})
      .toPromise();
  }

  listePapeis(empresa: any) {
    return this.http.get('/empresas/' + empresa.id + "/papeis", {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listePapeisComOperacoes(empresa: any){
    return this.http.get('/empresas/' + empresa.id + "/papeis-operacoes", {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salvePapel(empresa: any, dados: any){
    return this.http.post('/empresas/' + empresa.id + "/papeis", dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveOperacaoDoPapel(empresa: any, papel: any, operacao: any){
    return this.http.post(String(`/empresas/${empresa.id}/papeis/${papel.id}/operacoes`), operacao)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salvePapelUsuario(empresa: any, usuario: any, papel: any){
    return this.http.post(String(`/empresas/${empresa.id}/usuarios/${usuario.id}/papeis`), papel)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeTiposPet( ){
    return this.http.get('/empresas/pets/tipos', {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  desativeEmpresa(empresa: any, motivos, justificava: string) {
    return this.http.put('/empresas/' + empresa.id + "/desative", { motivos: motivos, justificava: justificava})
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  canceleDesativacao(cancelamento: any) {
    return this.http.put('/empresas/' + cancelamento.empresa.id + "/desativacao/cancele", cancelamento)
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  importeContatos(empresa: any, contatos: any[]) {
    return this.http.post('/empresas/' + empresa.id + '/importe/contatos', contatos)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  importePet(empresa: any, contatos: any[]) {
    return this.http.post('/empresas/' + empresa.id + '/importe/pets', contatos)
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  atualizeConfiguracoesClassificacao(empresa: any) {
    return this.http.put('/empresas/atualizeConfiguracoesClassificacao', empresa)
      .toPromise();
  }

  atualizeConfigPedidoMesaNaoIndentificada(empresa: any) {
    return this.http.put('/empresas/pedidoMesaNaoIdentificado', empresa)
      .toPromise();
  }

  salveCamposExtras(empresa: any, campos: any) {
    return super.salve('/empresas/camposextras', {id: empresa.id, campos: campos});
  }

  salveCidadeEntrega(empresa: any, cidade: any) {
    return super.salve('/empresas/permitirCidadeEntrega', {id: empresa.id, cidade: cidade});
  }

  removaCidadeEntrega(empresa: any, cidade: any) {
    return super.facaPost('/empresas/removaCidadeEntrega', {id: empresa.id, cidade: cidade});
  }


  salveZonaEntrega(empresa: any, zona: any) {
    return super.facaPost('/empresas/salveZonaEntrega', {id: empresa.id, zona: zona});
  }


  removaZonaEntrega(empresa: any, zona: any) {
    return super.facaPost('/empresas/removaZonaEntrega', {id: empresa.id, zona: zona});
  }

  obtenhaCidadesEntrega(empresa: any) {
    return super.obtenha('/empresas/cidadesEntrega', {id: empresa.id});
  }

  salveFormasDeEntrega(empresa: any, formasRetiradas: any) {
    return super.salve('/empresas/formasentrega', {id: empresa.id, formas: formasRetiradas});
  }

  salvePausaPromagrada(empresa: any, pausaProgramada: any){
    return super.facaPost('/empresas/pausaProgramada', { id: empresa.id, pausaProgramada: pausaProgramada});
  }

  excluaPausaPromagrada(empresa: any, pausaProgramada: any){
    return super.remova('/empresas/pausaProgramada/' + pausaProgramada.id + "?eid=" + empresa.id, { eid: empresa.id});
  }

  salveCategoria(empresa: any, categoria: any) {
    return this.http.post('/empresas/' + empresa.id + '/categoria', categoria)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  atualizeCategorias(empresa: any, categorias: any) {
    return this.http.put('/empresas/' + empresa.id + '/categorias/posicoes', categorias)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  atualiseSempreReceberPedidos(empresa: any) {
    return super.salve('/empresas/' + empresa.id + '/pedidos/sempreReceber',
      {id: empresa.id, srp:  empresa.sempreReceberPedidos }) ;

  }

  atualizeFusoHorario(empresa: any, novoFuso) {
    return super.salve('/empresas/' + empresa.id + '/fusohorario',
      {id: empresa.id, fh:  novoFuso }) ;

  }

  salveScriptsMarketing(empresa: any) {
    return this.http.put('/empresas/scripts/atualizar', empresa).toPromise().then(this.retorno).catch(this.handleError);
  }

  removaCategoria(empresa: any, categoriaExcluir: any) {
    return this.http.delete('/empresas/' + empresa.id + '/categoria/' + categoriaExcluir.id).toPromise()
            .then(this.retorno).catch(this.handleError);
  }

  imprimirTXT(empresa: any) {
    return super.salve('/empresas/' + empresa.id + '/pedidos/imprimirTXT',
      {id: empresa.id, txt: empresa.imprimirTXT})
  }

  salveConfigImpressao(empresa: any) {

    return this.http.post('/empresas/' + empresa.id + '/pedidos/impressao', empresa.configImpressao)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeOperacoesHistorico(){
    return this.http.get('/empresas/operacoes/historico/lista')
      .toPromise().then(this.retorno).catch(this.handleError);
  }
  obtenhaHistorico(empresa: any, filtro: any = null) {
    let queryFiltro = ''
    if(filtro) {
      let parametros = []

      if(filtro.texto) parametros.push('texto=' + filtro.texto)
      if(filtro.tipoObjeto) parametros.push('tipoObjeto=' + filtro.tipoObjeto.id)
      if(filtro.operacao) parametros.push('operacao=' + filtro.operacao.id)
      if(filtro.t){
        parametros.push('i=' + (filtro.i ? filtro.i : '0'))
        parametros.push('t=' + filtro.t)
      }

      queryFiltro = '?' + parametros.join('&')
    }

    return this.http.get('/empresas/' + empresa.id + '/historico' + queryFiltro)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  ativeIntegracao(empresa: any, planoIntegrado: any) {
    return this.facaPost('/empresas/' + empresa.id + '/integracao/ative', planoIntegrado);
  }

  ativeFidelidadeGcom(empresa: any, idLoja: any) {
    return this.facaPost('/empresas/' + empresa.id + '/fidelidadegcom/ative', {loja: idLoja});
  }

  desativeIntegracaoFidelidade(empresa: any){
    return this.facaPost('/empresas/' + empresa.id + '/fidelidadegcom/desative', {});

  }

  desativeIntegracao(empresa: any ) {
    return this.facaPost('/empresas/' + empresa.id + '/integracao/desative', {});
  }

  salveIntegracaoDelivery(empresa: any, integracao: any){
    return this.facaPost('/empresas/' + empresa.id + '/integracao/delivery', integracao);
  }

  removaIntegracaoDelivery(empresa: any ) {
    return this.facaPost('/empresas/' + empresa.id + '/integracao/delivery/remova', {});
  }


  removaIntegracaoFoodyDelivery(empresa: any ) {
    return this.facaPost('/empresas/' + empresa.id + '/integracao/fooddelivery/remova', {});
  }

  atualizeValorAtividadeCashback(empresa: any, atividade: any, cashback: number) {
    return this.facaPost('/empresas/' + empresa.id + '/atividade/valor', { id: atividade.id, cashback: cashback});
  }

  atualizeValorAtivdade(empresa: any, atividade: any, valor: number) {
    return this.facaPost('/empresas/' + empresa.id + '/atividade/valor', { id: atividade.id, pontosGanhos: valor});
  }

  atualizePontuarSoLoja(empresa: any , pontuarSoLoja){
    return this.facaPost('/empresas/' + empresa.id + '/integracao/pontuarSoLoja', { pontuarSoLoja: pontuarSoLoja});
  }
  atualizePontuarMesas(empresa: any , pontuarSoLoja){
    return this.facaPost('/empresas/' + empresa.id + '/integracao/pontuarMesas', { pontuarMesas: pontuarSoLoja});
  }

  fechePorTempo(tempoMinutos: number, mensagem: string) {
    return this.facaPost('/empresas/fechar', {tempo: tempoMinutos, mensagem: mensagem});
  }

  abra() {
    return this.facaPost('/empresas/abra', {});
  }

  obtenhaSegmentos( ) {
    return this.http.get('/empresas/segmentos/liste', {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveTemplate(empresa: any, template: any){
    return this.http.post(String(`/empresas/${empresa.id}/templates`), template)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveTamanhoTemplate(template: any, tamanho: any) {
    return this.http.post(String(`/empresas/templates/${template.id}/tamanho`), tamanho)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  removaTemplate(template: any){
    return this.http.delete(String(`/empresas/templates/${template.id}`), {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveAdicionalTemplate(template: any, adicional: any, opcao: any) {
    return this.http.post(String(`/empresas/templates/${template.id}/adicional/${adicional.id}`), opcao)
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  salveTipoDeCobranca(template: any) {
    return this.http.put(String(`/empresas/templates/${template.id}/tipodecobranca`), template)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveDeParaTamanho(template: any, tamanho: any, dePara: any){
    let dados = {idTamanho: tamanho.id}

    Object.assign(dados, dePara)

    return this.http.put(String(`/empresas/templates/${template.id}/dePara`), dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salvePontuacaoTamanhoTemplate(template: any){
    return this.http.put(String(`/empresas/templates/${template.id}/pontuacao`), template)
      .toPromise().then(this.retorno).catch(this.handleError);

  }

  removaDeParaTamanho(template: any, tamanho: any, dePara: any){
    let dados = {idTamanho: tamanho.id}

    Object.assign(dados, dePara)

    return this.http.put(String(`/empresas/templates/${template.id}/dePara/remova`), dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  atualizeIdentificadorTemplate(template: any) {
    return this.http.put(String(`/empresas/templates/${template.id}/identificador`), template)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  atualizeTemplate(template: any) {
    return this.http.put(String(`/empresas/templates/${template.id}`), template)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtenhaEstados(): Promise<any> {
    return this.http.get('/api/estados' , {params: {}})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeEmpresasRede(inicio: number, total: number, q: string) {
    return super.obtenha('/empresas/empresas-rede?i=' + inicio + '&t=' + total + '&q=' + q, {});
  }

  listeEmpresasRedeTrendsfood(rede) {
    return super.obtenha('/empresas/empresas-rede/' + rede, {});
  }

  listeEmpresasDoModelo(catalogorede) {
    return super.obtenha('/empresas/catalogo-modelo/' + catalogorede.id, {});
  }


  listeLojasChinnas() {
    return this.http.get('/integracaoerp/china/lojas' , {params: {}})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeLojasGendais() {
    return this.http.get('/integracaoerp/gendai/lojas' , {params: {}})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtenhaTodas() {
    return super.obtenha('/empresas/todas', {});
  }

  valideDominio(dominio: string) {
    return super.obtenha('/empresas/valideDominio?d=' + dominio, {});
  }

  verifiqueEmpresasOnline() {
    return super.obtenha('/api/sse/assinantesrede', {});
  }

  ativarTema(ativarDesativar: boolean, tema: string) {
    return super.facaPost('/empresas/ativeTema?a=' + (ativarDesativar ? 1 : 0) + '&t=' + tema, {});
  }

  recalculeResumoPedido() {
    return super.facaPost('/empresas/pedidos/resumo/recalcule', {});
  }

  listeTags()  {
     return super.obtenha('/empresas/me/tags', {})
  }

  salveTag(empresa: any, tag: any) {
    return this.facaPost('/empresas/' + empresa.id + '/tags', tag)
  }

  limpeCatalogoDaLoja(){
    return this.facaPost('/empresas/me/catalogo/limpe', {});
  }

  testeTokenAPIConversoes(empresa: any) {
    const accessToken = empresa.accessTokenAPIConversoes;
    const codigo = empresa.codigoTesteAPIConversoes;
    const pixel = empresa.pixelFacebook;

    return this.facaPost('/carrinho/teste-token-api-conversoes/' + empresa.id, {
      accessToken: accessToken.trim(),
      codigo: codigo.trim(),
      pixel: pixel.trim()
    });
  }

  ativeNovaLoja(codigo: string){
    return this.facaPost('/pagarme/me/instalacao/' + codigo, {});
  }


  autorizeNovaLojaPagbank(access_token: string, refresh_token: string, expires_in: string, account_id: string){
    return this.facaPost('/pagseguro/me/instalacao/nova' ,
      {access_token: access_token, refresh_token: refresh_token, expires_in: expires_in, account_id: account_id});
  }

  removaAutorizacaoLojaPagbank(){
    return this.facaPut('/pagseguro/me/instalacao/remova' ,{});
  }

  renoveTokenAutorizacaoLojaPagbank(){
    return this.facaPut('/pagseguro/me/connect/token/renove' ,{});
  }

  listeOperadoresPdv(){
    return super.obtenha('/integracaoerp/me/operadores/pdv', {})
  }

  async listeMotivosCancelarEmpresa() {
    return super.obtenha('/empresas/motivos/cancelamentos', {})
  }
}
