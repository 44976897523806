import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends ServerService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  confirmarContato(id: any): Promise<any> {
    return this.obtenha('/api/cliente/confirmar/' + id, {});
  }

  obterLink(numero: any): Promise<any> {
    return this.obtenha('/api/cliente/enviarLink/' + numero, {});
  }

  obtenhaCliente(id: any): Promise<any> {
    return this.obtenha('/api/cliente/' + id, {});
  }

  obtenhaClientePorTelefone(telefone: any, token: any = null) {
    let link = '/api/cliente/telefone/' + telefone

    if(token) link += "/" + token
    return this.obtenha( link, {    });
  }

  obtenhaEmpresa(): Promise<any> {
    return this.obtenha('/api/empresa/', {});
  }

  obtenhaImagensInstagram(): Promise<any> {
    return this.obtenha('/api/empresa/instagram', {});
  }

  obtenhaRegras(): Promise<any> {
    return this.obtenha('/api/regras', {});
  }

  proximaMensagem(idMensagem: string): Promise<any> {
    return this.obtenha('/api/mensagens/proxima', {id: idMensagem});
  }

  envieiMensagem(idMensagem: string, status: string, idWhatsapp: string): Promise<any> {
    return this.obtenha('/api/mensagens/enviei', {
      id: idMensagem, s: status,
      idw: idWhatsapp
    });
  }

  idUltimaMensagem(): Promise<any> {
    return this.obtenha('/api/mensagens/ultima?ngsw-bypass=true&h=' + new Date().getTime(), {});
  }

  obtenhaPlanoEmpresariais(): Promise<any> {
    return this.obtenha('/api/planos-empresariais', {});
  }

  obtenhaPlanoEmpresariaisAdmin(): Promise<any> {
    return this.obtenha('/api/planos-empresariais/admin', {});
  }

  obtenhaIdEmpresa(): string {
    let idEmpresa = window.location.hostname.split(".")[0]

    if(idEmpresa === 'localhost' || idEmpresa === '192') idEmpresa =  'fibo' //default fibo para testes locais

    return  idEmpresa
  }

  valideCodigo(codigo: string, telefone: string) {
    return this.obtenha('/api/cliente/valide', {
      codigo: codigo,
      telefone: telefone
    });
  }

  busquePorCEP(cep) {
    return this.obtenha('/api/endereco/' + cep, { });
  }

}
