import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {DialogService} from "@progress/kendo-angular-dialog";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {Mesa} from "../../pedidos/objetos/Mesa";
import {MesaService} from "../../services/mesa.service";
import {NovaMesaComponent} from "../nova-mesa/nova-mesa.component";

@Component({
  selector: 'app-cadastro-mesa',
  templateUrl: './cadastro-mesa.component.html',
  styleUrls: ['./cadastro-mesa.component.scss']
})
export class CadastroMesaComponent implements OnInit {
  loading: any;
  empresa: any;
  objetos: Array<Mesa> = [];
  modalDeletar = false;
  objetoApagar: Mesa;
  numeroPrincipal: Mesa = new Mesa();
  msgSucesso = '';
  filtro = '';
  mesasIntegracao: any  = [];
  constructor(private router: Router, private route: ActivatedRoute, private constantsService: ConstantsService,
              private mesaService: MesaService, private dialogService: DialogService,
              private empresasService: EmpresasService) {
    this.loading = true;
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;

      this.carregueMesas();
    });
  }

  async ngOnInit(){
    if( !this.mesasIntegracao.length){
      this.mesasIntegracao = await this.mesaService.obtenhaMesasIntegradas();
      if(this.mesasIntegracao.length)
        this.setMesasIntegradas();
    }

  }

  setMesasIntegradas(){
    this.objetos.forEach((mesa: any) => {
      if(mesa.codigoPdv && !mesa.mesaIntegrada)
        mesa.mesaIntegrada = this.mesasIntegracao.find((item: any) => Number(item.num) === Number(mesa.codigoPdv))
    })
  }

  editar(mesa: Mesa) {
    let altura = window.innerHeight - 100;
    const windowRef = this.dialogService.open({
      title: 'Editar Mesa',
      content: NovaMesaComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaMesaComponent: NovaMesaComponent = windowRef.content.instance;
    telaNovaMesaComponent.empresa = this.empresa;
    telaNovaMesaComponent.windowRef = windowRef;
    telaNovaMesaComponent.mesa = Object.assign({}, mesa);

    telaNovaMesaComponent.mesasIntegracao = this.mesasIntegracao;

    windowRef.result.subscribe((result: any) => {
      this.carregueMesas();
    });
  }

  abraDialogRemover(mesa: Mesa) {
    this.objetoApagar = mesa;

    this.modalDeletar = true;
  }

  onFilter($event: Event) {

  }

  novo() {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Mesa',
      content: NovaMesaComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNova : NovaMesaComponent = windowRef.content.instance;
    telaNova.empresa = this.empresa;
    telaNova.windowRef = windowRef;
    telaNova.mesasIntegracao = this.mesasIntegracao;

    windowRef.result.subscribe((result: any) => {
      this.carregueMesas();
    });
  }

  close(status: string) {
    if( status === 'sim') {
      this.mesaService.removaMesa(this.objetoApagar).then( () => {
        this.objetoApagar = null;
        this.modalDeletar = false;

        this.carregueMesas();
      });
    } else {
      this.objetoApagar = null;
      this.modalDeletar = false;
    }
  }

  voltar() {
    this.router.navigate(['/admin/pedidos-mesa']).then( () => {

    });
  }

  private carregueMesas() {
    this.mesaService.obtenhaMesas(this.empresa).then( (mesas) => {
      this.objetos = mesas;
      this.loading = false;
      if(this.mesasIntegracao.length)
        this.setMesasIntegradas();
    }).catch( (erro) => {
      this.loading = false;
    })
  }
}
