import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class GeoService extends ServerService {
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }

  obtenhaDadosMapa() {
    return this.obtenha('/geo/dadosmapa', {});
  }

  apagueKML() {
    return this.facaPost('/geo/apagueKML', {});
  }

  obtenhaEndereco(localizacao: { lng: number; lat: number }) {
    return this.obtenha('/geo/geocodeEndereco?lat=' + localizacao.lat + '&lng=' + localizacao.lng, {});
  }
}
