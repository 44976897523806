<kendo-grid (detailExpand)="abriuPedido($event)" #grid="kendoGrid"
            (cellClick)="clicouNaCelula($event)"
            [selectable]="{ mode: 'multiple', drag: false }" kendoGridSelectBy="id"
            [(selectedKeys)]="pedidosSelecionadosIds"
            [group]="groups"
  [kendoGridBinding]="listaPedidos"
            [groupable]="groupable"
  [loading]="carregando" style="min-height: 300px;">

  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="nenhum pedido"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>

  <ng-template kendoGridToolbarTemplate>
    <div class="float-right">
      <button type="button" class="btn btn-outline-secondary btn-sm   "
              (click)="exportToExcel(grid)" icon="file-excel">Exportar</button>

      <span [hidden]="!pedidosSelecionadosIds.length" >

         <ng-container *ngIf="!configImpressao?.multiplasImpressoras">
            <button class="btn btn-blue btn-sm ml-1" *ngIf="configImpressao?.layoutPedido != 'Ambos'"
                    [disabled]="imprimindo" (click)="imprimirVarios()">
             <i class="fa fa-print"></i>Imprimir Pedidos ({{pedidosSelecionadosIds.length}})
             <i class="k-icon k-i-loading mr-2" *ngIf="imprimindo"></i>
          </button>

           <kendo-dropdownbutton buttonClass="btn btn-blue btn-sm ml-1" [data]="opcoesImpressao"
                                 [popupSettings]="{animate: true}"
                                 (itemClick)="imprimirVarios(null, $event)" *ngIf="configImpressao?.layoutPedido  ==='Ambos'">
                 <i class="fa fa-print"></i>Imprimir Pedidos ({{pedidosSelecionadosIds.length}})
             <i class="k-icon k-i-loading mr-2" *ngIf="imprimindo"></i>
             </kendo-dropdownbutton >

         </ng-container>



          <kendo-dropdownbutton buttonClass="btn btn-blue btn-sm ml-1" [data]="impressoras" [popupSettings]="{animate: true}"
                                [disabled]="imprimindo"  (itemClick)="imprimirVarios( $event)"
                                [textField]="'setor'"  *ngIf="configImpressao?.multiplasImpressoras" >
            <i class="fa fa-print"></i> Imprimir Pedidos ({{pedidosSelecionadosIds.length}})
                      <i class="k-icon k-i-loading mr-2" *ngIf="imprimindo"></i>
          </kendo-dropdownbutton >


      </span>


    </div>
  </ng-template>
  <kendo-grid-checkbox-column [width]="50" [hidden]="!this.configImpressao?.imprimirTXT" ></kendo-grid-checkbox-column>
  <kendo-grid-column title="Codigo" field="codigo"  width="90"    >
    <ng-template kendoGridCellTemplate let-pedido >
      <a [routerLink]=" (modoGrupoLojas ? ('/admin-grupo/pedidos/detalhes/'+ pedido.empresa.id + '/') : '/admin/pedidos/detalhes/') + pedido.guid"
         [ngClass]=" {'negrito': !pedido?.visualizado }" >
        {{pedido.codigo}} </a>

       <app-pedido-origem [pedido]="pedido"></app-pedido-origem>

      <span class="badge badge-primary" *ngIf="pedido.multipedido">multipedido </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Realizado"  field="horarioVenda"   media="(min-width: 900px)"  width="115px;">
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
      <span [ngClass]=" { 'negrito': !pedido?.visualizado }">
        {{pedido.horarioVenda  | date:  pedido.finalizado ? 'dd/MM/yy HH:mm' : 'HH:mm' }} </span> <br>

      <span *ngIf="pedido.duracaoHoras <= 1" [ngClass]=" { 'negrito badge badge-success': !pedido?.visualizado }" >
        {{pedido.duracaoDescricao}} atrás
      </span>

      <span *ngIf="!pedido.finalizado && pedido.duracaoHoras > 1" class="badge badge-danger" >
        {{pedido.duracaoDescricao}} atrás
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Loja"  field="empresa.nome"  media="(min-width: 900px)"  width="200px" *ngIf="modoGrupoLojas">
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
      <span  ><b>{{pedido.empresa.nome}}</b></span>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column title="Cliente"  field="cliente.nome"  media="(min-width: 900px)"  width="160px">
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">

      <a class="" routerLink="{{obtenhaLinkContato(pedido)}}">
        <div class="info-contato"  [ngClass]=" { 'negrito': !pedido?.visualizado }">
          <span class="abreviar nome">{{pedido.cliente.nome}}<br></span>
          <span>{{pedido.cliente.telefone | tel: pedido.cliente.codigoPais}}</span>
        </div>
      </a>

    </ng-template>
  </kendo-grid-column>


    <kendo-grid-column title="Tx. Entrega" field="taxaEntrega"  media="(min-width: 1300px)" width="100px"
                       [hidden]="exibirColunaEntregador()" *ngIf="!modoSimples">
      <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
        <span class="text-muted" *ngIf="pedido.taxaEntrega ===  -1"><b>A Informar</b></span>
        <span class="text-muted" *ngIf="pedido.taxaEntrega !== -1"><b>{{pedido.taxaEntrega | currency: "BRL"}}</b></span>

      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Total" field="total" media="(min-width: 900px)"  width="100px"
                       [hidden]="exibirColunaEntregador()" *ngIf="!modoSimples">
      <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
        <span><b>{{pedido.total | currency: "BRL"}}</b></span>

      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-aggregates let-field="comanda.nome">
        {{ aggregates?.total?.sum | currency: 'BRL' }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Pagamento" field="descricaoFormasPagamento"    width="130px" media="(min-width: 1200px)"
                       [hidden]="exibirColunaEntregador()" *ngIf="!modoSimples">
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        {{value}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex"  >
        {{!pedido.pagamentoOnline ?  pedido.descricaoFormasPagamento : ''}}

        <span *ngIf="pedido.pagamentoOnline" class="badge badge-primary ml-1">
          {{pedido.pixOnline ? 'Pix Online' : 'Cartão Online'}}
        </span>

        <span *ngIf="pedido.cartaoAutenticado" class="badge badge-success ml-1"> Autenticado</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Pago"  field="pago"  width ="80px;" >
      <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">

          <i  class="fa fa-check fa-lg text-success" *ngIf="pedido.pago"></i>
          <i  class="fa fa-times fa-lg text-danger" *ngIf="!pedido.pago"></i>

      </ng-template>
    </kendo-grid-column>


  <ng-container *ngIf="exibirColunaEntregador()">
    <kendo-grid-column title="Pagamento"  media="(min-width: 900px)" width="250px" *ngIf="!modoSimples">
      <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
        <p class=" mt-0 mb-0" *ngIf="!pedido.retirar">
          Tx. Entrega:
          <span  *ngIf="pedido.taxaEntrega ===  -1"><b>A Informar</b></span>
          <span  *ngIf="pedido.taxaEntrega !== -1"><b>{{pedido.taxaEntrega | currency: "BRL"}}</b></span>
        </p>
        <p  class="  mt-0 mb-0">
          Total: <span><b>{{pedido.total | currency: "BRL"}}</b></span>
        </p>
        <p  class="  mt-0 mb-0">
          Pagamento:
          <span *ngIf="pedido.pagamentoOnline" class="badge badge-primary ml-1">
            {{pedido.pixOnline ? 'Pix Online' : 'Cartão Online'}}
        </span>
          <span *ngIf="!pedido.pagamentoOnline" class="badge badge-warning ml-1">Na Entrega</span>

        </p>
        <p  class="  mt-0 mb-0">
          Está Pago:
          <i  class="fa fa-check fa-lg text-success" *ngIf="pedido.pago"></i>
          <i  class="fa fa-times fa-lg text-danger" *ngIf="!pedido.pago"></i>

          <span *ngIf="!pedido.pagamentoOnline" class="text-primary">
              <br>
               <b>{{pedido.descricaoFormasPagamento}}</b>
          </span>

        </p>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        {{value}}
      </ng-template>

      <ng-template kendoGridGroupFooterTemplate let-group let-aggregates let-field="comanda.nome">
        Total: {{ aggregates?.total?.sum | currency: 'BRL' }}
      </ng-template>
    </kendo-grid-column>
  </ng-container>

  <kendo-grid-column title="Endereço Entrega"  field="enderecoCompleto"  media="(min-width: 1500px)" *ngIf="!modoSimples">
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
      <ng-container *ngIf="pedido && !pedido.retirar && pedido.formaDeEntrega"  >

        <i class="k-i-exclamation-circle text-danger k-icon" *ngIf="pedido.alteracaoEndereco"
           kendoTooltip title="cliente solicitou alteração endereço desse pedido" > </i>

       <span class="d-block cpointer font-12"  (click)="abraModalEditarEndereco($event, pedido)"  [ngClass]=" { 'negrito': !pedido?.visualizado }" >
          {{pedido.enderecoCompleto }}

         <i class="fe-edit fa-lg " ></i>


       </span>

        <ng-container *ngIf="pedido.endereco && pedido.endereco.localizacao">
          <br>
          <button class="btn btn-primary btn-sm font-11" (click)="exibaMapa($event, pedido);" title="Ver Localização Escolhida pelo Cliente." kendoTooltip>
            <i class="fas fa-map-pin"></i>
            Localização
          </button>
        </ng-container>

      </ng-container>

      <span *ngIf="pedido.retirar" class="font-12"
            [ngClass]=" { 'negrito': !pedido?.visualizado }"><i>{{pedido.formaDeEntrega}}</i></span>

      <span *ngIf="pedido.mesa" class="font-12"
            [ngClass]=" { 'negrito': !pedido?.visualizado }"><i>{{pedido.empresa.identificadorMesa}} {{pedido.mesa.nome}}</i></span>

      <span *ngIf="pedido.balcao" class=""><b>Balcao</b></span>

      <span *ngIf="pedido.horarioEntregaAgendada">
        <br><i>Agendado para {{pedido.horarioEntregaAgendada | date: "dd/MM/yyyy \'às\' HH:mm"}}</i>
      </span>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [title]="empresa.identificadorMesa" field="comanda.id"    width="130px" hidden="true">
    <ng-template kendoGridGroupHeaderTemplate let-grupo let-field="field" let-value="value" let-aggregates="aggregates">

      <a href="" (click)="verFechamento(grupo)">
        Comanda: {{value ? value : ''}} - {{empresa.identificadorMesa}} {{obtenhaComanda(grupo).mesa.nome}}</a>

      <ng-container *ngIf="!configImpressao || !configImpressao.multiplasImpressoras" >

        <button  type="button"   class="btn btn-outline-blue btn-xs ml-1" *ngIf="configImpressao?.layoutPedido !== 'Ambos' "
                 (click)="imprimaLayoutUnicoComanda(obtenhaComanda(grupo))" title="imprimir" kendoTooltip>
          <i class="fa fa-print"></i></button>


        <kendo-dropdownbutton buttonClass="btn btn-outline-blue btn-xs ml-1" [data]="opcoesImpressao" [popupSettings]="{animate: true}"
                              (itemClick)="imprimaUmDosLeiautesComanda(obtenhaComanda(grupo), $event)" *ngIf="configImpressao?.layoutPedido  ==='Ambos'">
          <i class="fa fa-print"></i>
        </kendo-dropdownbutton >

      </ng-container>

      <ng-container *ngIf="configImpressao?.multiplasImpressoras">

        <kendo-dropdownbutton buttonClass="btn btn-outline-blue btn-xs ml-1" [data]="impressoras" [popupSettings]="{animate: true}"
                              (itemClick)="imprimaNativamenteComanda(obtenhaComanda(grupo), $event)" *ngIf="impressoras.length" [textField]="'setor'" >
          <i class="fa fa-print"></i>
        </kendo-dropdownbutton >


      </ng-container>

      <div class="ml-2">
        <span *ngIf="obtenhaComanda(grupo).desconto">
          Desconto: {{ obtenhaComanda(grupo)?.desconto | currency: 'BRL' }},
        </span>
        Valor Total: {{ obtenhaComanda(grupo)?.totalComTaxa | currency: 'BRL' }}

        <span *ngIf="obtenhaComanda(grupo)?.pagamentoDescricao">  {{obtenhaComanda(grupo).pagamentoDescricao}} </span>

      </div>

      <div class="ml-2">
        <span class="badge badge-pill" [class.badge-success]="obtenhaComanda(grupo).aberta"
              [class.badge-dark]="!grupo.items[0].comanda.aberta"> <i class="fe-user"></i>{{grupo.items[0].comanda.status}}</span>
      </div>

      <div class="ml-2">
        {{obtenhaComanda(grupo).horarioAberturaDescricao}}
      </div>

      <div class="ml-2"  *ngIf="obtenhaComanda(grupo).pontosGanhos">
          Pontuação creditada:   {{obtenhaComanda(grupo)?.pontosGanhosDescricao  }}
      </div>

      <div class="ml-2" *ngIf="obtenhaComanda(grupo).aberta">
        <a class="btn btn-outline-blue btn-sm" [href]="empresa.linkLoja + '/mesa/' + grupo.items[0].mesa.id" target="_blank">
          <i class="fas fa-external-link-alt"></i>
        </a>
      </div>

      <button class="btn btn-danger btn-sm ml-2" *ngIf="obtenhaComanda(grupo).podeReabrir"

              [disabled]="obtenhaComanda(grupo).atualizando"
              (click)="reabraComanda(obtenhaComanda(grupo))">
            Reabrir <i class="k-i-loading k-icon" *ngIf="obtenhaComanda(grupo).atualizando"> </i>
      </button>

      <!--<button type="button" class="btn btn-danger btn-xs ml-2" *ngIf="empresa.integracaoDelivery && obtenhaComanda(grupo).fechada && !obtenhaComanda(grupo).referenciaExterna"
              (click)="notifiqueSistemaIntegrado(obtenhaComanda(grupo))">

        <i class="k-icon k-i-loading mr-1" *ngIf="obtenhaComanda(grupo).notificando"></i>
        Enviar {{empresa.integracaoDelivery?.sistema}}</button> -->

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Cashback Utilizado" field="cashbackUsado"  width="0"> </kendo-grid-column>

  <kendo-grid-column title="Entregador"    width ="175px;"  *ngIf="exibirColunaEntregador()">
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
      <app-acoes-delivery [pedido]="pedido" [empresa]="empresa" [hidden]="pedido.retirar "></app-acoes-delivery>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column title="Status" field="status"   width ="150px;"  >
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">

      <ng-container *ngIf="!pedido.aguardandoPagamentoOnline">
        <ng-container *ngIf="!pedido.naoNotificado || pedido.cancelado">
          <app-pedido-status [pedido]="pedido"></app-pedido-status>

          <span class="d-block badge badge-blue mt-1" *ngIf="pedido.horarioEntregaAgendada"  >
        <i class="fe-calendar mr-1"></i>{{pedido.horarioEntregaAgendada | date:"dd/MM/yyyy HH'h':mm"}}
      </span>

        </ng-container>
        <app-pedido-proximas-acoes  [pedido]="pedido" [comanda]="comanda"    [modoGrupoLojas]="modoGrupoLojas"
                                    [empresa]="empresa" (onAtualizouComanda)="this.onAtualizouComanda.emit($event)"
                                    [entregadores]="entregadores"     >
        </app-pedido-proximas-acoes>

        <ng-container *ngIf="pedido.naoNotificado && !pedido.cancelado">
        <span class=" text-danger cpointer">
                  <i class="fa fa-times-circle"></i>
               Pedido não enviado <b>{{empresa.integracaoDelivery?.sistema}}: "{{pedido.erroExterno}}"</b>
        </span>
        </ng-container>
      </ng-container>

      <app-sincronizar-pagamento-online [pedido]="pedido" *ngIf="pedido.aguardandoPagamentoOnline">

      </app-sincronizar-pagamento-online>


    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column    width="150px">
    <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
      <div  *ngIf="!modoSimples"   (click)="evitarSelecao($event)">

        <app-pedido-acoes  [pedido]="pedido" [comanda]="comanda" [configImpressao]="configImpressao"  [modoGrupoLojas]="modoGrupoLojas"
                           [empresa]="empresa" (onAtualizouComanda)="this.onAtualizouComanda.emit($event)" [entregadores]="entregadores"
                           [podeEditarPedido]="editarPedido"  >

        </app-pedido-acoes>
      </div>


      <button class="btn btn-blue btn-sm " style="cursor: pointer" (click)="verPedidoNaLoja(pedido)" *ngIf="modoSimples">
        Ver na loja</button>

    </ng-template>


  </kendo-grid-column>


  <ng-template kendoGridDetailTemplate let-pedido  *ngIf="!modoSimples" >
    <section>

      <div *ngIf="pedido.naoNotificado" class="alert alert-danger mt-1" role="alert">
        <p>{{pedido.erroExterno}}</p>
      </div>

      <div *ngIf="pedido.motivoReprovacao" class="alert alert-danger mt-1" role="alert">
        Pagamento não aprovado: <p>{{pedido.motivoReprovacao}}</p>
      </div>


      <div class="row">
        <div  class="col-12 col-sm-6" *ngIf="pedido.mesa">
          <h5><span class="text-muted mb-1 d-block">{{pedido.empresa.identificadorMesa}}: </span>
            <b> {{pedido.mesa.nome}} </b>
          </h5>

        </div>
        <div class="col-12 col-sm-6">
          <h5><span class="text-muted mb-1 d-block">Cliente: </span>
            <b>{{pedido.cliente.nome}} <span *ngIf="pedido.cliente.telefone">, fone: {{pedido.cliente.telefone | tel}}</span> </b></h5>
        </div>
        <div class="col-12 col-sm-6" *ngIf="!pedido.retirar && pedido.formaDeEntrega">
          <h5  ><span class="text-muted mb-1 d-block">Endereço entrega: </span>
            <b>  {{pedido.enderecoCompleto}}</b>
            <i class="fe-edit fa-2x cpointer ml-1 " (click)="abraModalEditarEndereco($event, pedido)"></i>
          </h5>
          <span *ngIf="pedido.horarioEntregaAgendada">Agendado para {{pedido.horarioEntregaAgendada | date: "dd/MM/yyyy 'às' HH:mm"}}

          </span>


        </div>

        <div class="col-12 " >

          <h5 *ngIf="pedido.pagamentos && pedido.pagamentos.length">
            <span class="text-muted mb-1 d-block">Pagamento em:</span>
            <b>
              <span *ngFor="let pagamento of pedido.pagamentos; let ultimo = last;">
                    {{pagamento.descricao}}
                    <span *ngIf="pedido.pagamentos.length > 1">
                      {{pagamento.valor | currency: "BRL"}}
                    </span>
                    <span *ngIf="pagamento.levarTroco"> ( troco para:  {{pagamento.trocoPara | currency: "BRL"}} )</span>

                    <span *ngIf="!ultimo"><br> </span>
              </span>
              <span *ngIf="pedido.taxaFormaDePagamento"> ( taxa :  {{pedido.taxaFormaDePagamento | currency: "BRL"}} )</span>
            </b>
          </h5>
          <h5 *ngIf="pedido.taxaEntrega || pedido.descontoTaxaEntrega">
            <span class="text-muted">Taxa Entrega:</span> <br> {{pedido.taxaEntrega + pedido.descontoTaxaEntrega | currency: "BRL"}}
          </h5>
          <h5  *ngIf="pedido.descontoTaxaEntrega > 0">
            <span class="text-muted">Desconto Taxa Entrega:</span> <br> {{pedido.descontoTaxaEntrega | currency: "BRL"}}
            <span *ngIf="pedido.cupom && pedido.descontoTaxaEntrega"><i> cupom:  "{{pedido.cupom}}"</i></span>
          </h5>
          <h5 *ngIf="pedido.entregador">
            <span class="text-muted">Entregador:</span> <br> {{pedido.entregador}}
          </h5>
          <h5  *ngIf="pedido.desconto > 0">
            <span class="text-muted">Desconto:</span> <br> {{pedido.desconto | currency: "BRL"}}
            <ng-container *ngFor="let promocaoAplicada of pedido.promocoesAplicadas; let isLast=last">
              <span><i>"{{promocaoAplicada.promocao.descricao}}
                ({{promocaoAplicada.desconto | currency: "BRL"}})"</i></span>
                {{isLast ? '' : ', '}}
            </ng-container>
            <span *ngIf="pedido.cupom && !pedido.descontoTaxaEntrega"><i> cupom:  "{{pedido.cupom}}"</i></span>
          </h5>
          <h5  *ngIf="pedido.descontoFormaDePagamento > 0">
            <span class="text-muted">Desconto Forma de Pagamento:</span> <br> {{pedido.descontoFormaDePagamento | currency: "BRL"}}
            <span *ngIf="pedido.descricaoDescontoFormaDePagamento"><i> ({{pedido.descricaoDescontoFormaDePagamento}})</i></span>
          </h5>

          <h5  *ngIf="pedido.pontosGanhos">
            <span class="text-muted">Pontuação creditada:</span> <br> {{pedido.pontosGanhosDescricao  }}


          </h5>

          <h5  *ngIf="pedido.observacoes"><span class="text-muted">Observações:</span> <br><i> "{{pedido.observacoes}}"</i></h5>

        </div>



      </div>

      <h5 class="mt-1">Itens do pedido:</h5>

      <kendo-grid
        [kendoGridBinding]="pedido.itens"
        [loading]="pedido.carregando"
        [scrollable]="false"
        footerStyle="font-size: 11px;"    >
        <kendo-grid-column title="Produto"     >
          <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
            <span>{{item.descricao}}</span>
            <ng-container *ngIf="item.adicionaisImprirmir ">
              <br>
              <ng-container *ngFor="let adicional of item.adicionaisImprirmir; let isLast=last">
                <i>{{adicional.descricao}}{{!isLast ? ', ' : ''}}</i>
                <br *ngIf="isLast">
              </ng-container>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Observacao"    >
          <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
            <span *ngIf="item.observacao"><i>"{{item.observacao}}"</i></span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Qtde"   width="80px;"  >
          <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
            <span>{{item.qtde}}{{item.unidade}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Valor"    >
          <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
            <span>{{item.valor | currency: "BRL"}}</span>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Total"    >
          <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
            <span>{{item.total | currency: "BRL"}}</span>
          </ng-template>
        </kendo-grid-column>



      </kendo-grid>

    </section>
  </ng-template>

  <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('pedidos')"></kendo-grid-excel>
</kendo-grid>

<kendo-dialog title="Localização Endereço" *ngIf="exibindoMapa" (close)="fecharTelaMapa()"
              [minHeight]="altura" [height]="altura" [width]="800">
  <div style="margin: -16px;">
    <app-tela-areas-de-entrega [exibirFormKML]="false" [localizacao]="taxaSelecionada"
                               [componente]="true"></app-tela-areas-de-entrega>
  </div>
</kendo-dialog>
