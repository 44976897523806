import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {CaixaService} from "../services/caixa.service";
import {ProcessadorCaixaTexto} from "../../services/impressao/ProcessadorCaixaTexto";
import {ImpressaoService} from "../../services/impressao.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

@Component({
  selector: 'app-historico-de-caixa',
  templateUrl: './historico-de-caixa.component.html',
  styleUrls: ['./historico-de-caixa.component.scss']
})
export class HistoricoDeCaixaComponent implements OnInit {
  @ViewChild('aguardeImpressao')
  public templateref: TemplateRef<any>;

  private dialogRef: DialogRef;
  historicoCaixas: any = {};
  carregando: any;
  skip = 0;
  pageSize = 10;
  empresa: any;
  configImpressao: any;

  caixaDetalhado: any;
  resumoCaixaDetalhado: any;

  constructor(private caixaService: CaixaService, private impressaoService: ImpressaoService,
              private constantsService: ConstantsService, private dialogService: DialogService) {
    this.carregueHistorico(this.skip, this.pageSize)
    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if(empresa) {
        this.empresa = empresa
        this.configImpressao = this.empresa.configImpressao;
      }

    })
  }

  ngOnInit(): void {
  }

  proximaPagina($event: PageChangeEvent) {
    this.skip = $event.skip;
    this.carregueHistorico(this.skip, this.pageSize);
  }

  private carregueHistorico(item: any, tamanho: number) {
    //carrega o historico paginado da empresa
    this.carregando = true
     this.caixaService.obtenhaHistoricoCaixas(item, tamanho).then((historicoCaixas: any) => {
       this.historicoCaixas = historicoCaixas;
       this.carregando = false;
     })
  }

  verDetalhes(resumoCaixa: any) {
    this.caixaService.obtenhaCaixa(resumoCaixa.id).then((resposta: any) => {
      this.caixaDetalhado = resposta.caixa;
      this.resumoCaixaDetalhado = resposta.resumo;
    })
  }

  imprimir(resumoCaixa: any) {
    this.dialogRef = this.dialogService.open({
      content: this.templateref,
      actions: [],
      width: 300,
      height: 75,
      autoFocusedElement: 'none'
    });

    setTimeout(() => {
      this.dialogRef.close();
    }, 1500);
    let impressora = this.configImpressao.impressoras[0]
    this.impressaoService.imprimaTexto( new ProcessadorCaixaTexto(impressora).obtenhaResumoCaixa(resumoCaixa), impressora);
  }

  fecharTransacoes() {
    this.caixaDetalhado = null;
    this.resumoCaixaDetalhado = null;

  }
}
