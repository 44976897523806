import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {query} from "@angular/animations";

@Component({
  selector: 'app-whatsapp-invalido',
  templateUrl: './whatsapp-invalido.component.html',
  styleUrls: ['./whatsapp-invalido.component.scss']
})
export class WhatsappInvalidoComponent implements OnInit {
  whatsappLogado: '';
  whatsappConfigurado: '';

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe( (queryParams) => {
      this.whatsappLogado = queryParams['whatsappLogado'];
      this.whatsappConfigurado = queryParams['whatsappConfigurado'];
    });
  }

  ngOnInit(): void {
  }

}
