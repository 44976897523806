<ng-container *ngIf="campanhas">
  <div class="card" *ngFor="let campanha of campanhas">
    <div class="card-header" *ngIf="titulo">
      <h4 class="card-title mb-0">{{titulo}}</h4>
    </div>
  <div class="card-body">
    <h5 class="font-15 card-title"><span class="text-black-50">Campanha </span>
      <b>{{campanha.nome}}</b></h5>

    <div>
      Progresso: <span class="h4 text-dark"><b>{{campanha.qtdeEnviadas}}</b></span> de
      <span class="h4 text-dark"><b>{{campanha.qtdeMensagens}}</b></span> mensagens
    </div>

    <div>
      Status:
      <span class="font-14"><span class="badge badge-primary" *ngIf="campanha.status === 'Enviando'">Enviando</span>
      <span class="badge badge-info" *ngIf="campanha.status === 'ParouEnvio'">Envio Interrompido</span>
      <span class="badge badge-primary" *ngIf="campanha.status === 'Enviada'">Enviada</span>
        <span class="badge badge-danger" *ngIf="campanha.status === 'Cancelada'">Cancelada</span>
      </span>
    </div>

    <div class="mt-2">
      <button class="btn btn-warning btn-sm" (click)="pararEnvio(campanha)" [disabled]="enviando" title="Interromper Envio da Campanha"
              *ngIf="campanha.status === 'Enviando'">Interromper Envio</button>

      <button class="btn btn-primary btn-sm" (click)="continuarEnvio(campanha)" [disabled]="enviando" title="Continuar Envio da Campanha"
              *ngIf="campanha.status === 'ParouEnvio'">Continuar Envio</button>

      <button class="ml-1 btn btn-danger btn-sm" (click)="cancelarEnvio(campanha)" [disabled]="enviando"
              *ngIf="campanha.status !== 'Cancelada'">Cancelar Envio</button>
    </div>
  </div>
</div>
</ng-container>
