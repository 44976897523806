import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from "@progress/kendo-angular-dialog";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {NgForm} from "@angular/forms";

declare var moment;

@Component({
  selector: 'app-tela-fechar-loja',
  templateUrl: './tela-fechar-loja.component.html',
  styleUrls: ['./tela-fechar-loja.component.scss']
})
export class TelaFecharLojaComponent implements OnInit {
  @ViewChild('frm', { static: true})  frm: NgForm;
  mensagemErro: any;

  public value = null;
  public horas = 0;
  public minutos = 15;
  public min = 15;
  public max = 580;
  public smallStep = 15;
  windowRef: DialogRef;
  tempo = '';
  enviando: any;
  mensagemFechada: string;

  constructor(private empresasService: EmpresasService) {
  }

  ngOnInit(): void {
    this.mudouValor()
  }

  fechar() {
    this.windowRef.close();
  }

  inicialize(windowRef: DialogRef) {
    this.windowRef = windowRef;
  }

  mudouValor() {
    const duracao = moment.duration(this.value, 'minutes');

    let humanizado = [];

    if( duracao.get('hours') === 1 ) {
      humanizado.push('1 hora');
    } else if( duracao.get('hours') >= 1 ) {
      humanizado.push(duracao.get('hours') + ' horas')
    }

    if( duracao.get('minutes') > 0 )
      humanizado.push(duracao.get('minutes') + ' minutos');

    this.tempo = humanizado.join(' e ');
  }

  fecharLoja() {

  }

  onSalvar() {
    if( !this.frm.valid ) {
      return;
    }

    this.enviando = true;
    this.empresasService.fechePorTempo(this.value, this.mensagemFechada).then( (resposta) => {
      this.windowRef.close({fechou: true});
    });
  }
}
