<div class="card-box ribbon-box  com-borda font-12  mt-1"   style="    padding-top: 10px; padding-right: 5px;"
     *ngIf="exibirCard()">
  <div class=" ribbon ribbon-blue  float-left" *ngIf="!solicitacaoUber() && !solicitacaoFoody() && !solicitacaoIfood()" >
    Entregador  <span *ngIf="exibirDetalhes">Parceiro</span>
  </div>

  <div class=" ribbon ribbon-dark float-left" *ngIf="solicitacaoUber()" >
    Uber  <span *ngIf="exibirDetalhes">Direct</span>
  </div>

  <div class=" ribbon ribbon-danger float-left" *ngIf="solicitacaoFoody()" >
    Foody Delivery
  </div>

   <div class=" ribbon ribbon-danger float-left" *ngIf="solicitacaoIfood()" >
     Entregador Ifood
  </div>

  <i class="fa fa-list fa-lg float-right cpointer" kendoTooltip title="ver notificações" [hidden]="!pedido.delivery"
     (click)="abralModalHistoricoNoticacoes($event)"></i>


  <div class="ribbon-content" *ngIf="pedido.alteracaoEndereco" >
    <app-acao-pedido-alteracao-endereco  [pedido]="pedido"> </app-acao-pedido-alteracao-endereco>
  </div>

  <div class="ribbon-content"  [hidden]="pedido.alteracaoEndereco">

    <h5 class="mt-1 header-title   mt-0  "  *ngIf="pedido?.delivery && !deliveryCancelar" >
        <span [ngClass]="{'text-danger': pedido.delivery.cancelado,
        'text-success': !pedido.delivery.pendente &&  !pedido.delivery.cancelado,
          'text-warning': pedido.delivery.pendente}">

          <b>{{pedido.delivery.descricaoStatus  }}</b></span>
    </h5>


    <p class="text-danger font-12" *ngIf="pedido.delivery?.cancelado && pedido.delivery?.motivoCancelamento">
      {{pedido.delivery.motivoCancelamento}}</p>

    <div class="form-group mb-0 mt-1"  *ngIf="selecionarParceiro">
      <label > {{todosParceiosSaoIfood() ? 'Selecione a Loja:' : 'Selecione o Parceiro:'}}</label><br>
      <kendo-dropdownlist class="form-control" name="acaoRetorno"
                          [data]="parceiros"
                          [filterable]="false"
                          [textField]="'descricao'" [valueField]="'id'"
                          (selectionChange)="onParceiroSelecionadoChange($event)"
                          [(ngModel)]="parceiroSelecionado"  >
      </kendo-dropdownlist>
    </div>

    <p class="mb-0" *ngIf="pedido.delivery && !pedido.delivery.cancelado ">

      <ng-container *ngIf="pedido.delivery.entregador">
        <i class="fas fa-motorcycle mr-1 fa-lg " *ngIf="!pedido.delivery.veiculo?.carro" ></i>
        <i class="fas fa-car mr-1  fa-lg " *ngIf="pedido.delivery.veiculo?.carro"></i>
        {{pedido.delivery.entregador?.name}} &nbsp; {{pedido.delivery.veiculo?.descricao}}

        <ng-container *ngIf="pedido.delivery.entregador?.phone">
          <br>
          <i class="fas fa-phone mr-1  fa-lg " ></i> {{pedido.delivery.entregador?.phone}}
        </ng-container>

      </ng-container>

      <span class="  text-blue mb-1 " *ngIf="pedido.delivery?.codigoRetirada"   style="float: right" [hidden]="pedido.delivery?.codigoCancelamentoRetorno">
          Codigo coleta: <b>{{pedido.delivery.codigoRetirada}}</b>
       </span>
      <span class="  text-danger mb-1 " *ngIf="pedido.delivery?.codigoCancelamentoRetorno"   style="float: right">
          Codigo devolução: <b>{{pedido.delivery.codigoCancelamentoRetorno}}</b>
       </span>
    </p>
    <div class="clearfix"></div>

    <div class="mt-1 font-12">

        <ng-container *ngIf="pedido.delivery?.pendente">
          <span *ngIf="exibirDetalhes">
             <br>
                            <span class="text-muted"> Andamento parceiro:</span> <b>
                                <button type="button" class="btn btn-xs  btn-light ml-1" (click)="sincronizeAndamentoEntrega()"
                                        kendoTooltip title="sincronizar andamento"
                                        [disabled]="pedido.sincronizando"   >
                                      <i class="fa fa-sync" *ngIf="!pedido.sincronizando"></i>
                                      <i class="k-icon k-i-loading"     *ngIf="pedido.sincronizando"></i>
                                  </button>
                            </b>
                            <br>
          </span>
            <span *ngIf="pedido.delivery.horarioLimite">Tempo limite: <b>{{pedido.delivery.horarioLimite | date: 'HH:mm'}}</b></span>
            <span *ngIf="pedido.delivery.encerrouPrazo" class="text-danger ml-1">
                              <b> Prazo encerrou, pode tentar uma nova solicitação</b>
          </span>
        </ng-container>

        <a href="{{pedido.delivery.urlRastreamento}}" target="_blank"
           *ngIf="pedido.delivery?.urlRastreamento && !pedido.delivery?.finalizado"
           class="text-primary mb-1"  title="Ver Localização entregador." kendoTooltip>
          <i class="fas fa-route  fa-lg mr-1  "></i>
          Acompanhar
        </a>

        <p *ngIf="pedido.delivery?.uberId" class="mb-0 text-black-50 mt-1">
          Uber ID: <span class="text-primary mr-2"><b>{{pedido.delivery?.uberId}}</b> </span>
        </p>

        <p class="text-muted mb-0 mt-1" *ngIf="exibirDetalhes && pedido.delivery?.codigo">
         Codigo Delivery:
          <b>  <span class="  mr-2 cpointer" (click)="verDelivery()">
                            {{pedido.delivery.codigo}}
                          </span></b>
          solicitado ás {{pedido.delivery.horario | date: 'HH:mm'}}
         </p>

        <p *ngIf="solicitacaoUber() && !this.pedido.pago" class="text-muted">
          Solicitação disponível apenas para pedidos pagos
        </p>

        <p *ngIf="!podeSolicitar() && !verCotacao()"
           class="text-muted" [hidden]="selecionarParceiro || pedidoFechado()">
          Solicitação não disponível
        </p>

      <button class="btn btn-success btn-block btn-sm mt-1" (click)="abraModalCotacaoDelivery(pedido)"
              *ngIf="verCotacao()"
              kendoTooltip title="solicitar entregador"   [disabled]="pedido.solicitando" >
        <i class="k-icon k-i-loading mr-1 " *ngIf="pedido.solicitando"> </i>
        Ver cotação
      </button>


      <ng-container *ngIf="podeSolicitar()">

         <ng-container  *ngIf="!pedido.delivery || pedido.delivery?.podeSolicitarNovamente || deliveryCancelar ">
           <div class="form-group mb-0 mt-1" *ngIf="restaurante && solicitacaoUber()">
             <label    >Ação não entrega:</label><br>
             <kendo-dropdownlist class="form-control" name="acaoRetorno"
                                 [data]="acoesRetorno"
                                 [filterable]="false"
                                 [textField]="'descricao'" [valueField]="'id'"
                                 [(ngModel)]="acaoRetorno"  >
             </kendo-dropdownlist>

           </div>

           <button class="btn  btn-success btn-block  btn-sm  mt-1" (click)="solicitarEntregador(pedido, $event)"
                   *ngIf="pedido.delivery?.podeSolicitarNovamente"
                   kendoTooltip title="solicitar outro entregador"   [disabled]="pedido.solicitando" >
             <i class="k-icon k-i-loading mr-1 " *ngIf="pedido.solicitando"> </i>
             Solicitar Outro  <i class="fas fa-motorcycle ml-1 " ></i>
              </button>

           <button class="btn btn-success btn-block btn-sm mt-1" (click)="solicitarEntregador(pedido, $event)"  *ngIf="!pedido.delivery || deliveryCancelar "
                   kendoTooltip title="solicitar entregador"   [disabled]="pedido.solicitando" >
             <i class="k-icon k-i-loading mr-1 " *ngIf="pedido.solicitando"> </i>
             Solicitar <i class="fas fa-motorcycle ml-1 " ></i>
           </button>


           <p *ngIf="deliveryCancelar" class="text-warning mt-1 mb-1">
             <i class="k-icon k-i-information"></i> Entrega no <b>Foody Delivery</b> será cancelada</p>
         </ng-container>

         <ng-container *ngIf="pedido.delivery?.podeCancelar && !deliveryCancelar">

           <button class="btn btn-xs btn-danger mt-1"  (click)="abraModalCancelarDelivery()">
             Cancelar entrega
           </button>

           <button class="btn btn-xs btn-dark  " [ngClass]="{'mt-1 ml-2': this.exibirDetalhes, 'mt-2': ! this.exibirDetalhes}"
                   *ngIf="integracaoUber && foodyDeliveryAguardando() "
                   (click)="usuarioVaiCancelarChamarUber()"> Solicitar Uber</button>

         </ng-container>



       </ng-container>

      <ng-container *ngIf="pedidoFechado()">
          <p class="text-muted" *ngIf="!pedido.delivery"><b>Não solicitado</b></p>
      </ng-container>

    </div>

    <div class=" text-danger cpointer font-12 mt-2" *ngIf="pedido.naoNotificadoLogistica" >
                  <i class="fa fa-times-circle mr-1"></i>
                  <b>{{pedido.erroExterno}}"</b>
    </div>


  </div>

</div>


