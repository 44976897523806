<kendo-grid [data]="sangrias" [height]="400">
  <kendo-grid-column field="caixa.id" title="Caixa" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      #{{ dataItem.caixa.id}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="dataHoraRealizada" title="Data/Hora" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.dataHoraRealizada | date:'short':'':'pt-BR' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="valorRetirado" title="Valor Retirado" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{ dataItem.valorRetirado | currency:'BRL' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="operador.nome" title="Operador" [width]="150"></kendo-grid-column>

    <kendo-grid-column field="justificativa" title="Justificativa" [width]="200"></kendo-grid-column>


    <ng-template kendoGridToolbarTemplate>
    <button type="button" kendoGridExcelCommand icon="file-excel">
      Exportar para Excel
    </button>
  </ng-template>

  <kendo-grid-excel [fileName]="'SANGRIAS_EMISSAO' + (agora | date:'ddMMyyyy_HHmm')"></kendo-grid-excel>
</kendo-grid>
