import {
  ApplicationRef,
  Injectable,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import {
  DialogService,
  DialogRef,
  DialogAction, DialogResult,
} from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { DynamicChangelogComponent } from '../dynamic-changelog/dynamic-changelog.component';

@Injectable({
  providedIn: 'root',
})
export class AtualizacoesService {
  private currentVersion = '1.0.1'; // Atualize este valor sempre que houver uma nova versão
  private requiresUpdateDueToBug = false; // Defina como true se a atualização for necessária devido a um bug

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private dialogService: DialogService,
    private http: HttpClient,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {
  }

  public checkForUpdates(): void {
    this.promptUserToUpdate();
    this.updates.available.subscribe(() => {
    });
  }

  private promptUserToUpdate(): void {
    if (this.hasSeenNovelties()) {
      return;
    }

    let acoes = [
      { text: 'Atualizar', primary: true },
    ];

    if (this.requiresUpdateDueToBug) {
      acoes = [
        { text: 'Não', primary: false },
        { text: 'Atualizar', primary: true }
      ]
    }

    this.loadChangelog().subscribe((changelog) => {
      const dialogRef: DialogRef = this.dialogService.open({
        title: 'Nova versão do Meu Cardápio',
        content: DynamicChangelogComponent,
        actions: acoes,
        width: 800,
        maxHeight: 700,
        minWidth: 250,
      });

      if (this.hasSeenNovelties()) {
        changelog = this.getRandomPhraseAtualizacao();
      }

      dialogRef.result.subscribe((result: any) => {
        if (result.primary) {
          window.location.reload();
        }
        if (!this.hasSeenNovelties()) {
          this.markNoveltiesAsSeen();
        }
      });

      dialogRef.content.instance.changelogContent = changelog;
    });
  }

  getRandomPhraseAtualizacao() {
    const phrases = [
      "✨ Nova versão: estabilidade aprimorada, desempenho otimizado e experiência incrível! 🚀",
      "🌟 Versão atualizada: estabilidade melhorada e performance aperfeiçoada para um uso fantástico! 🎉",
      "💪 Nova versão: estabilidade reforçada e otimizações para proporcionar uma experiência excepcional! 🎈",
      "🎊 Atualização: aprimoramento na estabilidade e desempenho para uma experiência ainda mais agradável! 😊",
      "🚀 Versão renovada: mais estabilidade e desempenho turbinado para uma incrível experiência de uso! 🎈",
      "💫 Atualização disponível: estabilidade e performance aprimoradas para proporcionar uma experiência inesquecível! 🤩",
      "🌠 Versão melhorada: aprimoramos a estabilidade e o desempenho para uma experiência fantástica! 🌟",
      "🛠️ Atualização emocionante: mais estabilidade e desempenho otimizado para um uso mais prazeroso! 😁",
      "🎯 Nova versão: foco em estabilidade aprimorada e performance otimizada para uma experiência incrível! 🎉",
      "⚡ Versão atualizada: aprimoramento na estabilidade e aceleração no desempenho para uma experiência maravilhosa! 🌈",
      "🌐 Nova versão lançada: estabilidade reforçada e otimizações para proporcionar uma experiência excepcional! 💥"
    ];

    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  }

  private loadChangelog(): Observable<string> {
    return this.http.get('CHANGELOG.md', { responseType: 'text' });
  }

  private hasSeenNovelties(): boolean {
    return true;
    //return localStorage.getItem('seenNoveltiesVersion') === this.currentVersion;
  }

  private markNoveltiesAsSeen(): void {
    localStorage.setItem('seenNoveltiesVersion', this.currentVersion);
  }
}
