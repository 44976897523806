import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {NgForm} from "@angular/forms";
import {DialogService} from "@progress/kendo-angular-dialog";
import {ImageIfoodComponent} from "./imagem-ifood";

@Component({
  selector: 'app-acao-pedido-disputa',
  templateUrl: './acao-pedido-disputa.component.html',
  styleUrls: ['./acao-pedido-disputa.component.scss']
})
export class AcaoPedidoDisputaComponent implements OnInit {
  @Input() pedido: any = {};
  @ViewChild('frm', {}) frm: NgForm;
  public _submitted = false;
  erroDisputa: string
  processando: boolean;
  tempoRestante: any;
  constructor( protected pedidosService: PedidosService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.setTempoResposta();
  }

  aceitarCancelarPedidoDaDisputa(){
    if( this.processando ) return;

    delete this.erroDisputa;
    this.processando = true;

    this.pedidosService.finalizeDisputaComAceitar(this.pedido).then( (resp) => {
      this.processando = false;
      this.pedido.disputa.finalizada = true;
    }).catch( erro => {
      this.processando = false;
      this.erroDisputa = erro;
    })
  }

  naoAceitarCancelarPedidoDaDisputa(){
    if( this.processando ) return;

    delete this.erroDisputa;
    this.processando = true;

    this.pedidosService.finalizeDisputaComRejeitar(this.pedido, this.pedido.motivoRejeitarDisputa).then( (resp) => {
      this.processando = false;
      this.pedido.disputa.finalizada = true;
    }).catch( erro => {
      this.processando = false;
      this.erroDisputa = erro;
    })
  }


  enviePropostaDisputa(){
    this._submitted = true;

    if(this.frm.valid){
      if( this.processando ) return;

      delete this.erroDisputa;
      this.processando = true;

      this.pedidosService.enviePropostaDisputa(this.pedido, this.pedido.proposta.acao, this.pedido.proposta.valor).then( (resp) => {
        this.processando = false;
        this.pedido.disputa.aguardandoProposta = true;
      }).catch( erro => {
        this.processando = false;
        this.erroDisputa = erro;
      })
    }
  }

  confirmeNaoAceitarCancelarPedidoDaDisputa(){
    this.pedido.confirmarRejeitarDisputa = true;
  }

  volteAceitarCancelarPedidoDisputa(){
    this.pedido.confirmarRejeitarDisputa = false;
  }


  setTempoResposta(){
    if(!this.pedido.disputa || this.pedido.disputa.finalizada) return;

    if(this.pedido.disputa.dataExpiracao){
      this.pedido.disputa.dataExpiracao = new Date(this.pedido.disputa.dataExpiracao)
      this.updateCountdown();


      setInterval(() => this.updateCountdown(), 1000);
    }
  }

  updateCountdown() {

    const now = new Date();

    const remainingTime = Math.max(0, this.pedido.disputa.dataExpiracao.getTime() - now.getTime());
    const minutes = Math.floor(remainingTime / 60000);
    const seconds = Math.floor((remainingTime % 60000) / 1000);
    this.tempoRestante = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  }

  verImagem(url: string){
    let altura = window.innerHeight - 100;
    const dialogRef = this.dialogService.open({
      title: 'Foto enviada pelo cliente',
      content: ImageIfoodComponent,
      minWidth: 600,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: altura
    });

    let instence: any = dialogRef.content.instance;

    instence.imagemURL = url;
  }

}
