import { Component, OnInit } from '@angular/core';
import {PerformanceWhatsappService} from "../../services/performance-whatsapp.service";
import {DateRangePopupComponent} from "@progress/kendo-angular-dateinputs";

declare var moment;
@Component({
  selector: 'app-performance-whatsapp',
  templateUrl: './performance-whatsapp.component.html',
  styleUrls: ['./performance-whatsapp.component.scss']
})
export class PerformanceWhatsappComponent implements OnInit {
  public gridData: any[]  = [];
  loading = false;
  filtro: any = {};


  constructor(private performanceWhatsappService: PerformanceWhatsappService) {
    this.filtro.inicio = moment().add(-30, 'd').toDate();
    this.filtro.fim = new Date();
  }

  ngOnInit(): void {
    this.carregueRelatorio();
  }

  carregueRelatorio() {
    this.loading = true;
    let dtInicio = moment(this.filtro.inicio).format('YYYYMMDD');
    let dtFim = moment(this.filtro.fim).format('YYYYMMDD');
    this.performanceWhatsappService.busqueRelatorio(dtInicio, dtFim).then( (dados) => {
      this.loading = false;

      for(let i = 0; i < dados.length; i++) {
        let registro: any = dados[i];

        registro.indice = i + 1;
      }

      this.gridData = dados;
    }).catch( () => {
      this.loading = false;
    });
  }

  limpeDataFinal() {
    this.filtro.fim = '';
  }

  fechePopup(popup: DateRangePopupComponent) {
    setTimeout( () => {
      if( this.filtro.fim ) {
        popup.toggle();
      }
    }, 200);
  }
}
