import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewContainerRef,
  ComponentFactoryResolver, ComponentFactory
} from '@angular/core';
import {Router} from "@angular/router";
import {ImpressaoService} from "../../services/impressao.service";
import {PedidosService} from "../../services/pedidos.service";
import {ModalPedidoAceitarComponent} from "../modal-pedido-aceitar/modal-pedido-aceitar.component";
import {ModalPedidoStatusComponent} from "../modal-pedido-status/modal-pedido-status.component";
import { ModalVincularGarcomComponent } from '../modal-vincular-garcom/modal-vincular-garcom.component';
import {CadEnderecoContatoComponent} from "../cad-endereco-contato/cad-endereco-contato.component";
import {IOperacoesModuloPedidos} from "../../superadmin/services/ioperacoesmodulopedidos";
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {MudarStatusComponent} from "../../componentes/mudar-status/mudar-status.component";
import {ProcessadorReciboTexto} from "../../services/impressao/ProcessadorReciboTexto";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";


@Component({
  selector: 'app-pedido-acoes',
  templateUrl: './pedido-acoes.component.html',
  styleUrls: ['./pedido-acoes.component.scss']
})
export class PedidoAcoesComponent implements OnInit , AfterViewInit {

 @Input() pedido: any = {};
 @Input() entregadores: any = [];
 @Input() comanda: any ;
 @Input() empresa: any = {};
 @Input() configImpressao: any = {};
 @Input() modoGrupoLojas = false;
 @Output() onAtualizouComanda = new EventEmitter();
  processando: boolean;
  erroAceitar: string
  opcoesEdicao = [   ];
  opcoesImpressao = [
    'Completo',
    'Resumido'
  ];
  impressoras = [];

  private _podeEditarPedido: boolean;
  EDITARPEDIDO =  'Editar Pedido';
  MUDARSTATUS =   'Mudar Status';
  factory: ComponentFactory<MudarStatusComponent>;

  @Input() set podeEditarPedido(value: boolean) {
    this._podeEditarPedido = value;

    if(this.pedido.multipedido) return;

    if( this._podeEditarPedido && this.pedido.podeEditar ) {
      this.opcoesEdicao = [
        this.EDITARPEDIDO, this.MUDARSTATUS

      ];

      if(this.pedido.endereco)
        this.opcoesEdicao.push('Alterar Endereço')
    } else {
      this.opcoesEdicao = [  this.MUDARSTATUS ]
    }
  }

  get podeEditarPedido(): boolean {

    return this._podeEditarPedido;

  }
  imprimeNativo = false;
  constructor(private router: Router, private impressao: ImpressaoService,
              private grupolojasService: GrupolojasService,  private autorizacaoService: AutorizacaoService,
              private dialogService: DialogService, private pedidosService: PedidosService,
              private viewContainerRef: ViewContainerRef, private resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    if(this.configImpressao && this.configImpressao.multiplasImpressoras)
      this.impressoras = this.configImpressao.impressoras;

    this.factory = this.resolver.resolveComponentFactory(MudarStatusComponent);


    this.imprimeNativo = this.configImpressao && this.configImpressao.imprimirTXT;
  }

  ngAfterViewInit(): void {

     if(!RotaGuardClient.alterarPedido( this.autorizacaoService.getUser())){
        let i = this.opcoesEdicao.indexOf(this.MUDARSTATUS)

        if( i >= 0) this.opcoesEdicao.splice(i, 1);
     }

    if(!RotaGuardClient.editarPedido( this.autorizacaoService.getUser())){
      let i = this.opcoesEdicao.indexOf(this.EDITARPEDIDO)

      if( i >= 0) this.opcoesEdicao.splice(i, 1);
    }

  }



  escolheuOpcaoEditar(opcao: any, pedido: any) {
    if(opcao === 'Finalizar Pedido'){
       this.finalizarPedido(pedido)
    } else if( opcao === 'Editar Pedido' ) {
        this.editarPedido(pedido);
    }  else if( opcao === 'Alterar Endereço' ) {
        this.abraModalEditarEndereco(pedido);
    } else {
      this.mudarStatusPedido(pedido);
    }
  }

  abraModalEditarEndereco(pedido) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadEnderecoContatoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.setEndereco(pedido.endereco, true);

    windowRef.result.subscribe((result: any) => {
      if(result && result.id) {
        pedido.endereco = Object.assign(pedido.endereco, result);
        pedido.enderecoCompleto = result.enderecoCompleto;
      }
    } );

  }

  editarPedido(pedido: any){
    let linkPedido = '/admin/pedidos/' + pedido.guid;

    if(!this.modoGrupoLojas){
      this.router.navigateByUrl(linkPedido).then ( () => {});
    } else {
      window.open(String(`https://${this.pedido.empresa.dominio}.meucardapio.ai${linkPedido}`))
    }

  }

  finalizarPedido(pedido: any){
    let dados =  { contato: pedido.cliente, pedido: pedido, empresa:  {}}

    this.router.navigateByUrl('/admin/pedidos/finalizar-mesa/' + pedido.codigo + '?urlRetorno=' + location.pathname, { state: dados})
  }

  mudarStatusPedido(pedido: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalPedidoStatusComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.modoGrupoLojas = this.modoGrupoLojas
    windowRef.content.instance.pedido = pedido
    windowRef.content.instance.contato = pedido.contato;


    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          Object.assign(pedido, result)

        if(result && result.cancelado)
          this.onAtualizouComanda.emit( this.comanda);

      },
      (a) => {
      });


  }

  imprimaLayoutUnico(){
    let layout: string = this.configImpressao ? this.configImpressao.layoutPedido : null;

    if(this.configImpressao && this.configImpressao.imprimirTXT){
      this.imprimaNativamenteNoLayout(layout);
    } else {
      this.imprimaPedido(this.pedido , layout)
    }
  }

  prevenirSelecao(evt: MouseEvent): boolean {
    evt.stopPropagation();
    evt.preventDefault();
    return false;
  }

  imprimaUmDosLeiautes(opcao: any) {
    let leiaute = opcao === 'Completo' ? 'PedidoCompleto' : 'PedidoResumido';

    if(this.configImpressao && this.configImpressao.imprimirTXT){
      this.imprimaNativamenteNoLayout(leiaute);
    } else {
      this.imprimaPedido(this.pedido, leiaute)
    }
  }

  imprimaPedido(pedido: any, layoutPedido: any) {
    let link = '/imprimir/pedido/' + pedido.guid

    if(layoutPedido)
      link += '?l=' + layoutPedido

    window.open(link);
  }

 async imprimaNativamenteNoLayout(leiaute: string){
    let impressora = this.configImpressao.impressoras[0];
    impressora.layout = leiaute;
    await this.imprimaNativamente(impressora)
  }

 async imprimaNativamente(impressora: any) {
    if(!impressora) return alert('Nenhuma impressora configurada');

    await this.impressao.imprimaQzTray(this.pedido, impressora)

   if(this.pedido.erroImpressao) alert(this.pedido.erroImpressao)
  }

  abraModalVincularGarcom(pedido: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalVincularGarcomComponent,
      minWidth: 250,
      width: window.innerWidth > 500 ? 500 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.empresa =  this.empresa;
    windowRef.content.instance.comanda = this.comanda;

    if( this.pedido.garcom)
      windowRef.content.instance.setGarcom( this.pedido.garcom)

    windowRef.result.subscribe( (comanda: any) => {
        if(comanda && comanda.id)
          this.onAtualizouComanda.emit(comanda);
      });
  }

  abraModalVerPedido(pedido: any){
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalPedidoAceitarComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.pedido = pedido

    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          Object.assign(pedido, result)
      },
      (a) => {
      });
  }
  notifiqueSistemaIntegrado($event) {
    if( this.processando ) return;

    delete this.erroAceitar;
    this.processando = true;

    this.getService().notifiqueSistemaIntegrado(this.pedido).then( (resp) => {
      this.pedido.referenciaExterna = resp;
      this.pedido.naoNotificado = false;
      this.processando = false;
    }).catch( erro => {
      this.processando = false;
      this.erroAceitar = erro;
      alert(erro)
    })

    if($event){
      $event.stopPropagation();
      $event.preventDefault();
    }
  }


  getService(): IOperacoesModuloPedidos {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }
}
