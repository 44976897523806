<h4 class="page-title">  <i class="far fa-building"></i>
  Entregadores</h4>

<div class="card">
  <div class="card-box">
    <kendo-tabstrip   [class]="'tabs'" (tabSelect)="trocouDeAba()">
      <kendo-tabstrip-tab [title]="'Cadastro'" [selected]="true">
        <ng-template kendoTabContent>
          <app-grid-entregador></app-grid-entregador>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Relatórios'" >
        <ng-template kendoTabContent>
          <kendo-panelbar class="mb-0 pb-0" style="    border-bottom: 0;">
            <kendo-panelbar-item [title]="'Filtros'" [expanded]="true"  >
              <ng-template kendoPanelBarContent>
                <div class="card card-body  pt-2 pb-0">
                  <div class="row filtro">
                    <div class="col-md-auto" *ngIf="!periodoEspecifico">
                      <label>
                        <span class="label lh">Mês <br></span>
                        <kendo-datepicker style="width: 20em"  class="form-control"
                                          [bottomView]="'year'"
                                          [topView]="'year'"
                                          [format]="'MMMM yyyy'"
                                          [activeView]="'year'"
                                          [max]="hoje"
                                          (valueChange)="mudouValorMes($event)"
                                          [(value)]="valorMes">
                        </kendo-datepicker>

                        <p class="text-blue cpointer mt-2" (click)="exibirPeriodoEspecifico()">
                          Período especifico</p>
                      </label>

                    </div>

                    <div  class="col-md-auto" *ngIf="periodoEspecifico">
                      <kendo-daterange  class="mr-2" >
                        <label>
                          <span class="label lh">De <br></span>
                          <kendo-datetimepicker style="width: 16em"  [(ngModel)]="filtro.inicio"  (ngModelChange)="carregueRelatorio()">
                            <kendo-datetimepicker-messages
                              today="Hoje"
                              toggle="Alterar data"
                              cancel="Cancelar"
                              dateTab="Data"
                              timeTab="Hora"
                              now="Agora"
                              accept="Ok"
                            ></kendo-datetimepicker-messages>

                          </kendo-datetimepicker>
                        </label>
                        <label class="ml-2 ">
                          <span class="label  lh">Até </span><br>
                          <kendo-datetimepicker style="width: 16em" [(ngModel)]="filtro.fim" (ngModelChange)="carregueRelatorio()" >
                            <kendo-datetimepicker-messages
                              today="Hoje"
                              toggle="Alterar data"
                              cancel="Cancelar"
                              dateTab="Data"
                              timeTab="Hora"
                              now="Agora"
                              accept="Ok"
                            ></kendo-datetimepicker-messages>
                          </kendo-datetimepicker>
                        </label>
                        <p class="text-blue cpointer" (click)="exibirPeriodoMensal()">
                          Período mensal</p>

                      </kendo-daterange>
                    </div>
                    <div class="col-3"  >
                      <label class="w-100">
                        <span class="label lh">Por entregador <br></span>
                        <kendo-dropdownlist filterable="true"  name="entregador" [(ngModel)]="filtro.entregador"
                                            (ngModelChange)="carregueRelatorio()"
                                            (filterChange)="busqueEntregadores($event)" [data]="entregadores" textField="nome"
                                            class="form-control">
                        </kendo-dropdownlist>
                      </label>


                    </div>
                  </div>
                </div>

              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar>
          <div class="card">
            <div class="card-body">
              <div *ngIf="!pedidosEntregador">
                <kendo-tabstrip #tabVendas class="nav-bordered mt-3"   >

                  <kendo-tabstrip-tab [title]="'Entregas por entregador'" [id]="'entregas'" [selected]="true">
                    <ng-template kendoTabContent>
                      <kendo-grid
                        [style]=""
                        [kendoGridBinding]="relatorioEntregas"
                        [loading]="carregandoRelatorio" style="min-height: 300px;">

                        <kendo-grid-messages
                          pagerPage="Página"
                          pagerOf="de"
                          pagerItems="itens"
                          noRecords="nenhuma entrega no período"
                          loading="Carregando"
                          pagerItemsPerPage="ítems por página"
                        >
                        </kendo-grid-messages>
                        <!--
                        <ng-template kendoGridToolbarTemplate>
                          <div class="float-right">
                            <button type="button" class="btn btn-outline-secondary btn-sm   "
                                    kendoGridExcelCommand icon="file-excel">Exportar</button>


                          </div>
                        </ng-template>
                        -->
                        <kendo-grid-column title="Entregador" field="nomeEntregador"  >
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
                            <span class="text-dark">{{relatorioEntregador.nomeEntregador}}</span>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Primeira Entrega" field="inicio" >
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
                            {{relatorioEntregador.inicio | date: "dd/MM/yyyy HH:mm"}}
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Última Entrega" field="fim" >
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
                            {{relatorioEntregador.fim | date: "dd/MM/yyyy HH:mm"}}
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Corridas" field="qtdCorridas" >
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
                            {{relatorioEntregador.qtdCorridas}}
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Total taxas"  field="totalTaxas">
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
              <span class="text-success">
                 <b>   {{relatorioEntregador.totalTaxas | currency: "BRL"}}</b>
              </span>

                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Qtd Entregas Grátis" field="qtdEntregasGratis" >
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
                            {{relatorioEntregador.qtdEntregasGratis}}
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Total desconto taxas"  field="totalDescontoTaxa">
                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
              <span class="text-success">
                 <b>   {{relatorioEntregador.totalDescontoTaxa | currency: "BRL"}}</b>
              </span>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column  >

                          <ng-template kendoGridCellTemplate let-relatorioEntregador  >
                            <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verPedidosEntregador(relatorioEntregador)"  title="ver detalhes" kendoTooltip>
                              <i class="fe-list fa-lg"></i>
                            </button>
                          </ng-template>

                        </kendo-grid-column>

                        <!--
                                                <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('relatorio-entregas')"></kendo-grid-excel>
                        -->
                      </kendo-grid>

                      <div class="mt-2 mb-2"></div>
                    </ng-template>
                  </kendo-tabstrip-tab>


                </kendo-tabstrip>

              </div>
              <ng-container *ngIf="pedidosEntregador">
                <h4>
                  <button class="btn btn-outline-blue btn-rounded" (click)="voltarPedidosEntregador()">
                    <i class="fa fa-arrow-left ct-point  "></i>
                  </button>
                  Pedidos do entregador <span><b>{{pedidosEntregador.entregador.nome}}</b></span></h4>

                <div class="row">
                  <div class="col">
                    <div class="card card-body text-xs-center">
                      <h4 class="card-title text-muted">      Taxa de Entrega (sem desconto)</h4>
                      <h5 class="card-text text-warning">
                        {{pedidosEntregador.totalAntesDesconto | currency: "BRL"}}

                        <i class="k-icon k-i-loading " *ngIf="carregandoPedidosEntregador"></i>
                      </h5>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card card-body text-xs-center">
                      <h4 class="card-title text-muted">      Descontos Taxa</h4>
                      <h5 class="card-text text-warning">
                        {{pedidosEntregador.totalDescontos | currency: "BRL"}}

                        <i class="k-icon k-i-loading " *ngIf="carregandoPedidosEntregador"></i>
                      </h5>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card card-body text-xs-center">
                      <h4 class="card-title text-muted">      Taxa de Entrega </h4>
                      <h5 class="card-text text-warning">
                        {{pedidosEntregador.totalTaxas | currency: "BRL"}}

                        <i class="k-icon k-i-loading " *ngIf="carregandoPedidosEntregador"></i>
                      </h5>
                    </div>
                  </div>

                  <!--</div>
                  <div class="row">-->
                  <div class="col">
                    <div class="card card-body text-xs-center">
                      <h4 class="card-title text-muted">      Quantidade de Entregas       </h4>
                      <h5 class="card-text text-warning">
                        {{pedidosEntregador.qtdCorridas}}

                        <i class="k-icon k-i-loading " *ngIf="carregandoPedidosEntregador"></i>
                      </h5>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-body text-xs-center">
                      <h4 class="card-title text-muted">      Entregas Grátis       </h4>
                      <h5 class="card-text text-warning">
                        {{pedidosEntregador.entregasGratis}}

                        <i class="k-icon k-i-loading " *ngIf="carregandoPedidosEntregador"></i>
                      </h5>
                    </div>
                  </div>
                </div>


                <!--
                <h5 class="mt-2">
                  Vendas dos adicionais {{obtenhaDescricaoFiltro()}}</h5>
            -->
                <kendo-grid
                  [style]=""
                  [kendoGridBinding]="pedidosEntregador.pedidos"
                  [loading]="carregandoPedidosEntregador" style="min-height: 300px;">

                  <kendo-grid-messages
                    pagerPage="Página"
                    pagerOf="de"
                    pagerItems="itens"
                    noRecords="nenhum pedido  "
                    loading="Carregando"
                    pagerItemsPerPage="ítems por página"
                  >
                  </kendo-grid-messages>
                  <!--                  <ng-template kendoGridToolbarTemplate>
                    <button type="button" class="float-right" kendoGridExcelCommand icon="file-excel">Exportar para Excel</button>
                  </ng-template>
                  -->

                  <kendo-grid-column title="Codigo" field="codigo"  width="90"  >
                    <ng-template kendoGridCellTemplate let-resumoPedido >
                      <a [routerLink]=" (modoGrupoLojas ? ('/admin-grupo/pedidos/detalhes/'+ resumoPedido.empresa.id + '/')
                      : '/admin/pedidos/detalhes/') + resumoPedido.guidPedido"
                      >
                        {{resumoPedido.codigoPedido}} </a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column title="Valor" field="valorPedido"  width="130px">
                    <ng-template kendoGridCellTemplate let-resumoPedido let-rowIndex="rowIndex">
                      <span><b>{{resumoPedido.valorPedido | currency: "BRL"}}</b></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column title="Tx. Entrega" field="taxaEntrega"  width="130px">
                    <ng-template kendoGridCellTemplate let-resumoPedido let-rowIndex="rowIndex">
                      <span class="text-muted" *ngIf="resumoPedido.taxaEntrega ===  -1"><b>A Informar</b></span>
                      <span class="text-muted" *ngIf="resumoPedido.taxaEntrega !== -1"><b>{{resumoPedido.taxaEntrega | currency: "BRL"}}</b></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column title="Desc. Tx." field="descontoTaxaEntrega"  width="130px">
                    <ng-template kendoGridCellTemplate let-resumoPedido let-rowIndex="rowIndex">
                      <span class="text-muted" ><b>{{resumoPedido.descontoTaxaEntrega | currency: "BRL"}}</b></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column title="Cliente"  field="nomeCliente"  >
                    <ng-template kendoGridCellTemplate let-resumoPedido let-rowIndex="rowIndex">

                      <a class="" routerLink="/admin/contatos/{{resumoPedido.telefoneCliente}}">
                        <div class="info-contato" >
                          <span class="abreviar nome">{{resumoPedido.nomeCliente}}<br></span>
                          <span>{{resumoPedido.telefoneCliente | tel}}</span>
                        </div>
                      </a>

                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column title="Entrega"  field="enderecoCompleto" >
                    <ng-template kendoGridCellTemplate let-resumoPedido let-rowIndex="rowIndex">
                      <ng-container
                      >
       <span class="d-block cpointer font-12" >
          {{resumoPedido.enderecoEntregaCompleto }}

       </span>
                      </ng-container>
                    </ng-template>
                  </kendo-grid-column>
                  <!--<kendo-grid-excel [fileName]="obtenhaNomeArquivo()"></kendo-grid-excel>-->
                </kendo-grid>

              </ng-container>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>

  </div>
</div>
