<div class="campanha">
<div>
<div *ngIf="campanha.tipoDeEnvio === 'Unico'">
  <i class="fab fa-whatsapp"
     style="font-size: 52px;line-height: 2em;float: left;"></i>
</div>
<div *ngIf="campanha.tipoDeEnvio === 'Agendado'">
  <i class="far fa-clock mr-2" style="font-size: 52px;line-height: 2em;float: left;"></i>
</div>
<div class="row">
  <div class="col-7">
    <div class="row">
      <div class="col-auto" *ngIf="campanha.linkImagem">
        <img class="img-fluid rounded" [src]="'/images/empresa/' + campanha.linkImagem" style="width: 200px"/>
      </div>
      <div class="col">
        <h3>
          <a [href]="'https://' + campanha.empresa.dominio + '.promokit.com.br/admin/nova-campanha/' + campanha.id" target="_blank">
          {{campanha.id}}. {{campanha.nome}}
          </a>
        </h3>
        <p>
          <span class="font-14">{{campanha.dataCriacao | date: 'dd/MM/yyyy HH:mm'}}</span>
        </p>
        <p>
          <span class="font-16">Todos Contatos</span>
        </p>
        <p style="min-height: 80px"><i>{{campanha.mensagem}}</i></p>
      </div>
    </div>

    <div class="mt-1">
      <span class="badge badge-pill badge-success" *ngIf="campanha.status === 'Nova' && campanha.status !== 'Nova'"> <i class="fe-user"></i> Nova</span>
      <span class="badge badge-pill badge-secondary" *ngIf="campanha.status === 'Enviada'"> <i class="fe-user"></i>Enviada</span>
      <span class="badge badge-pill badge-warning" *ngIf="campanha.status === 'DEV'"> <i class="fe-user"></i>Enviando</span>


      <div *ngIf="!campanha.foiTestada && campanha.tipoDeEnvio === 'Agendado' && campanha.status === 'Nova'">
                    <span class="badge badge-pill badge-danger">
                      Campanha agendada deve ser testada pra ser enviada.
                    </span>

        <button class="ml-2 btn btn-sm btn-secondary"
                (click)="testarCampanha(campanha);">Testar Campanha</button>
      </div>

      <span class="badge badge-pill badge-warning" *ngIf="campanha.foiTestada && campanha.tipoDeEnvio === 'Agendado' && campanha.status === 'Nova'">
                    <i class="far fa-clock" ></i>
        {{campanha.horario}}
          </span>
    </div>

    <p class="mt-2 mb-1" *ngIf="campanha.status === 'Nova' && campanha.tipoDeEnvio === 'Agendado'">
      Horário Envio: <b>{{campanha.horarioEnvio | date: 'dd/MM/yyyy HH:mm:SS'}}</b>
    </p>

    <div class="row mt-2" *ngIf="campanha.statusAprovacao === 'Pendente' && !modoAprovacao">
      <div class="col">
        <div class="alert alert-warning">
          <p>
            <strong>Atenção!</strong>
          </p>

          Campanha foi enviada para aprovação. Entre em contato com o suporte no <b>Whatsapp</b> para acelerar a aprovação.
        </div>
      </div>
    </div>
  </div>
  <div class="col-5">
    <div class="row d-flex align-items-center" style="height: 120px;">
      <div class="col" *ngIf="false">
        <h1 class="mb-0">{{campanha.qtdeLojasAceitaram || '-'}}</h1>
        <p>&nbsp;</p>
      </div>
      <div class="col" *ngIf="false">
        <h1 class="mb-0">{{campanha.qtdeMensagens}}</h1>
        <p>total</p>
      </div>
      <div class="col acoes" *ngIf="!modoAprovacao">
        <div class="row">
          <span class="badge badge-pill badge-success" *ngIf="campanha.status === 'Enviando' || campanha.status === 'ParouEnvio'">{{campanha.status}}</span>
          <ng-container>
            <button class="btn btn-blue btn-block"  (click)="editarCampanha(campanha)"
                    *ngIf="campanha.status === 'Nova'" [disabled]="enviando">Editar Campanha</button>
            <button class="btn btn-block btn-secondary" (click)="replicarCampanha(campanha);"
                    *ngIf="!campanha.foiReplicada" [disabled]="enviando">Replicar Campanha</button>
          </ng-container>
          <ng-container *ngIf="campanha.foiReplicada">
            <button class="btn btn-block btn-danger" (click)="desativarCampanha(campanha);"
                    title="Lojas não poderão mais aceitar a campanha." *ngIf="campanha.ativa"
                    [disabled]="enviando">Desativar Campanha</button>
            <button class="btn btn-block btn-success" (click)="ativarCampanha(campanha);"
                    title="Lojas poderão aceitar a campanha." *ngIf="!campanha.ativa"
                    [disabled]="enviando">Ativar Campanha</button>

            <span class="alert alert-success mt-2">Campanha foi replicada para as lojas!</span>

            <div class="mt-2 w-100">
              <button class="btn btn-block btn-success" (click)="verQuemAceitouCampanha(campanha);"
                      title="Lojas poderão aceitar a campanha."
                      [disabled]="enviando">Quem Aceitou?</button>
            </div>
          </ng-container>
          <button class="btn btn-success btn-block"   (click)="verDetalhesCampanha(campanha)" *ngIf="campanha.status === 'Enviada'">Relatório de Mensagens</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="row">
  <div class="col">
    <div class="alert alert-success" role="alert" *ngIf="mensagemSucesso">
      <i class="mdi mdi-check-all mr-2"></i> <span class="msg">
        {{mensagemSucesso}}
      </span>
    </div>
    <div class="alert alert-warning" role="alert" *ngIf="mensagemWarning">
      <i class="fa fa-times mr-2"></i> <span class="msg">
        {{mensagemWarning}}
      </span>
    </div>
  </div>
</div>
</div>
