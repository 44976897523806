import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UsuarioService} from "../../../services/usuario.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {ContatosService} from "../../../services/contatos.service";
import {Observable} from "rxjs";
import {AutorizacaoService} from "../../../services/autorizacao.service";
import * as async from "async";
import {FileRestrictions, SuccessEvent, UploadComponent} from "@progress/kendo-angular-upload";
import {ErrorCallback} from "async";
import {CampanhaRedeService} from "../../../services/campanha-rede.service";
import {TelaEscolherCupomCampanhaComponent}
  from "../../../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {TelaEscolherDominioEmpresaComponent}
  from "../../../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component";
import {ConstantsService} from "../../../fidelidade/ConstantsService";

declare let moment;

@Component({
  selector: 'app-nova-campanha-rede',
  templateUrl: './nova-campanha-rede.component.html',
  styleUrls: ['./nova-campanha-rede.component.scss']
})
export class NovaCampanhaRedeComponent implements OnInit {
  @Output() onVoltar = new EventEmitter();
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  agora = new Date();
  resumo = {total: 0, qtdeAtivos: 0 , qtdeInativos: 0, qtdeNovo: 0 };
  contatos: Array<any> = [];
  contatosBusca: Array<any> = [];
  contato: any;
  paginacao: any = {totalPorPagina: 5, pagina: 1, total: 0 };
  EXPRESSAO_URL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;
  filtro: any = {};
  carregando = false;
  qtdeCaracteresRestantes = 160;
  QTDE_MAXIMA_CARACTERES = 160;
  public steps: any = { hour: 0, minute: 15 };
  msgErroUpload = '';
  campanha = {
    id: null,
    nome: '',
    mensagem: '',
    status: 'Nova',
    filtro: null,
    horarioEnvio: moment().add('1', 'hour').set({m: 0}).toDate(),
    tipoDeEnvio: 'Unico',
    origemContatos: 'Todos',
    files: [],
    contatos: [],
    rede: 'chinainbox',
    redes: []
  };
  filtros: any  = [];
  campos = [{
    nome: 'Nome do Contato',
    texto: '[NomeContato]',
  }, {
    nome: 'Nome Completo Contato',
    texto: '[NomeCompletoContato]',
  }, {
    nome: 'Telefone do Contato',
    texto: '[TelefoneContato]',
  }, {
    nome: 'Empresa',
    texto: '[Empresa]',
  } , {
    nome: 'Link Cartão',
    texto: '[LinkCartao]',
  }, {
    nome: 'Codigo',
    texto: '[CODIGO]',
  }, {
    nome: 'Link Ativar Cartão',
    texto: '[LinkAtivarCartao]',
  }, {
    nome: 'Link Cardápio',
    texto: '[Link_Cardapio]',
  }, {
    nome: 'Link Avaliar Pedido',
    texto: '[Link_Avaliar]'
  }, {
    nome: 'Link Cupom',
    texto: 'Cupom',
  }, {
    nome: 'Link Inteligente Cardápio (Outra Empresa)',
    texto: 'Link_Cardapio_DARK',
  }, {
    nome: 'Nova Mensagem',
    texto: '[Nova_Mensagem]',
  }, {
    nome: 'Saldo Cashback Trigo',
    texto: '[Saldo_Cashback_Trigo]',
  }];
  mensagemSucesso: string;
  salvando: boolean;
  mensagemErro: any;
  private state$: Observable<object>;
  empresa: any;
  importou: boolean;

  public listItems: Array<any> = [{
    id: 1,
    nome: 'chinainbox'
  }, {
    id: 2,
    nome: 'gendai'
  }
  ];

  restricoes: FileRestrictions = {
    allowedExtensions: ['.csv']
  };
  private arquivo: File;
  private conteudoArquivo: any;
  private colunasImportar: any = [];
  private removeuPrimeiraLinha: boolean;
  separadorDecimal: any;
  opcoesSeparadorDecimal: any = [
    {codigo: ',', descricao: "Separado por ','"},
    {codigo: '.', descricao: "Separado por '.'"}
  ]
  usuario: any = null;
  rede: string;
  constructor(public activatedRoute: ActivatedRoute, private  contatoService: ContatosService, private router: Router,
              private usuarioService: UsuarioService, private campanhaRedeService: CampanhaRedeService,
               private constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService, private dialogService: DialogService) {
  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();

    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if (!empresa) return;
      this.empresa = empresa;
      this.rede = empresa.dadosRede ? empresa.dadosRede.grupo : null;

      if( this.empresa.meioDeEnvio === 'Whatsapp' ) {
        this.qtdeCaracteresRestantes = 1000;
        this.QTDE_MAXIMA_CARACTERES = 1000;
      }


    });

    this.usuarioService.listeFiltros().then( filtros => {
      if(filtros.length > 0)
        this.filtros = filtros

      if(window.history.state && window.history.state.data){
        this.setCampanha(window.history.state.data)
      } else {
        this.activatedRoute.params.subscribe(params => {
          let id = +params['idc']; // (+) converts string 'id' to a number

          if(id){
            this.campanhaRedeService.obtenhaCampanha(id).then((resposta: any) => {
              this.setCampanha(resposta)
            });
          }
        });
      }
    });
  }

  setCampanha(campanha: any) {
    this.campanha = campanha;
    this.contatos = campanha.contatos;

    if(this.campanha.horarioEnvio)
      this.campanha.horarioEnvio = new Date(this.campanha.horarioEnvio);

    this.alterouMensagem(this.campanha);
    this.filtreContatos();
  }

  voltar() {
    this.router.navigateByUrl('/admin-rede/marketing').then( () => {});
  }

  filtreContatos() {
    if(!this.filtro || this.campanha.origemContatos !== 'Filtro') {
      this.filtro = {}
      return;
    }

    this.carregando = true;
    this.busqueContatos(0 , () => {
      let params = this.getParamsFiltro();
      this.contatoService.obtenhaResumo(params).then( resumo => {
        this.resumo = resumo;
        this.paginacao.total = this.resumo.total;
        this.carregando = false;
      });
    })
  }

  busqueContatos(inicio, cb: Function) {
    let params = this.getParamsFiltro();
    if( this.campanha && this.campanha.id ) {
      params.idc = this.campanha.id;
    }

    this.contatoService.listeContatos(inicio, this.paginacao.totalPorPagina, params).then( contatos => {
      this.contatosBusca = contatos;
      this.contatos = contatos;

      for( let i = 0; i < this.contatos.length; i++ ) {
        let contato = this.contatos[i];

        if(contato.mensagemProcessada){
          setTimeout( async () => {
            const urls = contato.mensagemProcessada.match(this.EXPRESSAO_URL);

            async.eachSeries(urls,
              (url, cb: ErrorCallback<Error>) => {
                // tslint:disable-next-line:max-line-length
                contato.mensagemProcessada =
                  contato.mensagemProcessada.replace(url, "<a href='" + url + "' target='_blank'>" + url + "</a>");
                cb();
              });
          });
        }
      }

      cb();
    })
  }

  getParamsFiltro(): any {
    return {};
  }

  concluiuPasso1() {
  }

  alterouMensagem(notificacao: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = notificacao.mensagem.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(notificacao.mensagem)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[LinkCartao]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if ( campo === '[Empresa]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      }
    }

    this.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - notificacao.mensagem.length + qtdARemover - qtdAAdicionar;
  }

  adicioneNomeContato(textArea, notificacao: any) {
    const posicao = this.getCaretPos(textArea);

    notificacao.mensagem = notificacao.mensagem.substr(0, posicao) + '[Nome_Contato]' + notificacao.mensagem.substr(posicao);
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      return oField.selectionStart;
    }

    return 0;
  }

  setCaretPosition(elem, caretPos) {
    if (elem !== null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        if (elem.setSelectionRange) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    }
  }

  adicioneCampo(textArea, campanha: any, campo: any) {
    if( campo.texto === 'Link_Cardapio_DARK' ) {
      TelaEscolherDominioEmpresaComponent.abraComoDialog(this.dialogService, this.empresa,
        (dominioEmpresa) => {
          this.insiraCampoNaCaixaDeTexto(textArea, campanha, {
            texto: `[Link_Cardapio_${dominioEmpresa}]`
          });
        });

      return;
    } else if( campo.texto === 'Cupom' ) {
      TelaEscolherCupomCampanhaComponent.abraComoDialog(this.dialogService,
        this.empresa, (cupom) => {
        this.insiraCampoNaCaixaDeTexto(textArea, campanha, {
          texto: `[CUPOM_${cupom}]`
        });
      });

      return;
    }

    this.insiraCampoNaCaixaDeTexto(textArea, campanha, campo);
  }

  insiraCampoNaCaixaDeTexto(textArea, campanha: any, campo: any) {
    const posicao = this.getCaretPos(textArea);

    campanha.mensagem = campanha.mensagem.substr(0, posicao) + campo.texto + campanha.mensagem.substr(posicao);

    textArea.focus();

    setTimeout( () => {
      textArea.setSelectionRange(posicao + campo.texto.length, posicao + campo.texto.length);
    }, 50);
  }

  enviarMensagens() {
    if( !this.campanha.id ) {
      this.campanhaRedeService.salveCampanha(this.campanha).then(resp => {
        this.campanha.id = resp.id;
        this.mensagemSucesso = 'Campanha criada com sucesso criado!';
        this.salvando = false;
        this.router.navigateByUrl('/admin-rede/marketing').then( () => {});
      }).catch(erro => {
        this.salvando = false;
        this.mensagemErro = erro;
      });
    } else {
      this.router.navigateByUrl('/admin-rede/tela-enviar-mensagens/' + this.campanha.id).then( () => {});
    }
  }

  apenasSalvar() {
    this.salvando = true;
    this.campanhaRedeService.salveCampanha(this.campanha).then( resp =>   {
      this.campanha.id = resp.id;
      this.campanha.contatos = this.contatos;
      this.mensagemSucesso = 'Campanha criada com sucesso criado!';
      this.salvando = false;
      this.router.navigateByUrl('/admin-rede/marketing').then( () => {});
    }).catch(erro => {
      this.salvando = false;
      this.mensagemErro = erro;
    });
  }

  temFiltro(){
    if( !this.filtro || !this.filtro.dados ) {
      return [];
    }

    return  Object.keys(this.filtro.dados).length > 0;
  }

  selecionouArquivo($event: any) {
    this.msgErroUpload = '';
  }

  mudouOrigemContatos() {
    if( this.campanha.origemContatos === 'Filtro' ) {
      this.contatos = this.contatosBusca;
    } else {
      this.contatos = this.campanha.contatos;
    }
  }

  enviouArquivoDeContatos($event: SuccessEvent) {
    const respUpload = $event.response.body;

    if( respUpload.sucesso ) {
      this.contatos = respUpload.data;
      this.campanha.contatos = this.contatos;
    }
  }

  uploadFalhou($event: any) {
    this.msgErroUpload = $event.response.error.erro;
  }
}
