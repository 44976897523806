<form (ngSubmit)="clicou()">
<div class="mt-2">
  <h5 class="card-title">Restringir pedidos para as seguintes cidades</h5>
</div>

<div class="row">
  <div class="col-12 col-xl-8">
    <kendo-grid
  [kendoGridBinding]="gridView"
  [pageSize]="10"
  [pageable]="true"
  [sortable]="true"
  [reorderable]="true"
  [resizable]="false"
  [loading]="carregando"
  [columnMenu]="{ filter: false, columnChooser: false }"
>
  <ng-template kendoGridToolbarTemplate>
    <input placeholder="Filtrar cidades" kendoTextBox (input)="onFilter($event.target.value)"/>
    <button class="btn btn-blue" (click)="novaCidade()" type="button">+ Nova Cidade</button>
    <p>{{cidadesEscolhidas.length}} cidades</p>
  </ng-template>
    <kendo-grid-column
      field="nome"
      title="Nome Cidade"
      [resizable]="false"
      filter="numeric">
    </kendo-grid-column>
    <kendo-grid-column
      field="estado.nome"
      title="Estado"
      [width]="200"
      [resizable]="false"
      filter="numeric">
    </kendo-grid-column>
  <kendo-grid-column [width]="110">
    <ng-template kendoGridCellTemplate let-cidade let-rowIndex="rowIndex">
      <button type="button" class="btn btn-danger btn-xs mt-1"  (click)="remover(cidade)">
        <i class="fe-trash"></i>
      </button>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages noRecords="Nenhuma cidade encontrada.">
  </kendo-grid-messages>
</kendo-grid>
  </div>
</div>

<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja remover a <strong>{{cidadeARemover.nome}}</strong>?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" primary="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
</form>
