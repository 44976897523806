import { Component, OnInit } from '@angular/core';
import {MesaService} from "../../../services/mesa.service";
import {Mesa} from "../../objetos/Mesa";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import {Router} from "@angular/router";
import {AutorizacaoService} from "../../../services/autorizacao.service";
import {ModalVincularGarcomComponent} from "../../../fidelidade/modal-vincular-garcom/modal-vincular-garcom.component";

import {PedidoLocalService} from "../../../services/pedido-local.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../../lib/KendoPopupUtils";

@Component({
  selector: 'app-tela-novo-pedido-mesa',
  templateUrl: './tela-novo-pedido-mesa.component.html',
  styleUrls: ['./tela-novo-pedido-mesa.component.scss']
})
export class TelaNovoPedidoMesaComponent implements OnInit {
  mesas: Array<Mesa>;
  empresa: any;
  filtro = '';
  modoGarcom: boolean;
  usuario: any;
  mesasIntegracao: any = [];
  carregandoMesasIntegradas  = false;
  constructor(private router: Router, private constantsService: ConstantsService,
              private pedidoLocalService: PedidoLocalService, private dialogService: DialogService,
              private mesaService: MesaService, private autorizacaoService: AutorizacaoService) { }

  ngOnInit(): void {
    this.modoGarcom =  this.autorizacaoService.getUser().garcom;
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) { return; }

      this.empresa = empresa;

      this.pedidoLocalService.removaPedido('+5500000000000');

      this.mesaService.obtenhaMesas(this.empresa).then( async (mesas) => {
        this.mesas = mesas;

        if( !this.mesasIntegracao.length && this.empresa.integracaoDelivery){
          this.carregandoMesasIntegradas = true;
          this.mesasIntegracao = await this.mesaService.obtenhaMesasIntegradas();
          this.carregandoMesasIntegradas = false;
          if(this.mesasIntegracao.length)
            this.setMesasIntegradas();
        }

      });
    });
  }

  setMesasIntegradas(){
    this.mesas.forEach((mesa: any) => {
      if(mesa.codigoPdv && !mesa.mesaIntegrada)
        mesa.mesaIntegrada = this.mesasIntegracao.find((item: any) => Number(item.num) === Number(mesa.codigoPdv))
    })
  }


  abraModalVincularGarcom(mesa: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalVincularGarcomComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa =  this.empresa;
    windowRef.content.instance.mesa =  mesa

    windowRef.result.subscribe( (comanda: any) => {
        if(!comanda) return;
        const garcom = comanda.garcom,
              codigoComanda = comanda.codigoPdv;

        if(garcom && garcom.id)
          this.abraLinkNovoPedido(mesa, garcom, codigoComanda)
      } );
  }

  mesaEscolhida(mesa: Mesa) {
    if(!this.modoGarcom  && this.empresa.integracaoDelivery && this.empresa.integracaoDelivery.sistema === 'ecletica'){
        this.abraModalVincularGarcom(mesa)
    } else {
      this.abraLinkNovoPedido(mesa)
    }
  }

  abraLinkNovoPedido(mesa, garcom = null, codigoComanda = null){
    let queryParams: any = {
      idm: mesa.id,
      modoEdicao: false
    }

    if(garcom) queryParams.idg = garcom.id;
    if(codigoComanda) queryParams.cc = codigoComanda;

    if(this.modoGarcom) queryParams.mg = true

    queryParams.urlRetorno = '/admin/pedidos-mesas'

    this.router.navigate(['/admin/pedidos/novo'],
      {queryParams: queryParams}).then( () => {});
  }

}
