import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'app-menu-rede',
  templateUrl: './menu-rede.component.html',
  styleUrls: ['./menu-rede.component.scss']
})
export class MenuRedeComponent implements OnInit {
  usuario: any = {};
  empresa: any;
  carregou = true;
  url = '';
  rede: string;

  constructor(private router: Router, private route: ActivatedRoute,
      private renderer: Renderer2, private autorizacaoService: AutorizacaoService, private constantsService: ConstantsService) {
    route.url.subscribe(() => {
      this.url = route.snapshot.firstChild.routeConfig.path;
    });

    this.autorizacaoService.usuarioLogado$.subscribe( (usuario) => {
      if( !usuario ) return;

      this.usuario = usuario;
    });

    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if (!empresa) return;
      this.empresa = empresa;
      this.rede = empresa.dadosRede ? empresa.dadosRede.grupo : null;
    });
  }

  ngOnInit(): void {
  }

  naveguePara( url: string ) {
    this.router.navigateByUrl( '/admin-rede/' + url).then( (resposta: boolean) => {
      if( !resposta ) {
        return;
      }

      if(document.body.classList.contains('sidebar-enable'))
        this.renderer.removeClass(document.body, 'sidebar-enable')
      else
        this.renderer.addClass(document.body, 'sidebar-enable')

      this.url = url;
    });
  }
}
