import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MiaService} from "../services/mia.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-controle-previa-mensagem-whatsapp',
  templateUrl: './controle-previa-mensagem-whatsapp.component.html',
  styleUrls: ['./controle-previa-mensagem-whatsapp.component.scss']
})
export class ControlePreviaMensagemWhatsappComponent implements OnInit {
  @Input() mensagem = {
    mensagem: '',
    temMenu: false,
    textoSecao: '',
    menu: {
      textoSecao: '',
      tituloMensagem: '',
      textoBotao: '',
      opcoes: []
    }
  };
  exibaMenu = false;

  constructor(private miaService: MiaService, private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
  }

  @ViewChild('bottomSheetTemplate') bottomSheetTemplate: TemplateRef<any>;


  openBottomSheet() {
    this.exibaMenu = true;
  }
}
