import {FormaDeEntrega} from "./FormaDeEntrega";
import {Endereco} from "./Endereco";

export class Entrega {
  formaDeEntrega: FormaDeEntrega;
  endereco: Endereco;
  taxaDeEntrega: number;
  bairroOpcional = false;
  cepObrigatorio = false;
  complementoObrigatorio = false;
  taxaCalculadaId: any;
  valorComRetorno: number;
  foiPreenchida() {
    if( !this.formaDeEntrega ) {
      return false;
    }

    if( this.formaDeEntrega === FormaDeEntrega.RETIRAR ) {
      return true;
    }

    return this.endereco != null;
  }

  ehRetirada(){
    return this.formaDeEntrega && this.formaDeEntrega === FormaDeEntrega.RETIRAR
  }

  ehDelivery(){
    return this.formaDeEntrega && this.formaDeEntrega === FormaDeEntrega.RECEBER_EM_CASA
  }

  obtenhaForma(){
    if(this.formaDeEntrega === FormaDeEntrega.RETIRAR)
      return { id: 1 } ;

    if(this.formaDeEntrega === FormaDeEntrega.RECEBER_EM_CASA)
      return { id: 2 };
  }

  setTaxaEntrega(endereco: any, dados: any) {
    if(dados)  Object.assign(endereco, dados)

    this.endereco = endereco;
    this.taxaDeEntrega = endereco.taxaDeEntrega;
    this.taxaCalculadaId = endereco.taxaCalculadaId;

    if(endereco.taxaRetorno)
      this.valorComRetorno =  Number((this.taxaDeEntrega + endereco.taxaRetorno).toFixed(2))

  }
}
