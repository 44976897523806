<div style="position: relative;border: solid 1px #eaeaea;" class="row">
  <div class="col-auto p-0">
    <div class="conversas" style="overflow: hidden scroll;width: 300px;" [style.height.px]="altura">
      <div class="simplebar-content" style="padding: 0px;">

        <div *ngFor="let conversa of conversas" class="conversa"  [ngClass]="{'selecionado': conversa === conversaSelecionada}"
             (click)="abraConversa(conversa)">
          <div class="media p-2">
            <div class="media-body overflow-hidden">
              <h5 class="mt-0 mb-0 font-16">
                <span class="float-right text-muted font-weight-normal font-12">{{conversa.timestampWpp * 1000 | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                <ng-container *ngIf="conversa.nome">
                  {{conversa.nome}} <span class="font-13" style="color: #999">{{conversa.telefone}}</span>
                </ng-container>
                <ng-container *ngIf="!conversa.nome">
                  {{conversa.telefone}}
                </ng-container>
              </h5>
              <p class="mt-1 mb-0 text-muted font-13 d-flex align-items-center">
        <span style="color: #667781; white-space: nowrap; display: inline-block; overflow: hidden; text-overflow: ellipsis;">
          {{conversa.ultimaMensagem}}
        </span>
                <span class="ml-auto text-right">
          <span class="badge badge-soft-danger"></span>
        </span>
              </p>
            </div>
          </div>
          <hr class="mt-0 mb-0">
        </div>
      </div>
    </div>
  </div>
  <div class="col p-0">
    <div *ngIf="!conversaSelecionada" class="conversa-vazia">
      <i class="fas fa-comments"></i>
      <p>Nenhuma conversa selecionada</p>
    </div>

    <kendo-chat
      style="max-width: 100%;margin: 0px;" [style.height.px]="altura"
      [messages]="feed | async"
      [user]="user"
    >
      <ng-template kendoChatAttachmentTemplate let-attachment>
      </ng-template>
      <ng-template kendoChatMessageTemplate let-message>
        <div *ngIf="message.attachments && message.attachments.length > 0">
          <div class="k-card">
            <img [src]="message.attachments[0].content" style="width: 350px"/>
          </div>
        </div>
        <div [innerHTML]="message.text" style="max-width: 350px;font-size: 13px;line-height: 15px;">
        </div>
        <span class="font-11 text-muted">{{message.timestamp | date: 'HH:mm'}}</span>
      </ng-template>
    </kendo-chat>
    <div class="fundo-chat2"></div>
    <div class="fundo-chat"></div>
  </div>
</div>

