import {Component, OnInit, ViewChild} from '@angular/core';
import {Atividade} from "../../../../../server/domain/Atividade";
import {AtividadeService} from "../../../services/atividade.service";
import {NgForm} from "@angular/forms";
import {EnumTipoDePontuacao} from "../../../../../server/lib/emun/EnumTipoDePontuacao";
import {PlanosService} from "../../services/planos.service";
import {ModalKendo} from "../../../lib/ModalKendo";

@Component({
  selector: 'app-cad-atividade',
  templateUrl: './cad-atividade.component.html',
  styleUrls: ['./cad-atividade.component.scss']
})
export class CadAtividadeComponent extends ModalKendo  implements OnInit {
  @ViewChild('frm', { static: true})  frm: NgForm;
  empresa: any;
  mensagemSucesso: any;
  mensagemErro: any;
  atividade: Atividade;
  planos: any;
  salvando = false;
  constructor(private atividaeService: AtividadeService, private planosService: PlanosService,
              ) {
    super();
  }

  ngOnInit() {
    if(!this.atividade)
      this.setAtividade( new Atividade())
    this.planosService.obtenhaPlanos(this.empresa.id, 0).then( (resposta: any) => {
      this.planos = resposta.data;
      if(this.atividade.plano){
        this.planos.forEach( (plano) => {
          if(plano.id === this.atividade.plano.id)
            this.atividade.plano = plano;
        })
      }
    });
  }

  setAtividade(atividade){
    this.atividade = atividade;
  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  onSubmit() {
    delete this.mensagemErro;

    if( this.frm.valid ){
      this.salvando = true;
      this.atividade.empresa = this.empresa;
      this.atividaeService.salveAtividade(this.atividade).then( resposta => {
        this.salvando = false;
        if(!this.atividade.id) this.atividade.id = resposta.id;
        this.fecheModal(this.atividade)
      }).catch( erro => {
        this.mensagemErro   = erro;
        this.salvando = false;
      })
    }
  }

  informarPontosNaAtividade() {
    if(!this.atividade.plano || !this.atividade.plano.tipoDePontuacao) return false;

    return  this.atividade.plano.tipoDePontuacao.tipo === EnumTipoDePontuacao.QtdeFixaPorAtividade
  }

  informarCashback(){
    if(!this.atividade.plano || !this.atividade.plano.tipoDePontuacao) return false;

    return  this.atividade.plano.tipoDePontuacao.tipo === EnumTipoDePontuacao.Cashback
  }
}
