import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalKendo} from "../../lib/ModalKendo";
import {ProdutoService} from "../../services/produto.service";
import {GridComponent} from "@progress/kendo-angular-grid";

import {ExcelExportComponent} from "@progress/kendo-angular-excel-export";

declare var moment;

@Component({
  selector: 'app-modal-exportar-produtos',
  templateUrl: './modal-exportar-produtos.component.html',
  styleUrls: ['./modal-exportar-produtos.component.scss']
})
export class ModalExportarProdutosComponent    extends ModalKendo  implements OnInit {
  carregandoProdutos = true;
  produtos = []
  catalogo: any = {}
  total = 0;
  produtosSelecionadosIds = []
  temEstoque = true;
  @ViewChild('excelExport', { static: true }) excelExport: ExcelExportComponent;

  constructor(private produtoService: ProdutoService ) {
    super()
  }

  async ngOnInit() {
    let temProdutos = true, i = 0, total = 100;
    let todosProdutos = []
    while(temProdutos){
      let respProdutos: any = await this.produtoService.listeTodos(this.catalogo.id, i, total, this.temEstoque);

      if(respProdutos && respProdutos.produtos.length){
        todosProdutos.push(...respProdutos.produtos )
        this.total = respProdutos.total;
        i  += total;
      } else {
        temProdutos = false;
      }
    }

    this.produtos = todosProdutos;
    this.produtosSelecionadosIds = this.produtos.map((item: any) => item.id);
    this.carregandoProdutos = false;

    this.produtos.forEach((produto: any) => {
      if(produto.imagens && produto.imagens.length)
        produto.imagem = String(`https://promokit.com.br/images/empresa/${produto.imagens[0].linkImagem}`)
      produto.disponivel = produto.disponibilidade === 2 ? 'Não' : 'Sim'
      this.processeAdicionais(produto);
    })

  }

  downloadCsv() {
    let link = '/exportar/produtos/csv';
   // let qs = new FiltroTelaProdutos(this.temEstoque(),
   //   this.categoriaSelecionada, this.filtroDisponibilidades, this.filtro.texto, this.filtro.semCodigoPdv).toQueryString()

    window.open(link  );
  }


  processeAdicionais(produto: any){
    let opcoes  = [];

    if(produto.camposAdicionais){
      for(let i = 0; i < produto.camposAdicionais.length; i++){
        let adicinal: any = produto.camposAdicionais[i];

        if(adicinal.opcoesDisponiveis && adicinal.opcoesDisponiveis.length){
          adicinal.opcoesDisponiveis.forEach((opcao: any) => {
              opcao.nomeAdicional = adicinal.nome;
              opcao.nomeOpcao = opcao.nome;
              opcao.nome = '';
              if(opcao.linkImagem)
                opcao.imagem = String(`https://promokit.com.br/images/empresa/${opcao.linkImagem}`)
              opcao.disponivel = opcao.disponibilidade  ? 'Sim' : 'Não'
              opcoes.push(opcao)
          })
        }
      }
    }


    produto.opcoes = opcoes;
  }

  public async exportToExcel(grid: GridComponent): Promise<void> {
    let colunasOcultas = ['ordem', 'nomeAdicional', 'nomeOpcao', 'descricao', 'imagem'];

    colunasOcultas.forEach((nomeColuna) => {
      let coluna = grid.columns.find((item: any) => item.field === nomeColuna);
      coluna.hidden = false;
    });

    let allData = [];

    const gridData = Array.isArray(grid.data) ? grid.data : grid.data.data;

    for (const dataItem of gridData) {
      if( this.produtosSelecionadosIds.includes(dataItem.id)){
        allData.push(dataItem);

        if(dataItem.opcoes)  allData.push(...dataItem.opcoes);
      }
    }

    //nomeOpcao
    this.excelExport.data = allData;
    // @ts-ignore
    this.excelExport.columns = grid.columns;
    this.excelExport.save();

    colunasOcultas.forEach((nomeColuna) => {
      let coluna = grid.columns.find((item: any) => item.field === nomeColuna);
      coluna.hidden = true;
    });
  }

  obtenhaNomeArquivoDownload( ) {
    let sufixo =  moment().format('DDMMYYYYHHmmss');

    return String(`Produtos_Catalogo_${this.catalogo.id}_${sufixo}.xls`)
  }

}
