import {Component, Input, OnInit} from '@angular/core';
import {CadDisponibilidadeComponent} from "../cad-disponibilidade/cad-disponibilidade.component";
import {DisponibilidadeService} from "../services/disponibilidade.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";



@Component({
  selector: 'app-view-disponibilidade',
  templateUrl: './view-disponibilidade.component.html',
  styleUrls: ['./view-disponibilidade.component.scss']
})
export class ViewDisponibilidadeComponent implements OnInit {
  @Input() catalogo: any = {}
  @Input() disponibilidades = [];
  disponibilidadesSelecionadas = []
  disponibilidadesExibir  = [ ]

  public columns: any[] = [
    { field: "diasDescricao", title: "Dias da semana", width: "50%" },
    { field: "horariosDescricao", title: "Horarios", width: "50%" }
  ];

  constructor(    private dialogService: DialogService,
                 private disponibilidadeService: DisponibilidadeService) { }

  ngOnInit(): void {
    if(this.catalogo){
      let disponibilidades: any =
        this.disponibilidadeService.obtenhaDisponibidadesComDescricao(this.catalogo.disponibilidades)

      this.disponibilidades =
        disponibilidades.filter((item: any) => this.disponibilidades.find((dispo: any) => dispo.id === item.id) != null)

      this.disponibilidadesSelecionadas = this.disponibilidades.map((item: any) => item.id);

    }

    this.setDisponibilidadesSemana();

  }

  setDisponibilidadesSemana(){
    this.disponibilidadesExibir = [];

    this.disponibilidades.forEach((disp: any) => {
      let disponibilidadeExibir: any = {
        descricaoInicioFim: disp.descricaoInicioFim,
        dias:  [
          { dia: 0 , label: 'Dom', disponivel: false, turnos: [] },
          { dia: 1 , label: 'Seg', disponivel: false, turnos: [] },
          { dia: 2 , label: 'Ter', disponivel: false , turnos: []},
          { dia: 3 , label: 'Qua', disponivel: false , turnos: []},
          { dia: 4 , label: 'Qui', disponivel: false , turnos: []},
          { dia: 5 , label: 'Sex', disponivel: false , turnos: []},
          { dia: 6 , label: 'Sab', disponivel: false , turnos: []}
        ]
      }

      disp.periodos.forEach((periodo: any) => {
        periodo.dias.forEach((dia: any) => {
          disponibilidadeExibir.dias.forEach((diaSemama: any) => {
            if(diaSemama.dia === dia.dia){
              diaSemama.disponivel = true;
              if(periodo.horaInicio && periodo.horaFim){
                this.setHorariosTurnos(periodo)
                diaSemama.turnos.push(periodo)
              } else {
                diaSemama.fullTime = true;
              }
            }
          })
        })
      })

      this.disponibilidadesExibir.push(disponibilidadeExibir)
    })
  }

  setHorariosTurnos(periodo){
    periodo.horaInicio = this.horarioToDate( periodo.horaInicio)
    periodo.horaFim = this.horarioToDate( periodo.horaFim)
  }



  get disponibilidadesOrdenadas() {
    if(! this.catalogo) return []

    return this.catalogo.disponibilidades.sort((a, b) => a.descricao.localeCompare(b.descricao));
  }


  private horarioToDate(horario: any): Date{
    if(horario && typeof horario === 'string'){
      let horarios: any =    horario.split(':');
      let date = new Date();
      date.setHours( horarios[0] );
      date.setMinutes( horarios[1] );

      return date;
    } else {
      return horario;
    }
  }

  abraModalNovaDisponibilidade(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadDisponibilidadeComponent,
      minWidth: 250,
      width: window.innerWidth > 500 ? 500 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });


    windowRef.content.instance.catalogo = this.catalogo;

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe((novaDisponibilidade: any) => {
      if(novaDisponibilidade && novaDisponibilidade.id){
        this.disponibilidadeService.obtenhaDescricao(novaDisponibilidade)
        this.disponibilidades.push(novaDisponibilidade)
        this.catalogo.disponibilidades.push(novaDisponibilidade);
        this.setDisponibilidadesSemana();
      }
    });

    return false;

  }
}
