import {ApplicationRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {Router} from "@angular/router";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {ImpressaoService} from "../../services/impressao.service";
import {TelaImprimirPedido} from "../../fidelidade/TelaImprimirPedido";
import {MonitoradorPedidos} from "../../fidelidade/MonitoradorPedidos";
import {NgForm} from "@angular/forms";
import {EnumMomentoImprimirAuto} from "../../../../server/lib/emun/EnumMomentoImprimirAuto";
import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {IOperacoesModuloPedidos} from "../../superadmin/services/ioperacoesmodulopedidos";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {EntregadorService} from "../../services/entregador.service";
import {timer} from "rxjs";
import {RazaoCancelamentoIfoodComponent} from "../razao-cancelamento-ifood/razao-cancelamento-ifood.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mudar-status',
  templateUrl: './mudar-status.component.html',
  styleUrls: ['./mudar-status.component.scss']
})
export class MudarStatusComponent extends TelaImprimirPedido implements OnInit , OnDestroy {
  @ViewChild('razaoCancelmentoComp',{ static: false}) razaoCancelmentoComp: RazaoCancelamentoIfoodComponent;
  @Input() urlRetorno: string;
  @Input() contato: any;
  @Input() modoGrupoLojas: boolean;
  @Output() onAtualizouStatus = new EventEmitter();
  @Input() visivel = true;
  timerCancelamento: any;
  pedidoAlterado: any = {};
  motivoCancelamento: string
  StatusEmPreparacao = 1;

  codigosStatus = {
    "Novo": 0,
    "Em preparação": this.StatusEmPreparacao,
    "Pronto": 2,
    "Saiu para entrega": 3,
    "Entregue": 4,
    "Cancelado": 5,
    "Devolvido": 6
  }
  notificar: any = true;
  erro: string;
  salvando: boolean;
  cancelando: boolean;
  atualizarFoiPago: boolean;
  pagamento: any = {}
  entregadores: any;
  carregandoEntregadores: boolean;

  @Input()
  set pedido(val: any) {
    this._pedido = val;
    this.pedidoAlterado = {}

    Object.assign(this.pedidoAlterado, this._pedido)

    if(this.entregadores) {
      this.selecioneEntregadorNoPedido(this.entregadores)
    }

    if(this.pedidoAlterado.pago === undefined) this.pedidoAlterado.pago = false;

    this.atualizarFoiPago = (!this.pedidoAlterado.pago || !this.pedido.pontosGanhos) && !this._pedido.pagamentoOnline

    this._pedido.ehNovo = this.codigosStatus[this._pedido.status] === this.codigosStatus.Novo;

    if(this.novoStatus)
      this.pedidoAlterado.status = this.novoStatus

    if(this.pedido.balcao) this.notificar = false;

  }

  get pedido() {
    return this._pedido;
  }

  @Input()
  novoStatus: boolean;

  @Input()
  set exibirApenasAtualizarEntregador(val: any) {
    this._exibirApenasAtualizarEntregador = val;
  }

  get exibirApenasAtualizarEntregador() {
    return this._exibirApenasAtualizarEntregador
  }



  _exibirApenasAtualizarEntregador: boolean;

  constructor(protected pedidosService: PedidosService, private router: Router,  private constantsService: ConstantsService,
              public monitoradorPedidos: MonitoradorPedidos,   private  grupolojasService: GrupolojasService,
              protected impressao: ImpressaoService,  private autorizacaoService: AutorizacaoService,
              private entregadorService: EntregadorService,
              private app: ApplicationRef) {
    super(impressao, pedidosService)
    this.carregandoEntregadores = true
  }

  ngOnInit() {
   this.notificar = true;

   this.entregadorService.liste().then((entregadores) => {
     this.carregandoEntregadores = false;
     this.entregadores = entregadores

     this.selecioneEntregadorNoPedido(this.entregadores)


   })
    if(!this.modoGrupoLojas)
      this.constantsService.empresa$.subscribe( empresa => this.empresa = empresa || {}  )

    if(this.novoStatus)
      this.pedidoAlterado.status = this.novoStatus
  }



  obtenhaNovosStatus(){
    let novosStatus = [ ]

    if(this.pedido.naoAlterarStatus){
      novosStatus.push(this.pedido.status)
    } else {
      if(this.temPermissaoAlterarStatus()  && this.pedidoPodeSerAlterado()){
        novosStatus = ['Em preparação', 'Pronto']

        if(this.pedido.formaDeEntrega && this.pedido.formaDeEntrega !== 'Retirar')
          novosStatus.push('Saiu para entrega')

        novosStatus.push('Entregue')
      }
    }

    if(this.temPermissaCancelarPedido() && !this.pedidoComandaFechada())
      novosStatus.push('Cancelado')


    return novosStatus;
  }

  submitForm(frm: NgForm){
    if(this.pedidoComandaFechada()){
      this.onAtualizouStatus.emit(null)
      return;
    }

    if(frm && !frm.valid) return;

    if(this.vaiCancelar())
      return this.cancelePedido();

    return this.atualizarStatus();
  }

  cancelePedido(){
    delete   this.erro;
    if(this.cancelando) return;
    this.cancelando = true;

    let motivo = (this.pedido.doIfood && this.razaoCancelmentoComp ? this.razaoCancelmentoComp.razaoCancelamento : this.motivoCancelamento);

    this.getService().cancelePedido(this.pedido, motivo, this.notificar).then(async (resposta) => {

      if(resposta.aguardandoPdv  ) {
        this.pedido.aguardandoPdv = true;
        this.inicieMonitoramentoCancelamento(resposta.idEvent);
      } else if(resposta.aguardarGateway) {
        this.pedido.aguardandoEstorno = true;
        this.pedidoAlterado.status = null;
      } else {
       this.setPedidoCancelado();
      }
    }).catch( (erro) => {
      this.cancelando = false;
      this.erro = erro;
    })
  }

  setPedidoCancelado(){
    this.cancelando = false;
    this.pedido.cancelado =  true;
    this.pedido.status = this.pedidoAlterado.status
    this.atualizouStatus(false);
  }

  ngOnDestroy(): void {
    this.pareMonitoramento();
  }

  pareMonitoramento(){
    this.pedido.aguardandoPdv = false;
    this.cancelando = false;

    if( this.timerCancelamento)
      this.timerCancelamento.unsubscribe();
  }

  inicieMonitoramentoCancelamento(idEvent){
    let segundosTimer = 5;
    this.timerCancelamento =  timer(2000, 1000 * segundosTimer  ).subscribe( () => {
       console.log('verificar pedido: ' + this.pedido.id)
       this.pedidosService.obtenhaStatusCancelamento(this.pedido, idEvent).then( (resposta: any) => {
         if(resposta.pdvReconheceu)  this.pedido.pdvReconheceu = true;

         if(resposta.rejeitouCancelamento){
           this.pedido.motivoRejeicao  =  resposta.motivo;
           this.pareMonitoramento();
         }

          if(resposta.cancelado){
            this.setPedidoCancelado();
            this.pareMonitoramento();
          }
       })
    });
  }

  atualizarStatus(cb: any = null ) {
    delete   this.erro;
    if(this.salvando) return;
    this.salvando = true;

    let codigoNovoStatus = this.codigosStatus[this.pedidoAlterado.status];

    this.getService().altereStatus(this.pedido, codigoNovoStatus, this.notificar).then(async (resposta) => {
      if(resposta && resposta.erroNotificar)
        this.erro = resposta.erroNotificar;

      if(resposta.delivery)
          this.pedido.delivery = resposta.delivery;

      if(this.pedido.status !==  this.pedidoAlterado.status){
        this.pedido.status = this.pedidoAlterado.status
        this.pedido.cancelado = resposta.cancelado;
        if( codigoNovoStatus === this.StatusEmPreparacao && this._pedido.empresa.aceitarPedidoAutomatico)
          if(this.empresa.configImpressao && this.empresa.configImpressao.momentoImprimirAuto !== EnumMomentoImprimirAuto.NovoPedido)
            await this.envieImpressaoAutomatica();

      }

      if(this.pedidoAlterado.objetoEntregador) {
        if(!this.pedido.objetoEntregador || this.pedidoAlterado.objetoEntregador.id !== this.pedido.objetoEntregador.id) {
          await this.getService().altereEntregador(this.pedido, this.pedidoAlterado.objetoEntregador)
          this.pedido.objetoEntregador = this.pedidoAlterado.objetoEntregador
          this.pedido.entregador = this.pedidoAlterado.objetoEntregador.nome
          this.pedido.entregadorReduzido = this.pedidoAlterado.objetoEntregador.nomeReduzido
        }
      }

      if(this.atualizarFoiPago && this.pedidoAlterado.pago !== this.pedido.pago && !this.vaiCancelar()) {
       this.getService().altereFoiPago(this.pedido, this.pedidoAlterado.pago, this.pagamento.formaDePagamento).then((resp) => {
          this.pedido.pago = this.pedidoAlterado.pago;
          this.atualizouStatus(resp.redirectAdicionarPontos);

          if(cb) cb(true);

        }).catch( (erro) => {
            this.salvando = false;
            this.erro = erro;
            if(cb) cb(false, erro);
        })
      } else {
          this.atualizouStatus(false);
          if(cb) cb(true);
      }
    }).catch( (erro) => {
      this.salvando = false;
      this.erro = erro;
      if(cb) cb(false, erro);
    })
  }

  atualizouStatus(redirectAdicionarPontos){
    this.onAtualizouStatus.emit(this.erro);

    if(this.pedido.ehNovo)
      this.monitoradorPedidos.alterouStatusPedido(this.pedido);

    if(this.pedido.pago &&  redirectAdicionarPontos && !this.erro)
      this.adcionarPontos(this.contato, this.pedido.subvalor)
    else {
      if(this.urlRetorno && !this.erro)
        this.router.navigateByUrl(this.urlRetorno, {state: { mensagem: 'Pedido  #' + this.pedido.codigo + ' atualizado com sucesso!'}});

      this.salvando = false;
    }

  }

  adcionarPontos(contato: any, valor: number) {
    let state: any = { contato: contato, valor: valor };
    let urlRetorno = this.urlRetorno ? this.urlRetorno : this.router.url

    if(contato.cartoes && contato.cartoes.length ){
       state.cartao = contato.cartoes[0];

       this.router.navigateByUrl('/admin/contatos/pontos/adicionar?urlRetorno=' + urlRetorno,
        { state: state });
    } else {

      this.router.navigateByUrl('/admin/contatos/novo?urlRetorno=' + urlRetorno,
        { state: state });
    }
  }

  getService(): IOperacoesModuloPedidos {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }


  vaiCancelar() {
    return this.pedidoAlterado.status === 'Cancelado' || this.pedidoAlterado.status === 'Devolvido'
  }

  estornarOnline() {
    if(this.vaiCancelar() && this._pedido.pagamentoOnline && this._pedido.pago)
      return true;

    return false;
  }

  temPermissaCancelarPedido() {
    return RotaGuardClient.cancelarPedido(this.autorizacaoService.getUser())
  }

  temPermissaoAlterarStatus(){
    return RotaGuardClient.alterarStatusPedido(this.autorizacaoService.getUser())

  }

  pedidoPodeSerAlterado(){
    if(this.pedido.aguardandoPagamentoOnline) return false;

    if(this.pedido.comanda && !this.pedido.comanda.aberta)
      return false;

    if(this.pedido.agendadoParaOutroDia) return  false;

    return true;
  }

  pedidoComandaFechada(){
    if(this.pedido.comanda && this.pedido.comanda.id)
      return !this.pedido.comanda.aberta;

    return false;
  }

  private selecioneEntregadorNoPedido(entregadores: any) {
    for(let entregador of entregadores)
      if(this.pedidoAlterado.objetoEntregador && this.pedidoAlterado.objetoEntregador.id === entregador.id) {
        this.pedidoAlterado.objetoEntregador = entregador
        this.app.tick();
        /*         setTimeout(() => {

                 }, 0)*/
      }

  }
}
