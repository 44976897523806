import {TamanhoPapel} from "./TamanhoPapel";

export class LayoutPedido {
  public tamanhoColunaData: number;
  public tamanhoColunaTelefone: number;
  public tamanhoColunaValor: number;

  private constructor(
    public papel: TamanhoPapel,
    public tamanhoColunaCodigo: number,
    public tamanhoColunaCliente: number,
    public tamanhoColunaDescricao: number,
    public tamanhoColunaQtd: number,
  ) {
    this.tamanhoColunaData = this.papel.tamanhoLinha - this.tamanhoColunaCodigo
    this.tamanhoColunaTelefone = this.papel.tamanhoLinha - this.tamanhoColunaCliente
    this.tamanhoColunaValor = this.papel.tamanhoLinha - (this.tamanhoColunaDescricao + this.tamanhoColunaQtd)
  }

  static obtenhaLayout(tamanhoPapel: TamanhoPapel) {
    switch (tamanhoPapel) {
      case TamanhoPapel.Papel58mm:
        return new LayoutPedido(
          TamanhoPapel.Papel58mm,
          8,
          20,
          20,
          5
        )
      case TamanhoPapel.Papel58mm32car:
        return new LayoutPedido(
          TamanhoPapel.Papel58mm32car,
          8,
          15,
          15,
          5
        )
      case TamanhoPapel.Papel80mm:
        return new LayoutPedido(
          TamanhoPapel.Papel80mm,
          8,
          30,
          30,
          5
        )
      case TamanhoPapel.Papel80mm42car:
        return new LayoutPedido(
          TamanhoPapel.Papel80mm42car,
          8,
          25,
          25,
          5
        )
      default:
        return new LayoutPedido(
          TamanhoPapel.Papel80mm,
          8,
          30,
          30,
          5
        )
    }
  }

}


