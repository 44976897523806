import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class ImportadorIfoodService    extends ServerService {

  listeNovosProdutosIfood(link: string, reducao: number, sincronizarPrecos: boolean = false,
                          sincronizarImagens: boolean = false){
    let params: any = {};

    if(reducao >= 0) params.reducao = reducao;
    if(sincronizarPrecos) params.precos = true;
    if(sincronizarImagens) params.imagens = true;

    if(link.split('/').length > 0){
      let partes: any = link.split('/');
      link = partes[partes.length - 1 ];
    }


    return this.obtenha(  String(`/importador/ifood/produtos/${link}/novos`), params)
  }

  importeProduto(produto: any){
    return this.facaPost('/importador/produto', produto)
  }

  importe(idEmpresa: number, link: number, reducao: number) {
    return this.salve('/importador/empresa/' + idEmpresa + '/ifood', {link: link, reducao: reducao})
  }

  finalizouImportacao(){
    return this.facaPost('/importador/finalizou', {})
  }



}
