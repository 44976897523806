import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SocketService extends ServerService {
  private socket: Socket;

  constructor(protected http: HttpClient) {
    super(http);

    const protocol = window.location.protocol;
    const host = window.location.hostname;
    const port = 3000;
    const socketUrl = `https://${host}`;

    this.socket = io(socketUrl, {
      transports: ['websocket'],
      path: '/testesocketio'
    });

    this.on('reload').subscribe(() => {
      window.location.reload();
      window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {tipo: 'RELOAD'}}, "*");
    });
  }

  // Método para emitir eventos para o servidor
  emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }

  atualizeAbas() {
    return this.obtenha('/sockets/atualizeAbas', {});
  }

  // Método para ouvir eventos do servidor
  on(eventName: string): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(eventName, (data: any) => {
        debugger;
        observer.next(data);
      });

      // Limpeza quando o observable é destruído
      return () => this.socket.off(eventName);
    });
  }
}
