import { Component, OnInit } from '@angular/core';
import {DialogService} from "@progress/kendo-angular-dialog";
import {CadastroVitrineComponent} from "../cad-vitrine/cadastro-vitrine.component";
import {VitrinesService} from "../../superadmin/services/vitrines.service";
import {TelaAssociarVitrineProdutosComponent} from "../tela-associar-vitrine-produtos/tela-associar-vitrine-produtos.component";

@Component({
  selector: 'app-vitrines',
  templateUrl: './vitrines.component.html',
  styleUrls: ['./vitrines.component.scss']
})
export class VitrinesComponent implements OnInit {
  vitrines = [];
  carregando: boolean;
  vitrineApagar: any
  modalDeletar: boolean
  trocandoOrdem: boolean;
  opcoesSubir = [
    {
      text: 'Subir 1 posição',
      valor: 'SUBIR_1'
    },
    {
      text: 'Subir para Topo',
      valor: 'SUBIR_TOPO'
    }
  ];

  opcoesDescer = [
    { text: 'Descer 1 posição', valor: 'DESCER_1'},
    { text: 'Descer para final', valor: 'DESCER_TUDO' }
  ];

  constructor(private dialogService: DialogService, private vitrinesService: VitrinesService) { }

  ngOnInit(): void {
    this.carregueVitrines();
  }

  private abraModalVitrine(vitrine: any) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Vitrine ',
      content: CadastroVitrineComponent,
      minWidth: 500,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const tela: any = windowRef.content.instance;

    tela.windowRef = windowRef;
    tela.vitrine = Object.assign({}, vitrine);

    windowRef.result.subscribe((result: any) => {
      if(result && result.id)
        this.carregueVitrines();
    });
  }

  novaVitrine() {
    this.abraModalVitrine({ ordem: this.vitrines.length + 1})
  }

  editarVitrine(vitrine: any){
    this.abraModalVitrine(vitrine);
  }

  abraDialogRemover($event: any) {
    this.vitrineApagar = $event.dataItem;

    this.modalDeletar = true;
  }

  private carregueVitrines() {
    this.carregando = true;
    this.vitrinesService.listeVitrines( ).then((vitrines: any) => {
      this.vitrines = vitrines;
      this.carregando = false;
    })
  }

  abraModalAssociarProdutos(vitrine: any) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: String(`Vitrine ${vitrine.nome}  `),
      content: TelaAssociarVitrineProdutosComponent,
      minWidth: 600,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: altura
    });

    const tela: TelaAssociarVitrineProdutosComponent = windowRef.content.instance;

    tela.windowRef = windowRef;
    tela.vitrine = vitrine;

    windowRef.result.subscribe((produtos: any) => {
        if(produtos && produtos.length >= 0)
          vitrine.produtos = produtos
    });
  }

  ativarVitrine(vitrine: any) {
    vitrine.disponivel = true;
    this.vitrinesService.ativeVitrine(vitrine).then( () => {

    }).catch( erro => {
      vitrine.disponivel = false;
      alert(erro)
    })
  }

  desativarVitrine(vitrine: any) {
    vitrine.disponivel = false;
    this.vitrinesService.desativeVitrine(vitrine).then( () => {
    }).catch( erro => {
      vitrine.disponivel = true;
      alert(erro)
    })
  }

  subir1Posicao(vitrine: any) {
    this.trocandoOrdem = true;
    this.vitrinesService.subir1Posicao(vitrine).then( (dados) => {
      this.trocandoOrdem = false;
        this.carregueVitrines();
    }).catch( erro => {
      this.trocandoOrdem = false;
      alert(erro);
    });
  }

  descer1Posicao(vitrine) {
    this.trocandoOrdem = true;
    this.vitrinesService.descer1Posicao(vitrine).then( (dados) => {
      this.trocandoOrdem = false;
      this.carregueVitrines();
    }).catch( erro => {
      this.trocandoOrdem = false;
      alert(erro);
    });
  }

  clicouSubir(vitrine: any, indice: number,  opcao: any) {
    if( opcao.valor === 'SUBIR_1' ) {
      this.subir1Posicao(vitrine);
    } else if( opcao.valor === 'SUBIR_TOPO' ) {
      this.trocandoOrdem = true;
      this.vitrinesService.subirParaTopo(vitrine).then( (dados) => {
        this.trocandoOrdem = false;
        this.carregueVitrines();
      }).catch( erro => {
        this.trocandoOrdem = false;
        alert(erro);
      });
    }
  }

  clicouDescer(vitrine: any, indice: number, opcao: any) {
    if( opcao.valor === 'DESCER_1' ) {
      this.descer1Posicao(vitrine);
    } else if( opcao.valor === 'DESCER_TUDO' ) {
      this.trocandoOrdem = true;
      this.vitrinesService.descerParaFinal(vitrine).then( (dados) => {
        this.trocandoOrdem = false;
        this.carregueVitrines();
      }).catch( erro => {
        alert(erro);
      });
    }
  }

}
