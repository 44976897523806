import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {BehaviorSubject} from "rxjs";
import {ArmazenamentoService} from "../services/armazenamento.service";


@Injectable()
export class ConstantsService {
  private moduloPedido = new BehaviorSubject<boolean>(null);
  private moduloFidelidade = new BehaviorSubject<boolean>(null);
  private moduloChatbot = new BehaviorSubject<boolean>(false);
  private moduloChatbotInstagram = new BehaviorSubject<boolean>(false);
  private moduloGarcom = new BehaviorSubject<boolean>(false);
  private moduloApp = new BehaviorSubject<boolean>(false);
  private moduloEntregadores = new BehaviorSubject<boolean>(false);
  private moduloPdv = new BehaviorSubject<boolean>(false);
  private somenteCardapio = new BehaviorSubject<boolean>(null);
  private exibirProdutos = new BehaviorSubject<boolean>(null);
  private empresa = new BehaviorSubject<any>(null);
  private campoCpf = new BehaviorSubject<any>(null);
  private campoDataNascimento = new BehaviorSubject<any>(null);

  moduloPedido$ = this.moduloPedido.asObservable();
  moduloFidelidade$ = this.moduloFidelidade.asObservable();
  moduloChatbot$ = this.moduloChatbot.asObservable();
  somenteCardapio$ = this.somenteCardapio.asObservable();
  exibirProdutos$ = this.exibirProdutos.asObservable();
  moduloGarcom$ = this.moduloGarcom.asObservable();
  moduloEntregadores$ = this.moduloEntregadores.asObservable();
  moduloPdv$ = this.moduloPdv.asObservable();
  moduloApp$ = this.moduloApp.asObservable();
  moduloChatbotInstagram$  = this.moduloChatbotInstagram.asObservable();

  empresa$ = this.empresa.asObservable();
  campoCpf$ = this.campoCpf.asObservable();
  campoDataNascimento$ = this.campoDataNascimento.asObservable();
  produtosLoja: any = [];
  diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  KEYNAOIMPRIMIR = 'nao-imprimir'
  KEYDESATIVARALERTAGARCOM = 'desativar-alerta-garcom'
  naoImprimir: boolean
  naoTocarGarcom: boolean;


  constructor(private httpClient: HttpClient,  private empresasService: EmpresasService,
              private armazenamentoService: ArmazenamentoService) {
    this.empresasService.logada().then( (empresa: any) => {
      this.carregueInformacoesDeHorarioDeFuncionamento(empresa);

      this.empresa.next ( empresa );

      this.moduloChatbot.next( this.possuiModulo(empresa, 'chatbot'));
      this.moduloChatbotInstagram.next( this.possuiModulo(empresa, 'chatbot instagram'));
      this.moduloPedido.next( this.possuiModulo(empresa, 'pedidos')  )
      this.moduloFidelidade.next( this.possuiModulo(empresa, 'fidelidade')  )
      this.somenteCardapio.next( this.possuiApenasModulo(empresa, 'cardápio')  )
      this.moduloGarcom.next( this.possuiModulo(empresa, 'garcom')  )
      this.moduloEntregadores.next(
        this.possuiModulo(empresa, 'entregadores')
      )

      this.moduloPdv.next( this.possuiModulo(empresa, 'pdv')  )
      this.moduloApp.next( this.possuiModulo(empresa, 'app')  )
      this.exibirProdutos.next( this.temPedidos(empresa)  )
      this.campoCpf.next( this.possuiCampoExtra(empresa, 'cpf'));
      this.campoDataNascimento.next( this.possuiCampoExtra(empresa, 'datanascimento'));
    });
    try {
      if(this.armazenamentoService.carregue(this.KEYNAOIMPRIMIR, false) === '1')
        this.naoImprimir =  true;

      if(this.armazenamentoService.carregue(this.KEYDESATIVARALERTAGARCOM, false) === '1')
        this.naoTocarGarcom = true;

    }catch(e)
    {}
  }

  temPedidos(empresa) {
    return this.possuiModulo(empresa, 'cardápio') || this.possuiModulo(empresa, 'pedidos')
  }

  possuiCampoExtra(empresa: any, nomeCampo){
    if(!empresa || !empresa.camposExtras) return false;

    return empresa.camposExtras.find(campoExtra =>   campoExtra.nome === nomeCampo) != null;
  }

  possuiModulo(empresa: any, nomeModulo: string) {
    if(!empresa || !empresa.modulos) return false;

    return empresa.modulos.some(modulo =>   modulo.nome === nomeModulo);
  }

  possuiApenasModulo(empresa: any, nomeModulo: string) {
    if(!empresa || !empresa.modulos) return false;

    return empresa.modulos.length === 1 && this.possuiModulo(empresa, nomeModulo);
  }

  recarregueEmpresa() {
    this.empresasService.logada().then( (empresa: any) => {
      this.empresa.next ( empresa );
      this.moduloChatbot.next( this.possuiModulo(empresa, 'chatbot'));
      this.moduloPedido.next( this.possuiModulo(empresa, 'pedidos')  )
      this.moduloFidelidade.next( this.possuiModulo(empresa, 'fidelidade')  )
      this.somenteCardapio.next( this.possuiApenasModulo(empresa, 'cardápio')  )
      this.exibirProdutos.next( this.temPedidos(empresa)  )
      this.campoCpf.next( this.possuiCampoExtra(empresa, 'cpf'));
    }).catch( (error) => {});
  }

  mudouEmpresa(empresa: any) {
    localStorage.setItem('alterouEmpresa', new Date().getTime().toString());
    this.empresa.next( empresa);
  }

  private carregueInformacoesDeHorarioDeFuncionamento(empresa: any) {
    if(!empresa.horariosHoje.length) {
      empresa.descricaoHorario = "Fechado";
      if(!empresa.estaRecebendoPedidos)
        empresa.mensagemAbrirPedidos = empresa.mensagemPausaProgramada || 'Não estamos recebendo pedidos hoje'

    } else {
      empresa.descricaoHorario = this.diasDaSemana[empresa.horariosHoje[0].diaDaSemana];
      let descricoesHorario = [];

      for(let i = 0; i < empresa.horariosHoje.length; i++) {
        let horarioHoje = empresa.horariosHoje[i];
        if(!horarioHoje.funciona) {
          descricoesHorario.push("Fechado");

          if(!empresa.estaRecebendoPedidos)
            empresa.mensagemAbrirPedidos = empresa.mensagemPausaProgramada || 'Não estamos recebendo pedidos hoje'

          break;
        }
        descricoesHorario.push(horarioHoje.horarioAbertura.substr(0, 5)  + " - " + horarioHoje.horarioFechamento.substr(0, 5) );
      }
      if(!empresa.mensagemAbrirPedidos && empresa.horarioAbertura)
        empresa.mensagemAbrirPedidos = 'Abre às ' + empresa.horarioAbertura;

      empresa.descricaoHorario += " " + descricoesHorario.join(" | ");
    }

    if(empresa.estaAberta) {
      empresa.formasDeEntrega.forEach( (forma: any) => {
        if(forma.tempoMinimo && forma.tempoMaximo)
          empresa.descricaoTempoEntrega =  String(`${forma.tempoMinimo} ~ ${forma.tempoMaximo} min`);
      });

      if(!empresa.estaRecebendoPedidos)
        empresa.mensagemAbrirPedidos = empresa.mensagemFechada  || 'Fechamos por alguns minutos';
    }
  }

  toogleNaoImprimir() {
    this.armazenamentoService.salveSemExpirar(this.KEYNAOIMPRIMIR, this.naoImprimir ? '1' : '0')
  }

  toogleNaoTocarGarcom() {
    this.armazenamentoService.salveSemExpirar(this.KEYDESATIVARALERTAGARCOM, this.naoTocarGarcom ? '1' : '0')
  }
}
