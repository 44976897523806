<kendo-dialog (close)="confirm(false)" [width]="400">
  <kendo-dialog-titlebar>
    {{ title }}
  </kendo-dialog-titlebar>
  <p>{{ message }}</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="confirm(true)">Sim</button>
    <button kendoButton (click)="confirm(false)">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
