import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class DominiosService {

  constructor() { }

  obtenhaRaizCardapio() {
    if(location.host.indexOf('winecloud.com.br') >= 0) return '';
    if(location.host.indexOf('meucardapio.ai') >= 0) return '';
    if(location.host.indexOf('promokit') < 0) return '';
    return location.pathname.split('/')[1]
  }
}
