import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {EnumFiltroDePedidos} from "./pedidos.service";

@Injectable({
  providedIn: 'root'
})
export class ComandaService extends ServerService {

  constructor(http: HttpClient) {
    super(http);
  }

  liste() {
    return this.obtenha('/comandas/liste', { });
  }

  obtenhaMesa(idMesa: string) {
    return this.obtenha('/comandas/mesa?idm=' + idMesa, {});
  }

  trocarMesaComanda(idComanda: string, mesa: any) {
    return this.facaPost('/comandas/trocar-mesa', {
      idc: idComanda,
      idnm: mesa.id
    });
  }

  reabraComanda(comanda: any) {
    return this.facaPost(String(`/comandas/${comanda.id}/reabra`), {});
  }

  atualizeDesconto(comanda: any, desconto: number) {
    return this.facaPost(String(`/comandas/${comanda.id}/desconto`), {desconto: desconto});
  }

  marqueComoLido(comanda: any){
    return this.facaPost(String(`/comandas/${comanda.id}/marquelida`), {});
  }

}
